import React, { memo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Button, Text } from "../../../../../../components";
import { ArrowForSelectIcon } from "../../../../../../assets";

// action && types redux
import {
  getListWidget,
  handleLoadingFilterHome,
  setActiveRangeFilterWidget,
  setFormFilterWidget
} from "../../../../../../redux/actions";
import { Reducers } from "../../../../../../redux/types";

const listRanges = [
  {
    label: "All period",
    value: 0
  },
  {
    label: "Last 6 months",
    value: 6
  },
  {
    label: "Last year",
    value: 12
  }
];

let run: any;

const Filter = () => {
  const dispatch = useDispatch();
  const homeState = useSelector((state: Reducers) => state.home);

  const _getLastDate = (date: string) =>
    Number(
      moment(date)
        .endOf("month")
        .format("DD")
    );

  const _getData = () => {
    dispatch(handleLoadingFilterHome());
    clearTimeout(run);
    run = setTimeout(() => {
      dispatch(getListWidget(""));
    }, 2000);
  };

  const _setFormFilter = (form: any) => {
    dispatch(setFormFilterWidget(form));
    _getData();
  };

  const _filterFromPeriod = (value: number, index: number) => {
    dispatch(setActiveRangeFilterWidget(index));
    let form = {
      from: {
        date: 1,
        months: moment().month() + 1,
        years: moment().year(),
        time: "00:00"
      },
      to: {
        date: Number(
          moment(`${moment().year()}-${moment().month() + 1}`)
            .endOf("month")
            .format("DD")
        ),
        months: moment().month() + 1,
        years: moment().year(),
        time: "24:00"
      }
    };
    if (value > 0) {
      const date = moment().subtract(value, "months");
      form = {
        ...form,
        from: {
          date: 1,
          months: Number(date.format("MM")),
          years: Number(date.format("YYYY")),
          time: "00:00"
        }
      };
    }
    _setFormFilter(form);
  };

  return (
    <>
      <div className="dashboard-home-filter-wrap-date">
        <div className="dashboard-home-wrap-filter-shape">
          <div className="dashboard-home-filter-item-date dashboard-home-filter-item-date-background">
            <div className="dashboard-home-filter-wrap-item-date">
              <div className="dashboard-home-filter-wrap-select dashboard-home-filter-wrap-select-left">
                <select
                  className="dashboard-home-filter-select"
                  onChange={e =>
                    _setFormFilter({
                      ...homeState.form,
                      from: {
                        ...homeState.form.from,
                        months: Number(e.target.value)
                      }
                    })
                  }
                  value={homeState.form.from.months}
                >
                  {moment.months().map((item, index) => (
                    <option
                      value={index + 1}
                      key={index}
                      className="dashboard-home-filter-select-options"
                    >
                      {item}
                    </option>
                  ))}
                </select>
                <ArrowForSelectIcon />
              </div>

              <div className="dashboard-home-filter-wrap-select">
                <select
                  className="dashboard-home-filter-select"
                  onChange={e =>
                    _setFormFilter({
                      ...homeState.form,
                      from: {
                        ...homeState.form.from,
                        years: Number(e.target.value)
                      },
                      to: {
                        ...homeState.form.to,
                        years:
                          Number(e.target.value) > homeState.form.to.years
                            ? moment().year()
                            : homeState.form.to.years
                      }
                    })
                  }
                  value={homeState.form.from.years}
                >
                  {[...Array(20).keys()].map((item, index) => (
                    <option
                      value={moment().year() - index}
                      key={index}
                      className="dashboard-home-filter-select-options"
                    >
                      {moment().year() - index}
                    </option>
                  ))}
                </select>
                <ArrowForSelectIcon />
              </div>
            </div>
          </div>
          <div className="dashboard-home-filter-shape" />
        </div>

        <div className="dashboard-home-filter-item-date dashboard-home-filter-item-date-right">
          <div className="dashboard-home-filter-wrap-item-date">
            <div className="dashboard-home-filter-wrap-select dashboard-home-filter-wrap-select-left">
              <select
                className="dashboard-home-filter-select"
                onChange={e =>
                  _setFormFilter({
                    ...homeState.form,
                    to: {
                      ...homeState.form.to,
                      months: Number(e.target.value),
                      date: _getLastDate(
                        `${homeState.form.to.years}-${Number(e.target.value)}`
                      )
                    }
                  })
                }
                value={homeState.form.to.months}
              >
                {moment.months().map((item, index) => (
                  <option
                    value={index + 1}
                    key={index}
                    className="dashboard-home-filter-select-options"
                  >
                    {item}
                  </option>
                ))}
              </select>
              <ArrowForSelectIcon />
            </div>

            <div className="dashboard-home-filter-wrap-select">
              <select
                className="dashboard-home-filter-select"
                onChange={e =>
                  _setFormFilter({
                    ...homeState.form,
                    to: { ...homeState.form.to, years: Number(e.target.value) }
                  })
                }
                value={homeState.form.to.years}
              >
                {[
                  ...Array(
                    moment().year() - homeState.form.from.years + 1
                  ).keys()
                ].map((item, index) => (
                  <option
                    value={moment().year() - index}
                    key={index}
                    className="dashboard-home-filter-select-options"
                  >
                    {moment().year() - index}
                  </option>
                ))}
              </select>
              <ArrowForSelectIcon />
            </div>
          </div>
        </div>
      </div>

      <div className="dashboard-home-filter-wrap-range">
        {listRanges.map((item, index) => (
          <Button
            key={index}
            onPress={() => _filterFromPeriod(item.value, index)}
            className={`dashboard-home-filter-item-range ${index ===
              homeState.activeRange &&
              "dashboard-home-filter-item-range-selected"}`}
          >
            <Text
              className={`dashboard-home-filter-text-item ${index ===
                homeState.activeRange &&
                "dashboard-home-filter-text-item-selected"}`}
            >
              {item.label}
            </Text>
          </Button>
        ))}
      </div>
    </>
  );
};

export default memo(Filter);
