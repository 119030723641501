import React, { ChangeEvent, memo, useCallback } from "react";
import { shallowEqual, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import { Reducers } from "../../../../redux/types";

import "./styles.scss";
import { ICONS, IMAGES } from "../../../../configs";
import { openNewTab } from "../../../../utils";

interface Props {
  onChange?: (e: { name: "name" | "address"; value: string }) => void;
  value?: {
    name: string;
    address: string;
  };
  valueInput?: {
    name: string;
    address: string;
  };
  placeholder?: string;
  onChangeFile?: (e: ChangeEvent<HTMLInputElement>) => void;
  linkImage?: string;
  valueImageLocal?: File;
  onRemoveImage?: () => void;
}

const AttachmentInputBussiness = ({
  onChange,
  value,
  placeholder,
  onChangeFile,
  valueInput,
  linkImage,
  valueImageLocal,
  onRemoveImage
}: Props) => {
  const { t } = useTranslation();

  const { authState } = useSelector(
    (state: Reducers) => ({
      authState: state.auth
    }),
    shallowEqual
  );

  const _checkPermissionOthers = useCallback(
    () => authState.myPermission.list.includes("employee-others-edit"),
    [authState.myPermission.list]
  );

  return (
    <div>
      <div>
        <div className="components-employee-custom-label" />
        <div className="components-employee-container-attachment-input-container">
          <div>
            <div>
              <div className="components-employee-custom-label">
                {t("personnel.employeeDetail.employeeBussiness.name")}
              </div>
              <input
                placeholder={placeholder}
                className="components-employee-container-attachment-input"
                onChange={e =>
                  onChange && onChange({ name: "name", value: e.target.value })
                }
                value={valueInput?.name}
                disabled={!_checkPermissionOthers()}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <div className="components-employee-custom-label">
                {t("personnel.employeeDetail.employeeBussiness.address")}
              </div>
              <input
                placeholder={placeholder}
                className="components-employee-container-attachment-input"
                onChange={e =>
                  onChange &&
                  onChange({ name: "address", value: e.target.value })
                }
                value={valueInput?.address}
                disabled={!_checkPermissionOthers()}
              />
            </div>
          </div>
          <div
            className={
              value
                ? "component-upload components-employee-container-attachment global-shadow-card"
                : "component-upload components-employee-container-attachment-input-button global-shadow-card"
            }
          >
            <input
              type="file"
              onChange={onChangeFile}
              disabled={!_checkPermissionOthers()}
            />
            <span>{value || t("btn.attachmentButton")}</span>
          </div>
          {(valueImageLocal?.name || linkImage) && (
            <div
              style={{ width: 24, height: 24, marginLeft: 12 }}
              onClick={() =>
                openNewTab(
                  valueImageLocal?.name
                    ? URL.createObjectURL(valueImageLocal)
                    : linkImage!
                )
              }
            >
              <img
                src={ICONS.viewImage}
                alt="viewImage"
                style={{ width: 24, height: 24 }}
              />
            </div>
          )}

          {valueImageLocal?.name && (
            <div style={{ marginLeft: 12 }} onClick={onRemoveImage}>
              <img
                className="employee-row-button"
                src={IMAGES.deleteRound}
                alt="close"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(AttachmentInputBussiness);
