/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Switch from "react-switch";

import "./styles.scss";
import { Button, Container } from "../../../../components";
import { IMAGES } from "../../../../configs";
import { Reducers } from "../../../../redux/types";
import { getOnBehalfById } from "../../../../redux/actions";

interface Props {
  active: boolean;
  setActive: (e: any) => void;
  id?: string;
  changePass: boolean;
  setChangePass: (e: any) => void;
  isLoad: boolean;
}

const NewOnBehalf = ({
  active,
  setActive,
  id,
  changePass,
  setChangePass,
  isLoad
}: Props) => {
  const { register, errors, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const dispatch = useDispatch();
  const { populateEmployee, listEmployee, isLoadingListEmployee } = useSelector(
    (state: Reducers) => state.generate
  );
  const { isLoadingOnbehalfById, onBehalfById } = useSelector(
    (state: Reducers) => state.company
  );

  const [employess, setEmployees]: any = useState();
  const [activeFirst, setActiveFirst] = useState(true);

  const _errorValidation = (name: string) => (
    <ErrorMessage errors={errors} name={name} as="p" style={{ color: "red" }} />
  );

  const _getDetailEmployee = useCallback(
    (employeeID: string) => {
      let result = {};
      result = listEmployee.find(res => res.id === employeeID);
      setEmployees(result);
    },
    [listEmployee]
  );

  useEffect(() => {
    if (isLoad && id && activeFirst) {
      setActiveFirst(false);
      dispatch(
        getOnBehalfById(id, e => {
          setActive(e.attributes.active);
          setValue("name", e.attributes.name);
          setValue("email", e.attributes.email);
        })
      );
    }
  }, [activeFirst, dispatch, id, isLoad, setActive, setValue]);

  useEffect(() => {
    if (!isLoad) {
      setActiveFirst(true);
    }
  }, [isLoad]);

  useEffect(() => {
    if (isLoad && id) {
      setValue("employee_id", onBehalfById.attributes.parent.id);
      _getDetailEmployee(onBehalfById.attributes.parent.id);
    }
  }, [
    _getDetailEmployee,
    id,
    isLoad,
    listEmployee,
    onBehalfById.attributes.parent.id,
    setValue
  ]);

  const _deleteEmployee = () => {
    setValue("employee_id", "");
  };

  const _showHidePAssword = () => {
    if (changePass && changePass && id) {
      return (
        <div className="onbehalf-row-mode space">
          <div className="new-onbehalf-wrap-form employee">
            <span className="new-onbehalf-label">
              {id
                ? t("company.form.newPassword")
                : t("personnel.form.password")}
            </span>
            <input
              name="password"
              type="password"
              className="component-modal-input margin-top-12"
              placeholder={t("personnel.form.password")}
              ref={register({
                required: sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.password")
                )
              })}
            />
            {_errorValidation("password")}
          </div>
          <div className="new-onbehalf-wrap-form employee">
            <span className="new-onbehalf-label">
              {t("personnel.form.confirmPassword")}
            </span>
            <input
              name="pass_confirmation"
              type="password"
              className="component-modal-input margin-top-12"
              placeholder={t("personnel.form.confirmPassword")}
              ref={register({
                required: sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.confirmPassword")
                ),
                validate: value =>
                  value === watch("password") ||
                  String(t("company.form.passwordMatchError"))
              })}
            />
            {_errorValidation("pass_confirmation")}
          </div>
        </div>
      );
    }
    if (!id) {
      return (
        <div className="onbehalf-row-mode space">
          <div className="new-onbehalf-wrap-form employee">
            <span className="new-onbehalf-label">
              {t("personnel.form.password")}
            </span>
            <input
              name="password"
              type="password"
              className="component-modal-input margin-top-12"
              placeholder={t("personnel.form.password")}
              ref={register({
                required: sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.password")
                )
              })}
            />
            {_errorValidation("password")}
          </div>
          <div className="new-onbehalf-wrap-form employee">
            <span className="new-onbehalf-label">
              {t("personnel.form.confirmPassword")}
            </span>
            <input
              name="pass_confirmation"
              type="password"
              className="component-modal-input margin-top-12"
              placeholder={t("personnel.form.confirmPassword")}
              ref={register({
                required: sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.confirmPassword")
                ),
                validate: value =>
                  value === watch("password") ||
                  String(t("company.form.passwordMatchError"))
              })}
            />
            {_errorValidation("pass_confirmation")}
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <Container
      isLoading={isLoadingOnbehalfById || isLoadingListEmployee}
      style={{ width: 760, height: 300 }}
    >
      <div className="new-onbehalf-container">
        <form>
          <div className="onbehalf-row-mode space">
            <div className="new-onbehalf-wrap-form employee">
              <span className="new-onbehalf-label">
                {t("company.form.pickEmployee")}
              </span>
              <select
                name="employee_id"
                className="component-modal-input-dropdown margin-top-12"
                ref={register({
                  required: sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("company.form.pickEmployee")
                  )
                })}
                disabled={!!id}
                onChange={e => _getDetailEmployee(e.target.value)}
              >
                {populateEmployee.map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>
              <div className="component-modal-line" />
              {_errorValidation("employee_id")}
            </div>
            {watch("employee_id") && employess && (
              <div className="new-onbehalf-wrap-form employee">
                <div className="new-onbehalf-label normal">
                  {t("company.tab.onBehalf")}
                </div>
                <div className="onbehalf-row-mode vertical space">
                  <div>
                    <div className="new-onbehalf-label margin-top-12">
                      {employess.name}
                    </div>
                    <div className="new-onbehalf-label normal">
                      {employess.primaryUser.email}
                    </div>
                    <div className="new-onbehalf-label normal">
                      {employess.position_name}
                    </div>
                  </div>
                  {!id && (
                    <Button onPress={_deleteEmployee}>
                      <img
                        src={IMAGES.deleteRound}
                        alt="n/a"
                        style={{ height: 50, width: 50 }}
                        className="pointer"
                      />
                    </Button>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="onbehalf-row-mode space">
            <div className="new-onbehalf-wrap-form employee">
              <span className="new-onbehalf-label">
                {t("personnel.form.name")}
              </span>
              <input
                name="name"
                type="text"
                className="component-modal-input margin-top-12"
                placeholder={t("personnel.form.name")}
                ref={register({
                  required: sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("personnel.form.name")
                  )
                })}
              />
              {_errorValidation("name")}
            </div>
            <div className="new-onbehalf-wrap-form employee">
              <span className="new-onbehalf-label">
                {t("personnel.form.email")}
              </span>
              <input
                name="email"
                type="text"
                className="component-modal-input margin-top-12"
                placeholder={t("personnel.form.email")}
                ref={register({
                  required: sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("personnel.form.email")
                  ),
                  pattern: {
                    value: /^[\w]+@([\w-]+\.)+[\w-]{2,4}$/,
                    message: t("invalidEmailMsg")
                  }
                })}
              />
              <div className="email-info">{t("company.form.emailInfo")}</div>
              {_errorValidation("email")}
            </div>
          </div>
          {_showHidePAssword()}
          {id && (
            <div className="new-onbehalf-wrap-form employee">
              <div className="new-onbehalf-label">
                {t("company.form.changePassword")}
              </div>
              <div className="onbehalf-row-mode">
                <Switch
                  onChange={() => setChangePass(!changePass)}
                  onKeyPress={() => setChangePass(!changePass)}
                  checked={changePass}
                  onColor="#ff7600"
                  onHandleColor="#fffff7"
                  handleDiameter={18}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={26}
                  width={54}
                />
                <span style={{ fontWeight: "bold", marginLeft: 8 }}>
                  {`${t("no")} / ${t("yes")}`}
                </span>
              </div>
            </div>
          )}

          <div className="new-onbehalf-wrap-form employee">
            <div className="new-onbehalf-label">
              {t("personnel.form.isactive")}
            </div>
            <div className="onbehalf-row-mode">
              <Switch
                onChange={() => setActive(!active)}
                onKeyPress={() => setActive(!active)}
                checked={active}
                onColor="#ff7600"
                onHandleColor="#fffff7"
                handleDiameter={18}
                uncheckedIcon={false}
                checkedIcon={false}
                height={26}
                width={54}
              />
              <span style={{ fontWeight: "bold", marginLeft: 8 }}>
                {`${t("no")} / ${t("yes")}`}
              </span>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
};

export default NewOnBehalf;
