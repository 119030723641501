import React, { useCallback, useEffect } from "react";
import { Slide, toast } from "react-toastify";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FormContext, useForm } from "react-hook-form";
import Cookies from "js-cookie";

import {
  Button,
  Container,
  Footer,
  HeaderTittle,
  SideBar,
  Text,
  TopBar,
  WrapApp
} from "../../components";
import { Permission } from "../../utils";
import "./styles.scss";
import {
  addAccountsEmployee,
  getAllBank,
  getCompanyDetail,
  getMenu,
  getMyPermission,
  getProfileStaff,
  getRegency,
  handleTheme,
  putSaveMyProfile,
  setActiveDepartment,
  setActivePosition,
  setActiveSpecialRole,
  setFormMyProfileEmployee,
  setImageMyProfileEmployee
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

import Profile from "./Profile";
import Salary from "./Salary";
import Contracts from "./Contracts";

const EmployeeDetail = () => {
  const { id } = useParams() as any;
  const permission = {
    othersEdit: Permission("employee-others-edit"),
    othersView: Permission("employee-others-view"),
    selfView: Permission("employee-self-view"),
    selfEdit: Permission("employee-self-edit")
  };
  const getLanguage = Cookies.get("locale") || "en";
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const methods = useForm();
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;

  const { homeState, employeeState, menuState, myProfile } = useSelector(
    (state: Reducers) => ({
      employeeState: state.employee,
      menuState: state.menu,
      authState: state.auth,
      myProfile: state.home.profile.data.employee,
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
    dispatch(getMyPermission());
    if (myProfile && myProfile.id && myProfile.id !== id) {
      if (
        permission.selfView &&
        !permission.othersView &&
        !permission.othersEdit
      ) {
        // return false
        // history.goBack();
        history.push(`/employee-detail/${myProfile.id}`);
        toast(String(t("personnel.toast.otherPermissionMsg")), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      }
    }
  }, [
    dispatch,
    getLanguage,
    history,
    id,
    myProfile,
    myProfile.id,
    permission.othersEdit,
    permission.othersView,
    permission.selfView,
    t
  ]);

  const _handleRevert = useCallback(() => {
    dispatch(
      setFormMyProfileEmployee("idNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("passport", {
        number: "",
        expired_at: {
          mm: "",
          yy: ""
        },
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("taxNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(setFormMyProfileEmployee("city", ""));
    dispatch(setImageMyProfileEmployee(""));
    dispatch(
      getCompanyDetail(e => {
        return e;
      })
    );
    dispatch(getProfileStaff(id));
    dispatch(getRegency());
    dispatch(getAllBank());
    dispatch(addAccountsEmployee(true));
  }, [dispatch, id]);

  const _handleSave = useCallback(
    form => {
      dispatch(putSaveMyProfile(form, employeeState.staff.data.id));
    },
    [dispatch, employeeState.staff.data.id]
  );

  const _redirectPage = useCallback(
    (path: string) => {
      history.push(path);
      dispatch(setActiveDepartment(-1));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
    },
    [dispatch, history]
  );

  const _checkPermission = () => {
    if (homeState.profile.listRole.includes("personal-loan-hr")) {
      return true;
    }

    if (myProfile.id !== id && permission.othersEdit) {
      return true;
    }
    if (myProfile.id === id && permission.selfView) {
      return true;
    }
    return false;
  };

  const listPage = [
    {
      pathname: `${match.url}`,
      component: (
        <div className="page-employee-profile">
          <Profile userId={id} />
        </div>
      ),
      exact: true,
      titleTab: t("personnel.employeeDetail.profile"),
      disabled: false
    },
    {
      pathname: `${match.url}/salary`,
      component: <Salary employeeId={id} />,
      exact: true,
      titleTab: t("personnel.employeeDetail.salary"),
      disabled: !_checkPermission()
    },
    {
      pathname: `${match.url}/contract`,
      component: <Contracts employeeId={id} />,
      exact: true,
      titleTab: t("personnel.employeeDetail.contract"),
      disabled: !_checkPermission()
    }
  ];

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <FormContext {...methods}>
          <Container scroll withMaxWidth>
            <HeaderTittle title={t("personnel.employeeDetail.title")} />
            <div
              className={`dashboard-employee-menu  ${
                listPage[1].disabled ? "disabled" : ""
              }`}
            >
              {/* dasboard-employee-menu-nav */}
              {listPage.map((item, index) => (
                <div
                  className={`dasboard-employee-menu-nav ${index === 0 &&
                    item.pathname === pathname &&
                    "active"} ${index !== 0 &&
                    pathname.includes(item.pathname) &&
                    "active"} ${item.disabled ? "disabled" : ""}`}
                  key={index}
                >
                  <Button
                    onPress={() => _redirectPage(item.pathname)}
                    style={{ width: "100%", textAlign: "center" }}
                    disabled={item.disabled}
                  >
                    <Text>{item.titleTab}</Text>
                  </Button>
                </div>
              ))}
            </div>
            <div>
              {listPage.map((item, index) => (
                <Route path={item.pathname} exact={item.exact} key={index}>
                  {item.component}
                </Route>
              ))}
            </div>
          </Container>
          <Footer
            buttons={
              pathname === listPage[0].pathname && [
                {
                  label: t("btn.revert"),
                  className: "alt",
                  onClick: _handleRevert,
                  isLoading: employeeState.staff.isLoading
                  // isDisable: !permission.othersEdit,
                },
                {
                  label: t("btn.save"),
                  onClick: methods.handleSubmit(_handleSave),
                  isLoading: employeeState.isLoading,
                  isDisable:
                    id !== myProfile.id
                      ? !permission.othersEdit
                      : !permission.selfEdit
                }
              ]
            }
          />
        </FormContext>
      </WrapApp>
    </Switch>
  );
};

export default EmployeeDetail;
