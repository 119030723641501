import React, { CSSProperties, memo } from "react";

import "./styles.scss";

interface Props {
  children?: string | number | any;
  className?: string;
  style?: CSSProperties;
}

const Text = ({ children, className, style }: Props) => (
  <div className={`text-font ${className}`} style={style}>
    {children}
  </div>
);

Text.defaultProps = {
  className: ""
};

export default memo(Text);
