import {
  DELETE_DIVISION_ERROR,
  DELETE_DIVISION_PENDING,
  DELETE_DIVISION_RESET,
  DELETE_DIVISION_SUCCESS,
  GET_DIVISION_ERROR,
  GET_DIVISION_PENDING,
  GET_DIVISION_SUCCESS,
  POST_DIVISION_ERROR,
  POST_DIVISION_PENDING,
  POST_DIVISION_RESET,
  POST_DIVISION_SUCCESS,
  PUT_DIVISION_ERROR,
  PUT_DIVISION_PENDING,
  PUT_DIVISION_RESET,
  PUT_DIVISION_SUCCESS
} from "../actions";
import { Action, DivisionState } from "../types";

const initialState: DivisionState = {
  isLoadingGetDivision: false,
  division: null,
  errorGetDivision: null,
  isLoadingPostDivision: false,
  successPostDivision: null,
  errorPostDivision: null,
  isLoadingPutDivision: false,
  successPutDivision: null,
  errorPutDivision: null,
  isLoadingDeleteDivision: false,
  successDeleteDivision: null,
  errorDeleteDivision: null
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_DIVISION_PENDING:
      return {
        isLoadingGetDivision: true,
        division: null,
        errorGetDivision: null
      };

    case GET_DIVISION_SUCCESS:
      return {
        isLoadingGetDivision: false,
        division: payload.data.data,
        errorGetDivision: null
      };

    case GET_DIVISION_ERROR:
      return {
        ...state,
        isLoadingGetDivision: false,
        division: null,
        errorGetDivision: payload.data
      };

    case POST_DIVISION_PENDING:
      return {
        ...state,
        isLoadingPostDivision: true,
        successPostDivision: null,
        errorPostDivision: null
      };
    case POST_DIVISION_SUCCESS:
      return {
        ...state,
        isLoadingPostDivision: false,
        successPostDivision: payload.data.data,
        errorPostDivision: null
      };

    case POST_DIVISION_ERROR:
      return {
        ...state,
        isLoadingPostDivision: false,
        successPostDivision: null,
        errorPostDivision: payload.data
      };
    case POST_DIVISION_RESET:
      return {
        ...state,
        isLoadingPostDivision: false,
        successPostDivision: null,
        errorPostDivision: null
      };

    case PUT_DIVISION_PENDING:
      return {
        ...state,
        isLoadingPutDivision: true,
        successPutDivision: null,
        errorPutDivision: null
      };
    case PUT_DIVISION_SUCCESS:
      return {
        ...state,
        isLoadingPutDivision: false,
        successPutDivision: payload.data.data,
        errorPutDivision: null
      };

    case PUT_DIVISION_ERROR:
      return {
        ...state,
        isLoadingPutDivision: false,
        successPutDivision: null,
        errorPutDivision: payload.data
      };
    case PUT_DIVISION_RESET:
      return {
        ...state,
        isLoadingPutDivision: false,
        successPutDivision: null,
        errorPutDivision: null
      };

    case DELETE_DIVISION_PENDING:
      return {
        ...state,
        isLoadingDeleteDivision: true,
        successDeleteDivision: null,
        errorDeleteDivision: null
      };
    case DELETE_DIVISION_SUCCESS:
      return {
        ...state,
        isLoadingDeleteDivision: false,
        successDeleteDivision: payload.data.success,
        errorDeleteDivision: null
      };

    case DELETE_DIVISION_ERROR:
      return {
        ...state,
        isLoadingDeleteDivision: false,
        successDeleteDivision: null,
        errorDeleteDivision: payload.data
      };
    case DELETE_DIVISION_RESET:
      return {
        ...state,
        isLoadingDeleteDivision: false,
        successDeleteDivision: null,
        errorDeleteDivision: null
      };
    default:
      return { ...state };
  }
};
