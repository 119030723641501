import React from "react";

import "./styles.scss";

interface ListValue {
  value: string;
  label: string;
}

interface Props {
  listValue: ListValue[];
  onChange?: (e: any) => void;
  value?: string;
  defaultValue?: string;
  type?: any;
  className?: string;
  containerClassName?: string;
}

const Picker = ({
  listValue,
  onChange,
  value,
  defaultValue,
  className,
  containerClassName
}: Props) => {
  return (
    <div className={containerClassName}>
      <select
        className={`component-picker-select ${className}`}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {listValue.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

Picker.defaultProps = {
  className: "",
  containerClassName: ""
};

export default Picker;
