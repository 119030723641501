import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const GetPolicies = (policySlug: string) => {
  const policies = useSelector((state: Reducers) => state.auth.policyOwned);
  const result = policies.list.find(el => el.id === policySlug);
  return result && result.value_object && result.value_object.value;
};

export default GetPolicies;
