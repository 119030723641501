import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
// import Scrollbars from "react-custom-scrollbars";

import { SideBar, TopBar, WrapApp, WrapContent } from "../../components";
import { getMenu, getMyPermission, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";

// screens
import Company from "./Company";
import Department from "./Department";
import Tree from "./Tree";
import Division from "./Division";
import OnBehalf from "./OnBehalf";
import Announcement from "./Announcement";
import Detail from "./Announcement/Detail";

const DetailCompany = () => {
  const { path } = useRouteMatch();
  const getLanguage = Cookies.get("locale") || "en";
  const dispatch = useDispatch();

  const menuState = useSelector((state: Reducers) => state.menu);

  const listPage = [
    {
      path: `${path}`,
      component: <Company />,
      exact: true
    },
    {
      path: `${path}/department`,
      component: <Department />,
      exact: true
    },
    {
      path: `${path}/tree`,
      component: <Tree />,
      exact: true
    },
    {
      path: `${path}/division`,
      component: <Division />,
      exact: true
    },
    {
      path: `${path}/on-behalf`,
      component: <OnBehalf />,
      exact: true
    },
    {
      path: `${path}/announcement`,
      component: <Announcement />,
      exact: true
    },
    {
      path: `${path}/announcement/detail/:id`,
      component: <Detail />,
      exact: false
    }
  ];

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
    dispatch(getMyPermission());
  }, [dispatch, getLanguage]);

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <WrapContent>
          {listPage.map((item, index) => (
            <Route path={item.path} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </WrapContent>
      </WrapApp>
    </Switch>
  );
};

export default DetailCompany;
