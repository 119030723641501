import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Reducers } from "../../../redux/types";
import {
  deleteDivision,
  getDivision,
  getMyPermission,
  postDivision,
  putDivision,
  resetDeleteDivision,
  resetPostDivision,
  resetPutDivision
} from "../../../redux/actions";
import {
  Button,
  Container,
  Footer,
  HeaderTittle,
  Modal
} from "../../../components";
import { IMAGES } from "../../../configs";
import "./styles.scss";
import { Permission, autoGrowTextArea, copyWritings } from "../../../utils";
import { Navigation } from "../components/Navigation";

const Division = () => {
  const [firstTimeOnly, setFirstTimeOnly] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const permission = {
    otherList: Permission("on-behalf-others-list"),
    ownedList: Permission("on-behalf-owned-list"),
    divisionOtherList: Permission("division-others-list"),
    positionOtherList: Permission("position-others-list"),
    positioCreate: Permission("position-create"),
    positionOtherEdit: Permission("position-others-edit")
  };

  const { t } = useTranslation();

  const defaultForm: {
    title: string;
    description: string;
    uuid: null | string;
  } = { title: "", description: "", uuid: null };
  const [form, setForm] = useState(defaultForm);

  const dispatch = useDispatch();
  const { division: DivisionState } = useSelector((state: Reducers) => state);

  const [params] = useState({
    per_page: 10,
    q: "",
    page: 1,
    sortField: "created_at",
    sortOrder: "desc"
  });

  const _onSaveSubmit = () => {
    if (!isSubmit) {
      setIsSubmit(true);
      if (form.uuid) {
        dispatch(putDivision(form));
      } else {
        dispatch(postDivision(form));
      }
    }
  };

  const onDeleteSubmit = () => {
    setIsSubmit(true);
    dispatch(deleteDivision(form.uuid));
  };

  const reload = useCallback(
    (value: any) => {
      dispatch(getDivision(value));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getMyPermission());
  }, [dispatch]);

  useEffect(() => {
    if (firstTimeOnly) {
      reload(params);
      setFirstTimeOnly(false);
    }
    if (
      !DivisionState.isLoadingPostDivision &&
      isSubmit &&
      DivisionState.successPostDivision
    ) {
      dispatch(resetPostDivision());
      setForm(defaultForm);
      setIsSubmit(false);
      setSaveModal(false);
      reload(params);
    }
    if (
      !DivisionState.isLoadingPutDivision &&
      isSubmit &&
      DivisionState.successPutDivision
    ) {
      dispatch(resetPutDivision());
      setForm(defaultForm);
      setIsSubmit(false);
      setSaveModal(false);
      reload(params);
    }
    if (
      !DivisionState.isLoadingDeleteDivision &&
      isSubmit &&
      DivisionState.successDeleteDivision
    ) {
      dispatch(resetDeleteDivision());
      setForm(defaultForm);
      setIsSubmit(false);
      setDeleteModal(false);
      reload(params);
    }
  }, [
    setFirstTimeOnly,
    firstTimeOnly,
    params,
    reload,
    DivisionState.isLoadingPostDivision,
    DivisionState.successPostDivision,
    DivisionState.isLoadingPutDivision,
    DivisionState.successPutDivision,
    DivisionState.isLoadingDeleteDivision,
    DivisionState.successDeleteDivision,
    dispatch,
    isSubmit,
    defaultForm
  ]);
  return (
    <>
      <Container
        scroll
        withMaxWidth
        className="page-company-position company-tree"
      >
        <HeaderTittle title={copyWritings("company")} />
        <Navigation />
        <Container isLoading={DivisionState.isLoadingGetDivision}>
          <div className="company-division">
            <div className="columns">
              <div className="column">
                <table className="division-table">
                  <thead>
                    <tr>
                      <th>{t("personnel.table.name")}</th>
                      <th>{t("company.table.desc")}</th>
                      <th>
                        <div
                          className="pages columns"
                          style={{
                            fontWeight: "normal",
                            textTransform: "none"
                          }}
                        >
                          <div className="column" />
                          <div className="column is-narrow">
                            {`1 - 3 ${t("personnel.table.of")} 3`}
                          </div>
                          <div
                            className="column is-narrow"
                            style={{ cursor: "pointer" }}
                          >
                            &lt;
                          </div>
                          <div
                            className="column is-narrow"
                            style={{ cursor: "pointer" }}
                          >
                            &gt;
                          </div>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  {!DivisionState.isLoadingGetDivision &&
                    DivisionState.division &&
                    Array.isArray(DivisionState.division) && (
                      <tbody>
                        {DivisionState.division.map((dt, index) => (
                          <tr key={index}>
                            <td>{dt.attributes?.title}</td>
                            <td>{dt.attributes?.description || "-"}</td>
                            <td>
                              <div className="columns">
                                <div className="column" />
                                <div className="column is-narrow">
                                  <div className="action-button">
                                    {permission.positionOtherEdit && (
                                      <Button
                                        onPress={() => {
                                          setForm({
                                            ...form,
                                            title: dt.attributes?.title,
                                            description:
                                              dt.attributes?.description || "",
                                            uuid: dt.id
                                          });
                                          setSaveModal(true);
                                        }}
                                      >
                                        <img
                                          className="employee-row-button"
                                          src={IMAGES.pencil}
                                          alt="edit"
                                        />
                                      </Button>
                                    )}
                                    {permission.positionOtherEdit && (
                                      <Button
                                        onPress={() => {
                                          setForm({
                                            ...form,
                                            title: dt.attributes?.title,
                                            description:
                                              dt.attributes?.description || "",
                                            uuid: dt.id
                                          });
                                          setDeleteModal(true);
                                        }}
                                      >
                                        <img
                                          className="employee-row-button"
                                          src={IMAGES.deleteRound}
                                          alt="delete"
                                        />
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    )}
                </table>
              </div>
            </div>
          </div>
        </Container>
      </Container>
      <Container withMaxWidth flex className="company-division-footer-button">
        {permission.positioCreate && (
          <div style={{ position: "relative", width: "100%" }}>
            <div style={{ position: "absolute", right: 0 }}>
              <Button
                className="company-button"
                onPress={() => setSaveModal(true)}
              >
                <img src={IMAGES.floatingPlusButton} alt="create" />
              </Button>
            </div>
          </div>
        )}
      </Container>
      <Modal
        warning
        btnSubmitText={
          DivisionState.isLoadingDeleteDivision ? "LOADING" : t("btn.submit")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModal}
        onPressCancel={() => setDeleteModal(false)}
        onPressSubmit={onDeleteSubmit}
      />

      <Modal
        btnSubmitText={
          DivisionState.isLoadingPostDivision ||
          DivisionState.isLoadingPutDivision
            ? "LOADING"
            : t("btn.save")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={
          form.uuid
            ? t("company.form.editPositionTitle")
            : t("company.form.newPositionTitle")
        }
        isOpen={saveModal}
        onPressCancel={() => {
          setForm(defaultForm);
          setSaveModal(false);
        }}
        onPressSubmit={_onSaveSubmit}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            _onSaveSubmit();
          }}
          className="form-position-class"
        >
          <div style={{ width: 380 }}>
            <div style={{ marginBottom: 16, fontWeight: "bold" }}>
              {t("company.form.title")}
            </div>
            <input
              className="component-modal-input"
              type="text"
              value={form.title}
              onChange={e => setForm({ ...form, title: e.target.value })}
            />
            <div
              style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
            >
              {t("task.form.description")}
            </div>
            <textarea
              className="component-modal-input text-area"
              value={form.description}
              onChange={e => setForm({ ...form, description: e.target.value })}
              onInput={autoGrowTextArea}
            />

            <input type="submit" hidden />
          </div>
        </form>
      </Modal>
      <Footer />
    </>
  );
};
export default Division;
