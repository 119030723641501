import moment from "moment";

import {
  GET_LIST_WIDGET_ERROR,
  GET_LIST_WIDGET_PENDING,
  GET_LIST_WIDGET_SUCCESS,
  GET_MY_PROFILE_ERROR,
  GET_MY_PROFILE_PENDING,
  GET_MY_PROFILE_SUCCESS,
  GET_WIDGET_SUCCESS,
  HANDLE_CATEGORY_WIDGET,
  HANDLE_SELECT_SUB_WIDGET,
  HANDLE_SELECT_WIDGET,
  POST_SUPPORT_ERROR,
  POST_SUPPORT_PENDING,
  POST_SUPPORT_SUCCESS,
  SET_ACTIVE_RANGE_FILTER_WIDGET,
  SET_ACTIVE_SIDE_BAR_DATA,
  SET_CONFIRM_SUBMIT_FORM,
  SET_FORM_FILTER_WIDGET
} from "../actions";
import { Action, HomeState } from "../types";

const initialState: HomeState = {
  isLoadingSupport: false,
  listWidget: {
    isLoading: false,
    data: [],
    activeWidget: 0,
    activeSubWidget: 0,
    activeDataChart: 0
  },
  form: {
    from: {
      date: 1,
      months: moment().month() + 1,
      years: moment().year(),
      time: "00:00"
    },
    to: {
      date: Number(
        moment(`${moment().year()}-${moment().month() + 1}`)
          .endOf("month")
          .format("DD")
      ),
      months: moment().month() + 1,
      years: moment().year(),
      time: "24:00"
    }
  },
  activeRange: 0,
  profile: {
    isLoading: false,
    data: { employee: { position: { title: "" } } },
    listRole: []
  },
  dataFormSubmit: []
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // get list widget
    case GET_LIST_WIDGET_PENDING:
      return {
        ...state,
        listWidget: { ...initialState.listWidget, isLoading: true }
      };
    case GET_LIST_WIDGET_SUCCESS: {
      let dataListWidget: any[] = [];
      payload.data.map((item: any) => {
        dataListWidget = [...dataListWidget, { type: item }];
        return dataListWidget;
      });

      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          isLoading: false,
          data: dataListWidget
        }
      };
    }
    case GET_LIST_WIDGET_ERROR:
      return {
        ...state,
        listWidget: { ...state.listWidget, isLoading: false }
      };

    // get widget
    case GET_WIDGET_SUCCESS:
      state.listWidget.data[payload.index!] = {
        ...state.listWidget.data[payload.index!],
        detail: payload.data,
        categories_active: payload.data.categories[0].value
      };

      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          data: state.listWidget.data
        }
      };

    // handle select category
    case HANDLE_CATEGORY_WIDGET:
      state.listWidget.data[payload.index!] = {
        ...state.listWidget.data[payload.index!],
        categories_active: payload.data
      };

      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          data: state.listWidget.data
        }
      };

    // handle select widget
    case HANDLE_SELECT_WIDGET:
      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          activeWidget: payload.data.data,
          activeSubWidget: payload.data.isTrip ? null : 0,
          activeDataChart: 0
        }
      };
    // handle select sub widget
    case HANDLE_SELECT_SUB_WIDGET:
      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          activeWidget: payload.index,
          activeSubWidget: payload.data,
          activeDataChart: 0
        }
      };
    // set active sidebar data
    case SET_ACTIVE_SIDE_BAR_DATA:
      return {
        ...state,
        listWidget: {
          ...state.listWidget,
          activeDataChart: payload.data
        }
      };

    // set form
    case SET_FORM_FILTER_WIDGET:
      return { ...state, form: payload.data };
    // set active range
    case SET_ACTIVE_RANGE_FILTER_WIDGET:
      return { ...state, activeRange: payload.data };

    // get my profile
    case GET_MY_PROFILE_PENDING:
      return { ...state, profile: { ...state.profile, isLoading: true } };
    case GET_MY_PROFILE_SUCCESS: {
      let dataListRoles: any[] = [];
      payload.data.employee.roles.map((item: any) => {
        dataListRoles = [...dataListRoles, item.name];
        return dataListRoles;
      });
      return {
        ...state,
        profile: {
          ...state.profile,
          isLoading: false,
          data: payload.data,
          listRole: dataListRoles
        }
      };
    }
    case GET_MY_PROFILE_ERROR:
      return { ...state, profile: { ...state.profile, isLoading: false } };

    case POST_SUPPORT_PENDING:
      return { ...state, isLoadingSupport: true };
    case POST_SUPPORT_SUCCESS:
      return {
        ...state,
        isLoadingSupport: false
      };
    case POST_SUPPORT_ERROR:
      return { ...state, isLoadingSupport: false };

    // SET SUBMIT CONFIRM FORM
    case SET_CONFIRM_SUBMIT_FORM:
      return {
        ...state,
        dataFormSubmit: [...state.dataFormSubmit, payload.data]
      };

    default:
      return state;
  }
};
