import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { IMAGES } from "../../configs";

interface Props {
  onChange?: (e: string) => void;
  label?: any;
  className?: any;
  value?: string;
}

const Search = ({ onChange, label, className, value }: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`component-search ${className}`}>
      <img src={IMAGES.searchIcon} alt="search-icon" />
      <input
        type="text"
        onChange={e => onChange && onChange(e.target.value)}
        placeholder={t("searchPlaceholder") || label}
        value={value}
      />
    </div>
  );
};

Search.defaultProps = {
  className: "",
  label: "Looking for something?"
};

export default memo(Search);
