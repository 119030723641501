import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_PROFILE_BY_ID_SUCCESS = "GET_PROFILE_BY_ID_SUCCESS";
export const GET_PROFILE_BY_ID_PENDING = "GET_PROFILE_BY_ID_PENDING";
export const GET_PROFILE_BY_ID_ERROR = "GET_PROFILE_BY_ID_ERROR";

export const getProfileById = (uuid: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_BY_ID_PENDING });

    const res = await API.getProfileById(uuid);
    dispatch({
      type: GET_PROFILE_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_PROFILE_BY_ID_ERROR });
  }
};
