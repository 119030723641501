import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import { Button, Container, Text } from "../../../../components";
import { Card } from "..";
import { IMAGES } from "../../../../configs";

import { Reducers } from "../../../../redux/types";
import {
  getAllPersonal,
  getAllPosition,
  getDepartment,
  getPersonByIdPosition,
  getPersonal,
  getPolicy,
  getPositionByID,
  setActiveDepartment,
  setActivePersonil,
  setActivePosition,
  setDepartementName,
  setPersonilName,
  setPositionName
} from "../../../../redux/actions";

import { copyWritings } from "../../../../utils";

const CategoryPolicy = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);

  const { policy } = useSelector(
    (state: Reducers) => ({
      policy: state.generate
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllPersonal());
    dispatch(getAllPosition());
  }, [dispatch]);

  const _setActiveDepartment = (index: number, id: string, title: string) => {
    if (index === policy.activeDepartment.index) {
      dispatch(setActiveDepartment(-1));
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getAllPersonal());
      dispatch(getAllPosition());
      dispatch(getPolicy());
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activePosition.index >= 0 &&
      policy.activePersonil.index < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(id));
      dispatch(getPositionByID(id));
      dispatch(getPolicy(id));
      dispatch(setDepartementName(title));
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activePersonil.index >= 0 &&
      policy.activePosition.index < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(id));
      dispatch(getPositionByID(id));
      dispatch(getPolicy(id));
      dispatch(setDepartementName(title));
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activePersonil.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(id));
      dispatch(getPositionByID(id));
      dispatch(getPolicy(id));
      dispatch(setDepartementName(title));
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activePersonil.index < 0 &&
      policy.activePosition.index < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(id));
      dispatch(getPositionByID(id));
      dispatch(getPolicy(id));
      dispatch(setDepartementName(title));
    }
  };

  const _setActivePosition = (
    index: number,
    idDp: string,
    idPos: string,
    title: string
  ) => {
    if (index === policy.activePosition.index) {
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getAllPersonal());
      dispatch(getPolicy(idDp));
    }
    if (
      policy.activeDepartment.index >= 0 &&
      index !== policy.activePosition.index
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(getPersonal(idDp, idPos));
      dispatch(getPolicy(idDp, idPos));
      dispatch(setPositionName(title));
    }
    if (
      policy.activeDepartment.index < 0 &&
      index !== policy.activePosition.index &&
      policy.activePersonil.index < 0
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(getPersonByIdPosition(idPos));
      dispatch(getPolicy(idDp, idPos));
      dispatch(setPositionName(title));
    }
    if (
      policy.activeDepartment.index >= 0 &&
      index === policy.activePosition.index
    ) {
      dispatch(setActivePosition(-1));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(idDp));
      dispatch(getPolicy(idDp));
    }
    if (
      policy.activePersonil.index >= 0 &&
      index !== policy.activePosition.index &&
      policy.activeDepartment.index < 0
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonByIdPosition(idPos));
      dispatch(getPolicy(idDp, idPos));
      dispatch(setPositionName(title));
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePersonil.index >= 0 &&
      index !== policy.activePosition.index
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(setActivePersonil(-1));
      dispatch(getPersonal(idDp, idPos));
      dispatch(getPolicy(idDp, idPos));
      dispatch(setPositionName(title));
    }
  };

  const _setActivePersonil = (index: number, name: string, id: string) => {
    if (index === policy.activePersonil.index) {
      dispatch(setActivePersonil(-1));
      dispatch(getPolicy(policy.activeDepartment.id, policy.activePosition.id));
    } else {
      dispatch(setActivePersonil(index, id));
      dispatch(setPersonilName(name));
      dispatch(
        getPolicy(policy.activeDepartment.id, policy.activePosition.id, id)
      );
    }
  };

  const _renderPersonil = () => {
    if (policy.activeDepartment.index >= 0) {
      return policy.dataPersonal;
    }
    if (policy.activePosition.index >= 0) {
      return policy.personalByIdPosition;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return policy.dataPersonal;
    }
    return policy.allPersonal;
  };

  const _loadingPersonil = () => {
    if (policy.activeDepartment.index >= 0) {
      return policy.isLoadingPersonal;
    }
    if (policy.activePosition.index >= 0) {
      return policy.isLoadingPersonalByIdPosition;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return policy.isLoadingPersonal;
    }
    return policy.isLoadingAllPersonal;
  };

  const _renderPosition = () => {
    if (policy.activeDepartment.index < 0) {
      return policy.allPosition;
    }
    return policy.positionById;
  };

  const _renderHeaderName = () => {
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activePersonil.index >= 0
    ) {
      return policy.personilName;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activePersonil.index < 0
    ) {
      return policy.positionName;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index < 0 &&
      policy.activePersonil.index >= 0
    ) {
      return policy.personilName;
    }
    if (
      policy.activeDepartment.index < 0 &&
      policy.activePosition.index < 0 &&
      policy.activePersonil.index >= 0
    ) {
      return policy.personilName;
    }
    if (
      policy.activeDepartment.index < 0 &&
      policy.activePosition.index >= 0 &&
      policy.activePersonil.index >= 0
    ) {
      return policy.personilName;
    }
    if (policy.activeDepartment.index >= 0) {
      return policy.departementName;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return policy.positionName;
    }
    if (policy.activeDepartment.index < 0 && policy.activePosition.index >= 0) {
      return policy.positionName;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activePersonil.index >= 0
    ) {
      return policy.personilName;
    }
    return "company";
  };

  const SetTitle = ({ title }) => {
    const copywritings_title = copyWritings(
      title
        .replace(/[\s/-]/g, "_")
        .replace(/[().]/g, "")
        .toLocaleLowerCase()
    );
    const translate_title = t(
      `policy.${title
        .replace(/[\s/-]/g, "_")
        .replace(/[().]/g, "")
        .toLocaleLowerCase()}`
    );
    return copywritings_title || translate_title;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
      }}
    >
      <Card
        className={`${
          isShow ? "category-policy-card" : "category-policy-card-close"
        }`}
        style={{ height: isShow ? 395 : 100 }}
      >
        <Button
          onPress={() => setIsShow(!isShow)}
          className="category-policy-button-acordion"
        >
          {isShow ? (
            <div className="category-policy-header-title-wrap">
              <Text className="category-policy-header-title">
                <SetTitle title="department" />
              </Text>
              <Text className="category-policy-header-title">
                <SetTitle title="position" />
              </Text>
              <Text className="category-policy-header-title">
                <SetTitle title="employee" />
              </Text>
              <img
                style={{ width: 23, height: 23 }}
                src={IMAGES.upArrow}
                alt="up-arrow"
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              {/* <Text className="category-policy-card-close-text">
                {_renderHeaderName()}
              </Text> */}
              <Text className="category-policy-card-close-text">
                {_renderHeaderName().toLocaleLowerCase() === "company" ? (
                  <SetTitle title={_renderHeaderName()} />
                ) : (
                  _renderHeaderName()
                )}
              </Text>
              <img
                style={{ width: 23, height: 23, alignSelf: "center" }}
                src={IMAGES.downArrow}
                alt="down-arrow"
              />
            </div>
          )}
        </Button>
        {isShow && (
          <>
            <div className="category-policy-line" />
            <div className="category-policy-card-category">
              <div className="category-policy-card-category-wrap">
                <Container isLoading={policy.isLoadingDepartment}>
                  {policy.dataDepartment.map((item: any, index: number) => (
                    <Card
                      key={index}
                      className={`category-policy-card-category-content ${index ===
                        policy.activeDepartment.index &&
                        "category-policy-card-selected"}`}
                    >
                      <Button
                        onPress={() =>
                          _setActiveDepartment(
                            index,
                            item.id,
                            item.attributes.title
                          )
                        }
                      >
                        <Text
                          className={`${index ===
                            policy.activeDepartment.index &&
                            "category-policy-card-selected-text"}`}
                        >
                          {item.attributes.title}
                        </Text>
                      </Button>
                    </Card>
                  ))}
                </Container>
              </div>

              <div className="category-policy-card-category-wrap">
                <Container
                  isLoading={
                    policy.activeDepartment.index < 0
                      ? policy.isLoadingAllPosition
                      : policy.isloadingPositionByid
                  }
                >
                  {_renderPosition().map((item: any, index: number) => (
                    <Card
                      key={index}
                      className={`category-policy-card-category-content ${index ===
                        policy.activePosition.index &&
                        "category-policy-card-selected"}`}
                    >
                      <Button
                        onPress={() =>
                          _setActivePosition(
                            index,
                            policy.activeDepartment.id,
                            item.id,
                            item.attributes.title
                          )
                        }
                      >
                        <Text
                          className={`${index === policy.activePosition.index &&
                            "category-policy-card-selected-text"}`}
                        >
                          {`${item.attributes.title}`}
                        </Text>
                      </Button>
                    </Card>
                  ))}
                </Container>
              </div>

              <div className="category-policy-card-category-wrap">
                <Container isLoading={_loadingPersonil()}>
                  {_renderPersonil().map((item: any, index: number) => (
                    <Card
                      key={index}
                      className={`category-policy-card-category-content ${index ===
                        policy.activePersonil.index &&
                        "category-policy-card-selected"}`}
                    >
                      <Button
                        onPress={() =>
                          _setActivePersonil(
                            index,
                            item.attributes.name,
                            item.id
                          )
                        }
                      >
                        <Text
                          style={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            width: "90%"
                          }}
                          className={`${index === policy.activePersonil.index &&
                            "category-policy-card-selected-text"}`}
                        >
                          {item.attributes.name}
                        </Text>
                      </Button>
                    </Card>
                  ))}
                </Container>
              </div>
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default memo(CategoryPolicy);
