import React, { memo, useState } from "react";

import Modal from "../Modal";
import Toast from "../Toast";
import { maskedMoney } from "../../utils";
import "./styles.scss";

// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";

interface Props {
  titleModal?: string;
  onPressSubmit?: (data: any) => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: number | string;
  max_limit?: number | string;
  onChange?: (e: any) => void;
}

const ModalEditLimit = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  value,
  max_limit
}: Props) => {
  const [limit, setLimit] = useState(value);
  const [errorLimit, setErrorLimit] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const setdataLimit = __calback => {
    if (errorLimit) {
      Toast({ is_success: false, message: errorMessage });
      return;
    }
    __calback(limit);
  };

  const maxLimit = max_limit || 0;
  const editdata = e => {
    setLimit(e.target.value);
    if (Number(e.target.value) > Number(maxLimit)) {
      setErrorLimit(true);
      setErrorMessage(
        `Overlimit !! \n(maximum limit is setted ${maskedMoney(
          maxLimit
        )} by implementer)`
      );
    } else {
      setErrorLimit(false);
      setErrorMessage("");
    }
  };

  const { t } = useTranslation();
  return (
    <Modal
      titleModal={t("EDIT LIMIT")}
      isOpen={isOpen}
      onPressCancel={onPressCancel}
      onPressSubmit={() => setdataLimit(onPressSubmit)}
      btnSubmitText={t("master_data.edit").toUpperCase()}
      btnCancelText={t("deleteData.cancel").toUpperCase()}
    >
      <div style={{ width: "100%" }}>
        <input
          onChange={editdata}
          value={limit}
          type="number"
          style={{
            border: "1px solid #ddd",
            width: "100%",
            height: 40,
            borderRadius: "3px",
            marginTop: 30,
            textAlign: "center"
          }}
        />

        {errorMessage.split("\n").map(__val__ => (
          <p style={{ textAlign: "center", color: "red" }}>{__val__}</p>
        ))}
      </div>
    </Modal>
  );
};

export default memo(ModalEditLimit);
