import React, { memo } from "react";
import { autoGrowTextArea } from "../../utils";

import Modal from "../Modal";

import "./styles.scss";

interface Props {
  onPressSubmit?: () => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: string;
  onChange?: (e: any) => void;
}

const ModalAddRemarks = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  value,
  onChange
}: Props) => {
  return (
    <Modal
      titleModal="Add Remarks"
      isOpen={isOpen}
      onPressCancel={onPressCancel}
      onPressSubmit={onPressSubmit}
    >
      <form
        className="form-remarks"
        onSubmit={e => {
          e.preventDefault();
          onPressSubmit && onPressSubmit();
        }}
      >
        <div style={{ width: 480 }}>
          <textarea
            className="margin-top-52 text-area-remark"
            placeholder="Briefly state your remarks"
            value={value}
            onChange={onChange}
            ref={e => {
              setTimeout(() => e && e.focus(), 100);
            }}
            onInput={autoGrowTextArea}
          />
          <input type="submit" hidden />
        </div>
      </form>
    </Modal>
  );
};

export default memo(ModalAddRemarks);
