import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../Button";

const AddButton = props => {
  // eslint-disable-next-line react/prop-types
  const { _action } = props;
  const { t } = useTranslation();
  return (
    <>
      <Button
        {...{
          // eslint-disable-next-line react/destructuring-assignment
          onClick: _action,
          type: "button",
          text: `${t("master_data.add")}`,
          style: {
            marginTop: 50,
            marginBottom: 50,
            background: "linear-gradient(#faaf40, #f05a28, #db3a26)",
            width: "5em",
            height: "2.8em",
            color: "white",
            fotWeight: "bold",
            borderRadius: 3,
            cursor: "pointer"
          }
        }}
      />
    </>
  );
};

export default AddButton;
