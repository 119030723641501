import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_TABLE_REPORT_SUCCESS = "GET_TABLE_REPORT_SUCCESS";
export const GET_TABLE_REPORT_PENDING = "GET_TABLE_REPORT_PENDING";
export const GET_TABLE_REPORT_ERROR = "GET_TABLE_REPORT_ERROR";

export const SET_FORM_FILTER_WIDGET_REPORT = "SET_FORM_FILTER_WIDGET_REPORT";
export const SET_ACTIVE_RANGE_FILTER_REPORT = "SET_ACTIVE_RANGE_FILTER_REPORT";

export const DOWNLOAD_XML_REPORT = "DOWNLOAD_XML_REPORT";

export const DOWNLOAD_CSV_REPORT = "DOWNLOAD_CSV_REPORT";

export const getReportTable = (page: number) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { report } = getState();
    dispatch({ type: GET_TABLE_REPORT_PENDING });

    const from = `${report.form.from.years}-${report.form.from.months}`;
    const to = `${report.form.to.years}-${report.form.to.months}`;
    const res = await API.getReport(from, to, page);
    dispatch({
      type: GET_TABLE_REPORT_SUCCESS,
      payload: { data: res.data.data, page: res.data.meta.pagination }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TABLE_REPORT_ERROR });
  }
};

export const handleLoadingFilterReport = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_TABLE_REPORT_PENDING });
};

export const setFormFilterReport = (form: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FORM_FILTER_WIDGET_REPORT,
    payload: { data: form }
  });
};

export const setActiveRangeFilterWidgetReport = (index: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ACTIVE_RANGE_FILTER_REPORT,
    payload: { data: index }
  });
};

export const downloadReportXML = () => async (dispatch: Dispatch) => {
  const res = await API.downloadXML();
  dispatch({ type: DOWNLOAD_XML_REPORT, payload: { data: res } });
};

export const downloadReportCSV = () => async (dispatch: Dispatch) => {
  const res = await API.downloadCSV();
  dispatch({ type: DOWNLOAD_CSV_REPORT, payload: { data: res } });
};
