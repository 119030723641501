import {
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_PENDING,
  CHANGE_PASSWORD_SUCCESS,
  GET_MY_PERMISSION_ERROR,
  GET_MY_PERMISSION_PENDING,
  GET_MY_PERMISSION_SUCCESS,
  GET_POLICY_OWNED_ERROR,
  GET_POLICY_OWNED_PENDING,
  GET_POLICY_OWNED_SUCCESS,
  GET_SETTING_ERROR,
  GET_SETTING_PENDING,
  GET_SETTING_SUCCESS,
  HANDLE_LOGOUT_ERROR,
  HANDLE_LOGOUT_PENDING,
  HANDLE_LOGOUT_SUCCESS
} from "../actions";
import { Action, AuthState } from "../types";

const initialState: AuthState = {
  isLoading: false,
  changePassword: {
    isLoading: false,
    message: ""
  },
  myPermission: {
    isLoading: false,
    list: []
  },
  setting: {
    isLoading: false,
    list: [{ value_object: {} }]
  },
  policyOwned: {
    isLoading: false,
    list: []
  }
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // handle logout
    case HANDLE_LOGOUT_PENDING:
      return { ...state, isLoading: true };
    case HANDLE_LOGOUT_SUCCESS:
      return { ...state, isLoading: false };
    case HANDLE_LOGOUT_ERROR:
      return { ...state, isLoading: false };

    case CHANGE_PASSWORD_PENDING:
      return { ...state, changePassword: { isLoading: true, message: "" } };
    case CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePassword: { isLoading: false, message: "" } };
    case CHANGE_PASSWORD_ERROR:
      return {
        ...state,
        changePassword: { isLoading: false, message: payload.data }
      };

    case GET_MY_PERMISSION_PENDING:
      return {
        ...state,
        myPermission: { ...state.myPermission, isLoading: true }
      };
    case GET_MY_PERMISSION_SUCCESS:
      return {
        ...state,
        myPermission: {
          ...state.myPermission,
          isLoading: false,
          list: payload.data
        }
      };
    case GET_MY_PERMISSION_ERROR:
      return {
        ...state,
        myPermission: { ...state.myPermission, isLoading: false }
      };

    case GET_SETTING_PENDING:
      return {
        ...state,
        setting: { ...state.setting, isLoading: true }
      };
    case GET_SETTING_SUCCESS:
      return {
        ...state,
        setting: {
          ...state.setting,
          isLoading: false,
          list: payload.data.filter((el: any) => el.id === "feature-dictionary")
        }
      };
    case GET_SETTING_ERROR:
      return {
        ...state,
        setting: { ...state.setting, isLoading: false }
      };

    case GET_POLICY_OWNED_PENDING:
      return {
        ...state,
        policyOwned: { ...state.policyOwned, isLoading: true }
      };
    case GET_POLICY_OWNED_SUCCESS:
      return {
        ...state,
        policyOwned: {
          ...state.policyOwned,
          isLoading: false,
          list: payload.data
        }
      };
    case GET_POLICY_OWNED_ERROR:
      return {
        ...state,
        policyOwned: { ...state.policyOwned, isLoading: false }
      };

    default:
      return state;
  }
};
