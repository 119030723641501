import React, { ReactNode, memo } from "react";

import "./styles.scss";

interface Props {
  children?: ReactNode;
  label?: string;
  isPrimary?: boolean;
  onChangePrimary?: (e?: any) => void;
  onChangeDelete?: (e?: any) => void;
}

const CardInput = ({
  label,
  children,
  onChangeDelete,
  onChangePrimary,
  isPrimary
}: Props) => (
  <div
    className="components-employee-cardinput-container global-shadow-card"
    style={{ marginBottom: 15 }}
  >
    <div className="components-employee-cardinput-container-label">
      <div className="components-employee-cardinput-label">{label}</div>
      <div className="components-employee-cardinput-button-container">
        <div
          className={`components-employee-cardinput-button ${
            isPrimary ? "disabled-delete" : "active-delete"
          }`}
          onClick={isPrimary ? undefined : onChangeDelete}
        >
          DELETE
        </div>
        <div
          className={`components-employee-cardinput-button ${
            isPrimary ? "primary" : "not-primary"
          }`}
          onClick={isPrimary ? undefined : onChangePrimary}
        >
          {isPrimary ? "PRIMARY" : "MAKE PRIMARY"}
        </div>
      </div>
    </div>
    {children}
  </div>
);

export default memo(CardInput);
