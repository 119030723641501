/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Switch from "react-switch";
import { IMAGES } from "../../../../../configs";
import {
  Button,
  Container,
  Modal,
  ModalAnnouncementUserPicker,
  ModalSubmitForm
} from "../../../../../components";
import { Reducers } from "../../../../../redux/types";
import {
  getAnnouncementList,
  getAnnouncementParticipants,
  resetAnnouncementParticipants,
  setModalAnnouncementParticipants,
  setModalEditAnnouncement,
  updateAnnouncement
} from "../../../../../redux/actions";

import "./styles.scss";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  announcementId: string;
}

const ModalEditAnnouncement = ({ isOpen, onCancel, announcementId }: Props) => {
  const { register, handleSubmit, errors, reset, watch, setValue } = useForm();

  const dispatch = useDispatch();
  const initialBody = {
    sendToAll: true,
    saveAsDraft: false
  };
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const initialSubmitForm = {
    form: null,
    message: ""
  };

  const [body, setBody] = useState(initialBody);
  const [isLoadingData, setLoadingData] = useState(true);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [searchUser, setSearchUser] = useState("");
  const [listParticipant, setParticipant] = useState("");
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);

  const { announcementState } = useSelector(
    (state: Reducers) => ({
      setModal: state.company.setModalEditAnnouncement,
      announcementState: state.company
    }),
    shallowEqual
  );

  useEffect(() => {
    setLoadingData(true);
    setValue(
      "started_at",
      moment(
        announcementState.announcementById &&
          announcementState.announcementById.started_at
      ).format("YYYY-MM-DD")
    );
    setValue(
      "finished_at",
      moment(
        announcementState.announcementById &&
          announcementState.announcementById.finished_at
      ).format("YYYY-MM-DD")
    );
    setValue(
      "title",
      announcementState.announcementById &&
        announcementState.announcementById.title
    );
    setValue(
      "description",
      announcementState.announcementById &&
        announcementState.announcementById.description
    );
    setTimeout(() => {
      setLoadingData(false);
    }, 300);
    dispatch(getAnnouncementParticipants());
    announcementState.announcementById &&
    announcementState.announcementById.employee &&
    announcementState.announcementById.employee.length > 0
      ? (body.sendToAll = false)
      : (body.sendToAll = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, announcementState.announcementById, setValue]);
  useEffect(() => {
    setSearchUser("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementState.setModalAnnouncementParticipants]);

  const _setAnnouncementParticipantList = useCallback(() => {
    dispatch(setModalAnnouncementParticipants(true));
    dispatch(getAnnouncementParticipants());
  }, [dispatch]);

  const _getIdParticipant = useCallback(() => {
    const result = [] as any;
    if (
      announcementState.saveAnnouncementParticipantList &&
      announcementState.saveAnnouncementParticipantList.length > 0
    ) {
      announcementState.saveAnnouncementParticipantList.map(el =>
        result.push(el.id)
      );
    } else if (
      !body.saveAsDraft &&
      announcementState.saveAnnouncementParticipantList &&
      announcementState.saveAnnouncementParticipantList.length > 0
    ) {
      announcementState.saveAnnouncementParticipantList.map(el =>
        result.push(el.id)
      );
    }
    setParticipant(result.join(","));
    return true;
  }, [announcementState.saveAnnouncementParticipantList, body.saveAsDraft]);
  useEffect(() => {
    _getIdParticipant();
  }, [_getIdParticipant]);

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body,
      participants: _getIdParticipant()
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let participants: any = [];
    announcementState.saveAnnouncementParticipantList.map(e => {
      participants = [...participants, e.name];
      return true;
    });

    const result: any = [
      { title: t("company.form.subject"), value: tempData[2].value || "-" },
      {
        title: t("task.form.participants"),
        value: participants.length > 0 ? participants : t("all")
      },
      {
        title: t("task.form.startDate"),
        value: moment(tempData[0].value).format("DD MMM YYYY") || "-"
      },
      {
        title: t("task.detail.finishDate"),
        value: moment(tempData[1].value).format("DD MMM YYYY") || "-"
      },
      { title: t("company.tab.announcement"), value: tempData[3].value || "-" }
    ];

    if (result) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body
    };
    const participants = listParticipant;
    setSubmitForm({ ...submitForm, form });
    dispatch(
      updateAnnouncement(announcementId, form, participants, () => {
        dispatch(resetAnnouncementParticipants());
        onCancel();
        reset();
        dispatch(setModalEditAnnouncement(false));
        setModalSubmitForm(false);
        dispatch(getAnnouncementList());
        setBody(initialBody);
      })
    );
  };

  return (
    <Container>
      <Modal
        titleModal={t("company.form.editAnnouncement")}
        isOpen={isOpen}
        onPressCancel={() => {
          reset();
          onCancel();
          body.saveAsDraft = false;
          dispatch(resetAnnouncementParticipants());
        }}
        onPressSubmit={
          body.saveAsDraft
            ? handleSubmit(_onSave)
            : handleSubmit(_onConfirmSubmitForm)
        }
        btnSubmitText={!body.saveAsDraft ? t("btn.submit") : t("btn.save")}
        btnCancelText={t("btn.cancel")}
        loading={isLoadingData}
      >
        <form className="margin-top-12">
          <div style={{ width: 494 }}>
            <span className="component-modal-assign-announcement-label">
              {t("company.form.announceTo")}
            </span>
            <div className="announcement-wrap-switch">
              <span
                className={`component-modal-assign-announcement-label switch ${!body.sendToAll &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("all")}
              </span>
              <Switch
                onChange={() =>
                  setBody({ ...body, sendToAll: !body.sendToAll })
                }
                checked={!body.sendToAll}
                onColor="#ff7600"
                offColor="#ff7600"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() =>
                  setBody({ ...body, sendToAll: !body.sendToAll })
                }
              />
              <span
                className={`component-modal-assign-announcement-label switch ${body.sendToAll &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("company.form.selectedEmployee")}
              </span>
            </div>
            {announcementState.announcementById &&
            announcementState.announcementById.employee &&
            !body.sendToAll ? (
              <div className="modal-assign-person-container margin-bottom-12">
                {announcementState.saveAnnouncementParticipantList &&
                announcementState.saveAnnouncementParticipantList.length > 0 ? (
                  announcementState.saveAnnouncementParticipantList.map(
                    (user, index) => (
                      <div
                        className="modal-assign-person margin-right-8 margin-top-8"
                        key={index}
                      >
                        <img
                          src={user.avatar ? user.avatar.url : IMAGES.avatar}
                          alt="n/a"
                          className="modal-assign-person-photo"
                        />
                        <div>
                          {`(${user.position_name}) `}
                          <span>{`${user.name}`}</span>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <></>
                )}
                <Button
                  onPress={() => _setAnnouncementParticipantList()}
                  className="margin-top-8"
                >
                  <div className="component-modal-assign-announcement-btn-add-person">
                    <img
                      className="is-focus"
                      style={{ height: 50, width: 50 }}
                      src={IMAGES.floatingPlusButton}
                      alt="create"
                      tabIndex={0}
                    />
                    <span className="component-modal-assign-announcement-btn-add-person-text">
                      {t("task.form.addPerson")}
                    </span>
                  </div>
                </Button>
              </div>
            ) : (
              <div className="modal-assign-person-container margin-bottom-12">
                {!body.sendToAll && (
                  <Button
                    onPress={() => _setAnnouncementParticipantList()}
                    className="margin-top-8"
                  >
                    <div className="component-modal-assign-announcement-btn-add-person">
                      <img
                        className="is-focus"
                        style={{ height: 50, width: 50 }}
                        src={IMAGES.floatingPlusButton}
                        alt="create"
                        tabIndex={0}
                      />
                      <span className="component-modal-assign-announcement-btn-add-person-text">
                        {t("task.form.addPerson")}
                      </span>
                    </div>
                  </Button>
                )}
              </div>
            )}
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-announcement-label">
                  {t("personnel.form.startDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="started_at"
                    ref={register({
                      required: sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("personnel.form.startDate")
                      )
                    })}
                    min={moment().format("YYYY-MM-DD")}
                    max={watch("finished_at")}
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="started_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-announcement-label">
                  {t("company.form.endDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="finished_at"
                    ref={register({
                      required: sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("company.form.endDate")
                      )
                    })}
                    min={watch("started_at") || moment().format("YYYY-MM-DD")}
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="finished_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label">
                {t("company.form.subject")}
              </span>
              <input
                name="title"
                type="text"
                className="component-modal-input margin-top-12"
                placeholder={t("company.form.subject")}
                ref={register({
                  required: sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("company.form.subject")
                  )
                })}
              />
              <ErrorMessage
                errors={errors}
                name="title"
                as="p"
                style={{ color: "red" }}
              />
            </div>
            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label">
                {t("company.tab.announcement")}
              </span>
              <div className="global-shadow-card component-modal-assign-announcement-card-desc margin-top-12">
                <textarea
                  name="description"
                  className="component-modal-assign-announcement-text-area"
                  ref={register({
                    required: sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("company.tab.announcement")
                    )
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  className="alert"
                  name="description"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label ">
                {t("company.form.saveDraft")}
              </span>
            </div>
            <div className="announcement-wrap-switch">
              <span
                className={`component-modal-assign-announcement-label switch ${body.saveAsDraft &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("no")}
              </span>
              <Switch
                onChange={() =>
                  setBody({ ...body, saveAsDraft: !body.saveAsDraft })
                }
                checked={body.saveAsDraft}
                onColor="#ff7600"
                offColor="#888888"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() =>
                  setBody({ ...body, saveAsDraft: !body.saveAsDraft })
                }
              />
              <span
                className={`component-modal-assign-announcement-label switch ${!body.saveAsDraft &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("yes")}
              </span>
            </div>
          </div>
        </form>
      </Modal>
      <ModalAnnouncementUserPicker getData value={searchUser} />
      <ModalSubmitForm
        isLoading={announcementState.isLoadingUpdateAnnouncement}
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={handleSubmit(_onSave)}
        onCancel={() => setModalSubmitForm(false)}
      />
    </Container>
  );
};

export default memo(ModalEditAnnouncement);
