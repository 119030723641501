import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Switch from "react-switch";
import { Slide, toast } from "react-toastify";
import { IMAGES } from "../../../../configs";
import {
  FileUpload,
  Modal,
  ModalSubmitForm,
  Upload
} from "../../../../components";
import { Reducers } from "../../../../redux/types";

import {
  getContractsByEmployee,
  postAddContract,
  putContract,
  resetDetailContract,
  setModalAddContract,
  setModalEditContract
} from "../../../../redux/actions";

import { openNewTab } from "../../../../utils";

import "./styles.scss";

interface Props {
  employee: string;
  contractId?: string;
  isUpdate: boolean;
}

const ModalFormContract = ({ employee, isUpdate }: Props) => {
  const { register, handleSubmit, errors, reset, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialSubmitForm = {
    form: null,
    message: ""
  };
  const initialBody = {
    employee_id: employee,
    active: true
  };

  const [body, setBody] = useState(initialBody);
  const [isLoadingData, setLoadingData] = useState(true);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);

  const [file, setFile]: any = useState([]);
  const [listAttachment, setListAttachment]: any = useState([]);
  const [listDeleteFile, setListDeleteFile]: any = useState([]);

  const {
    setModal,
    formLoading,
    formLoadingEdit,
    staffState,
    detailContract
  } = useSelector(
    (state: Reducers) => ({
      setModal: state.employee,
      formLoading: state.employee.isLoadingAddContract,
      formLoadingEdit: state.employee.isLoadingEditContract,
      staffState: state.employee.staff.data,
      detailContract: state.employee.staff.detailContract
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      setModal.setModalEditContract &&
      detailContract &&
      detailContract.attributes &&
      detailContract.attributes.attachment &&
      detailContract.attributes.attachment.length
    ) {
      setListAttachment(detailContract.attributes.attachment);
    }
  }, [setModal.setModalEditContract, detailContract]);

  useEffect(() => {
    setLoadingData(true);
    setValue(
      "started_at",
      moment(
        detailContract.attributes && detailContract.attributes.started_at
      ).format("YYYY-MM-DD")
    );
    setValue(
      "finished_at",
      moment(
        detailContract.attributes && detailContract.attributes.finished_at
      ).format("YYYY-MM-DD")
    );
    setValue(
      "number",
      detailContract.attributes && detailContract.attributes.number
    );
    setValue(
      "remarks",
      detailContract.attributes && detailContract.attributes.remarks
    );
    setTimeout(() => {
      setLoadingData(false);
    }, 300);
    detailContract.attributes && detailContract.attributes.active === true
      ? (body.active = true)
      : (body.active = false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdate, detailContract, setValue, listAttachment]);

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let files: any = [];
    if (file.length > 0) {
      file.map((e: any) => {
        files = [...files, e.name];
        return true;
      });
    }

    const result: any = [
      { title: `${t("personnel.form.employeeName")}:`, value: staffState.name },
      {
        title: `${t("personnel.form.contractNo")}:`,
        value: tempData[0].value || "-"
      },
      {
        title: `${t("task.form.startDate")}:`,
        value: moment(tempData[1].value).format("DD MMM YYYY") || "-"
      },
      {
        title:
          tempData[2].title === "finished_at"
            ? `${t("personnel.form.finishDate")}:`
            : `${t("personnel.form.finishDate")}:`,
        value:
          tempData[2].title === "finished_at"
            ? moment(tempData[2].value).format("DD MMM YYYY")
            : "-"
      },
      {
        title:
          tempData[2].title === "remarks"
            ? `${t("personnel.form.remarks")}:`
            : "",
        value: tempData[2].title === "remarks" ? tempData[2].value : ""
      },
      {
        title:
          tempData[3] && tempData[3].title === "remarks"
            ? `${t("personnel.form.remarks")}:`
            : "",
        value:
          tempData[3] && tempData[3].title === "remarks"
            ? tempData[3].value
            : ""
      },
      {
        title: `${t("personnel.form.isactive")}:`,
        value: body.active ? t("yes") : t("no")
      },
      {
        title: t("task.form.attachment"),
        value: files.length > 0 ? files : "-"
      }
    ];
    if (result) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    }
  };

  const UploadDocument = async (e: any) => {
    const { files } = e.target;
    Array.from(files).map((item: any) => {
      item.id = Math.random()
        .toString(36)
        .substring(7);
      const result = file.findIndex((ed: any) => ed.name === item.name);
      if (result < 0) {
        setFile((currentArray: any) => [...currentArray, item]);
      } else {
        toast(t("task.form.message.duplicateAttachment"), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      }
      return true;
    });
  };

  const deleteFile = (id: any) => {
    const newFile = file.filter((el: any) => el.id !== id);
    setFile(newFile);
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file
    };
    const message = "";

    setSubmitForm({ ...submitForm, form, message });
    dispatch(
      postAddContract(form, () => {
        dispatch(setModalAddContract(false));
        dispatch(getContractsByEmployee(employee));
        setModalSubmitForm(false);
        reset();
        setFile([]);
        setListDeleteFile([]);
        setBody(initialBody);
      })
    );
  };

  const dropFile = (index: any, id: any) => {
    listAttachment.splice(index, 1);
    const new_data = [...listAttachment];
    setListAttachment(new_data);

    listDeleteFile.push(id);
    const data_delete = [...listDeleteFile];
    setListDeleteFile(data_delete);
  };

  const _onEdit = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      delete_file: listDeleteFile
    };
    const message = "";

    setSubmitForm({ ...submitForm, form, message });
    dispatch(
      putContract(form, detailContract.id, () => {
        dispatch(setModalEditContract(false));
        dispatch(getContractsByEmployee(employee));
        setModalSubmitForm(false);
        reset();
        setFile([]);
        setListDeleteFile([]);
        setBody(initialBody);
      })
    );
  };

  const _onCancel = () => {
    reset();
    setFile([]);
    setListDeleteFile([]);
    setBody(initialBody);
    dispatch(setModalAddContract(false));
    dispatch(setModalEditContract(false));
    if (setModal.setModalEditContract) {
      dispatch(resetDetailContract());
    }
  };

  return (
    <>
      <Modal
        loading={setModal.setModalAddContract ? formLoading : isLoadingData}
        titleModal={
          setModal.setModalAddContract
            ? t("personnel.form.newContractTitle")
            : t("personnel.form.editContractTitle")
        }
        isOpen={setModal.setModalAddContract || setModal.setModalEditContract}
        onPressCancel={_onCancel}
        onPressSubmit={handleSubmit(_onConfirmSubmitForm)}
        btnSubmitText={
          setModal.setModalAddContract ? t("btn.submit") : t("btn.edit")
        }
        btnCancelText={t("btn.cancel")}
      >
        <form
          className="margin-top-12"
          onSubmit={
            setModal.setModalAddContract
              ? handleSubmit(_onSave)
              : handleSubmit(_onEdit)
          }
        >
          <div style={{ width: 494 }}>
            <div className="margin-top-20">
              {setModal.setModalEditContract && (
                <>
                  <span className="component-modal-contract-form-label">
                    {t("personnel.form.employeeName")}
                  </span>
                  <div className="modal-assign-person-container margin-bottom-12">
                    <div className="modal-assign-person margin-right-8 margin-top-8">
                      <img
                        src={
                          staffState && staffState.avatar
                            ? staffState.avatar && staffState.avatar.url
                            : IMAGES.avatar
                        }
                        alt="n/a"
                        className="modal-assign-person-photo"
                      />
                      <div>
                        {`(${staffState.position &&
                          staffState.position.title}) `}
                        <span>{`${staffState.name}`}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label">
                {t("personnel.form.contractNo")}
              </span>
              <input
                name="number"
                type="text"
                className="component-modal-input margin-top-12"
                placeholder="Contract Number"
                ref={register({ required: "Contract number is required" })}
                maxLength={200}
              />
              <ErrorMessage
                errors={errors}
                name="number"
                as="p"
                style={{ color: "red" }}
              />
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-contract-form-label">
                  {t("task.form.startDate")}
                </span>
                <input
                  placeholder="YYYY/MM/DD"
                  type="date"
                  style={{ color: "#666666" }}
                  name="started_at"
                  ref={register({ required: "Start date is required" })}
                  max={watch("finished_at")}
                />
                <div className="component-modal-line" />
                <ErrorMessage
                  errors={errors}
                  name="started_at"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-contract-form-label">
                  {t("personnel.form.finishDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="finished_at"
                    min={watch("started_at")}
                    ref={register()}
                    defaultValue=""
                  />
                  <div className="component-modal-line" />
                </div>
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label ">
                {t("personnel.form.isactive")}
              </span>
            </div>
            <div className="form-wrap-switch">
              <span
                className={`component-modal-contract-form-label switch ${body.active &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("no")}
              </span>
              <Switch
                onChange={() => setBody({ ...body, active: !body.active })}
                checked={body.active}
                onColor="#ff7600"
                offColor="#888888"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() => setBody({ ...body, active: !body.active })}
              />
              <span
                className={`component-modal-contract-form-label switch ${!body.active &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("yes")}
              </span>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label">
                {t("personnel.form.remarks")}
              </span>
              <div className="global-shadow-card component-modal-contract-form-remarks margin-top-12">
                <textarea
                  name="remarks"
                  className="component-modal-contract-form-text-area"
                  ref={register()}
                />
              </div>
            </div>

            <div className="margin-top-20">
              <div className="component-modal-assign-task-label">
                {t("task.form.attachment")}
              </div>
              <div className="assign-task-upload">
                {file.length > 0 &&
                  file.map((el: any, i: number) => {
                    const ext = el.name.split(".").pop();
                    return (
                      <FileUpload
                        key={i}
                        name={`${el.name}`}
                        style={{
                          marginTop: 6,
                          marginBottom: 6,
                          marginRight: 8
                        }}
                        deleteFile={e => {
                          e.stopPropagation();
                          deleteFile(el.id);
                        }}
                        type={
                          ext === "png" || ext === "jpg" || ext === "jpeg"
                            ? "image"
                            : "text"
                        }
                        linkImage={URL.createObjectURL(el)}
                        onPress={() => openNewTab(URL.createObjectURL(el))}
                      />
                    );
                  })}

                {setModal.setModalEditContract && listAttachment.length > 0
                  ? listAttachment.map((el: any, i: number) => {
                      return (
                        <>
                          <FileUpload
                            key={i}
                            name={el.title}
                            style={{
                              marginTop: 6,
                              marginBottom: 6,
                              marginRight: 8
                            }}
                            deleteFile={e => {
                              e.stopPropagation();
                              dropFile(i, el.id);
                            }}
                            type={
                              el.extension === "png" ||
                              el.extension === "jpg" ||
                              el.extension === "jpeg"
                                ? "image"
                                : "text"
                            }
                            linkImage={el.url}
                            onPress={() => openNewTab(el.url)}
                          />
                          <p>
                            &nbsp;
                            {/* {`${el.title}`} */}
                          </p>
                        </>
                      );
                    })
                  : ""}

                <Upload
                  name="attchmen"
                  tabIndex={0}
                  multiple
                  onChange={(e: any) => UploadDocument(e)}
                  textBtn={t("btn.attachmentButton")}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ModalSubmitForm
        isLoading={setModal.setModalAddContract ? formLoading : formLoadingEdit}
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={
          setModal.setModalAddContract
            ? handleSubmit(_onSave)
            : handleSubmit(_onEdit)
        }
        onCancel={() => setModalSubmitForm(false)}
      />
    </>
  );
};

export default memo(ModalFormContract);
