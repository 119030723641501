import React, { memo } from "react";

import { Container } from "..";
// import { ICONS } from "../../configs";
// import Container from "../ContainerMF/ContainerMF";

interface Buttons {
  label: string;
  onClick?: () => void;
  className?: "alt" | "small";
  isLoading?: boolean;
  isDisable?: boolean;
}

interface Props {
  isButton?: boolean;
  onPressSave?: () => void;
  buttons?: Buttons[] | undefined | null | false;
  date?: string;
  by?: string;
  previewButton?: () => void;
}

const Footer = ({
  isButton,
  onPressSave,
  buttons,
  date,
  by,
  previewButton
}: Props) => {
  return (
    <Container className="button-footer" flex withMaxWidth>
      {/* <div className="action-buttons">
        <Button>
          <img src={ICONS.btnMessage} alt="print" />
        </Button>
        <Button>
          <img title="Download XML" src={ICONS.btnCopy} alt="copy" />
        </Button>
        <Button>
          <img title="Download CSV" src={ICONS.btnExcel} alt="message" />
        </Button>
        <Button>
          <img src={ICONS.btnPrint} alt="print" />
        </Button>
      </div> */}

      {date && (
        <>
          <div style={{ fontSize: 12 }}>Last Updated:</div>
          <div style={{ fontWeight: "bold", padding: "0 5px", fontSize: 12 }}>
            {date}
          </div>
        </>
      )}
      {by && (
        <>
          <div style={{ padding: "0 5px 0 0", fontSize: 12 }}> by </div>
          <div style={{ fontWeight: "bold", fontSize: 12 }}>{by}</div>
        </>
      )}

      {buttons &&
        buttons.map((item: any, index: number) => (
          <button
            className={`button shadow ${item.className}`}
            onClick={item.onClick}
            key={index}
            type="button"
            disabled={item.isLoading || item.isDisable}
          >
            {item.label}
          </button>
        ))}
      {previewButton && previewButton()}
      {/* <div style={{ backgroundColor: "green", height: 100, width: 100 }} /> */}

      {isButton ? (
        <div className="buttons">
          <button className="button alt shadow" type="button">
            REVERT CHANGES
          </button>
          <button type="button" className="button shadow" onClick={onPressSave}>
            SAVE
          </button>
        </div>
      ) : (
        <></>
      )}
    </Container>
  );
};

export default memo(Footer);
