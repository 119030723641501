import { Slide, toast } from "react-toastify";

import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_COMPANY_DETAIL_PENDING = "GET_COMPANY_DETAIL_PENDING";
export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_ERROR = "GET_COMPANY_DETAIL_ERROR";

export const POST_COMPANY = "POST_COMPANY";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const PRIMARY_ADDRESS = "PRIMARY_ADDRESS";
export const PRIMARY_ACCOUNT = "PRIMARY_ACCOUNT";

export const DELETE_ACCOUNT_COMPANY_PENDING = "DELETE_ACCOUNT_COMPANY_PENDING";
export const DELETE_ACCOUNT_COMPANY_SUCCESS = "DELETE_ACCOUNT_COMPANY_SUCCESS";
export const DELETE_ACCOUNT_COMPANY_ERROR = "DELETE_ACCOUNT_COMPANY_ERROR";

export const DEPARTMENT_PENDING = "DEPARTMENT_PENDING";
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_ERROR = "DEPARTMENT_ERROR";

export const DEPARTMENT_ADD_PENDING = "DEPARTMENT_ADD_PENDING";
export const DEPARTMENT_ADD_SUCCESS = "DEPARTMENT_ADD_SUCCESS";
export const DEPARTMENT_ADD_ERROR = "DEPARTMNET_ADD_ERROR";

export const CLASS_DIVISION_PENDING = "CLASS_DIVISION_PENDING";
export const CLASS_DIVISION_SUCCESS = "CLASS_DIVISION_SUCCESS";
export const CLASS_DIVISION_ERROR = "CLASS_DIVISION_ERROR";

export const GET_ROLE_PENDING = "GET_ROLE_PENDING";
export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS";
export const GET_ROLE_ERROR = "GET_ROLE_ERROR";

export const GET_DEPARTMENT_POSITION_PENDING =
  "GET_DEPARTMENT_POSITION_PENDING";
export const GET_DEPARTMENT_POSITION_SUCCESS =
  "GET_DEPARTMENT_POSITION_SUCCESS";
export const GET_DEPARTMENT_POSITION_ERROR = "GET_DEPARTMENT_POSITION_ERROR";

export const GET_ROLE_POSITION_PENDING = "GET_ROLE_POSITION_PENDING";
export const GET_ROLE_POSITION_SUCCESS = "GET_ROLE_POSITION_SUCCESS";
export const GET_ROLE_POSITION_ERROR = "GET_ROLE_POSITION_ERROR";

export const ADD_POSITION_PENDING = "ADD_POSITION_PENDING";
export const ADD_POSITION_SUCCESS = "ADD_POSITION_SUCCESS";
export const ADD_POSITION_ERROR = "ADD_POSITION_ERROR";

export const SET_IMAGE_COMPANY = "SET_IMAGE_COMPANY";

export const POST_COMPANIES_PENDING = "POST_COMPANIES_PENDING";
export const POST_COMPANIES_SUCCESS = "POST_COMPANIES_SUCCESS";
export const POST_COMPANIES_ERROR = "POST_COMPANIES_ERROR";

export const SET_PRIMARY_BANK_PENDING = "SET_PRIMARY_BANK_PENDING";
export const SET_PRIMARY_BANK_SUCCESS = "SET_PRIMARY_BANK_SUCCESS";
export const SET_PRIMARY_BANK_ERROR = "SET_PRIMARY_BANK_ERROR";

export const DELETE_POSITION_PENDING = "DELETE_POSITION_PENDING";
export const DELETE_POSITION_SUCCESS = "DELETE_POSITION_SUCCESS";
export const DELETE_POSITION_ERROR = "DELETE_POSITION_ERROR";

export const UPDATE_POSITION_PENDING = "UPDATE_POSITION_PENDING";
export const UPDATE_POSITION_SUCCESS = "UPDATE_POSITION_SUCCESS";
export const UPDATE_POSITION_ERROR = "UPDATE_POSITION_ERROR";

export const GET_DETAIL_POSITION_PENDING = "GET_DETAIL_POSITION_PENDING";
export const GET_DETAIL_POSITION_SUCCESS = "GET_DETAIL_POSITION_SUCCESS";
export const GET_DETAIL_POSITION_ERROR = "GET_DETAIL_POSITION_ERROR";

export const ADD_DEPARTMENT_PENDING = "ADD_DEPARTMENT_PENDING";
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_ERROR = "ADD_DEPARTMENT_ERROR";

export const GET_DETAIL_DEPARTMENT_PENDING = "GET_DETAIL_DEPARTMENT_PENDING";
export const GET_DETAIL_DEPARTMENT_SUCCESS = "GET_DETAIL_DEPARTMENT_SUCCESS";
export const GET_DETAIL_DEPARTMENT_ERROR = "GET_DETAIL_DEPARTMENT_ERROR";

export const UPDATE_DEPARTMENT_PENDING = "UPDATE_DEPARTMENT_PENDING";
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_ERROR = "UPDATE_DEPARTMENT_ERROR";

export const DELETE_DEPARTMENT_PENDING = "DELETE_DEPARTMENT_PENDING";
export const DELETE_DEPARTMENT_SUCCESS = "DELETE_DEPARTMENT_SUCCESS";
export const DELETE_DEPARTMENT_MANDATORY = "DELETE_DEPARTMENT_MANDATORY";
export const DELETE_DEPARTMENT_ERROR = "DELETE_DEPARTMENT_ERROR";

export const GET_LIST_DEPARTMENT_PENDING = "GET_LIST_DEPARTMENT_PENDING";
export const GET_LIST_DEPARTMENT_SUCCESS = "GET_LIST_DEPARTMENT_SUCCESS";
export const GET_LIST_DEPARTMENT_ERROR = "GET_LIST_DEPARTMENT_ERROR";

export const GET_LIST_CLASS_PENDING = "GET_LIST_CLASS_PENDING";
export const GET_LIST_CLASS_SUCCESS = "GET_LIST_CLASS_SUCCESS";
export const GET_LIST_CLASS_ERROR = "GET_LIST_CLASS_ERROR";

export const GET_LIST_POSITION_PENDING = "GET_LIST_POSITION_PENDING";
export const GET_LIST_POSITION_SUCCESS = "GET_LIST_POSITION_SUCCESS";
export const GET_LIST_POSITION_ERROR = "GET_LIST_POSITION_ERROR";

export const GET_PAYMENT_METHODE_PENDING = "GET_PAYMENT_METHODE_PENDING";
export const GET_PAYMENT_METHODE_SUCCESS = "GET_PAYMENT_METHODE_SUCCESS";
export const GET_PAYMENT_METHODE_ERROR = "GET_PAYMENT_METHODE_ERROR";

export const GET_LIST_ONBEHALF_PENDING = "GET_LIST_ONBEHALF_PENDING";
export const GET_LIST_ONBEHALF_SUCCESS = "GET_LIST_ONBEHALF_SUCCESS";
export const GET_LIST_ONBEHALF_ERROR = "GET_LIST_ONBEHALF_ERROR";

export const GET_LIST_ONBEHALF_BY_ID_PENDING =
  "GET_LIST_ONBEHALF_BY_ID_PENDING";
export const GET_LIST_ONBEHALF_BY_ID_SUCCESS =
  "GET_LIST_ONBEHALF_BY_ID_SUCCESS";
export const GET_LIST_ONBEHALF_BY_ID_ERROR = "GET_LIST_ONBEHALF_BY_ID_ERROR";

export const POST_ONBEHALF_PENDING = "POST_ONBEHALF_PENDING";
export const POST_ONBEHALF_SUCCESS = "POST_ONBEHALF_SUCCESS";
export const POST_ONBEHALF_ERROR = "POST_ONBEHALF_ERROR";

export const RESET_ONBYHALF_BY_ID = "RESET_ONBYHALF_BY_ID";

export const PUT_ACTIVE_ONBEHALF_PENDING = "PUT_ACTIVE_ONBEHALF_PENDING";
export const PUT_ACTIVE_ONBEHALF_SUCCESS = "PUT_ACTIVE_ONBEHALF_SUCCESS";
export const PUT_ACTIVE_ONBEHALF_ERROR = "PUT_ACTIVE_ONBEHALF_ERROR";

// Announcement
export const GET_LIST_ANNOUNCEMENT_PENDING = "GET_LIST_ANNOUNCEMENT_PENDING";
export const GET_LIST_ANNOUNCEMENT_SUCCESS = "GET_LIST_ANNOUNCEMENT_SUCCESS";
export const GET_LIST_ANNOUNCEMENT_ERROR = "GET_LIST_ANNOUNCEMENT_ERROR";

export const GET_ANNOUNCEMENT_BY_ID_PENDING = "GET_ANNOUNCEMENT_BY_ID_PENDING";
export const GET_ANNOUNCEMENT_BY_ID_SUCCESS = "GET_ANNOUNCEMENT_BY_ID_SUCCESS";
export const GET_ANNOUNCEMENT_BY_ID_ERROR = "GET_LIST_ANNOUNCEMENT_BY_ID_ERROR";

export const POST_ANNOUNCEMENT_PENDING = "POST_ANNOUNCEMENT_PENDING";
export const POST_ANNOUNCEMENT_SUCCESS = "POST_ANNOUNCEMENT_SUCCESS";
export const POST_ANNOUNCEMENT_ERROR = "POST_ANNOUNCEMENT_ERROR";

export const UPDATE_ANNOUNCEMENT_PENDING = "UPDATE_ANNOUNCEMENT_PENDING";
export const UPDATE_ANNOUNCEMENT_SUCCESS = "UPDATE_ANNOUNCEMENT_SUCCESS";
export const UPDATE_ANNOUNCEMENT_ERROR = "UPDATE_ANNOUNCEMENT_ERROR";

export const UPDATE_DETAIL_ANNOUNCEMENT_FORM =
  "UPDATE_DETAIL_ANNOUNCEMENT_FORM";

export const DELETE_ANNOUNCEMENT_PENDING = "DELETE_ANNOUNCEMENT_PENDING";
export const DELETE_ANNOUNCEMENT_SUCCESS = "DELETE_ANNOUNCEMENT_SUCCESS";
export const DELETE_ANNOUNCEMENT_ERROR = "DELETE_ANNOUNCEMENT_ERROR";

export const SET_MODAL_ANNOUNCEMENT = "SET_MODAL_ANNOUNCEMENT";
export const SET_MODAL_EDIT_ANNOUNCEMENT = "SET_MODAL_EDIT_ANNOUNCEMENT";

export const GET_ADD_ANNOUNCEMENT_PARTICIPANT =
  "GET_ADD_ANNOUNCEMENT_PARTICIPANT";
export const RESET_ANNOUNCEMENT_PARTICIPANT = "RESET_ANNOUNCEMENT_PARTICIPANT";
export const SAVE_ADD_ANNOUNCEMENT_PARTICIPANT =
  "SAVE_ADD_ANNOUNCEMENT_PARTICIPANT";
export const SET_ADD_ANNOUNCEMENT_PARTICIPANT =
  "SET_ADD_ANNOUNCEMENT_PARTICIPANT";
export const SET_MODAL_ANNOUNCEMENT_PARTICIPANT =
  "SET_MODAL_ANNOUNCEMENT_PARTICIPANT";
export const SET_DELETE_ANNOUNCEMENT_PARTICIPANT =
  "SET_DELETE_ANNOUNCEMENT_PARTICIPANT";

export const getCompanyDetail = (cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_COMPANY_DETAIL_PENDING });

    const res = await API.getCompanyDetail();

    cb(res.data.data);
    dispatch({
      type: GET_COMPANY_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (e) {
    dispatch({ type: GET_COMPANY_DETAIL_ERROR });
  }
};

export const postCompany = (body: any) => async (dispatch: Dispatch) => {
  const res = await API.postCompany(body);
  dispatch({ type: POST_COMPANY, payload: { data: res.data } });
};

export const deleteAddress = (id: string) => async (dispatch: Dispatch) => {
  const res = await API.deleteAddress(id);
  dispatch({ type: DELETE_ADDRESS, payload: { data: res.data } });
};

export const deleteAccount = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_COMPANY_PENDING });

    await API.deleteAccount(id);
    dispatch({ type: DELETE_ACCOUNT_COMPANY_SUCCESS, payload: { data: id } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: DELETE_ACCOUNT_COMPANY_ERROR });
  }
};

export const primaryAddress = (id: string, index: number) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: PRIMARY_ADDRESS, payload: { data: "", index } });
};

export const primaryAccount = (id: string, index: number) => async (
  dispatch: Dispatch
) => {
  dispatch({ type: PRIMARY_ACCOUNT, payload: { data: "", index } });
};

export const getDepartmentDetail = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_PENDING });

    const res = await API.getDepartmentPositionPopulate();
    dispatch({
      type: DEPARTMENT_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: DEPARTMENT_ERROR });
  }
};

export const getAddDepartment = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DEPARTMENT_ADD_PENDING });

    const res = await API.getAddDepartment();
    dispatch({
      type: DEPARTMENT_ADD_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: DEPARTMENT_ADD_ERROR });
  }
};

export const getDivisionClass = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: CLASS_DIVISION_PENDING });

    const res = await API.getClassDivision();
    dispatch({
      type: CLASS_DIVISION_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: CLASS_DIVISION_ERROR });
  }
};

export const getRole = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ROLE_PENDING });

    const res = await API.getRole();
    dispatch({ type: GET_ROLE_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ROLE_ERROR });
  }
};

export const getRoleSpecial = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ROLE_PENDING });

    const res = await API.getRoleSpecial();
    dispatch({ type: GET_ROLE_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ROLE_ERROR });
  }
};

export const getRoleByPosition = (id: any, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_ROLE_POSITION_PENDING });

    const res = await API.getRoleByPosition(id);
    if (res.data) {
      let role: any[] = [];
      res.data.data.roles.map(p => {
        role = [...role, { id: p.id, name: p.name }];
        return true;
      });

      cb(role);
      dispatch({
        type: GET_ROLE_POSITION_SUCCESS,
        payload: { data: res.data.data }
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ROLE_POSITION_ERROR });
  }
};

export const positionAdd = (body: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ADD_POSITION_PENDING });

    const res = await API.addPosition(body);
    dispatch({ type: ADD_POSITION_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: ADD_POSITION_ERROR });
  }
};

export const getDepartmentPosition = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_DEPARTMENT_POSITION_PENDING });

    const res = await API.getDepartmentPosition();
    dispatch({
      type: GET_DEPARTMENT_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_DEPARTMENT_POSITION_ERROR });
  }
};

export const deletePosition = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_POSITION_PENDING });

    const res = await API.deletePosition(id);
    dispatch({
      type: DELETE_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else if (err.response && err.response.status === 409) {
      cb(err.response.data.message);
    }
    dispatch({ type: DELETE_POSITION_ERROR });
  }
};

export const updatePosition = (id: string, body: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: UPDATE_POSITION_PENDING });

    const res = await API.putPosition(id, body);
    dispatch({
      type: UPDATE_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: UPDATE_POSITION_ERROR });
  }
};

export const getPositionDetail = (id: any, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_DETAIL_POSITION_PENDING });

    const res = await API.getPositionDetail(id);
    if (res.data) {
      cb(res.data.data);

      dispatch({
        type: GET_DETAIL_POSITION_SUCCESS,
        payload: { data: res.data.data }
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_DETAIL_POSITION_ERROR });
  }
};

export const addDepartment = (body: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: ADD_POSITION_PENDING });

    const res = await API.addDepartment(body);
    dispatch({ type: ADD_POSITION_SUCCESS, payload: { data: res } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: ADD_POSITION_ERROR });
  }
};

export const getDetailDepartment = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_DETAIL_DEPARTMENT_PENDING });

    const res = await API.getDetailDepartment(id);
    if (res.data) {
      cb(res.data.data);
    }
    dispatch({
      type: GET_DETAIL_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: ADD_POSITION_ERROR });
  }
};

export const putDepartment = (id: string, body: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: UPDATE_DEPARTMENT_PENDING });

    const res = await API.putDepartment(id, body);
    dispatch({
      type: UPDATE_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: UPDATE_DEPARTMENT_ERROR });
  }
};

export const deleteDepartment = (
  id: string,
  cb: () => void,
  cbErr: (e: any) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DELETE_DEPARTMENT_PENDING });

    const res = await API.deleteDepartment(id);
    dispatch({
      type: DELETE_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else if (err.response && err.response.status === 409) {
      cbErr(err.response.data.message);
      dispatch({
        type: DELETE_DEPARTMENT_MANDATORY,
        payload: { error: err.response.data.message }
      });
    }

    dispatch({ type: DELETE_DEPARTMENT_ERROR });
  }
};

export const listDepartment = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_DEPARTMENT_PENDING });

    const res = await API.getAddDepartment();
    dispatch({
      type: GET_LIST_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_DEPARTMENT_ERROR });
  }
};

export const listDivisionClass = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_CLASS_PENDING });

    const res = await API.getClassDivision();
    dispatch({
      type: GET_LIST_CLASS_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_CLASS_ERROR });
  }
};

export const listPosition = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_POSITION_PENDING });

    const res = await API.getDepartmentPosition();
    dispatch({
      type: GET_LIST_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_POSITION_ERROR });
  }
};

export const setImageCompany = (dataImage: File | "") => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_IMAGE_COMPANY,
    payload: { data: dataImage }
  });
};

export const postCompanies = (formData: any, id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { company } = getState();

    dispatch({ type: POST_COMPANIES_PENDING });

    const form = new FormData();
    form.append("name", formData.name);
    form.append("branch[0][name]", formData.name);
    form.append("branch[0][id]", formData.id);

    if (company.loadCompanyDetail.branches[0].branchAddress.length > 0) {
      form.append("branch[0][addresses][0][email]", formData.company_account);
      form.append("branch[0][addresses][0][address1]", formData.address);
      form.append("branch[0][addresses][0][zip_code]", formData.zipCode);
      form.append("branch[0][addresses][0][phone]", formData.phoneNo);
      form.append("branch[0][addresses][0][fax]", formData.faxNo);
      form.append("branch[0][addresses][0][id]", formData.addressId);

      if (formData.regencyId !== "") {
        form.append("branch[0][addresses][0][regency_id]", formData.regencyId);
      } else {
        form.append(
          "branch[0][addresses][0][regency_id]",
          company.loadCompanyDetail.branches[0].branchAddress[0].address.regency
            .id
        );
      }
    } else {
      form.append("branch[0][new_address][0][email]", formData.company_account);
      form.append("branch[0][new_address][0][address1]", formData.address);
      form.append("branch[0][new_address][0][zip_code]", formData.zipCode);
      form.append("branch[0][new_address][0][phone]", formData.phoneNo);
      form.append("branch[0][new_address][0][fax]", formData.faxNo);

      if (formData.regencyId !== "") {
        form.append(
          "branch[0][new_address][0][regency_id]",
          formData.regencyId
        );
      } else {
        form.append(
          "branch[0][new_address][0][regency_id]",
          company.loadCompanyDetail.branches[0].branchAddress[0].address.regency
            .id
        );
      }
    }

    if (company.imageString) {
      form.append("logo", company.imageFile);
    }

    formData.account.map((e: any, index: number) => {
      form.append(`accounts[0][new_account][${index}][name]`, e.account_name);
      form.append(
        `accounts[0][new_account][${index}][number]`,
        e.account_number
      );
      form.append(`accounts[0][new_account][${index}][bank_id]`, e.bank_id);
      form.append(`accounts[0][new_account][${index}][branch]`, e.branch);
      form.append(`accounts[0][new_account][${index}][title]`, e.title);
      form.append(`accounts[0][new_account][${index}][code]`, e.coa);
      form.append(
        `accounts[0][new_account][${index}][payment_method_id]`,
        e.payment_id
      );
      return true;
    });

    const res = await API.postCompanies(form, id);
    toast("Update Company Successfully", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
    dispatch({
      type: POST_COMPANIES_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast("Something went wrong, please try again later!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "term-policy-toast danger",
        closeButton: false,
        draggable: false
      });
      dispatch({ type: POST_COMPANIES_ERROR });
    }
  }
};

export const setPrimaryBank = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: SET_PRIMARY_BANK_PENDING });

    await API.setPrimaryBank(id);
    dispatch({
      type: SET_PRIMARY_BANK_SUCCESS,
      payload: { data: id }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: SET_PRIMARY_BANK_ERROR });
  }
};

export const getPaymentMethode = (cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_PAYMENT_METHODE_PENDING });

    const res = await API.getMethodePayment();
    if (res.data) {
      let payment: any[] = [];
      res.data.data.map(p => {
        payment = [...payment, { value: p.id, label: p.attributes.title }];
        return true;
      });
      cb(payment);
      dispatch({
        type: GET_PAYMENT_METHODE_SUCCESS,
        payload: { data: res.data.data }
      });
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PAYMENT_METHODE_ERROR });
  }
};

export const getOnBehalf = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_ONBEHALF_PENDING });

    const res = await API.getOnbehalf();
    dispatch({
      type: GET_LIST_ONBEHALF_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_ONBEHALF_ERROR });
  }
};

export const postOnBehalf = (body: any, id: string, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_ONBEHALF_PENDING });

    const res = id
      ? await API.putOnBehalf(body, id)
      : await API.postOnBehalf(body);
    dispatch({
      type: POST_ONBEHALF_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Data Stored", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 2200);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_ONBEHALF_ERROR });
  }
};

export const getOnBehalfById = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_LIST_ONBEHALF_BY_ID_PENDING });

    const res = await API.getOnbehalfById(id);
    dispatch({
      type: GET_LIST_ONBEHALF_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
    cb(res.data.data);
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_ONBEHALF_BY_ID_ERROR });
  }
};

export const resetOnbihalfById = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_ONBYHALF_BY_ID
  });
};

export const putOnBehalfActive = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_ACTIVE_ONBEHALF_PENDING });

    const res = await API.putOnbehalfActive(id);
    dispatch({
      type: PUT_ACTIVE_ONBEHALF_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("User Updated", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });

    dispatch(getOnBehalf());
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: PUT_ACTIVE_ONBEHALF_ERROR });
  }
};

// ANNOUNCEMENT
export const getAnnouncementList = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_ANNOUNCEMENT_PENDING });

    const res = await API.getAnnouncementList();
    dispatch({
      type: GET_LIST_ANNOUNCEMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_ANNOUNCEMENT_ERROR });
  }
};

export const getAnnouncementById = (id: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_ANNOUNCEMENT_BY_ID_PENDING });

    const res = await API.getAnnouncementById(id);
    dispatch({
      type: GET_ANNOUNCEMENT_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ANNOUNCEMENT_BY_ID_ERROR });
  }
};

export const postAnnouncement = (
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_ANNOUNCEMENT_PENDING });
    const formData = new FormData();
    const startForm = new Date(body.started_at);
    const startDate = new Date(
      startForm.setSeconds(startForm.getSeconds() + 1)
    );
    const endForm = new Date(body.end_at);
    endForm.setHours(endForm.getHours() + 23);
    const endDate = new Date(endForm.setMinutes(endForm.getMinutes() + 59));

    formData.append("started_at", startDate.toUTCString());
    formData.append("finished_at", endDate.toUTCString());
    formData.append("title", body.subject);
    formData.append("all", body.sendToAll);
    if (body.saveAsDraft === true) {
      const status = "1";
      formData.append("status", status);
    } else {
      const status = "2";
      formData.append("status", status);
    }
    if (body.sendToAll === false) {
      formData.append("user_id", body.participants);
    }
    formData.append("description", body.description);

    const res = await API.postAnnouncement(formData);
    dispatch({
      type: POST_ANNOUNCEMENT_SUCCESS,
      payload: { data: res.data.data }
    });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response && err.response.data.success,
      message: err.response && err.response.data.message.error_messages.user_id
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: POST_ANNOUNCEMENT_ERROR });
  }
};

export const deleteAnnouncement = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_ANNOUNCEMENT_PENDING });

    const res = await API.deleteAnnouncement(id);
    dispatch({
      type: DELETE_ANNOUNCEMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else if (err.response && err.response.status === 409) {
      cb(err.response.data.message);
    }
    dispatch({ type: DELETE_ANNOUNCEMENT_ERROR });
  }
};

export const setModalAnnouncement = (index: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_ANNOUNCEMENT,
    payload: { data: index }
  });
};

export const setModalEditAnnouncement = (index: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_EDIT_ANNOUNCEMENT,
    payload: { data: index }
  });
};

export const setModalAnnouncementParticipants = (index: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_ANNOUNCEMENT_PARTICIPANT,
    payload: { data: index }
  });
};

export const getAnnouncementParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ADD_ANNOUNCEMENT_PARTICIPANT
  });
};

export const setAddAnnouncementParticipants = (data: any, cb?: () => void) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ADD_ANNOUNCEMENT_PARTICIPANT,
    payload: { data }
  });
  cb && cb();
};

export const saveAddAnnouncementParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: SAVE_ADD_ANNOUNCEMENT_PARTICIPANT
  });
};

export const resetAnnouncementParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_ANNOUNCEMENT_PARTICIPANT
  });
};

export const setDeleteAnnouncementParticipants = (id: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_DELETE_ANNOUNCEMENT_PARTICIPANT,
    payload: { data: id }
  });
};

export const updateAnnouncement = (
  announceId: string,
  body: any,
  participants: any,
  onSuccess?: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: UPDATE_ANNOUNCEMENT_PENDING });
    const formData = new URLSearchParams();
    const startForm = new Date(body.started_at);
    const startDate = new Date(
      startForm.setSeconds(startForm.getSeconds() + 1)
    );
    const endForm = new Date(body.finished_at);
    endForm.setHours(endForm.getHours() + 23);
    const endDate = new Date(endForm.setMinutes(endForm.getMinutes() + 59));

    formData.append("started_at", startDate.toUTCString());
    formData.append("finished_at", endDate.toUTCString());
    formData.append("title", body.title);
    formData.append("all", body.sendToAll);
    if (body.saveAsDraft === true) {
      const status = "1";
      formData.append("status", status);
    } else {
      const status = "2";
      formData.append("status", status);
    }
    if (body.sendToAll === false) {
      formData.append("user_id", participants);
    }
    formData.append("description", body.description);

    const res = await API.updateAnnouncement(announceId, formData);
    toast("Update Announcement Success", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
    dispatch({
      type: UPDATE_ANNOUNCEMENT_SUCCESS,
      payload: { data: res.data }
    });
    onSuccess && onSuccess();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    toast("Something went wrong!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast danger",
      closeButton: false,
      draggable: false
    });

    dispatch({ type: UPDATE_ANNOUNCEMENT_ERROR });
  }
};

export const updateDetailAnnouncementForm = (data: {
  formName: string;
  value: any;
}) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_DETAIL_ANNOUNCEMENT_FORM, payload: { data } });
};
