import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Autocomplete, Button, Spinner, Text } from "../../../../components";
import "./styles.scss";
import { Reducers } from "../../../../redux/types";

interface Props {
  name?: string;
  isAccount?: boolean;
  hapus?: boolean;
  primary?: boolean;
  data?: any;
  dataPopulate?: any;
  dataAccount?: any;
  optionData?: string;
  setOptionData?: (e: any) => void;
  hapusAddress?: () => void;
  hapusAccount?: () => void;
  onChange?: (alias: { name: string; value: string }) => void;
  isLoading?: boolean;
  index?: number;
  isError?: boolean;
}

const CardCompany = ({
  name,
  hapus,
  // primary,
  isAccount,
  hapusAddress,
  hapusAccount,
  onChange,
  data,
  dataAccount,
  isLoading,
  index
}: Props) => {
  const { t } = useTranslation();
  const employeeState = useSelector((state: Reducers) => state.employee);

  const _bank = useCallback(() => {
    let result: any[] = [];
    employeeState.listBank.list.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  }, [employeeState.listBank.list]);

  const _valueBank = useCallback(() => {
    const indexBank = _bank().findIndex(
      item => item.value === dataAccount.bank_id
    );
    return _bank()[indexBank].label;
  }, [_bank, dataAccount.bank_id]);

  return (
    <div className="dashboard-company-card-form-container shadow">
      {!isLoading ? (
        <>
          <div className="dashboard-company-card-form-button-container">
            {/* {isAccount ? (
          <div className="dashboard-company-picker">
            <Picker listValue={value} />
          </div>
        ) : ( */}
            <div style={{ width: "90%" }}>
              <Text
                style={{
                  paddingTop: "0.4rem",
                  fontSize: "1.2rem"
                }}
              >
                {name}
              </Text>
            </div>
            {/* )} */}
            <div
              className="dashboard-company-card-form-button-container"
              style={{ marginLeft: "auto" }}
            >
              <div style={{ paddingRight: "0.4rem" }}>
                <Button
                  onPress={!isAccount ? hapusAddress : hapusAccount}
                  className={`button small ${hapus ? "" : "alt"}`}
                >
                  {t("btn.delete")}
                </Button>
              </div>
              {/* <div>
            <Button className={`button small hollow ${primary ? "" : "alt"}`}>
              {primary ? "PRIMARY" : "MAKE PRIMARY"}
            </Button>
          </div> */}
            </div>
          </div>
          {!isAccount ? (
            <div style={{ paddingBottom: 20 }}>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.alias}
                  onChange={e =>
                    onChange!({ name: "alias", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.line_1}
                  onChange={e =>
                    onChange!({ name: "line_1", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.line_2}
                  onChange={e =>
                    onChange!({ name: "line_2", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="City"
                  value={data.city}
                  onChange={e =>
                    onChange!({ name: "city", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Zip Code"
                  value={data.zipcode}
                  onChange={e =>
                    onChange!({ name: "zip_code", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Phone No."
                  value={data.phone}
                  onChange={e =>
                    onChange!({ name: "phone", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Fax No."
                  value={data.fax}
                  onChange={e =>
                    onChange!({ name: "fax", value: e.target.value })
                  }
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingBottom: 20 }}>
              <div className="dashboard-company-card-form-underline">
                <Autocomplete
                  option={_bank()}
                  placeHolder={
                    dataAccount.bank_id
                      ? _valueBank()
                      : t("personnel.form.bankName")
                  }
                  onValueChange={e =>
                    onChange!({ name: "bank_id", value: e.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder={t("personnel.form.accountName")}
                  value={dataAccount.name}
                  onChange={e =>
                    onChange!({ name: "name", value: e.target.value })
                  }
                  name={`accounts[name][${index}]`}
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder={t("personnel.form.accountNumber")}
                  value={dataAccount.number}
                  onChange={e =>
                    onChange!({ name: "number", value: e.target.value })
                  }
                  name={`accounts[number][${index}]`}
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder={t("personnel.form.branch")}
                  value={dataAccount.branch}
                  onChange={e =>
                    onChange!({ name: "branch", value: e.target.value })
                  }
                  name={`accounts[branch][${index}]`}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="dashboard-profile-wrap-loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

CardCompany.defaultProps = {
  isLoading: false
};

export default memo(CardCompany);
