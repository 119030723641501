import { Slide, toast } from "react-toastify";

import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";
import i18n from "../../i18n";

export const GET_LOAN_SOURCES_PENDING = "GET_LOAN_SOURCES_PENDING";
export const GET_LOAN_SOURCES_SUCCESS = "GET_LOAN_SOURCES_SUCCESS";
export const GET_LOAN_SOURCES_ERROR = "GET_LOAN_SOURCES_ERROR";

export const GET_LOAN_PROFILE_LIST_PENDING = "GET_LOAN_PROFILE_LIST_PENDING";
export const GET_LOAN_PROFILE_LIST_SUCCESS = "GET_LOAN_PROFILE_LIST_SUCCESS";
export const GET_LOAN_PROFILE_LIST_ERROR = "GET_LOAN_PROFILE_LIST_ERROR";

export const GET_LOAN_SOURCES_BY_ID_PENDING = "GET_LOAN_SOURCES_BY_ID_PENDING";
export const GET_LOAN_SOURCES_BY_ID_SUCCESS = "GET_LOAN_SOURCES_BY_ID_SUCCESS";
export const GET_LOAN_SOURCES_BY_ID_ERROR = "GET_LOAN_SOURCES_BY_ID_ERROR";

export const GET_LIST_USER_ON_LOAN_PENDING = "GET_LIST_USER_ON_LOAN_PENDING";
export const GET_LIST_USER_ON_LOAN_SUCCESS = "GET_LIST_USER_ON_LOAN_SUCCESS";
export const GET_LIST_USER_ON_LOAN_ERROR = "GET_LIST_USER_ON_LOAN_ERROR";

export const GENERATE_LOAN_PROFILE_PENDING = "GENERATE_LOAN_PROFILE_PENDING";
export const GENERATE_LOAN_PROFILE_SUCCESS = "GENERATE_LOAN_PROFILE_SUCCESS";
export const GENERATE_LOAN_PROFILE_ERROR = "GENERATE_LOAN_PROFILE_ERROR";

export const UPDATE_LOAN_PROFILE_STATUS_PENDING =
  "UPDATE_LOAN_PROFILE_STATUS_PENDING";
export const UPDATE_LOAN_PROFILE_STATUS_SUCCESS =
  "UPDATE_LOAN_PROFILE_STATUS_SUCCESS";
export const UPDATE_LOAN_PROFILE_STATUS_ERROR =
  "UPDATE_LOAN_PROFILE_STATUS_ERROR";

export const getLoanSources = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LOAN_SOURCES_PENDING });

    const res = await API.getLoanSources();
    dispatch({
      type: GET_LOAN_SOURCES_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LOAN_SOURCES_ERROR });
  }
};

export const getLoanSourceById = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LOAN_SOURCES_BY_ID_PENDING });

    const res = await API.getLoanSourceById(id);
    dispatch({
      type: GET_LOAN_SOURCES_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LOAN_SOURCES_BY_ID_ERROR });
  }
};

export const getLoanProfileList = (page: number) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_LOAN_PROFILE_LIST_PENDING });
    const res = await API.getLoanProfileList(page);
    dispatch({
      type: GET_LOAN_PROFILE_LIST_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LOAN_PROFILE_LIST_ERROR });
  }
};

export const getListUserOnLoan = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_USER_ON_LOAN_PENDING });
    const res = await API.getListUserOnLoan();
    dispatch({
      type: GET_LIST_USER_ON_LOAN_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_LIST_USER_ON_LOAN_ERROR });
  }
};

export const generateLoanProfile = (body: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GENERATE_LOAN_PROFILE_PENDING });

    const res = await API.generateLoanProfile(body);
    dispatch({
      type: GENERATE_LOAN_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });
    toast(i18n.t("task.form.message.dataStored"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: GENERATE_LOAN_PROFILE_ERROR });
  }
};

export const updateLoanProfileStatus = (
  LoanStatus: any,
  id: string,
  cb: () => void
) => async (dispatch: Dispatch) => {
  const body = {
    status: LoanStatus
  };
  try {
    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_PENDING });

    const res = await API.updateLoanProfileStatus(body, id);
    dispatch({
      type: UPDATE_LOAN_PROFILE_STATUS_SUCCESS,
      payload: { data: res.data.data }
    });
    toast(i18n.t("task.form.message.statusUpdated"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_ERROR });
  }
};

export const updateAllLoanProfileStatus = (
  LoanStatus: any,
  cb: () => void
) => async (dispatch: Dispatch) => {
  const body = {
    status: LoanStatus
  };
  try {
    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_PENDING });

    const res = await API.updateAllLoanProfileStatus(body);
    dispatch({
      type: UPDATE_LOAN_PROFILE_STATUS_SUCCESS,
      payload: { data: res.data.data }
    });
    toast(i18n.t("task.form.message.statusUpdated"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_ERROR });
  }
};

export const updateLoanLimit = (
  limit: any,
  id: string,
  cb: () => void
) => async (dispatch: Dispatch) => {
  const body = {
    amount_limit: limit
  };
  try {
    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_PENDING });

    const res = await API.updateLoanProfileStatus(body, id);
    dispatch({
      type: UPDATE_LOAN_PROFILE_STATUS_SUCCESS,
      payload: { data: res.data.data }
    });
    toast(i18n.t("task.form.message.statusUpdated"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    dispatch({ type: UPDATE_LOAN_PROFILE_STATUS_ERROR });
  }
};
