/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import "./styles.scss";
import {
  Container,
  HeaderTittle,
  Search,
  Tab,
  Toast
} from "../../../components";
import {
  getActivityById,
  getExpenseById,
  getList,
  getProjectById,
  getpaymentById,
  resetResponse,
  saveActivity,
  saveExpense,
  savePayment,
  saveProject,
  searchData
} from "../../../redux/actions/master_data";
import { documentTitle } from "../../../utils";
import { Table } from "./components";
import {
  AddButton,
  TemplateButton,
  UploadButton
} from "./components/Buttons/index";

import {
  ModalActivityCategory,
  ModalExpenseType,
  ModalPaymentCategory,
  ModalProjectCategory
} from "../../../components/ModalMasterData";
import { Reducers } from "../../../redux/types";

const ExpenseType = () => {
  const { t } = useTranslation();
  documentTitle("Master Data");
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;
  const dispatch = useDispatch();

  const [openModalExpenseType, setOpenModalExpenseType] = useState(false);
  const [openModalProjectCategory, setOpenModalProjectCategory] = useState(
    false
  );
  const [openModalPaymentCategory, setOpenModalPaymentCategory] = useState(
    false
  );
  const [openModalActivityCategory, setOpenModalActivityCategory] = useState(
    false
  );

  const [currentPage, setCurrentPage] = useState(1);

  const [typeModal, setTypeModal] = useState(null);

  const masterState = useSelector((state: Reducers) => state.masterdata);

  const _openModal = (modalName, type_modal, id = null, current_page = 1) => {
    setCurrentPage(current_page);
    dispatch(resetResponse());
    switch (modalName) {
      case "expenseType":
        if (id != null) {
          dispatch(getExpenseById(id));
        }

        setOpenModalExpenseType(true);
        setTypeModal(type_modal);
        break;
      case "projectCategory":
        dispatch(getProjectById(id));
        setOpenModalProjectCategory(true);
        setTypeModal(type_modal);
        break;
      case "activityCategory":
        dispatch(getActivityById(id));
        setOpenModalActivityCategory(true);
        setTypeModal(type_modal);
        break;
      case "paymentCategory":
        dispatch(getpaymentById(id));
        setOpenModalPaymentCategory(true);
        setTypeModal(type_modal);
        break;
      default:
        break;
    }
  };

  const _closeModal = modalName => {
    switch (modalName) {
      case "expenseType":
        dispatch(getList("expenseType"));
        setOpenModalExpenseType(false);
        setTypeModal(null);
        break;
      case "projectCategory":
        setOpenModalProjectCategory(false);
        setTypeModal(null);
        break;
      case "activityCategory":
        setOpenModalActivityCategory(false);
        setTypeModal(null);
        break;
      case "paymentCategory":
        setOpenModalPaymentCategory(false);
        setTypeModal(null);
        break;
      default:
        break;
    }
  };

  const submited = (type: any, id: any, data: any) => {
    const _form = new FormData();

    switch (type) {
      case "expenseType":
        _form.append("category_id", data.category_id);
        _form.append("parent_id", data.parent_id ? data.parent_id : "");
        _form.append("title", data.title);
        _form.append("code", data.code);
        _form.append("description", data.description ? data.description : "");
        _form.append("hidden", data.hidden);
        _form.append("unit_id", data.unit_id ? data.unit_id : "");
        _form.append("direct_item", data.direct_item);
        _form.append("client_timezone", data.client_timezone);
        if (id) {
          _form.append("_method", "PUT");
        }
        dispatch(saveExpense(_form, id));
        dispatch(getList("expenseType", currentPage));
        setOpenModalExpenseType(false);

        break;

      case "paymentCategory":
        _form.append("payment_method_id", data.payment_method_id);
        _form.append(
          "account_bank_id",
          data.account_bank_id ? data.account_bank_id : ""
        );
        _form.append(
          "account_branch",
          data.account_branch ? data.account_branch : ""
        );
        _form.append(
          "account_name",
          data.account_name ? data.account_name : ""
        );
        _form.append(
          "account_number",
          data.account_number ? data.account_number : ""
        );
        _form.append("active", data.active);
        _form.append("client_timezone", data.client_timezone);
        _form.append("code", data.code);
        _form.append("currency_code", data.currency_code);
        _form.append("description", data.description ? data.description : "");
        _form.append("method_id", data.method_id);
        _form.append("primary", data.primary);
        _form.append("title", data.title);

        if (id) {
          _form.append("_method", "PUT");
        }

        dispatch(savePayment(_form, id));
        setOpenModalPaymentCategory(false);

        break;

      case "projectCategory":
        _form.append("category_id", data.category_id);
        _form.append("title", data.title);
        _form.append("description", data.description);
        _form.append("client_timezone", data.client_timezone);
        if (id) {
          _form.append("_method", "PUT");
        }

        dispatch(saveProject(_form, id));
        dispatch(getList("projectCategory"));
        setOpenModalProjectCategory(false);

        break;

      case "activityCategory":
        _form.append("category_id", data.category_id);
        _form.append("title", data.title);
        _form.append("description", data.description);
        _form.append("client_timezone", data.client_timezone);
        if (id) {
          _form.append("_method", "PUT");
        }
        dispatch(saveActivity(_form, id));
        dispatch(getList("activityCategory"));
        setOpenModalActivityCategory(false);

        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (masterState.save_response || masterState.delete_response) {
      Toast({ message: `${masterState.message_response}`, is_success: true });
    } else if (masterState.message_response) {
      Toast({ message: `${masterState.message_response}`, is_success: false });
    }

    const url = window.location.pathname;
    const path = url.split("/");
    const page = path[path.length - 1];
    dispatch(getList(page, currentPage, masterState.is_hide));
  }, [
    masterState.message_response,
    masterState.save_response,
    masterState.data,
    dispatch,
    masterState.delete_response,
    currentPage,
    masterState.is_hide
  ]);

  const leftItems = [
    {
      pathname: `${match.path}/expenseType`,
      component: (
        <div className="page-task-list-request">
          <Table
            type="expenseType"
            _action={(id, page) => _openModal("expenseType", "edit", id, page)}
          />
          <ModalExpenseType
            title={`${t(
              `master_data.${typeModal}`
            )} Data Expense`.toUpperCase()}
            isOpen={openModalExpenseType}
            onPressCancel={() => _closeModal("expenseType")}
            onPressSubmit={(id, data) => submited("expenseType", id, data)}
          />
        </div>
      ),
      exact: true,
      subLabel: t("Expense Type"),
      type: "expenseType",
      accessible: true
    },
    {
      pathname: `${match.path}/projectCategory`,
      component: (
        <div className="page-task-list-request">
          <Table
            type="projectCategory"
            _action={(id, page) =>
              _openModal("projectCategory", "edit", id, page)
            }
          />
          <ModalProjectCategory
            title={`${t(
              `master_data.${typeModal}`
            )} Data Project Category`.toUpperCase()}
            isOpen={openModalProjectCategory}
            onPressCancel={() => _closeModal("projectCategory")}
            onPressSubmit={(id, data) => submited("projectCategory", id, data)}
          />
        </div>
      ),
      exact: true,
      subLabel: t("Project category"),
      type: "projectCategory",
      accessible: true
    },
    {
      pathname: `${match.path}/activityCategory`,
      component: (
        <div className="page-task-list-request">
          <Table
            type="activityCategory"
            _action={(id, page) =>
              _openModal("activityCategory", "edit", id, page)
            }
          />
          <ModalActivityCategory
            title={`${t(
              `master_data.${typeModal}`
            )} Data Activity Category`.toUpperCase()}
            isOpen={openModalActivityCategory}
            onPressCancel={() => _closeModal("activityCategory")}
            onPressSubmit={(id, data) => submited("activityCategory", id, data)}
          />
        </div>
      ),
      exact: true,
      subLabel: t("Activity Category"),
      type: "activityCategory",
      accessible: true
    },
    {
      pathname: `${match.path}/paymentCategory`,
      component: (
        <div className="page-task-list-request">
          <Table
            type="paymentCategory"
            _action={(id, page) =>
              _openModal("paymentCategory", "edit", id, page)
            }
          />
          <ModalPaymentCategory
            title={`${t(
              `master_data.${typeModal}`
            )} Data Expense`.toUpperCase()}
            isOpen={openModalPaymentCategory}
            onPressCancel={() => _closeModal("paymentCategory")}
            onPressSubmit={(id, data) => submited("paymentCategory", id, data)}
          />
        </div>
      ),
      exact: true,
      subLabel: t("Payment Category"),
      type: "paymentCategory",
      accessible: true
    }
  ];

  const items: any[] = [...leftItems];

  items.map(item => {
    item.onClick = () => history.push(item.pathname);
    return true;
  });

  const searchText = useCallback(
    debounce((text: any) => {
      const list = [...leftItems];
      const path = String(window.location.pathname).replace(
        `${match.path}/`,
        ""
      );

      if (list.length > 0) {
        dispatch(searchData(path === "history" ? "finished" : path, text));
      }
    }, 1000),
    []
  );

  const handleText = (text: any) => {
    searchText(text);
  };

  return (
    <>
      <Container scroll className="page-task-list" withMaxWidth>
        <HeaderTittle title={t("master_data.title")} />
        <div className="tab margin-bottom-16" style={{ marginTop: -40 }}>
          <Tab {...{ pathname }} items={leftItems} fontSize="normal" />
        </div>
        <div style={{ display: "flex" }}>
          <div
            style={{
              width:
                window.location.pathname.split("/").pop() === "expenseType"
                  ? "50%"
                  : "90%"
            }}
          >
            <Search
              className="margin-left-8 margin-right-8"
              onChange={e => {
                handleText(e);
              }}
            />
          </div>
          <div
            style={{
              marginTop: -40,
              width:
                window.location.pathname.split("/").pop() === "expenseType"
                  ? "60%"
                  : "10%"
            }}
          >
            {window.location.pathname.split("/").pop() === "expenseType" ? (
              <>
                <AddButton _action={() => _openModal("expenseType", "add")} />
                <UploadButton />
                <TemplateButton />
              </>
            ) : (
              ""
            )}

            {window.location.pathname.split("/").pop() === "projectCategory" ? (
              <>
                <AddButton
                  _action={() => _openModal("projectCategory", "add")}
                />
              </>
            ) : (
              ""
            )}

            {window.location.pathname.split("/").pop() ===
            "activityCategory" ? (
              <>
                <AddButton
                  _action={() => _openModal("activityCategory", "add")}
                />
              </>
            ) : (
              ""
            )}

            {window.location.pathname.split("/").pop() === "paymentCategory" ? (
              <>
                <AddButton
                  _action={() => _openModal("paymentCategory", "add")}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        <Switch>
          <Route path={match.path} exact>
            <Redirect to={`${match.path}/expenseType`} />
          </Route>

          {items.map(
            (item, index) =>
              item.accessible && (
                <Route path={item.pathname} exact={item.exact} key={index}>
                  {item.component}
                </Route>
              )
          )}
        </Switch>
      </Container>
    </>
  );
};

export default ExpenseType;
