import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";

import "./styles.scss";
import "../../../components/OrgChart/oc.css";
import OrgChart from "../../../components/OrgChart/oc";
import { Reducers } from "../../../redux/types";
import { Container, Footer, HeaderTittle, Modal } from "../../../components";
import { FilterStaff, ModalEditStaff } from "../../Employee/components";
import {
  deleteEmployee,
  getCompanyTree,
  getMyPermission,
  // getProfileStaff,
  resetCompanyTree,
  resetEmployeeProfile
} from "../../../redux/actions";
import { Permission, Roles, copyWritings } from "../../../utils";
import { Navigation } from "../components/Navigation";

const Tree = () => {
  const oc = useRef() as React.RefObject<HTMLDivElement>;
  const dispatch = useDispatch();
  const permission = {
    others: Permission("employee-others-edit")
  };
  const roles = {
    loanHr: Roles("personal-loan-hr")
  };
  const history = useHistory();
  const { companytreeState, generateState, homeState } = useSelector(
    (state: Reducers) => ({
      companytreeState: state.companytree,
      generateState: state.generate,
      homeState: state.home
    }),
    shallowEqual
  );
  const [oneTimeOC, setOneTimeOC] = useState(true);
  const [firstTimeOnly, setFirstTimeOnly] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState({
    visible: false,
    id: ""
  });
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [params, setParams] = useState({ position_id: "", department_id: "" });

  const reload = useCallback(
    (value: any) => {
      dispatch(getCompanyTree(value));
    },
    [dispatch]
  );

  const _openModalEdit = useCallback(
    (id: string) => {
      if (homeState.profile.data.employee.id !== id) {
        if (permission.others && !roles.loanHr) {
          history.push(`/employee-detail/${id}`);
        } else if (roles.loanHr && permission.others) {
          history.push("/employee/staff");
        } else {
          toast("You don't have permission", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast danger",
            closeButton: false,
            draggable: false
          });
        }
      } else {
        history.push(`/employee-detail/${id}`);
      }
    },
    [
      history,
      homeState.profile.data.employee.id,
      permission.others,
      roles.loanHr
    ]
  );

  useEffect(() => {
    dispatch(getMyPermission());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCompanyTree());
    };
  }, [dispatch]);

  useEffect(() => {
    if (firstTimeOnly) {
      reload(params);
      setFirstTimeOnly(false);
    }
    if (generateState.activeDepartment.id !== params.department_id) {
      const newParams = {
        ...params,
        department_id: generateState.activeDepartment.id
      };
      setOneTimeOC(true);
      setParams({ ...newParams });
      reload(newParams);
    }
    if (generateState.activePosition.id !== params.position_id) {
      const newParams = {
        ...params,
        position_id: generateState.activePosition.id
      };
      setOneTimeOC(true);
      setParams({ ...newParams });
      reload(newParams);
    }
    if (
      companytreeState.isLoadingGetCompanyTree &&
      document.getElementsByClassName("oc-list")
    ) {
      for (
        let q = 0;
        q < document.getElementsByClassName("oc-list").length;
        q += 1
      ) {
        document.getElementsByClassName("oc-list")[q].innerHTML = "";
      }
    }
    if (companytreeState.successGetCompanyTree && oc.current && oneTimeOC) {
      oc.current.innerHTML = "";
      let html = "";
      let i = 0;
      while (i < companytreeState.successGetCompanyTree.length) {
        html = `${html}<div class='oc-list' id='oc-${i}'></div>`;
        i += 1;
      }
      oc.current.innerHTML = html;
      setOneTimeOC(false);

      companytreeState.successGetCompanyTree.map((dt: any, index: number) => {
        const orgChart = () =>
          new OrgChart({
            chartContainer: `#oc-${index}`,
            data: dt,
            nodeContent: "position",
            nodePhotoURL: "avatar",
            nodeId: "employee_id",
            nodePostId: "position_id",
            direction: "l2r"
          });
        return orgChart();
      });
    }
    [...document.querySelectorAll(".company-tree .edit")].map((item: any) => {
      item.onclick = () => {
        _openModalEdit(item.dataset.id);
      };
      return true;
    });

    [...document.querySelectorAll(".company-tree .delete")].map((item: any) => {
      item.onclick = () => {
        if (permission.others && !roles.loanHr) {
          setShowModalDelete({ visible: true, id: item.dataset.id });
        } else {
          toast("You don't have permission", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast danger",
            closeButton: false,
            draggable: false
          });
        }
      };
      return true;
    });

    [...document.querySelectorAll(".company-tree .add")].map((item: any) => {
      item.onclick = () => {
        if (permission.others && !roles.loanHr) {
          dispatch(resetEmployeeProfile());
          setShowModalAdd(true);
        } else {
          toast("You don't have permission", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast danger",
            closeButton: false,
            draggable: false
          });
        }
      };
      return true;
    });
  }, [
    setOneTimeOC,
    oneTimeOC,
    reload,
    companytreeState.successGetCompanyTree,
    firstTimeOnly,
    setFirstTimeOnly,
    generateState.activeDepartment.id,
    generateState.activePosition.id,
    params,
    oc,
    companytreeState.isLoadingGetCompanyTree,
    _openModalEdit,
    dispatch,
    permission.others,
    roles.loanHr,
    history
  ]);

  return (
    <>
      <Container scroll withMaxWidth className="page-company-tree company-tree">
        <HeaderTittle title={copyWritings("company")} />
        <Navigation />
        <FilterStaff />
        <Container isLoading={companytreeState.isLoadingGetCompanyTree}>
          <div
            className="chart-container"
            ref={oc}
            style={{ paddingTop: "2rem", overflowY: "auto" }}
          />
        </Container>
      </Container>
      <Footer />

      <ModalEditStaff
        isOpen={showModal}
        onPressCancel={() => setShowModal(false)}
      />

      <ModalEditStaff
        isOpen={showModalAdd}
        onPressCancel={() => setShowModalAdd(false)}
        isNew
      />

      <Modal
        warning
        titleModal="DELETE ITEM"
        message
        isOpen={showModalDelete.visible}
        onPressCancel={() => setShowModalDelete({ visible: false, id: "" })}
        onPressSubmit={() =>
          dispatch(
            deleteEmployee(showModalDelete.id, () => {
              window.location.reload();
              setShowModalDelete({ visible: false, id: "" });
            })
          )
        }
      />
    </>
  );
};

export default Tree;
