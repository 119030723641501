import {
  ADD_DEPARTMENT_ERROR,
  ADD_DEPARTMENT_PENDING,
  ADD_DEPARTMENT_SUCCESS,
  ADD_POSITION_ERROR,
  ADD_POSITION_PENDING,
  ADD_POSITION_SUCCESS,
  CLASS_DIVISION_ERROR,
  CLASS_DIVISION_PENDING,
  CLASS_DIVISION_SUCCESS,
  DELETE_ACCOUNT_COMPANY_ERROR,
  DELETE_ACCOUNT_COMPANY_PENDING,
  DELETE_ACCOUNT_COMPANY_SUCCESS,
  DELETE_ADDRESS,
  DELETE_ANNOUNCEMENT_ERROR,
  DELETE_ANNOUNCEMENT_PENDING,
  DELETE_ANNOUNCEMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  DELETE_DEPARTMENT_MANDATORY,
  DELETE_DEPARTMENT_PENDING,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_POSITION_ERROR,
  DELETE_POSITION_PENDING,
  DELETE_POSITION_SUCCESS,
  DEPARTMENT_ADD_ERROR,
  DEPARTMENT_ADD_PENDING,
  DEPARTMENT_ADD_SUCCESS,
  DEPARTMENT_ERROR,
  DEPARTMENT_PENDING,
  DEPARTMENT_SUCCESS,
  GET_ADD_ANNOUNCEMENT_PARTICIPANT,
  GET_ALL_BANK_ERROR,
  GET_ALL_BANK_PENDING,
  GET_ALL_BANK_SUCCESS,
  GET_ANNOUNCEMENT_BY_ID_ERROR,
  GET_ANNOUNCEMENT_BY_ID_PENDING,
  GET_ANNOUNCEMENT_BY_ID_SUCCESS,
  GET_COMPANY_DETAIL_ERROR,
  GET_COMPANY_DETAIL_PENDING,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_DEPARTMENT_POSITION_ERROR,
  GET_DEPARTMENT_POSITION_PENDING,
  GET_DEPARTMENT_POSITION_SUCCESS,
  GET_DETAIL_DEPARTMENT_ERROR,
  GET_DETAIL_DEPARTMENT_PENDING,
  GET_DETAIL_DEPARTMENT_SUCCESS,
  GET_DETAIL_POSITION_ERROR,
  GET_DETAIL_POSITION_PENDING,
  GET_DETAIL_POSITION_SUCCESS,
  GET_LIST_ANNOUNCEMENT_ERROR,
  GET_LIST_ANNOUNCEMENT_PENDING,
  GET_LIST_ANNOUNCEMENT_SUCCESS,
  GET_LIST_CLASS_ERROR,
  GET_LIST_CLASS_PENDING,
  GET_LIST_CLASS_SUCCESS,
  GET_LIST_DEPARTMENT_ERROR,
  GET_LIST_DEPARTMENT_PENDING,
  GET_LIST_DEPARTMENT_SUCCESS,
  GET_LIST_ONBEHALF_BY_ID_ERROR,
  GET_LIST_ONBEHALF_BY_ID_PENDING,
  GET_LIST_ONBEHALF_BY_ID_SUCCESS,
  GET_LIST_ONBEHALF_ERROR,
  GET_LIST_ONBEHALF_PENDING,
  GET_LIST_ONBEHALF_SUCCESS,
  GET_LIST_POSITION_ERROR,
  GET_LIST_POSITION_PENDING,
  GET_LIST_POSITION_SUCCESS,
  GET_PAYMENT_METHODE_ERROR,
  GET_PAYMENT_METHODE_PENDING,
  GET_PAYMENT_METHODE_SUCCESS,
  GET_ROLE_ERROR,
  GET_ROLE_PENDING,
  GET_ROLE_POSITION_ERROR,
  GET_ROLE_POSITION_PENDING,
  GET_ROLE_POSITION_SUCCESS,
  GET_ROLE_SUCCESS,
  POST_ANNOUNCEMENT_ERROR,
  POST_ANNOUNCEMENT_PENDING,
  POST_ANNOUNCEMENT_SUCCESS,
  POST_COMPANIES_ERROR,
  POST_COMPANIES_PENDING,
  POST_COMPANIES_SUCCESS,
  POST_COMPANY,
  POST_ONBEHALF_ERROR,
  POST_ONBEHALF_PENDING,
  POST_ONBEHALF_SUCCESS,
  PRIMARY_ACCOUNT,
  PRIMARY_ADDRESS,
  RESET_ANNOUNCEMENT_PARTICIPANT,
  RESET_ONBYHALF_BY_ID,
  SAVE_ADD_ANNOUNCEMENT_PARTICIPANT,
  SET_ADD_ANNOUNCEMENT_PARTICIPANT,
  SET_DELETE_ANNOUNCEMENT_PARTICIPANT,
  SET_IMAGE_COMPANY,
  SET_MODAL_ANNOUNCEMENT,
  SET_MODAL_ANNOUNCEMENT_PARTICIPANT,
  SET_MODAL_EDIT_ANNOUNCEMENT,
  SET_PRIMARY_BANK_ERROR,
  SET_PRIMARY_BANK_PENDING,
  SET_PRIMARY_BANK_SUCCESS,
  UPDATE_ANNOUNCEMENT_ERROR,
  UPDATE_ANNOUNCEMENT_PENDING,
  UPDATE_ANNOUNCEMENT_SUCCESS,
  UPDATE_DEPARTMENT_ERROR,
  UPDATE_DEPARTMENT_PENDING,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DETAIL_ANNOUNCEMENT_FORM,
  UPDATE_POSITION_ERROR,
  UPDATE_POSITION_PENDING,
  UPDATE_POSITION_SUCCESS
} from "../actions";
import { Action, CompanyState } from "../types";

const initialState: CompanyState = {
  isLoading: false,
  loadCompanyDetail: {
    branches: [
      {
        branchAddress: [
          {
            address: {
              address: "",
              regency: {
                id: ""
              }
            }
          }
        ]
      }
    ],
    company_account: [
      {
        account: {
          bankName: ""
        }
      }
    ],
    name: "",
    logo: {
      url: ""
    }
  },
  isLoadCompanyDetail: false,
  loadDepartment: [],
  isLoadingDepartment: false,
  loadDepartmentAdd: [],
  isLoadingDepartmentAdd: false,
  loadDivision: [],
  isLoadDivision: false,
  loadRole: [],
  loadAddDepartment: [],
  isAddDepartment: false,
  isLoadRole: false,
  loadRolePosition: {},
  isLoadRolePosition: false,
  isLoadAddPosition: false,
  loadAddPosition: [],
  loadDepartmentPost: [],
  isLoadDepartmentPost: false,
  imageFile: {},
  imageString: "",
  isLoadingPostCompanies: false,
  listBank: [],
  isLoadingBank: false,
  isLoadingSetPrimaryBank: false,
  isDeletePosition: false,
  deletePosition: [],
  updatePosition: [],
  isUpdatePosition: false,
  isDetailPosition: false,
  detailPosition: {},
  isDetailDepartment: false,
  loadDetailDepartment: [],
  isUpdateDepartment: false,
  loadUpdateDepartment: [],
  isDeleteDepartment: false,
  loadDeleteDepartment: [],
  listDepartment: [],
  isLoadListDepartment: false,
  listClass: [],
  isLoadListClass: false,
  listPosition: [],
  isLoadPosition: false,
  isMandatoryDept: false,
  mandatoryMsg: "",
  loadPayment: [],
  isLoadPayment: false,
  lisPositionRole: [],
  isLoadingListOnbehalf: false,
  listOnbehalf: [],
  isLoadingPostOnbehalf: false,
  isLoadingOnbehalfById: false,
  onBehalfById: {
    attributes: {
      name: "",
      password: "",
      email: "",
      active: true,
      parent: { id: "" }
    }
  },
  detailAnnouncement: null,
  isLoadingListAnnouncement: false,
  listAnnouncement: [],
  listEmployee: null,
  announcementById: null,
  isLoadingPostAnnouncement: false,
  setModalAnnouncementParticipants: false,
  announcementParticipantList: [],
  saveAnnouncementParticipantList: [],
  isDeleteAnnouncement: false,
  loadDeleteAnnouncement: [],
  setModalNewAnnouncement: false,
  setModalEditAnnouncement: false,
  deleteAnnouncement: [],
  isLoadingUpdateAnnouncement: false,
  isLoadingAnnouncementById: false,
  saveLog: []
};

let selected;
let selectedAccount;
let newAddress: any[];
let newAccount: any[];

let listDepartment: any[];
let listClass: any[];
let listPosition: any[];
let lisPositionRole: any[];
let result: any[] = [];

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_COMPANY_DETAIL_PENDING:
      return { ...state, isLoading: true };
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        loadCompanyDetail: payload.data,
        logoCompany: payload.data,
        isLoading: false
      };
    case GET_COMPANY_DETAIL_ERROR:
      return { ...state, isLoading: false };

    case POST_COMPANY:
      return { ...state, isLoadCompanyDetail: false };

    case DELETE_ACCOUNT_COMPANY_PENDING:
      return { ...state, isLoadCompanyDetail: true };
    case DELETE_ACCOUNT_COMPANY_SUCCESS:
      return {
        ...state,
        isLoadCompanyDetail: false,
        loadCompanyDetail: {
          ...state.loadCompanyDetail,
          company_account: state.loadCompanyDetail.company_account.filter(
            e => e.id !== payload.data
          )
        }
      };
    case DELETE_ACCOUNT_COMPANY_ERROR:
      return { ...state, isLoadCompanyDetail: false };

    case DELETE_ADDRESS:
      return { ...state, isLoadCompanyDetail: false };
    case PRIMARY_ADDRESS:
      // selected = { ...state.loadCompanyDetail };
      // selected.addresses[payload.data].is_primary = true;
      newAddress = [];
      selected = [...state.loadCompanyDetail.addresses];
      selected.map((e, i) => {
        newAddress = [...newAddress, { ...e, is_primary: payload.index === i }];
        return newAddress;
      });

      return {
        ...state,
        loadCompanyDetail: {
          ...state.loadCompanyDetail,
          addresses: newAddress
        },
        isLoadCompanyDetail: false
      };
    case PRIMARY_ACCOUNT:
      newAccount = [];
      selectedAccount = [...state.loadCompanyDetail.company_account];
      selectedAccount.map((e, i) => {
        newAccount = [...newAccount, { ...e, primary: payload.index === i }];
        return newAccount;
      });
      return {
        ...state,
        isLoadCompanyDetail: true,
        loadCompanyDetail: {
          ...state.loadCompanyDetail,
          company_account: newAccount
        }
      };
    case DEPARTMENT_PENDING:
      return { ...state, isLoadingDepartment: true };
    case DEPARTMENT_SUCCESS:
      return {
        ...state,
        loadDepartment: payload.data,
        isLoadingDepartment: false
      };
    case DEPARTMENT_ERROR:
      return {
        ...state,
        isLoadingDepartment: false
      };
    case DEPARTMENT_ADD_PENDING:
      return { ...state, isLoadingDepartmentAdd: true };
    case DEPARTMENT_ADD_SUCCESS:
      return {
        ...state,
        loadDepartmentAdd: payload.data,
        isLoadingDepartmentAdd: false
      };
    case DEPARTMENT_ADD_ERROR:
      return { ...state, isLoadingDepartmentAdd: false };
    case CLASS_DIVISION_PENDING:
      return { ...state, isLoadDivision: true };
    case CLASS_DIVISION_SUCCESS:
      return { ...state, loadDivision: payload.data, isLoadDivision: false };
    case CLASS_DIVISION_ERROR:
      return { ...state, isLoadDivision: false };
    case GET_ROLE_PENDING:
      return { ...state, isLoadRole: true };
    case GET_ROLE_SUCCESS:
      return { ...state, loadRole: payload.data, isLoadRole: false };
    case GET_ROLE_ERROR:
      return { ...state, isLoadRole: false };
    case GET_ROLE_POSITION_PENDING:
      return { ...state, isLoadRolePosition: true };
    case GET_ROLE_POSITION_SUCCESS:
      return {
        ...state,
        loadRolePosition: payload.data,
        isLoadRolePosition: false
      };
    case GET_ROLE_POSITION_ERROR:
      return { ...state, isLoadRolePosition: false };
    case ADD_POSITION_PENDING:
      return { ...state, isLoadAddPosition: true };
    case ADD_POSITION_SUCCESS:
      return {
        ...state,
        loadAddPosition: payload.data,
        isLoadAddPosition: false
      };
    case ADD_POSITION_ERROR:
      return { ...state, isLoadAddPosition: false };
    case GET_DEPARTMENT_POSITION_PENDING:
      return { ...state, isLoadDepartmentPost: true };
    case GET_DEPARTMENT_POSITION_SUCCESS:
      return {
        ...state,
        isLoadDepartmentPost: false,
        loadDepartmentPost: payload.data
      };
    case GET_DEPARTMENT_POSITION_ERROR:
      return { ...state, isLoadDepartmentPost: false };

    case SET_IMAGE_COMPANY:
      return {
        ...state,
        imageString: payload.data ? URL.createObjectURL(payload.data) : "",
        imageFile: payload.data
      };

    case POST_COMPANIES_PENDING:
      return { ...state, isLoadingPostCompanies: true };
    case POST_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoadingPostCompanies: false,
        loadCompanyDetail: payload.data
      };
    case POST_COMPANIES_ERROR:
      return { ...state, isLoadingPostCompanies: false };

    case GET_ALL_BANK_PENDING:
      return { ...state, isLoadingBank: true };
    case GET_ALL_BANK_SUCCESS:
      return {
        ...state,
        listBank: payload.data,
        isLoadingBank: false
      };
    case GET_ALL_BANK_ERROR:
      return { ...state, isLoadingBank: false };

    case SET_PRIMARY_BANK_PENDING:
      return { ...state, isLoadingSetPrimaryBank: true };
    case SET_PRIMARY_BANK_SUCCESS:
      result = [];
      state.loadCompanyDetail.company_account.map((item: any) => {
        result = [...result, { ...item, primary: item.id === payload.data }];
        return true;
      });

      return {
        ...state,
        isLoadingSetPrimaryBank: false,
        loadCompanyDetail: {
          ...state.loadCompanyDetail,
          company_account: result
        }
      };
    case SET_PRIMARY_BANK_ERROR:
      return { ...state, isLoadingSetPrimaryBank: false };
    case DELETE_POSITION_PENDING:
      return { ...state, isDeletePosition: true };
    case DELETE_POSITION_SUCCESS:
      return {
        ...state,
        isDeletePosition: false,
        deletePosition: payload.data
      };
    case DELETE_POSITION_ERROR:
      return { ...state, isDeletePosition: false };
    case UPDATE_POSITION_PENDING:
      return { ...state, isUpdatePosition: true };
    case UPDATE_POSITION_SUCCESS:
      return {
        ...state,
        isUpdatePosition: false,
        updatePosition: payload.data
      };
    case UPDATE_POSITION_ERROR:
      return {
        ...state,
        isUpdatePosition: false
      };
    case GET_DETAIL_POSITION_PENDING:
      return { ...state, isDetailPosition: true };
    case GET_DETAIL_POSITION_SUCCESS:
      return { ...state, isDetailPosition: true, detailPosition: payload.data };
    case GET_DETAIL_POSITION_ERROR:
      return { ...state, isDetailPosition: false };
    case ADD_DEPARTMENT_PENDING:
      return { ...state, isAddDepartment: true };
    case ADD_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isAddDepartment: false,
        loadAddPosition: payload.data
      };
    case ADD_DEPARTMENT_ERROR:
      return { ...state, isAddDepartment: false };
    case GET_DETAIL_DEPARTMENT_PENDING:
      return { ...state, isDetailDepartment: true };
    case GET_DETAIL_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isDetailDepartment: false,
        loadDetailDepartment: payload.data
      };
    case GET_DETAIL_DEPARTMENT_ERROR:
      return { ...state, isDetailDepartment: false };
    case UPDATE_DEPARTMENT_PENDING:
      return { ...state, isUpdateDepartment: true };
    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isUpdateDepartment: false,
        loadUpdateDepartment: payload.data
      };
    case UPDATE_DEPARTMENT_ERROR:
      return { ...state, isUpdateDepartment: false };
    case DELETE_DEPARTMENT_PENDING:
      return { ...state, isDeleteDepartment: true };
    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        isDeleteDepartment: false,
        loadDeleteDepartment: payload.data
      };
    case DELETE_DEPARTMENT_ERROR:
      return { ...state, isDeleteDepartment: false };
    case DELETE_DEPARTMENT_MANDATORY:
      return { ...state, isMandatoryDept: true, mandatoryMsg: payload.error };
    case GET_LIST_DEPARTMENT_PENDING:
      return { ...state, isLoadListDepartment: true };
    case GET_LIST_DEPARTMENT_SUCCESS:
      listDepartment = [];
      payload.data.map((item: any) => {
        listDepartment = [
          ...listDepartment,
          { value: item.id, label: item.attributes.title }
        ];
        return true;
      });
      return { ...state, isLoadListDepartment: false, listDepartment };
    case GET_LIST_DEPARTMENT_ERROR:
      return { ...state, isLoadListDepartment: false };
    case GET_LIST_CLASS_PENDING:
      return { ...state, isLoadListClass: true };
    case GET_LIST_CLASS_SUCCESS:
      listClass = [];
      payload.data.map((item: any) => {
        listClass = [
          ...listClass,
          { value: item.id, label: item.attributes.title }
        ];
        return true;
      });
      return { ...state, isLoadListClass: false, listClass };
    case GET_LIST_CLASS_ERROR:
      return { ...state, isLoadListClass: false };
    case GET_LIST_POSITION_PENDING:
      return { ...state, isLoadPosition: true };
    case GET_LIST_POSITION_SUCCESS:
      listPosition = [{ value: "", label: "None" }];
      lisPositionRole = [];
      payload.data.map((item: any) => {
        listPosition = [...listPosition, { value: item.id, label: item.title }];
        lisPositionRole = [
          ...lisPositionRole,
          { value: item.id, label: item.title }
        ];
        return true;
      });
      return { ...state, isLoadPosition: false, listPosition, lisPositionRole };
    case GET_LIST_POSITION_ERROR:
      return { ...state, isLoadPosition: false };
    case GET_PAYMENT_METHODE_PENDING:
      return { ...state, isLoadPayment: true };
    case GET_PAYMENT_METHODE_SUCCESS:
      return { ...state, isLoadPayment: false, loadPayment: payload.data };
    case GET_PAYMENT_METHODE_ERROR:
      return { ...state, isLoadPayment: false };

    // GET ONBEHALF ACCOUNR
    case GET_LIST_ONBEHALF_PENDING:
      return { ...state, isLoadingListOnbehalf: true };
    case GET_LIST_ONBEHALF_SUCCESS:
      return {
        ...state,
        listOnbehalf: payload.data,
        isLoadingListOnbehalf: false
      };
    case GET_LIST_ONBEHALF_ERROR:
      return {
        ...state,
        isLoadingListOnbehalf: false
      };

    // GET ONBEHALF BY ID
    case GET_LIST_ONBEHALF_BY_ID_PENDING:
      return { ...state, isLoadingOnbehalfById: true };
    case GET_LIST_ONBEHALF_BY_ID_SUCCESS:
      return {
        ...state,
        onBehalfById: payload.data,
        isLoadingOnbehalfById: false
      };
    case GET_LIST_ONBEHALF_BY_ID_ERROR:
      return {
        ...state,
        isLoadingOnbehalfById: false
      };

    // Post Onbehalf
    case POST_ONBEHALF_PENDING:
      return { ...state, isLoadingPostOnbehalf: true };
    case POST_ONBEHALF_SUCCESS:
      return {
        ...state,
        isLoadingPostOnbehalf: false
      };
    case POST_ONBEHALF_ERROR:
      return { ...state, isLoadingPostOnbehalf: false };

    // RESET ONBEHALF
    case RESET_ONBYHALF_BY_ID:
      return {
        ...state,
        onBehalfById: initialState.onBehalfById
      };

    // ANNOUNCEMENT
    // GET ANNOUNCEMENT LIST
    case GET_LIST_ANNOUNCEMENT_PENDING:
      return { ...state, isLoadingListAnnouncement: true };
    case GET_LIST_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        listAnnouncement: payload.data,
        isLoadingListAnnouncement: false
      };
    case GET_LIST_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        isLoadingListAnnouncement: false
      };

    // GET ANNOUNCEMENT BY ID
    case GET_ANNOUNCEMENT_BY_ID_PENDING:
      return { ...state, isLoadingAnnouncementById: true };
    case GET_ANNOUNCEMENT_BY_ID_SUCCESS:
      if (payload.data.employee === undefined) {
        payload.data.employee = [];
      }
      return {
        ...state,
        announcementById: payload.data,
        detailAnnouncement: payload.data,
        announcementParticipantList: payload.data.employee,
        saveAnnouncementParticipantList: payload.data.employee,
        isLoadingAnnouncementById: false
      };
    case GET_ANNOUNCEMENT_BY_ID_ERROR:
      return {
        ...state,
        isLoadingAnnouncementById: false
      };

    // POST ANNOUNCEMENT
    case POST_ANNOUNCEMENT_PENDING:
      return { ...state, isLoadingPostAnnouncement: true };
    case POST_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoadingPostAnnouncement: false
      };
    case POST_ANNOUNCEMENT_ERROR:
      return { ...state, isLoadingPostAnnouncement: false };

    case SET_MODAL_ANNOUNCEMENT_PARTICIPANT:
      return { ...state, setModalAnnouncementParticipants: payload.data };
    case GET_ADD_ANNOUNCEMENT_PARTICIPANT:
      return {
        ...state,
        announcementParticipantList: state.saveAnnouncementParticipantList
      };
    case SET_ADD_ANNOUNCEMENT_PARTICIPANT:
      return {
        ...state,
        announcementParticipantList: [
          ...state.announcementParticipantList,
          payload.data
        ]
      };
    case SAVE_ADD_ANNOUNCEMENT_PARTICIPANT:
      return {
        ...state,
        saveAnnouncementParticipantList: state.announcementParticipantList,
        announcementParticipantList: initialState.announcementParticipantList
      };

    case RESET_ANNOUNCEMENT_PARTICIPANT:
      return {
        ...state,
        announcementParticipantList: initialState.announcementParticipantList,
        saveAnnouncementParticipantList:
          initialState.saveAnnouncementParticipantList
      };
    case SET_DELETE_ANNOUNCEMENT_PARTICIPANT:
      return {
        ...state,
        announcementParticipantList: state.announcementParticipantList.filter(
          e => e.id !== payload.data
        )
      };
    // DELETE ANNOUNCEMENT
    case DELETE_ANNOUNCEMENT_PENDING:
      return { ...state, isDeleteAnnouncement: true };
    case DELETE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isDeleteAnnouncement: false,
        deleteAnnouncement: payload.data
      };
    case DELETE_ANNOUNCEMENT_ERROR:
      return { ...state, isDeleteAnnouncement: false };

    case UPDATE_ANNOUNCEMENT_PENDING:
      return {
        ...state,
        isLoadingUpdateAnnouncement: true
      };
    case UPDATE_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoadingUpdateAnnouncement: false,
        saveLog: [
          ...state.saveLog,
          {
            date: payload.data.data.date,
            message: payload.data.message,
            success: payload.data.success,
            type: "Update Announcement"
          }
        ]
      };
    case UPDATE_ANNOUNCEMENT_ERROR:
      return {
        ...state,
        isLoadingUpdateAnnouncement: false
      };
    case UPDATE_DETAIL_ANNOUNCEMENT_FORM:
      return {
        ...state,
        detailAnnouncement: {
          ...state.detailAnnouncement,
          [payload.data.formName]: payload.data.value,
          isEdit: true
        },
        isEdit: true
      };

    // MODAL
    case SET_MODAL_ANNOUNCEMENT:
      return { ...state, setModalNewAnnouncement: payload.data };
    case SET_MODAL_EDIT_ANNOUNCEMENT:
      return { ...state, setModalEditAnnouncement: payload.data };

    default:
      return state;
  }
};
