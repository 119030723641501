import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import { Reducers } from "../../../../redux/types";
import {
  getAnnouncementById,
  updateDetailAnnouncementForm
} from "../../../../redux/actions";
import { documentTitle, textToLink } from "../../../../utils";
import "react-toastify/dist/ReactToastify.css";
import { Container, HeaderTittle } from "../../../../components";
import { IMAGES } from "../../../../configs";

import "./styles.scss";

const Detail = () => {
  documentTitle("Announcement - Detail");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id } = useParams() as any;

  const { announcementState } = useSelector(
    (state: Reducers) => ({
      announcementState: state.company,
      myProfile: state.home,
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAnnouncementById(String(id)));
  }, [dispatch, id]);

  return (
    <>
      <Container className="page-announcement-detail">
        <Container withMaxWidth scroll>
          <ToastContainer limit={1} />
          <HeaderTittle title="Company" />
          <div className="company-navbar">
            <NavLink
              exact
              className="navbar-item"
              activeClassName="active"
              to={`/company/announcement/detail/${id}`}
            >
              {t("company.tab.announcementDetail")}
            </NavLink>
          </div>
          <div className="page-announcement-detail-my-announce page-announcement-detail paper margin-top-28">
            <div className="users margin-right-72">
              <label>{t("company.form.announceBy")}</label>
              <div className="user margin-top-16">
                <img
                  alt="avatar"
                  src={IMAGES.avatar}
                  className="margin-right-8"
                />
                <span className="value-announcement-text">
                  {announcementState.detailAnnouncement
                    ? announcementState.detailAnnouncement.creator.name
                    : "-"}
                </span>
              </div>
              <label>{t("company.form.announceTo")}</label>
              {announcementState.detailAnnouncement &&
              announcementState.detailAnnouncement.users ? (
                announcementState.detailAnnouncement.users.map(
                  (item: any, index: number) => (
                    <div className="user margin-top-16" key={index}>
                      <img
                        alt="avatar"
                        src={
                          item && item.avatar ? item.avatar.url : IMAGES.avatar
                        }
                        className="margin-right-8"
                      />
                      <span>
                        {announcementState.detailAnnouncement ? item.name : "-"}
                      </span>
                    </div>
                  )
                )
              ) : (
                <div className="user margin-top-16">
                  <span>
                    <b>{t("all").toUpperCase()}</b>
                  </span>
                </div>
              )}
            </div>
            <div style={{ width: "100%" }}>
              <div className="forms">
                <div className="form-group margin-bottom-20">
                  <label>{t("company.form.subject")}</label>
                  <span className="value-announcement-text">
                    {announcementState.detailAnnouncement &&
                    announcementState.detailAnnouncement.title
                      ? announcementState.detailAnnouncement.title
                      : "-"}
                  </span>
                </div>
                <div className="form-group margin-bottom-20">
                  <label>{t("personnel.form.startDate")}</label>
                  <span>
                    {announcementState.detailAnnouncement
                      ? moment(
                          announcementState.detailAnnouncement.started_at
                        ).format("dddd, DD MMM YYYY")
                      : "-"}
                  </span>
                </div>
                <div className="form-group margin-bottom-20">
                  <label>{t("company.form.endDate")}</label>
                  <div>
                    <div className="grey">
                      <span style={{ fontWeight: "bold" }}>
                        {announcementState.detailAnnouncement &&
                          moment(
                            announcementState.detailAnnouncement.finished_at
                          ).format("dddd, DD MMM YYYY")}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="form-group margin-bottom-20">
                  <label>{t("task.form.description")}</label>
                  <div
                    className="shadow text-area textarea-desc text-break desc"
                    dangerouslySetInnerHTML={textToLink(
                      announcementState.detailAnnouncement
                        ? announcementState.detailAnnouncement.description
                        : ""
                    )}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      dispatch(
                        updateDetailAnnouncementForm({
                          formName: "description",
                          value: e.target.value
                        })
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Container>
    </>
  );
};

export default Detail;
