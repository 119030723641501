import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../redux/types";

import { setSetting } from "../../../../redux/actions";

import { Button } from "../../../../components";

const ArrayInput = () => {
  const dispatch = useDispatch();
  const settingState = useSelector((state: Reducers) => state.setting);
  const [valueAddArray, setValueAddArray]: any = useState("");

  const deleteArrayValue = (data: any, index: number) => {
    data.splice(index, 1);
    dispatch(setSetting("value", data));
  };

  const addArrayValue = (data: any, value?: any) => {
    const addedData = [...data, value || valueAddArray];
    dispatch(setSetting("value", addedData));
    setValueAddArray("");
  };

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>Value</p>
      {settingState.settingDetail.data ? (
        settingState.settingDetail.value.map((_: any, index: number) => (
          <div key={index} style={{ marginTop: 8 }}>
            <input
              style={{ color: "grey" }}
              type="time"
              value={settingState.settingDetail.value[index]}
              onChange={e => {
                const res = settingState.settingDetail.value;
                res[index] = e.target.value;
                dispatch(setSetting("value", res));
              }}
            />
            <div className="setting-list-line" />
          </div>
        ))
      ) : (
        <div style={{ marginTop: -12 }}>
          <div style={{ flexDirection: "row", display: "flex" }}>
            <div>
              <input
                style={{ width: "100%", color: "grey" }}
                value={valueAddArray}
                onChange={(e: any) => setValueAddArray(e.target.value)}
              />
              <div className="setting-list-line margin-bottom-20" />
            </div>
            <Button
              className="setting-list-btn-add-array"
              onPress={() => {
                valueAddArray &&
                  addArrayValue(settingState.settingDetail.value);
              }}
            >
              <p className="setting-list-btn-add-array-icon">+</p>
            </Button>
          </div>
          <div className="setting-list-container-capsules">
            {settingState.settingDetail.value.map(
              (item: any, index: number) => (
                <div className="setting-list-capsule" key={index}>
                  <p className="setting-list-text-capsule">{item}</p>
                  <div className="setting-list-container-del-capsule">
                    <div
                      onClick={() =>
                        deleteArrayValue(
                          settingState.settingDetail.value,
                          index
                        )
                      }
                      className="setting-list-del-icon"
                    >
                      x
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ArrayInput;
