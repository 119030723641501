import { API } from "../../configs";

export const is_hidden = (hidden: any) => {
  return dispatch => {
    dispatch({
      type: "IS_HIDE",
      payload: {
        is_hide: hidden
      }
    });
  };
};
export const import_template = (_form: any) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true,
        save_response: false,
        delete_response: false,
        message_response: null
      }
    });

    API.importTemplate(_form)
      .then(response => {
        dispatch({
          type: "SAVE_RESPONSE",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "SAVE_RESPONSE",
          payload: err.response
        });
      });
  };
};

export const getTemplate = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true,
        save_response: false,
        delete_response: false,
        message_response: null
      }
    });
    API.downloadTemplate()
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "finance_category_template.xlsx");
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => {
        dispatch({
          type: "SAVE_RESPONSE",
          payload: err.response
        });
      });
  };
};

export const removeData = (type: any, uuid: any) => {
  return dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true,
        save_response: false,
        delete_response: false,
        message_response: null
      }
    });

    let __function = "";
    switch (type) {
      case "finance_category":
        __function = "deleteExpenseType";
        break;
      case "category":
        __function = "deleteProjectCategory";
        break;
      case "trip_category":
        __function = "deleteActivityCategory";
        break;
      case "payment_method":
        __function = "deletePaymentCategory";
        break;
      default:
        break;
    }

    if (__function !== "") {
      API[__function](uuid)
        .then(response => {
          dispatch({
            type: "DELETE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "DELETE_RESPONSE",
            payload: err.response
          });
        });
    }
  };
};

export const saveActivity = (_form: any, uuid: any) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    if (uuid == null) {
      API.createActivityCategory(_form)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    } else {
      API.editActivityCategory(_form, uuid)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    }
  };
};

export const saveProject = (_form: any, uuid: any) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    if (uuid == null) {
      API.createProjectCategory(_form)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    } else {
      API.editProjectCategory(_form, uuid)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    }
  };
};

export const savePayment = (_form: any, uuid: any) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    if (uuid == null) {
      API.createPaymentCategory(_form)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    } else {
      API.editPaymentCategory(_form, uuid)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    }
  };
};

export const resetResponse = () => {
  return async dispatch => {
    dispatch({
      type: "SAVE_RESPONSE",
      payload: {
        save_response: null,
        message_response: null,
        data_response: null,
        data: null
      }
    });
  };
};

export const saveExpense = (_form: any, uuid: any) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    if (uuid == null) {
      API.createExpenseType(_form)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    } else {
      API.editExpenseType(_form, uuid)
        .then(response => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: response
          });
        })
        .catch(err => {
          dispatch({
            type: "SAVE_RESPONSE",
            payload: err.response
          });
        });
    }
  };
};

export const getListPaymentCategory = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getPopulatePaymentMethod()
      .then(response => {
        dispatch({
          type: "LIST_PAYMENT_CATEGORY",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "LIST_PAYMENT_CATEGORY",
          payload: err.response
        });
      });
  };
};

export const getListMasterCurrency = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getMasterCurrency()
      .then(response => {
        dispatch({
          type: "LIST_CURRENCY",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "LIST_CURRENCY",
          payload: err.response
        });
      });
  };
};

export const getpaymentById = uuid => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getPaymentCategoryById(uuid)
      .then(response => {
        dispatch({
          type: "SEARCH_DATA",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "SEARCH_DATA",
          payload: err.response
        });
      });
  };
};

export const getPopulateBank = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });
    await API.getMasterBank()
      .then(response => {
        dispatch({
          type: "LIST_BANK",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "LIST_BANK",
          payload: err.response
        });
      });
  };
};

export const getActivityById = uuid => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getActivityCategoryById(uuid)
      .then(response => {
        dispatch({
          type: "SEARCH_DATA",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "SEARCH_DATA",
          payload: err.response
        });
      });
  };
};

export const getProjectById = uuid => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getProjectCategoryById(uuid)
      .then(response => {
        dispatch({
          type: "SEARCH_DATA",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "SEARCH_DATA",
          payload: err.response
        });
      });
  };
};

export const getListParentCategory = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getListParent()
      .then(response => {
        dispatch({
          type: "LIST_PARENT",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "LIST_PARENT",
          payload: err.response
        });
      });
  };
};

export const getListUnit = () => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getMasterUnit()
      .then(response => {
        dispatch({
          type: "LIST_UNIT",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "LIST_UNIT",
          payload: err.response
        });
      });
  };
};

export const isProduct = (choosen: boolean) => {
  return async dispatch => {
    dispatch({
      type: "IS_PRODUCT",
      payload: {
        is_product: choosen
      }
    });
  };
};

export const isHide = (choosen: boolean) => {
  return async dispatch => {
    dispatch({
      type: "IS_HIDE",
      payload: {
        is_hide: choosen
      }
    });
  };
};

export const isDirectItem = (choosen: boolean) => {
  return async dispatch => {
    dispatch({
      type: "IS_DIRECT_ITEM",
      payload: {
        is_direct_item: choosen
      }
    });
  };
};

export const getExpenseById = uuid => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    await API.getExpenseCategoryById(uuid)
      .then(response => {
        dispatch({
          type: "SEARCH_DATA",
          payload: response
        });
      })
      .catch(err => {
        dispatch({
          type: "SEARCH_DATA",
          payload: err.response
        });
      });
  };
};

export const searchData = (path, keyword, page = 1) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    switch (path) {
      case "expenseType":
        await API.searchExpenseType(page, keyword)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      case "projectCategory":
        await API.searchProjectCategory(page, keyword)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      case "activityCategory":
        await API.searchActivityCategory(page, keyword)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      case "paymentCategory":
        await API.searchPaymentCategory(page, keyword)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      default:
        break;
    }
  };
};

export const getList = (_type, _page = 1, hidden = false) => {
  return async dispatch => {
    dispatch({
      type: "RELOAD",
      payload: {
        is_loading: true
      }
    });

    switch (_type) {
      case "expenseType":
        await API.getListExpenseType(_page, hidden)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });

        break;
      case "projectCategory":
        await API.getListProjectCategory(_page)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });

        break;
      case "activityCategory":
        await API.getListActivityCategory(_page)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      case "paymentCategory":
        await API.getListPaymentCategory(_page)
          .then(response => {
            dispatch({
              type: "LIST_DATA",
              payload: response
            });
          })
          .catch(err => {
            dispatch({
              type: "LIST_DATA",
              payload: err.response
            });
          });
        break;
      default:
        break;
    }
  };
};
