import { debounce } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, Loading, Modal, Search } from "../../../components";
import {
  ArrayInput,
  ArrayObjectInput,
  BooleanInput,
  CopywritingInput,
  DropdownInput,
  NumberingInput
} from "../components";
import { IMAGES } from "../../../configs";

import {
  getSettingDetail,
  getSettingList,
  getSettingLookup,
  postResetSetting,
  postSetting,
  setSetting
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import "./styles.scss";

const List = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settingState = useSelector((state: Reducers) => state.setting);
  const [openModal, setOpenModal] = useState(false);
  const [openModalReset, setOpenModalReset] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(getSettingList(1));
  }, [dispatch]);

  const handleOpenModal = (id: number, lookup: any) => {
    if (lookup) {
      dispatch(getSettingLookup(lookup));
    }
    dispatch(getSettingDetail(id, () => setOpenModal(true)));
  };

  const searchText = useCallback(
    debounce((text: any) => {
      setSearchValue(text);
      dispatch(getSettingList(1, text));
    }, 1000),
    []
  );

  const handleRenderValue = () => {
    if (settingState.settingDetail && settingState.settingDetail.value) {
      if (
        settingState.lookupValue &&
        settingState.lookupValue.length > 0 &&
        settingState.settingDetail.data &&
        settingState.settingDetail.data.lookup_url
      ) {
        if (settingState.settingDetail.datatype === "array-object") {
          return <ArrayObjectInput />;
        }
        return <DropdownInput />;
      }
    }
    if (
      settingState.settingDetail &&
      settingState.settingDetail.data &&
      settingState.settingDetail.slug === "feature-dictionary"
    ) {
      return <CopywritingInput />;
    }
    if (
      settingState.settingDetail &&
      settingState.settingDetail.data &&
      settingState.settingDetail.data.type === "numbering"
    ) {
      return <NumberingInput />;
    }
    if (
      settingState.settingDetail &&
      (settingState.settingDetail.datatype === "integer" ||
        settingState.settingDetail.datatype === "float" ||
        settingState.settingDetail.datatype === "string")
    ) {
      return (
        <div>
          <p style={{ fontWeight: "bold" }}>Value</p>
          <input
            value={
              settingState.settingDetail && settingState.settingDetail.value
            }
            onChange={e => dispatch(setSetting("value", e.target.value))}
            style={{ paddingBottom: 12 }}
            className="component-modal-input"
          />
        </div>
      );
    }
    if (
      settingState.settingDetail &&
      settingState.settingDetail.datatype === "array"
    ) {
      return <ArrayInput />;
    }
    if (
      settingState.settingDetail &&
      settingState.settingDetail.datatype === "boolean"
    ) {
      return <BooleanInput />;
    }
    return null;
  };

  const renderValueTable = (data: any) => {
    if (
      ["object", "array-object", "array"].includes(data.datatype) ||
      (data.datatype === "integer" && data.data)
    ) {
      return "-Click edit to see value-";
    }
    if (data.datatype === "boolean") {
      return String(data.value);
    }
    return data.value;
  };

  const handleSubmit = () => {
    let valueJSON: any = [];
    if (
      settingState.settingDetail.data &&
      settingState.settingDetail.data.type === "numbering"
    ) {
      settingState.settingDetail &&
        settingState.settingDetail.value.map((item: any) => {
          if (item.type === "month" && item.value === "January") {
            valueJSON = [...valueJSON, { type: "month", value: 1 }];
          } else if (item.type === "month" && item.value === "Jan") {
            valueJSON = [...valueJSON, { type: "month", value: 2 }];
          } else if (item.type === "month" && item.value === "01") {
            valueJSON = [...valueJSON, { type: "month", value: 3 }];
          } else if (item.type === "month" && item.value === "1") {
            valueJSON = [...valueJSON, { type: "month", value: 4 }];
          } else if (item.type === "month" && item.value === "IV") {
            valueJSON = [...valueJSON, { type: "month", value: 5 }];
          } else if (item.type === "year" && item.value === "2022") {
            valueJSON = [...valueJSON, { type: "year", value: 1 }];
          } else if (item.type === "year" && item.value === "22") {
            valueJSON = [...valueJSON, { type: "year", value: 2 }];
          } else {
            valueJSON = [...valueJSON, item];
          }
          return null;
        });
      valueJSON = JSON.stringify(valueJSON);
    } else if (
      settingState.settingDetail.slug === "feature-dictionary" ||
      settingState.settingDetail.datatype === "array-object"
    ) {
      valueJSON = JSON.stringify(
        settingState.settingDetail && settingState.settingDetail.value
      );
    }

    dispatch(
      postSetting(
        settingState.settingDetail && settingState.settingDetail.id,
        () => {
          dispatch(
            getSettingList(
              settingState.paginationListSetting.current_page,
              searchValue
            )
          );
          setOpenModal(false);
        },
        settingState.settingDetail.data &&
          (settingState.settingDetail.data.type === "numbering" ||
            settingState.settingDetail.slug === "feature-dictionary" ||
            settingState.settingDetail.datatype === "array-object")
          ? valueJSON
          : settingState.settingDetail && settingState.settingDetail.value
      )
    );
  };

  const handleResetSetting = () => {
    dispatch(
      postResetSetting(
        settingState.settingDetail && settingState.settingDetail.id,
        () => {
          dispatch(
            getSettingList(
              settingState.paginationListSetting.current_page,
              searchValue
            )
          );
          setOpenModal(false);
          setOpenModalReset(false);
        }
      )
    );
  };

  return (
    <div>
      <div className="setting-list-container-search">
        <Search onChange={(e: any) => searchText(e)} />
      </div>
      <Loading
        isVisible={
          settingState.isLoadingListSetting ||
          settingState.isLoadingSettingDetail
        }
      />
      <table className="data-table">
        <thead>
          <tr>
            <th style={{ width: "40%" }}>
              {String(t("setting.title")).toUpperCase()}
            </th>
            <th>{String(t("setting.type")).toUpperCase()}</th>
            <th style={{ width: 300 }}>
              {String(t("setting.value")).toUpperCase()}
            </th>
            <th>{String(t("setting.lastUpdate")).toUpperCase()}</th>
            <th colSpan={2} className="loan-profile-list-regular-text">
              {settingState.paginationListSetting ? (
                <div>
                  <div className="loan-profile-list-pagging">{`${settingState.paginationListSetting.from} - ${settingState.paginationListSetting.to} of ${settingState.paginationListSetting.total}`}</div>
                  {settingState.paginationListSetting.current_page > 1 && (
                    <div
                      onClick={() =>
                        dispatch(
                          getSettingList(
                            settingState.paginationListSetting.current_page - 1
                          )
                        )
                      }
                      className="loan-profile-list-pagging arrow pointer"
                    >
                      {"<"}
                    </div>
                  )}
                  {settingState.paginationListSetting.current_page <
                    settingState.paginationListSetting.last_page && (
                    <div
                      onClick={() =>
                        dispatch(
                          getSettingList(
                            settingState.paginationListSetting.current_page + 1
                          )
                        )
                      }
                      className="loan-profile-list-pagging arrow pointer"
                    >
                      {">"}
                    </div>
                  )}
                </div>
              ) : null}
            </th>
          </tr>

          {settingState.listSetting.map((item: any, index: number) => (
            <tr key={index}>
              <td>{item.title}</td>
              <td>{item.datatype}</td>
              <td>{renderValueTable(item)}</td>
              <td>
                {item.updater && (
                  <div>
                    <p>{moment(item.updated_at).format("DD MMMM YYYY")}</p>
                    <p>{`by ${item.updater.name}`}</p>
                  </div>
                )}
              </td>
              <td>
                <Button
                  onPress={() =>
                    handleOpenModal(item.id, item.data && item.data.lookup_url)
                  }
                >
                  <img alt="warn" src={IMAGES.pencil} />
                </Button>
              </td>
            </tr>
          ))}
        </thead>
      </table>
      <Modal
        isOpen={openModal}
        onPressCancel={() => setOpenModal(false)}
        titleModal={String(t("setting.editSetting"))}
        onPressSubmit={() => handleSubmit()}
        customBtn
        loading={settingState.isLoadingPostSetting}
        txtCustomBtn={String(t("setting.reset"))}
        btnSubmitText={String(t("setting.submit"))}
        btnCancelText={String(t("setting.cancel"))}
        onPressCustomBtn={() => setOpenModalReset(true)}
      >
        <div style={{ width: 600, marginTop: 20 }}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "49%" }}>
              <p style={{ fontWeight: "bold" }}>Slug</p>
              <p>
                {settingState.settingDetail && settingState.settingDetail.slug}
              </p>
            </div>
            <div style={{ width: "49%" }}>
              <p style={{ fontWeight: "bold" }}>{String(t("setting.type"))}</p>
              <p>
                {settingState.settingDetail &&
                  settingState.settingDetail.datatype}
              </p>
            </div>
          </div>
          <div style={{ flexDirection: "row" }}>
            <div>
              <p style={{ fontWeight: "bold" }}>{String(t("setting.title"))}</p>
              <p>
                {settingState.settingDetail && settingState.settingDetail.title}
              </p>
            </div>
          </div>
          <div style={{ flexDirection: "row" }}>
            <div>
              <p style={{ fontWeight: "bold" }}>
                {String(t("setting.description"))}
              </p>
              <p>
                {(settingState.settingDetail &&
                  settingState.settingDetail.description) ||
                  "-"}
              </p>
            </div>
          </div>
          {handleRenderValue()}
        </div>
      </Modal>
      <Modal
        isOpen={openModalReset}
        onPressCancel={() => setOpenModalReset(false)}
        titleModal={String(t("setting.resetSetting"))}
        onPressSubmit={() => handleResetSetting()}
        warning
        loading={settingState.isLoadingResetSetting}
        btnSubmitText={String(t("setting.submit"))}
        btnCancelText={String(t("setting.cancel"))}
      >
        <div
          style={{
            width: 600,
            marginTop: 20,
            alignItems: "center",
            justifyContent: "center",
            display: "flex"
          }}
        >
          <span>{String(t("setting.resetConfig"))}</span>
        </div>
      </Modal>
    </div>
  );
};

export default List;
