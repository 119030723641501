import {
  GET_COMPANY_TREE_ERROR,
  GET_COMPANY_TREE_LOADING,
  GET_COMPANY_TREE_SUCCESS
} from "../actions";
import { Action, CompanyTreeState } from "../types";

const initialState: CompanyTreeState = {
  isLoadingGetCompanyTree: false,
  successGetCompanyTree: null,
  errorGetCompanyTree: null
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_COMPANY_TREE_LOADING:
      return {
        ...state,
        isLoadingGetCompanyTree: true,
        successGetCompanyTree: null,
        errorGetCompanyTree: null
      };
    case GET_COMPANY_TREE_SUCCESS:
      return {
        ...state,
        successGetCompanyTree: payload.data.data,
        isLoadingGetCompanyTree: false,
        errorGetCompanyTree: null
      };
    case GET_COMPANY_TREE_ERROR:
      return {
        ...state,
        successGetCompanyTree: null,
        isLoadingGetCompanyTree: false,
        errorGetCompanyTree: payload.data
      };
    default:
      return state;
  }
};
