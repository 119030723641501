import React, { memo } from "react";
import { shallowEqual, useSelector } from "react-redux";

import "./styles.scss";
import "../../../../../../sass/styles.scss";
import { Container, Text } from "../../../../../../components";
import { IMAGES } from "../../../../../../configs";

import { Reducers } from "../../../../../../redux/types";

interface Props {
  type?: string;
}

const Widget = ({ type }: Props) => {
  const { profileByIdState, homeState, projectState } = useSelector(
    (state: Reducers) => ({
      profileByIdState: state.profileById,
      homeState: state.home,
      projectState: state.generate.projectById
    }),
    shallowEqual
  );

  const _handleGravatar = () => {
    if (type === "project") {
      return IMAGES.avatar;
    }
    if (
      profileByIdState.profileById &&
      profileByIdState.profileById.employee &&
      profileByIdState.profileById.employee.avatar
    ) {
      return profileByIdState.profileById.employee.avatar.url;
    }

    return IMAGES.avatar;
  };

  return (
    <div className="dashboard-userpage-widget-content-card">
      <Container
        isLoading={
          profileByIdState.isLoadingProfileById &&
          profileByIdState.profileById === null &&
          homeState.listWidget.isLoading
        }
      >
        <div style={{ width: "25%" }}>
          {(profileByIdState.profileById || projectState) && (
            <div className="dashboard-userpage-widget-user-person-content">
              <img
                src={_handleGravatar()}
                alt="person"
                className="dashboard-userpage-widget-user-person-photo"
              />

              <Text className="dashboard-userpage-widget-user-person-name">
                {profileByIdState.profileById && type !== "project"
                  ? profileByIdState.profileById.employee.name
                  : projectState &&
                    projectState.attributes &&
                    projectState.attributes.title}
              </Text>
              <Text className="dashboard-userpage-widget-user-person-position">
                {profileByIdState.profileById && type !== "project"
                  ? profileByIdState.profileById.employee.position_name
                  : null}
              </Text>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default memo(Widget);
