import { API } from "../../configs";

export const FindData = (data: any) => {
  return dispatch => {
    dispatch({
      type: "GET_DATA",
      payload: {
        data: {
          success: null,
          data: null,
          message: null
        }
      }
    });

    if (data.type === "task") {
      API.searchTaskByNumber(data.number)
        .then(response => {
          dispatch({
            type: "GET_DATA",
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: "GET_DATA",
            payload: error.response
          });
        });
    }
    if (data.type === "finance") {
      API.searchInvoiceByNumber(data.number)
        .then(response => {
          dispatch({
            type: "GET_DATA",
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: "GET_DATA",
            payload: error.response
          });
        });
    }
  };
};

export const DestroyData = (data: any) => {
  return dispatch => {
    if (data.type === "task") {
      API.deleteTask(data.id)
        .then(response => {
          dispatch({
            type: "DELETE_DATA",
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: "DELETE_DATA",
            payload: error.response
          });
        });
    }
    if (data.type === "finance") {
      API.deleteInvoice(data.id)
        .then(response => {
          dispatch({
            type: "DELETE_DATA",
            payload: response
          });
        })
        .catch(error => {
          dispatch({
            type: "DELETE_DATA",
            payload: error.response
          });
        });
    }
  };
};
