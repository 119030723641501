import React, { CSSProperties, ReactNode, memo } from "react";

import "./styles.scss";
import { Loading, Spinner } from "..";

interface Props {
  children: ReactNode;
  isLoading: boolean;
  style?: CSSProperties;
  withMaxWidth?: boolean;
  scroll?: boolean;
  small?: boolean;
  className?: string;
  flex?: boolean;
  typeLoading?: "default" | "modal";
}

const Container = ({
  children,
  isLoading,
  style,
  withMaxWidth,
  scroll,
  small,
  className,
  flex,
  typeLoading
}: Props) => {
  const _widthMaxWidth = () => {
    if (typeLoading === "default") {
      return isLoading ? (
        <div className="container" style={style}>
          <Spinner />
        </div>
      ) : (
        <div
          className={`container-mf ${
            scroll ? " scroll global-scroll-bar " : ""
          } ${small ? " small " : ""} ${className}`}
        >
          <div className={`content ${flex ? "flex" : ""}`}>{children}</div>
        </div>
      );
    }
    return (
      <div
        className={`container-mf ${
          scroll ? " scroll global-scroll-bar " : ""
        } ${small ? " small " : ""} ${className}`}
      >
        <Loading isVisible={isLoading} />
        <div className={`content ${flex ? "flex" : ""}`}>{children}</div>
      </div>
    );
  };

  const _normal = () => {
    if (typeLoading === "default") {
      return isLoading ? (
        <div className={`container ${className}`} style={style}>
          <Spinner />
        </div>
      ) : (
        <>{children}</>
      );
    }
    return (
      <>
        <Loading isVisible={isLoading} />
        {children}
      </>
    );
  };

  return withMaxWidth ? _widthMaxWidth() : _normal();
};

Container.defaultProps = {
  isLoading: false,
  withMaxWidth: false,
  scroll: false,
  className: "",
  flex: false,
  typeLoading: "default"
};

export default memo(Container);
