import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useTranslation } from "react-i18next";
import { CategoryPolicy, Checkbox, Picker, TimePicker } from "../../components";
import {
  Button,
  Container,
  Footer,
  HeaderTittle
} from "../../../../components";
import { MultiInput, Numbering } from "./components";
import "./styles.scss";
import { getPolicy, savePolicy, updatePolicy } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { IMAGES } from "../../../../configs";
import { copyWritings, documentTitle } from "../../../../utils";

interface FormatTime {
  policySlug: string;
  value: string;
}

const initFormatTime: FormatTime[] = [];
toast.configure();

const initMenuActive: any[] = [];

const Home = () => {
  const { t } = useTranslation();
  documentTitle("Policy");

  const [menuActive, setMenuActive] = useState(initMenuActive);
  const [hour, setHour] = useState(initFormatTime);
  const [minute, setMinute] = useState(initFormatTime);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPolicy());
  }, [dispatch]);
  const policyState = useSelector((state: Reducers) => state.policy);

  const _getValue = useCallback(
    (policy_slug: string) => {
      const getIndex = policyState.form.listForm.findIndex(
        e => e.policy_slug === policy_slug
      );
      if (getIndex >= 0) {
        return policyState.form.listForm[getIndex].value;
      }
      return null;
    },
    [policyState.form.listForm]
  );

  const _valueHour = useCallback(
    (policySlug: string) => {
      const index = hour.findIndex(e => policySlug === e.policySlug);
      return index >= 0 ? hour[index].value : "";
    },
    [hour]
  );

  const _valueMinute = useCallback(
    (policySlug: string) => {
      const index = minute.findIndex(e => policySlug === e.policySlug);
      return index >= 0 ? minute[index].value : "";
    },
    [minute]
  );

  const _setHour = useCallback(
    (policySlug: string, value: string) => {
      const index = hour.findIndex(e => policySlug === e.policySlug);
      if (index >= 0) {
        const res = [...hour];
        res[index] = { policySlug, value };
        setHour(res);
      } else {
        setHour([
          ...hour,
          {
            policySlug,
            value
          }
        ]);
      }
    },
    [hour]
  );

  const _setMinute = useCallback(
    (policySlug: string, value: string) => {
      const index = minute.findIndex(e => policySlug === e.policySlug);
      if (index >= 0) {
        const res = [...minute];
        res[index] = { policySlug, value };
        setMinute(res);
      } else {
        setMinute([
          ...minute,
          {
            policySlug,
            value
          }
        ]);
      }
    },
    [minute]
  );

  const _handleChange = useCallback(
    (policy_slug: string, value: any) => {
      dispatch(updatePolicy(policy_slug, value));
    },
    [dispatch]
  );

  const _handleChangeCheckBox = useCallback(
    (policySLug: string, allValue: any[], valuePolicy: any) => {
      if (allValue.indexOf(valuePolicy) === -1) {
        dispatch(updatePolicy(policySLug, [...allValue, valuePolicy]));
      } else {
        dispatch(
          updatePolicy(
            policySLug,
            allValue.filter((item: any) => item !== valuePolicy)
          )
        );
      }
    },
    [dispatch]
  );

  const _handleKeyPress = useCallback(
    (event: any, policySlug: string, policyValue: any[]) => {
      const valueHour = hour.find(item => item.policySlug === policySlug)
        ?.value;

      const valueMinute = minute.find(item => item.policySlug === policySlug)
        ?.value;

      if (event.key === "Enter" && valueMinute && valueHour) {
        dispatch(
          updatePolicy(policySlug, [
            ...policyValue,
            `${valueHour}:${valueMinute}`
          ])
        );
      }
    },
    [dispatch, hour, minute]
  );

  const _handleDeleteTime = useCallback(
    (el: string[], policySlug: string) => {
      dispatch(updatePolicy(policySlug, [...el]));
    },
    [dispatch]
  );

  const _handleRenderInput = useCallback(
    (
      policySlug: string,
      dataType: string,
      policyValue: any,
      listData: any,
      dropDownData: any[]
    ) => {
      if (dropDownData.length > 0) {
        return (
          <Picker
            selected={policyValue}
            listValue={dropDownData}
            onChange={el => _handleChange(policySlug, el)}
          />
        );
      }
      if ((dataType === "integer" || dataType === "float") && !listData) {
        return (
          <input
            type="number"
            className="input-policy"
            value={policyValue}
            step={dataType === "float" ? "0.001 " : "1"}
            onChange={(el: any) => _handleChange(policySlug, el.target.value)}
          />
        );
      }
      if (dataType === "boolean") {
        return (
          <Switch
            onColor="#50B1B1"
            width={40}
            height={23}
            onChange={(event: boolean) => _handleChange(policySlug, event)}
            onKeyPress={() => _handleChange(policySlug, !policyValue)}
            checked={policyValue}
            handleDiameter={20}
            uncheckedIcon={false}
            checkedIcon={false}
          />
        );
      }

      if (dataType === "debugger2010") {
        _handleKeyPress("test", policySlug, policyValue);

        _setMinute(policySlug, "el.target.value");

        _valueHour(policySlug);

        _setHour(policySlug, "el.target.value");

        _valueMinute(policySlug);

        _handleDeleteTime(["test"], policySlug);
      }

      if (dataType === "array" && !listData) {
        return (
          <>
            <TimePicker
              listTime={policyValue}
              handleEnterKeyPress={(event: any) =>
                _handleKeyPress(event, policySlug, policyValue)
              }
              onChangeHour={(el: any) => _setHour(policySlug, el.target.value)}
              onChangeMinute={(el: any) =>
                _setMinute(policySlug, el.target.value)
              }
              valueHour={_valueHour(policySlug)}
              valueMinute={_valueMinute(policySlug)}
              handleDelete={(el: string[]) => _handleDeleteTime(el, policySlug)}
            />
          </>
        );
      }
      if ((dataType === "integer" || dataType === "string") && listData) {
        return (
          <Picker
            selected={policyValue}
            listValue={listData}
            onChange={el => _handleChange(policySlug, el)}
          />
        );
      }

      if (dataType === "string" && !listData) {
        return (
          <input
            type="text"
            className="input-policy"
            value={policyValue}
            onChange={(el: any) => _handleChange(policySlug, el.target.value)}
          />
        );
      }

      return null;
    },
    [
      _handleChange,
      _handleDeleteTime,
      _handleKeyPress,
      _setHour,
      _setMinute,
      _valueHour,
      _valueMinute
    ]
  );

  const _handleRenderNumberingType = useCallback(
    (value: any[], policySLug: string) => {
      return (
        <Numbering
          policySlug={policySLug}
          handleChange={_handleChange}
          value={value}
        />
      );
    },
    [_handleChange]
  );

  const _handleActiveCard = useCallback(
    (id: number | string) => {
      if (menuActive.findIndex(e => e === id) >= 0) {
        setMenuActive(menuActive.filter(e => e !== id));
      } else {
        setMenuActive([...menuActive, id]);
      }
    },
    [menuActive]
  );

  const _checkActiveCard = useCallback(
    (id: number | string) => menuActive.findIndex(e => e === id) >= 0,
    [menuActive]
  );

  const _submit = () => {
    dispatch(
      savePolicy(e => {
        if (e) {
          toast(t("policy.success_message"), {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast",
            closeButton: false,
            draggable: false
          });
        } else {
          toast(t("policy.failed_message"), {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast danger",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _checkParent = useCallback(
    (list: any[], parentSlug: string, cb: (e) => void) => {
      if (parentSlug) {
        const index = list.findIndex(e => e.policy_slug === parentSlug);

        if (_getValue(parentSlug) !== null) {
          cb(_getValue(parentSlug));
          _getValue(parentSlug) !== null &&
            _checkParent(list, list[index] && list[index].parent_slug, cb);
        } else {
          cb(list[index] && list[index].value);
          list[index] &&
            list[index].value &&
            _checkParent(list, list[index].parent_slug, cb);
        }
      }
    },
    [_getValue]
  );

  const _isShow = useCallback(
    (list: any[], parentSlug: string) => {
      let result = true;
      _checkParent(list, parentSlug, e => {
        result = e;
      });

      return result;
    },
    [_checkParent]
  );

  const _inputList = useCallback(
    (list: any[]) =>
      list.map((policies: any, indexPolicies: any) => {
        return (
          _isShow(list, policies.parent_slug) &&
          !policies.title.includes("NOT IMPLEMENTED") && (
            <div key={indexPolicies} className="nyobainaja">
              {policies.datatype === "array-object" &&
              policies.data.array_fields ? (
                <MultiInput
                  data={policies}
                  value={
                    _getValue(policies.policy_slug) !== null
                      ? _getValue(policies.policy_slug)
                      : policies.value
                  }
                  onChange={e => _handleChange(policies.policy_slug, e)}
                />
              ) : (
                <div className="container-policy">
                  <div>
                    <div className="policy-title">
                      {/* {`${policies.title}${policies.override ? " *" : ""}`} */}
                      <SetText
                        text={`${policies.title}${
                          policies.override ? " *" : ""
                        }`}
                      />
                    </div>
                    {policies.datatype === "array-object" &&
                      policies.data?.type === "numbering" &&
                      _handleRenderNumberingType(
                        _getValue(policies.policy_slug) !== null
                          ? _getValue(policies.policy_slug)
                          : policies.value,
                        policies.policy_slug
                      )}
                    {policies.datatype === "array" &&
                      policies.data &&
                      policies.data.lookup_values &&
                      policies.data.lookup_values.length > 0 && (
                        <div className="container-list-checkbox">
                          {_getValue(policies.policy_slug) !== null
                            ? _getValue(policies.policy_slug).map(
                                (checkboxLabel: any, indexBoxLabel: number) => (
                                  <div
                                    style={{
                                      width: "50%",
                                      marginBottom: 15
                                    }}
                                    key={indexBoxLabel}
                                  >
                                    <Checkbox
                                      onChange={(el: any) =>
                                        _handleChangeCheckBox(
                                          policies.policy_slug,
                                          _getValue(policies.policy_slug),
                                          el.target.value
                                        )
                                      }
                                      value={checkboxLabel.key}
                                      label={checkboxLabel.value}
                                      defaultChecked={
                                        policies.value.indexOf(
                                          checkboxLabel.key
                                        ) !== -1
                                      }
                                    />
                                  </div>
                                )
                              )
                            : policies.data.lookup_values.map(
                                (checkboxLabel: any, indexBoxLabel: number) => (
                                  <div
                                    style={{
                                      width: "50%",
                                      marginBottom: 15
                                    }}
                                    key={indexBoxLabel}
                                  >
                                    <Checkbox
                                      onChange={(el: any) =>
                                        _handleChangeCheckBox(
                                          policies.policy_slug,
                                          policies.data.lookup_values,
                                          el.target.value
                                        )
                                      }
                                      value={checkboxLabel.key}
                                      label={checkboxLabel.value}
                                      defaultChecked={
                                        policies.value.indexOf(
                                          checkboxLabel.key
                                        ) !== -1
                                      }
                                    />
                                  </div>
                                )
                              )}
                        </div>
                      )}
                  </div>
                  <div>
                    {_handleRenderInput(
                      policies.policy_slug,
                      policies.datatype,
                      _getValue(policies.policy_slug) !== null
                        ? _getValue(policies.policy_slug)
                        : policies.value,
                      policies.data
                        ? policies.data.lookup_values ||
                            policies.data.dropdown_data
                        : null,
                      policies.dropdown_data
                    )}
                  </div>
                </div>
              )}
            </div>
          )
        );
      }),
    [
      _getValue,
      _handleChange,
      _handleChangeCheckBox,
      _handleRenderInput,
      _handleRenderNumberingType,
      _isShow
    ]
  );

  const _formInput = useCallback(
    (children: any[]) =>
      children.map((listPolicy: any, indexListPolicy: number) => {
        return (
          <div className="container-policies" key={indexListPolicy}>
            <div key={indexListPolicy} className="container-list-policy">
              <div className="container-list">
                <Button
                  className="button-sub-menu"
                  onPress={e => {
                    e.stopPropagation();
                    _handleActiveCard(listPolicy.id);
                  }}
                >
                  {/* <p className="list-policy-title">{listPolicy.title}</p> */}
                  <p className="list-policy-title">
                    <SetText text={listPolicy.title} />
                  </p>
                  <img
                    style={{ width: 18, height: 9 }}
                    src={
                      _checkActiveCard(listPolicy.id)
                        ? IMAGES.chevronUp
                        : IMAGES.chevron
                    }
                    alt="chevron"
                  />
                </Button>
                {_checkActiveCard(listPolicy.id) &&
                  _inputList(listPolicy.policies)}
                {_checkActiveCard(listPolicy.id) &&
                  _formInput(listPolicy.children)}
              </div>
            </div>
          </div>
        );
      }),
    [_checkActiveCard, _handleActiveCard, _inputList]
  );

  const SetText = ({ text }) => {
    const copywritings_title = copyWritings(
      text
        .replace(/[\s/-]/g, "_")
        .replace(/[().'&]/g, "")
        .replace(/_+/g, "_")
        .toLocaleLowerCase()
    );
    const translate_title = t(
      `policy.${text
        .replace(/[\s/-]/g, "_")
        .replace(/[().'&]/g, "")
        .replace(/_+/g, "_")
        .toLocaleLowerCase()}`
    );
    return copywritings_title || translate_title;
  };

  return (
    <div className="container-card home">
      <ToastContainer limit={1} />
      <Container
        scroll
        isLoading={policyState.listPolicy.isLoadingPolicy}
        withMaxWidth
      >
        <HeaderTittle title={copyWritings("policy")} />
        <CategoryPolicy />
        {policyState.listPolicy.data.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={
                _checkActiveCard(item.id)
                  ? `card-policy${
                      index === 0 ? " margin-top" : ""
                    } global-shadow-card`
                  : `card-policy${
                      index === 0 ? " margin-top" : ""
                    } with-hover global-shadow-card`
              }
            >
              <div
                style={
                  !_checkActiveCard(item.id)
                    ? { width: "100%" }
                    : { width: "100%", marginBottom: 50 }
                }
              >
                <Button
                  className={
                    !_checkActiveCard(item.id)
                      ? "button-accordion"
                      : "button-accordion active-card"
                  }
                  onPress={() => _handleActiveCard(item.id)}
                >
                  <div className="header-policy container-list-policy">
                    <p className="list-policy-title">
                      {`${t(`policy.${item.title.toLowerCase()}`)}`}
                    </p>

                    <img
                      style={{ width: 18, height: 9 }}
                      src={
                        !_checkActiveCard(item.id)
                          ? IMAGES.chevron
                          : IMAGES.chevronUp
                      }
                      alt="chevron"
                    />
                  </div>
                </Button>
                <div className="container-line">
                  <div
                    className={
                      _checkActiveCard(item.id) ? "line-accordion" : ""
                    }
                  />
                </div>
                {_checkActiveCard(item.id) && _inputList(item.policies)}
                {_checkActiveCard(item.id) && _formInput(item.children)}

                {/* {_formInput(item.children)} */}
              </div>
            </div>
          );
        })}
      </Container>
      {/* <Footer isButton onPressSave={() => dispatch(savePolicy())} /> */}
      <Footer
        buttons={[
          {
            label: t("btn.revertChanges").toUpperCase(),
            onClick: () => window.location.reload(),
            isLoading: policyState.form.listForm.length === 0
          },
          {
            label: t("btn.save"),
            onClick: () => _submit(),
            isLoading: policyState.form.isLoading
          }
        ]}
      />
    </div>
  );
};

export default Home;
