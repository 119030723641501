/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { ErrorMessage, useForm } from "react-hook-form";
import Switch from "react-switch";
import { Reducers } from "../../../../redux/types";
import {
  generateLoanProfile,
  getListUserOnLoan,
  getLoanProfileList,
  getLoanSourceById,
  getLoanSources,
  resetEmployeeProfile,
  setModalGenerateLoanProfile
} from "../../../../redux/actions";

import { Modal } from "../../../../components";
import { maskedMoney } from "../../../../utils";
import "./styles.scss";

interface Props {
  currentPage: number;
}

const GenerateProfileForm = ({ currentPage }: Props) => {
  const { register, handleSubmit, errors, reset, getValues } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sprintf } = require("sprintf-js");

  const initialCalc = {
    fromSalary: false,
    salary: ""
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const [calc, setCalc] = useState(initialCalc);
  const [resPercentageSalary, setResPersentageSalary] = useState(null);
  const [autoFillMinimumRequest, setAutoFillMinimumRequest] = useState(false);

  const [autoFillMaximumRequest, setAutoFillMaximumRequest] = useState(false);

  const [minimumRequest, setMinimumRequest] = useState("");
  const [maximumRequest, setMaximumRequest] = useState("");
  const [isChangedValue, setIsChangedValue] = useState(false);

  const [minimumRequestByAccount, setMinimumRequestByAccount] = useState("");
  const [maximumRequestByAccount, setMaximumRequestByAccount] = useState("");

  const [isEditMaxLoan, setIsEditMaxLoan] = useState(false);
  const [isEditMinLoan, setIsEditMinLoan] = useState(false);
  const [__foundSourceId, __setFoundSourceId] = useState("");
  const [__foundSourceName, __setFoundSourceName] = useState("");

  const { setModal, loanSourceList, loanState } = useSelector(
    (state: Reducers) => ({
      setModal: state.employee.setModalGenerateLoan,
      loanSourceList: state.loan.listLoanSources,
      loanState: state.loan
    }),
    shallowEqual
  );

  const [selectedEmployee, setSelectedEmployee]: any = useState(
    loanState &&
      loanState.listUserOnLoan.length > 0 &&
      loanState.listUserOnLoan[0]
  );

  useEffect(() => {
    if (!selectedEmployee) {
      setSelectedEmployee(
        loanState &&
          loanState.listUserOnLoan.length > 0 &&
          loanState.listUserOnLoan[0]
      );
    }
  }, [
    loanState,
    loanState.listUserOnLoan,
    selectedEmployee,
    __foundSourceName
  ]);

  useEffect(() => {
    dispatch(getLoanSources());
    dispatch(getListUserOnLoan());
  }, [dispatch]);

  const populateSources = () => {
    let result = [{ value: "", label: t("task.form.none") }];
    loanSourceList.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  };

  const _onConfirmGenerate = (data: any) => {
    if (data) {
      setConfirmModal(true);
    }
  };

  const _onSave = (data: any) => {
    // eslint-disable-next-line radix
    if (!isEditMaxLoan && !isAll) {
      // delete data.maximum_request;
      data.minimum_request = minimumRequestByAccount;
    }

    if (!isEditMinLoan && !isAll) {
      // delete data.minimum_request;
      data.minimum_request = minimumRequestByAccount;
    }

    let form = {};
    form = {
      ...data,
      all: isAll,
      employee_id: selectedEmployee.employee.id,
      amount_max: getValues("amount_max") || resPercentageSalary
    };

    dispatch(
      generateLoanProfile(form, () => {
        dispatch(setModalGenerateLoanProfile(false, false));
        dispatch(resetEmployeeProfile());
        reset({ amount_max: "", source_id: "" });
        setCalc(initialCalc);
        setConfirmModal(false);
        dispatch(getLoanProfileList(currentPage));
      })
    );
  };

  const _onCancel = () => {
    setIsEditMinLoan(false);
    setIsEditMaxLoan(false);
    __setFoundSourceName("");
    setMinimumRequestByAccount("");
    setMaximumRequestByAccount("");
    setIsChangedValue(false);
    setMinimumRequest("");
    setMaximumRequest("");
    setAutoFillMinimumRequest(false);
    setAutoFillMaximumRequest(false);
    reset({ amount_max: "", source_id: "" });
    setCalc(initialCalc);
    dispatch(setModalGenerateLoanProfile(false, false));
    dispatch(resetEmployeeProfile());
  };

  const hasSalary = () => {
    return (
      selectedEmployee &&
      selectedEmployee.employee.salary &&
      selectedEmployee.employee.salary.amount !== ""
    );
  };

  const handlePercentage = (maxAmount: any) => {
    setResPersentageSalary(maxAmount);
  };

  const set_minimum_request = _val => {
    setIsChangedValue(true);
    setMinimumRequest(_val);
  };

  const set_maximum_request = _val => {
    setMinimumRequest(
      loanState.dataLoanSource &&
        loanState.dataLoanSource.attributes &&
        loanState.dataLoanSource.attributes.minimum_request
    );

    setIsChangedValue(true);
    setMaximumRequest(_val);
  };

  const __setMinimumRequestByAccount = (foundSourceId, employeId = null) => {
    let foundSourceName = "";

    populateSources().map(_val => {
      if (_val.value === foundSourceId) {
        foundSourceName = _val.label;
        __setFoundSourceName(foundSourceName);
        return true;
      }

      return false;
    });

    if (
      selectedEmployee.employee &&
      selectedEmployee.employee.id &&
      loanState.listLoanProfile
    ) {
      if (employeId != null) {
        loanState.listLoanProfile.map(_val => {
          if (
            _val.attributes.employee_uuid === employeId &&
            _val.attributes.source === foundSourceName
          ) {
            setMinimumRequestByAccount(_val.attributes.minimum_request);
            return true;
          }

          return false;
        });
      }

      loanState.listLoanSources.map(_val => {
        if (_val.id === foundSourceId) {
          setMinimumRequestByAccount(_val.attributes.minimum_request);
          return true;
        }

        return false;
      });
    }

    if (foundSourceId === "") {
      setAutoFillMinimumRequest(false);
      setMinimumRequest("");
      setMinimumRequestByAccount("");
    }
  };

  const __setMaximumRequestByAccount = (foundSourceId, employeId = null) => {
    let foundSourceName = "";

    populateSources().map(_val => {
      if (_val.value === foundSourceId) {
        foundSourceName = _val.label;
        __setFoundSourceName(foundSourceName);
        return true;
      }

      return false;
    });

    if (
      selectedEmployee.employee &&
      selectedEmployee.employee.id &&
      loanState.listLoanProfile
    ) {
      if (employeId != null) {
        loanState.listLoanProfile.map(_val => {
          if (
            _val.attributes.employee_uuid === employeId &&
            _val.attributes.source === foundSourceName
          ) {
            setMaximumRequestByAccount(_val.attributes.maximum_request);
            return true;
          }

          return false;
        });
      }

      loanState.listLoanSources.map(_val => {
        if (_val.id === foundSourceId) {
          setMaximumRequestByAccount(_val.attributes.maximum_request);
          return true;
        }

        return false;
      });
    }

    if (foundSourceId === "") {
      setAutoFillMaximumRequest(false);
      setMaximumRequest("");
      setMaximumRequestByAccount("");
    }
  };

  return (
    <>
      <Modal
        isOpen={setModal}
        onPressCancel={_onCancel}
        titleModal={t("personnel.btn.generateLoan")}
        onPressSubmit={handleSubmit(_onConfirmGenerate)}
        loading={loanState.isLoadingLoanSources}
        btnSubmitText={t("btn.generate")}
        btnCancelText={t("btn.cancel")}
      >
        <div className="modal-container">
          <div>
            <span className="component-modal-assign-task-label">
              {t("personnel.form.fundSource")}
            </span>
            <select
              className="component-modal-assign-task-dropdown padding-left-12 margin-top-16"
              name="source_id"
              ref={register({
                required: String(t("personnel.form.selectSourceMsg"))
              })}
              onChange={e => {
                __setFoundSourceId(e.target.value);
                if (!isAll) {
                  __setMinimumRequestByAccount(
                    e.target.value
                    // selectedEmployee.employee.id
                  );

                  __setMaximumRequestByAccount(
                    e.target.value
                    // selectedEmployee.employee.id
                  );
                }

                setAutoFillMinimumRequest(true);
                setAutoFillMaximumRequest(true);
                dispatch(getLoanSourceById(e.target.value));
              }}
            >
              {populateSources().map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <ErrorMessage
              errors={errors}
              name="source_id"
              as="p"
              style={{ color: "red" }}
            />
            <div className="component-modal-line margin-bottom-16" />
            <div className="wrap-switch">
              <span
                className={`component-modal-assign-task-label switch margin-right-8 margin-bottom-16 ${isAll &&
                  "opacity"}`}
              >
                Employee Loan Profile
              </span>
              <Switch
                onChange={() => {
                  setIsAll(!isAll);
                  __setMinimumRequestByAccount(
                    __foundSourceId
                    // selectedEmployee.employee.id
                  );
                  __setMaximumRequestByAccount(
                    __foundSourceId
                    // selectedEmployee.employee.id
                  );
                }}
                checked={isAll}
                onColor="#ff7600"
                offColor="#ff7600"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() => {
                  setIsAll(!isAll);
                  __setMinimumRequestByAccount(
                    __foundSourceId
                    // selectedEmployee.employee.id
                  );
                  __setMaximumRequestByAccount(
                    __foundSourceId
                    // selectedEmployee.employee.id
                  );
                }}
              />
              <span
                className={`component-modal-assign-task-label switch margin-left-8 margin-bottom-16 ${!isAll &&
                  "opacity"}`}
              >
                All Loan Profile
              </span>
            </div>
            {!isAll && (
              <div>
                <span className="component-modal-assign-task-label margin-bottom-16">
                  Employee
                </span>
                <select
                  className="component-modal-assign-task-dropdown padding-left-12 margin-top-16"
                  name="source_id"
                  onChange={(e: any) => {
                    __setMinimumRequestByAccount(
                      __foundSourceId,
                      loanState.listUserOnLoan[Number(e.target.value)].employee
                        .id
                    );
                    setSelectedEmployee(
                      loanState.listUserOnLoan[Number(e.target.value)]
                    );
                  }}
                >
                  {loanState.listUserOnLoan.map((item, index) => (
                    <option
                      selected={item.id === selectedEmployee.id}
                      key={index}
                      value={index}
                    >
                      {item.title}
                    </option>
                  ))}
                </select>
                <div className="component-modal-line" />
              </div>
            )}
            {hasSalary() && !isAll && (
              <>
                <div className="wrap-switch">
                  <span
                    className={`component-modal-assign-task-label switch ${calc.fromSalary &&
                      "opacity"}`}
                    style={{ marginRight: 5 }}
                  >
                    {t("personnel.form.manual")}
                  </span>
                  <Switch
                    onChange={() =>
                      setCalc({ ...calc, fromSalary: !calc.fromSalary })
                    }
                    checked={calc.fromSalary}
                    onColor="#ff7600"
                    offColor="#ff7600"
                    onHandleColor="#fffff7"
                    handleDiameter={16}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={24}
                    width={48}
                    tabIndex={0}
                    onKeyPress={() =>
                      setCalc({ ...calc, fromSalary: !calc.fromSalary })
                    }
                  />
                  <span
                    className={`component-modal-assign-task-label switch ${!calc.fromSalary &&
                      "opacity"}`}
                    style={{ marginLeft: 5 }}
                  >
                    {t("personnel.employeeDetail.salary")}
                  </span>
                </div>
                {calc.fromSalary && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                    className="margin-top-20"
                  >
                    <div style={{ width: "20%" }}>
                      <span className="component-modal-assign-task-label">
                        {t("personnel.form.percentage")}
                      </span>
                      <div>
                        <input
                          name="percentage"
                          placeholder=""
                          type="text"
                          maxLength={2}
                          className="components-employee-custom-input adornment-field"
                          onChange={e => {
                            const calculate =
                              Number(selectedEmployee.employee.salary.amount) *
                              (Number(e.target.value) / 100);
                            handlePercentage(calculate);
                          }}
                        />
                        <span className="input-adornment">%</span>
                      </div>
                    </div>
                    <div
                      style={{
                        width: "75%",
                        alignContent: "left",
                        paddingTop: "3.25rem"
                      }}
                    >
                      <span className="component-modal-assign-task-label salary-text">
                        {`dari Rp. ${maskedMoney(
                          selectedEmployee.employee.salary.amount
                        )}`}
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            {(!(calc.fromSalary && hasSalary()) || isAll) && (
              <div className="margin-top-20">
                <span className="component-modal-assign-task-label">
                  {t("loan_profile.plafond")}
                </span>
                <input
                  name="amount_max"
                  type="number"
                  className="components-employee-custom-input"
                  ref={register({
                    required: String(t("personnel.form.inputAmountMsg"))
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="amount_max"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            )}
            <input
              name="currency_code"
              type="hidden"
              defaultValue={
                loanState.dataLoanSource &&
                loanState.dataLoanSource.attributes &&
                loanState.dataLoanSource.attributes.currency_code
                  ? loanState.dataLoanSource.attributes.currency_code
                  : "IDR"
              }
              className="components-employee-custom-input"
              ref={register}
            />
            <input
              name="currency_code"
              type="hidden"
              defaultValue={
                loanState.dataLoanSource &&
                loanState.dataLoanSource.attributes &&
                loanState.dataLoanSource.attributes.currency_code
                  ? loanState.dataLoanSource.attributes.currency_code
                  : "IDR"
              }
              className="components-employee-custom-input"
              ref={register}
            />

            {/* min request loan */}
            {(!(calc.fromSalary && hasSalary()) || isAll) && (
              <div className="margin-top-20">
                <span className="component-modal-assign-task-label">
                  {t("loan_profile.minimum_loan")}
                </span>

                <input
                  onChange={e => {
                    setIsEditMinLoan(true);
                    isAll
                      ? set_minimum_request(e.target.value)
                      : setMinimumRequestByAccount(e.target.value);
                  }}
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    isAll
                      ? minimumRequest === "" &&
                        !isChangedValue &&
                        autoFillMinimumRequest &&
                        loanState.dataLoanSource &&
                        loanState.dataLoanSource.attributes &&
                        loanState.dataLoanSource.attributes.minimum_request
                        ? Number(
                            loanState.dataLoanSource.attributes.minimum_request
                          )
                        : Number(minimumRequest)
                      : // if isAll false
                        Number(minimumRequestByAccount)
                  }
                  name="minimum_request"
                  type="number"
                  className="components-employee-custom-input"
                  ref={register({
                    required: String(t("personnel.form.inputMinimumRequestMsg"))
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="minimum_request"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            )}
            {/* disini */}
            {/* max request loan */}
            {(!(calc.fromSalary && hasSalary()) || isAll) && (
              <div className="margin-top-20">
                <span className="component-modal-assign-task-label">
                  {t("loan_profile.maximum_loan")}
                </span>

                <input
                  onChange={e => {
                    setIsEditMaxLoan(true);
                    isAll
                      ? set_maximum_request(e.target.value)
                      : setMaximumRequestByAccount(e.target.value);
                  }}
                  value={
                    // eslint-disable-next-line no-nested-ternary
                    isAll
                      ? maximumRequest === "" &&
                        !isChangedValue &&
                        autoFillMaximumRequest &&
                        loanState.dataLoanSource &&
                        loanState.dataLoanSource.attributes &&
                        loanState.dataLoanSource.attributes.maximum_request
                        ? Number(
                            loanState.dataLoanSource.attributes.maximum_request
                          )
                        : maximumRequest
                      : // if isAll false
                        Number(maximumRequestByAccount)
                  }
                  name="maximum_request"
                  type="number"
                  className="components-employee-custom-input"
                  ref={register({
                    required: String(t("personnel.form.inputMaximumRequestMsg"))
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="maximum_request"
                  as="p"
                  style={{ color: "red" }}
                />
                {/* info current max and min request loan */}
                {/* minimum */}
                {isAll === false &&
                __foundSourceName !== "" &&
                __foundSourceName !== "None" ? (
                  <p style={{ fontWeight: "bold" }}>
                    {` ${t("loan_profile.currently_minimum_request")} ( ${
                      selectedEmployee && selectedEmployee.employee
                        ? selectedEmployee.employee.name
                        : ""
                    } )`}
                  </p>
                ) : (
                  ""
                )}
                {isAll === false &&
                  selectedEmployee.employee &&
                  selectedEmployee.employee.id &&
                  loanState.listLoanProfile.map((_val, _index) => {
                    if (
                      _val.attributes.employee_uuid ===
                        selectedEmployee.employee.id &&
                      _val.attributes.source === __foundSourceName
                    ) {
                      return (
                        <p key={_index}>
                          {`[*] . ${maskedMoney(
                            _val.attributes.minimum_request
                          )} (${_val.attributes.source})`}
                        </p>
                      );
                    }

                    return false;
                  })}
                {/* maximum */}
                {isAll === false &&
                __foundSourceName !== "" &&
                __foundSourceName !== "None" ? (
                  <p style={{ fontWeight: "bold" }}>
                    {` ${t("loan_profile.currently_maximum_request")} ( ${
                      selectedEmployee && selectedEmployee.employee
                        ? selectedEmployee.employee.name
                        : ""
                    } )`}
                  </p>
                ) : (
                  ""
                )}

                {isAll === false &&
                  selectedEmployee.employee &&
                  selectedEmployee.employee.id &&
                  loanState.listLoanProfile.map((_val, _index) => {
                    if (
                      _val.attributes.employee_uuid ===
                        selectedEmployee.employee.id &&
                      _val.attributes.source === __foundSourceName
                    ) {
                      return (
                        <p key={_index}>
                          {`[*] . ${maskedMoney(
                            _val.attributes.maximum_request
                          )} (${_val.attributes.source})`}
                        </p>
                      );
                    }

                    return false;
                  })}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        warning
        onPressCancel={() => {
          setConfirmModal(false);
        }}
        isOpen={confirmModal}
        onPressSubmit={handleSubmit(_onSave)}
        btnSubmitText={t("btn.submit")}
        btnCancelText={t("btn.cancel")}
        loading={loanState.isLoadingGenerateLoanProfile}
      >
        {isAll ? (
          <div style={{ textAlign: "center" }}>
            {t("personnel.form.confirmLoanProfileAll")}
            <br />
            {t("personnel.form.confirmLoanProfileAnd")}
            &nbsp;
            <b>{`IDR ${maskedMoney(getValues("amount_max"))}`}</b>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Trans>
              {sprintf(
                t("personnel.form.confirmLoanProfileSingle"),
                selectedEmployee.title
              )}
            </Trans>
            <br />
            {t("personnel.form.confirmLoanProfileAnd")}
            &nbsp;
            <b>
              {`IDR ${maskedMoney(
                getValues("amount_max") || resPercentageSalary
              )}`}
            </b>
          </div>
        )}
      </Modal>
    </>
  );
};

export default memo(GenerateProfileForm);
