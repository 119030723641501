import { Dispatch, GetState } from "../types";

export const HANDLE_THEME = "HANDLE_THEME";

export const handleTheme = (theme?: "dark" | "light") => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const { config } = getState();

  dispatch({
    type: HANDLE_THEME,
    payload: { data: theme ? theme === "dark" : !config.isDark }
  });
};
