import { Slide, toast } from "react-toastify";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";

export const GET_REGENCY_SUCCESS = "GET_REGENCY_SUCCESS";
export const GET_REGENCY_PENDING = "GET_REGENCY_PENDING";
export const GET_REGENCY_ERROR = "GET_REGENCY_ERROR";

export const POST_SIGN_UP_COMPANY_SUCCESS = "POST_SIGN_UP_COMPANY_SUCCESS";
export const POST_SIGN_UP_COMPANY_PENDING = "POST_SIGN_UP_COMPANY_PENDING";
export const POST_SIGN_UP_COMPANY_ERROR = "POST_SIGN_UP_COMPANY_ERROR";

export const SET_FORM_WALKTHROUGH = "SET_FORM_WALKTHROUGH";

export const UPDATE_FORM_WALKTHROUGH = "UPDATE_FORM_WALKTHROUGH";

declare const window: Window;

export const getRegency = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_REGENCY_PENDING });

    const res = await API.getRegency();
    dispatch({
      type: GET_REGENCY_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    dispatch({ type: GET_REGENCY_ERROR });
  }
};

export const setFormWalkthrough = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: SET_FORM_WALKTHROUGH, payload: { data } });
};

export const updateFormWalkthrough = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_FORM_WALKTHROUGH, payload: { data } });
};

export const postSignUpCompany = () => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { walkthrough } = getState();
    dispatch({ type: POST_SIGN_UP_COMPANY_PENDING });

    const res = await API.postSignUpCompany(walkthrough.form);
    dispatch({
      type: POST_SIGN_UP_COMPANY_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Register Company Success", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
    window.location.replace(process.env.REACT_APP_HOST_WEB || "");
  } catch (err) {
    toast("Something went wrong, please try again later!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast danger",
      closeButton: false,
      draggable: false
    });
    dispatch({ type: POST_SIGN_UP_COMPANY_ERROR });
  }
};
