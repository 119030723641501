import React from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import { Reducers } from "../../../../redux/types";

import { setSetting } from "../../../../redux/actions";

const DropdownInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settingState = useSelector((state: Reducers) => state.setting);

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>{t("setting.value")}</p>
      <div className="setting-list-container-switch">
        <Switch
          onChange={() => {
            dispatch(setSetting("value", !settingState.settingDetail.value));
          }}
          checked={settingState.settingDetail.value}
          onColor="#ff7600"
          onHandleColor="#fffff7"
          handleDiameter={18}
          uncheckedIcon={false}
          checkedIcon={false}
          height={26}
          width={40}
        />
        <p style={{ marginLeft: 12 }}>
          {settingState.settingDetail && settingState.settingDetail.value
            ? "True"
            : "False"}
        </p>
      </div>
    </div>
  );
};

export default DropdownInput;
