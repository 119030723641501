import React, { useEffect } from "react";
// import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import {
  Container,
  // HeaderTittle,
  SideBar,
  TopBar,
  WrapApp
} from "../../components";

import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";

import DeleteData from "./DeleteData";

const Admin = () => {
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";
  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
  }, [dispatch, getLanguage]);

  const match = useRouteMatch();

  const items: any = [
    {
      pathname: `${match.path}/delete`,
      component: <DeleteData />,
      exact: false
    }
  ];

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <Container scroll withMaxWidth>
          {/* <HeaderTittle title="Admin" /> */}
          <div style={{ marginTop: -20 }}>
            {items.map((item, index) => (
              <Route path={item.pathname} exact={item.exact} key={index}>
                {item.component}
              </Route>
            ))}
          </div>
        </Container>
      </WrapApp>
    </Switch>
  );
};

export default Admin;
