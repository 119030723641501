import React, { memo } from "react";

import "./styles.scss";
import { HeaderIcon } from "../../assets";

interface Props {
  title: string;
}

const TittleModule = ({ title }: Props) => {
  return <HeaderIcon title={title} />;
};

export default memo(TittleModule);
