import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Loading, Modal } from "..";
import { IMAGES } from "../../configs";
import {
  getEmployee,
  saveAddParticipants,
  setAddParticipants,
  setDeleteParticipants,
  setModalParticipant
} from "../../redux/actions";
import Picker from "../Picker";
import { Reducers } from "../../redux/types";

interface Props {
  onChange?: (e: string) => void;
  value?: string;
  getData?: boolean;
  onSubmit?: (e: any[]) => void;
  hasPicker?: any;
  pickerOnChange?: (e: any) => void;
  pickerValue?: string;
}

const ModalUserPicker = ({
  onChange,
  value,
  getData,
  onSubmit,
  pickerOnChange,
  hasPicker,
  pickerValue
}: Props) => {
  const dispatch = useDispatch();

  const { taskState, employee, generateState, listDepartment } = useSelector(
    (state: Reducers) => ({
      employee: state.generate.listEmployee,
      taskState: state.task,
      generateState: state.generate,
      listDepartment: state.generate.listDepartment
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      taskState.taskDetail &&
      taskState.taskDetail.id !== null &&
      taskState.taskDetail.status === 1
    ) {
      if (getData && taskState.setModalParticipant) {
        dispatch(
          getEmployee("", "", "", 1, false, "", false, true, false, false, true)
        );
      }
    } else if (getData && taskState.setModalParticipant) {
      dispatch(
        getEmployee("", "", "", 1, false, "", false, false, false, false, true)
      );
    }
  }, [getData, dispatch, taskState.setModalParticipant, taskState.taskDetail]);

  const _cancel = () => {
    if (!getData) {
      dispatch(
        getEmployee("", "", "", 1, false, "", false, false, false, false, true)
      );
    }
    dispatch(setModalParticipant(false));
  };

  const _selected = useCallback(
    (id: string) => {
      const result = taskState.participanList.findIndex(e => e.id === id);

      return result >= 0;
    },
    [taskState.participanList]
  );

  const _getEmployee = useCallback(() => {
    if (getData) {
      if (taskState.taskDetail && taskState.taskDetail.status !== 1) {
        return taskState.taskDetail
          ? employee.filter(
              e =>
                taskState.taskDetail.assignee.findIndex(
                  res => res.employee.id === e.id
                ) < 0
            )
          : [];
      }
      return employee;
    }
    return employee;
  }, [employee, taskState.taskDetail, getData]);

  const [modalPic, setModalPic] = useState(false);
  const [selectedPerson, setSelectedPerson]: any = useState();

  const _handleModalPic = (data: any) => {
    setSelectedPerson(data);
    if (taskState.participanList.length === 0) {
      setModalPic(true);
    } else {
      dispatch(setAddParticipants(data));
    }
  };

  const _updateListDepartments = data => {
    if (data) {
      if (data[0]) {
        data[0].label = "All";
      }
    }

    return data;
  };

  return (
    <Modal
      titleModal=""
      isOpen={taskState.setModalParticipant}
      onPressCancel={_cancel}
      onPressSubmit={() => {
        if (getData) {
          if (taskState.taskDetail && taskState.taskDetail.status !== 1) {
            onSubmit && onSubmit(taskState.participanList);
          } else {
            dispatch(saveAddParticipants());
          }
        } else {
          dispatch(saveAddParticipants());
        }
        _cancel();
      }}
      btnSubmitText="SAVE"
    >
      <div className="component-modal-userpicker-card-search-input global-shadow-card margin-bottom-36">
        {hasPicker && (
          <div className="component-modal-user-picker-department-container">
            <div>Department</div>
            <Picker
              listValue={_updateListDepartments(listDepartment)}
              onChange={e => pickerOnChange && pickerOnChange(e.target.value)}
              defaultValue={pickerValue}
              value={pickerValue}
              className="picker margin-left-12"
              containerClassName="picker-container"
            />
          </div>
        )}
        <div className="component-modal-userpicker-container-search-input">
          <img
            src={IMAGES.search}
            alt="edit"
            style={{ height: 24, width: 24 }}
          />
          <input
            placeholder="Looking for someone?"
            style={{ fontSize: 24 }}
            type="text"
            className="component-modal-userpicker-search-input margin-left-12"
            onChange={e => {
              onChange && onChange(e.target.value);
            }}
            value={value}
          />
        </div>
      </div>
      <div className="component-modal-user-picker-list-employee-container">
        {_getEmployee().map((data, index) => (
          <div
            key={index}
            className={`component-modal-user-picker-container-employee ${
              index > 0 ? "margin-top-24" : null
            }`}
          >
            <div className="component-modal-user-picker-container-employee-data">
              <img
                src={IMAGES.avatar}
                alt="avatar"
                className="global-shadow-card"
                style={{ height: 60, width: 60, borderRadius: 100 }}
              />
              <div className="margin-left-12">
                <div>{data.name}</div>
                <div style={{ marginTop: -8 }}>
                  {`${data.division && data.division.title} - ${data.position &&
                    data.position.title}`}
                </div>
              </div>
            </div>
            {_selected(data.id) ? (
              <div onClick={() => dispatch(setDeleteParticipants(data.id))}>
                <img
                  src={IMAGES.substractButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            ) : (
              <div onClick={() => _handleModalPic(data)}>
                <img
                  src={IMAGES.addButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <Modal
        isOpen={modalPic}
        btnSubmitText="YES"
        btnCancelText="NO"
        onPressSubmit={() =>
          dispatch(setAddParticipants(selectedPerson, () => setModalPic(false)))
        }
        onPressCancel={() => setModalPic(false)}
        message={`Set ${selectedPerson &&
          selectedPerson.name} as Person in Charge (PIC)?`}
        warning
      />

      <Loading isVisible={generateState.isLoadingListEmployee} />
    </Modal>
  );
};

ModalUserPicker.defaultProps = {
  getData: false
};

export default memo(ModalUserPicker);
