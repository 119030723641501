import React, { memo, useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Loading, Modal } from "..";
import { IMAGES } from "../../configs";
import {
  getEmployee,
  saveAddRequestParticipant,
  setAddRequestParticipant,
  setDeleteRequestParticipant,
  setModalRequestParticipant
} from "../../redux/actions";
import { Reducers } from "../../redux/types";
import Picker from "../Picker";

interface Props {
  onChange?: (e: string) => void;
  value?: string;
  getData?: boolean;
  onSubmit?: (e: any[]) => void;
  hasPicker?: any;
  pickerOnChange?: (e: any) => void;
  pickerValue?: string;
}

const ModalTaskRequestUserPicker = ({
  onChange,
  value,
  getData,
  onSubmit,
  pickerOnChange,
  hasPicker,
  pickerValue
}: Props) => {
  const dispatch = useDispatch();

  const { taskState, employee, generateState, listDepartment } = useSelector(
    (state: Reducers) => ({
      employee: state.generate.listEmployee,
      taskState: state.task,
      generateState: state.generate,
      listDepartment: state.generate.listDepartment
    }),
    shallowEqual
  );

  useEffect(() => {
    if (getData && taskState.setModalRequestParticipant) {
      dispatch(
        getEmployee(
          "",
          "",
          "",
          1,
          false,
          value || "",
          false,
          false,
          false,
          false,
          true
        )
      );
    }
  }, [getData, dispatch, taskState.setModalRequestParticipant, value]);

  const _cancel = () => {
    if (!getData) {
      dispatch(
        getEmployee("", "", "", 1, false, "", false, false, false, false, true)
      );
    }
    dispatch(setModalRequestParticipant(false));
  };

  const _selected = useCallback(
    (id: string) => {
      const result = taskState.requestParticipantList.findIndex(
        e => e.id === id
      );

      return result >= 0;
    },
    [taskState.requestParticipantList]
  );

  const _getEmployee = useCallback(() => {
    return employee;
  }, [employee]);

  const _handleModalPic = (data: any) => {
    if (
      taskState.requestParticipantList &&
      taskState.requestParticipantList.length < 1
    ) {
      dispatch(setAddRequestParticipant(data));
    }
  };

  const _updateListDepartments = data => {
    if (data) {
      if (data[0]) {
        data[0].label = "All";
      }
    }

    return data;
  };

  return (
    <Modal
      titleModal=""
      isOpen={taskState.setModalRequestParticipant}
      onPressCancel={_cancel}
      onPressSubmit={() => {
        if (getData) {
          onSubmit && onSubmit(taskState.requestParticipantList);
        } else {
          dispatch(saveAddRequestParticipant());
        }
        _cancel();
      }}
      btnSubmitText="SAVE"
    >
      <div className="component-modal-task-request-userpicker-card-search-input global-shadow-card margin-bottom-36">
        {hasPicker && (
          <div className="component-modal-task-request-user-picker-department-container">
            <div>Department</div>
            <Picker
              listValue={_updateListDepartments(listDepartment)}
              onChange={e => pickerOnChange && pickerOnChange(e.target.value)}
              defaultValue={pickerValue}
              value={pickerValue}
              className="picker margin-left-12"
              containerClassName="picker-container"
            />
          </div>
        )}
        <div className="component-modal-task-request-userpicker-container-search-input">
          <img
            src={IMAGES.search}
            alt="edit"
            style={{ height: 24, width: 24 }}
          />
          <input
            placeholder="Looking for someone?"
            style={{ fontSize: 24 }}
            type="text"
            className="component-modal-task-request-userpicker-search-input margin-left-12"
            onChange={e => onChange && onChange(e.target.value)}
            value={value}
          />
        </div>
      </div>
      <div className="component-modal-task-request-user-picker-list-employee-container">
        {_getEmployee().map((data, index) => (
          <div
            key={index}
            className={`component-modal-task-request-user-picker-container-employee ${
              index > 0 ? "margin-top-24" : null
            }`}
          >
            <div className="component-modal-task-request-user-picker-container-employee-data">
              <img
                src={IMAGES.avatar}
                alt="avatar"
                className="global-shadow-card"
                style={{ height: 60, width: 60, borderRadius: 100 }}
              />
              <div className="margin-left-12">
                <div>{data && data.name}</div>
                <div style={{ marginTop: -8 }}>
                  {`${data.division && data.division.title} - ${data.position &&
                    data.position.title}`}
                </div>
              </div>
            </div>
            {_selected(data.id) ? (
              <div
                onClick={() => dispatch(setDeleteRequestParticipant(data.id))}
              >
                <img
                  src={IMAGES.substractButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            ) : (
              <div onClick={() => _handleModalPic(data)}>
                <img
                  src={IMAGES.addButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <Loading isVisible={generateState.isLoadingListEmployee} />
    </Modal>
  );
};

ModalTaskRequestUserPicker.defaultProps = {
  getData: false
};

export default memo(ModalTaskRequestUserPicker);
