import React, { CSSProperties, ReactNode, memo } from "react";

import "./styles.scss";

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  mouseIn?: () => void;
  mouseOut?: () => void;
  pinned?: boolean;
}

const Card = ({
  children,
  className,
  style,
  mouseOut,
  mouseIn,
  pinned
}: Props) => (
  <div
    className={`card-container ${className} shadow`}
    style={style}
    onMouseEnter={mouseIn}
    onMouseLeave={mouseOut}
  >
    {pinned ? <div className="card-circle" /> : <></>}
    {children}
  </div>
);

Card.defaultProps = {
  className: ""
};

export default memo(Card);
