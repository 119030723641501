import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  addAccountsEmployee,
  putSaveMyProfile,
  resetEmployeeProfile,
  setFormMyProfileEmployee,
  setImageMyProfileEmployee
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { Modal } from "../../../../components";
import "../../../../sass/styles.scss";
import Profile from "../../Profile";
import "./styles.scss";

interface Props {
  isOpen: boolean;
  onPressCancel: () => void;
  isNew?: boolean;
}

const ModalEditStaff = ({ isOpen, onPressCancel, isNew }: Props) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const { t } = useTranslation();

  const employeeState = useSelector((state: Reducers) => state.employee);
  const [isLoadRole, setIsLoadRole] = useState(true);
  const [isLoadingReset, setIsLoadingReset] = useState(false);

  const defaulValues = {
    name: "",
    pob: "",
    dob: "",
    code: "",
    number: "",
    password: "",
    email: "",
    phone: "",
    zip: "",
    fax: "",
    address: ""
  };

  useEffect(() => {
    dispatch(
      setFormMyProfileEmployee("idNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("passport", {
        number: "",
        expired_at: {
          mm: "",
          yy: ""
        },
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("taxNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(setFormMyProfileEmployee("city", ""));
    dispatch(setImageMyProfileEmployee(""));
    dispatch(addAccountsEmployee(true));

    setIsLoadRole(true);

    setTimeout(() => {
      setIsLoadRole(false);
    }, 300);
  }, [isOpen, dispatch]);

  const _resetmen = useCallback(() => {
    setIsLoadingReset(true);
    dispatch(resetEmployeeProfile());
    dispatch(
      setFormMyProfileEmployee("idNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("passport", {
        number: "",
        expired_at: {
          mm: "",
          yy: ""
        },
        file: {}
      })
    );
    dispatch(
      setFormMyProfileEmployee("taxNumber", {
        number: "",
        file: {}
      })
    );
    dispatch(setFormMyProfileEmployee("city", ""));
    dispatch(setImageMyProfileEmployee(""));
    methods.reset(defaulValues);
    setTimeout(() => {
      setIsLoadingReset(false);
    }, 300);
  }, [defaulValues, dispatch, methods]);

  const _handleSave = useCallback(
    form => {
      dispatch(
        putSaveMyProfile(
          form,
          employeeState.staff.data.id,
          () => {
            onPressCancel();
            _resetmen();
          },
          isNew
        )
      );
    },
    [dispatch, employeeState.staff.data.id, onPressCancel, isNew, _resetmen]
  );

  return (
    <FormContext {...methods}>
      <Modal
        titleModal={isNew ? t("personnel.form.newTitle") : "EDIT STAFF"}
        isOpen={isOpen}
        onPressCancel={() => {
          onPressCancel();
          _resetmen();
        }}
        onPressSubmit={methods.handleSubmit(_handleSave)}
        btnSubmitText={t("btn.submit")}
        btnCancelText={t("btn.cancel")}
      >
        <Profile
          isMe={false}
          idForm={isNew ? "add" : "edit"}
          isLoadListRole={isNew ? isLoadRole : false}
          isLoadingReset={isLoadingReset}
        />
      </Modal>
    </FormContext>
  );
};

ModalEditStaff.defaultProps = {
  isNew: false
};

export default ModalEditStaff;
