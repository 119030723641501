import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Button, Card, Container, Text } from "../../../../../../components";
import { shortNumber } from "../../../../../../utils";
import { ArrowForSelectIcon } from "../../../../../../assets";

// action && type redux
import {
  handleCategoryWidget,
  handleSelectSubWidget,
  handleSelectWidget
} from "../../../../../../redux/actions";
import { Reducers } from "../../../../../../redux/types";

interface Props {
  item: any;
  index: number;
}

const Widget = ({ item, index }: Props) => {
  const dispatch = useDispatch();
  const homeState = useSelector((state: Reducers) => state.home);

  const _categoriesActive = (itemCategory: any) => {
    const indexCategory = itemCategory.detail.categories.findIndex(
      (find: any) =>
        Number(find.value) === Number(itemCategory.categories_active)
    );
    return indexCategory;
  };

  const _handleSelectWidget = (indexWidget: number, isTrip: boolean) => {
    dispatch(handleSelectWidget(indexWidget, isTrip));
  };

  const _handleSelectSubWidget = (indexSubWidget: number) => {
    dispatch(handleSelectSubWidget(indexSubWidget, index));
  };

  return (
    <Card
      className={`dashboard-home-widget-content-card ${homeState.listWidget
        .activeWidget === index &&
        "dashboard-home-widget-content-card-selected"}`}
    >
      <Container isLoading={!item.detail}>
        <Button
          onPress={() => {
            _handleSelectWidget(
              index,
              item.type === "total-trips" ||
                item.type === "total-expenses" ||
                item.type === "category-expenses"
            );
          }}
          className="dashboard-home-widget-card-button"
        >
          <div
            className={`dashboard-home-widget-container-category ${item.detail &&
              item.detail.categories.length === 1 &&
              "dashboard-home-widget-container-height"}`}
          >
            {item.detail && item.detail.categories.length > 1 && (
              <div className="dashboard-home-widget-wrap-category">
                <select
                  className="dashboard-home-widget-category"
                  onChange={e =>
                    dispatch(
                      handleCategoryWidget(Number(e.target.value), index)
                    )
                  }
                >
                  {item.detail.categories.map(
                    (itemCategory: any, indexCategory: number) => (
                      <option
                        key={indexCategory}
                        value={itemCategory.value}
                        className="dashboard-home-widget-category-options"
                      >
                        {itemCategory.name}
                      </option>
                    )
                  )}
                </select>
                <ArrowForSelectIcon />
              </div>
            )}
          </div>

          <div className="dashboard-home-widget-body-card">
            <div className="dashboard-home-widget-card-row dashboard-home-widget-card-row-padding-horizontal">
              <div className="dashboard-home-widget-flex-1">
                {item.detail ? (
                  <Text className="dashboard-home-widget-text-title-card">
                    {item.detail.title === "Employee Outstanding"
                      ? "Total Cash Advanced"
                      : item.detail.title}
                  </Text>
                ) : null}
                {item.detail && item.detail.sub_title && (
                  <Text className="dashboard-home-widget-text-sub-title">
                    {`${item.detail.sub_title} bils`}
                  </Text>
                )}
              </div>
              <div className="dashboard-home-widget-flex-1 dashboard-home-widget-align-items-end">
                <Text className="dashboard-home-widget-text-title-price">
                  {item.detail &&
                    shortNumber(
                      item.detail.categories[_categoriesActive(item)].value,
                      "no"
                    )}
                </Text>
                {item.detail &&
                  shortNumber(
                    item.detail.categories[_categoriesActive(item)].value,
                    "full",
                    true
                  ) !== "" && (
                    <Text className="dashboard-home-widget-text-label-price">
                      {item.detail &&
                        shortNumber(
                          item.detail.categories[_categoriesActive(item)].value,
                          "full",
                          true
                        )}
                    </Text>
                  )}
              </div>
            </div>

            <div className="dashboard-home-widget-card-row">
              {item.detail &&
                item.detail.categories[_categoriesActive(item)].items &&
                item.detail.categories[_categoriesActive(item)].items.map(
                  (itemCategory: any, indexCategory: number) => (
                    <Button
                      onPress={e => {
                        e.stopPropagation();
                        _handleSelectSubWidget(indexCategory);
                      }}
                      className={`dashboard-home-widget-flex-1 dashboard-home-widget-align-items-center dashboard-home-widget-button-item ${homeState
                        .listWidget.activeWidget === index &&
                        homeState.listWidget.activeSubWidget ===
                          indexCategory &&
                        "dashboard-home-widget-button-item-active"}`}
                      key={indexCategory}
                    >
                      <Text
                        className={`dashboard-home-widget-text-titile-count ${homeState
                          .listWidget.activeWidget === index &&
                          homeState.listWidget.activeSubWidget ===
                            indexCategory &&
                          "dashboard-home-widget-text-color-active"}`}
                      >
                        {itemCategory.title}
                      </Text>
                      {itemCategory.sub_title && (
                        <Text className="dashboard-home-widget-item-text-sub-title">
                          {`${itemCategory.sub_title} bils`}
                        </Text>
                      )}
                      <Text
                        className={`dashboard-home-widget-text-count ${homeState
                          .listWidget.activeWidget === index &&
                          homeState.listWidget.activeSubWidget ===
                            indexCategory &&
                          "dashboard-home-widget-text-color-active"}`}
                      >
                        {shortNumber(itemCategory.value)}
                      </Text>
                    </Button>
                  )
                )}
            </div>
          </div>
        </Button>
      </Container>
    </Card>
  );
};

export default memo(Widget);
