import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import InputComponent from "./components/InputComponent";

import { setSetting } from "../../../../redux/actions";

const NumberingInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settingState = useSelector((state: Reducers) => state.setting);

  const [textNumbering, setTextNumbering]: any = useState("");
  const [iterationValue, setIterationValue]: any = useState({
    duration: "1",
    digits: "1"
  });
  const valueOptionDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9];

  const addArrayValue = (data: any, value?: any) => {
    const addedData = [...data, value];
    dispatch(setSetting("value", addedData));
  };

  const deleteArrayValue = (data: any, index: number) => {
    data.splice(index, 1);
    dispatch(setSetting("value", data));
  };

  const renderDurationNumbering = (id: string) => {
    if (id === "1") {
      return "Non Reset";
    }
    if (id === "2") {
      return "Monthly Reset";
    }
    return "Yearly Reset";
  };

  const renderExampleNumbering = () => {
    let res = "";
    if (settingState.settingDetail.value.length > 0) {
      settingState.settingDetail.value.map((numberingValue: any) => {
        if (numberingValue.type === "text") {
          res = `${res}${numberingValue.value}`;
        } else if (numberingValue.type === "iteration") {
          let _tempDigits: any = [];
          for (let i = 0; i < Number(numberingValue.digits) - 1; i += 1) {
            _tempDigits = [..._tempDigits, "0"];
          }
          res = `${res}${_tempDigits.join("")}1`;
        } else if (numberingValue.type === "month") {
          if (numberingValue.value === "1" || numberingValue.value === 1) {
            res = `${res}January`;
          } else if (
            numberingValue.value === "2" ||
            numberingValue.value === 2
          ) {
            res = `${res}Jan`;
          } else if (
            numberingValue.value === "3" ||
            numberingValue.value === 3
          ) {
            res = `${res}01`;
          } else if (
            numberingValue.value === "4" ||
            numberingValue.value === 4
          ) {
            res = `${res}1`;
          } else if (
            numberingValue.value === "5" ||
            numberingValue.value === 5
          ) {
            res = `${res}IV`;
          } else {
            res = `${res}${numberingValue.value}`;
          }
        } else if (numberingValue.type === "year") {
          if (numberingValue.value === "1" || numberingValue.value === 1) {
            res = `${res}${moment(new Date()).format("YYYY")}`;
          } else if (
            numberingValue.value === "2" ||
            numberingValue.value === 2
          ) {
            res = `${res}${moment(new Date()).format("YY")}`;
          } else {
            res = `${res}${numberingValue.value}`;
          }
        } else {
          res = "-";
        }
        return null;
      });
    }
    return res;
  };

  const renderTextCapsule = (type: string, value: any) => {
    if (type === "month") {
      if (value === 1 || value === "1") {
        return "January";
      }
      if (value === 2 || value === "2") {
        return "Jan";
      }
      if (value === 3 || value === "3") {
        return "01";
      }
      if (value === 4 || value === "4") {
        return "1";
      }
      if (value === 5 || value === "5") {
        return "IV";
      }
      return value;
    }
    if (type === "year") {
      if (value === 1 || value === "1") {
        return "2022";
      }
      if (value === 2 || value === "2") {
        return "22";
      }
      return value;
    }
    return value;
  };

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>{t("setting.numberingFormat")}</p>
      <div style={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
        {settingState.settingDetail.value.map((item, index) => (
          <div className="setting-list-capsule" key={index}>
            <p className="setting-list-text-capsule">
              {item.type === "iteration" ? (
                <span>
                  <span>{"iteration: "}</span>
                  <span style={{ fontWeight: "bold" }}>
                    {`${renderDurationNumbering(item.duration)}; `}
                  </span>
                  <span>{"Digits: "}</span>
                  <span style={{ fontWeight: "bold" }}>
                    {`${item.digits}; `}
                  </span>
                </span>
              ) : (
                <span>
                  <span>{`${item.type}: `}</span>
                  <span style={{ fontWeight: "bold" }}>
                    {renderTextCapsule(item.type, item.value)}
                  </span>
                </span>
              )}
            </p>
            <div className="setting-list-container-del-capsule">
              <div
                onClick={() =>
                  deleteArrayValue(settingState.settingDetail.value, index)
                }
                className="setting-list-del-icon"
              >
                x
              </div>
            </div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <span>{t("setting.resExample")}</span>
        <span style={{ fontWeight: "bold" }}>{renderExampleNumbering()}</span>
      </div>
      <div>
        <div className="setting-list-container-numbering-format">
          <div className="setting-list-container-numbering-format-input">
            <Button
              className="setting-list-btn-add-array numbering"
              onPress={() => {
                addArrayValue(settingState.settingDetail.value, {
                  type: "text",
                  value: textNumbering
                });
              }}
            >
              <p className="setting-list-btn-add-array-icon">+</p>
            </Button>
            <div
              style={{
                width: "80%"
              }}
            >
              <span style={{ fontWeight: "bold" }}>{t("setting.text")}</span>
              <input
                style={{ width: "100%", color: "grey" }}
                value={textNumbering}
                onChange={(e: any) => setTextNumbering(e.target.value)}
              />
              <div style={{ width: "100%" }} className="setting-list-line" />
            </div>
          </div>
          <div className="setting-list-container-numbering-format-input">
            {settingState.settingDetail &&
            settingState.settingDetail.value.filter(
              (obj: any) => obj.type === "iteration"
            ).length > 0 ? (
              <Button className="setting-list-btn-add-array-disabled">
                <p className="setting-list-btn-add-array-icon">+</p>
              </Button>
            ) : (
              <Button
                className="setting-list-btn-add-array numbering"
                onPress={() => {
                  addArrayValue(settingState.settingDetail.value, {
                    type: "iteration",
                    duration: iterationValue.duration,
                    digits: iterationValue.digits
                  });
                }}
              >
                <p className="setting-list-btn-add-array-icon">+</p>
              </Button>
            )}

            <div
              style={{
                width: "80%"
              }}
            >
              <div style={{ width: "100%", display: "flex" }}>
                <div style={{ width: "70%" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("setting.iteration")}
                  </span>
                </div>
                <div style={{ width: "30%" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {t("setting.digits")}
                  </span>
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between"
                }}
              >
                <div style={{ width: "68%", marginTop: 8 }}>
                  <select
                    name="project_id"
                    className="component-modal-input-dropdown"
                    onChange={e =>
                      setIterationValue({
                        ...iterationValue,
                        duration: e.target.value
                      })
                    }
                  >
                    <option value="1">Non Reset</option>
                    <option value="2">Monthly Reset</option>
                    <option value="3">Yearly Reset</option>
                  </select>
                  <div
                    style={{ width: "100%" }}
                    className="setting-list-line"
                  />
                </div>
                <div style={{ width: "28%", marginTop: 8 }}>
                  <select
                    name="project_id"
                    className="component-modal-input-dropdown"
                    onChange={e =>
                      setIterationValue({
                        ...iterationValue,
                        digits: e.target.value
                      })
                    }
                  >
                    {valueOptionDigit.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <div
                    style={{ width: "100%" }}
                    className="setting-list-line"
                  />
                </div>
              </div>
            </div>
          </div>
          <InputComponent
            desc={t("setting.fullMonthDesc")}
            example="January"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "month",
                value: "January"
              })
            }
          />
          <InputComponent
            desc={t("setting.shortMonthDesc")}
            example="Jan"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "month",
                value: "Jan"
              })
            }
          />
          <InputComponent
            desc={t("setting.numZeroMonthDesc")}
            example="01"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "month",
                value: "01"
              })
            }
          />
          <InputComponent
            desc={t("setting.numMonthDesc")}
            example="1"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "month",
                value: "1"
              })
            }
          />
          <InputComponent
            desc={t("setting.romanMonthDesc")}
            example="IV"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "month",
                value: "IV"
              })
            }
          />
          <InputComponent
            desc={t("setting.fullYear")}
            example="2022"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "year",
                value: "2022"
              })
            }
          />
          <InputComponent
            desc={t("setting.twoDigitsYear")}
            example="22"
            onPressBtn={() =>
              addArrayValue(settingState.settingDetail.value, {
                type: "year",
                value: "22"
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default NumberingInput;
