import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";

import { WrapApp } from "../../components";

import FormCompany from "./pages/FormCompany";
import DetailCompany from "./pages/DetailCompany";

const Walkthrough = () => {
  const match = useRouteMatch();
  const listPage = [
    {
      path: match.path,
      component: <FormCompany />,
      exact: true
    },
    {
      path: `${match.path}/detail`,
      component: <DetailCompany />,
      exact: true
    }
  ];

  return (
    <Switch>
      <WrapApp>
        {listPage.map((item, index) => (
          <Route path={item.path} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
      </WrapApp>
    </Switch>
  );
};

export default Walkthrough;
