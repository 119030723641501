import React from "react";
import Select from "react-select";

interface Props {
  placeHolder?: string;
  color?: any;
  style?: any;
  option: any[];
  onValueChange?: (e: { label: string; value: string }) => void;
  defaultValue?: any;
  value?: any;
  disable?: boolean;
}
const PickerSelect = ({
  option,
  onValueChange,
  color,
  placeHolder,
  defaultValue,
  value,
  disable,
  style
}: Props) => (
  <div>
    <Select
      name="form-field-name"
      defaultInputValue={defaultValue}
      value={value}
      options={option}
      placeholder={placeHolder}
      styles={{
        control: base => ({
          ...base,
          backgroundColor: "transparent",
          fontSize: "100%",
          fontFamily: "Poppins",
          fontWeight: placeHolder || placeHolder === "City" ? 100 : "bold",
          "&:hover": { borderColor: "gray" },
          border: "none",
          boxShadow: "none",
          ...style
        }),
        option: () => {
          return {
            margin: 10,
            justifyContent: "",
            borderBottomColor: "#000",
            backgroundColor: "#fff",
            fontSize: 14,
            fontFamily: "Poppins"
          };
        },
        valueContainer: () => {
          return {
            textOverflow: "ellipsis",
            textAlign: "center",
            overflow: "hidden"
          };
        }
      }}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          text: "orangered",
          neutral50: placeHolder === "City" ? "rgba(124, 124, 124, .5)" : color,
          neutral80: placeHolder === "City" ? "rgba(124, 124, 124, .5)" : color
        }
      })}
      onChange={e => onValueChange && onValueChange(e)}
      isDisabled={disable || false}
    />
  </div>
);

PickerSelect.defaultProps = {
  color: "#666"
};
export default PickerSelect;
