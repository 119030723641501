import React, { BaseSyntheticEvent } from "react";

import { IMAGES } from "../../configs";
import "./styles.scss";

interface Props {
  name?: any;
  deleteFile?: (value: BaseSyntheticEvent) => void;
  style?: object;
  canDelete?: boolean;
  type?: "text" | "image";
  linkImage?: string | "document";
  onPress?: () => void;
  onChange?: () => void;
  isLoadingUpload?: boolean;
}

const FileUpload = ({
  name,
  deleteFile,
  style,
  canDelete,
  type,
  linkImage,
  onPress,
  isLoadingUpload,
  onChange
}: Props) => {
  const _handleRenderButton = () => {
    if (isLoadingUpload) {
      return null;
    }
    return (
      <div
        onClick={deleteFile}
        className={`delete ${type === "image" ? "image" : ""}`}
      >
        <img src={IMAGES.deleteFile} alt="" />
      </div>
    );
  };
  return (
    <div>
      <div
        className={`component-file-upload ${type === "image" ? "image" : ""}`}
        style={style}
        onClick={onPress}
        onChange={onChange}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: type === "image" ? "center" : "space-between",
            alignItems: "center"
          }}
        >
          {type === "text" ? (
            <div className="title">{name}</div>
          ) : (
            <img
              src={linkImage === "document" ? IMAGES.document : linkImage}
              alt="img-preview"
              style={
                linkImage === "document"
                  ? { width: "40%", height: "90%" }
                  : { width: "100%", height: "100%" }
              }
            />
          )}
          {canDelete ? _handleRenderButton() : null}
        </div>
      </div>
      <div className="coba-ellipsis">{type === "image" ? name : null}</div>
    </div>
  );
};

FileUpload.defaultProps = {
  canDelete: true,
  type: "text"
};

export default FileUpload;
