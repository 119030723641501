import moment from "moment";

import {
  DOWNLOAD_CSV_REPORT,
  DOWNLOAD_XML_REPORT,
  GET_TABLE_REPORT_ERROR,
  GET_TABLE_REPORT_PENDING,
  GET_TABLE_REPORT_SUCCESS,
  SET_ACTIVE_RANGE_FILTER_REPORT,
  SET_FORM_FILTER_WIDGET_REPORT
} from "../actions";
import { Action, ReportState } from "../types";

const initalState: ReportState = {
  listReport: {
    isLoadingReport: false,
    data: [],
    pagination: {}
  },
  form: {
    from: {
      months: moment().month() + 1,
      years: moment().year()
    },
    to: {
      months: moment().month() + 1,
      years: moment().year()
    }
  },
  activeRange: 0,
  downloadXML: null,
  downloadCSV: null
};

export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_TABLE_REPORT_PENDING:
      return {
        ...state,
        listReport: { ...state.listReport, isLoadingReport: true }
      };
    case GET_TABLE_REPORT_SUCCESS:
      return {
        ...state,
        listReport: {
          ...state.listReport,
          isLoadingReport: false,
          data: payload.data,
          pagination: payload.page
        }
      };
    case GET_TABLE_REPORT_ERROR:
      return {
        ...state,
        listReport: { ...state.listReport, isLoadingReport: false }
      };
    case SET_FORM_FILTER_WIDGET_REPORT:
      return {
        ...state,
        form: payload.data
      };
    case SET_ACTIVE_RANGE_FILTER_REPORT:
      return { ...state, activeRange: payload.data };
    case DOWNLOAD_XML_REPORT:
      return { ...state, downloadXML: payload.data };
    case DOWNLOAD_CSV_REPORT:
      return { ...state, downloadCSV: payload.data };
    default:
      return state;
  }
};
