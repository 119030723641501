import React from "react";

const SelectType = props => {
  // eslint-disable-next-line react/prop-types
  const { translate } = props;
  const option = [
    {
      text: translate("deleteData.select_type_default"),
      value: ""
    },
    {
      text: "Task",
      value: "task"
    },
    {
      text: "Invoice",
      value: "finance"
    }
  ];

  return (
    <>
      <div style={{ marginTop: "-30px" }}>
        <div
          style={{
            background: "#f3f3f3",
            padding: "30px",
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "5px"
          }}
        >
          <label style={{ fontWeight: "bold" }}>
            {translate("deleteData.type_warning")}
          </label>
          <select
            // eslint-disable-next-line react/prop-types
            onChange={e => props.choose(e)}
            style={{
              width: "100%",
              height: "40px",
              border: "none",
              borderBottom: "1px solid #dddddd",
              background: "#f3f3f3",
              color: "#363636",
              fontSize: "1em",
              cursor: "pointer"
            }}
          >
            {option.map((value, index) => (
              <option key={index} value={value.value}>
                {value.text}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
};

export default SelectType;
