import React, { useState } from "react";

import { isNumber } from "util";
import { ICONS, IMAGES } from "../../../../../../configs";
import { Picker } from "../../../../components";

interface Props {
  policySlug: string;
  handleChange: (policySlug: string, policies: { value: any }[]) => void;
  value: any[];
}

const Numbering = ({ handleChange, policySlug, value }: Props) => {
  const [renderNumberingText, setRenderNumberingText] = useState("");
  const [
    renderNumberingIterationDuration,
    setRenderNumberingIterationDuration
  ] = useState("1");
  const [
    renderNumberingIterationDigits,
    setRenderNumberingIterationDigits
  ] = useState("1");
  const iterationValues = [
    {
      id: 1,
      title: "Non Reset"
    },
    // {
    //   id: 2,
    //   title: "Weekly Reset"
    // },
    {
      id: 3,
      title: "Monthly Reset"
    },
    {
      id: 4,
      title: "Yearly Reset"
    }
  ];
  const renderTypeValue = (
    el: {
      type: string;
      value?: any;
      digits?: number;
      duration?: number;
    },
    example = false
  ) => {
    switch (el.type) {
      case "month":
        if (el.value === 1) {
          return "January"; // FULL
        }

        if (el.value === 2) {
          return "Jan"; // CHAR
        }

        if (el.value === 3) {
          return "01"; // DIGIT with leading zero
        }

        if (el.value === 4) {
          return "1"; // DIGIT without leading zero
        }

        if (el.value === 5) {
          return "I"; // ROMAN
        }
        return el.value;
      case "year":
        if (el.value === 1) {
          return "2021"; // FULL Year
        }
        if (el.value === 2) {
          return "21"; // 2 digits
        }

        return el.value;
      case "iteration":
        if (example && el.digits != null) {
          let str = "";
          for (let i = 0; i < el.digits - 1; i += 1) {
            str += "0";
          }
          return `${str}1`;
        }
        return `${
          iterationValues.filter(dt => dt.id === el.duration)[0].title
        }; ${el.digits} digits`;
      default:
        return el.value;
    }
  };
  const renderResultExample = () => {
    let str = "";
    value.forEach((el: { type: string; value: any }) => {
      str += renderTypeValue(el, true);
    });
    return str;
  };
  return (
    <div className="terms-policy-numbering margin-top-16">
      <div className="flex-wrap margin-bottom-20">
        {value.map((el: { type: string; value: any }, index: number) => (
          <div
            key={index}
            className="flex terms-policy-home-tag padding-8 margin-right-4 margin-bottom-4"
          >
            {el.type}
            :&nbsp;
            <b className="margin-right-8">{renderTypeValue(el)}</b>
            <div
              className="terms-policy-home-tag-round-btn-delete"
              onClick={() => {
                handleChange(policySlug, [
                  ...value.filter((_dt: any, i: number) => i !== index)
                ]);
              }}
            >
              <img
                src={IMAGES.btnDel}
                alt=""
                className="terms-policy-home-tag-btn-delete"
              />
            </div>
          </div>
        ))}
      </div>
      <div className="flex items-top margin-bottom-24 example">
        <span className="margin-right-8">Result example: </span>
        <b>{renderResultExample()}</b>
      </div>
      <div className="flex button-options">
        <div>
          <div className="flex items-top margin-bottom-20">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={renderNumberingText ? ICONS.btnAdd : ICONS.btnAddDisabled}
              onClick={() => {
                if (renderNumberingText) {
                  handleChange(policySlug, [
                    ...value,
                    {
                      type: "text",
                      value: renderNumberingText
                    }
                  ]);
                }
              }}
            />
            <div>
              <label className="margin-right-8">Text:</label>
              <input
                className="input-policy"
                style={{
                  textAlign: "left"
                }}
                type="text"
                value={renderNumberingText}
                onChange={e => {
                  setRenderNumberingText(e.target.value);
                }}
              />
            </div>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "month",
                  value: 1
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Month, full textual, eg:&nbsp;
              <b>January</b>
            </span>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "month",
                  value: 2
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Month, short textual, eg:&nbsp;
              <b>Jan</b>
            </span>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "month",
                  value: 3
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Month, numeric with leading zero, eg:&nbsp;
              <b>01</b>
            </span>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "month",
                  value: 4
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Month, numeric without leading zero, eg:&nbsp;
              <b>1</b>
            </span>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "month",
                  value: 5
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Month, roman numerals, eg:&nbsp;
              <b>I</b>
            </span>
          </div>
        </div>
        <div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "year",
                  value: 1
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Year, full numeric, eg:&nbsp;
              <b>2021</b>
            </span>
          </div>
          <div
            className="flex items-top margin-bottom-20"
            onClick={() => {
              handleChange(policySlug, [
                ...value,
                {
                  type: "year",
                  value: 2
                }
              ]);
            }}
          >
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={ICONS.btnAdd}
            />
            <span>
              Year, two digits, eg:&nbsp;
              <b>21</b>
            </span>
          </div>
          <div className="flex items-top margin-bottom-20">
            {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
            <img
              className="pointer margin-right-16"
              alt="tambah"
              src={
                value.filter((dt: { type: string }) => dt.type === "iteration")
                  .length === 0
                  ? ICONS.btnAdd
                  : ICONS.btnAddDisabled
              }
              onClick={() => {
                if (
                  value.filter(
                    (dt: { type: string }) => dt.type === "iteration"
                  ).length === 0
                ) {
                  handleChange(policySlug, [
                    ...value,
                    {
                      type: "iteration",
                      digits: parseInt(renderNumberingIterationDigits, 0),
                      duration: parseInt(renderNumberingIterationDuration, 0)
                    }
                  ]);
                }
              }}
            />
            <div>
              <div>
                <label className="margin-right-8">Iteration:</label>
                <Picker
                  styleContainer={{ display: "inline-block" }}
                  style={{ textAlignLast: "left" }}
                  listValue={iterationValues}
                  value={renderNumberingIterationDuration}
                  onChange={e => {
                    setRenderNumberingIterationDuration(e);
                  }}
                />
              </div>
              <div>
                <label className="margin-right-8">Digits:</label>
                <input
                  maxLength={1}
                  className="input-policy"
                  style={{
                    textAlign: "left"
                  }}
                  type="text"
                  value={renderNumberingIterationDigits}
                  onChange={e => {
                    if (isNumber(parseInt(e.target.value, 0))) {
                      setRenderNumberingIterationDigits(e.target.value);
                    }
                    if (e.target.value === "") {
                      setRenderNumberingIterationDigits("");
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbering;
