import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

const HomeIcon = () => {
  const configState = useSelector((state: Reducers) => state.config);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17.606"
      height="15.695"
      viewBox="0 0 460.298 460.297"
    >
      <g>
        <g>
          <path
            fill={configState.isDark ? "#fff" : "#666"}
            d="M230.149,120.939L65.986,256.274c0,0.191-0.048,0.472-0.144,0.855c-0.094,0.38-0.144,0.656-0.144,0.852v137.041
			c0,4.948,1.809,9.236,5.426,12.847c3.616,3.613,7.898,5.431,12.847,5.431h109.63V303.664h73.097v109.64h109.629
			c4.948,0,9.236-1.814,12.847-5.435c3.617-3.607,5.432-7.898,5.432-12.847V257.981c0-0.76-0.104-1.334-0.288-1.707L230.149,120.939
			z"
          />
          <path
            fill={configState.isDark ? "#fff" : "#666"}
            d="M457.122,225.438L394.6,173.476V56.989c0-2.663-0.856-4.853-2.574-6.567c-1.704-1.712-3.894-2.568-6.563-2.568h-54.816
			c-2.666,0-4.855,0.856-6.57,2.568c-1.711,1.714-2.566,3.905-2.566,6.567v55.673l-69.662-58.245
			c-6.084-4.949-13.318-7.423-21.694-7.423c-8.375,0-15.608,2.474-21.698,7.423L3.172,225.438c-1.903,1.52-2.946,3.566-3.14,6.136
			c-0.193,2.568,0.472,4.811,1.997,6.713l17.701,21.128c1.525,1.712,3.521,2.759,5.996,3.142c2.285,0.192,4.57-0.476,6.855-1.998
			L230.149,95.817l197.57,164.741c1.526,1.328,3.521,1.991,5.996,1.991h0.858c2.471-0.376,4.463-1.43,5.996-3.138l17.703-21.125
			c1.522-1.906,2.189-4.145,1.991-6.716C460.068,229.007,459.021,226.961,457.122,225.438z"
          />
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      {/* <g id="prefix__home" opacity={0.5}>
        <g id="prefix__Group_1384" data-name="Group 1384">
          <g id="prefix__Group_1383" data-name="Group 1383">
            <path
              id="prefix__Path_2033"
              d="M17.419 34l-8.346-6.114a.457.457 0 00-.54 0L.187 34a.457.457 0 10.541.738L8.8 28.822l8.075 5.913a.457.457 0 00.544-.735z"
              fill={configState.isDark ? "#fff" : "#000"}
              data-name="Path 2033"
              transform="translate(.001 -27.798)"
            />
          </g>
        </g>
        <g
          id="prefix__Group_1386"
          data-name="Group 1386"
          transform="translate(1.941 7.041)"
        >
          <g id="prefix__Group_1385" data-name="Group 1385">
            <path
              id="prefix__Path_2034"
              d="M69.719 232.543a.457.457 0 00-.457.457v7.282H65.6v-3.975a2.287 2.287 0 10-4.575 0v3.975h-3.66V233a.457.457 0 00-.915 0v7.739a.457.457 0 00.457.457h4.575a.457.457 0 00.456-.422.346.346 0 000-.036v-4.432a1.373 1.373 0 112.745 0v4.432a.336.336 0 000 .035.457.457 0 00.456.422h4.575a.457.457 0 00.457-.457V233a.458.458 0 00-.452-.457z"
              fill={configState.isDark ? "#fff" : "#666"}
              data-name="Path 2034"
              transform="translate(-56.452 -232.543)"
            />
          </g>
        </g>
      </g> */}
    </svg>
  );
};

export default memo(HomeIcon);
