import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Container, Spinner, Table, Text } from "../../../../components";
import {
  downloadReportCSV,
  downloadReportXML,
  getReportTable
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import Filter from "../component/Filter";
import "./styles.scss";
import { documentTitle } from "../../../../utils";

const Home = () => {
  documentTitle("Report - Data Export");
  const dispatch = useDispatch();
  const reportState = useSelector((state: Reducers) => state.report);

  useEffect(() => {
    dispatch(getReportTable(1));
  }, [dispatch]);

  const _downloadXML = () => {
    dispatch(downloadReportXML());
    if (reportState.downloadXML) {
      const url = window.URL.createObjectURL(
        new Blob([reportState.downloadXML.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xml");
      document.body.appendChild(link);
      link.click();
    }
  };

  const _downloadCSV = () => {
    dispatch(downloadReportCSV());
    if (reportState.downloadCSV) {
      const url = window.URL.createObjectURL(
        new Blob([reportState.downloadCSV.data])
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  const _renderTable = () => {
    if (reportState.listReport.data.length !== 0) {
      return (
        <Table
          prevPage={() =>
            dispatch(
              getReportTable(reportState.listReport.pagination.current_page - 1)
            )
          }
          nextPage={() =>
            dispatch(
              getReportTable(reportState.listReport.pagination.current_page + 1)
            )
          }
          pagination={reportState.listReport.pagination}
          data={reportState.listReport.data}
          downloadXML={() => _downloadXML()}
          downloadCSV={() => _downloadCSV()}
        />
      );
    }

    return (
      <div className="report-home-no-data">
        <Text className="report-home-no-data-text">No Data</Text>
      </div>
    );
  };

  return (
    <Container
      withMaxWidth
      style={{ paddingLeft: 122 }}
      className="page-report-data"
    >
      <div
        className="report-home-content-row report-home-content-row-margin-bottom"
        style={{ width: "75%", marginTop: 15, marginBottom: 20 }}
      >
        <Filter />
      </div>
      <div style={{ marginTop: 33 }}>
        {reportState.listReport.isLoadingReport ? (
          <div className="report-home-spinner">
            <Spinner />
          </div>
        ) : (
          _renderTable()
        )}
      </div>
    </Container>
  );
};

export default Home;
