/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Autocomplete,
  Button,
  Picker,
  Spinner,
  Text
} from "../../../../../components";
import "./styles.scss";
import { Reducers } from "../../../../../redux/types";

interface Props {
  name?: string;
  isAccount?: boolean;
  hapus?: boolean;
  primary?: boolean;
  data?: any;
  dataPopulate?: any;
  dataAccount?: any;
  optionData?: string;
  setOptionData?: (e: any) => void;
  hapusAddress?: () => void;
  hapusAccount?: () => void;
  onChange?: (alias: { name: string; value: string }) => void;
  listValue?: any;
  isLoading?: boolean;
  isError?: boolean;
  index?: number;
}

const CardCompany = ({
  name,
  hapus,
  listValue,
  isAccount,
  hapusAddress,
  hapusAccount,
  onChange,
  data,
  dataAccount,
  isLoading,
  isError,
  index
}: Props) => {
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const employeeState = useSelector((state: Reducers) => state.employee);

  const _bank = useCallback(() => {
    let result: any[] = [];
    employeeState.listBank.list.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  }, [employeeState.listBank.list]);

  const _isCash = useCallback(() => {
    if (listValue.length > 0) {
      const indexValue = listValue.findIndex(
        item => item.value === dataAccount.payment_id
      );
      return listValue[indexValue] && listValue[indexValue].label === "Cash";
    }
    return false;
  }, [dataAccount.payment_id, listValue]);

  const _valueBank = useCallback(() => {
    const indexValue = _bank().findIndex(
      item => item.value === dataAccount.bank_id
    );
    return _bank()[indexValue].label;
  }, [_bank, dataAccount.bank_id]);

  const _handleFormValidation = useCallback(
    (isShowError: boolean | undefined, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  return (
    <div className="dashboard-company-card-form-container shadow">
      {!isLoading ? (
        <>
          <div className="dashboard-company-card-form-button-container">
            {isAccount ? (
              <div className="dashboard-company-picker">
                <Picker
                  listValue={listValue}
                  onChange={e => {
                    const indexValue = listValue.findIndex(
                      res => res.value === e.target.value
                    );
                    onChange!({ name: "payment_id", value: e.target.value });
                    onChange!({
                      name: "payment_name",
                      value: listValue[indexValue].label
                    });
                  }}
                  value={dataAccount.payment_id}
                />
              </div>
            ) : (
              <div style={{ width: "90%" }}>
                <Text
                  style={{
                    paddingTop: "0.4rem",
                    fontSize: "1.2rem"
                  }}
                >
                  {name}
                </Text>
              </div>
            )}
            <div
              className="dashboard-company-card-form-button-container"
              style={{ marginLeft: "auto" }}
            >
              <div style={{ paddingRight: "0.4rem" }}>
                <Button
                  onPress={!isAccount ? hapusAddress : hapusAccount}
                  className={`button small ${hapus ? "" : "alt"}`}
                >
                  {t("btn.delete")}
                </Button>
              </div>
              {/* <div>
            <Button className={`button small hollow ${primary ? "" : "alt"}`}>
              {primary ? "PRIMARY" : "MAKE PRIMARY"}
            </Button>
          </div> */}
            </div>
          </div>
          {!isAccount ? (
            <div style={{ paddingBottom: 20 }}>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.alias}
                  onChange={e =>
                    onChange!({ name: "alias", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.line_1}
                  onChange={e =>
                    onChange!({ name: "line_1", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder=" Address #03 Alias"
                  value={data.line_2}
                  onChange={e =>
                    onChange!({ name: "line_2", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="City"
                  value={data.city}
                  onChange={e =>
                    onChange!({ name: "city", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Zip Code"
                  value={data.zipcode}
                  onChange={e =>
                    onChange!({ name: "zip_code", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Phone No."
                  value={data.phone}
                  onChange={e =>
                    onChange!({ name: "phone", value: e.target.value })
                  }
                />
              </div>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="Fax No."
                  value={data.fax}
                  onChange={e =>
                    onChange!({ name: "fax", value: e.target.value })
                  }
                />
              </div>
            </div>
          ) : (
            <div style={{ paddingBottom: 20 }}>
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder={t("company.form.title")}
                  value={dataAccount.title}
                  onChange={e =>
                    onChange!({ name: "title", value: e.target.value })
                  }
                  name={`accounts[title][${index}]`}
                />
              </div>
              {_handleFormValidation(
                isError && dataAccount.title === "",
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("company.form.title")
                )
              )}
              {!_isCash() && (
                <>
                  <div className="dashboard-company-card-form-underline ">
                    <input
                      className="dashboard-company-card-form-user-text"
                      placeholder={t("personnel.form.accountName")}
                      value={dataAccount.account_name}
                      onChange={e =>
                        onChange!({
                          name: "account_name",
                          value: e.target.value
                        })
                      }
                      name={`accounts[account_name][${index}]`}
                    />
                  </div>
                  {_handleFormValidation(
                    isError && dataAccount.account_name === "",
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("company.form.accountName")
                    )
                  )}
                </>
              )}
              {!_isCash() && (
                <>
                  <div
                    className="dashboard-company-card-form-underline"
                    style={{ marginTop: 15 }}
                  >
                    <Autocomplete
                      option={_bank()}
                      placeHolder={
                        dataAccount.bank_id
                          ? _valueBank()
                          : t("personnel.form.bankName")
                      }
                      onValueChange={e =>
                        onChange!({ name: "bank_id", value: e.value })
                      }
                    />
                  </div>
                  {_handleFormValidation(
                    isError && dataAccount.bank_id === "",
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.bankName")
                    )
                  )}
                </>
              )}
              {!_isCash() && (
                <>
                  <div className="dashboard-company-card-form-underline">
                    <input
                      className="dashboard-company-card-form-user-text"
                      placeholder={t("personnel.form.accountNumber")}
                      value={dataAccount.account_number}
                      onChange={e =>
                        onChange!({
                          name: "account_number",
                          value: e.target.value
                        })
                      }
                      name={`accounts[account_number][${index}]`}
                    />
                  </div>
                  {_handleFormValidation(
                    isError && dataAccount.account_number === "",
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("company.form.accountNumber")
                    )
                  )}
                </>
              )}
              {!_isCash() && (
                <>
                  <div className="dashboard-company-card-form-underline">
                    <input
                      className="dashboard-company-card-form-user-text"
                      placeholder={t("personnel.form.branch")}
                      value={dataAccount.branch}
                      onChange={e =>
                        onChange!({ name: "branch", value: e.target.value })
                      }
                      name={`accounts[branch][${index}]`}
                    />
                  </div>
                  {_handleFormValidation(
                    isError && dataAccount.branch === "",
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("company.form.branch")
                    )
                  )}
                </>
              )}
              <div className="dashboard-company-card-form-underline">
                <input
                  className="dashboard-company-card-form-user-text"
                  placeholder="COA"
                  value={dataAccount.coa}
                  onChange={e =>
                    onChange!({ name: "coa", value: e.target.value })
                  }
                  name={`accounts[coa][${index}]`}
                />
              </div>
              {_handleFormValidation(
                isError && dataAccount.coa === "",
                sprintf(t("personnel.form.isRequiredMsg"), "COA")
              )}
            </div>
          )}
        </>
      ) : (
        <div className="dashboard-profile-wrap-loading">
          <Spinner />
        </div>
      )}
    </div>
  );
};

CardCompany.defaultProps = {
  isError: false
};

export default memo(CardCompany);
