import React, { memo } from "react";

import "./styles.scss";

interface Props {
  label?: string;
  onChange?: (e: any) => void;
  value?: string;
  placeholder?: string;
}

const InputText = ({ label, onChange, value, placeholder }: Props) => (
  <div>
    <div className="components-employee-custom-label">{label}</div>
    <input
      placeholder={placeholder}
      className="components-employee-custom-input"
      onChange={onChange}
      value={value}
    />
  </div>
);

export default memo(InputText);
