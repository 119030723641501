import { useSelector } from "react-redux";

import { Reducers } from "../redux/types";

const Roles = (slug: string) => {
  const homeState = useSelector((state: Reducers) => state.home);
  const res = homeState.profile.listRole.find((el: any) => el === slug);
  if (res) {
    return true;
  }
  return false;
};

export default Roles;
