import {
  GET_PROFILE_BY_ID_ERROR,
  GET_PROFILE_BY_ID_PENDING,
  GET_PROFILE_BY_ID_SUCCESS
} from "../actions";
import { Action, ProfileByIdState } from "../types";

const initalState: ProfileByIdState = {
  profileById: null,
  isLoadingProfileById: false
};

export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_PROFILE_BY_ID_PENDING:
      return {
        ...state,
        isLoadingProfileById: true,
        profileById: null
      };
    case GET_PROFILE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingProfileById: false,
        profileById: payload.data
      };
    case GET_PROFILE_BY_ID_ERROR:
      return {
        ...state,
        isLoadingProfileById: false,
        profileById: null
      };
    default:
      return { ...state };
  }
};
