/* eslint-disable react/jsx-indent */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";

import "./styles.scss";
import {
  getDetailTaskRemarks,
  getTaskForm,
  getTaskPrint
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { documentTitle, textToLink } from "../../../../utils";
import { Loading } from "../../../../components";

const PrintPage = () => {
  documentTitle("Task_Form");
  const dispatch = useDispatch();
  const { id } = useParams() as any;

  const taskState = useSelector((state: Reducers) => state.task);

  useEffect(() => {
    dispatch(getTaskPrint(id));
    dispatch(getTaskForm(String(id)));
    dispatch(getDetailTaskRemarks(String(id)));
  }, [dispatch, id]);

  useEffect(() => {
    if (
      taskState.listTaskForm &&
      taskState.listTaskForm.id &&
      taskState.listTaskPrint &&
      taskState.taskDetailRemarks
    ) {
      window.print();
    }
  }, [
    taskState.listTaskForm,
    taskState.listTaskPrint,
    taskState.taskDetailRemarks
  ]);

  return (
    <div className="container-print-task">
      <div>
        <table className="table-header-task">
          <thead>
            <tr>
              <th>Task Form</th>
            </tr>
          </thead>
          <tbody className="pt">
            <td />
            <td>Task ID :</td>
            <td>{taskState.listTaskForm && taskState.listTaskForm.number}</td>
          </tbody>
        </table>
      </div>

      <div className="wrap-detail-task">
        <table className="table-detail-task">
          <tbody className="pt">
            <tr>
              <td>Assigned to :</td>
              <td>
                {taskState.listTaskForm &&
                  taskState.listTaskForm.assignee.map((el: any) => (
                    <>
                      {`${el.employee && el.employee.name} (${el.employee &&
                        el.employee.position_name} - NIK ${el.employee &&
                        el.employee.number})`}
                      <br />
                    </>
                  ))}
              </td>
              <td>Assign by :</td>
              <td>
                {`${taskState.listTaskForm &&
                  taskState.listTaskForm.creator.employee
                    .name} (${taskState.listTaskForm &&
                  taskState.listTaskForm.creator.employee.position_name})`}
              </td>
            </tr>
            <tr>
              <td rowSpan={3}>Description :</td>
              <td rowSpan={3}>
                <span
                  dangerouslySetInnerHTML={textToLink(
                    taskState.listTaskForm && taskState.listTaskForm.description
                      ? taskState.listTaskForm.description
                      : ""
                  )}
                />
              </td>
              <td>Assign date</td>
              <td>
                {moment(
                  taskState.listTaskForm && taskState.listTaskForm.started_at
                ).format("DD/MM/YY")}
              </td>
            </tr>
            <tr>
              <td>Expected date</td>
              <td>
                {moment(
                  taskState.listTaskForm && taskState.listTaskForm.due_at
                ).format("DD/MM/YY")}
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="td-height" />
            </tr>
          </tbody>
        </table>
      </div>

      <div className="wrap-detail-task">
        <table className="table-report-task">
          <thead>
            <tr>
              <th>Task Report</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {taskState.listTaskPrint
                  ? taskState.listTaskPrint.map((el: any) => (
                      <>
                        <div className="bold">
                          {`${el.creator && el.creator.employee.name} (${moment(
                            el.date
                          ).format("DD MMM YYYY")}):`}
                        </div>
                        <div
                          dangerouslySetInnerHTML={textToLink(
                            el.description.replace(
                              /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
                              ""
                            )
                          )}
                        />
                        <div>
                          <b>File Attachment: </b>
                          {el.file_collection.length > 0
                            ? el.file_collection.map((item: any, i: number) => (
                                <a
                                  key={i}
                                  href={item.file.url}
                                  style={{ color: "blue" }}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {el.file_collection.length - 1 !== i
                                    ? `${item.file.title}, `
                                    : item.file.title}
                                </a>
                              ))
                            : "-"}
                        </div>
                        <br />
                      </>
                    ))
                  : "-"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <table>
        <tbody className="pt">
          <tr>
            <td>
              <td className="bold">Attachment:</td>
            </td>
          </tr>
          {taskState.listTaskForm &&
            taskState.listTaskForm.file_collection.map((el: any, i: number) => (
              <tr key={i}>
                <td style={{ paddingLeft: 4 }}>
                  {`File ${i + 1}: `}
                  <a
                    key={i}
                    href={el.file.url}
                    style={{ color: "blue" }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {el.file.title}
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <table>
        <tbody className="pt">
          <tr>
            <td>
              <td className="bold">Remarks:</td>
            </td>
          </tr>
          {taskState?.taskDetailRemarks?.comments &&
          taskState?.taskDetailRemarks?.comments.length > 0
            ? taskState.taskDetailRemarks.comments.map(
                (item: any, index: number) => (
                  <div
                    key={index}
                    className="print-page-remark margin-bottom-20"
                  >
                    <div>
                      <div>
                        <div className="grey">
                          {moment(item.created_at).format(
                            "ddd, DD/MM/YY HH:mm"
                          )}
                        </div>
                        <div>{item.creator.name}</div>
                      </div>
                      <p>{item.content}</p>
                    </div>
                  </div>
                )
              )
            : null}
        </tbody>
      </table>

      <Loading
        isVisible={
          taskState.isLoadingTaskDetailReport || taskState.isLoadingTaskForm
        }
      />
    </div>
  );
};

export default PrintPage;
