import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import "../../styles.scss";
import "./styles.scss";

import {
  PaginationBackEmployee,
  PaginationForwardEmployee
} from "../../../../../assets";
import { Button, Container } from "../../../../../components";
import { IMAGES } from "../../../../../configs";
import { getTaskList } from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";
import { Permission, ternaryFunc } from "../../../../../utils";

interface Props {
  type:
    | "upcoming"
    | "ongoing"
    | "finished"
    | "assigned"
    | "myrequest"
    | "moderation";
}

const Table = ({ type }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const permission = {
    self: Permission("task-self-view"),
    others: Permission("task-others-view")
  };

  const _statusColor = (status: number, historyExtends: any[]) => {
    let color = "";
    if (status === 1 || status === 2) {
      color = "color-y";
    } else if (status === 4) {
      color = "color-o";
    } else if (status === 5 && historyExtends.length > 1) {
      color = "color-b";
    }
    return color;
  };

  const statusDesc = (status: number, historyExtends: any[]) => {
    let label = "";
    if (status === 1) {
      label = t("status.draft");
    } else if (status === 2) {
      label = t("status.issued");
    } else if (status === 3) {
      label = t("status.assigned");
    } else if (status === 4) {
      label = t("status.revise");
    } else if (status === 5 && historyExtends.length > 1) {
      label = t("status.delayed");
    } else if (status === 5) {
      label = t("status.ongoing");
    } else if (status === 6) {
      label = t("status.finished");
    } else if (status === 7) {
      label = t("status.canceled");
    }
    return label;
  };

  useEffect(() => {
    dispatch(getTaskList(type, ""));
  }, [dispatch, type]);

  const taskState = useSelector((state: Reducers) => state.task);

  const _datectTypeFile = (ext: any) => {
    if (ext.length === 0) {
      return false;
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "jpg" ||
          el.file.extension === "jpeg" ||
          el.file.extension === "png"
      )
    ) {
      return "image";
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx"
      )
    ) {
      return "document";
    }
    if (
      ext.every(
        (el: any) =>
          el.file.extension === "doc" ||
          el.file.extension === "docx" ||
          el.file.extension === "pdf" ||
          el.file.extension === "xlsx" ||
          el.file.extension === "jpg" ||
          el.file.extension === "png" ||
          el.file.extension === "jpeg"
      )
    ) {
      return "mix";
    }
    return false;
  };

  const _renderTypeFile = (item: any) => {
    if (_datectTypeFile(item) === "document") {
      return (
        <img src={IMAGES.document} alt="n/a" className="task-image-list" />
      );
    }
    if (_datectTypeFile(item) === "image") {
      return (
        <img src={IMAGES.picAttach} alt="n/a" className="task-image-list" />
      );
    }
    if (_datectTypeFile(item) === "mix") {
      return <img src={IMAGES.mixed} alt="n/a" className="task-image-list" />;
    }
    return <></>;
  };

  return (
    <Container
      isLoading={taskState.isLoadingTaskList}
      style={{ marginTop: 70 }}
    >
      {taskState.taskList.length > 0 ? (
        <table className="data-table">
          <thead>
            <tr>
              <th>{t("task.tableCol.taskNumber")}</th>
              <th>{t("task.tableCol.assignee")}</th>
              <th>{t("task.tableCol.assignBy")}</th>
              <th>{t("task.tableCol.activity")}</th>
              <th className="hide">ACTIVITY DATE</th>
              <th colSpan={2}>{t("task.tableCol.timeline")}</th>
              <th style={{ paddingLeft: 30, width: 1 }}>
                {t("task.tableCol.status")}
              </th>
              <th colSpan={2} className="task-list-regular-text">
                <div className="task-list-pagging">{`${taskState.paginationTask.from} - ${taskState.paginationTask.to} of ${taskState.paginationTask.total}`}</div>
                {taskState.paginationTask.current_page > 1 && (
                  <div
                    onClick={() =>
                      dispatch(
                        getTaskList(
                          type,
                          "",
                          taskState.paginationTask.current_page - 1
                        )
                      )
                    }
                    className="task-list-pagging arrow pointer"
                  >
                    <PaginationBackEmployee />
                  </div>
                )}
                {taskState.paginationTask.current_page <
                  taskState.paginationTask.last_page && (
                  <div
                    onClick={() =>
                      dispatch(
                        getTaskList(
                          type,
                          "",
                          taskState.paginationTask.current_page + 1
                        )
                      )
                    }
                    className="task-list-pagging arrow pointer"
                  >
                    <PaginationForwardEmployee />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody className="double">
            {taskState.taskList.map((item: any, index: number) => {
              return (
                <tr key={index}>
                  <td className="grey">
                    {permission.self || permission.others ? (
                      <>
                        <Link
                          style={{ color: "#db3a26" }}
                          to={`/task/detail/${item.id}`}
                        >
                          <p>{item.number}</p>
                        </Link>
                        <span>
                          {ternaryFunc(item.is_team, "Team", "Individu")}
                        </span>
                        {ternaryFunc(
                          item.recurring,
                          <p className="recurring-sign">
                            {t("task.repeatTask")}
                          </p>,
                          ""
                        )}
                      </>
                    ) : (
                      <p>{item.number}</p>
                    )}
                  </td>
                  <td>
                    <p>
                      {ternaryFunc(
                        item.pic && item.pic.employee && item.pic.employee.name,
                        item?.pic?.employee?.name,
                        item?.assignee && item.assignee.length > 0
                          ? item.assignee[0]?.user?.name
                          : ""
                      )}
                    </p>
                    <span>
                      {ternaryFunc(
                        item.pic &&
                          item.pic.employee &&
                          item.pic.employee.position_name,
                        item?.pic?.employee?.position_name,
                        item?.assignee && item.assignee.length > 0
                          ? item.assignee[0]?.role
                          : ""
                      )}
                    </span>
                  </td>
                  <td>
                    <p>
                      {item.assigner && item.assigner.name
                        ? item.assigner.name
                        : "teset"}
                    </p>
                  </td>
                  <td>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <p className="table-task-list-ellipsis">{item.title}</p>
                      <div>
                        {_renderTypeFile(
                          item.file_collection ? item.file_collection : []
                        )}
                      </div>
                    </div>
                    <span className="table-task-list-ellipsis">
                      {item.project && item.project.title}
                    </span>
                  </td>
                  <td className="grey">
                    <div>From</div>
                    <div>To</div>
                  </td>
                  <td>
                    <div>
                      {moment(item.started_at).format("ddd, DD MMM YYYY")}
                    </div>
                    <div>
                      {moment(
                        type === "finished" ? item.finished_at : item.due_at
                      ).format("ddd, DD MMM YYYY")}
                    </div>
                  </td>
                  <td colSpan={2} style={{ padding: 0, height: 1, width: 100 }}>
                    <div
                      className={`status ${_statusColor(
                        item.status,
                        item.history_extends
                      )}`}
                    >
                      <div className="task-list-status-td">
                        <p className="bold">
                          {statusDesc(item.status, item.history_extends)}
                        </p>
                        <span className="hide">
                          {moment(item.started_at).format("ddd, DD MMM YYYY")}
                        </span>
                      </div>
                      <div className="task-list-action actions">
                        {permission.self && (
                          <Button
                            onPress={() =>
                              history.push(`/task/detail/${item.id}`)
                            }
                          >
                            <img
                              src={IMAGES.pencil}
                              alt="n/a"
                              style={{ height: 50, width: 50 }}
                              className="pointer"
                            />
                          </Button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="task-empty-data">{t("emptyData")}</div>
      )}
    </Container>
  );
};

export default memo(Table);
