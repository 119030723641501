import React, { memo } from "react";

import "./styles.scss";

interface ListValue {
  id: any;
  title: any;
}

interface Props {
  listValue: ListValue[];
  onChange?: (e: string) => void;
  value?: string;
  selected?: any;
  style?: React.CSSProperties;
  styleContainer?: React.CSSProperties;
}

const Picker = ({
  listValue,
  onChange,
  value,
  selected,
  style,
  styleContainer
}: Props) => {
  return (
    <div className="picker-container global-shadow-card" style={styleContainer}>
      <select
        style={style}
        className="policy-picker-select"
        value={value}
        onChange={e => onChange && onChange(e.target.value)}
      >
        <option hidden>-- Select --</option>
        {listValue.map((item, index) => (
          <option key={index} value={item.id} selected={item.id === selected}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default memo(Picker);
