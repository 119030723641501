/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorMessage, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import Modal from "../Modal";
import { Autocomplete, Button, FileUpload, ModalSubmitForm, Upload } from "..";
import { API, IMAGES } from "../../configs";
import {
  getDepartment,
  getEmployee,
  getRequestParticipant,
  getTaskList,
  postTaskRequest,
  resetRequestParticipant,
  setModalRequest,
  setModalRequestParticipant
} from "../../redux/actions";
import { Reducers } from "../../redux/types";
import {
  Permission,
  autoGrowTextArea,
  copyWritings,
  getPolicies,
  openNewTab
} from "../../utils";
import ModalTaskRequestUserPicker from "../ModalTaskRequestUserPicker";
import { usePrevious } from "../../utils/prevDataHook";

const ModalRequestTask = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    getValues
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const checkPermission = Permission("task-others-list");

  const { sprintf } = require("sprintf-js");

  const { project, setModal, assignLoading, taskState } = useSelector(
    (state: Reducers) => ({
      project: state.generate.dataProject,
      setModal: state.task.setModalRequest,
      assignLoading: state.task.isLoadingPostTask,
      taskState: state.task
    }),
    shallowEqual
  );

  const policy = {
    min: getPolicies("task-min-date"),
    max: getPolicies("task-max-date"),
    minAssign: getPolicies("task-assign-min-date"),
    maxAssign: getPolicies("task-assign-max-date")
  };

  const policyDate = {
    minDate: moment()
      .add(policy.min, "d")
      .format("YYYY-MM-DD"),
    maxDate: moment()
      .add(policy.max, "d")
      .format("YYYY-MM-DD"),
    minAssignDate: moment()
      .add(policy.minAssign, "d")
      .format("YYYY-MM-DD"),
    maxAssignDate: moment()
      .add(policy.maxAssign, "d")
      .format("YYYY-MM-DD")
  };

  const handleMessage = useCallback(() => {
    if (
      policyDate.minAssignDate > getValues("started_at") &&
      taskState.saveParticipanList.length > 0
    ) {
      return "min-assign";
    }
    if (
      policyDate.maxAssignDate < getValues("due_at") &&
      taskState.saveParticipanList.length > 0
    ) {
      return "max-assign";
    }
    if (
      policyDate.minDate > getValues("started_at") &&
      taskState.saveParticipanList.length === 0
    ) {
      return "min";
    }
    if (
      policyDate.maxDate < getValues("due_at") &&
      taskState.saveParticipanList.length === 0
    ) {
      return "max";
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.saveParticipanList, getValues("due_at")]);

  const populateProject = () => {
    let result = [] as any;
    project.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  };

  const initialBody = {
    recurring: false,
    recurring_period: "",
    recurring_time: "00:00"
  };

  const initialSubmitForm = {
    form: null,
    message: ""
  };

  const [body, setBody] = useState(initialBody);
  const [searchUser, setSearchUser] = useState("");
  const [department, setDepartment] = useState("");
  const previousDepartment = usePrevious(department);
  const [file, setFile]: any = useState([]);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);
  const [bodyProject, setBodyProject] = useState([]) as any[];
  const [error, setError] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null) as any;

  useEffect(() => {
    setSearchUser("");
    dispatch(
      getEmployee("", "", "", 1, false, "", true, true, false, false, true)
    );
    if (body.recurring_period) {
      setBody({ ...body, recurring: true });
    } else if (body.recurring_period === "") {
      setBody({ ...body, recurring: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.setModalRequestParticipant, body.recurring_period]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        dispatch(
          getEmployee(
            department || "",
            "",
            "",
            1,
            false,
            searchUser || "",
            false,
            false,
            false,
            false,
            true
          )
        );
      },
      department !== previousDepartment ? 0 : 1500
    );

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, dispatch, searchUser]);

  const _setParticipanList = useCallback(() => {
    setDepartment("");
    dispatch(setModalRequestParticipant(true));
    dispatch(getDepartment());
    dispatch(getRequestParticipant());
  }, [dispatch]);

  const UploadDocument = async (e: any) => {
    const { files } = e.target;
    Array.from(files).map((item: any) => {
      item.id = Math.random()
        .toString(36)
        .substring(7);
      const result = file.findIndex((ed: any) => ed.name === item.name);
      if (result < 0) {
        setFile((currentArray: any) => [...currentArray, item]);
      } else {
        toast(t("task.form.message.duplicateAttachment"), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "request-task-toast request-red",
          closeButton: false,
          draggable: false
        });
      }
      return true;
    });
    e.target.value = null;
  };

  const deleteFile = (id: any) => {
    const newFile = file.filter((el: any) => el.id !== id);
    setFile(newFile);
  };

  const _getIdParticipant = () => {
    const result = [] as any;
    if (taskState.saveRequestParticipanList.length > 0) {
      taskState.saveRequestParticipanList.map(el => result.push(el.id));
    }
    return result;
  };

  const _onConfirmSubmitForm = (data: any) => {
    if (_getIdParticipant().length < 1) {
      const message = "Please choose user to request";
      toast(`${message}`, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "request-task-toast request-red",
        closeButton: false,
        draggable: false
      });
    } else {
      const form = {
        ...data,
        ...body,
        participants: _getIdParticipant()
      };
      let tempData: any = [];
      Object.keys(form).map((key: any) => {
        if (form[key]) {
          tempData = [...tempData, { title: key, value: form[key] }];
        }
        return true;
      });

      let files: any = [];
      if (file.length > 0) {
        file.map((e: any) => {
          files = [...files, e.name];
          return true;
        });
      }

      const projectName = selectedProject.label || "";

      let participants: any = [];
      taskState.saveRequestParticipanList.map(e => {
        participants = [...participants, e.name];
        return true;
      });

      const result: any = [
        { title: t("task.form.activityName"), value: tempData[0].value || "-" },
        { title: t("task.form.projectName"), value: projectName || "-" },
        {
          title: t("task.form.participants"),
          value: participants.length > 0 ? participants : "-"
        },
        {
          title: t("task.form.startDate"),
          value: moment(tempData[2].value).format("DD MMM YYYY") || "-"
        },
        {
          title: t("task.form.finishDate"),
          value: moment(tempData[1].value).format("DD MMM YYYY") || "-"
        },
        { title: t("task.form.description"), value: tempData[3].value || "-" },
        {
          title: t("task.form.attachment"),
          value: files.length > 0 ? files : "-"
        }
      ];

      if (result && bodyProject.length > 0) {
        setDataConfirmSubmit(result);
        setModalSubmitForm(true);
      } else {
        setError(true);
      }
    }
  };

  const _onSave = (data: any) => {
    const projecId =
      bodyProject.length > 0 &&
      bodyProject.find((_, index) => index === bodyProject.length - 1)?.value;

    const form = {
      ...data,
      ...body,
      project_id: projecId,
      files: file,
      participants: _getIdParticipant(),
      assigned: 1,
      client_timezone: "Asia/Jakarta",
      finished_at: "",
      repeat_at: 1
    };
    let message = "";
    if (_getIdParticipant().length < 1) {
      message = "Please choose user to request";
      toast(`${message}`, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "request-task-toast request-red",
        closeButton: false,
        draggable: false
      });
    } else {
      setSubmitForm({ ...submitForm, form, message });
      dispatch(
        postTaskRequest(form, (e: { success: boolean; message: any }) => {
          const fileErr = e.message?.error_messages?.file?.[0] || e.message;
          if (e.success === true) {
            setBodyProject([]);
            dispatch(setModalRequest(false));
            setModalSubmitForm(false);
            dispatch(resetRequestParticipant());
            toast(`${e.message.toUpperCase()}`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              transition: Slide,
              className: "request-task-toast",
              closeButton: false,
              draggable: false
            });
            if (checkPermission) {
              history.push("assigned-task");
              dispatch(getTaskList("assigned", ""));
            } else {
              history.push("upcoming");
              dispatch(getTaskList("upcoming", ""));
            }
            setBody(initialBody);
            setFile([]);
            reset();
          } else if (e.success === false) {
            toast(`${fileErr}`, {
              position: "top-center",
              autoClose: 3500,
              hideProgressBar: true,
              transition: Slide,
              className: "request-task-toast request-red",
              closeButton: false,
              draggable: false
            });
          }
        })
      );
    }
  };

  const _onCancel = () => {
    reset();
    setBodyProject([]);
    dispatch(setModalRequest(false));
    dispatch(resetRequestParticipant());
    setFile([]);
    setBody(initialBody);
  };

  const _convertDataSelect = (data: any[]) => {
    let result = [] as any;
    if (data && data.length > 0) {
      data.map((e: any) => {
        result = [...result, { value: e.id, label: e.attributes.title }];
        return true;
      });
    }
    return result;
  };

  const _getChildProject = (id: string, index: number) => {
    API.getProjectV2(id).then(res => {
      const dataPj: any[] = res.data.data;
      if (index === 0) {
        const filtered = bodyProject.filter(
          (_: any, i: number) => i + 1 <= index
        );
        setBodyProject([...filtered]);
        setTimeout(() => {
          const result = [...filtered, { value: id, list: dataPj }];
          setBodyProject(result);
        }, 400);
      }
      if (index > 0 && dataPj?.length > 0 && bodyProject?.length > 0) {
        const filtered = bodyProject.filter(
          (_: any, i: number) => i + 1 <= index
        );
        setBodyProject([...filtered]);
        setTimeout(() => {
          const result = [...filtered, { value: id, list: dataPj }];
          setBodyProject(result);
        }, 400);
      }
      if (index > 0 && dataPj?.length === 0) {
        bodyProject[index] = { value: id, list: [] };
      }
    });
  };

  return (
    <>
      <Modal
        loading={assignLoading}
        titleModal={sprintf(t("task.form.requestTask"), copyWritings("task"))}
        isOpen={setModal}
        onPressCancel={_onCancel}
        onPressSubmit={handleSubmit(_onConfirmSubmitForm)}
        btnSubmitText={t("btn.request")}
        btnCancelText={t("btn.cancel")}
      >
        {handleMessage() && (
          <div className="request-task-message">
            <img
              src={IMAGES.hazardSign}
              alt="n/a"
              className="task-request-hazard"
            />
            {handleMessage() === "min-assign"
              ? sprintf(t("task.form.message.policyMinDate"), policy.minAssign)
              : handleMessage() === "max-assign"
              ? sprintf(t("task.form.message.policyMaxDate"), policy.maxAssign)
              : handleMessage() === "min"
              ? sprintf(t("task.form.message.policyMinDate"), policy.min)
              : handleMessage() === "max"
              ? sprintf(t("task.form.message.policyMaxDate"), policy.max)
              : ""}
          </div>
        )}
        <form
          className="form-request-task margin-top-12"
          onSubmit={handleSubmit(_onSave)}
        >
          <div style={{ width: 494 }}>
            <span className="component-modal-request-task-label">
              {t("task.form.requestTo")}
            </span>
            <div className="modal-assign-person-container margin-bottom-12">
              {taskState.saveRequestParticipanList.length > 0 ? (
                taskState.saveRequestParticipanList.map((el, index) => (
                  <div
                    className="modal-assign-person margin-right-8 margin-top-8"
                    key={index}
                  >
                    <img
                      src={IMAGES.avatar}
                      alt="n/a"
                      className="modal-assign-person-photo"
                    />
                    <div>
                      {`(${el.position_name}) `}
                      <span>{`${el.name}`}</span>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              <Button
                onPress={() => _setParticipanList()}
                className="margin-top-8"
              >
                <div className="component-modal-request-task-btn-add-person">
                  <img
                    className="is-focus"
                    style={{ height: 50, width: 50 }}
                    src={IMAGES.floatingPlusButton}
                    alt="create"
                    tabIndex={0}
                  />
                  <span className="component-modal-request-task-btn-add-person-text">
                    {taskState.saveRequestParticipanList.length > 0
                      ? t("task.form.changePerson")
                      : t("task.form.addPerson")}
                  </span>
                </div>
              </Button>
            </div>
            <span className="component-modal-request-task-label">
              {t("task.form.activityName")}
            </span>
            <input
              name="title"
              type="text"
              className="component-modal-input margin-top-12"
              placeholder={t("task.form.activityName")}
              ref={register({
                required: String(t("task.form.message.errorActivity"))
              })}
              maxLength={100}
            />
            <ErrorMessage
              errors={errors}
              name="title"
              as="p"
              style={{ color: "red" }}
            />
            {setModal && (
              <>
                <div className="margin-top-20">
                  <span className="component-modal-assign-task-label">
                    {t("task.form.projectName")}
                  </span>
                  <Autocomplete
                    placeHolder={t("task.form.selectProject")}
                    option={populateProject()}
                    style={{ fontWeight: 400 }}
                    onValueChange={e => {
                      setError(false);
                      setSelectedProject(e);
                      _getChildProject(e.value, 0);
                    }}
                  />
                  <div className="task-modal-line" />
                  {error && bodyProject.length === 0 && (
                    <div className="err-text-task">
                      {t("task.form.message.errorProject")}
                    </div>
                  )}
                </div>

                {bodyProject.length > 0 &&
                  bodyProject.map((item: any, index: number) => {
                    if (item?.list?.length > 0) {
                      return (
                        <div
                          key={`data${item.value + index}`}
                          className="margin-top-8"
                        >
                          <Autocomplete
                            placeHolder={t("task.form.selectProject")}
                            option={_convertDataSelect(item.list)}
                            style={{ fontWeight: 400 }}
                            onValueChange={e => {
                              setError(false);
                              setSelectedProject(e);
                              _getChildProject(e.value, index + 1);
                            }}
                          />
                          <div className="task-modal-line" />
                        </div>
                      );
                    }
                    return <></>;
                  })}
              </>
            )}

            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-request-task-label">
                  {t("task.form.finishDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="due_at"
                    ref={register({
                      required: String(t("task.form.message.errorFinishDate"))
                    })}
                    min={watch("started_at") || moment().format("YYYY-MM-DD")}
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="due_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-request-task-label">
                  {t("task.form.startDate")}
                </span>
                <input
                  placeholder="YYYY/MM/DD"
                  type="date"
                  style={{ color: "#666666" }}
                  name="started_at"
                  ref={register({
                    required: String(t("task.form.message.errorStartDate"))
                  })}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  min={moment().format("YYYY-MM-DD")}
                  max={watch("due_at")}
                  onChange={e => {
                    if (getValues("due_at") === "") {
                      setValue(
                        "due_at",
                        moment(e.target.value)
                          .add(1, "d")
                          .format("YYYY-MM-DD")
                      );
                    }
                  }}
                />
                <div className="component-modal-line" />
                <ErrorMessage
                  errors={errors}
                  name="started_at"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="margin-top-20 component-modal-request-task-date-picker">
              <span className="component-modal-request-task-label">
                {t("task.form.description")}
              </span>
              <div className="global-shadow-card component-modal-request-task-card-desc margin-top-12">
                <textarea
                  onInput={autoGrowTextArea}
                  name="description"
                  className="component-modal-request-task-text-area"
                  ref={register({
                    required: String(t("task.form.message.errorDescription"))
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="margin-top-20">
              <div className="component-modal-request-task-label">
                {t("task.form.attachment")}
              </div>
              <div className="request-task-upload">
                {file.length > 0 &&
                  file.map((el: any, i: number) => {
                    const ext = el.name.split(".").pop();
                    return (
                      <FileUpload
                        key={i}
                        name={el.name}
                        style={{
                          marginTop: 6,
                          marginBottom: 6,
                          marginRight: 8
                        }}
                        deleteFile={e => {
                          e.stopPropagation();
                          deleteFile(el.id);
                        }}
                        type={
                          ext === "png" || ext === "jpg" || ext === "jpeg"
                            ? "image"
                            : "text"
                        }
                        linkImage={URL.createObjectURL(el)}
                        onPress={() => openNewTab(URL.createObjectURL(el))}
                      />
                    );
                  })}
                <Upload
                  name="attachment"
                  tabIndex={0}
                  multiple
                  onChange={(e: any) => UploadDocument(e)}
                  textBtn={t("btn.attachmentButton")}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ModalTaskRequestUserPicker
        onChange={e => {
          setSearchUser(e);
        }}
        pickerOnChange={e => {
          setDepartment(e);
        }}
        value={searchUser}
        hasPicker
        pickerValue={department}
      />
      <ModalSubmitForm
        isLoading={taskState.isLoadingPostTaskRequest}
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={handleSubmit(_onSave)}
        onCancel={() => setModalSubmitForm(false)}
      />
    </>
  );
};

export default memo(ModalRequestTask);
