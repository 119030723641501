import { Slide, toast } from "react-toastify";
import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_DETAIL_TASK_PENDING = "GET_DETAIL_TASK_PENDING";
export const GET_DETAIL_TASK_SUCCESS = "GET_DETAIL_TASK_SUCCESS";
export const GET_DETAIL_TASK_ERROR = "GET_DETAIL_TASK_ERROR";

export const UPDATE_DETAIL_TASK_FORM = "UPDATE_DETAIL_TASK_FORM";

export const UPDATE_DETAIL_TASK_REPORT = "UPDATE_DETAIL_TASK_REPORT";

export const UPDATE_PROJECT_ID = "UPDATE_PROJECT_ID";

export const UPDATE_EXTENDS_TIME_REDUCER = "UPDATE_EXTENDS_TIME_REDUCER";

export const UPDATE_DESC_DETAIL_TASK = "UPDATE_DESC_DETAIL_TASK";

export const UPDATE_FILE_COLLECTION = "UPDATE_FILE_COLLECTION";

export const UPDATE_FILE_COLLECTION_REPORT = "UPDATE_FILE_COLLECTION_REPORT";

export const DELETE_FILE_COLLECTION_REDUCER = "DELETE_FILE_COLLECTION_REDUCER";

export const DELETE_FILE_COLLECTION_REPORT_REDUCER =
  "DELETE_FILE_COLLECTION_REPORT_REDUCER";

export const DELETE_FILE_COLLECTION_PENDING = "DELETE_FILE_COLLECTION_PENDING";
export const DELETE_FILE_COLLECTION_SUCCESS = "DELETE_FILE_COLLECTION_SUCCESS";
export const DELETE_FILE_COLLECTION_ERROR = "DELETE_FILE_COLLECTION_ERROR";

export const POST_AVAILABLE_DATE_TASK_REPORT_PENDING =
  "POST_AVAILABLE_DATE_TASK_REPORT_PENDING";
export const POST_AVAILABLE_DATE_TASK_REPORT_SUCCESS =
  "POST_AVAILABLE_DATE_TASK_REPORT_SUCCESS";
export const POST_AVAILABLE_DATE_TASK_REPORT_ERROR =
  "POST_AVAILABLE_DATE_TASK_REPORT_ERROR";

export const DELETE_FILE_COLLECTION_REPORT_PENDING =
  "DELETE_FILE_COLLECTION_REPORT_PENDING";
export const DELETE_FILE_COLLECTION_REPORT_SUCCESS =
  "DELETE_FILE_COLLECTION_REPORT_SUCCESS";
export const DELETE_FILE_COLLECTION_REPORT_ERROR =
  "DELETE_FILE_COLLECTION_REPORT_ERROR";

export const GET_DETAIL_TASK_REPORT_PENDING = "GET_DETAIL_TASK_REPORT_PENDING";
export const GET_DETAIL_TASK_REPORT_SUCCESS = "GET_DETAIL_TASK_REPORT_SUCCESS";
export const GET_DETAIL_TASK_REPORT_ERROR = "GET_DETAIL_TASK_REPORT_ERROR";

export const GET_TASK_LIST_PENDING = "GET_TASK_LIST_PENDING";
export const GET_TASK_LIST_SUCCESS = "GET_TASK_LIST_SUCCESS";
export const GET_TASK_LIST_ERROR = "GET_TASK_LIST_ERROR";

export const GET_AVAILABLE_DATE_TASK_PENDING =
  "GET_AVAILABLE_DATE_TASK_PENDING";
export const GET_AVAILABLE_DATE_TASK_SUCCESS =
  "GET_AVAILABLE_DATE_TASK_SUCCESS";
export const GET_AVAILABLE_DATE_TASK_ERROR = "GET_AVAILABLE_DATE_TASK_ERROR";

export const COMBINE_DESC = "COMBINE_DESC";

export const PUT_TASK_DETAIL_PENDING = "PUT_TASK_DETAIL_PENDING";
export const PUT_TASK_DETAIL_SUCCESS = "PUT_TASK_DETAIL_SUCCESS";
export const PUT_TASK_DETAIL_ERROR = "PUT_TASK_DETAIL_ERROR";

export const PUT_TASK_DETAIL_REPORT_PENDING = "PUT_TASK_DETAIL_REPORT_PENDING";
export const PUT_TASK_DETAIL_REPORT_SUCCESS = "PUT_TASK_DETAIL_REPORT_SUCCESS";
export const PUT_TASK_DETAIL_REPORT_ERROR = "PUT_TASK_DETAIL_REPORT_ERROR";

export const GET_TASK_DETAIL_REMARKS_PENDING =
  "GET_TASK_DETAIL_REMARKS_PENDING";
export const GET_TASK_DETAIL_REMARKS_SUCCESS =
  "GET_TASK_DETAIL_REMARKS_SUCCESS";
export const GET_TASK_DETAIL_REMARKS_ERROR = "GET_TASK_DETAIL_REMARKS_ERROR";

export const POST_EXTENDS_TASK_DAY_PENDING = "POST_EXTENDS_TASK_DAY_PENDING";
export const POST_EXTENDS_TASK_DAY_SUCCESS = "POST_EXTENDS_TASK_DAY_SUCCESS";
export const POST_EXTENDS_TASK_DAY_ERROR = "POST_EXTENDS_TASK_DAY_ERROR";

export const POST_DETAIL_TASK_REMARKS_PENDING =
  "POST_DETAIL_TASK_REMARKS_PENDING";
export const POST_DETAIL_TASK_REMARKS_SUCCESS =
  "POST_DETAIL_TASK_REMARKS_SUCCESS";
export const POST_DETAIL_TASK_REMARKS_ERROR = "POST_DETAIL_TASK_REMARKS_ERROR";

export const POST_TASK_PENDING = "POST_TASK_PENDING";
export const POST_TASK_SUCCESS = "POST_TASK_SUCCESS";
export const POST_TASK_ERROR = "POST_TASK_ERROR";

export const POST_TASK_REQUEST_ERROR = "POST_TASK_REQUEST_ERROR";
export const POST_TASK_REQUEST_PENDING = "POST_TASK_REQUEST_PENDING";
export const POST_TASK_REQUEST_SUCCESS = "POST_TASK_REQUEST_SUCCESS";

export const SET_MODAL_ASSIGN = "SET_MODAL_ASSIGN";
export const GET_ADD_PARTICIPANT = "GET_ADD_PARTICIPANT";
export const SET_ADD_PARTICIPANT = "SET_ADD_PARTICIPANT";
export const SAVE_ADD_PARTICIPANT = "SAVE_ADD_PARTICIPANT";
export const SET_DELETE_PARTICIPANT = "SET_DELETE_PARTICIPANT";
export const SET_MODAL_PARTICIPANT = "SET_MODAL_PARTICIPANT";
export const SET_MODAL_EDIT_PARTICIPANT = "SET_MODAL_EDIT_PARTICIPANT";
export const RESET_PARTICIPANT = "RESET_PARTICIPANT";

export const GET_ADD_REQUEST_PARTICIPANT = "GET_ADD_REQUEST_PARTICIPANT";
export const RESET_REQUEST_PARTICIPANT = "RESET_REQUEST_PARTICIPANT";
export const SAVE_ADD_REQUEST_PARTICIPANT = "SAVE_ADD_REQUEST_PARTICIPANT";
export const SET_ADD_REQUEST_PARTICIPANT = "SET_ADD_REQUEST_PARTICIPANT";
export const SET_MODAL_REQUEST = "SET_MODAL_REQUEST";
export const SET_MODAL_REQUEST_PARTICIPANT = "SET_MODAL_REQUEST_PARTICIPANT";
export const SET_DELETE_REQUEST_PARTICIPANT = "SET_DELETE_REQUEST_PARTICIPANT";

export const POST_END_TASK_PENDING = "POST_END_TASK_PENDING";
export const POST_END_TASK_SUCCESS = "POST_END_TASK_SUCCESS";
export const POST_END_TASK_ERROR = "POST_END_TASK_ERROR";

export const POST_UPLOAD_TASK_DETAIL_FILE_PENDING =
  "POST_UPLOAD_TASK_DETAIL_FILE_PENDING";
export const POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS =
  "POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS";
export const POST_UPLOAD_TASK_DETAIL_FILE_ERROR =
  "POST_UPLOAD_TASK_DETAIL_FILE_ERROR";

export const POST_UPLOAD_TASK_DETAIL_REPORT_FILE_PENDING =
  "POST_UPLOAD_TASK_DETAIL_REPORT_FILE_PENDING";
export const POST_UPLOAD_TASK_DETAIL_REPORT_FILE_SUCCESS =
  "POST_UPLOAD_TASK_DETAIL_REPORT_FILE_SUCCESS";
export const POST_UPLOAD_TASK_DETAIL_REPORT_FILE_ERROR =
  "POST_UPLOAD_TASK_DETAIL_REPORT_FILE_ERROR";

export const PUT_TASK_REPORT_SUBMIT_PENDING = "PUT_TASK_REPORT_SUBMIT_PENDING";
export const PUT_TASK_REPORT_SUBMIT_SUCCESS = "PUT_TASK_REPORT_SUBMIT_SUCCESS";
export const PUT_TASK_REPORT_SUBMIT_ERROR = "PUT_TASK_REPORT_SUBMIT_ERROR";

export const GET_TASK_FORM_PENDING = "GET_TASK_FORM_PENDING";
export const GET_TASK_FORM_SUCCESS = "GET_TASK_FORM_SUCCESS";
export const GET_TASK_FORM_ERROR = "GET_TASK_FORM_ERROR";

export const GET_TASK_PRINT_PENDING = "GET_TASK_PRINT_PENDING";
export const GET_TASK_PRINT_SUCCESS = "GET_TASK_PRINT_SUCCESS";
export const GET_TASK_PRINT_ERROR = "GET_TASK_PRINT_ERROR";

export const GET_TASK_BUBBLE_PENDING = "GET_TASK_BUBBLE_PENDING";
export const GET_TASK_BUBBLE_SUCCESS = "GET_TASK_BUBBLE_SUCCESS";
export const GET_TASK_BUBBLE_ERROR = "GET_TASK_BUBBLE_ERROR";

export const PUT_TASK_MODERATION_PENDING = "PUT_TASK_MODERATION_PENDING";
export const PUT_TASK_MODERATION_SUCCESS = "PUT_TASK_MODERATION_SUCCESS";
export const PUT_TASK_MODERATION_ERROR = "PUT_TASK_MODERATION_ERROR";

export const PUT_TASK_EDIT_PENDING = "PUT_TASK_EDIT_PENDING";
export const PUT_TASK_EDIT_SUCCESS = "PUT_TASK_EDIT_SUCCESS";
export const PUT_TASK_EDIT_ERROR = "PUT_TASK_EDIT_ERROR";

export const POST_ACTIVATE_TASK_PENDING = "POST_ACTIVATE_TASK_PENDING";
export const POST_ACTIVATE_TASK_SUCCESS = "POST_ACTIVATE_TASK_SUCCESS";
export const POST_ACTIVATE_TASK_ERROR = "POST_ACTIVATE_TASK_ERROR";

export const PUT_TASK_ASSIGNED_PENDING = "PUT_TASK_ASSIGNED_PENDING";
export const PUT_TASK_ASSIGNED_SUCCESS = "PUT_TASK_ASSIGNED_SUCCESS";
export const PUT_TASK_ASSIGNED_ERROR = "PUT_TASK_ASSIGNED_ERROR";

export const getDetailTask = (taskId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_DETAIL_TASK_PENDING });

    const res = await API.getDetailTask(taskId);
    dispatch({
      type: GET_DETAIL_TASK_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_DETAIL_TASK_ERROR });
  }
};

export const getTaskForm = (taskId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TASK_FORM_PENDING });

    const res = await API.getTaskForm(taskId);
    dispatch({
      type: GET_TASK_FORM_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TASK_FORM_ERROR });
  }
};

export const updateDetailTaskForm = (data: {
  formName: string;
  value: any;
}) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_DETAIL_TASK_FORM, payload: { data } });
};

export const updateFileCollection = (
  fileName: string,
  file: any,
  taskId: string,
  cbSuccess: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_UPLOAD_TASK_DETAIL_FILE_PENDING });
    const formDataFileCollection = new FormData();
    // disini
    formDataFileCollection.append("file[0]", file);
    const res = await API.postUploadDetailTaskFile(
      taskId,
      formDataFileCollection
    );
    dispatch({
      type: UPDATE_FILE_COLLECTION,
      payload: { data: { fileName, file, res } }
    });
    dispatch({ type: POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS });
    cbSuccess();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    let msg: any = null;
    if (err.response.data.message) {
      Object.entries(err.response.data.message.error_messages).forEach(
        ([, value]) => {
          msg = value;
        }
      );
      toast(msg[0], {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_UPLOAD_TASK_DETAIL_FILE_ERROR });
  }
};

export const updateFileCollectionReport = (file: {
  index: number;
  file: any;
}) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_FILE_COLLECTION_REPORT, payload: { data: file } });
};

export const updateExtendsTimeReducer = () => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_EXTENDS_TIME_REDUCER });
};

export const combineDesc = () => (dispatch: Dispatch) => {
  dispatch({ type: COMBINE_DESC });
};

export const updateDetailTaskReport = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_DETAIL_TASK_REPORT, payload: { data } });
};

export const updateDescDetailTaskReport = (data: {
  index: number;
  description: string;
}) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_DESC_DETAIL_TASK, payload: { data } });
};

export const updateProjectId = (id: string) => (dispatch: Dispatch) => {
  dispatch({ type: UPDATE_PROJECT_ID, payload: { id } });
};

export const deleteFileCollectionReducer = (index: number) => (
  dispatch: Dispatch
) => {
  dispatch({ type: DELETE_FILE_COLLECTION_REDUCER, payload: { data: index } });
};

export const deleteFileCollectionReportReducer = (fileData: {
  indexReport: number;
  indexFile: number;
  type: "inputFile" | "file_collection";
}) => (dispatch: Dispatch) => {
  dispatch({
    type: DELETE_FILE_COLLECTION_REPORT_REDUCER,
    payload: { data: fileData }
  });
};

// disini
export const getDetailTaskReport = (taskId: string, uuid: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_DETAIL_TASK_REPORT_PENDING });
    let dataAvailable: any = [];
    let dataTaskReport: any = [];

    const detailReport = await API.getDetailTaskReport(taskId, uuid);
    const available = await API.getAvailableDateTaskReport(taskId, uuid);
    available.data.data.map(item => {
      dataAvailable = [
        ...dataAvailable,
        {
          id: "",
          date: item,
          file_collection: [],
          description: "",
          available_date: true,
          isEdit: false,
          inputFile: []
        }
      ];
      return true;
    });
    detailReport.data.data.map((item: any) => {
      dataTaskReport = [
        ...dataTaskReport,
        { ...item, available_date: false, isEdit: false, inputFile: [] }
      ];
      return true;
    });
    const data = [...dataAvailable, ...dataTaskReport];
    data.sort(
      (current, next) =>
        Number(new Date(current.date)) - Number(new Date(next.date))
    );
    dispatch({
      type: GET_DETAIL_TASK_REPORT_SUCCESS,
      payload: { data, value: detailReport.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_DETAIL_TASK_REPORT_ERROR });
  }
};

export const getTaskList = (status: string, q: string, page = 1) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_TASK_LIST_PENDING });

    const res = await API.getTaskList(status, q, page);
    dispatch({
      type: GET_TASK_LIST_SUCCESS,
      payload: { data: res.data.data, value: res.data.meta.pagination }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TASK_LIST_ERROR });
  }
};

export const getAvailableDateTask = (taskId: string, uuid: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_AVAILABLE_DATE_TASK_PENDING });

    const res = await API.getAvailableDateTaskReport(taskId, uuid);
    dispatch({
      type: GET_AVAILABLE_DATE_TASK_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_AVAILABLE_DATE_TASK_ERROR });
  }
};

export const getDetailTaskRemarks = (taskId: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_TASK_DETAIL_REMARKS_PENDING });

    const res = await API.getRemarks(taskId);
    dispatch({
      type: GET_TASK_DETAIL_REMARKS_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TASK_DETAIL_REMARKS_ERROR });
  }
};

export const postExtendsTaskDay = (
  taskId: any,
  body: any,
  getData: () => void,
  cb: (e: { success: boolean; message: string }) => void,
  uuid: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_EXTENDS_TASK_DAY_PENDING });

    const res = await API.postTaskExtendDays(taskId, body);
    await getData();
    await dispatch(getDetailTaskReport(String(taskId), uuid));
    dispatch({
      type: POST_EXTENDS_TASK_DAY_SUCCESS
    });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_EXTENDS_TASK_DAY_ERROR });
  }
};

export const putTaskDetail = (
  taskId: any,
  body: any,
  cb?: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_DETAIL_PENDING });

    const res = await API.putTaskDetail(taskId, body);
    dispatch({
      type: PUT_TASK_DETAIL_SUCCESS,
      payload: { data: res.data }
    });
    cb && cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: PUT_TASK_DETAIL_ERROR });
  }
};

export const putTaskAssigned = (
  assignedId: string,
  assignedChangeId: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_ASSIGNED_PENDING });

    const res = await API.putTaskAssigned(assignedId, assignedChangeId);
    dispatch({
      type: PUT_TASK_ASSIGNED_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: PUT_TASK_ASSIGNED_ERROR });
  }
};

export const deleteFileCollection = (taskId: any, fileId: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_FILE_COLLECTION_PENDING });

    await API.deleteFileTaskDetail(taskId, fileId);
    dispatch({
      type: DELETE_FILE_COLLECTION_SUCCESS
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: DELETE_FILE_COLLECTION_ERROR });
  }
};

export const deleteFileReportCollection = (
  taskReportId: any,
  fileId: any
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: DELETE_FILE_COLLECTION_REPORT_PENDING });

    await API.deleteFileTaskDetailReport(taskReportId, fileId);
    dispatch({
      type: DELETE_FILE_COLLECTION_REPORT_SUCCESS
      // payload: { data: { response: res, file, date } },
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: DELETE_FILE_COLLECTION_REPORT_ERROR });
  }
};

export const setModalAssign = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_ASSIGN,
    payload: { data: index }
  });
};

export const putTaskDetailReport = (
  taskId: any,
  reportId: any,
  body: any,
  userId: string,
  submitted: boolean,
  selfTask: boolean,
  endTask?: boolean,
  cbEndtask?: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_DETAIL_REPORT_PENDING });
    let res;
    if (!submitted && selfTask) {
      res = await API.putTaskDetailReport(taskId, reportId, body, userId);
    }
    if (endTask) {
      const endTaskRes = await API.postEndTask(taskId);
      cbEndtask &&
        cbEndtask({
          success: endTaskRes.data.success,
          message: endTaskRes.data.message
        });
      window.location.reload();
    }

    dispatch({
      type: PUT_TASK_DETAIL_REPORT_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    cbEndtask &&
      cbEndtask({
        success: err.response.data.success,
        message: err.response.data.message
      });
    dispatch({ type: PUT_TASK_DETAIL_REPORT_ERROR });
  }
};

export const postTask = (
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_TASK_PENDING });
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("participants", body.participants);
    formData.append("assigned", body.assigned);
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at);
    formData.append("is_team", body.is_team);
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_month", body.recurring_month);
    formData.append("recurring_day", body.recurring_day);
    formData.append("recurring_weekday", body.recurring_weekday);
    formData.append("recurring_time", body.recurring_time);
    formData.append("recurring_history", body.recurring_history);
    formData.append(
      "recurring_ended_at",
      body.recurring_ended_at ? `${body.recurring_ended_at} 23:59` : ""
    );
    // eslint-disable-next-line no-prototype-builtins
    if (body.hasOwnProperty("files")) {
      body.files.map((item: any, index: number) =>
        formData.append(`files[${String(index)}]`, item)
      );
    }
    const res = await API.postTask(formData);
    dispatch({ type: POST_TASK_SUCCESS, payload: { data: res.data.data } });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response && err.response.data.success,
      message: err.response && err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: POST_TASK_ERROR });
  }
};

export const putTaskEdit = (
  taskId: any,
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_EDIT_PENDING });
    const formData = new URLSearchParams();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("assigned_by", body.participants);
    formData.append("assigned", body.assigned);
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at);
    formData.append("is_team", body.is_team);
    formData.append("recurring", body.recurring);
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_month", body.recurring_month);
    formData.append("recurring_day", body.recurring_day);
    formData.append("recurring_weekday", body.recurring_weekday);
    formData.append("recurring_time", body.recurring_time);
    formData.append("recurring_history", body.recurring_history);
    formData.append(
      "recurring_ended_at",
      body.recurring_ended_at ? `${body.recurring_ended_at} 23:59` : ""
    );
    const res = await API.putTaskEdit(taskId, formData);
    dispatch({ type: PUT_TASK_EDIT_SUCCESS, payload: { data: res.data.data } });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response && err.response.data.success,
      message: err.response && err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: PUT_TASK_EDIT_ERROR });
  }
};

export const postActivateTask = (taskId: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_ACTIVATE_TASK_PENDING });

    await API.postTaskActivate(taskId);
    dispatch({
      type: POST_ACTIVATE_TASK_SUCCESS
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_ACTIVATE_TASK_ERROR });
  }
};

export const postEndTask = (
  taskId: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_END_TASK_PENDING });

    const res = await API.postEndTask(taskId);
    dispatch({
      type: POST_END_TASK_SUCCESS
    });
    cb({ success: res.data.success, message: res.data.message });
    window.location.reload();
  } catch (err) {
    cb({
      success: err.response.data.success,
      message: err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_END_TASK_ERROR });
  }
};

export const postDetailTaskRemarks = (
  taskId: any,
  body: { comment: string }
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_DETAIL_TASK_REMARKS_PENDING });

    await API.postDetailTaskRemarks(taskId, body);
    await dispatch(getDetailTaskRemarks(taskId));
    dispatch({
      type: POST_DETAIL_TASK_REMARKS_SUCCESS
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_DETAIL_TASK_REMARKS_ERROR });
  }
};

export const postUploadDetailTaskFile = (taskId: string, file: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_UPLOAD_TASK_DETAIL_FILE_PENDING });

    const res = await API.postUploadDetailTaskFile(taskId, file);
    dispatch({
      type: POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_UPLOAD_TASK_DETAIL_FILE_ERROR });
  }
};

export const postAvailableDateTaskReport = (
  taskId: string,
  body: {
    date: string;
    description: string;
    files: any;
  },
  uuid: string,
  submitted: boolean,
  selfTask: boolean,
  submit?: boolean,
  endTask?: boolean,
  cb?: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_AVAILABLE_DATE_TASK_REPORT_PENDING });
    let res: any;
    if (body.description && !submitted && selfTask) {
      const formData = new FormData();
      formData.append("date", body.date);
      formData.append("description", body.description);
      body.files.map((item: any, index: number) =>
        formData.append(`files[${String(index)}]`, item.file)
      );
      res = await API.postAvailableDateTaskReport(taskId, formData, uuid);
      if (submit) {
        await API.putTaskReportSubmit(taskId, res.data.data.id, body, uuid);
      }
      cb &&
        cb({
          success: res.data.success,
          message: res.data.message
        });
    } else if (!endTask) {
      cb &&
        cb({
          success: false,
          message: "Description cannot be empty"
        });
    }
    if (endTask) {
      const endTaskRes = await API.postEndTask(taskId);
      cb &&
        cb({
          success: endTaskRes.data.success,
          message: endTaskRes.data.message
        });
      window.location.reload();
    }
    dispatch(getDetailTaskReport(String(taskId), uuid));

    dispatch({
      type: POST_AVAILABLE_DATE_TASK_REPORT_SUCCESS,
      payload: res.data
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    let msg: any = null;
    if (err.response.data.message) {
      Object.entries(err.response.data.message.error_messages).forEach(
        ([, value]) => {
          msg = value;
        }
      );
      toast(msg[0], {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_AVAILABLE_DATE_TASK_REPORT_ERROR });
  }
};

export const postUploadDetailTaskReportFile = (
  taskReportId: string,
  file: any,
  taskId: string,
  uuid: string,
  cb: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_UPLOAD_TASK_DETAIL_REPORT_FILE_PENDING });
    // disini
    const formData = new FormData();
    formData.append("file", file);
    const res = await API.postUploadDetailTaskReportFile(
      taskReportId,
      formData
    );
    dispatch({
      type: POST_UPLOAD_TASK_DETAIL_REPORT_FILE_SUCCESS,
      payload: { data: { res: res.data, file } }
    });
    cb();
    dispatch(getDetailTaskReport(taskId, uuid));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    let msg: any = null;
    if (err.response.data.message) {
      Object.entries(err.response.data.message.error_messages).forEach(
        ([, value]) => {
          msg = value;
        }
      );
      toast(msg[0], {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }
    dispatch({
      type: POST_UPLOAD_TASK_DETAIL_REPORT_FILE_ERROR
      // payload: { data: { res: err.response.data, date, file: file.file.name } },
    });
  }
};

export const putTaskReportSubmit = (
  id: string,
  reportId: string,
  body: { date: string; description: string },
  uuid: string,
  cb?: () => void,
  endTask?: boolean,
  cbEndtask?: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_REPORT_SUBMIT_PENDING });

    await API.putTaskReportSubmit(id, reportId, body, uuid);
    if (endTask) {
      const endTaskRes = await API.postEndTask(id);
      cbEndtask &&
        cbEndtask({
          success: endTaskRes.data.success,
          message: endTaskRes.data.message
        });
    }
    cb && cb();
    dispatch({
      type: PUT_TASK_REPORT_SUBMIT_SUCCESS
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    cbEndtask &&
      cbEndtask({
        success: err.response.data.success,
        message: err.response.data.message
      });
    dispatch({ type: PUT_TASK_REPORT_SUBMIT_ERROR });
  }
};

export const setModalEditParticipant = (value: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_EDIT_PARTICIPANT,
    payload: { data: value }
  });
};

export const setModalParticipant = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_PARTICIPANT,
    payload: { data: index }
  });
};

export const getParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ADD_PARTICIPANT
  });
};

export const setAddParticipants = (data: any, cb?: () => void) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ADD_PARTICIPANT,
    payload: { data }
  });
  cb && cb();
};

export const saveAddParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: SAVE_ADD_PARTICIPANT
  });
};

export const resetParticipants = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_PARTICIPANT
  });
};

export const setDeleteParticipants = (id: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DELETE_PARTICIPANT,
    payload: { data: id }
  });
};

export const setModalRequest = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_REQUEST,
    payload: { data: index }
  });
};

export const setModalRequestParticipant = (index: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_REQUEST_PARTICIPANT,
    payload: { data: index }
  });
};

export const getRequestParticipant = () => (dispatch: Dispatch) => {
  dispatch({
    type: GET_ADD_REQUEST_PARTICIPANT
  });
};

export const setAddRequestParticipant = (data: any, cb?: () => void) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ADD_REQUEST_PARTICIPANT,
    payload: { data }
  });
  cb && cb();
};

export const saveAddRequestParticipant = () => (dispatch: Dispatch) => {
  dispatch({
    type: SAVE_ADD_REQUEST_PARTICIPANT
  });
};

export const resetRequestParticipant = () => (dispatch: Dispatch) => {
  dispatch({
    type: RESET_REQUEST_PARTICIPANT
  });
};

export const setDeleteRequestParticipant = (id: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_DELETE_REQUEST_PARTICIPANT,
    payload: { data: id }
  });
};

export const getTaskPrint = (taskId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TASK_PRINT_PENDING });

    const res = await API.getTaskPrint(taskId);
    dispatch({
      type: GET_TASK_PRINT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TASK_PRINT_ERROR });
  }
};

export const postTaskRequest = (
  body: any,
  cb: (e: { success: boolean; message: string }) => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_TASK_REQUEST_PENDING });
    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("project_id", body.project_id);
    formData.append("due_at", body.due_at);
    formData.append("started_at", body.started_at);
    formData.append("assigned_by", body.participants);
    formData.append("assigned", body.assigned); // 0
    formData.append("client_timezone", body.client_timezone);
    formData.append("finished_at", body.finished_at); // null
    formData.append("repeat_at", body.repeat_at); // false
    formData.append("recurring", body.recurring); // false
    formData.append("recurring_period", body.recurring_period || 0);
    formData.append("recurring_time", body.recurring_time); // null
    body.files.map((item: any, index: number) =>
      formData.append(`files[${String(index)}]`, item)
    );
    const res = await API.postTaskRequest(formData);
    dispatch({
      type: POST_TASK_REQUEST_SUCCESS,
      payload: { data: res.data.data }
    });
    cb({ success: res.data.success, message: res.data.message });
  } catch (err) {
    cb({
      success: err.response && err.response.data.success,
      message: err.response && err.response.data.message
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: POST_TASK_REQUEST_ERROR });
  }
};

export const getTaskBubbleNotif = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TASK_BUBBLE_PENDING });

    const res = await API.getTaskBubbleNotif();
    dispatch({
      type: GET_TASK_BUBBLE_SUCCESS,
      payload: { data: res.data.data[0] }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TASK_BUBBLE_ERROR });
  }
};

export const putTaskModeration = (
  moderationId: any,
  body: any,
  cb?: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: PUT_TASK_MODERATION_PENDING });

    const res = await API.putTaskModeration(moderationId, body);
    dispatch({
      type: PUT_TASK_MODERATION_SUCCESS,
      payload: { data: res.data }
    });
    cb && cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: PUT_TASK_MODERATION_ERROR });
  }
};
