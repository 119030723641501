import React, { memo } from "react";

import "./styles.scss";
import { Button, Text } from "../..";
import { ArrowRightIcon } from "../../../assets";

interface Props {
  item: string;
  active: boolean;
  bold: boolean;
}

const BreadCrumb = ({ item, bold }: Props) => {
  return (
    <>
      <ArrowRightIcon />
      <div className="top-bar-bread-crumb-wrap-item-title ">
        <Button>
          <Text
            className={`top-bar-bread-crumb-title 
            ${bold ? "top-bar-bread-crumb-title-bold" : ""}`}
          >
            {item.replace(/-/g, " ")}
          </Text>
        </Button>
      </div>
    </>
  );
};

export default memo(BreadCrumb);
