import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import {
  getList,
  getTemplate,
  is_hidden
} from "../../../../../redux/actions/master_data";

const TemplateButton = () => {
  const { t } = useTranslation();
  const [expenseType, setExpenseType] = useState(false);
  const dispatch = useDispatch();
  const download_file = () => {
    dispatch(getTemplate());
  };

  const changeExpenseType = () => {
    if (expenseType) {
      dispatch(is_hidden(false));
      dispatch(getList("expenseType", 1, false));
      setExpenseType(false);
    } else {
      dispatch(is_hidden(true));
      dispatch(getList("expenseType", 1, true));
      setExpenseType(true);
    }
  };

  return (
    <>
      <Button
        {...{
          onClick: () => download_file(),
          type: "button",
          text: "Template",
          style: {
            marginTop: 35,
            marginBottom: 50,
            marginLeft: 5,
            background: "linear-gradient(#faaf40, #f05a28, #db3a26)",
            width: "6em",
            height: "2.8em",
            padding: 5,
            color: "white",
            fotWeight: "bold",
            borderRadius: 3,
            cursor: "pointer"
          }
        }}
      />

      <div
        style={{
          display: "inline-block",
          position: "relative",
          top: 19,
          marginLeft: 5
        }}
      >
        <div style={{ display: "flex", marginBottom: -10, padding: 5 }}>
          <Switch
            onChange={changeExpenseType}
            checked={expenseType}
            onColor="#ff7600"
            offColor="#b5b5b5"
            onHandleColor="#fffff7"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            height={27}
            width={55}
            tabIndex={0}
          />
          <p style={{ marginLeft: 15, marginTop: 0 }}>
            {`${t("master_data.show")} / ${t("master_data.hidden")} `}
          </p>
        </div>
      </div>
    </>
  );
};

export default TemplateButton;
