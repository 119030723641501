import React, { memo } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { Button, Text } from "../../../../../components";
import { ArrowForSelectIcon } from "../../../../../assets";

// action && types redux
import {
  getReportTable,
  handleLoadingFilterReport,
  setActiveRangeFilterWidgetReport,
  setFormFilterReport
} from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";

const listRanges = [
  {
    label: "All period",
    value: 0
  },
  {
    label: "Last 6 months",
    value: 6
  },
  {
    label: "Last 3 months",
    value: 3
  },
  {
    label: "Last year",
    value: 12
  }
];

let run: any;

const Filter = () => {
  const dispatch = useDispatch();
  const reportState = useSelector((state: Reducers) => state.report);

  const _getLastDate = (date: string) =>
    Number(
      moment(date)
        .endOf("month")
        .format("DD")
    );

  const _getData = () => {
    dispatch(handleLoadingFilterReport());
    clearTimeout(run);
    run = setTimeout(() => {
      dispatch(getReportTable(1));
    }, 2000);
  };

  const _setFormFilter = (form: any) => {
    dispatch(setFormFilterReport(form));
    _getData();
  };

  const _filterFromPeriod = (value: number, index: number) => {
    dispatch(setActiveRangeFilterWidgetReport(index));
    let form = {
      from: {
        months: moment().month() + 1,
        years: moment().year()
      },
      to: {
        months: moment().month() + 1,
        years: moment().year()
      }
    };
    if (value > 0) {
      const date = moment().subtract(value, "months");
      form = {
        ...form,
        from: {
          months: Number(date.format("MM")),
          years: Number(date.format("YYYY"))
        }
      };
    }
    _setFormFilter(form);
  };

  return (
    <>
      <div className="report-component-filter-filter-wrap-date">
        <div className="report-component-filter-wrap-filter-shape">
          <div className="report-component-filter-filter-item-date report-component-filter-filter-item-date-background">
            <div className="report-component-filter-filter-wrap-select report-component-filter-filter-wrap-select-left">
              <select
                className="report-component-filter-filter-select"
                onChange={e =>
                  _setFormFilter({
                    ...reportState.form,
                    from: {
                      ...reportState.form.from,
                      months: Number(e.target.value)
                    }
                  })
                }
                value={reportState.form.from.months}
              >
                {moment.months().map((item, index) => (
                  <option
                    value={index + 1}
                    key={index}
                    className="report-component-filter-filter-select-options"
                  >
                    {item}
                  </option>
                ))}
              </select>
              <ArrowForSelectIcon />
            </div>

            <div className="report-component-filter-filter-wrap-select">
              <select
                className="report-component-filter-filter-select"
                onChange={e =>
                  _setFormFilter({
                    ...reportState.form,
                    from: {
                      ...reportState.form.from,
                      years: Number(e.target.value)
                    },
                    to: {
                      ...reportState.form.to,
                      years:
                        Number(e.target.value) > reportState.form.to.years
                          ? moment().year()
                          : reportState.form.to.years
                    }
                  })
                }
                value={reportState.form.from.years}
              >
                {[...Array(20).keys()].map((item, index) => (
                  <option
                    value={moment().year() - index}
                    key={index}
                    className="report-component-filter-filter-select-options"
                  >
                    {moment().year() - index}
                  </option>
                ))}
              </select>
              <ArrowForSelectIcon />
            </div>
          </div>
          <div className="report-component-filter-filter-shape" />
        </div>

        <div className="report-component-filter-filter-item-date report-component-filter-filter-item-date-right">
          <div className="report-component-filter-filter-wrap-select report-component-filter-filter-wrap-select-left">
            <select
              className="report-component-filter-filter-select"
              onChange={e =>
                _setFormFilter({
                  ...reportState.form,
                  to: {
                    ...reportState.form.to,
                    months: Number(e.target.value),
                    date: _getLastDate(
                      `${reportState.form.to.years}-${Number(e.target.value)}`
                    )
                  }
                })
              }
              value={reportState.form.to.months}
            >
              {moment.months().map((item, index) => (
                <option
                  value={index + 1}
                  key={index}
                  className="report-component-filter-filter-select-options"
                >
                  {item}
                </option>
              ))}
            </select>
            <ArrowForSelectIcon />
          </div>

          <div className="report-component-filter-filter-wrap-select">
            <select
              className="report-component-filter-filter-select"
              onChange={e =>
                _setFormFilter({
                  ...reportState.form,
                  to: { ...reportState.form.to, years: Number(e.target.value) }
                })
              }
              value={reportState.form.to.years}
            >
              {[
                ...Array(
                  moment().year() - reportState.form.from.years + 1
                ).keys()
              ].map((item, index) => (
                <option
                  value={moment().year() - index}
                  key={index}
                  className="report-component-filter-filter-select-options"
                >
                  {moment().year() - index}
                </option>
              ))}
            </select>
            <ArrowForSelectIcon />
          </div>
        </div>
      </div>

      <div className="report-component-filter-filter-wrap-range">
        {listRanges.map((item, index) => (
          <Button
            key={index}
            onPress={() => _filterFromPeriod(item.value, index)}
            className={`report-component-filter-filter-item-range ${index ===
              reportState.activeRange &&
              "report-component-filter-filter-item-range-selected"}`}
          >
            <Text
              className={`report-component-filter-filter-text-item ${index ===
                reportState.activeRange &&
                "report-component-filter-filter-text-item-selected"}`}
            >
              {item.label}
            </Text>
          </Button>
        ))}
      </div>
    </>
  );
};

export default memo(Filter);
