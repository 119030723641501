import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_MENU_SUCCESS = "GET_MENU_SUCCESS";
export const GET_MENU_PENDING = "GET_MENU_PENDING";
export const GET_MENU_ERROR = "GET_MENU_ERROR";

export const GET_MENU_REPORT_SUCCESS = "GET_MENU_REPORT_SUCCESS";
export const GET_MENU_REPORT_PENDING = "GET_MENU_REPORT_PENDING";
export const GET_MENU_REPORT_ERROR = "GET_MENU_REPORT_ERROR";

export const getMenu = (
  mod: "dashboard" | "general" | "report",
  lang?: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_MENU_PENDING });
    const res = await API.getMenu(mod, lang);
    dispatch({
      type: GET_MENU_SUCCESS,
      payload: { data: res.data.data, collapsible: res.data.collapsible }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_MENU_ERROR });
  }
};
export const getMenuReport = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_MENU_REPORT_PENDING });

    const res = await API.getMenuReport();
    dispatch({
      type: GET_MENU_REPORT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    dispatch({ type: GET_MENU_REPORT_ERROR });
  }
};
