import React, { memo } from "react";
import { FaCheck, FaFile, FaHistory, FaSync, FaUpload } from "react-icons/fa";
import BubbleNotif from "../BubbleNotif";

import "./styles.scss";

const _handleRenderIcon = (icon: string, acesssible: any) => {
  if (icon === "upcoming") {
    return (
      <FaUpload
        style={{ height: 26, width: 26, marginBottom: 4 }}
        className={!acesssible ? "accessible" : ""}
      />
    );
  }
  if (icon === "ongoing") {
    return (
      <FaSync
        style={{ height: 26, width: 26, marginBottom: 4 }}
        className={!acesssible ? "accessible" : ""}
      />
    );
  }
  if (icon === "history") {
    return (
      <FaHistory
        style={{ height: 26, width: 26, marginBottom: 4 }}
        className={!acesssible ? "accessible" : ""}
      />
    );
  }
  if (icon === "request") {
    return (
      <FaFile
        style={{ height: 26, width: 26, marginBottom: 4 }}
        className={!acesssible ? "accessible" : ""}
      />
    );
  }
  if (icon === "approval") {
    return (
      <FaCheck
        style={{ height: 26, width: 26, marginBottom: 4 }}
        className={!acesssible ? "accessible" : ""}
      />
    );
  }
  return (
    <img
      src={icon}
      style={{ height: 26, width: 26, marginBottom: 4 }}
      className={!acesssible ? "accessible" : ""}
      alt="n/a"
    />
  );
};

const Tab = ({
  items,
  pathname,
  capsule,
  fontSize
}: {
  items: any;
  pathname: any;
  capsule?: any;
  fontSize?: "medium" | "large" | "normal";
}) => {
  return (
    <div className="component-tab tasklist-width">
      <div
        className={`items ${
          // eslint-disable-next-line no-nested-ternary
          fontSize === "medium"
            ? "font-medium"
            : fontSize === "normal"
            ? "font-normal"
            : "font-large"
        }`}
      >
        {items.map((item: any, index: number) => (
          <div
            key={index}
            className={pathname === item.pathname ? "active" : ""}
            onClick={item.accessible && item.onClick}
            title={!item.accessible ? "Your cannot access this page" : ""}
          >
            {item.img && _handleRenderIcon(item.img, item.accessible)}
            {item.label && <span>{item.label}</span>}
            {item.subLabel && (
              <span
                style={item.small && { fontSize: 12 }}
                className={!item.accessible ? "accessible" : ""}
              >
                {item.subLabel}
              </span>
            )}
            {item.bubleData && item.bubleData.total > 0 && (
              <BubbleNotif
                value={item.bubleData && item.bubleData.total}
                message={item.bubleData && item.bubleData.message}
              />
            )}
          </div>
        ))}
      </div>

      {capsule && (
        <div
          className="capsule"
          style={capsule.color && { background: capsule.color }}
        >
          <span style={{ fontSize: 16, fontWeight: "bold" }}>
            {capsule.type}
          </span>
          <div>
            <div>{capsule.label}</div>
            <div>{capsule.subLabel}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(Tab);
