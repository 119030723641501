import React from "react";
import { Button } from "../../../../../../components";

interface Props {
  desc: string;
  example: string;
  onPressBtn?: () => void;
}

const InputComponent = ({ desc, example, onPressBtn }: Props) => {
  return (
    <div className="setting-list-container-numbering-format-input">
      <Button
        className="setting-list-btn-add-array numbering"
        onPress={onPressBtn}
      >
        <p className="setting-list-btn-add-array-icon">+</p>
      </Button>
      <div
        style={{
          width: "80%"
        }}
      >
        <span>
          {desc}
          <span style={{ fontWeight: "bold" }}>{` ${example}`}</span>
        </span>
      </div>
    </div>
  );
};

export default InputComponent;
