/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable no-nested-ternary */
import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Slide, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorMessage, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";

import "./styles.scss";
import Modal from "../Modal";
import { Autocomplete, Button, FileUpload, ModalUserPicker, Upload } from "..";
import { API, IMAGES } from "../../configs";
import {
  getDepartment,
  getDetailTask,
  getEmployee,
  getParticipants,
  getProjectV2,
  getTaskList,
  postTask,
  putTaskEdit,
  resetParticipants,
  setModalAssign,
  setModalParticipant
} from "../../redux/actions";
import { Reducers } from "../../redux/types";
import {
  Permission,
  autoGrowTextArea,
  copyWritings,
  getPolicies,
  openNewTab
} from "../../utils";
import ModalSubmitForm from "../ModalSubmitForm";
import { usePrevious } from "../../utils/prevDataHook";

interface Props {
  taskUuid?: string;
}

const ModalAssignTask = ({ taskUuid }: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    getValues
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const checkPermission = Permission("task-others-list");

  const { sprintf } = require("sprintf-js");

  const { project, setModal, assignLoading, taskState } = useSelector(
    (state: Reducers) => ({
      project: state.generate.dataProject,
      setModal: state.task.setModalAsign,
      assignLoading: state.task.isLoadingPostTask,
      taskState: state.task
    }),
    shallowEqual
  );

  const [bodyProject, setBodyProject] = useState([]) as any[];

  const _getParentProject = useCallback(async () => {
    const childId = taskState.taskDetail?.project?.id;

    if (childId && setModal) {
      await API.getParentProject(childId).then(async res => {
        const arrParent: any[] = res.data.data;
        if (arrParent.length > 0) {
          arrParent.reverse();
          const data = [...arrParent, childId];
          const newBodyP: any[] = [];

          for (let index = 0; index < data.length; index += 1) {
            /* eslint-disable no-await-in-loop */
            const response = await API.getProjectV2(data[index], false);
            const dataPj: any[] = response.data.data;
            const result = { value: data[index], list: dataPj };
            newBodyP.push(result);
          }
          setBodyProject(newBodyP);
        } else {
          setBodyProject([{ value: childId, list: [] }]);
        }
      });
    }
  }, [setBodyProject, setModal, taskState.taskDetail]);

  useEffect(() => {
    if (taskUuid) {
      _getParentProject();
      setValue("title", taskState.taskDetail && taskState.taskDetail.title);
      setValue(
        "project_id",
        taskState.taskDetail && taskState.taskDetail.project.id
      );
      setValue(
        "started_at",
        moment(taskState.taskDetail && taskState.taskDetail.started_at).format(
          "YYYY-MM-DD"
        )
      );
      setValue(
        "due_at",
        moment(taskState.taskDetail && taskState.taskDetail.due_at).format(
          "YYYY-MM-DD"
        )
      );
      setValue(
        "description",
        taskState.taskDetail && taskState.taskDetail.description
      );
    }
  }, [taskUuid, taskState.taskDetail, setValue, taskState, _getParentProject]);

  const policy = {
    min: getPolicies("task-min-date"),
    max: getPolicies("task-max-date"),
    minAssign: getPolicies("task-assign-min-date"),
    maxAssign: getPolicies("task-assign-max-date")
  };
  const permission = {
    assign: Permission("task-assign"),
    create: Permission("task-create")
  };

  const policyDate = {
    minDate: moment()
      .add(policy.min, "d")
      .format("YYYY-MM-DD"),
    maxDate: moment()
      .add(policy.max, "d")
      .format("YYYY-MM-DD"),
    minAssignDate: moment()
      .add(policy.minAssign, "d")
      .format("YYYY-MM-DD"),
    maxAssignDate: moment()
      .add(policy.maxAssign, "d")
      .format("YYYY-MM-DD")
  };

  const handleMessage = useCallback(() => {
    if (!taskUuid) {
      if (
        policyDate.minAssignDate > getValues("started_at") &&
        taskState.saveParticipanList.length > 0
      ) {
        return "min-assign";
      }
      if (
        policyDate.maxAssignDate < getValues("due_at") &&
        taskState.saveParticipanList.length > 0
      ) {
        return "max-assign";
      }
      if (
        policyDate.minDate > getValues("started_at") &&
        taskState.saveParticipanList.length === 0
      ) {
        return "min";
      }
      if (
        policyDate.maxDate < getValues("due_at") &&
        taskState.saveParticipanList.length === 0
      ) {
        return "max";
      }
      return false;
    }
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.saveParticipanList, getValues("due_at")]);

  const populateProject = () => {
    let result = [] as any;
    project.map((e: any) => {
      result = [...result, { value: e.id, label: e?.attributes?.title }];
      return true;
    });
    return result;
  };

  const initialBody = {
    recurring: false,
    recurring_period: "",
    recurring_month: "",
    recurring_day: "",
    recurring_weekday: "",
    recurring_time: "",
    recurring_history: null,
    is_team: false
  };

  const initialSubmitForm = {
    form: null,
    message: ""
  };

  const [dataPeriode] = useState([
    { value: "", label: t("task.form.none") },
    { value: "4", label: t("task.form.yearly") },
    { value: "3", label: t("task.form.monthly") },
    { value: "2", label: t("task.form.weekly") },
    { value: "1", label: t("task.form.daily") }
  ]);
  const [body, setBody] = useState(initialBody);
  const [month, setMonth]: any = useState([]);
  const [day, setDay]: any = useState([]);
  const [weekDay, setWeekDay]: any = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [department, setDepartment] = useState("");
  const previousDepartment = usePrevious(department);
  const [file, setFile]: any = useState([]);
  const [endPeriod, setEndPeriod] = useState(true);
  const [modalEndPeriod, setModalEndPeriod] = useState(false);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [modalSubmit, setModalSubmit] = useState(false);
  const [directSave, setDirectSave] = useState(false);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);
  const [dataEndPeriodDate, setDataEndPeriodDate] = useState("");
  const [error, setError] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null) as any;

  const generateMonth = () => {
    const months = moment.months();
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Month" }];
    months.map(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
      return true;
    });
    setMonth((arr: any) => [...arr, ...result]);
  };

  const generateDay = () => {
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Date" }];
    while (index <= 28) {
      result = [...result, { value: index, label: index }];
      index += 1;
    }
    setDay((arr: any) => [...arr, ...result]);
  };

  const generateWeekDay = () => {
    const weeks = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];
    let index = 1;
    let result: any[] = [{ value: "", label: "Select Weekday" }];
    weeks.forEach(el => {
      result = [...result, { value: index, label: el }];
      index += 1;
    });
    setWeekDay((arr: any) => [...arr, ...result]);
  };

  useEffect(() => {
    dispatch(getProjectV2(true));
    if (!taskUuid) {
      dispatch(
        getEmployee("", "", "", 1, false, "", true, false, false, false, true)
      );
    } else {
      dispatch(
        getEmployee("", "", "", 1, false, "", true, true, false, false, true)
      );
    }
    generateDay();
    generateMonth();
    generateWeekDay();
  }, [dispatch, taskUuid]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        dispatch(
          getEmployee(
            department || "",
            "",
            "",
            1,
            false,
            searchUser || "",
            false,
            false,
            false,
            false,
            true
          )
        );
      },
      department !== previousDepartment ? 0 : 1500
    );

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, dispatch, searchUser]);

  useEffect(() => {
    setSearchUser("");
    if (body.recurring_period && body.recurring_period !== "0") {
      setBody({ ...body, recurring: true });
    } else if (body.recurring_period === "") {
      setBody({ ...body, recurring: false });
    } else {
      setBody({ ...body, recurring: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.setModalParticipant, body.recurring_period]);

  useEffect(() => {
    if (taskState?.saveParticipanList?.length > 0) {
      setDirectSave(true);
    } else {
      setDirectSave(false);
    }
  }, [taskState, taskState.saveParticipanList]);

  useEffect(() => {
    if (taskState.taskDetail) {
      setBody({
        ...body,
        recurring: taskState.taskDetail.recurring,
        recurring_period:
          taskState.taskDetail.recurring_period === null
            ? ""
            : String(taskState.taskDetail.recurring_period),
        recurring_time: taskState.taskDetail.recurring_time,
        recurring_weekday:
          taskState.taskDetail.recurring_weekday === null
            ? ""
            : String(taskState.taskDetail.recurring_weekday),
        recurring_day:
          taskState.taskDetail.recurring_day === null
            ? ""
            : String(taskState.taskDetail.recurring_day),
        recurring_month:
          taskState.taskDetail.recurring_month === null
            ? ""
            : String(taskState.taskDetail.recurring_month)
      });

      if (taskState.taskDetail.recurring) {
        setDataEndPeriodDate(
          moment(
            taskState.taskDetail && taskState.taskDetail.recurring_ended_at
          ).format("YYYY-MM-DD")
        );

        setEndPeriod(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskState.taskDetail]);

  const _setParticipanList = useCallback(() => {
    setDepartment("");
    dispatch(setModalParticipant(true));
    dispatch(getParticipants());
    dispatch(getDepartment());
  }, [dispatch]);

  const UploadDocument = async (e: any) => {
    const { files } = e.target;
    Array.from(files).map((item: any) => {
      item.id = Math.random()
        .toString(36)
        .substring(7);
      const result = file.findIndex((ed: any) => ed.name === item.name);
      if (result < 0) {
        setFile((currentArray: any) => [...currentArray, item]);
      } else {
        toast(t("task.form.message.duplicateAttachment"), {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      }
      return true;
    });

    e.target.value = null;
  };

  const deleteFile = (id: any) => {
    const newFile = file.filter((el: any) => el.id !== id);
    setFile(newFile);
  };

  const _getIdParticipant = () => {
    const result = [] as any;
    if (taskState.saveParticipanList.length > 0) {
      taskState.saveParticipanList.map(el => result.push(el.id));
    }
    return result.join(",");
  };

  const _openModalSubmit = () => {
    setModalSubmit(true);
  };

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body,
      files: file,
      participants: _getIdParticipant()
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let files: any = [];
    if (file.length > 0) {
      file.map((e: any) => {
        files = [...files, e.name];
        return true;
      });
    }

    const projectName = selectedProject?.label || "";

    let participants: any = [];
    taskState.saveParticipanList.map(e => {
      participants = [...participants, e.name];
      return true;
    });

    const result: any = [
      { title: t("task.form.activityName"), value: tempData[0].value || "-" },
      { title: t("task.form.projectName"), value: projectName || "-" },
      {
        title: t("task.form.participants"),
        value: participants.length > 0 ? participants : "-"
      },
      {
        title: "Type",
        value: body.is_team ? "Team" : "Individual"
      },
      {
        title: t("task.form.startDate"),
        value: moment(tempData[2].value).format("DD MMM YYYY") || "-"
      },
      {
        title: t("task.form.finishDate"),
        value: moment(tempData[1].value).format("DD MMM YYYY") || "-"
      },
      { title: t("task.form.description"), value: tempData[3].value || "-" },
      {
        title: t("task.form.attachment"),
        value: files.length > 0 ? files : "-"
      },
      {
        title: t("task.form.repeatTask"),
        value: body.recurring ? "Yes" : "No"
      },
      {
        title: t("task.form.repeatPeriod"),
        value:
          body.recurring_period === "1"
            ? t("task.form.daily")
            : body.recurring_period === "2"
            ? t("task.form.weekly")
            : body.recurring_period === "3"
            ? t("task.form.monthly")
            : !body.recurring
            ? "-"
            : t("task.form.yearly")
      },
      {
        title: t("task.form.recurringMonth"),
        value:
          body.recurring_period === "4"
            ? moment()
                .month(Number(body.recurring_month) - 1)
                .format("MMMM")
            : "-"
      },
      {
        title: t("task.form.recurringWeek"),
        value:
          body.recurring_period === "2"
            ? moment()
                .day(body.recurring_weekday)
                .format("dddd")
            : "-"
      },
      {
        title: t("task.form.recurringDate"),
        value: ["3", "4"].includes(body.recurring_period)
          ? body.recurring_day
          : "-"
      },
      {
        title: t("task.form.recurringTime"),
        value: body.recurring ? body.recurring_time : "-"
      },
      {
        title: t("task.form.endPeriod"),
        value:
          body.recurring && endPeriod && tempData[4]
            ? moment(tempData[4].value).format("DD MMM YYYY")
            : "-"
      }
    ];

    if (result && bodyProject.length > 0) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    } else {
      setError(true);
    }
  };

  const _onSave = (data: any) => {
    const projecId =
      bodyProject.length > 0 &&
      bodyProject.find((_, index) => index === bodyProject.length - 1)?.value;

    const form = {
      ...data,
      ...body,
      project_id: projecId,
      files: file,
      participants: _getIdParticipant(),
      assigned: 1,
      client_timezone: "Asia/Jakarta",
      finished_at: ""
    };

    let message = "";
    let success = false;
    if (
      form.recurring &&
      form.recurring_period === "1" &&
      !form.recurring_time
    ) {
      message = t("task.form.message.recurringTimeEmpty");
    } else if (
      form.recurring &&
      form.recurring_period === "2" &&
      !form.recurring_weekday
    ) {
      message = t("task.form.message.recurringWeekdayEmpty");
    } else if (
      form.recurring &&
      ["3", "4"].includes(form.recurring_period) &&
      !form.recurring_day
    ) {
      message = t("task.form.message.recurringDayEmpty");
    } else if (
      form.recurring &&
      form.recurring_period === "4" &&
      !form.recurring_month
    ) {
      message = t("task.form.message.recurringMonthEmpty");
    } else if (
      form.recurring &&
      ["1", "2", "3", "4"].includes(form.recurring_period) &&
      !form.recurring_time
    ) {
      message = t("task.form.message.recurringTimeEmpty");
    } else {
      message = "Data Stored";
      success = true;
    }

    if (success && form.recurring_ended_at) {
      setModalEndPeriod(true);
      setSubmitForm({ ...submitForm, form, message });
    } else if (success && message) {
      dispatch(
        postTask(form, (e: { success: boolean; message: string }) => {
          if (e.success === true) {
            setBodyProject([]);
            setModalSubmit(false);
            setModalSubmitForm(false);
            dispatch(setModalAssign(false));
            dispatch(resetParticipants());
            toast(`${e.message.toUpperCase()}`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-task-toast",
              closeButton: false,
              draggable: false
            });
            if (checkPermission) {
              history.push("assigned-task");
              dispatch(getTaskList("assigned", ""));
            } else {
              history.push("upcoming");
              dispatch(getTaskList("upcoming", ""));
            }
            setBody(initialBody);
            setFile([]);
            setEndPeriod(false);
            setModalSubmitForm(false);
            reset();
          } else if (e.success === false) {
            toast(`${e.message}`, {
              position: "top-center",
              autoClose: 3500,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-task-toast assign-red",
              closeButton: false,
              draggable: false
            });
          }
        })
      );
    } else {
      toast(message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }
  };

  const _onEdit = (data: any) => {
    const projecId =
      bodyProject.length > 0 &&
      bodyProject.find((_, index) => index === bodyProject.length - 1)?.value;

    const form = {
      ...data,
      ...body,
      project_id: projecId,
      participants: _getIdParticipant(),
      assigned: 0,
      client_timezone: "Asia/Jakarta",
      finished_at: ""
    };

    let message = "";
    let success = false;
    if (
      form.recurring &&
      form.recurring_period === "1" &&
      !form.recurring_time
    ) {
      message = t("task.form.message.recurringTimeEmpty");
    } else if (
      form.recurring &&
      form.recurring_period === "2" &&
      !form.recurring_weekday
    ) {
      message = t("task.form.message.recurringWeekdayEmpty");
    } else if (
      form.recurring &&
      ["3", "4"].includes(form.recurring_period) &&
      !form.recurring_day
    ) {
      message = t("task.form.message.recurringDayEmpty");
    } else if (
      form.recurring &&
      form.recurring_period === "4" &&
      !form.recurring_month
    ) {
      message = t("task.form.message.recurringMonthEmpty");
    } else if (
      form.recurring &&
      ["1", "2", "3", "4"].includes(form.recurring_period) &&
      !form.recurring_time
    ) {
      message = t("task.form.message.recurringTimeEmpty");
    } else {
      message = t("task.form.message.dataStored");
      success = true;
    }

    if (success && form.recurring_ended_at) {
      setModalEndPeriod(true);
      setSubmitForm({ ...submitForm, form, message });
    } else if (success && message) {
      dispatch(
        putTaskEdit(
          taskUuid,
          form,
          (e: { success: boolean; message: string }) => {
            if (e.success === true) {
              setBodyProject([]);
              setModalSubmit(false);
              dispatch(setModalAssign(false));
              toast(`${e.message.toUpperCase()}`, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-task-toast",
                closeButton: false,
                draggable: false
              });
              setBody(initialBody);
              setEndPeriod(false);
              reset();
              dispatch(getDetailTask(String(taskUuid)));
            } else if (e.success === false) {
              toast(`${e.message}`, {
                position: "top-center",
                autoClose: 3500,
                hideProgressBar: true,
                transition: Slide,
                className: "assign-task-toast assign-red",
                closeButton: false,
                draggable: false
              });
            }
          }
        )
      );
    } else {
      toast(message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }
  };

  const _onSubmit = () => {
    dispatch(
      postTask(submitForm.form, (e: { success: boolean; message: string }) => {
        if (e.success === true) {
          dispatch(resetParticipants());
          setBodyProject([]);
          dispatch(setModalAssign(false));
          setModalSubmit(false);
          toast(`${e.message.toUpperCase()}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-task-toast",
            closeButton: false,
            draggable: false
          });
          if (checkPermission) {
            history.push("assigned-task");
            dispatch(getTaskList("assigned", ""));
          } else {
            history.push("upcoming");
            dispatch(getTaskList("upcoming", ""));
          }

          setBody(initialBody);
          setFile([]);
          setEndPeriod(false);
          reset();
          setModalEndPeriod(false);
          setModalSubmitForm(false);
        } else if (e.success === false) {
          toast(`${e.message}`, {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-task-toast assign-red",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _onCancel = () => {
    setBodyProject([]);
    if (!taskUuid) {
      reset();
      dispatch(setModalAssign(false));
      dispatch(resetParticipants());
      setFile([]);
      setBody(initialBody);
      setEndPeriod(true);
    } else if (taskState.taskDetail && taskUuid) {
      dispatch(setModalAssign(false));
      setBody({
        ...body,
        recurring: taskState.taskDetail.recurring,
        recurring_period:
          taskState.taskDetail.recurring_period === null
            ? ""
            : String(taskState.taskDetail.recurring_period),
        recurring_time: taskState.taskDetail.recurring_time,
        recurring_weekday:
          taskState.taskDetail.recurring_weekday === null
            ? ""
            : String(taskState.taskDetail.recurring_weekday),
        recurring_day:
          taskState.taskDetail.recurring_day === null
            ? ""
            : String(taskState.taskDetail.recurring_day),
        recurring_month:
          taskState.taskDetail.recurring_month === null
            ? ""
            : String(taskState.taskDetail.recurring_month)
      });
    } else {
      dispatch(setModalAssign(false));
      setBody(initialBody);
      setEndPeriod(true);
    }
  };

  const _onSubmitEdit = (data: any) => {
    const projecId =
      bodyProject.length > 0 &&
      bodyProject.find((_, index) => index === bodyProject.length - 1)?.value;

    const form = {
      ...data,
      ...body,
      project_id: projecId,
      participants: _getIdParticipant(),
      assigned: 0,
      client_timezone: "Asia/Jakarta",
      finished_at: ""
    };
    dispatch(
      putTaskEdit(
        taskUuid,
        form,
        (e: { success: boolean; message: string }) => {
          if (e.success === true) {
            setModalSubmit(false);
            setBodyProject([]);
            dispatch(setModalAssign(false));
            toast(`${e.message.toUpperCase()}`, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-task-toast",
              closeButton: false,
              draggable: false
            });
            setBody(initialBody);
            setEndPeriod(false);
            setModalEndPeriod(false);
            reset();
            dispatch(getDetailTask(String(taskUuid)));
          } else if (e.success === false) {
            toast(`${e.message}`, {
              position: "top-center",
              autoClose: 3500,
              hideProgressBar: true,
              transition: Slide,
              className: "assign-task-toast assign-red",
              closeButton: false,
              draggable: false
            });
          }
        }
      )
    );
  };

  const _onChangeRecPeriod = e => {
    setBody({ ...body, recurring_period: e.target.value });
    const startDate = moment(watch("started_at")).local();
    if (e.target.value === "1") {
      setDataEndPeriodDate(
        moment(startDate)
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    } else if (e.target.value === "2") {
      setDataEndPeriodDate(
        moment(startDate)
          .add(7, "d")
          .format("YYYY-MM-DD")
      );
    } else if (e.target.value === "3") {
      setDataEndPeriodDate(
        moment(startDate)
          .add(1, "M")
          .format("YYYY-MM-DD")
      );
    } else if (e.target.value === "4") {
      setDataEndPeriodDate(
        moment(startDate)
          .add(12, "M")
          .add(1, "d")
          .format("YYYY-MM-DD")
      );
    } else {
      setBody(initialBody);

      setDataEndPeriodDate("");
    }
  };

  const _convertDataSelect = (data: any[]) => {
    let result = [] as any;
    if (data && data.length > 0) {
      data.map((e: any) => {
        result = [...result, { value: e.id, label: e?.attributes?.title }];
        return true;
      });
    }
    return result;
  };

  const _valueProject = useCallback(
    (data: any[], id: string) => {
      const indexValue = data.findIndex(item => item?.value === id);
      return data?.[indexValue]?.label ?? t("task.form.selectProject");
    },
    [t]
  );

  const _getChildProject = (id: string, index: number) => {
    API.getProjectV2(id).then(res => {
      const dataPj: any[] = res.data.data;
      if (index === 0) {
        const filtered = bodyProject.filter(
          (_: any, i: number) => i + 1 <= index
        );
        setBodyProject([...filtered]);
        setTimeout(() => {
          const result = [...filtered, { value: id, list: dataPj }];
          setBodyProject(result);
        }, 400);
      }
      if (index > 0 && dataPj?.length > 0 && bodyProject?.length > 0) {
        const filtered = bodyProject.filter(
          (_: any, i: number) => i + 1 <= index
        );
        setBodyProject([...filtered]);
        setTimeout(() => {
          const result = [...filtered, { value: id, list: dataPj }];
          setBodyProject(result);
        }, 400);
      }
      if (index > 0 && dataPj?.length === 0) {
        bodyProject[index] = { value: id, list: [] };
      }
    });
  };

  return (
    <>
      <Modal
        loading={assignLoading}
        titleModal={
          !taskUuid
            ? sprintf(t("task.form.assignTask"), copyWritings("task"))
            : sprintf(t("task.form.editTask"), copyWritings("task"))
        }
        isOpen={setModal}
        onPressCancel={_onCancel}
        onPressSubmit={
          !taskUuid ? handleSubmit(_onConfirmSubmitForm) : handleSubmit(_onEdit)
        }
        btnSubmitText={!taskUuid ? t("btn.assign") : t("btn.save")}
        btnCancelText={t("btn.cancel")}
      >
        {handleMessage() && (
          <div className="assign-task-message">
            <img
              src={IMAGES.hazardSign}
              alt="n/a"
              className="task-assign-hazard"
            />
            {handleMessage() === "min-assign"
              ? sprintf(t("task.form.message.policyMinDate"), policy.minAssign)
              : handleMessage() === "max-assign"
              ? sprintf(t("task.form.message.policyMaxDate"), policy.maxAssign)
              : handleMessage() === "min"
              ? sprintf(t("task.form.message.policyMinDate"), policy.min)
              : handleMessage() === "max"
              ? sprintf(t("task.form.message.policyMaxDate"), policy.max)
              : ""}
          </div>
        )}
        <form
          className="form-assign-task margin-top-12"
          onSubmit={handleSubmit(_onSave)}
        >
          <div style={{ width: 494 }}>
            {permission.assign && (
              <div className="task-wrap-switch">
                <span
                  className={`component-modal-assign-task-label switch ${body.is_team &&
                    "opacity"}`}
                  style={{ marginRight: 5 }}
                >
                  Individual
                </span>
                <Switch
                  disabled={
                    taskUuid !== "" &&
                    taskState &&
                    taskState.taskDetail &&
                    taskState.taskDetail.recurring &&
                    taskState.taskDetail.status !== 1
                  }
                  onChange={() => setBody({ ...body, is_team: !body.is_team })}
                  checked={body.is_team}
                  onColor="#ff7600"
                  offColor="#ff7600"
                  onHandleColor="#fffff7"
                  handleDiameter={16}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={24}
                  width={48}
                  tabIndex={0}
                  onKeyPress={() =>
                    setBody({ ...body, is_team: !body.is_team })
                  }
                />
                <span
                  className={`component-modal-assign-task-label switch ${!body.is_team &&
                    "opacity"}`}
                  style={{ marginLeft: 5 }}
                >
                  Team
                </span>
              </div>
            )}
            {permission.assign && (
              <>
                <span className="component-modal-assign-task-label">
                  {t("task.form.assignTo")}
                </span>
                <div className="modal-assign-person-container margin-bottom-12">
                  {taskState?.saveParticipanList?.length > 0 ? (
                    taskState?.saveParticipanList?.map((el, index) => (
                      <div
                        className="modal-assign-person margin-right-8 margin-top-8"
                        key={index}
                      >
                        <img
                          src={IMAGES.avatar}
                          alt="n/a"
                          className="modal-assign-person-photo"
                        />
                        <div>
                          {`(${(el.position && el.position.title) ||
                            el.position_name}) `}
                          <span>{`${el.name} ${!index ? "(PIC)" : ""}`}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                  <Button
                    disabled={
                      taskUuid !== "" &&
                      taskState.taskDetail &&
                      taskState.taskDetail.recurring &&
                      taskState.taskDetail.status !== 1
                    }
                    onPress={() => _setParticipanList()}
                    className="margin-top-8"
                  >
                    <div className="component-modal-assign-task-btn-add-person">
                      <img
                        className="is-focus"
                        style={{ height: 50, width: 50 }}
                        src={IMAGES.floatingPlusButton}
                        alt="create"
                        tabIndex={0}
                      />
                      <span className="component-modal-assign-task-btn-add-person-text">
                        {t("task.form.addPerson")}
                      </span>
                    </div>
                  </Button>
                </div>
              </>
            )}
            <span className="component-modal-assign-task-label">
              {t("task.form.activityName")}
            </span>
            <input
              disabled={
                taskUuid !== "" &&
                taskState.taskDetail &&
                taskState.taskDetail.recurring &&
                taskState.taskDetail.status !== 1
              }
              style={
                taskUuid &&
                taskState.taskDetail &&
                taskState.taskDetail.recurring &&
                taskState.taskDetail.status !== 1
                  ? { color: "#7c7c7c", opacity: "0.7" }
                  : {}
              }
              name="title"
              type="text"
              className="component-modal-input margin-top-12"
              placeholder={t("task.form.activityName")}
              ref={register({
                required: String(t("task.form.message.errorActivity"))
              })}
            />
            <ErrorMessage
              errors={errors}
              name="title"
              as="p"
              style={{ color: "red" }}
            />
            {setModal && (
              <>
                <div className="margin-top-20">
                  <span className="component-modal-assign-task-label">
                    {t("task.form.projectName")}
                  </span>
                  {/* <select
                disabled={
                  taskUuid !== "" &&
                  taskState.taskDetail &&
                  taskState.taskDetail.recurring &&
                  taskState.taskDetail.status !== 1
                }
                name="project_id"
                className="component-modal-input-dropdown margin-top-12"
                ref={register({
                  required: String(t("task.form.message.errorProject"))
                })}
              >
                {populateProject().map((item, index) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select> */}
                  <Autocomplete
                    disable={
                      taskUuid !== "" &&
                      taskState.taskDetail &&
                      taskState.taskDetail.recurring &&
                      taskState.taskDetail.status !== 1
                    }
                    placeHolder={
                      taskUuid && bodyProject?.[0]?.value
                        ? _valueProject(
                            populateProject(),
                            bodyProject?.[0]?.value
                          )
                        : t("task.form.selectProject")
                    }
                    option={populateProject()}
                    style={{ fontWeight: 400 }}
                    onValueChange={e => {
                      setError(false);
                      setSelectedProject(e);
                      _getChildProject(e.value, 0);
                    }}
                  />
                  <div className="task-modal-line" />
                  {error && bodyProject.length === 0 && (
                    <div className="err-text-task">
                      {t("task.form.message.errorProject")}
                    </div>
                  )}
                </div>

                {bodyProject.length > 0 &&
                  bodyProject.map((item: any, index: number) => {
                    if (item?.list?.length > 0) {
                      return (
                        <div
                          key={`data${item.value + index}`}
                          className="margin-top-8"
                        >
                          <Autocomplete
                            placeHolder={
                              taskUuid && bodyProject.length > 0
                                ? _valueProject(
                                    _convertDataSelect(item.list),
                                    bodyProject?.[index + 1]?.value
                                  )
                                : t("task.form.selectProject")
                            }
                            // defaultValue={}
                            option={_convertDataSelect(item.list)}
                            style={{ fontWeight: 400 }}
                            onValueChange={e => {
                              setError(false);
                              setSelectedProject(e);
                              _getChildProject(e.value, index + 1);
                            }}
                          />
                          <div className="task-modal-line" />
                        </div>
                      );
                    }
                    return <></>;
                  })}
              </>
            )}

            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-task-label">
                  {t("task.form.finishDate")}
                </span>
                <div>
                  <input
                    disabled={
                      taskUuid !== "" &&
                      taskState.taskDetail &&
                      taskState.taskDetail.recurring &&
                      taskState.taskDetail.status !== 1
                    }
                    style={
                      taskUuid &&
                      taskState.taskDetail &&
                      taskState.taskDetail.recurring &&
                      taskState.taskDetail.status !== 1
                        ? { color: "#7c7c7c", opacity: "0.7" }
                        : { color: "#666666" }
                    }
                    placeholder="YYYY/MM/DD"
                    type="date"
                    name="due_at"
                    ref={register({
                      required: String(t("task.form.message.errorFinishDate"))
                    })}
                    min={watch("started_at") || moment().format("YYYY-MM-DD")}
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="due_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-task-label">
                  {t("task.form.startDate")}
                </span>
                <input
                  disabled={
                    taskUuid !== "" &&
                    taskState.taskDetail &&
                    taskState.taskDetail.recurring &&
                    taskState.taskDetail.status !== 1
                  }
                  style={
                    taskUuid &&
                    taskState.taskDetail &&
                    taskState.taskDetail.recurring &&
                    taskState.taskDetail.status !== 1
                      ? { color: "#7c7c7c", opacity: "0.7" }
                      : { color: "#666666" }
                  }
                  placeholder="YYYY/MM/DD"
                  type="date"
                  name="started_at"
                  ref={register({
                    required: String(t("task.form.message.errorStartDate"))
                  })}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  min={moment().format("YYYY-MM-DD")}
                  max={watch("due_at")}
                  onChange={e => {
                    if (getValues("due_at") === "") {
                      setValue(
                        "due_at",
                        moment(e.target.value)
                          .add(1, "d")
                          .format("YYYY-MM-DD")
                      );
                    }
                    setDataEndPeriodDate(
                      moment(e.target.value).format("YYYY-MM-DD")
                    );
                  }}
                />
                <div className="component-modal-line" />
                <ErrorMessage
                  errors={errors}
                  name="started_at"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="margin-top-20 component-modal-assign-task-date-picker">
              <span className="component-modal-assign-task-label">
                {t("task.form.description")}
              </span>
              <div className="global-shadow-card component-modal-assign-task-card-desc margin-top-12">
                <textarea
                  disabled={
                    taskUuid !== "" &&
                    taskState.taskDetail &&
                    taskState.taskDetail.recurring &&
                    taskState.taskDetail.status !== 1
                  }
                  style={
                    taskUuid &&
                    taskState.taskDetail &&
                    taskState.taskDetail.recurring &&
                    taskState.taskDetail.status !== 1
                      ? { color: "#7c7c7c", opacity: "0.7" }
                      : {}
                  }
                  onInput={autoGrowTextArea}
                  name="description"
                  className="component-modal-assign-task-text-area"
                  ref={register({
                    required: String(t("task.form.message.errorDescription"))
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="description"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div
              className="margin-top-20 assign-task-wrap-repeat"
              style={
                body.recurring_period === "1"
                  ? { justifyContent: "flex-start" }
                  : { justifyContent: "space-between" }
              }
            >
              <div className="margin-right-8 margin-bottom-8">
                <span className="component-modal-assign-task-label">
                  {t("task.form.repeatTask")}
                </span>
                <div className="global-shadow-card component-modal-assign-task-dropdown-container margin-top-12">
                  <select
                    className="component-modal-assign-task-dropdown padding-left-12"
                    name="recurring_period"
                    onChange={e => _onChangeRecPeriod(e)}
                    value={body.recurring_period}
                  >
                    {dataPeriode.map((item, index) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {body.recurring && body.recurring_period === "4" && (
                <div className="margin-right-8">
                  <span className="component-modal-assign-task-label margin-left-8">
                    {t("task.form.month")}
                  </span>
                  <div
                    className={`global-shadow-card component-modal-assign-task-dropdown-container margin-top-12 ${body.recurring_month ===
                      "" && "opacity"}`}
                  >
                    <select
                      className="component-modal-assign-task-dropdown padding-left-12"
                      onChange={e =>
                        setBody({ ...body, recurring_month: e.target.value })
                      }
                      value={body.recurring_month}
                    >
                      {month.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {body.recurring && ["3", "4"].includes(body.recurring_period) && (
                <div className="margin-right-8">
                  <span className="component-modal-assign-task-label margin-left-8">
                    {t("task.form.date")}
                  </span>
                  <div
                    className={`global-shadow-card component-modal-assign-task-dropdown-container margin-top-12 ${body.recurring_day ===
                      "" && "opacity"}`}
                  >
                    <select
                      className="component-modal-assign-task-dropdown padding-left-12"
                      onChange={e =>
                        setBody({ ...body, recurring_day: e.target.value })
                      }
                      value={body.recurring_day}
                    >
                      {day.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {body.recurring && body.recurring_period === "2" && (
                <div className="margin-right-8">
                  <span className="component-modal-assign-task-label margin-left-8">
                    {t("task.form.weekDay")}
                  </span>
                  <div
                    className={`global-shadow-card component-modal-assign-task-dropdown-container elipsis margin-top-12 ${body.recurring_weekday ===
                      "" && "opacity"}`}
                  >
                    <select
                      style={{ textOverflow: "ellipsis" }}
                      className="component-modal-assign-task-dropdown padding-left-12"
                      onChange={e => {
                        setBody({ ...body, recurring_weekday: e.target.value });
                      }}
                      value={body.recurring_weekday}
                    >
                      {weekDay.map((item, index) => (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}

              {body.recurring &&
                ["4", "3", "2", "1"].includes(body.recurring_period) && (
                  <div className="margin-right-8">
                    <span className="component-modal-assign-task-label margin-left-8">
                      {t("task.form.time")}
                    </span>
                    <div className="global-shadow-card component-modal-assign-task-dropdown-container margin-top-12">
                      <input
                        placeholder="HH:MM"
                        className="component-modal-assign-task-dropdown padding-left-12"
                        type="time"
                        style={{
                          borderWidth: 0,
                          backgroundColor: "#fffff7",
                          borderRadius: 30
                        }}
                        onChange={e => {
                          setBody({ ...body, recurring_time: e.target.value });
                        }}
                        value={body.recurring_time}
                      />
                    </div>
                  </div>
                )}
            </div>
            {body.recurring && (
              <div className="margin-top-20">
                <div className="component-modal-assign-task-label">
                  {t("task.form.endPeriod")}
                </div>
                <div className="task-end-period-wrap">
                  <Switch
                    onChange={() => setEndPeriod(!endPeriod)}
                    checked={endPeriod}
                    onColor="#ff7600"
                    onHandleColor="#fffff7"
                    handleDiameter={16}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={24}
                    width={48}
                    tabIndex={0}
                    onKeyPress={() => setEndPeriod(!endPeriod)}
                  />
                  {body.recurring && endPeriod && (
                    <div className="content">
                      <input
                        placeholder="YYYY-MM-DD"
                        type="date"
                        style={{ color: "#666666" }}
                        name="recurring_ended_at"
                        ref={register}
                        min={
                          watch("started_at") || moment().format("YYYY-MM-DD")
                        }
                        value={!body.recurring_period ? "" : dataEndPeriodDate}
                        onChange={e =>
                          setDataEndPeriodDate(
                            moment(e.target.value).format("YYYY-MM-DD")
                          )
                        }
                      />
                      <div className="component-modal-line" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!taskUuid ? (
              <div className="margin-top-20">
                <div className="component-modal-assign-task-label">
                  {t("task.form.attachment")}
                </div>
                <div className="assign-task-upload">
                  {file.length > 0 &&
                    file.map((el: any, i: number) => {
                      const ext = el.name.split(".").pop();
                      return (
                        <FileUpload
                          key={i}
                          name={el.name}
                          style={{
                            marginTop: 6,
                            marginBottom: 6,
                            marginRight: 8
                          }}
                          deleteFile={e => {
                            e.stopPropagation();
                            deleteFile(el.id);
                          }}
                          type={
                            ext === "png" || ext === "jpg" || ext === "jpeg"
                              ? "image"
                              : "text"
                          }
                          linkImage={URL.createObjectURL(el)}
                          onPress={() => openNewTab(URL.createObjectURL(el))}
                        />
                      );
                    })}
                  <Upload
                    name="attchmen"
                    tabIndex={0}
                    multiple
                    onChange={(e: any) => UploadDocument(e)}
                    textBtn={t("btn.attachmentButton")}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </form>
      </Modal>
      <ModalUserPicker
        onChange={e => {
          setSearchUser(e);
        }}
        pickerOnChange={e => {
          setDepartment(e);
        }}
        value={searchUser}
        hasPicker
        pickerValue={department}
      />
      <ModalSubmitForm
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={
          directSave ? handleSubmit(_onSave) : handleSubmit(_openModalSubmit)
        }
        onCancel={() => setModalSubmitForm(false)}
      />
      <Modal
        loading={taskState.isLoadingPostTask}
        isOpen={modalSubmit}
        onPressCancel={() => {
          setModalSubmit(false);
        }}
        warning
        btnCancelText={t("btn.no")}
        btnSubmitText={t("btn.yes")}
        onPressSubmit={handleSubmit(_onSave)}
      >
        <div className="task-end-periode-title">
          {t("task.form.message.selfAssign")}
        </div>
      </Modal>
      <Modal
        loading={assignLoading}
        isOpen={modalEndPeriod}
        onPressCancel={() => setModalEndPeriod(false)}
        warning
        btnCancelText={t("btn.no")}
        btnSubmitText={t("btn.yes")}
        onPressSubmit={
          !taskUuid ? handleSubmit(_onSubmit) : handleSubmit(_onSubmitEdit)
        }
      >
        <div className="task-end-periode-title">
          {t("task.form.message.willRecurring")}
          <span className="bold">
            {` ${moment(getValues("recurring_ended_at")).format(
              "ddd, DD MMM YYYY"
            )} `}
          </span>
          <br />
          {t("task.form.message.sureRecurring")}
        </div>
      </Modal>
    </>
  );
};

export default memo(ModalAssignTask);
