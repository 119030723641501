import React, { memo } from "react";

import { Button, Text } from "../../../../components";
import "./styles.scss";

interface Props {
  name: string;
  hapus?: boolean;
  primary?: boolean;
  value?: string;
  subValue?: string;
  isAccount?: boolean;
  data?: any;
  onDeleteAccount?: () => void;
  onDeleteAddress?: () => void;
  onPrimaryAddress?: () => void;
  onPrimaryAccount?: () => void;
  dataAccount?: any;
  isLoading?: boolean;
}

const CardCompany = ({
  data,
  name,
  primary,
  dataAccount,
  isAccount,
  onDeleteAccount,
  onDeleteAddress,
  onPrimaryAddress,
  onPrimaryAccount,
  isLoading
}: Props) => {
  return (
    <div className="dashboard-company-card-container shadow">
      <div className="dashboard-company-card-button-container">
        <Text
          style={{
            paddingBottom: 10,
            fontSize: "1.2rem",
            whiteSpace: "nowrap"
          }}
        >
          {name}
        </Text>
        <div
          className="dashboard-company-card-button-container"
          style={{ marginLeft: "auto" }}
        >
          <div style={{ paddingRight: "0.4rem" }}>
            <Button
              onPress={isAccount ? onDeleteAccount : onDeleteAddress}
              // disabled={primary || isLoading}
            >
              <div className="button small">DELETE</div>
            </Button>
          </div>
          <div>
            <Button
              onPress={isAccount ? onPrimaryAccount : onPrimaryAddress}
              disabled={isLoading || primary}
            >
              <div className={`button small hollow ${primary ? "" : "alt"}`}>
                {primary ? "PRIMARY" : "MAKE PRIMARY"}
              </div>
            </Button>
          </div>
        </div>
      </div>
      {!isAccount ? (
        <div>
          <div className="alias">{data.alias}</div>
          <div className="address">
            {!isAccount ? `${data.line_1}. ` : "Hello"}

            {data.line_2 === null ? "" : data.line_2}

            {!isAccount ? `${data.city.name}. ` : "Hello"}

            {!isAccount ? `${data.zipcode}. ` : "Hello"}
          </div>
          <div className="phone-fax">
            phone.
            {!isAccount ? `${data.phone}. ` : null}
            Fax:
            {data.fax === null ? "" : data.fax}
          </div>
        </div>
      ) : (
        <div>
          <div className="name-branch">{dataAccount.account.bankName}</div>
          <div className="account-no">{dataAccount.account.number}</div>
          <div className="coa">{dataAccount.account.branch}</div>
        </div>
      )}
    </div>
  );
};

CardCompany.defaultProps = {
  isLoading: false
};

export default memo(CardCompany);
