import {
  GET_REGENCY_ERROR,
  GET_REGENCY_PENDING,
  GET_REGENCY_SUCCESS,
  POST_SIGN_UP_COMPANY_ERROR,
  POST_SIGN_UP_COMPANY_PENDING,
  POST_SIGN_UP_COMPANY_SUCCESS,
  SET_FORM_WALKTHROUGH,
  UPDATE_FORM_WALKTHROUGH
} from "../actions";
import { Action, WalkthroughState } from "../types";

const initalState: WalkthroughState = {
  listRegency: {
    isLoadingRegency: false,
    data: []
  },
  form: {
    name: "",
    email: "",
    password: "",
    confirm_password: "",
    name_company: "",
    address1: "",
    address2: "",
    regency_id: "",
    city: "",
    phone: "",
    fax: "",
    zip_code: "",
    email_company: "",
    name_branch: null,
    department: "",
    position: "",
    city_name: ""
  },
  signUpCompany: {
    isLoading: false
  }
};

export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_REGENCY_PENDING:
      return {
        ...state,
        listRegency: { ...state.listRegency, isLoadingRegency: true }
      };
    case GET_REGENCY_SUCCESS:
      return {
        ...state,
        listRegency: {
          ...state.listRegency,
          isLoadingRegency: false,
          data: payload.data
        }
      };
    case GET_REGENCY_ERROR:
      return {
        ...state,
        signUpCompany: { ...state.listRegency, isLoadingRegency: false }
      };
    case POST_SIGN_UP_COMPANY_PENDING:
      return {
        ...state,
        signUpCompany: { ...state.signUpCompany, isLoading: true }
      };
    case POST_SIGN_UP_COMPANY_SUCCESS:
      return {
        ...state,
        signUpCompany: {
          ...state.signUpCompany,
          isLoading: false
        }
      };
    case POST_SIGN_UP_COMPANY_ERROR:
      return {
        ...state,
        signUpCompany: {
          ...state.signUpCompany,
          isLoading: false
        }
      };

    // set form
    case SET_FORM_WALKTHROUGH:
      return {
        ...state,
        form: payload.data
      };
    case UPDATE_FORM_WALKTHROUGH:
      return {
        ...state,
        form: { ...state.form, [payload.data.formName]: payload.data.value }
      };
    default:
      return state;
  }
};
