const textToLink = (text: string) => {
  const exp = /(\b(((https?|ftp|file|):\/\/)|www[.])[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|]([.][-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|]))/gi;
  let temp = text.replace(exp, '<a href="$1" target="_blank">$1</a>');
  let result = "";

  while (temp.length > 0) {
    const pos = temp.indexOf('href="');
    if (pos === -1) {
      result += temp;
      break;
    }
    result += temp.substring(0, pos + 6);

    temp = temp.substring(pos + 6, temp.length);
    if (temp.indexOf("://") > 8 || temp.indexOf("://") === -1) {
      result += "http://";
    }
  }
  return {
    __html: result
  };
};

export default textToLink;
