import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { SideBar, TopBar, WrapApp } from "../../components";
import "./styles.scss";
import {
  getMenu,
  getMyPermission,
  getPolicyOwned,
  getTaskBubbleNotif,
  handleTheme
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

import List from "./List";
import Detail from "./Detail";

const Task = () => {
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";

  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
    dispatch(getMyPermission());
    dispatch(getPolicyOwned());
    dispatch(getTaskBubbleNotif());
  }, [dispatch, getLanguage]);

  const match = useRouteMatch();

  const items: any = [
    {
      pathname: `${match.path}/list`,
      component: <List />,
      exact: false
    },
    {
      pathname: `${match.path}/detail/:id`,
      component: <Detail />,
      exact: false
    }
  ];

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <Route path={match.path} exact>
          <Redirect to={`${match.path}/list`} />
        </Route>
        {items.map((item, index) => (
          <Route path={item.pathname} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
      </WrapApp>
    </Switch>
  );
};

export default Task;
