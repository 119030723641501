import { Slide, toast } from "react-toastify";
import Cookies from "js-cookie";
import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";
import { utcDate } from "../../utils";
import i18n from "../../i18n";

export const GET_LIST_WIDGET_PENDING = "GET_LIST_WIDGET_PENDING";
export const GET_LIST_WIDGET_SUCCESS = "GET_LIST_WIDGET_SUCCESS";
export const GET_LIST_WIDGET_ERROR = "GET_LIST_WIDGET_ERROR";

export const GET_WIDGET_PENDING = "GET_WIDGET_PENDING";
export const GET_WIDGET_SUCCESS = "GET_WIDGET_SUCCESS";
export const GET_WIDGET_ERROR = "GET_WIDGET_ERROR";

export const POST_SUPPORT_PENDING = "POST_SUPPORT_PENDING";
export const POST_SUPPORT_SUCCESS = "POST_SUPPORT_SUCCESS";
export const POST_SUPPORT_ERROR = "POST_SUPPORT_ERROR";

export const HANDLE_CATEGORY_WIDGET = "HANDLE_CATEGORY_WIDGET";

export const HANDLE_SELECT_WIDGET = "HANDLE_SELECT_WIDGET";
export const HANDLE_SELECT_SUB_WIDGET = "HANDLE_SELECT_SUB_WIDGET";

export const SET_FORM_FILTER_WIDGET = "SET_FORM_FILTER_WIDGET";
export const SET_ACTIVE_RANGE_FILTER_WIDGET = "SET_ACTIVE_RANGE_FILTER_WIDGET";
export const SET_ACTIVE_SIDE_BAR_DATA = "SET_ACTIVE_SIDE_BAR_DATA";

export const GET_MY_PROFILE_PENDING = "GET_MY_PROFILE_PENDING";
export const GET_MY_PROFILE_SUCCESS = "GET_MY_PROFILE_SUCCESS";
export const GET_MY_PROFILE_ERROR = "GET_MY_PROFILE_ERROR";
export const SET_CONFIRM_SUBMIT_FORM = "SET_CONFIRM_SUBMIT_FORM";

export const getWidget = (
  widgetName: string,
  indexWidget: number,
  uuid?: string,
  project_id?: string,
  department_id?: string
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { home } = getState();
    dispatch({ type: GET_WIDGET_PENDING });

    const from = utcDate(
      `${home.form.from.years}/${home.form.from.months}/${home.form.from.date} ${home.form.from.time}`
    );
    const to = utcDate(
      `${home.form.to.years}/${home.form.to.months}/${home.form.to.date} ${home.form.to.time}`
    );

    const language = Cookies.get("locale") || "en";

    const res = await API.getWidget(
      widgetName,
      from,
      to,
      uuid,
      project_id,
      department_id,
      language
    );

    dispatch({
      type: GET_WIDGET_SUCCESS,
      payload: { data: res.data.data, index: indexWidget }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_WIDGET_ERROR });
  }
};

export const handleLoadingFilterHome = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_LIST_WIDGET_PENDING });
};

export const getListWidget = (
  uuid?: string,
  project_id?: string,
  department_id?: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_LIST_WIDGET_PENDING });

    const res = await API.getListWidget(uuid, project_id, department_id);
    dispatch({
      type: GET_LIST_WIDGET_SUCCESS,
      payload: { data: res.data.data }
    });
    res.data.data.map((item: string, index: number) =>
      dispatch(getWidget(item, index, uuid, project_id, department_id))
    );
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_LIST_WIDGET_ERROR });
  }
};

export const handleCategoryWidget = (value: number, index: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: HANDLE_CATEGORY_WIDGET,
    payload: { data: value, index }
  });
};

export const handleSelectWidget = (index: number, isTrip: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: HANDLE_SELECT_WIDGET,
    payload: { data: { data: index, isTrip } }
  });
};

export const handleSelectSubWidget = (indexSub: number, index: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: HANDLE_SELECT_SUB_WIDGET,
    payload: { data: indexSub, index }
  });
};

export const setFormFilterWidget = (form: any) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FORM_FILTER_WIDGET,
    payload: { data: form }
  });
};

export const setActiveRangeFilterWidget = (index: number) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ACTIVE_RANGE_FILTER_WIDGET,
    payload: { data: index }
  });
};

export const getMyProfile = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_MY_PROFILE_PENDING });

    const res = await API.getMyProfile();
    dispatch({
      type: GET_MY_PROFILE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_MY_PROFILE_ERROR });
  }
};

export const setActiveSidebarData = (index: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_SIDE_BAR_DATA,
    payload: { data: index }
  });
};

export const postSupport = (body: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: POST_SUPPORT_PENDING });

    const formData = new FormData();
    formData.append("title", body.title);
    formData.append("description", body.description);
    formData.append("type", body.type);
    formData.append("file", body.file);

    const res = await API.postSupport(formData);
    dispatch({
      type: POST_SUPPORT_SUCCESS,
      payload: { data: res.data.data }
    });
    await toast.success(i18n.t("support.msg.submit"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast-long",
      closeButton: false,
      draggable: false
    });
    window.location.replace(process.env.REACT_APP_HOST_WEB || "");
  } catch (err) {
    toast.error(i18n.t("support.msg.errorSubmit"), {
      position: "top-center",
      autoClose: 6000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast danger",
      closeButton: false,
      draggable: false
    });
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: POST_SUPPORT_ERROR });
  }
};

export const setConfirmSubmitForm = (data: {
  formName: string;
  value: any;
}) => (dispatch: Dispatch) => {
  dispatch({ type: SET_CONFIRM_SUBMIT_FORM, payload: { data } });
};
