import React from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../../components";
import { IMAGES } from "../../../../configs";
import { Reducers } from "../../../../redux/types";

import { setSetting } from "../../../../redux/actions";

const ArrayInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settingState = useSelector((state: Reducers) => state.setting);

  const deleteLevel = (index: number) => {
    const deletedLevel = settingState.settingDetail.value;
    deletedLevel.splice(index, 1);
    dispatch(setSetting("value", deletedLevel));
  };

  const addLevel = (index: number) => {
    const addedLevel = [
      ...settingState.settingDetail.value,
      { level: index + 2, user: [""] }
    ];
    dispatch(setSetting("value", addedLevel));
  };

  const deleteUser = (indexLevel: number, indexUser: number) => {
    const deletedUser = settingState.settingDetail.value;
    deletedUser[indexLevel].user.splice(indexUser, 1);
    dispatch(setSetting("value", deletedUser));
  };

  const addUser = (indexLevel: number) => {
    const addedUser = settingState.settingDetail.value;
    addedUser[indexLevel].user = [...addedUser[indexLevel].user, ""];
    dispatch(setSetting("value", addedUser));
  };

  const changeValueOption = (
    indexLevel: number,
    indexUser: number,
    value: any
  ) => {
    const changedValue = settingState.settingDetail.value;
    changedValue[indexLevel].user[indexUser] = value;
    dispatch(setSetting("value", changedValue));
  };

  return (
    <div>
      {settingState.settingDetail &&
        settingState.settingDetail.value.map((item: any, index: any) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              marginBottom: 40
            }}
          >
            <div style={{ width: "30%" }}>
              <div className="margin-bottom-12">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {`${t("setting.level")} #${index + 1}`}
                  </span>
                  {settingState.settingDetail.value.length > 1 && (
                    <Button
                      className="margin-left-12"
                      onPress={() => deleteLevel(index)}
                    >
                      <img alt="warn" src={IMAGES.deleteFile} />
                    </Button>
                  )}
                </div>
              </div>
              {settingState.settingDetail.value.length === index + 1 && (
                <Button
                  className="setting-list-btn-add-array-object level"
                  onPress={() => addLevel(index)}
                >
                  <p style={{ color: "white", fontWeight: "bold" }}>
                    {t("setting.addLevel")}
                  </p>
                </Button>
              )}
            </div>
            <div style={{ width: "70%" }}>
              {item.user.map((itemUser: any, indexUser: number) => (
                <div
                  key={indexUser}
                  className="margin-bottom-20"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                  }}
                >
                  <div style={{ width: "90%" }}>
                    <select
                      className="component-modal-input-dropdown"
                      onChange={(e: any) =>
                        changeValueOption(index, indexUser, e.target.value)
                      }
                    >
                      <option disabled selected={itemUser === ""}>
                        {t("setting.selectEmployee")}
                      </option>
                      {settingState.lookupValue.map(
                        (itemLookup, indexLookup) => (
                          <option
                            key={indexLookup}
                            value={itemLookup.id_user}
                            selected={
                              String(itemLookup.id_user) === String(itemUser)
                            }
                          >
                            {itemLookup.title}
                          </option>
                        )
                      )}
                    </select>
                    <div className="setting-list-line" />
                  </div>
                  {item.user.length > 1 && (
                    <Button
                      className="margin-top-4"
                      onPress={() => deleteUser(index, indexUser)}
                    >
                      <img alt="warn" src={IMAGES.deleteFile} />
                    </Button>
                  )}
                </div>
              ))}
              <Button
                className="setting-list-btn-add-array-object"
                onPress={() => addUser(index)}
              >
                <p style={{ color: "white", fontWeight: "bold" }}>
                  {t("setting.addEmployee")}
                </p>
              </Button>
            </div>
          </div>
        ))}
    </div>
  );
};

export default ArrayInput;
