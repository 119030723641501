import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button, Spinner, Text } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import {
  postSignUpCompany,
  // updateDetailTaskForm,
  updateFormWalkthrough
} from "../../../../redux/actions";
import "./styles.scss";
import { documentTitle, heightPercent } from "../../../../utils";

const DetailCompany = () => {
  documentTitle("Detail Company");
  const walkthroughState = useSelector((state: Reducers) => state.walkthrough);
  const dispatch = useDispatch();
  const history = useHistory();

  const _handleFinishPage = useCallback(() => {
    dispatch(
      updateFormWalkthrough({
        formName: "city",
        value: String(walkthroughState.form.city).slice(
          0,
          walkthroughState.form.city.indexOf(":")
        )
      })
    );
    dispatch(postSignUpCompany());
  }, [dispatch, walkthroughState.form.city]);

  useEffect(() => {
    dispatch(
      updateFormWalkthrough({
        formName: "city_name",
        value: String(walkthroughState.form.city).slice(
          walkthroughState.form.city.indexOf(":") + 1,
          walkthroughState.form.city.length
        )
      })
    );
  }, [dispatch, walkthroughState.form.city]);

  return (
    <div
      className="page-walktrough-detail walktrough-detail-company-container"
      style={{ height: heightPercent(100) }}
    >
      <Text className="walktrough-detail-company-title">
        {walkthroughState.form.name_company}
      </Text>
      <Text className="walktrough-detail-company-subtitle">
        Please make sure the data you input is correct. You may have to contact
        administrator to change the data.
      </Text>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Company Name
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.name_company}
            </Text>
          </div>
        </div>
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Company Address
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.address1}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Admin Email
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              implementer@djurnee.id
            </Text>
          </div>
        </div>
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              City
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.city_name}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Admin Password
            </Text>
          </div>

          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              password
            </Text>
          </div>
        </div>
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Zip Code
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.zip_code}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }} />

          <div style={{ width: "60%" }}>
            <Text
              style={{ color: "red" }}
              className="walktrough-detail-company-title-data"
            >
              (Please update later!)
            </Text>
          </div>
        </div>
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Phone No.
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.phone}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data" />
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Fax No.
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.fax || "-"}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-form-container">
        <div className="walktrough-detail-company-column-data" />
        <div className="walktrough-detail-company-column-data">
          <div style={{ width: "40%" }}>
            <Text
              style={{ fontWeight: "bold" }}
              className="walktrough-detail-company-title-data"
            >
              Email
            </Text>
          </div>
          <div style={{ width: "60%" }}>
            <Text className="walktrough-detail-company-title-data">
              {walkthroughState.form.email_company}
            </Text>
          </div>
        </div>
      </div>
      <div className="walktrough-detail-company-container-buttons">
        <Button onPress={() => history.goBack()}>
          <div className="walktrough-detail-company-container-button-back">
            <Text
              style={{
                color: "white",
                fontWeight: "bold",
                fontFamily: "Poppins",
                fontSize: 23
              }}
            >
              BACK
            </Text>
          </div>
        </Button>
        <Button
          onPress={() => _handleFinishPage()}
          className="walktrough-detail-company-container-button "
          disabled={walkthroughState.signUpCompany.isLoading}
        >
          <div className="walktrough-detail-company-container-text-button">
            {walkthroughState.signUpCompany.isLoading ? (
              <Spinner />
            ) : (
              <Text
                style={{
                  alignSelf: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  fontSize: 23
                }}
              >
                FINISH
              </Text>
            )}
          </div>
        </Button>
      </div>
    </div>
  );
};

export default DetailCompany;
