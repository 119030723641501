import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { SideBar, TopBar, WrapApp } from "../../components";
import {
  getMenu,
  getMyPermission,
  getPolicyOwned,
  handleTheme
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

import SupportPage from "./Pages/SupportPage";

const Support = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";
  const menuState = useSelector((state: Reducers) => state.menu);
  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
    dispatch(getMyPermission());
    dispatch(getPolicyOwned());
  }, [dispatch, getLanguage]);

  return (
    <>
      <Switch>
        <WrapApp>
          <SideBar show={!menuState.collapsible} />
          <TopBar />
          <Route path={match.path} exact>
            <SupportPage />
          </Route>
        </WrapApp>
      </Switch>
    </>
  );
};

export default Support;
