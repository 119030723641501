import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { SideBar, TopBar, WrapApp } from "../../components";
import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import ExpenseType from "./ExpenseType";

const MasterData = () => {
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";
  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
  }, [dispatch, getLanguage]);

  const match = useRouteMatch();

  const items: any = [
    {
      pathname: `${match.path}/finance_category`,
      component: <ExpenseType />,
      exact: false
    }
  ];

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <Route path={match.path} exact>
          <Redirect to={`${match.path}/finance_category`} />
        </Route>
        {items.map((item, index) => (
          <Route path={item.pathname} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
      </WrapApp>
    </Switch>
  );
};

export default MasterData;
