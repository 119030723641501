import axios from "axios";
import XLSX from "xlsx";

import { useCookie } from "../utils";

const cookie = useCookie();
const token = `Bearer ${
  cookie["auth._token.djurnee.redirect"]
    ? cookie["auth._token.djurnee.redirect"].substr(9)
    : ""
}`;

const host = axios.create({
  baseURL: process.env.REACT_APP_HOST
});

const hostV1 = axios.create({
  baseURL: process.env.REACT_APP_HOST_V1
});

const hostDummy = axios.create({
  baseURL: process.env.REACT_APP_HOST_DUMMY
});

const hostDummy2 = axios.create({
  baseURL: process.env.REACT_APP_HOST_DUMMY2
});

const hostReport = axios.create({
  baseURL: process.env.REACT_APP_HOST_REPORT
});

export interface BodySignUpCompany {
  city: any;
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  name_company: string;
  address1: string;
  address2: string | null;
  regency_id: string;
  phone: string;
  fax: string;
  zip_code: string;
  email_company: string;
  name_branch: string | null;
  position: string;
  department: string;
  city_name: string;
}

const api = {
  postLogout: () =>
    hostV1.post(
      "auth/logout",
      {},
      {
        headers: { Authorization: token }
      }
    ),
  getMyProfile: () =>
    host.get("auth/user?include=employee", {
      headers: { Authorization: token }
    }),
  getMyPermission: () =>
    hostV1.get("setting/permission/owned", {
      headers: { Authorization: token }
    }),
  getSetting: () =>
    hostV1.get("setting/general/owned", {
      headers: { Authorization: token }
    }),

  getListWidget: (
    user_id?: string,
    project_id?: string,
    department_id?: string,
    language?: string
  ) =>
    hostV1.get(
      `dashboard/list-of-widget${
        project_id ? `?project_id=${project_id}` : ""
      }${user_id ? `?user_id=${user_id}` : ""}${
        department_id ? `?department_id=${department_id}` : ""
      }
      ${language ? `&language=${language}` : ""}`,
      {
        headers: { Authorization: token }
      }
    ),

  getDetailTask: (taskId: string) =>
    host.get(`task/${taskId}`, {
      headers: { Authorization: token }
    }),

  getTaskForm: (taskId: string) =>
    host.get(`form/task/${taskId}`, {
      headers: { Authorization: token }
    }),

  getDetailTaskReport: (taskId: string, uuid: string) =>
    host.get(`task/${taskId}/user/${uuid}/reports`, {
      headers: { Authorization: token }
    }),
  getAvailableDateTaskReport: (taskId: string, uuid: string) =>
    host.get(`task/${taskId}/user/${uuid}/reports/available-date`, {
      headers: { Authorization: token }
    }),
  getReport: (from: string, to: string, page: number) =>
    host.get(
      `finance/export/accurate/list?from=${from}&to=${to}&per_page=5&page=${page}`,
      {
        headers: {
          Authorization: token
        }
      }
    ),
  getWidget: (
    widgetName: string,
    from: string,
    to: string,
    user_id?: string,
    project_id?: string,
    department_id?: string,
    language?: string
  ) =>
    hostV1.get(
      `dashboard/${widgetName}?from=${from}&to=${to}${
        user_id !== "" ? `&user_id=${user_id}` : ""
      }
      ${project_id ? `&project_id=${project_id}` : ""}
      ${department_id ? `&department_id=${department_id}` : ""}
      ${language ? `&language=${language}` : ""}
      `,
      {
        headers: { Authorization: token }
      }
    ),
  getRegency: () => hostV1.get("master/regency"),

  checkRegisteredCompany: () => hostV1.get("auth/check-data"),

  getPolicy: (
    departmentId?: string,
    positionId?: string,
    personnelId?: string
  ) => {
    const _isGlobal = () => {
      if (personnelId) {
        return `personnel/${personnelId}`;
      }
      if (positionId) {
        return `position/${positionId}`;
      }
      if (departmentId) {
        return `department/${departmentId}`;
      }
      return "global";
    };

    return hostV1.get(`policy/${_isGlobal()}`, {
      headers: { Authorization: token }
    });
  },
  downloadXML: () =>
    host.get("finance/export/accurate/download/xml", {
      headers: {
        "Content-Type": "text/xml",
        Authorization: token
      },
      responseType: "blob"
    }),
  downloadCSV: () =>
    host.get("finance/export/accurate/download/csv", {
      headers: {
        "Content-Type": "text/csv",
        Authorization: token
      },
      responseType: "blob"
    }),
  postSignUpCompany: (body: BodySignUpCompany) =>
    hostV1.post("start/register", body),
  getMenusSideBar: () => hostDummy.get("/menu"),
  getMenu: (mod: string, lang?: string) =>
    hostV1.get(`dashboard/menu?module=${mod}&language=${!lang ? "en" : lang}`, {
      headers: { Authorization: token }
    }),
  getProfileById: (uuid: string) =>
    hostV1.get(`dashboard/user_id/${uuid}`, {
      headers: { Authorization: token }
    }),
  getPopulateEmployeeExceptSelf: () =>
    hostV1.get("company/employee/populateExceptSelf", {
      headers: { Authorization: token }
    }),
  getEmployeeProfile: () =>
    hostDummy2.get("company/employee/self", {
      headers: { Authorization: token }
    }),
  getProfileStaff: (id: string) =>
    host.get(`company/employee/${id}`, {
      headers: { Authorization: token }
    }),
  getContractsById: (id: string) =>
    hostV1.get(`company/employee/contracts/${id}`, {
      headers: { Authorization: token }
    }),
  getContractsByEmployee: (id: string) =>
    hostV1.get(`company/employee/${id}/contracts`, {
      headers: { Authorization: token }
    }),
  getSalaryById: (id: string) =>
    hostV1.get(`company/salary/details/${id}`, {
      headers: { Authorization: token }
    }),
  getSalaryByEmployee: (id?: string) =>
    hostV1.get(`company/salary/show${id ? `?employee_id=${id}` : ""}`, {
      headers: { Authorization: token }
    }),
  postEmployeeContract: (body: any) =>
    hostV1.post("company/employee/contracts", body, {
      headers: { Authorization: token }
    }),
  putEmployeeContract: (body: any, id: string) =>
    hostV1.post(`company/employee/contracts/${id}`, body, {
      headers: { Authorization: token }
    }),
  postEmployeeSalary: (body: any) =>
    hostV1.post("company/salary/save", body, {
      headers: { Authorization: token }
    }),
  putEmployeeSalary: (body: any, id: string) =>
    hostV1.put(`company/salary/update/${id}`, body, {
      headers: { Authorization: token }
    }),
  deleteContract: (id: any) =>
    hostV1.delete(`company/employee/contracts/${id}`, {
      headers: { Authorization: token }
    }),
  deleteSalary: (id: any) =>
    hostV1.delete(`company/salary/delete/${id}`, {
      headers: { Authorization: token }
    }),
  getMenuReport: () =>
    hostReport.get("dashboard/menu?module=general", {
      headers: { Authorization: token }
    }),
  getReportType: () =>
    hostV1.get("reports/menu/report-type", {
      headers: { Authorization: token }
    }),
  getDepartment: () =>
    hostV1.get("reports/menu/department", {
      headers: { Authorization: token }
    }),
  getAllPosition: () =>
    hostV1.get("company/position", {
      headers: { Authorization: token }
    }),
  getPositionById: (id: string) =>
    hostV1.get(
      `company/position?page=1&sortField=title&sortOrder=asc&department_id=${id}`,
      {
        headers: { Authorization: token }
      }
    ),
  getPersonal: (id?: string, idPos?: string) =>
    hostV1.get(
      `reports/menu/personel?${id ? `department_id=${id}&` : ""}${
        idPos ? `position_id=${idPos}` : ""
      }`,
      {
        headers: { Authorization: token }
      }
    ),
  getEmployee: (
    id?: string,
    idPos?: string,
    idRole?: string,
    page = 1,
    hidden = false,
    hiddenOutSource = false,
    q?: string,
    parent?: boolean,
    show_self?: boolean,
    disable = false,
    bypassPermission = false,
    per_page = 20
  ) =>
    host.get(
      `company/employee?hidden=${hidden}&outsource=${hiddenOutSource}&disabled=${disable}&per_page=${per_page}${
        q ? `&q=${q}` : ""
      }&page=${page}${id ? `&department_id=${id}` : ""}${
        idPos ? `&position_id=${idPos}` : ""
      }${idRole ? `&role_id=${idRole}` : ""}${
        parent ? `&parent=${parent}` : ""
      }&show_self=${show_self}&bypass_permission=${bypassPermission}`,
      {
        headers: { Authorization: token }
      }
    ),
  getPersonalByIdPosition: (idPos: string) =>
    hostV1.get(`company/employee?position_id=${idPos}`, {
      headers: { Authorization: token }
    }),
  getAllPersonal: () =>
    hostV1.get("reports/menu/personel", {
      headers: { Authorization: token }
    }),
  getProject: (id?: string) =>
    hostV1.get(`reports/menu/project? ${id ? "" : ""}`, {
      headers: { Authorization: token }
    }),
  getProjectV2: (parentId?: string, is_parent = false) =>
    hostV1.get(
      `project/project/populate?parent_id=${parentId || ""}${
        is_parent ? `&show_top_parent=${true}` : ""
      }`,
      {
        headers: { Authorization: token }
      }
    ),
  getProjectById: (id: string) =>
    hostV1.get(`project/project/${id}`, {
      headers: { Authorization: token }
    }),
  getClient: () =>
    hostV1.get("reports/menu/client", {
      headers: { Authorization: token }
    }),
  downloadHTMLReport: (
    type: any,
    from: any,
    to: any,
    department_id: string,
    client_id: string,
    project_id: string,
    personnelId: string
  ) =>
    hostV1.get(
      `reports/${type}?${from ? `from=${from}` : ""}${to ? `&to=${to}` : ""}${
        department_id ? `&department_id=${department_id}` : ""
      }${client_id ? `&client_id=${client_id}` : ""}${
        project_id ? `&project_id=${project_id}` : ""
      }${personnelId ? `&personnel_id=${personnelId}` : ""}`,
      {
        headers: { Authorization: token }
      }
    ),
  getPDFReport: (
    type: any,
    from: any,
    to: any,
    department_id: string,
    client_id: string,
    project_id: string,
    personnelId: string
  ) =>
    hostV1.get(
      `reports/${type}/download/pdf?${from ? `from=${from}` : ""}${
        to ? `&to=${to}` : ""
      }${department_id ? `&department_id=${department_id}` : ""}${
        client_id ? `&client_id=${client_id}` : ""
      }${project_id ? `&project_id=${project_id}` : ""}${
        personnelId ? `&personnel_id=${personnelId}` : ""
      }`,
      {
        headers: {
          Authorization: token,
          "Content-Type": "text/pdf"
        },
        responseType: "blob"
      }
    ),
  getCSVReport: (
    type: any,
    from: any,
    to: any,
    department_id: string,
    client_id: string,
    project_id: string,
    personnelId: string
  ) =>
    hostV1.get(
      `reports/${type}/download/xlsx?${from ? `from=${from}` : ""}${
        to ? `&to=${to}` : ""
      }${department_id ? `&department_id=${department_id}` : ""}${
        client_id ? `&client_id=${client_id}` : ""
      }${project_id ? `&project_id=${project_id}` : ""}${
        personnelId ? `&personnel_id=${personnelId}` : ""
      }`,
      {
        headers: { Authorization: token, "Content-Type": "text/pdf" },
        responseType: "blob"
      }
    ),
  getCompanyDetail: () =>
    host.get("company/company/owned", {
      headers: { Authorization: token }
    }),
  postSavePolicy: (
    id: string,
    value: { policy_slug: string; value: any; method: "set" | "delete" }[],
    type: "global" | "department" | "position" | "personnel"
  ) =>
    hostV1.post(
      `policy/${type}/${id ? `${id}/` : ""}bulk-set`,
      {
        policies: value
      },
      {
        headers: { Authorization: token }
      }
    ),
  getSpecialRole: () =>
    hostV1.get("setting/role", {
      headers: { Authorization: token }
    }),
  getCompanyTree: (params: string) =>
    host.get(`company/employee/tree${params && `?${params}`}`, {
      headers: { Authorization: token }
    }),
  postCompany: (body: any) =>
    host.put(
      "company/post",
      {
        headers: { Authorization: token }
      },
      {
        ...body
      }
    ),
  deleteAddress: (id: any) =>
    host.delete(`company/address/${id}`, {
      headers: { Authorization: token }
    }),
  deleteAccount: (id: any) =>
    host.delete(`djurnee/payment/method/${id}/delete`, {
      headers: { Authorization: token }
    }),
  primaryAddress: (id: any) =>
    host.put(
      `company/address/primary/${id}`,
      {
        headers: { Authorization: token }
      },
      {}
    ),
  primaryAccount: (id: any) =>
    host.put(
      `company/account/primary/${id}`,
      {
        headers: { Authorization: token }
      },
      {}
    ),
  getDivision: (params: string) =>
    hostV1.get(`/djurnee/division?${params}`, {
      headers: { Authorization: token }
    }),
  getDepartmentPositionPopulate: () =>
    hostV1.get("company/department/populate", {
      headers: { Authorization: token }
    }),
  getDepartmentPosition: () =>
    host.get("company/position", {
      headers: { Authorization: token }
    }),
  getAddDepartment: () =>
    hostV1.get("company/department/populate", {
      headers: { Authorization: token }
    }),
  getClassDivision: () =>
    hostV1.get("djurnee/division/populate", {
      headers: { Authorization: token }
    }),
  getRole: () =>
    hostV1.get("setting/role", {
      headers: { Authorization: token }
    }),
  getRoleSpecial: () =>
    hostV1.get("setting/role/populate-special", {
      headers: { Authorization: token }
    }),
  postDivision: (form: any) =>
    hostV1.post("djurnee/division", form, {
      headers: { Authorization: token }
    }),
  putDivision: (form: any, id: string) =>
    hostV1.put(`/djurnee/division/${id}`, form, {
      headers: { Authorization: token }
    }),
  deleteDivision: (id: string) =>
    hostV1.delete(`/djurnee/division/${id}`, {
      headers: { Authorization: token }
    }),
  getRoleByPosition: (id: string) =>
    host.get(`company/position/${id}`, {
      headers: { Authorization: token }
    }),
  getPositionDetail: (id: string) =>
    host.get(`company/position/${id}`, { headers: { Authorization: token } }),
  putSaveProfileEmployee: (formData: any, id: string, method: "PUT" | "POST") =>
    host.post(
      `company/employee${method === "POST" ? "" : `/${id}`}?_method=${method}`,
      formData,
      {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data"
        }
      }
    ),
  addPosition: (body: any) =>
    hostV1.post(
      "company/position",
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  postCompanies: (formData: any, id: string) =>
    host.post(`company/company/${id}`, formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" }
    }),
  getAllBank: () =>
    hostV1.get("master/bank/populate", {
      headers: { Authorization: token }
    }),
  setPrimaryBank: (id: string) =>
    host.put(
      `djurnee/payment/method/${id}/set_primary`,
      { primary: 1 },
      {
        headers: { Authorization: token }
      }
    ),
  deletePosition: (id: string) =>
    hostV1.delete(`company/position/${id}`, {
      headers: { Authorization: token }
    }),
  putPosition: (id: string, body: any) =>
    hostV1.put(
      `company/position/${id}`,
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  addDepartment: (body: any) =>
    hostV1.post(
      "company/department",
      { ...body },
      {
        headers: { Authorization: token }
      }
    ),
  getDetailDepartment: (id: string) =>
    hostV1.get(`company/department/${id}`, {
      headers: { Authorization: token }
    }),
  putDepartment: (id: string, body: any) =>
    hostV1.put(
      `company/department/${id}`,
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  deleteDepartment: (id: string) =>
    hostV1.delete(`company/department/${id}`, {
      headers: { Authorization: token }
    }),
  getAllRoles: (idDp?: string, idPos?: string) =>
    hostV1.get(
      `permission/roles?special=true${idDp ? `&department_id=${idDp}` : ""}${
        idPos ? `&position_id=${idPos}` : ""
      }`,
      {
        headers: { Authorization: token }
      }
    ),
  deleteAccountEmployee: (id: string) =>
    hostV1.delete(`finance/account/user/${id}`, {
      headers: { Authorization: token }
    }),
  getMethodePayment: () =>
    hostV1.get("finance/payment/method/populate", {
      headers: { Authorization: token }
    }),
  changePassword: (form: {
    password1: string;
    password2: string;
    password3: string;
  }) =>
    hostV1.put("account/password", form, {
      headers: { Authorization: token }
    }),
  putPrimaryAccountEmployee: (account_id: string, employee_id: string) =>
    host.put(
      `user/account/${account_id}?employee_id=${employee_id}`,
      { primary: 1 },
      {
        headers: { Authorization: token }
      }
    ),
  getTaskList: (status: string, q?: string, page?: number) =>
    host.get(
      `task/status/${status}?per_page=10&page=${page}${q ? `&q=${q}` : ""}`,
      {
        headers: { Authorization: token }
      }
    ),
  postTaskExtendDays: (taskId: string, body: any) =>
    host.post(
      `task/${taskId}/extend?_method=PUT`,
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  postUploadDetailTaskFile: (taskId: string, body: any) =>
    host.post(`task/${taskId}/upload/file`, body, {
      headers: { Authorization: token }
    }),
  postUploadDetailTaskReportFile: (taskReportId: string, body: any) =>
    host.post(`task/report/${taskReportId}/upload/file`, body, {
      headers: { Authorization: token }
    }),
  postTask: (body: any) =>
    host.post("task", body, {
      headers: { Authorization: token }
    }),
  postTaskRequest: (body: any) =>
    host.post("task/createRequest", body, {
      headers: { Authorization: token }
    }),
  putTaskDetail: (taskId: string, body: any) =>
    host.post(
      "task/assignee",
      {
        task_id: taskId,
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  putTaskAssigned: (assignedId: string, assignedChangeId: string) =>
    host.put(
      `task/assignee/change-assigned/${assignedId}/${assignedChangeId}`,
      null,
      {
        headers: { Authorization: token }
      }
    ),
  putTaskDetailReport: (
    taskId: string,
    reportId: any,
    body: any,
    userId: string
  ) =>
    host.put(
      `task/${taskId}/user/${userId}/reports/${reportId}`,
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  postDetailTaskRemarks: (taskId: string, body: any) =>
    host.post(
      `task/${taskId}/comment`,
      {
        ...body
      },
      {
        headers: { Authorization: token }
      }
    ),
  deleteFileTaskDetail: (taskId: string, fileId: any) =>
    host.delete(`task/${taskId}/delete/file/${fileId}`, {
      headers: { Authorization: token }
    }),
  deleteFileTaskDetailReport: (taskIdReport: any, fileId: any) =>
    host.delete(`task/report/${taskIdReport}/delete/file/${fileId}`, {
      headers: { Authorization: token }
    }),
  postEndTask: (id: string) =>
    host.post(
      `task/${id}/finish`,
      {},
      {
        headers: { Authorization: token }
      }
    ),
  postAvailableDateTaskReport: (id: string, body: any, uuid: string) =>
    host.post(
      `task/${id}/user/${uuid}/reports`,

      body,

      {
        headers: { Authorization: token }
      }
    ),
  getRemarks: (taskId: string) =>
    host.get(`task/${taskId}/remarks`, {
      headers: { Authorization: token }
    }),
  deleteEmployee: (id: string) =>
    host.put(
      `user/employee/${id}`,
      { active: false },
      {
        headers: { Authorization: token }
      }
    ),
  putTaskReportSubmit: (
    id: string,
    reportId: string,
    body: { date: string; description: string },
    uuid: string
  ) =>
    host.put(`task/${id}/user/${uuid}/reports/${reportId}/submit`, body, {
      headers: { Authorization: token }
    }),
  getTemplateEmployee: () =>
    hostV1
      .get("company/employee/import/template", {
        headers: {
          Authorization: token
        },
        responseType: "arraybuffer"
      })
      .then(response => {
        const data = new Uint8Array(response.data);
        const workbook = XLSX.read(data, { type: "array" });
        XLSX.writeFile(workbook, "employee_import_tamplate.xls");
      }),
  validateUploadDataEmployee: (formData: any, progress: (e: string) => void) =>
    hostV1.post("company/employee/import/validate", formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        progress(`${percentCompleted}%`);
      }
    }),
  uploadDataEmployee: (formData: any, progress: (e: string) => void) =>
    hostV1.post("company/employee/import", formData, {
      headers: { Authorization: token, "Content-Type": "multipart/form-data" },
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        progress(`${percentCompleted}%`);
      }
    }),
  getPolicyOwned: () =>
    host.get("policy/policy/owned", {
      headers: { Authorization: token }
    }),
  getOnbehalf: () =>
    host.get("company/on-behalf", {
      headers: { Authorization: token }
    }),
  postSupport: (body: any) =>
    hostV1.post("help-and-support", body, {
      headers: { Authorization: token }
    }),
  postOnBehalf: (body: any) =>
    hostV1.post("company/on-behalf", body, {
      headers: { Authorization: token }
    }),
  getOnbehalfById: (id: string) =>
    host.get(`company/on-behalf/${id}`, {
      headers: { Authorization: token }
    }),
  putOnBehalf: (body: any, id: string) =>
    hostV1.put(`company/on-behalf/${id}`, body, {
      headers: { Authorization: token }
    }),
  putOnbehalfActive: (id: string) =>
    hostV1.put(
      `company/on-behalf/${id}/set-active/${0}`,
      {},
      {
        headers: { Authorization: token }
      }
    ),
  getTaskPrint: (id: string) =>
    host.get(`task/${id}/reports`, {
      headers: { Authorization: token }
    }),
  getAnnouncementList: () =>
    host.get("company/announcement", {
      headers: { Authorization: token }
    }),
  getAnnouncementById: (id: string) =>
    host.get(`company/announcement/${id}`, {
      headers: { Authorization: token }
    }),
  postAnnouncement: (body: any) =>
    hostV1.post("company/announcement", body, {
      headers: { Authorization: token }
    }),
  updateAnnouncement: (id: string, body: any) =>
    hostV1.put(`company/announcement/${id}`, body, {
      headers: { Authorization: token }
    }),
  deleteAnnouncement: (id: string) =>
    hostV1.delete(`company/announcement/${id}`, {
      headers: { Authorization: token }
    }),
  getTaskBubbleNotif: () =>
    hostV1.get("dashboard/notifications/task", {
      headers: { Authorization: token }
    }),
  putTaskModeration: (id: string, body: any) =>
    hostV1.put(`moderation/moderation/${id}`, body, {
      headers: { Authorization: token }
    }),
  putTaskEdit: (taskId: string, body: any) =>
    host.put(`task/updateRequest/${taskId}`, body, {
      headers: { Authorization: token }
    }),
  postTaskActivate: (taskId: string) =>
    host.post(
      `task/submitRequest/${taskId}`,
      {},
      {
        headers: { Authorization: token }
      }
    ),
  getLoanSources: () =>
    hostV1.get("finance/loan/sources/get-sources", {
      headers: { Authorization: token }
    }),
  getLoanProfileList: (page: number) =>
    hostV1.get(`finance/loan/profiles/management?page=${page}`, {
      headers: { Authorization: token }
    }),
  getSettingList: (page: number, q: string) =>
    host.get(
      `setting/general?per_page=10&page=${page}&include=updater&q=${q}`,
      {
        headers: { Authorization: token }
      }
    ),

  getSettingDetail: (id: number) =>
    host.get(`setting/general/${id}`, {
      headers: { Authorization: token }
    }),
  postResetSetting: (id: string) =>
    hostV1.post(`setting/general/reset/${id}`, null, {
      headers: { Authorization: token }
    }),
  getSettingLookup: (lookup: string) =>
    host.get(lookup, {
      headers: { Authorization: token }
    }),
  postSetting: (body: any, id: string) =>
    hostV1.post(`setting/general/${id}`, body, {
      headers: { Authorization: token }
    }),
  getLoanSourceById: (id: string) =>
    hostV1.get(`finance/loan/sources/details/${id}`, {
      headers: { Authorization: token }
    }),
  getListUserOnLoan: () =>
    hostV1.get("company/user/populateLoanUser", {
      headers: { Authorization: token }
    }),
  generateLoanProfile: (body: any) =>
    hostV1.post("finance/loan/profiles/generate", body, {
      headers: { Authorization: token }
    }),
  updateLoanProfileStatus: (body: any, uuid: string) =>
    hostV1.put(`finance/loan/profiles/update/${uuid}`, body, {
      headers: { Authorization: token }
    }),
  updateLoanProfileLoanLimit: (body: any, uuid: string) =>
    hostV1.put(`finance/loan/profiles/update/${uuid}`, body, {
      headers: { Authorization: token }
    }),
  updateAllLoanProfileStatus: (body: any) =>
    hostV1.put("finance/loan/profiles/updateAllStatus", body, {
      headers: { Authorization: token }
    }),
  searchTaskByNumber: (number: any) =>
    host.get(`task/search?number=${number}`, {
      headers: { Authorization: token }
    }),
  searchInvoiceByNumber: (number: any) =>
    host.get(`finance/invoice/search?number=${number}`, {
      headers: { Authorization: token }
    }),
  deleteInvoice: (uuid: any) =>
    hostV1.delete(`finance/invoice/${uuid}/admin-delete`, {
      headers: { Authorization: token }
    }),
  deleteTask: (uuid: any) =>
    hostV1.delete(`task/task/${uuid}/admin-delete`, {
      headers: { Authorization: token }
    }),
  getListExpenseType: (page, hidden) =>
    host.get(
      `finance/category?per_page=10&page=${page}&sortField=created_at&sortOrder=desc&hidden=${hidden}&include=updater,parent,unit`,
      {
        headers: { Authorization: token }
      }
    ),
  getListProjectCategory: (page: any) =>
    host.get(
      `project/category?per_page=10&page=${page}&sortField=created_at&sortOrder=desc&hidden=false&include=updater`,
      {
        headers: { Authorization: token }
      }
    ),
  getListActivityCategory: (page: any) =>
    host.get(
      `trip/category/populate?per_page=10&page=${page}&sortField=created_at&sortOrder=desc&include=updater`,
      {
        headers: { Authorization: token }
      }
    ),
  getListPaymentCategory: (page: any) =>
    host.get(
      `djurnee/payment/method?per_page=10&page=${page}&sortField=created_at&sortOrder=desc&include=updater,method,bank`,
      {
        headers: { Authorization: token }
      }
    ),
  searchExpenseType: (page: any, keyword: any) =>
    host.get(
      `finance/category?per_page=10&q=${keyword}&page=${page}&sortField=created_at&sortOrder=desc&hidden=false&include=updater,parent,unit`,
      {
        headers: { Authorization: token }
      }
    ),
  searchProjectCategory: (page: any, keyword: any) =>
    host.get(
      `project/category?per_page=10&q=${keyword}&page=${page}&sortField=created_at&sortOrder=desc&include=updater`,
      {
        headers: { Authorization: token }
      }
    ),
  searchActivityCategory: (page: any, keyword: any) =>
    host.get(
      `trip/category/populate?per_page=10&q=${keyword}&page=${page}&sortField=created_at&sortOrder=desc&include=updater`,
      {
        headers: { Authorization: token }
      }
    ),
  searchPaymentCategory: (page: any, keyword: any) =>
    host.get(
      `djurnee/payment/method?per_page=10&q=${keyword}&page=${page}&sortField=created_at&sortOrder=desc&include=updater,method,bank`,
      {
        headers: { Authorization: token }
      }
    ),
  getExpenseCategoryById: (uuid: any) =>
    host.get(
      `finance/category/${uuid}?id=${uuid}&include=updater,parent,unit`,
      {
        headers: { Authorization: token }
      }
    ),
  getMasterUnit: () =>
    host.get("master/unit", {
      headers: { Authorization: token }
    }),
  getListParent: () =>
    host.get("finance/category/populate?parent=true&hidden=true", {
      headers: { Authorization: token }
    }),
  getProjectCategoryById: (uuid: any) =>
    host.get(`project/category/${uuid}?id=${uuid}&include=updater`, {
      headers: { Authorization: token }
    }),
  getActivityCategoryById: (uuid: any) =>
    host.get(`trip/category/${uuid}?id=${uuid}&include=updater`, {
      headers: { Authorization: token }
    }),
  getMasterBank: () =>
    host.get("master/bank/populate", {
      headers: { Authorization: token }
    }),
  getPaymentCategoryById: (uuid: any) =>
    host.get(
      `djurnee/payment/method/${uuid}?id=${uuid}&include=updater,bank,method`,
      {
        headers: { Authorization: token }
      }
    ),
  getMasterCurrency: () =>
    host.get("master/currency/populate", {
      headers: { Authorization: token }
    }),
  getPopulatePaymentMethod: () =>
    host.get("finance/payment/method/populate", {
      headers: { Authorization: token }
    }),
  editExpenseType: (_form: any, uuid: any) =>
    host.post(`finance/category/${uuid}`, _form, {
      headers: { Authorization: token }
    }),
  createExpenseType: (_form: any) =>
    host.post("finance/category", _form, {
      headers: { Authorization: token }
    }),
  editPaymentCategory: (_form: any, uuid: any) =>
    host.post(`djurnee/payment/method/${uuid}`, _form, {
      headers: { Authorization: token }
    }),
  createPaymentCategory: (_form: any) =>
    host.post("djurnee/payment/method", _form, {
      headers: { Authorization: token }
    }),
  editProjectCategory: (_form: any, uuid: any) =>
    host.post(`project/category/${uuid}`, _form, {
      headers: { Authorization: token }
    }),
  createProjectCategory: (_form: any) =>
    host.post("project/category", _form, {
      headers: { Authorization: token }
    }),
  editActivityCategory: (_form: any, uuid: any) =>
    host.post(`trip/category/${uuid}`, _form, {
      headers: { Authorization: token }
    }),
  createActivityCategory: (_form: any) =>
    host.post("trip/category", _form, {
      headers: { Authorization: token }
    }),
  deleteExpenseType: (uuid: any) =>
    host.delete(`finance/category/${uuid}`, {
      headers: { Authorization: token }
    }),
  deleteProjectCategory: (uuid: any) =>
    host.delete(`project/category/${uuid}`, {
      headers: { Authorization: token }
    }),
  deleteActivityCategory: (uuid: any) =>
    host.delete(`trip/category/${uuid}`, {
      headers: { Authorization: token }
    }),
  deletePaymentCategory: (uuid: any) =>
    host.delete(`djurnee/payment/method/${uuid}`, {
      headers: { Authorization: token }
    }),
  downloadTemplate: () =>
    hostV1.get("finance/category/import/template", {
      headers: { Authorization: token },
      responseType: "blob"
    }),
  importTemplate: (_form: any) =>
    hostV1.post("finance/category/import", _form, {
      headers: { Authorization: token }
    }),
  getParentProject: (uuid: any) =>
    hostV1.get(`project/project/${uuid}/parent`, {
      headers: { Authorization: token }
    })
};

export default api;
