import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import "./styles.scss";

import { Button, Container, Modal } from "../../../../components";
import { IMAGES } from "../../../../configs";
import {
  deleteSalary,
  getSalaryByEmployee,
  getSalaryById,
  setModalEditSalary
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { Permission, maskedMoney } from "../../../../utils";
import { ModalFormSalary } from "..";

interface Props {
  employee: string;
}

const TableContracts = ({ employee }: Props) => {
  const dispatch = useDispatch();
  const permission = {
    list: Permission("employee-others-list"),
    others: Permission("employee-others-view"),
    edit: Permission("employee-others-edit")
  };
  const { t } = useTranslation();

  const [isSetUpdate, setUpdate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [salaryId, setSalaryId] = useState("");

  const { employeeState, salaryState, homeState } = useSelector(
    (state: Reducers) => ({
      company: state.company,
      employeeState: state.employee,
      salaryState: state.employee.staff.listSalary,
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSalaryByEmployee(employee));
    if (!employeeState.isDeleteSalary && isDelete) {
      setDelete(false);
      dispatch(getSalaryByEmployee(employee));
      setDeleteModal(false);
    }
  }, [dispatch, employee, employeeState.isDeleteSalary, isDelete]);

  const _statusColor = (status: boolean) => {
    let color = "";
    if (status === false) {
      color = "color-y";
    } else {
      color = "color-g";
    }
    return color;
  };

  const statusDesc = (status: boolean) => {
    let label = "";
    if (status === true) {
      label = t("personnel.employeeDetail.tbl.active");
    } else {
      label = t("personnel.employeeDetail.tbl.notActive");
    }
    return label;
  };

  const _deleteSalary = (id: string) => {
    if (!isDelete) {
      setDelete(true);
      dispatch(
        deleteSalary(id, e => {
          setMessage(e);
          setWarning(true);
          setDeleteModal(false);
        })
      );
      setDeleteModal(false);
    }
  };

  return (
    <>
      <Container isLoading={employeeState.isLoading} style={{ marginTop: 70 }}>
        {salaryState.length > 0 ? (
          <table className="data-table">
            <thead>
              <tr>
                <th>{t("personnel.employeeDetail.tbl.contractNo")}</th>
                <th>{t("personnel.employeeDetail.tbl.amount")}</th>
                <th colSpan={2}>{t("personnel.employeeDetail.tbl.date")}</th>
                <th>{t("personnel.employeeDetail.tbl.remarks")}</th>
                <th colSpan={2} style={{ paddingLeft: "46px" }}>
                  {t("personnel.employeeDetail.tbl.status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {salaryState.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="col-subject">
                    <p>
                      {(item.attributes && item.attributes.contract_number) ||
                        "N/a"}
                    </p>
                    {/* {permission.self || permission.others ? (
                      <Link
                        style={{ color: "#db3a26" }}
                        to={`/company/salary/detail/${item.id}`}
                      >
                        <p>{item.number}</p>
                      </Link>
                    ) : (
                      <p>{item.number}</p>
                    )} */}
                  </td>
                  <td className="col-subject">
                    <div>
                      {`${item.attributes.currency_code} ${maskedMoney(
                        item.attributes.amount
                      )}`}
                    </div>
                  </td>
                  <td className="grey">
                    <div>{t("personnel.table.startDate")}</div>
                    <div>{t("personnel.table.endDate")}</div>
                  </td>
                  <td className="col-date">
                    <div>
                      {moment(
                        item.attributes && item.attributes.started_at
                      ).format("ddd, DD MMM YYYY")}
                    </div>
                    <div>
                      {moment(
                        item.attributes && item.attributes.finished_at
                      ).format("ddd, DD MMM YYYY")}
                    </div>
                  </td>
                  <td className="col-remarks">
                    <p className="table-list-ellipsis">
                      {item.attributes && item.attributes.remarks}
                    </p>
                  </td>
                  <td className="col-status">
                    <div
                      className={`status ${_statusColor(
                        item.attributes.active
                      )}`}
                    >
                      <div className="table-list-status-td">
                        <p className="bold">
                          {statusDesc(item.attributes.active)}
                        </p>
                      </div>
                      <div className="table-list-action actions">
                        {permission.edit ||
                        homeState.profile.listRole.includes(
                          "personal-loan-hr"
                        ) ? (
                          <>
                            <Button
                              onPress={() => {
                                dispatch(getSalaryById(item.attributes.uuid));
                                setUpdate(true);
                                dispatch(setModalEditSalary(true));
                              }}
                            >
                              <img
                                src={IMAGES.pencil}
                                alt="n/a"
                                style={{ height: 50, width: 50 }}
                                className="pointer"
                              />
                            </Button>
                            <Button
                              onPress={() => {
                                setSalaryId(item.attributes.uuid);
                                setDeleteModal(true);
                              }}
                            >
                              <img
                                src={IMAGES.deleteRound}
                                alt="n/a"
                                style={{ height: 50, width: 50 }}
                                className="pointer"
                              />
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="table-empty-data">{t("emptyData")}</div>
        )}
      </Container>
      <ModalFormSalary employee={employee} isUpdate={isSetUpdate} />
      <Modal
        warning
        btnSubmitText={employeeState.isDeleteSalary ? "LOADING" : "SUBMIT"}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModal}
        onPressCancel={() => setDeleteModal(false)}
        message={String(t("personnel.form.deleteMsg"))}
        onPressSubmit={() => _deleteSalary(salaryId)}
      />
      <Modal
        warning
        titleModal="WARNING"
        isOpen={warning}
        onPressCancel={() => setWarning(false)}
        message={message}
        mandatory
      />
    </>
  );
};

export default memo(TableContracts);
