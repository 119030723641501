/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import "./styles.scss";
import moment from "moment";
import {
  Button,
  Container,
  Modal,
  Spinner,
  Text
} from "../../../../components";
import { IMAGES } from "../../../../configs";
import {
  PaginationBackEmployee,
  PaginationForwardEmployee
} from "../../../../assets";
import { ModalEditStaff } from "..";

import { Reducers } from "../../../../redux/types";
import {
  deleteEmployee,
  getEmployee,
  resetEmployeeProfile,
  updateLoanProfileStatus
} from "../../../../redux/actions";
import { Permission, Roles } from "../../../../utils";

interface Props {
  showHidden: boolean;
  showHiddenOurSource: boolean;
  showDisabled: boolean;
  q: string;
}

const TableStaff = ({
  showHidden,
  showHiddenOurSource,
  showDisabled,
  q
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { sprintf } = require("sprintf-js");

  const { generateState } = useSelector(
    (state: Reducers) => ({
      generateState: state.generate,
      employeeState: state.employee
    }),
    shallowEqual
  );
  const [modalOpen, setModalOpen] = useState({ visible: false, id: "" });
  const [modalEdit, setModalEdit] = useState(false);
  const [modalAdd, setModalAdd] = useState(false);

  const [modalApproveLoanProfile, setModalApproveLoanProfile] = useState(false);
  const [modalDeclineLoanProfile, setModalDeclineLoanProfile] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [loanProfileId, setLoanProfileId] = useState("");

  const permission = {
    employeeSelfEdit: Permission("employee-self-edit"),
    employeeOthersEdit: Permission("employee-others-edit"),
    employeeOthersView: Permission("employee-others-view"),
    employeeSelfDel: Permission("employee-self-delete"),
    employeeOthersDel: Permission("employee-others-delete")
  };

  const roles = {
    su: Roles("superadmin"),
    hr: Roles("hr"),
    loanHr: Roles("personal-loan-hr")
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const statusDesc = (status: number) => {
  //   let label = "";
  //   if (status === 5) {
  //     label = t("status.eligible");
  //   } else if (status === 6) {
  //     label = t("status.nonEligible");
  //   } else {
  //     label = "";
  //   }
  //   return label;
  // };

  const _openModalAdd = useCallback(() => {
    dispatch(resetEmployeeProfile());
    setModalAdd(true);
  }, [dispatch]);

  const _deleteEmployee = useCallback(() => {
    dispatch(
      deleteEmployee(modalOpen.id, () =>
        setModalOpen({ visible: false, id: "" })
      )
    );
  }, [dispatch, modalOpen.id]);

  // const _checkPersonalLoanUsers = useCallback(() => {
  //   const res = homeState.profile.listRole.find((el: any) =>
  //     el.includes("personal-loan")
  //   );
  //   if (res || roles.su) {
  //     return true;
  //   }
  //   return false;
  // }, [homeState.profile.listRole, roles.su]);

  // const _checkPersonalLoanUsersOnList = useCallback((item: any) => {
  //   const res = item.roles.find((el: any) => el.name.includes("personal-loan"));
  //   if (res) {
  //     return true;
  //   }
  //   return false;
  // }, []);

  const _checkEditPermission = useCallback(
    (item: any) => {
      const res = item.roles.find((el: any) =>
        el.name.includes("personal-loan")
      );
      if (roles.hr || roles.su) {
        return true;
      }
      if (roles.loanHr && res) {
        return true;
      }
      if (roles.loanHr && !res) {
        return false;
      }
      if (
        (!roles.loanHr || !roles.hr) &&
        !res &&
        (permission.employeeSelfEdit ||
          permission.employeeOthersEdit ||
          permission.employeeOthersView)
      ) {
        return true;
      }
      if (
        (!roles.loanHr || !roles.hr) &&
        res &&
        (permission.employeeSelfEdit ||
          permission.employeeOthersEdit ||
          permission.employeeOthersView)
      ) {
        return false;
      }
      return false;
    },
    [
      permission.employeeOthersEdit,
      permission.employeeOthersView,
      permission.employeeSelfEdit,
      roles.hr,
      roles.loanHr,
      roles.su
    ]
  );

  const _checkDeletePermission = useCallback(
    (item: any) => {
      const res = item.roles.find((el: any) =>
        el.name.includes("personal-loan")
      );
      if (roles.hr || roles.su) {
        return true;
      }
      if (roles.loanHr && !res) {
        return false;
      }
      return false;
    },
    [roles.hr, roles.loanHr, roles.su]
  );

  const _updateLoanProfileStatus = (id: string, status: number) => {
    dispatch(
      updateLoanProfileStatus(status, id, () => {
        setModalApproveLoanProfile(false);
        setModalDeclineLoanProfile(false);
        setSelectedEmployee("");
        setLoanProfileId("");
      })
    );
    dispatch(
      getEmployee(
        generateState.activeDepartment.id || "",
        generateState.activePosition.id || "",
        generateState.activePersonil.id || "",
        generateState.pagination.current_page,
        showHidden,
        q, // q
        false, // parent
        false, // show_self
        showHiddenOurSource, // hiddenOutSource
        showDisabled // disable
      )
    );
  };

  const _renderList = useCallback(
    (item: any, index: number, showOutSource: boolean) => (
      <tr
        className={`employee-row ${
          item.outsource ? "employee-row-outsource" : ""
        }`}
        key={index}
      >
        <td
          className="employee-component-table-body-text"
          style={{
            textAlign: "left",
            textOverflow: "ellipsis",
            overflow: "hidden",
            wordBreak: "break-all"
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <img
                src={(item.avatar && item.avatar.url) || IMAGES.avatar}
                alt="person"
                className="dasboard-employee-list-photo shadow"
              />
            </div>
            <div style={{ width: "90%" }}>
              <Text className="text-elipsis">{item.name}</Text>
              <Text style={{ fontSize: 12, marginTop: 4 }}>{item.number}</Text>
            </div>
          </div>
        </td>

        <td
          className="employee-component-table-body-text"
          style={{ textAlign: "left" }}
        >
          <div>
            <div style={{ display: "flex" }}>
              <Text className="text-elipsis" style={{ marginRight: 8 }}>
                {item.position_name}
              </Text>
              {item.special_roles.length > 0 && (
                <div style={{ display: "flex" }}>
                  {item.special_roles.map(
                    (itemRole: any, indexRole: number) => (
                      <Text
                        key={indexRole}
                        className="dasboard-employee-list-status"
                      >
                        {itemRole.name}
                      </Text>
                    )
                  )}
                </div>
              )}
            </div>
            <Text style={{ fontSize: 12, marginTop: 4 }}>
              {item.division.title}
            </Text>
          </div>
        </td>

        <td className="employee-component-table-body-text">
          <div>
            <Text className="text-elipsis">
              {item.primaryUser && item.primaryUser.email}
            </Text>
            <Text style={{ fontSize: 12, marginTop: 4 }}>
              {(item.addresses.length > 0 && item.addresses[0].address.phone) ||
                ""}
            </Text>
          </div>
        </td>

        {/* {_checkPersonalLoanUsers() ? (
          <td className="employee-component-table-body-text">
            <div>
              {_checkPersonalLoanUsersOnList(item) && item.loanProfile && (
                <>
                  <div style={{ display: "flex" }}>
                    <Text
                      className={`dasboard-employee-list-status ${
                        item.loanProfile.status === 6 ? "red" : ""
                      }`}
                    >
                      {item.loanProfile
                        ? statusDesc(item.loanProfile.status)
                        : ""}
                    </Text>
                  </div>
                  <Text style={{ marginTop: 4 }}>
                    <strong>{t("personnel.table.plafond")}</strong>
                    &nbsp;
                    {item.loanProfile ? item.loanProfile.currency_code : ""}
                    &nbsp;
                    {item.loanProfile
                      ? maskedMoney(item.loanProfile.amount_max)
                      : ""}
                  </Text>
                </>
              )}
            </div>
          </td>
        ) : null} */}

        {showOutSource ? (
          <td className="employee-component-table-body-text">
            {item.started_at ? (
              <Text>
                <strong>{t("personnel.table.startDate")}</strong>
                &nbsp;
                {item.started_at || "-"}
              </Text>
            ) : (
              "-"
            )}
            {item.expired_at && (
              <Text>
                <strong>{t("personnel.table.endDate")}</strong>
                &nbsp;
                {item.expired_at || "-"}
              </Text>
            )}
            <Text style={{ color: "red", fontWeight: "bold" }}>
              {item.expired_at &&
              new Date(item.expired_at) < new Date() &&
              moment(String(item.expired_at)).format("DD-MM-YYYY") !==
                moment(String(new Date())).format("DD-MM-YYYY")
                ? "Expired"
                : ""}
            </Text>
          </td>
        ) : null}
        <td className="employee-component-table-body-text">
          <div className="buttons">
            {_checkEditPermission(item) && (
              <Button
                onPress={() => history.push(`/employee-detail/${item.id}`)}
              >
                <img
                  className="employee-row-button"
                  src={IMAGES.pencil}
                  alt="Edit Employee"
                />
              </Button>
            )}
            {!item.is_disabled && _checkDeletePermission(item) && (
              <Button
                onPress={() => setModalOpen({ visible: true, id: item.id })}
              >
                <img
                  className="employee-row-button"
                  src={IMAGES.deleteRound}
                  alt="Delete Employee"
                />
              </Button>
            )}
          </div>
        </td>
      </tr>
    ),
    [t, _checkEditPermission, _checkDeletePermission, history]
  );
  return (
    <div className="employee-component-table-container">
      <Container
        // isLoading={generateState.isLoadingListEmployee}
        style={{ marginTop: 60 }}
      >
        <table
          style={{
            height: "30%",
            width: "100%",
            maxWidth: 1600,
            display: "table",
            tableLayout: "fixed",
            position: "relative"
          }}
          cellSpacing="0"
          cellPadding="0"
        >
          <thead>
            <tr className="employee-component-table-header">
              <th
                className={`employee-component-table-header-text ${
                  showHiddenOurSource ? "name-outsource" : "name"
                }`}
              >
                {t("personnel.table.name")}
              </th>
              <th
                className={`employee-component-table-header-text ${
                  showHiddenOurSource ? "position-outsource" : "position"
                }`}
              >
                {t("personnel.table.position")}
              </th>
              <th
                className={`employee-component-table-header-text ${
                  showHiddenOurSource ? "contact-outsource" : "contact"
                }`}
              >
                {t("personnel.table.contact")}
              </th>
              {/* {_checkPersonalLoanUsers() ? (
                <th
                  className={`employee-component-table-header-text ${
                    showHiddenOurSource ? "loan-outsource" : "loan"
                  }`}
                >
                  {t("personnel.table.personalLoan")}
                </th>
              ) : null} */}

              {showHiddenOurSource ? (
                <th className="employee-component-table-header-text date-outsource">
                  {t("personnel.table.duration")}
                </th>
              ) : null}

              <th className="employee-component-table-header-text paging">
                <Text style={{ display: "inline-block", fontWeight: "normal" }}>
                  {`${generateState.pagination.from || "0"} - ${generateState
                    .pagination.to || "0"} ${t(
                    "personnel.table.of"
                  )} ${generateState.pagination.total || "0"}`}
                </Text>
                {generateState.pagination.current_page !== 1 ? (
                  <Button
                    style={{ display: "inline-block", marginLeft: 10 }}
                    onPress={() =>
                      dispatch(
                        getEmployee(
                          generateState.activeDepartment.id || "",
                          generateState.activePosition.id || "",
                          generateState.activePersonil.id || "",
                          generateState.pagination.current_page - 1,
                          showHidden,
                          q, // q
                          false, // parent
                          false, // show_self
                          showHiddenOurSource, // hiddenOutSource
                          showDisabled // disable
                        )
                      )
                    }
                  >
                    <PaginationBackEmployee />
                  </Button>
                ) : (
                  <></>
                )}
                {generateState.pagination.current_page !==
                generateState.pagination.last_page ? (
                  <Button
                    style={{ display: "inline-block", marginLeft: 10 }}
                    onPress={() =>
                      dispatch(
                        getEmployee(
                          generateState.activeDepartment.id || "",
                          generateState.activePosition.id || "",
                          generateState.activePersonil.id || "",
                          generateState.pagination.current_page + 1,
                          showHidden,
                          q, // q
                          false, // parent
                          false, // show_self
                          showHiddenOurSource, // hiddenOutSource
                          showDisabled // disable
                        )
                      )
                    }
                  >
                    <PaginationForwardEmployee />
                  </Button>
                ) : (
                  <></>
                )}
              </th>
            </tr>
          </thead>

          {generateState.listEmployee.length > 0 ? (
            <tbody className="global-scroll-bar">
              {generateState.listEmployee.map((item: any, index: number) =>
                !showHidden
                  ? !item.is_hidden &&
                    _renderList(item, index, showHiddenOurSource)
                  : _renderList(item, index, showHiddenOurSource)
              )}
            </tbody>
          ) : (
            <div className="employee-isloading">
              {generateState.isLoadingListEmployee ? (
                <Spinner />
              ) : (
                <div>Empty Data</div>
              )}
            </div>
          )}
        </table>
      </Container>

      <Container withMaxWidth flex className="employee-footer-button">
        {Permission("employee-create") && (
          <Button onPress={_openModalAdd}>
            <img
              className="employee-button"
              src={IMAGES.floatingPlusButton}
              alt="print"
            />
          </Button>
        )}
      </Container>

      <Modal
        warning
        titleModal={`${t("btn.delete")} ITEM`}
        isOpen={modalOpen.visible}
        message
        onPressCancel={() => setModalOpen({ visible: false, id: "" })}
        onPressSubmit={_deleteEmployee}
        btnSubmitText={t("btn.submit")}
        btnCancelText={t("btn.cancel")}
      />

      <ModalEditStaff
        isOpen={modalEdit}
        onPressCancel={() => setModalEdit(false)}
      />

      <ModalEditStaff
        isOpen={modalAdd}
        onPressCancel={() => setModalAdd(false)}
        isNew
      />
      <Modal
        isOpen={modalApproveLoanProfile}
        warning
        onPressCancel={() => {
          setModalApproveLoanProfile(false);
          setSelectedEmployee("");
          setLoanProfileId("");
        }}
        onPressSubmit={() => _updateLoanProfileStatus(loanProfileId, 5)}
        btnSubmitText={t("btn.yes")}
        btnCancelText={t("btn.cancel")}
      >
        <div style={{ textAlign: "center" }}>
          <Trans>
            {sprintf(t("personnel.message.approveSingle"), selectedEmployee)}
          </Trans>
        </div>
      </Modal>
      <Modal
        isOpen={modalDeclineLoanProfile}
        warning
        onPressCancel={() => {
          setModalDeclineLoanProfile(false);
          setSelectedEmployee("");
          setLoanProfileId("");
        }}
        onPressSubmit={() => _updateLoanProfileStatus(loanProfileId, 6)}
        btnSubmitText={t("btn.yes")}
        btnCancelText={t("btn.cancel")}
      >
        <div style={{ textAlign: "center" }}>
          <Trans>
            {sprintf(t("personnel.message.declineSingle"), selectedEmployee)}
          </Trans>
        </div>
      </Modal>
      {/* <div className="employee-component-table-action">
        <div>
          <img style={{ opacity: 0.3 }} src={ICONS.btnMessage} alt="print" />
        </div>
        <Button onPress={downloadXML}>
          <img title="Download XML" src={ICONS.btnCopy} alt="copy" />
        </Button>
        <Button onPress={downloadCSV}>
          <img title="Download CSV" src={ICONS.btnExcel} alt="message" />
        </Button>
        <div>
          <img style={{ opacity: 0.3 }} src={ICONS.btnPrint} alt="print" />
        </div>
      </div> */}
    </div>
  );
};

export default memo(TableStaff);
