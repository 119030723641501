/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Scrollbars } from "react-custom-scrollbars";
import { useForm } from "react-hook-form";

import {
  Autocomplete,
  Button,
  Card,
  Container,
  Footer,
  HeaderTittle,
  Modal,
  ModalDepartment,
  Text
} from "../../../components";
import {
  addDepartment,
  deleteDepartment,
  deletePosition,
  getDepartmentDetail,
  getDepartmentPosition,
  getDetailDepartment,
  getMyPermission,
  getPositionDetail,
  getRoleByPosition,
  getRoleSpecial,
  handleTheme,
  listDepartment,
  listDivisionClass,
  listPosition,
  positionAdd,
  putDepartment,
  updatePosition
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { ICONS, IMAGES } from "../../../configs";
import "./styles.scss";
import { Permission, copyWritings, documentTitle } from "../../../utils";
import { Navigation } from "../components/Navigation";

const Department = () => {
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const labelDept = copyWritings("department");
  const addPosisi: any = {
    department_id: "",
    parent_id: "",
    title: "",
    division_id: "",
    roles: []
  };

  const addDepartemen: any = {
    code: "",
    description: "",
    title: ""
  };

  documentTitle("Company - Department");
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const [modalPosition, setModalPosition] = useState(false);
  const [modalDepartment, setModalDepartment] = useState(false);
  const [firstTimeOnly, setFirstTimeOnly] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalDept, setDeleteModalDept] = useState(false);
  const [modalEditPostiton, setEditPosition] = useState(false);
  const [modalEditDepart, setEditDepart] = useState(false);
  const [addPosition, setPosition] = useState(addPosisi);
  const [departemenAdd, setDepartment] = useState(addDepartemen);
  const [mandatory, setMandatory] = useState(false);
  const [mandatoryMsg, setMandatoryMsg] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [uuid, setId] = useState("");
  const [copyFrom, setCopyFrom] = useState("");
  const [autoComplete, setAutoComplete] = useState(null);
  const [validateForm, setValidateForm] = useState(false);
  const permission = {
    otherList: Permission("on-behalf-others-list"),
    ownedList: Permission("on-behalf-owned-list"),
    divisionOtherList: Permission("division-others-list"),
    positionOtherList: Permission("position-others-list"),
    positioCreate: Permission("position-create"),
    positionOtherEdit: Permission("position-others-edit"),
    divisionCreate: Permission("division-create"),
    divisionOtherEdit: Permission("division-others-edit")
  };

  const departmentState = useSelector((state: Reducers) => state.company);

  useEffect(() => {
    dispatch(getMyPermission());
  }, [dispatch]);

  useEffect(() => {
    if (firstTimeOnly) {
      dispatch(handleTheme("light"));
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
      setFirstTimeOnly(false);
    }

    if (
      !departmentState.isLoadAddPosition &&
      isSubmit &&
      departmentState.loadAddPosition
    ) {
      setIsSubmit(false);
      setPosition(addPosisi);
      setModalPosition(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }

    if (
      !departmentState.isDeletePosition &&
      isSubmit &&
      departmentState.deletePosition
    ) {
      setIsSubmit(false);
      setPosition(addPosisi);
      setDeleteModal(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }

    if (
      !departmentState.isUpdatePosition &&
      isSubmit &&
      departmentState.updatePosition
    ) {
      setIsSubmit(false);
      setPosition(addPosisi);
      setEditPosition(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }

    if (
      !departmentState.isAddDepartment &&
      isSubmit &&
      departmentState.loadAddDepartment
    ) {
      setIsSubmit(false);
      setDepartment(addDepartemen);
      setModalDepartment(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }

    if (
      !departmentState.isUpdateDepartment &&
      isSubmit &&
      departmentState.loadUpdateDepartment
    ) {
      setIsSubmit(false);
      setDepartment(addDepartemen);
      setEditDepart(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }

    if (
      !departmentState.isDeletePosition &&
      isSubmit &&
      departmentState.loadDeleteDepartment
    ) {
      setIsSubmit(false);
      setDeleteModalDept(false);
      dispatch(getDepartmentDetail());
      dispatch(getDepartmentPosition());
    }
  }, [
    dispatch,
    setFirstTimeOnly,
    firstTimeOnly,
    departmentState.loadAddPosition,
    departmentState.isLoadAddPosition,
    departmentState.isDeletePosition,
    departmentState.deletePosition,
    departmentState.isUpdatePosition,
    departmentState.updatePosition,
    departmentState.isAddDepartment,
    departmentState.loadAddDepartment,
    departmentState.loadUpdateDepartment,
    departmentState.isUpdateDepartment,
    departmentState.loadDeleteDepartment,
    departmentState.isDeleteDepartment,
    addDepartemen,
    addPosisi,
    isSubmit
  ]);

  const role = departmentState.loadRole.map((e: any) => {
    return { value: e.id, label: e.attributes.title };
  });

  const _openModal = () => {
    setModalPosition(true);
    dispatch(listDivisionClass());
    dispatch(listPosition());
    dispatch(listDepartment());
    dispatch(getRoleSpecial());
    setPosition(addPosisi);
    setCopyFrom("");
  };

  const _openModalEdit = (id: string) => {
    setEditPosition(true);
    setCopyFrom("");
    dispatch(listDivisionClass());
    dispatch(listPosition());
    dispatch(listDepartment());
    dispatch(getRoleSpecial());
    dispatch(
      getPositionDetail(id, e => {
        let detail: any[] = [];
        e.roles.map(p => {
          detail = [...detail, { id: p.id, name: p.name }];
          return true;
        });

        setPosition({
          ...addPosition,
          roles: [...addPosition.roles, ...detail],
          department_id: e.department?.id,
          parent_id: e.parent?.id,
          title: e.title,
          division_id: e.division?.id
        });
      })
    );
  };

  const _openModalEditDepart = (id: string) => {
    setEditDepart(true);
    dispatch(
      getDetailDepartment(id, e => {
        setDepartment({
          ...departemenAdd,
          title: e?.attributes?.title,
          description: e?.attributes?.description,
          code: e?.attributes?.code || ""
        });
      })
    );
  };

  const _getPositionId = (id: any) => {
    dispatch(
      getRoleByPosition(id, e => {
        setPosition({
          ...addPosition,
          roles: [...addPosition.roles, ...e]
        });
      })
    );
    setCopyFrom(id);
  };

  const _positionAdd = () => {
    setValidateForm(true);
    if (
      !isSubmit &&
      addPosition.title &&
      addPosition.division_id &&
      addPosition.roles.length >= 1
    ) {
      setIsSubmit(true);
      // setModalPosition(false);
      let posisi: any[] = [];
      addPosition.roles.map((e: any) => {
        posisi = [...posisi, { id: e.id }];
        return true;
      });
      dispatch(positionAdd({ ...addPosition, roles: posisi }));
      setValidateForm(false);
      setModalPosition(false);
    }
  };

  const _deletePosition = (id: string) => {
    if (!isSubmit) {
      setIsSubmit(true);
      dispatch(
        deletePosition(id, e => {
          setMandatoryMsg(e);
          setMandatory(true);
          setDeleteModal(false);
        })
      );
      setDeleteModal(false);
    }
  };

  const _updatePosition = (id: string) => {
    if (!isSubmit) {
      setIsSubmit(true);
      setEditPosition(false);
      let posisi: any[] = [];
      addPosition.roles.map((e: any) => {
        posisi = [...posisi, { id: e.id }];
        return true;
      });
      dispatch(updatePosition(id, { ...addPosition, roles: posisi }));
    }
  };

  const _departmentAdd = () => {
    if (!isSubmit) {
      setIsSubmit(true);
      setModalDepartment(false);
      dispatch(addDepartment(departemenAdd));
    }
  };

  const _updateDepartment = (id: string) => {
    if (!isSubmit) {
      setIsSubmit(true);
      setEditDepart(false);
      dispatch(putDepartment(id, departemenAdd));
    }
  };

  const _deleteDepartment = (id: string) => {
    if (!isSubmit) {
      setIsSubmit(true);
      dispatch(
        deleteDepartment(
          id,
          () => setDeleteModalDept(false),
          e => {
            setMandatory(true);
            setMandatoryMsg(e);
            setDeleteModalDept(false);
          }
        )
      );
    }
  };

  const _deleteRole = (id: string) => {
    setPosition({
      ...addPosition,
      roles: addPosition.roles.filter((s: any) => {
        return s.id !== id;
      })
    });
  };

  const _autocompleteRole = (value: any) => {
    setAutoComplete(value.value);
    const complete = { id: value.value, name: value.label };
    setPosition({ ...addPosition, roles: [...addPosition.roles, complete] });
  };

  const _handleFormValidation = useCallback(
    (isShowError: boolean, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  return (
    <>
      <Container scroll className="page-company-department" withMaxWidth>
        <HeaderTittle title={copyWritings("company")} />
        <Navigation />
        <div className="columns is-gapless is-8r">
          <div className="column is-one-third">
            <div className="department-tittle">
              {copyWritings("department").toUpperCase()}
            </div>
            <div style={{ marginRight: 36 }}>
              <Container isLoading={departmentState.isLoadingDepartment}>
                {!departmentState.isLoadingDepartment && (
                  <Scrollbars
                    autoHeight
                    autoHeightMax={310}
                    style={{
                      width: "100%",
                      marginRight: 20
                    }}
                  >
                    {departmentState.loadDepartment.map(
                      (item: any, index: number) => (
                        <Card
                          key={index}
                          className="department-card"
                          style={{ marginRight: 20 }}
                        >
                          <div>
                            <div className="card-text">
                              {item.attributes.title}
                            </div>
                            {permission.divisionOtherEdit && (
                              <img
                                src={IMAGES.pencil}
                                alt="poh"
                                className="card-row-button one"
                                onClick={() => {
                                  _openModalEditDepart(item.id);
                                  setId(item.id);
                                }}
                              />
                            )}
                            {permission.divisionOtherEdit && (
                              <img
                                src={IMAGES.deleteRound}
                                alt="del"
                                className="card-row-button two"
                                onClick={() => {
                                  setDeleteModalDept(true);
                                  setId(item.id);
                                }}
                              />
                            )}
                          </div>
                        </Card>
                      )
                    )}
                  </Scrollbars>
                )}
              </Container>
            </div>
            <div>
              {permission.divisionCreate && (
                <Button onPress={() => setModalDepartment(true)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row"
                    }}
                  >
                    <img src={ICONS.btnAdd} alt="add" />
                    <div
                      style={{ paddingTop: 15 }}
                      className="company-department-detail-button-add"
                    >
                      {sprintf(t("btn.addDept"), labelDept.toUpperCase())}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          </div>
          <div className="column">
            <table className="department-table" cellSpacing="0" cellPadding="0">
              <thead>
                <tr>
                  <th className="department-tittle">&nbsp;</th>
                  <th className="department-tittle table-position">
                    {t("personnel.table.position")}
                  </th>
                  <th className="department-tittle table-position">
                    {`${copyWritings("report").toUpperCase()} ${t(
                      "to"
                    ).toUpperCase()}`}
                  </th>
                  <th className="department-tittle">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <Container isLoading={departmentState.isLoadDepartmentPost}>
                  {departmentState.loadDepartmentPost.map(
                    (pos: any, index: number) => (
                      <tr key={index}>
                        <td className="position">
                          <div>
                            {pos.division.title !== null
                              ? pos.division.title
                              : "-"}
                          </div>
                        </td>
                        <td className="role">
                          {pos.title !== null ? pos.title : "-"}
                        </td>
                        <td className="report-to">
                          {pos.parent_name !== null ? pos.parent_name : "-"}
                        </td>
                        <td className="buttons-department">
                          {permission.positionOtherEdit && (
                            <img
                              src={IMAGES.pencil}
                              alt="print"
                              className="department-row-button"
                              onClick={() => {
                                _openModalEdit(pos.id);
                                setId(pos.id);
                              }}
                            />
                          )}
                          {permission.positionOtherEdit && (
                            <img
                              src={IMAGES.deleteRound}
                              alt="jelo"
                              className="department-row-button"
                              onClick={() => {
                                setDeleteModal(true);
                                setId(pos.id);
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </Container>
              </tbody>
            </table>

            <div>
              {permission.positioCreate && (
                <Button onPress={() => _openModal()}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img src={ICONS.btnAdd} alt="add" />
                    <div
                      style={{ paddingTop: 15 }}
                      className="company-department-detail-button-add"
                    >
                      {t("btn.addPosition")}
                    </div>
                  </div>
                </Button>
              )}
            </div>
          </div>
        </div>
      </Container>
      <Footer isButton={false} />

      {/* modal position */}
      <Modal
        titleModal={t("company.form.addDeptPosition")}
        isOpen={modalPosition}
        onPressCancel={() => {
          setPosition(addPosisi);
          setModalPosition(false);
          setValidateForm(false);
        }}
        btnSubmitText={
          departmentState.isLoadAddPosition ? "LOADING" : t("btn.add")
        }
        btnCancelText={t("btn.cancel")}
        onPressSubmit={() => _positionAdd()}
      >
        <form className="form-position" onSubmit={handleSubmit(_positionAdd)}>
          <div style={{ display: "flex" }}>
            <div style={{ width: 380, marginRight: 80 }}>
              <div style={{ marginBottom: 16, fontWeight: "bold" }}>
                {t("company.form.positionName")}
              </div>
              <input
                className="component-modal-input"
                value={addPosition.title}
                onChange={e =>
                  setPosition({ ...addPosition, title: e.target.value })
                }
              />
              {_handleFormValidation(
                !addPosition.title && validateForm,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("company.form.positionName")
                )
              )}
              <div
                style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
              >
                {copyWritings("department")}
              </div>
              <select
                className="component-modal-input-dropdown"
                value={addPosition.department_id}
                onChange={e =>
                  setPosition({ ...addPosition, department_id: e.target.value })
                }
              >
                <option value="" disabled hidden selected>
                  {sprintf(
                    t("company.form.selectDept"),
                    copyWritings("department")
                  )}
                </option>
                {departmentState.listDepartment.map(
                  (item: any, index: number) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
              <div className="component-modal-line" />
              {_handleFormValidation(
                !addPosition.department_id && validateForm,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.department")
                )
              )}
              <div
                style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
              >
                {t("company.form.class")}
              </div>
              <select
                name="divisionId"
                className="component-modal-input-dropdown"
                value={addPosition.division_id}
                onChange={e =>
                  setPosition({ ...addPosition, division_id: e.target.value })
                }
                ref={register({ required: true })}
              >
                <option value="" disabled selected hidden>
                  {t("company.form.selectClass")}
                </option>
                {departmentState.listClass.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>

              <div className="component-modal-line" />
              {_handleFormValidation(
                !addPosition.division_id && validateForm,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("company.form.class")
                )
              )}
              <div
                style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
              >
                {`${copyWritings("report")} ${t("to")}`}
              </div>
              <select
                name="parentId"
                className="component-modal-input-dropdown"
                value={addPosition.parent_id}
                onChange={e =>
                  setPosition({ ...addPosition, parent_id: e.target.value })
                }
                ref={register({ required: true })}
              >
                {departmentState.listPosition.map(
                  (item: any, index: number) => (
                    <option key={index} value={item.value}>
                      {item.label}
                    </option>
                  )
                )}
              </select>
              <div className="component-modal-line" />
            </div>
            <div style={{ width: 380 }}>
              <div style={{ marginBottom: 16, fontWeight: "bold" }}>
                {copyWritings("role")}
              </div>
              <div className="global-shadow-card components-add-position-form">
                <div
                  style={{
                    width: "90%",
                    height: "92%"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12
                    }}
                  >
                    {t("company.form.copyFrom")}
                    <div className="global-shadow-card components-add-position-container-dropdown">
                      <select
                        className="component-modal-input-dropdown"
                        style={{
                          fontWeight: "bold",
                          marginLeft: 10,
                          marginRight: 5
                        }}
                        value={copyFrom}
                        onChange={(e: any) => _getPositionId(e.target.value)}
                      >
                        <option value="" disabled selected hidden>
                          {t("company.form.selectPosition")}
                        </option>
                        {departmentState.lisPositionRole.map(
                          (item: any, index: number) => (
                            <option key={index} value={item.value}>
                              {item.label}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: 12,
                      flexWrap: "wrap"
                    }}
                  >
                    {addPosition.roles.map((e: any, index: number) => (
                      <div
                        key={index}
                        className="nyobain margin-right-8 padding-left-8 padding-right-8"
                        style={{
                          backgroundColor: "#50B1B1",
                          height: 32,
                          fontSize: 12,
                          color: "#ffffff",
                          borderRadius: 100,
                          marginTop: 10
                        }}
                      >
                        {e.name}
                        <div
                          style={{
                            width: 20,
                            height: 20,
                            borderRadius: 100,
                            marginLeft: 5,
                            backgroundColor: "#66666650"
                          }}
                        >
                          <img
                            style={{
                              width: 20,
                              height: 20,
                              alignSelf: "center",
                              cursor: "pointer"
                            }}
                            src={IMAGES.btnDel}
                            alt="btnDel"
                            onClick={() => _deleteRole(e.id)}
                          />
                        </div>
                      </div>
                    ))}

                    <div
                      style={{
                        width: "30%",
                        borderBottom: " 1px solid grey"
                      }}
                    >
                      <Autocomplete
                        option={role}
                        placeHolder=""
                        value={autoComplete}
                        onValueChange={e => _autocompleteRole(e)}
                      />
                    </div>
                  </div>
                  {_handleFormValidation(
                    addPosition.roles.length === 0 && validateForm,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.position")
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      {/* modal edit */}
      <Modal
        titleModal={t("company.form.editDeptPosition")}
        isOpen={modalEditPostiton}
        onPressCancel={() => {
          setEditPosition(false);
          setPosition(addPosisi);
        }}
        btnSubmitText={
          departmentState.isUpdatePosition ? "LOADING" : t("btn.save")
        }
        btnCancelText={t("btn.cancel")}
        onPressSubmit={() => _updatePosition(uuid)}
      >
        <div style={{ display: "flex" }}>
          <div style={{ width: 380, marginRight: 80 }}>
            <div style={{ marginBottom: 16, fontWeight: "bold" }}>
              {t("company.form.positionName")}
            </div>

            <input
              className="component-modal-input"
              defaultValue={departmentState.detailPosition.title}
              value={addPosition.title}
              onChange={e =>
                setPosition({ ...addPosition, title: e.target.value })
              }
            />

            <div
              style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
            >
              {copyWritings("department")}
            </div>
            {departmentState.isDetailPosition ? (
              <select
                className="component-modal-input-dropdown"
                value={addPosition.department_id}
                onChange={e =>
                  setPosition({ ...addPosition, department_id: e.target.value })
                }
              >
                {departmentState.listDepartment.map(
                  (item: any, index: number) => (
                    <option
                      key={index}
                      value={item.value}
                      selected={
                        item.value ==
                        departmentState.detailPosition.department?.id
                      }
                    >
                      {item.label}
                    </option>
                  )
                )}
              </select>
            ) : (
              <></>
            )}
            <div className="component-modal-line" />
            <div
              style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
            >
              {t("company.form.class")}
            </div>

            <select
              className="component-modal-input-dropdown"
              value={addPosition.division_id}
              onChange={e =>
                setPosition({ ...addPosition, division_id: e.target.value })
              }
            >
              {departmentState.listClass.map((item: any, index: number) => (
                <option
                  key={index}
                  value={item.value}
                  selected={
                    item.value == departmentState.detailPosition.division?.id
                  }
                >
                  {item.label}
                </option>
              ))}
            </select>

            <div className="component-modal-line" />
            <div
              style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}
            >
              {`${copyWritings("report")} ${t("to")}`}
            </div>

            <select
              className="component-modal-input-dropdown"
              value={addPosition.parent_id}
              onChange={e => {
                setPosition({ ...addPosition, parent_id: e.target.value });
              }}
            >
              {departmentState.listPosition.map((item: any, index: number) => (
                <option
                  key={index}
                  value={item.value}
                  selected={
                    item.value == departmentState.detailPosition.parent?.id
                  }
                >
                  {item.label}
                </option>
              ))}
            </select>

            <div className="component-modal-line" />
          </div>
          <div style={{ width: 380 }}>
            <div style={{ marginBottom: 16, fontWeight: "bold" }}>
              {copyWritings("role")}
            </div>
            <div className="global-shadow-card components-add-position-form">
              <div
                style={{
                  width: "90%",
                  height: "92%"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 12
                  }}
                >
                  {t("company.form.copyFrom")}
                  <div className="global-shadow-card components-add-position-container-dropdown">
                    <select
                      className="component-modal-input-dropdown"
                      style={{ fontWeight: "bold" }}
                      value={copyFrom}
                      onChange={(e: any) => _getPositionId(e.target.value)}
                    >
                      {departmentState.listPosition.map(
                        (item: any, index: number) => (
                          <option key={index} value={item.value}>
                            {item.label}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 12,
                    flexWrap: "wrap"
                  }}
                >
                  {addPosition.roles.map((e: any, index: number) => (
                    <div
                      key={index}
                      className="nyobain margin-right-8 padding-left-8 padding-right-8"
                      style={{
                        backgroundColor: "#50B1B1",
                        height: 32,
                        fontSize: 12,
                        color: "#ffffff",
                        borderRadius: 100,
                        marginTop: 10
                      }}
                    >
                      {e.name}
                      <div
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: 100,
                          marginLeft: 5,
                          backgroundColor: "#66666650"
                        }}
                      >
                        <img
                          style={{ width: 20, height: 20, alignSelf: "center" }}
                          src={IMAGES.btnDel}
                          alt="btnDel"
                          onClick={() => _deleteRole(e.id)}
                        />
                      </div>
                    </div>
                  ))}

                  <div
                    style={{
                      width: "30%",
                      borderBottom: " 1px solid grey",
                      marginBottom: 10
                    }}
                  >
                    <Autocomplete
                      option={role}
                      value={autoComplete}
                      placeHolder=""
                      onValueChange={e => _autocompleteRole(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* modal delete Position */}
      <Modal
        warning
        btnSubmitText={
          departmentState.isDeletePosition ? "LOADING" : t("btn.delete")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModal}
        onPressCancel={() => setDeleteModal(false)}
        message={String(t("company.form.deleteMsg"))}
        onPressSubmit={() => _deletePosition(uuid)}
      />

      {/* modal addDepartment */}
      <ModalDepartment
        isOpen={modalDepartment}
        valueDepartment={departemenAdd.title}
        valueDesc={departemenAdd.description}
        valueCode={departemenAdd.code}
        onChangeDepartment={(e: any) =>
          setDepartment({ ...departemenAdd, title: e.target.value })
        }
        onChangeDesc={(e: any) =>
          setDepartment({ ...departemenAdd, description: e.target.value })
        }
        onChangeCode={(e: any) =>
          setDepartment({ ...departemenAdd, code: e.target.value })
        }
        onPressCancel={() => {
          setModalDepartment(false);
          setDepartment(addDepartemen);
        }}
        onPressSubmit={() => _departmentAdd()}
      />

      {/* modal Edit addDepartment */}
      <ModalDepartment
        isEdit
        isOpen={modalEditDepart}
        onChangeDepartment={(e: any) =>
          setDepartment({ ...departemenAdd, title: e.target.value })
        }
        onChangeDesc={(e: any) =>
          setDepartment({ ...departemenAdd, description: e.target.value })
        }
        onChangeCode={(e: any) =>
          setDepartment({ ...departemenAdd, code: e.target.value })
        }
        onPressCancel={() => {
          setEditDepart(false);
          setDepartment(addDepartemen);
        }}
        valueDepartment={departemenAdd.title}
        valueDesc={departemenAdd.description}
        valueCode={departemenAdd.code}
        onPressSubmit={() => _updateDepartment(uuid)}
      />

      {/* modal delete Department */}
      <Modal
        warning
        btnSubmitText={
          departmentState.isDeletePosition ? "LOADING" : t("btn.delete")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModalDept}
        onPressCancel={() => setDeleteModalDept(false)}
        message={String(t("company.form.deleteMsg"))}
        onPressSubmit={() => _deleteDepartment(uuid)}
      />

      <Modal
        warning
        titleModal="WARNING"
        isOpen={mandatory}
        onPressCancel={() => setMandatory(false)}
        message={mandatoryMsg}
        mandatory
      />
    </>
  );
};

export default Department;
