/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Modal,
  ModalEditLimit,
  Text
} from "../../../components";
import { IMAGES } from "../../../configs";

import { Reducers } from "../../../redux/types";
import { Roles, maskedMoney } from "../../../utils";
import {
  getLoanProfileList,
  setModalGenerateLoanProfile,
  updateAllLoanProfileStatus,
  updateLoanLimit,
  updateLoanProfileStatus
} from "../../../redux/actions";
import GenerateProfileForm from "../components/GenerateProfileForm";

import "./styles.scss";

const List = () => {
  const history = useHistory();
  const { sprintf } = require("sprintf-js");
  const dispatch = useDispatch();
  // const [generateAll, setGenerateAll] = useState(false);
  const [modalApproveAll, setModalApproveAll] = useState(false);
  const [modalDeclineAll, setModalDeclineAll] = useState(false);
  const [modalApproveLoanProfile, setModalApproveLoanProfile] = useState(false);
  const [modalDeclineLoanProfile, setModalDeclineLoanProfile] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [loanProfileId, setLoanProfileId] = useState("");
  const [modalEditLimit, setModalEditLimit] = useState(false);
  const [editLimit, setEditLimit] = useState("");
  const [maxLimit, setMaxLimit] = useState("");
  const loanState = useSelector((state: Reducers) => state.loan);

  const homeState = useSelector((state: Reducers) => state.home);

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getLoanProfileList(1));
  }, [dispatch, editLimit, maxLimit]);

  const _updateAllLoanProfileStatus = (status: number) => {
    dispatch(
      updateAllLoanProfileStatus(status, () => {
        setModalApproveAll(false);
        setModalDeclineAll(false);
        dispatch(
          getLoanProfileList(loanState.paginationListLoanProfile.current_page)
        );
      })
    );
  };

  const _updateLoanProfileStatus = (id: string, status: number) => {
    dispatch(
      updateLoanProfileStatus(status, id, () => {
        setModalApproveLoanProfile(false);
        setModalDeclineLoanProfile(false);
        setSelectedEmployee("");
        setLoanProfileId("");
        dispatch(
          getLoanProfileList(loanState.paginationListLoanProfile.current_page)
        );
      })
    );
  };

  const _updateLoanLimit = (id: string, limit: any) => {
    dispatch(
      updateLoanLimit(limit, id, () => {
        setModalApproveLoanProfile(false);
        setModalDeclineLoanProfile(false);
        setSelectedEmployee("");
        setLoanProfileId("");
        dispatch(
          getLoanProfileList(loanState.paginationListLoanProfile.current_page)
        );
      })
    );
  };

  const _hasPersonalLoan = useCallback(() => {
    const res = homeState.profile.listRole.find(
      (el: any) => el === "personal-loan-hr" || el === "personal-loan-finance"
    );
    return res;
  }, [homeState.profile.listRole]);

  useEffect(() => {
    if (homeState.profile.listRole.length > 0) {
      if (
        !(
          _hasPersonalLoan() ||
          homeState.profile.listRole.find((el: any) => el === "superadmin")
        )
      ) {
        window.location.replace(process.env.REACT_APP_HOST_WEB || "");
      }
    }
  }, [_hasPersonalLoan, homeState.profile.listRole]);

  const statusDesc = (status: number) => {
    let label = "";
    if (status === 5) {
      label = t("status.eligible");
    } else if (status === 6) {
      label = t("status.nonEligible");
    } else {
      label = "";
    }
    return label;
  };

  return (
    <Container isLoading={loanState.isLoadingListLoanProfile}>
      <div>
        {Roles("superadmin") ? (
          <button
            onClick={() => {
              dispatch(setModalGenerateLoanProfile(true, true));
            }}
            className="button margin-right-8"
            type="button"
          >
            {t("personnel.btn.generateLoan")}
          </button>
        ) : null}

        {_hasPersonalLoan() ? (
          <button
            className="button margin-right-8"
            type="button"
            onClick={() => setModalApproveAll(true)}
          >
            {t("personnel.btn.approveAllLoan")}
          </button>
        ) : null}
        {_hasPersonalLoan() ? (
          <button
            className="button margin-right-8"
            type="button"
            onClick={() => setModalDeclineAll(true)}
          >
            {t("personnel.btn.declineAllLoan")}
          </button>
        ) : null}
        <GenerateProfileForm
          currentPage={
            (loanState.paginationListLoanProfile &&
              loanState.paginationListLoanProfile.current_page) ||
            1
          }
        />
        <table className="data-table margin-top-20">
          <thead>
            <tr>
              <th>{t("listLoanProfile.employee").toUpperCase()}</th>
              <th>{t("listLoanProfile.department").toUpperCase()}</th>
              <th>{t("listLoanProfile.source").toUpperCase()}</th>
              <th>{t("listLoanProfile.currency").toUpperCase()}</th>
              <th>{t("loan_profile.plafond").toUpperCase()}</th>
              <th>{t("listLoanProfile.due").toUpperCase()}</th>
              <th>{t("listLoanProfile.date").toUpperCase()}</th>
              <th>STATUS</th>
              <th colSpan={2} className="loan-profile-list-regular-text">
                {loanState.paginationListLoanProfile ? (
                  <div>
                    <div className="loan-profile-list-pagging">{`${loanState.paginationListLoanProfile.from} - ${loanState.paginationListLoanProfile.to} of ${loanState.paginationListLoanProfile.total}`}</div>
                    {loanState.paginationListLoanProfile.current_page > 1 && (
                      <div
                        onClick={() =>
                          dispatch(
                            getLoanProfileList(
                              loanState.paginationListLoanProfile.current_page -
                                1
                            )
                          )
                        }
                        className="loan-profile-list-pagging arrow pointer"
                      >
                        {"<"}
                      </div>
                    )}
                    {loanState.paginationListLoanProfile.current_page <
                      loanState.paginationListLoanProfile.last_page && (
                      <div
                        onClick={() =>
                          dispatch(
                            getLoanProfileList(
                              loanState.paginationListLoanProfile.current_page +
                                1
                            )
                          )
                        }
                        className="loan-profile-list-pagging arrow pointer"
                      >
                        {">"}
                      </div>
                    )}
                  </div>
                ) : null}
              </th>
            </tr>
          </thead>
          <tbody className="double">
            {loanState.listLoanProfile.map((item, index) => (
              <tr key={index}>
                <td>{item.attributes.employee}</td>
                <td>{item.attributes.department}</td>
                <td>{item.attributes.source}</td>
                <td>{item.attributes.currency}</td>
                <td>
                  <div style={{ display: "flex" }}>
                    <p>
                      {maskedMoney(
                        item.attributes.limit_setted != null
                          ? item.attributes.limit_setted
                          : item.attributes.limit
                      )}
                    </p>

                    {homeState &&
                    homeState.profile &&
                    homeState.profile.listRole.length &&
                    (homeState.profile.listRole.includes("personal-loan-hr") ||
                      homeState.profile.listRole.includes(
                        "personal-loan-finance"
                      )) ? (
                      <Button
                        onPress={() => {
                          setLoanProfileId(item.id);
                          setEditLimit(
                            item.attributes.limit_setted != null
                              ? item.attributes.limit_setted
                              : item.attributes.limit
                          );
                          setMaxLimit(item.attributes.limit);
                          setModalEditLimit(true);
                        }}
                        style={{
                          marginLeft: "10px",
                          marginTop: -5
                        }}
                      >
                        <img
                          src={IMAGES.pencil}
                          alt="n/a"
                          style={{
                            height: 30,
                            width: 30
                          }}
                          className="pointer"
                        />
                      </Button>
                    ) : (
                      ""
                    )}
                  </div>
                </td>
                <td>{maskedMoney(item.attributes.due)}</td>
                <td>{moment(item.attributes.date).format("DD MMMM YYYY")}</td>
                <td>
                  <Text
                    style={{ textAlign: "center" }}
                    className={`dasboard-employee-list-status ${
                      item.attributes.status === 6 ? "red" : ""
                    }`}
                  >
                    {statusDesc(item.attributes.status)}
                  </Text>
                </td>
                <td>
                  <div className="loan-profile-list-action actions loan-profile-list-btn-container">
                    <Button
                      onPress={() =>
                        history.push(
                          `/employee-detail/${item.attributes.employee_uuid}`
                        )
                      }
                    >
                      <img
                        src={IMAGES.pencil}
                        alt="n/a"
                        style={{ height: 50, width: 50 }}
                        className="pointer"
                      />
                    </Button>
                    {item.attributes.active && _hasPersonalLoan() ? (
                      <Button
                        onPress={() => {
                          setSelectedEmployee(item.attributes.employee);
                          setLoanProfileId(item.id);
                          setModalDeclineLoanProfile(true);
                        }}
                      >
                        <img
                          src={IMAGES.deleteRound}
                          alt="n/a"
                          style={{ height: 50, width: 50 }}
                          className="pointer"
                        />
                      </Button>
                    ) : null}
                    {_hasPersonalLoan() && !item.attributes.active && (
                      <Button
                        onPress={() => {
                          setSelectedEmployee(item.attributes.employee);
                          setLoanProfileId(item.id);
                          setModalApproveLoanProfile(true);
                        }}
                      >
                        <img
                          src={IMAGES.cashInHand}
                          alt="n/a"
                          style={{ height: 50, width: 50 }}
                          className="pointer"
                        />
                      </Button>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Modal
          isOpen={modalApproveAll}
          warning
          onPressCancel={() => setModalApproveAll(false)}
          onPressSubmit={() => _updateAllLoanProfileStatus(5)}
          btnSubmitText={t("btn.yes")}
          btnCancelText={t("btn.cancel")}
        >
          <div style={{ textAlign: "center" }}>
            <Trans>{t("personnel.message.approveAll")}</Trans>
          </div>
        </Modal>
        <Modal
          isOpen={modalDeclineAll}
          warning
          onPressCancel={() => setModalDeclineAll(false)}
          onPressSubmit={() => _updateAllLoanProfileStatus(6)}
          btnSubmitText={t("btn.yes")}
          btnCancelText={t("btn.cancel")}
        >
          <div style={{ textAlign: "center" }}>
            <Trans>{t("personnel.message.declineAll")}</Trans>
          </div>
        </Modal>
        <Modal
          isOpen={modalApproveLoanProfile}
          warning
          onPressCancel={() => {
            setModalApproveLoanProfile(false);
            setSelectedEmployee("");
            setLoanProfileId("");
          }}
          onPressSubmit={() => _updateLoanProfileStatus(loanProfileId, 5)}
          btnSubmitText={t("btn.yes")}
          btnCancelText={t("btn.cancel")}
        >
          <div style={{ textAlign: "center" }}>
            <Trans>
              {sprintf(t("personnel.message.approveSingle"), selectedEmployee)}
            </Trans>
          </div>
        </Modal>
        <Modal
          isOpen={modalDeclineLoanProfile}
          warning
          onPressCancel={() => {
            setModalDeclineLoanProfile(false);
            setSelectedEmployee("");
            setLoanProfileId("");
          }}
          onPressSubmit={() => _updateLoanProfileStatus(loanProfileId, 6)}
          btnSubmitText={t("btn.yes")}
          btnCancelText={t("btn.cancel")}
        >
          <div style={{ textAlign: "center" }}>
            <Trans>
              {sprintf(t("personnel.message.declineSingle"), selectedEmployee)}
            </Trans>
          </div>
        </Modal>
        <ModalEditLimit
          value={Number(editLimit).toFixed()}
          max_limit={Number(maxLimit).toFixed()}
          isOpen={modalEditLimit}
          onPressCancel={() => {
            setLoanProfileId("");
            setEditLimit("");
            setMaxLimit("");
            setModalEditLimit(false);
          }}
          onPressSubmit={data => {
            setModalEditLimit(false);
            _updateLoanLimit(loanProfileId, data);
          }}
        />
      </div>
    </Container>
  );
};

export default List;
