/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { ErrorMessage, useForm } from "react-hook-form";
import Switch from "react-switch";
import { Reducers } from "../../../../redux/types";
import {
  generateLoanProfile,
  getEmployee,
  getLoanSourceById,
  getLoanSources,
  resetEmployeeProfile,
  setModalGenerateLoanProfile
} from "../../../../redux/actions";

import { Modal } from "../../../../components";
import { maskedMoney } from "../../../../utils";
import "./styles.scss";

const GenerateProfileForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    // watch,
    setValue,
    getValues
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { sprintf } = require("sprintf-js");

  const initialCalc = {
    fromSalary: false,
    salary: ""
  };

  const [confirmModal, setConfirmModal] = useState(false);
  const [calc, setCalc] = useState(initialCalc);

  const { setModal, isAll, loanSourceList, loanState, staffData } = useSelector(
    (state: Reducers) => ({
      isAll: state.employee.isGenerateLoanAll,
      setModal: state.employee.setModalGenerateLoan,
      loanSourceList: state.loan.listLoanSources,
      loanState: state.loan,
      staffData: state.employee.staff.data
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getLoanSources());
  }, [dispatch]);

  const populateSources = () => {
    let result = [{ value: "", label: t("task.form.none") }];
    loanSourceList.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  };

  const _onConfirmGenerate = (data: any) => {
    if (data) {
      setConfirmModal(true);
    }
  };

  const _onSave = (data: any) => {
    let form = {};
    if (isAll) {
      form = {
        ...data,
        all: isAll
      };
    } else {
      form = {
        ...data,
        all: isAll,
        employee_id: staffData.id
      };
    }
    dispatch(
      generateLoanProfile(form, () => {
        dispatch(setModalGenerateLoanProfile(false, false));
        dispatch(resetEmployeeProfile());
        reset({ amount_max: "", source_id: "" });
        setCalc(initialCalc);
        setConfirmModal(false);
        dispatch(
          getEmployee(
            "", // id
            "", // idPos
            "", // idRole
            1, // page
            false, // hidden
            "", // q
            false, // parent
            false, // show_self
            false, // hiddenOutSource
            false // disable
          )
        );
      })
    );
  };

  const _onCancel = () => {
    reset({ amount_max: "", source_id: "" });
    setCalc(initialCalc);
    dispatch(setModalGenerateLoanProfile(false, false));
    dispatch(resetEmployeeProfile());
  };

  const maskedSalary = maskedMoney(
    staffData && staffData.salary && staffData.salary.amount
  );

  return (
    <>
      <Modal
        isOpen={setModal}
        onPressCancel={_onCancel}
        titleModal={t("personnel.btn.generateLoan")}
        onPressSubmit={handleSubmit(_onConfirmGenerate)}
        loading={loanState.isLoadingLoanSources}
        btnSubmitText={t("btn.generate")}
        btnCancelText={t("btn.cancel")}
      >
        <div className="modal-container">
          <div>
            <span className="component-modal-assign-task-label">
              {t("personnel.form.fundSource")}
            </span>
            <select
              className="component-modal-assign-task-dropdown padding-left-12"
              name="source_id"
              ref={register({
                required: String(t("personnel.form.selectSourceMsg"))
              })}
              onChange={e => {
                dispatch(getLoanSourceById(e.target.value));
              }}
            >
              {populateSources().map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <div className="component-modal-line" />
            <ErrorMessage
              errors={errors}
              name="source_id"
              as="p"
              style={{ color: "red" }}
            />
            {!isAll &&
              staffData &&
              staffData.salary &&
              staffData.salary.amount !== "" && (
                <>
                  <div className="wrap-switch">
                    <span
                      className={`component-modal-assign-task-label switch ${calc.fromSalary &&
                        "opacity"}`}
                      style={{ marginRight: 5 }}
                    >
                      {t("personnel.form.manual")}
                    </span>
                    <Switch
                      onChange={() =>
                        setCalc({ ...calc, fromSalary: !calc.fromSalary })
                      }
                      checked={calc.fromSalary}
                      onColor="#ff7600"
                      offColor="#ff7600"
                      onHandleColor="#fffff7"
                      handleDiameter={16}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={24}
                      width={48}
                      tabIndex={0}
                      onKeyPress={() =>
                        setCalc({ ...calc, fromSalary: !calc.fromSalary })
                      }
                    />
                    <span
                      className={`component-modal-assign-task-label switch ${!calc.fromSalary &&
                        "opacity"}`}
                      style={{ marginLeft: 5 }}
                    >
                      {t("personnel.employeeDetail.salary")}
                    </span>
                  </div>
                  {calc.fromSalary && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}
                      className="margin-top-20"
                    >
                      <div style={{ width: "20%" }}>
                        <span className="component-modal-assign-task-label">
                          {t("personnel.form.percentage")}
                        </span>
                        <div>
                          <input
                            name="percentage"
                            placeholder=""
                            type="text"
                            maxLength={2}
                            className="components-employee-custom-input adornment-field"
                            onChange={e => {
                              const calculate =
                                Number(staffData.salary.amount) *
                                (Number(e.target.value) / 100);
                              setValue("amount_max", calculate);
                            }}
                          />
                          <span className="input-adornment">%</span>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "75%",
                          alignContent: "left",
                          paddingTop: "3.25rem"
                        }}
                      >
                        <span className="component-modal-assign-task-label salary-text">
                          {`dari Rp. ${maskedSalary}`}
                        </span>
                      </div>
                    </div>
                  )}
                </>
              )}
            <div className="margin-top-20">
              <span className="component-modal-assign-task-label">
                {t("policy.amount")}
              </span>
              <input
                name="amount_max"
                disabled={calc.fromSalary}
                type="number"
                className="components-employee-custom-input"
                ref={register({
                  required: String(t("personnel.form.inputAmountMsg"))
                })}
              />
              <ErrorMessage
                errors={errors}
                name="amount_max"
                as="p"
                style={{ color: "red" }}
              />
            </div>
            <input
              name="currency_code"
              type="hidden"
              defaultValue={
                loanState.dataLoanSource &&
                loanState.dataLoanSource.attributes &&
                loanState.dataLoanSource.attributes.currency_code
                  ? loanState.dataLoanSource.attributes.currency_code
                  : "IDR"
              }
              className="components-employee-custom-input"
              ref={register}
            />
          </div>
        </div>
      </Modal>
      <Modal
        warning
        onPressCancel={() => setConfirmModal(false)}
        isOpen={confirmModal}
        onPressSubmit={handleSubmit(_onSave)}
        btnSubmitText={t("btn.submit")}
        btnCancelText={t("btn.cancel")}
        loading={loanState.isLoadingGenerateLoanProfile}
      >
        {isAll ? (
          <div style={{ textAlign: "center" }}>
            {t("personnel.form.confirmLoanProfileAll")}
            <br />
            {t("personnel.form.confirmLoanProfileAnd")}
            &nbsp;
            <b>{`IDR ${maskedMoney(getValues("amount_max"))}`}</b>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <Trans>
              {sprintf(
                t("personnel.form.confirmLoanProfileSingle"),
                staffData.name
              )}
            </Trans>
            <br />
            {t("personnel.form.confirmLoanProfileAnd")}
            &nbsp;
            <b>{`IDR ${maskedMoney(getValues("amount_max"))}`}</b>
          </div>
        )}
      </Modal>
    </>
  );
};

export default memo(GenerateProfileForm);
