import React, { memo } from "react";

import "./styles.scss";

interface ListValue {
  id: any;
  title: any;
}

interface Props {
  listValue: ListValue[];
  onChange?: (e: any) => void;
  value?: string;
  selected?: any;
  classNameSelect: string;
  classNameContainer?: string;
}

const Picker = ({
  listValue,
  onChange,
  value,
  selected,
  classNameSelect,
  classNameContainer
}: Props) => {
  return (
    <div className={classNameContainer}>
      <select className={classNameSelect} value={value} onChange={onChange}>
        {listValue.map((item, index) => (
          <option key={index} value={item.id} selected={item.id === selected}>
            {item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

Picker.defaultProps = {
  classNameSelect: "",
  classNameContainer: ""
};

export default memo(Picker);
