import {
  ADD_ACCOUNT_FORM_EMPLOYEE,
  ADD_FORM_DATA_ADDRESS,
  DELETE_ACCOUNT_EMPLOYEE_ERROR,
  DELETE_ACCOUNT_EMPLOYEE_PENDING,
  DELETE_ACCOUNT_EMPLOYEE_SUCCESS,
  DELETE_ACCOUNT_FORM_EMPLOYEE,
  DELETE_CONTRACT_ERROR,
  DELETE_CONTRACT_PENDING,
  DELETE_CONTRACT_SUCCESS,
  DELETE_SALARY_ERROR,
  DELETE_SALARY_PENDING,
  DELETE_SALARY_SUCCESS,
  GET_ALL_BANK_ERROR,
  GET_ALL_BANK_PENDING,
  GET_ALL_BANK_SUCCESS,
  GET_ALL_ROLES_ERROR,
  GET_ALL_ROLES_PENDING,
  GET_ALL_ROLES_SUCCESS,
  GET_CONTRACTS_BY_EMPLOYEE_ERROR,
  GET_CONTRACTS_BY_EMPLOYEE_PENDING,
  GET_CONTRACTS_BY_EMPLOYEE_SUCCESS,
  GET_CONTRACTS_BY_ID_ERROR,
  GET_CONTRACTS_BY_ID_PENDING,
  GET_CONTRACTS_BY_ID_SUCCESS,
  GET_EMPLOYEE_PROFILE_DUMMY_SUCCESS,
  GET_EMPLOYEE_PROFILE_ERROR,
  GET_EMPLOYEE_PROFILE_PENDING,
  GET_EMPLOYEE_PROFILE_SUCCESS,
  GET_PROFILE_STAFF_ERROR,
  GET_PROFILE_STAFF_PENDING,
  GET_PROFILE_STAFF_SUCCESS,
  GET_SALARY_BY_EMPLOYEE_ERROR,
  GET_SALARY_BY_EMPLOYEE_PENDING,
  GET_SALARY_BY_EMPLOYEE_SUCCESS,
  GET_SALARY_BY_ID_ERROR,
  GET_SALARY_BY_ID_PENDING,
  GET_SALARY_BY_ID_SUCCESS,
  POST_ADD_CONTRACT_ERROR,
  POST_ADD_CONTRACT_PENDING,
  POST_ADD_CONTRACT_SUCCESS,
  POST_ADD_SALARY_ERROR,
  POST_ADD_SALARY_PENDING,
  POST_ADD_SALARY_SUCCESS,
  PUT_CONTRACT_ERROR,
  PUT_CONTRACT_PENDING,
  PUT_CONTRACT_SUCCESS,
  PUT_SALARY_ERROR,
  PUT_SALARY_PENDING,
  PUT_SALARY_SUCCESS,
  PUT_SAVE_MY_PROFILE_ERROR,
  PUT_SAVE_MY_PROFILE_PENDING,
  PUT_SAVE_MY_PROFILE_SUCCESS,
  REMOVE_FORM_MY_PROFILE_EMPLOYEE,
  REMOVE_VALIDATE_FILE_EMPLOYEE,
  RESET_DETAIL_CONTRACT,
  RESET_EMPLOYEE_PROFILE,
  RESET_ROLES_EMPLOYEE_PROFILE,
  SET_EMPLOYEE_PROFILE,
  SET_FORM_ACCOUNT_FORM_EMPLOYEE,
  SET_FORM_MY_PROFILE_EMPLOYEE,
  SET_IMAGE_MY_PROFILE_EMPLOYEE,
  SET_MODAL_ADD_CONTRACT,
  SET_MODAL_ADD_SALARY,
  SET_MODAL_EDIT_CONTRACT,
  SET_MODAL_EDIT_SALARY,
  SET_MODAL_GENERATE_LOAN_PROFILE,
  SET_PRIMARY_ACCOUNT_EMPLOYEE_ERROR,
  SET_PRIMARY_ACCOUNT_EMPLOYEE_PENDING,
  SET_PRIMARY_ACCOUNT_EMPLOYEE_SUCCESS,
  SET_STAFF_EMPLOYEE_PROFILE,
  UPLOAD_DATA_EMPLOYEE_ERROR,
  UPLOAD_DATA_EMPLOYEE_PENDING,
  UPLOAD_DATA_EMPLOYEE_SUCCESS,
  VALIDATE_DATA_EMPLOYEE_ERROR,
  VALIDATE_DATA_EMPLOYEE_PENDING,
  VALIDATE_DATA_EMPLOYEE_SUCCESS
} from "../actions";

import { Action, EmployeeState } from "../types";

const initialState: EmployeeState = {
  isLoading: false,
  uploadProgress: "",
  resultValidate: {},
  listEmployee: {
    data: null,
    isLoadingEmployee: false
  },
  staff: {
    data: {},
    isLoading: false,
    form: {
      imageFile: {},
      imageString: "",
      idNumber: {
        number: "",
        file: {}
      },
      passport: {
        number: "",
        expired_at: {
          mm: "",
          yy: ""
        },
        file: {}
      },
      taxNumber: {
        number: "",
        file: {}
      },
      employeeBussiness: {
        name: "",
        address: "",
        file: {}
      },
      city: "",
      accounts: []
    },
    isLoadingAccount: false,
    formValidate: {
      city: false
    },
    listContract: [],
    detailContract: {},
    listSalary: [],
    detailSalary: {}
  },
  listBank: {
    isLoading: false,
    list: []
  },
  listRoles: {
    isLoading: false,
    list: []
  },
  isDeleteContract: false,
  deleteContract: [],
  isDeleteSalary: false,
  deleteSalary: [],
  setModalAddContract: false,
  setModalEditContract: false,
  setModalAddSalary: false,
  setModalEditSalary: false,
  setModalGenerateLoan: false,
  isGenerateLoanAll: false,
  isLoadingAddContract: false,
  isLoadingEditContract: false,
  isLoadingAddSalary: false,
  isLoadingEditSalary: false
};

let result: any[] = [];
let tempRes: any = null;
let condition: any = null;

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_EMPLOYEE_PROFILE_PENDING:
      return {
        ...state,
        listEmployee: { ...state.listEmployee, isLoadingEmployee: true }
      };
    case GET_EMPLOYEE_PROFILE_SUCCESS:
      return {
        ...state,
        listEmployee: {
          ...state.listEmployee,
          isLoadingEmployee: false,
          data: payload.data
        }
      };
    case GET_EMPLOYEE_PROFILE_ERROR:
      return {
        ...state,
        listEmployee: { ...state.listEmployee, isLoadingEmployee: false }
      };

    case GET_EMPLOYEE_PROFILE_DUMMY_SUCCESS:
      return {
        ...state,
        listEmployee: {
          ...state.listEmployee,
          isLoadingEmployee: false,
          data: payload.data
        }
      };

    case GET_PROFILE_STAFF_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoading: true }
      };
    case GET_PROFILE_STAFF_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoading: false,
          data: payload.data,
          form: {
            ...state.staff.form,
            city:
              (payload.data.addresses.length > 0 &&
                payload.data.addresses[0].address.regency &&
                payload.data.addresses[0].address.regency.id) ||
              "",
            idNumber: {
              ...state.staff.form.idNumber,
              number:
                (payload.data.idNumber && payload.data.idNumber.number) || ""
            },
            passport: {
              ...state.staff.form.passport,
              number:
                (payload.data.passport && payload.data.passport.number) || "",
              expired_at: {
                yy:
                  (payload.data.passport &&
                    payload.data.passport.expired_at &&
                    payload.data.passport.expired_at
                      .split("-")[0]
                      .slice(2, 4)) ||
                  "",
                mm:
                  (payload.data.passport &&
                    payload.data.passport.expired_at &&
                    payload.data.passport.expired_at.split("-")[1]) ||
                  ""
              }
            },
            taxNumber: {
              ...state.staff.form.taxNumber,
              number:
                (payload.data.taxNumber && payload.data.taxNumber.number) || ""
            },
            employeeBussiness: {
              ...state.staff.form.employeeBussiness,
              name: payload.data.employeeBussiness
                ? payload.data.employeeBussiness.name
                : "",
              address: payload.data.employeeBussiness
                ? payload.data.employeeBussiness.address
                : "",
              file: payload.data.employeeBussiness
                ? payload.data.employeeBussiness.bussiness_photo
                : null
            }
          }
        }
      };
    case GET_PROFILE_STAFF_ERROR:
      return {
        ...state,
        staff: { ...state.staff, data: {}, isLoading: false }
      };

    case GET_ALL_BANK_PENDING:
      return {
        ...state,
        listBank: { ...state.listBank, isLoading: true }
      };
    case GET_ALL_BANK_SUCCESS:
      return {
        ...state,
        listBank: { ...state.listBank, isLoading: false, list: payload.data }
      };
    case GET_ALL_BANK_ERROR:
      return {
        ...state,
        listBank: { ...state.listBank, isLoading: false }
      };

    case GET_ALL_ROLES_PENDING:
      return {
        ...state,
        listRoles: { ...state.listRoles, isLoading: true }
      };
    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        listRoles: { ...state.listRoles, isLoading: false, list: payload.data }
      };
    case GET_ALL_ROLES_ERROR:
      return {
        ...state,
        listRoles: { ...state.listRoles, isLoading: false }
      };

    case SET_PRIMARY_ACCOUNT_EMPLOYEE_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoadingAccount: true }
      };
    case SET_PRIMARY_ACCOUNT_EMPLOYEE_SUCCESS:
      result = [];
      state.staff.data.accounts.map((item: any) => {
        result = [
          ...result,
          { ...item, primary: item.account.id === payload.data }
        ];
        return true;
      });
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoadingAccount: false,
          data: { ...state.staff.data, accounts: result }
        }
      };
    case SET_PRIMARY_ACCOUNT_EMPLOYEE_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoadingAccount: false }
      };

    case DELETE_ACCOUNT_EMPLOYEE_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoadingAccount: true }
      };
    case DELETE_ACCOUNT_EMPLOYEE_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoadingAccount: false,
          data: {
            ...state.staff.data,
            accounts: state.staff.data.accounts.filter(
              e => e.account.id !== payload.data
            )
          }
        }
      };
    case DELETE_ACCOUNT_EMPLOYEE_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoadingAccount: false }
      };

    case PUT_SAVE_MY_PROFILE_PENDING:
      return { ...state, isLoading: true };
    case PUT_SAVE_MY_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staff: {
          ...state.staff,
          data: payload.data,
          form: {
            ...state.staff.form,
            accounts: initialState.staff.form.accounts,
            idNumber: {
              ...state.staff.form.idNumber,
              file: initialState.staff.form.idNumber.file
            },
            passport: {
              ...state.staff.form.passport,
              file: initialState.staff.form.passport.file
            },
            taxNumber: {
              ...state.staff.form.taxNumber,
              file: initialState.staff.form.taxNumber.file
            }
          }
        }
      };
    case PUT_SAVE_MY_PROFILE_ERROR:
      if (payload && payload.data && payload.data.formName) {
        return {
          ...state,
          staff: {
            ...state.staff,
            formValidate: {
              ...state.staff.formValidate,
              [payload.data.formName]: payload.data.value
            }
          },
          isLoading: false
        };
      }
      return { ...state, isLoading: false };

    case VALIDATE_DATA_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
        uploadProgress: (payload && payload.data) || ""
      };
    case VALIDATE_DATA_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadProgress: "",
        resultValidate: payload.data
      };
    case VALIDATE_DATA_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        uploadProgress: "",
        resultValidate: {}
      };

    case UPLOAD_DATA_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoading: true,
        uploadProgress: (payload && payload.data) || ""
      };
    case UPLOAD_DATA_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        uploadProgress: "",
        resultValidate: {}
      };
    case UPLOAD_DATA_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoading: false,
        uploadProgress: "",
        resultValidate: {}
      };

    case REMOVE_VALIDATE_FILE_EMPLOYEE:
      return {
        ...state,
        isLoading: false,
        resultValidate: {},
        uploadProgress: ""
      };

    case SET_EMPLOYEE_PROFILE:
      return {
        ...state,
        listEmployee: {
          ...state.listEmployee,
          data: {
            ...state.listEmployee.data,
            [payload.data.formName]: payload.data.value
          }
        }
      };

    case ADD_FORM_DATA_ADDRESS:
      return {
        ...state,
        listEmployee: {
          ...state.listEmployee,
          data: {
            ...state.listEmployee.data,
            addresses: [...state.listEmployee.data.addresses, ...payload.data]
          }
        }
      };

    case SET_IMAGE_MY_PROFILE_EMPLOYEE:
      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            imageFile: payload.data,
            imageString: payload.data ? URL.createObjectURL(payload.data) : ""
          }
        }
      };

    case SET_FORM_MY_PROFILE_EMPLOYEE:
      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            [payload.name!]: payload.data
          },
          formValidate: { ...state.staff.formValidate, city: false }
        }
      };

    case REMOVE_FORM_MY_PROFILE_EMPLOYEE:
      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            [payload.data]: {
              ...state.staff.form[payload.data],
              file: initialState.staff.form[payload.data].file
            }
          }
        }
      };

    case ADD_ACCOUNT_FORM_EMPLOYEE:
      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            accounts: payload.data
              ? []
              : [
                  ...state.staff.form.accounts,
                  { bank_id: "", branch: "", number: "", name: "" }
                ]
          }
        }
      };

    case DELETE_ACCOUNT_FORM_EMPLOYEE:
      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            accounts: state.staff.form.accounts.filter(
              (item, index) => index !== payload.data
            )
          }
        }
      };

    case SET_FORM_ACCOUNT_FORM_EMPLOYEE:
      result = [...state.staff.form.accounts];
      result[payload.index!] = {
        ...result[payload.index!],
        [payload.data.name]: payload.data.value
      };

      return {
        ...state,
        staff: {
          ...state.staff,
          form: {
            ...state.staff.form,
            accounts: result
          }
        }
      };

    case SET_STAFF_EMPLOYEE_PROFILE:
      if (payload.data.value.length > 0) {
        condition = payload.data.value[payload.data.value.length - 1];
        if (condition.name.includes("loan")) {
          tempRes = payload.data.value.filter((item: any) =>
            item.name.includes("loan")
          );
        } else {
          tempRes = payload.data.value.filter(
            (item: any) => !item.name.includes("loan")
          );
        }
      } else {
        tempRes = [];
      }
      return {
        ...state,
        staff: {
          ...state.staff,
          data: {
            ...state.staff.data,
            [payload.data.formName]:
              payload.data.formName === "roles" ? tempRes : payload.data.value,
            changedSignature: payload.data.formName === "signature"
          }
        }
      };

    case RESET_EMPLOYEE_PROFILE:
      return {
        ...state,
        staff: {
          ...state.staff,
          data: {
            roles: [],
            is_disabled: false,
            is_hidden: false,
            position: { id: "", title: "" }
          }
        }
      };

    case RESET_ROLES_EMPLOYEE_PROFILE:
      return {
        ...state,
        staff: {
          ...state.staff,
          data: {
            ...state.staff.data,
            roles: []
          }
        }
      };
    case GET_CONTRACTS_BY_EMPLOYEE_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoading: true }
      };
    case GET_CONTRACTS_BY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoading: false,
          listContract: payload.data
        }
      };
    case GET_CONTRACTS_BY_EMPLOYEE_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoading: false }
      };
    case GET_CONTRACTS_BY_ID_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoading: true }
      };
    case GET_CONTRACTS_BY_ID_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoading: false,
          detailContract: payload.data
        }
      };
    case GET_CONTRACTS_BY_ID_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoading: false }
      };
    case RESET_DETAIL_CONTRACT:
      return {
        ...state,
        staff: {
          ...state.staff,
          detailContract: {}
        }
      };
    case GET_SALARY_BY_EMPLOYEE_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoading: true }
      };
    case GET_SALARY_BY_EMPLOYEE_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoading: false,
          listSalary: payload.data
        }
      };
    case GET_SALARY_BY_EMPLOYEE_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoading: false }
      };
    case GET_SALARY_BY_ID_PENDING:
      return {
        ...state,
        staff: { ...state.staff, isLoading: true }
      };
    case GET_SALARY_BY_ID_SUCCESS:
      return {
        ...state,
        staff: {
          ...state.staff,
          isLoading: false,
          detailSalary: payload.data
        }
      };
    case GET_SALARY_BY_ID_ERROR:
      return {
        ...state,
        staff: { ...state.staff, isLoading: false }
      };
    case SET_MODAL_ADD_CONTRACT:
      return { ...state, setModalAddContract: payload.data };
    case SET_MODAL_EDIT_CONTRACT:
      return { ...state, setModalEditContract: payload.data };
    case SET_MODAL_ADD_SALARY:
      return { ...state, setModalAddSalary: payload.data };
    case SET_MODAL_EDIT_SALARY:
      return { ...state, setModalEditSalary: payload.data };

    // Post Add Contract
    case POST_ADD_CONTRACT_PENDING:
      return { ...state, isLoadingAddContract: true };
    case POST_ADD_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoadingAddContract: false
      };
    case POST_ADD_CONTRACT_ERROR:
      return { ...state, isLoadingAddContract: false };

    // Put Contract
    case PUT_CONTRACT_PENDING:
      return { ...state, isLoadingEditContract: true };
    case PUT_CONTRACT_SUCCESS:
      return {
        ...state,
        isLoadingEditContract: false
      };
    case PUT_CONTRACT_ERROR:
      return { ...state, isLoadingEditContract: false };

    // Post Add Salary
    case POST_ADD_SALARY_PENDING:
      return { ...state, isLoadingAddSalary: true };
    case POST_ADD_SALARY_SUCCESS:
      return {
        ...state,
        isLoadingAddSalary: false
      };
    case POST_ADD_SALARY_ERROR:
      return { ...state, isLoadingAddSalary: false };

    // Put Salary
    case PUT_SALARY_PENDING:
      return { ...state, isLoadingEditSalary: true };
    case PUT_SALARY_SUCCESS:
      return {
        ...state,
        isLoadingEditSalary: false
      };
    case PUT_SALARY_ERROR:
      return { ...state, isLoadingEditSalary: false };

    // DELETE CONTRACT
    case DELETE_CONTRACT_PENDING:
      return { ...state, isDeleteContract: true };
    case DELETE_CONTRACT_SUCCESS:
      return {
        ...state,
        isDeleteContract: false,
        deleteContract: payload.data
      };
    case DELETE_CONTRACT_ERROR:
      return { ...state, isDeleteContract: false };

    // DELETE SALARY
    case DELETE_SALARY_PENDING:
      return { ...state, isDeleteSalary: true };
    case DELETE_SALARY_SUCCESS:
      return {
        ...state,
        isDeleteSalary: false,
        deleteSalary: payload.data
      };
    case DELETE_SALARY_ERROR:
      return { ...state, isDeleteSalary: false };

    // MODAL GENERATE LOAN PROFILE
    case SET_MODAL_GENERATE_LOAN_PROFILE:
      return {
        ...state,
        setModalGenerateLoan: payload.data,
        isGenerateLoanAll: payload.value
      };

    default:
      return state;
  }
};
