import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Switch from "react-switch";
import { IMAGES } from "../../../../configs";
import { Modal, ModalSubmitForm } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import {
  getSalaryByEmployee,
  postAddSalary,
  putSalary,
  setModalAddSalary,
  setModalEditSalary
} from "../../../../redux/actions";

import "./styles.scss";
import { maskedMoney } from "../../../../utils";

interface Props {
  employee: string;
  isUpdate: boolean;
}

const ModalFormSalary = ({ employee, isUpdate }: Props) => {
  const { register, handleSubmit, errors, reset, setValue, watch } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialSubmitForm = {
    form: null,
    message: ""
  };
  const initialBody = {
    employee_id: employee,
    active: true,
    currency_id: 1
  };

  const [body, setBody] = useState(initialBody);
  const [isLoadingData, setLoadingData] = useState(true);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);

  const {
    contracts,
    setModal,
    formLoading,
    formLoadingEdit,
    staffState,
    detailSalary
  } = useSelector(
    (state: Reducers) => ({
      contracts: state.employee.staff.listContract,
      setModal: state.employee,
      formLoading: state.employee.isLoadingAddContract,
      formLoadingEdit: state.employee.isLoadingEditContract,
      staffState: state.employee.staff.data,
      detailSalary: state.employee.staff.detailSalary
    }),
    shallowEqual
  );

  useEffect(() => {
    if (setModal.setModalEditSalary) {
      setLoadingData(true);
      setValue(
        "contract_id",
        detailSalary[0] &&
          detailSalary[0].attributes &&
          detailSalary[0].attributes.contract_uuid
      );
      setValue(
        "started_at",
        moment(
          detailSalary[0] &&
            detailSalary[0].attributes &&
            detailSalary[0].attributes.started_at
        ).format("YYYY-MM-DD")
      );
      setValue(
        "finished_at",
        moment(
          detailSalary[0] &&
            detailSalary[0].attributes &&
            detailSalary[0].attributes.finished_at
        ).format("YYYY-MM-DD")
      );
      setValue(
        "amount",
        detailSalary[0] &&
          detailSalary[0].attributes &&
          Number(detailSalary[0].attributes.amount)
      );
      setValue(
        "remarks",
        detailSalary[0] &&
          detailSalary[0].attributes &&
          detailSalary[0].attributes.remarks
      );
      setTimeout(() => {
        setLoadingData(false);
      }, 300);
      detailSalary[0] &&
      detailSalary[0].attributes &&
      detailSalary[0].attributes.active === true
        ? (body.active = true)
        : (body.active = false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdate, detailSalary, setValue]);

  const populateContracts = () => {
    let result = [{ value: "", label: "none" }];
    contracts.map((e: any) => {
      result = [
        ...result,
        {
          value: e.id,
          label: `${e.number} ${e.active ? " - Active" : " - Not Active"}`
        }
      ];
      return true;
    });
    return result;
  };

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let selectedContract = "";

    if (tempData[0] !== "") {
      selectedContract =
        contracts.find(e => e.id === tempData[0].value) &&
        contracts.find(e => e.id === tempData[0].value).number;
    } else {
      selectedContract = "-";
    }

    const result: any = [
      { title: `${t("personnel.form.employeeName")}:`, value: staffState.name },
      {
        title: `${t("personnel.form.contractNo")}:`,
        value: selectedContract || "-"
      },
      {
        title: `${t("personnel.form.amount")}:`,
        value: maskedMoney(tempData[1].value) || "-"
      },
      {
        title: `${t("task.form.startDate")}:`,
        value: moment(tempData[2].value).format("DD MMM YYYY") || "-"
      },
      {
        title:
          tempData[3].title === "finished_at"
            ? `${t("personnel.form.finishDate")}:`
            : `${t("personnel.form.finishDate")}:`,
        value:
          tempData[3].title === "finished_at"
            ? moment(tempData[3].value).format("DD MMM YYYY")
            : "-"
      },
      {
        title:
          tempData[3].title === "remarks"
            ? `${t("personnel.form.remarks")}:`
            : "",
        value: tempData[3].title === "remarks" ? tempData[3].value : ""
      },
      {
        title:
          tempData[4].title === "remarks"
            ? `${t("personnel.form.remarks")}:`
            : "",
        value: tempData[4].title === "remarks" ? tempData[4].value : ""
      },
      {
        title: `${t("personnel.form.isactive")}:`,
        value: body.active ? t("yes") : t("no")
      }
    ];

    if (result) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body
    };
    const message = "";

    setSubmitForm({ ...submitForm, form, message });
    dispatch(
      postAddSalary(form, () => {
        dispatch(setModalAddSalary(false));
        dispatch(getSalaryByEmployee(employee));
        setModalSubmitForm(false);
        reset();
        setBody(initialBody);
      })
    );
  };

  const _onEdit = (data: any) => {
    const form = {
      ...data,
      active: body.active,
      currency_id: body.currency_id
    };
    const message = "";
    const salaryUuid = detailSalary[0] && detailSalary[0].attributes.uuid;

    setSubmitForm({ ...submitForm, form, message });
    dispatch(
      putSalary(form, salaryUuid, () => {
        dispatch(setModalEditSalary(false));
        dispatch(getSalaryByEmployee(employee));
        setModalSubmitForm(false);
        reset();
        setBody(initialBody);
      })
    );
  };

  const _onCancel = () => {
    reset();
    setBody(initialBody);
    dispatch(setModalAddSalary(false));
    dispatch(setModalEditSalary(false));
  };

  return (
    <>
      <Modal
        loading={setModal.setModalAddSalary ? formLoading : isLoadingData}
        titleModal={
          setModal.setModalAddSalary
            ? t("personnel.form.newSalaryTitle")
            : t("personnel.form.editSalaryTitle")
        }
        isOpen={setModal.setModalAddSalary || setModal.setModalEditSalary}
        onPressCancel={_onCancel}
        onPressSubmit={handleSubmit(_onConfirmSubmitForm)}
        btnSubmitText={
          setModal.setModalAddSalary ? t("btn.submit") : t("btn.edit")
        }
        btnCancelText={t("btn.cancel")}
      >
        <form
          className="margin-top-12"
          onSubmit={
            setModal.setModalAddSalary
              ? handleSubmit(_onSave)
              : handleSubmit(_onEdit)
          }
        >
          <div style={{ width: 494 }}>
            <div className="margin-top-20">
              {setModal.setModalEditSalary && (
                <>
                  <span className="component-modal-contract-form-label">
                    {t("personnel.form.employeeName")}
                  </span>
                  <div className="modal-assign-person-container margin-bottom-12">
                    <div className="modal-assign-person margin-right-8 margin-top-8">
                      <img
                        src={
                          staffState && staffState.avatar
                            ? staffState.avatar && staffState.avatar.url
                            : IMAGES.avatar
                        }
                        alt="n/a"
                        className="modal-assign-person-photo"
                      />
                      <div>
                        {`(${staffState.position &&
                          staffState.position.title}) `}
                        <span>{`${staffState.name}`}</span>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="margin-top-20">
              <span className="component-modal-request-task-label">
                {t("personnel.form.contractNo")}
              </span>
              <select
                name="contract_id"
                className="component-modal-input-dropdown margin-top-12"
                ref={register({
                  required: "Please select employee contract"
                })}
              >
                {populateContracts().map((item, index) => (
                  <option key={index} value={item.value} title={item.label}>
                    {item.label}
                  </option>
                ))}
              </select>
              <div className="component-modal-line" />
              <ErrorMessage
                errors={errors}
                name="contract_id"
                as="p"
                style={{ color: "red" }}
              />
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label">
                {t("personnel.form.amount")}
              </span>
              <input
                name="amount"
                type="number"
                className="component-modal-input margin-top-12"
                placeholder={t("personnel.form.amount")}
                ref={register({ required: "Salary amount is required" })}
                maxLength={200}
              />
              <ErrorMessage
                errors={errors}
                name="amount"
                as="p"
                style={{ color: "red" }}
              />
            </div>
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-contract-form-label">
                  {t("task.form.startDate")}
                </span>
                <input
                  placeholder="YYYY/MM/DD"
                  type="date"
                  style={{ color: "#666666" }}
                  name="started_at"
                  ref={register({ required: "Start date is required" })}
                  max={watch("finished_at")}
                />
                <div className="component-modal-line" />
                <ErrorMessage
                  errors={errors}
                  name="started_at"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-contract-form-label">
                  {t("personnel.form.finishDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="finished_at"
                    min={
                      watch("started_at") || moment().format("YYYY-MM-DD hh:mm")
                    }
                    ref={register({ required: "Finish date is required" })}
                    defaultValue=""
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="finished_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label ">
                {t("personnel.form.isactive")}
              </span>
            </div>
            <div className="form-wrap-switch">
              <span
                className={`component-modal-contract-form-label switch ${body.active &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("no")}
              </span>
              <Switch
                onChange={() => setBody({ ...body, active: !body.active })}
                checked={body.active}
                onColor="#ff7600"
                offColor="#888888"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() => setBody({ ...body, active: !body.active })}
              />
              <span
                className={`component-modal-contract-form-label switch ${!body.active &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("yes")}
              </span>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-contract-form-label">
                {t("personnel.form.remarks")}
              </span>
              <div className="global-shadow-card component-modal-contract-form-remarks margin-top-12">
                <textarea
                  name="remarks"
                  className="component-modal-contract-form-text-area"
                  ref={register()}
                />
              </div>
            </div>
          </div>
        </form>
      </Modal>
      <ModalSubmitForm
        isLoading={setModal.setModalAddSalary ? formLoading : formLoadingEdit}
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={
          setModal.setModalAddSalary
            ? handleSubmit(_onSave)
            : handleSubmit(_onEdit)
        }
        onCancel={() => setModalSubmitForm(false)}
      />
    </>
  );
};

export default memo(ModalFormSalary);
