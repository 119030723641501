import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

interface Props {
  color?: string;
}

const ArrowRightIcon = ({ color }: Props) => {
  const configState = useSelector((state: Reducers) => state.config);

  const _color = () => {
    if (color) {
      return color;
    }
    return configState.isDark ? "#fff" : "#000";
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="25"
      viewBox="0 0 10 25"
    >
      <text
        id="_"
        data-name="&gt;"
        transform="translate(0 19)"
        fill={_color()}
        fontSize="18"
        fontFamily="Poppins-Regular, Poppins"
        opacity="0.5"
      >
        <tspan x="0" y="0">
          &gt;
        </tspan>
      </text>
    </svg>
  );
};

export default memo(ArrowRightIcon);
