/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Slide, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { HeaderTittle } from "../../../components";
import SearchData from "./components/SearchData";
import SelectType from "./components/SelectType";
import DetailTask from "./components/DetailTask";
import DetailInvoice from "./components/DetailInvoice";
import MessageInfo from "./components/MessageInfo";
import { DestroyData, FindData } from "../../../redux/actions/deletedata";
// import { FindData } from "../../../redux/actions/deletedata";
import ModalDeleteData from "../../../components/ModalDeleteData";

const DeleteData = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [type, setType] = useState(null);
  const [dataId, setDataId] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const {
    data,
    success,
    message,
    success_delete,
    message_delete
  } = useSelector((state: any) => state.deletedata);

  useEffect(() => {
    setIsLoading(false);
    if (success_delete) {
      dispatch(FindData({ type, number: null }));
      toast(
        t(`deleteData.${message_delete.toLowerCase().replace(/\s+/g, "_")}`),
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-green",
          closeButton: false,
          draggable: false
        }
      );
    }
  }, [
    data,
    dispatch,
    isDelete,
    message_delete,
    success_delete,
    t,
    type,
    success
  ]);

  const _destroy = (_type: any, id: any) => {
    setType(_type);
    dispatch(DestroyData({ type: _type, id }));
  };

  const choose_type = e => {
    setType(e.target.value);
  };
  const getData = data_input => {
    setIsLoading(true);
    setIsDelete(false);
    if (type == null || type === "") {
      toast(t("deleteData.type_warning"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-green",
        closeButton: false,
        draggable: false
      });
      setIsLoading(false);
      return;
    }

    if (data_input == null || data_input === "") {
      toast("data search can't be empty", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-green",
        closeButton: false,
        draggable: false
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    dispatch(FindData({ type, number: data_input.trim() }));
  };

  const openModal = (_type: any, id: any) => {
    setType(_type);
    setDataId(id);
    setIsOpenModal(true);
  };
  const closeModal = () => {
    setIsOpenModal(false);
  };
  const submitData = () => {
    setIsOpenModal(false);
    _destroy(type, dataId);
  };
  return (
    <>
      <HeaderTittle title={t("deleteData.title")} />
      <SelectType choose={choose_type} translate={t} />
      <SearchData __action={getData} translate={t} />
      {!success ? (
        <MessageInfo _message={message} is_loading={isLoading} translate={t} />
      ) : type === "task" && data.type === "task" ? (
        <DetailTask
          is_loading={isLoading}
          data={data}
          destroy={openModal}
          translate={t}
        />
      ) : type === "finance" && data.type === "invoice" ? (
        <DetailInvoice
          is_loading={isLoading}
          data={data}
          destroy={openModal}
          translate={t}
        />
      ) : (
        ""
      )}

      <ModalDeleteData
        isOpen={isOpenModal}
        onPressCancel={closeModal}
        onPressSubmit={submitData}
      />
    </>
  );
};

export default DeleteData;
