import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { getSetting } from "../redux/actions";
import { Reducers } from "../redux/types";

const CopyWritings = (slug: string) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSetting());
  }, [dispatch]);
  const getLanguage = Cookies.get("locale");
  const setting = useSelector((state: Reducers) => state.auth.setting);
  const value =
    setting &&
    setting.list[0] &&
    setting.list[0].attributes &&
    setting.list[0].attributes.value;
  const value_en =
    setting &&
    setting.list[0] &&
    setting.list[0].attributes &&
    setting.list[0].attributes.value.EN;
  const value_id =
    setting &&
    setting.list[0] &&
    setting.list[0].attributes &&
    setting.list[0].attributes.value.ID;
  if (value) {
    if (getLanguage === "en") {
      return value_en[slug];
    }
    if (getLanguage === "id") {
      return value_id[slug];
    }
    return value_en[slug];
  }
  return "";
};
export default CopyWritings;
