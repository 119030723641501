import {
  GENERATE_LOAN_PROFILE_ERROR,
  GENERATE_LOAN_PROFILE_PENDING,
  GENERATE_LOAN_PROFILE_SUCCESS,
  GET_LIST_USER_ON_LOAN_ERROR,
  GET_LIST_USER_ON_LOAN_PENDING,
  GET_LIST_USER_ON_LOAN_SUCCESS,
  GET_LOAN_PROFILE_LIST_ERROR,
  GET_LOAN_PROFILE_LIST_PENDING,
  GET_LOAN_PROFILE_LIST_SUCCESS,
  GET_LOAN_SOURCES_BY_ID_ERROR,
  GET_LOAN_SOURCES_BY_ID_PENDING,
  GET_LOAN_SOURCES_BY_ID_SUCCESS,
  GET_LOAN_SOURCES_ERROR,
  GET_LOAN_SOURCES_PENDING,
  GET_LOAN_SOURCES_SUCCESS,
  UPDATE_LOAN_PROFILE_STATUS_ERROR,
  UPDATE_LOAN_PROFILE_STATUS_PENDING,
  UPDATE_LOAN_PROFILE_STATUS_SUCCESS
} from "../actions";
import { Action, LoanState } from "../types";

const initialState: LoanState = {
  listLoanSources: [],
  listLoanProfile: [],
  dataLoanSource: {},
  isLoadingListLoanProfile: false,
  isLoadingLoanSources: false,
  isLoadingGenerateLoanProfile: false,
  isLoadingUpdateLoanProfile: false,
  paginationListLoanProfile: null,
  listUserOnLoan: [],
  isLoadingListUserOnLoan: false
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case GET_LOAN_SOURCES_PENDING:
      return {
        ...state,
        isLoadingLoanSources: true
      };
    case GET_LOAN_SOURCES_SUCCESS:
      return {
        ...state,
        isLoadingLoanSources: false,
        listLoanSources: payload.data
      };
    case GET_LOAN_SOURCES_ERROR:
      return {
        ...state,
        isLoadingLoanSources: false
      };

    case GET_LOAN_PROFILE_LIST_PENDING:
      return {
        ...state,
        isLoadingListLoanProfile: true
      };
    case GET_LOAN_PROFILE_LIST_SUCCESS:
      return {
        ...state,
        isLoadingListLoanProfile: false,
        listLoanProfile: [...payload.data.data],
        paginationListLoanProfile: payload.data.meta.pagination
      };
    case GET_LOAN_PROFILE_LIST_ERROR:
      return {
        ...state,
        isLoadingListLoanProfile: false
      };

    case GET_LIST_USER_ON_LOAN_PENDING:
      return {
        ...state,
        isLoadingListUserOnLoan: true
      };
    case GET_LIST_USER_ON_LOAN_SUCCESS:
      return {
        ...state,
        isLoadingListUserOnLoan: false,
        listUserOnLoan: [...payload.data.data]
      };
    case GET_LIST_USER_ON_LOAN_ERROR:
      return {
        ...state,
        isLoadingListUserOnLoan: false
      };

    // Get Loan Source By ID
    case GET_LOAN_SOURCES_BY_ID_PENDING:
      return {
        ...state,
        isLoadingLoanSources: true
      };
    case GET_LOAN_SOURCES_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingLoanSources: false,
        dataLoanSource: payload.data
      };
    case GET_LOAN_SOURCES_BY_ID_ERROR:
      return {
        ...state,
        isLoadingLoanSources: false,
        dataLoanSource: {}
      };

    // Generate Loan Profile
    case GENERATE_LOAN_PROFILE_PENDING:
      return { ...state, isLoadingGenerateLoanProfile: true };
    case GENERATE_LOAN_PROFILE_SUCCESS:
      return {
        ...state,
        isLoadingGenerateLoanProfile: false
      };
    case GENERATE_LOAN_PROFILE_ERROR:
      return { ...state, isLoadingGenerateLoanProfile: false };

    // update Loan Profile Status
    case UPDATE_LOAN_PROFILE_STATUS_PENDING:
      return { ...state, isLoadingUpdateLoanProfile: true };
    case UPDATE_LOAN_PROFILE_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingUpdateLoanProfile: false
      };
    case UPDATE_LOAN_PROFILE_STATUS_ERROR:
      return { ...state, isLoadingUpdateLoanProfile: false };

    default:
      return state;
  }
};
