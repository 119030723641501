import { MasterState } from "../types";

const initialState: MasterState = {
  list_data: [],
  list_unit: [],
  list_parent_category: [],
  list_payment_category: [],
  list_bank: [],
  list_currency: [],
  data: null,
  is_product: false,
  success: false,
  is_hide: false,
  message: null,
  is_loading: true,
  paginationTask: {
    from: null,
    to: null,
    total: null,
    current_page: null,
    last_page: null,
    per_page: null
  },
  save_response: false,
  delete_response: false,
  message_response: null,
  data_response: null
};

const masterdata = (state = initialState, { type, payload }) => {
  switch (type) {
    case "LIST_DATA":
      return {
        ...state,
        list_data:
          payload && payload.data && payload.data.data ? payload.data.data : [],
        success: payload && payload.data ? payload.data.success : false,
        message: payload && payload.data ? payload.data.message : null,
        is_loading: false,
        paginationTask: {
          from:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.from
              : null,
          to:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.to
              : null,
          total:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.total
              : null,
          current_page:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.current_page
              : null,
          last_page:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.last_page
              : null,
          per_page:
            payload && payload.data && payload.data.meta
              ? payload.data.meta.pagination.per_page
              : null
        }
      };
    case "RELOAD":
      return {
        ...state,
        is_loading: payload.is_loading,
        save_response: payload.save_response,
        delete_response: payload.delete_response,
        message_response: payload.message_response
      };
    case "SEARCH_DATA":
      return {
        ...state,
        data: payload.data ? payload.data.data : null,
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false,
        is_product: !!(payload.data.data && payload.data.data.unit),
        is_hide:
          payload.data && payload.data.data ? payload.data.data.hidden : false,
        is_direct_item:
          payload.data && payload.data.data
            ? payload.data.data.direct_item
            : false
      };
    case "IS_PRODUCT":
      return {
        ...state,
        is_product: payload.is_product
      };

    case "LIST_UNIT":
      return {
        ...state,
        list_unit: payload.data ? payload.data.data : [],
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false
      };
    case "LIST_PARENT":
      return {
        ...state,
        list_parent_category:
          payload.data && payload.data.data && payload.data.data.data
            ? payload.data.data.data
            : null,
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false
      };
    case "LIST_BANK":
      return {
        ...state,
        list_bank: payload.data ? payload.data.data : [],
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false
      };
    case "LIST_CURRENCY":
      return {
        ...state,
        list_currency:
          payload.data && payload.data.data ? payload.data.data.data : null,
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false
      };
    case "LIST_PAYMENT_CATEGORY":
      return {
        ...state,
        list_payment_category: payload.data ? payload.data.data : null,
        success: payload.data.success,
        message: payload.data.message,
        is_loading: false
      };

    case "SAVE_RESPONSE":
      return {
        ...state,
        save_response: payload.data ? payload.data.success : null,
        message_response: payload.data ? payload.data.message : null,
        data_response: payload.data ? payload.data.data : null,
        data: payload.data ? payload.data.data : null
      };
    case "DELETE_RESPONSE":
      return {
        ...state,
        delete_response: payload.data ? payload.data.success : null,
        message_response: payload.data ? payload.data.message : null
      };
    case "IS_HIDE":
      return {
        ...state,
        is_hide: payload.is_hide
      };

    default:
      return state;
  }
};

export default masterdata;
