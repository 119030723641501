import React, { memo } from "react";

const TitleDjurneeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="148.17"
      height="47.34"
      viewBox="0 0 141.415 48.536"
    >
      <g
        id="Group_1570"
        data-name="Group 1570"
        transform="translate(-676.815 -442.806)"
      >
        <path
          id="Path_2652"
          data-name="Path 2652"
          d="M838.582,475.433a2.629,2.629,0,0,0-2.628,2.628v7.692c0,2.927-.238,4.27-.831,5.259a4.521,4.521,0,0,1-3.994,2.016c-2.53,0-4.152-1.067-4.152-6.881v-8.086a2.629,2.629,0,0,0-2.628-2.628h0a2.629,2.629,0,0,0-2.628,2.628v9.115c0,7.712,3.243,10.677,8.345,10.677,2.649,0,4.311-.939,6.286-2.877a2.438,2.438,0,0,0,4.865-.231V478.061a2.631,2.631,0,0,0-2.631-2.628"
          transform="translate(-108.722 -24.481)"
          fill="#fffff7"
        />
        <path
          id="Path_2653"
          data-name="Path 2653"
          d="M986.516,473.458a8.072,8.072,0,0,0-6.135,2.6,2.085,2.085,0,0,0-2.085-2.084h-.669a2.085,2.085,0,0,0-2.084,2.084v16.932a2.615,2.615,0,0,0,2.615,2.615h0a2.615,2.615,0,0,0,2.615-2.615v-7.493c0-2.91.2-4.169.865-5.231a4.391,4.391,0,0,1,3.854-2.006c2.6,0,4.169,1.022,4.169,6.843v7.886a2.615,2.615,0,1,0,5.231,0v-8.908c0-3.894-.393-5.781-1.573-7.473-1.416-2.045-3.776-3.147-6.8-3.147"
          transform="translate(-224.14 -22.999)"
          fill="#fffff7"
        />
        <path
          id="Path_2654"
          data-name="Path 2654"
          d="M926.653,473.753h-.393c-2.243,0-3.344.6-4.722,2.605a2.125,2.125,0,0,0-2.125-2.125h-.551a2.125,2.125,0,0,0-2.125,2.125v16.9a2.617,2.617,0,1,0,5.233,0v-9.345c0-3.366,1.519-5.056,4.633-5.151l.048,0a2.521,2.521,0,0,0,2.521-2.521,2.5,2.5,0,0,0-2.521-2.49"
          transform="translate(-180.018 -23.22)"
          fill="#fffff7"
        />
        <path
          id="Path_2655"
          data-name="Path 2655"
          d="M785.315,475.474a2.788,2.788,0,0,0-2.788,2.788v36.96a.631.631,0,0,0,.631.631h.343a4.6,4.6,0,0,0,4.6-4.6V478.262a2.788,2.788,0,0,0-2.788-2.788"
          transform="translate(-79.317 -24.511)"
          fill="#fffff7"
        />
        <path
          id="Path_2656"
          data-name="Path 2656"
          d="M785.315,442.808A2.788,2.788,0,1,0,788.1,445.6a2.788,2.788,0,0,0-2.788-2.788"
          transform="translate(-79.317 -0.002)"
          fill="#fffff7"
        />
        <path
          id="Path_2657"
          data-name="Path 2657"
          d="M747.254,442.806a2.627,2.627,0,0,0-2.629,2.627v5.428c.255.14.5.287.749.447a12.09,12.09,0,0,1,4.507,5.258V445.433a2.626,2.626,0,0,0-2.627-2.627"
          transform="translate(-50.879 0)"
          fill="#fffff7"
        />
        <path
          id="Path_2658"
          data-name="Path 2658"
          d="M693.928,475.051c-.06-.04-.122-.077-.182-.112a11.093,11.093,0,1,0-5.838,20.524h10.127A.965.965,0,0,0,699,494.5V484.37a11.073,11.073,0,0,0-5.074-9.318m-6.02,15.508a6.188,6.188,0,1,1,5.838-8.237,5.616,5.616,0,0,1,.182.614,6.233,6.233,0,0,1,0,2.869,5.621,5.621,0,0,1-.182.614,6.188,6.188,0,0,1-5.838,4.14"
          transform="translate(0 -22.862)"
          fill="#fffff7"
        />
        <path
          id="Path_2659"
          data-name="Path 2659"
          d="M1074.839,473.457c-6.028,0-10.8,5.076-10.8,11.488a11.066,11.066,0,0,0,11.1,11.332,10.562,10.562,0,0,0,7.206-2.829,2.4,2.4,0,0,0-1.674-4.135h-.082a2.389,2.389,0,0,0-1.679.706,4.971,4.971,0,0,1-3.882,1.457,5.445,5.445,0,0,1-5.658-4.288h13.511a2.525,2.525,0,0,0,2.528-2.573c0-.012,0-.022,0-.029-.254-6.461-4.644-11.13-10.566-11.13m-5.714,9.929.092-.8a5.5,5.5,0,0,1,5.7-4.328c3.034,0,5.03,1.535,5.66,4.328l.092.8Z"
          transform="translate(-290.541 -22.998)"
          fill="#fffff7"
        />
        <path
          id="Path_2660"
          data-name="Path 2660"
          d="M1176.451,487.189a2.525,2.525,0,0,0,2.528-2.573c0-.012,0-.022,0-.029-.254-6.461-4.644-11.13-10.567-11.13-6.028,0-10.8,5.076-10.8,11.488a11.066,11.066,0,0,0,11.1,11.332,10.561,10.561,0,0,0,7.206-2.829,2.4,2.4,0,0,0-1.674-4.135h-.082a2.39,2.39,0,0,0-1.679.706,4.97,4.97,0,0,1-3.882,1.457,5.445,5.445,0,0,1-5.658-4.288h13.512Zm-13.753-3.8.092-.8a5.5,5.5,0,0,1,5.7-4.328c3.034,0,5.03,1.535,5.66,4.328l.092.8Z"
          transform="translate(-360.75 -22.998)"
          fill="#fffff7"
        />
      </g>
    </svg>
  );
};

export default memo(TitleDjurneeIcon);
