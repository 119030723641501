import React, { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import Modal from "../Modal";
import "./styles.scss";

// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";
import { Reducers } from "../../redux/types";
import Spinner from "../Spinner";
import { Toast } from "../index";

interface Props {
  onPressSubmit?: (data, id: any) => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: string;
  onChange?: (e: any) => void;
  title?: string;
}

const ModalProjectCategory = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  title
}: Props) => {
  const { t } = useTranslation();

  const masterState = useSelector((state: Reducers) => state.masterdata);

  const [_form, _setForm] = useState({
    category_id: null,
    title: null,
    description: null,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    _setForm({
      category_id: masterState.data ? masterState.data.id : null,
      title: masterState.data ? masterState.data.title : null,
      description: masterState.data ? masterState.data.description : null,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  }, [masterState.data]);

  const setTitle = e => {
    _setForm({ ..._form, title: e.target.value });
  };

  const setDescription = e => {
    _setForm({ ..._form, description: e.target.value });
  };

  const __submited = __callback => {
    const id = masterState.data ? masterState.data.id : null;
    if (!_form.title) {
      setError(true);
      Toast({
        message: t("master_data.validation.title_is_required"),
        is_success: false
      });
      return;
    }
    __callback(id, _form);

    _setForm({
      category_id: null,
      title: null,
      description: null,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const __closedModal = __callback => {
    setError(false);
    __callback();
  };

  return (
    <Modal
      titleModal={title}
      isOpen={isOpen}
      onPressCancel={() => __closedModal(onPressCancel)}
      onPressSubmit={() => __submited(onPressSubmit)}
      btnSubmitText={t("master_data.submit").toUpperCase()}
      btnCancelText={t("deleteData.cancel").toUpperCase()}
    >
      <div style={{ width: "550px", marginTop: "30px" }}>
        {masterState.is_loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <form style={{ width: "100%" }}>
            <div>
              <span className="component-modal-request-task-label">
                {t("master_data.form.title").toUpperCase()}
              </span>
              <input
                onKeyUp={setTitle}
                type="text"
                defaultValue={masterState.data ? masterState.data.title : null}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottom: "1px solid lightgrey"
                }}
              />
              <span style={{ color: "red" }}>
                {(!_form.title ||
                  (masterState.data && !masterState.data.title)) &&
                error
                  ? t("master_data.validation.title_is_required")
                  : ""}
              </span>
            </div>
            <div style={{ marginTop: 20 }}>
              <span className="component-modal-request-task-label">
                {t("master_data.form.description").toUpperCase()}
              </span>
              <textarea
                onKeyUp={setDescription}
                defaultValue={
                  masterState.data && masterState.data.description !== "null"
                    ? masterState.data.description
                    : null
                }
                rows={10}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1.6rem",
                  border: "none",
                  width: "100%",
                  borderBottom: "1px solid lightgrey"
                }}
              />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default memo(ModalProjectCategory);
