import React, { memo } from "react";

import Modal from "../Modal";

import "./styles.scss";

interface Props {
  onPressSubmit?: () => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: number | string;
  onChangeValue?: (e: any) => void;
}

const ModalExtendTask = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  value,
  onChangeValue
}: Props) => {
  return (
    <Modal
      titleModal="Extend Task"
      isOpen={isOpen}
      onPressCancel={onPressCancel}
      onPressSubmit={onPressSubmit}
    >
      <form
        className="form-extend-task"
        onSubmit={e => {
          e.preventDefault();
          onPressSubmit && onPressSubmit();
        }}
      >
        <div
          style={{ display: "flex", fontWeight: "bold" }}
          className="margin-top-52"
        >
          Extra
          <div style={{ width: 100 }} className="margin-left-8 margin-right-8">
            <input
              className="input-extends-task"
              type="number"
              placeholder="how many?"
              value={value}
              onChange={onChangeValue}
              ref={input => {
                setTimeout(() => input && input.focus(), 100);
              }}
            />
            <input type="submit" hidden />
            <div className="component-modal-line" />
          </div>
          days
        </div>
      </form>
    </Modal>
  );
};

export default memo(ModalExtendTask);
