import i18n from "../../i18n";
import {
  DELETE_EMPLOYEE_ERROR,
  DELETE_EMPLOYEE_PENDING,
  DELETE_EMPLOYEE_SUCCESS,
  GET_ALL_PERSONAL_ERROR,
  GET_ALL_PERSONAL_PENDING,
  GET_ALL_PERSONAL_SUCCESS,
  GET_ALL_POSITION_ERROR,
  GET_ALL_POSITION_PENDING,
  GET_ALL_POSITION_SUCCESS,
  GET_CLIENT_ERROR,
  GET_CLIENT_PENDING,
  GET_CLIENT_SUCCESS,
  GET_CSV,
  GET_CSV_ERROR,
  GET_CSV_PENDING,
  GET_DEPARTMENT_ERROR,
  GET_DEPARTMENT_PENDING,
  GET_DEPARTMENT_SUCCESS,
  GET_EMPLOYEE_ERROR,
  GET_EMPLOYEE_PENDING,
  GET_EMPLOYEE_SUCCESS,
  GET_HTML,
  GET_HTML_ERROR,
  GET_HTML_PENDING,
  GET_PDF,
  GET_PDF_ERROR,
  GET_PDF_PENDING,
  GET_PERSONAL_ERROR,
  GET_PERSONAL_PENDING,
  GET_PERSONAL_SUCCESS,
  GET_PERSON_BY_POSITION_ERROR,
  GET_PERSON_BY_POSITION_PENDING,
  GET_PERSON_BY_POSITION_SUCCESS,
  GET_POPULATE_EMPLOYEE_ERROR,
  GET_POPULATE_EMPLOYEE_PENDING,
  GET_POPULATE_EMPLOYEE_SUCCESS,
  GET_POSITION_ERROR,
  GET_POSITION_PENDING,
  GET_POSITION_SUCCESS,
  GET_PROJECT_BY_ID_ERROR,
  GET_PROJECT_BY_ID_PENDING,
  GET_PROJECT_BY_ID_SUCCESS,
  GET_PROJECT_ERROR,
  GET_PROJECT_PENDING,
  GET_PROJECT_SUCCESS,
  GET_REPORT_TYPE_ERROR,
  GET_REPORT_TYPE_PENDING,
  GET_REPORT_TYPE_SUCCESS,
  GET_SPECIAL_ROLE_ERROR,
  GET_SPECIAL_ROLE_PENDING,
  GET_SPECIAL_ROLE_SUCCESS,
  PUT_SAVE_MY_PROFILE_SUCCESS,
  RESET_EMPLOYEE_SUCCESS,
  SET_ACTIVE_DEPARTMENT,
  SET_ACTIVE_PERSONIL,
  SET_ACTIVE_POSITION,
  SET_ACTIVE_ROLE,
  SET_DEPARTEMENT_NAME,
  SET_PERSONIL_NAME,
  SET_POSITION_NAME
} from "../actions";
import { Action, GenerateState } from "../types";

const intialState: GenerateState = {
  listReportType: [],
  isLoadingReportType: false,
  listDepartment: [],
  isLoadingDepartment: false,
  listPersonal: [],
  isLoadingPersonal: false,
  listClient: [],
  isLoadingClient: false,
  listProject: [],
  isLoadingProject: false,
  html: null,
  isLoadingHtml: false,
  pdf: null,
  isLoadingPdf: false,
  csv: null,
  isLoadingCsv: false,
  pdfName: null,
  csvName: null,
  dataDepartment: [],
  allPersonal: [],
  isLoadingAllPersonal: false,
  activeDepartment: {
    index: -1,
    id: ""
  },
  allPosition: [],
  isLoadingAllPosition: false,
  activePosition: { index: -1, id: "" },
  activePersonil: { index: -1, id: "" },
  dataPersonal: [],
  positionById: [],
  projectById: {},
  isLoadingProjectById: false,
  isloadingPositionByid: false,
  personalByIdPosition: [],
  isLoadingPersonalByIdPosition: false,
  activeSpecialRole: -1,
  specialRole: [],
  isLoadingSpecialRole: false,
  listEmployee: [],
  listAllEmployee: [],
  isLoadingListEmployee: false,
  isLoadingListAllEmployee: false,
  pagination: {},
  departementName: "",
  positionName: "",
  personilName: "",
  department: {
    isLoading: false,
    list: []
  },
  dataProject: [],
  populateEmployee: [],
  populateAllEmployee: []
};

let listReportType: any[];
let listDepartment: any[];
let listPersonal: any[];
let listClient: any[];
let listProject: any[];
let populateEmployee: any[];
let populateAllEmployee: any[];

let index;
let result;

export default (state = intialState, { type, payload }: Action) => {
  switch (type) {
    case GET_REPORT_TYPE_PENDING:
      return {
        ...state,
        isLoadingReportType: true,
        listReportType: []
      };
    case GET_REPORT_TYPE_SUCCESS:
      listReportType = [];
      payload.data.map((item: any) => {
        listReportType = [
          ...listReportType,
          { value: item.value, label: item.value }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingReportType: false,
        listReportType
      };
    case GET_REPORT_TYPE_ERROR:
      return {
        ...state,
        isLoadingReportType: false
      };
    case GET_DEPARTMENT_PENDING:
      return {
        ...state,
        isLoadingDepartment: true
      };
    case GET_DEPARTMENT_SUCCESS:
      listDepartment = [{ value: "", label: "All" }];
      payload.data.map((item: any) => {
        listDepartment = [
          ...listDepartment,
          { value: item.attributes.uuid, label: item.attributes.title }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingDepartment: false,
        listDepartment,
        dataDepartment: [...payload.data]
      };
    case GET_DEPARTMENT_ERROR:
      return {
        ...state,
        isLoadingDepartment: false
      };
    case GET_PERSONAL_PENDING:
      return {
        ...state,
        isLoadingPersonal: true
      };
    case GET_PERSONAL_SUCCESS:
      listPersonal = [{ value: "", label: "All" }];
      payload.data.map((item: any) => {
        listPersonal = [
          ...listPersonal,
          { value: item.id, label: item.attributes.name }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingPersonal: false,
        listPersonal,
        dataPersonal: [...payload.data]
      };
    case GET_PERSONAL_ERROR:
      return {
        ...state,
        isLoadingPersonal: false
      };
    case GET_CLIENT_PENDING:
      return {
        ...state,
        isLoadingClient: true
      };
    case GET_CLIENT_SUCCESS:
      listClient = [{ value: "", label: "All" }];
      payload.data.map((item: any) => {
        listClient = [
          ...listClient,
          { value: item.id, label: item.attributes.name }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingClient: false,
        listClient
      };
    case GET_CLIENT_ERROR:
      return {
        ...state,
        isLoadingClient: false
      };
    case GET_PROJECT_PENDING:
      return {
        ...state,
        isLoadingProject: true
      };
    case GET_PROJECT_SUCCESS:
      listProject = [{ value: "", label: "All" }];
      payload.data.map((item: any) => {
        listProject = [
          ...listProject,
          { value: item.id, label: item.attributes.title }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingProject: false,
        listProject,
        dataProject: payload.data
      };
    case GET_PROJECT_ERROR:
      return {
        ...state,
        isLoadingProject: false
      };

    case GET_PROJECT_BY_ID_PENDING:
      return {
        ...state,
        isLoadingProject: true
      };
    case GET_PROJECT_BY_ID_SUCCESS:
      return {
        ...state,
        isLoadingProject: false,
        projectById: payload.data
      };
    case GET_PROJECT_BY_ID_ERROR:
      return {
        ...state,
        isLoadingProject: false
      };

    case GET_HTML:
      return {
        ...state,
        html: payload.data,
        isLoadingHtml: false
      };
    case GET_HTML_PENDING:
      return {
        ...state,
        isLoadingHtml: true
      };
    case GET_HTML_ERROR:
      return {
        ...state,
        isLoadingHtml: false
      };

    case GET_PDF:
      return {
        ...state,
        pdf: payload.data,
        pdfName: payload.name,
        isLoadingPdf: false
      };
    case GET_PDF_PENDING:
      return {
        ...state,
        isLoadingPdf: true
      };
    case GET_PDF_ERROR:
      return {
        ...state,
        isLoadingPdf: false
      };

    case GET_CSV:
      return {
        ...state,
        csv: payload.data,
        csvName: payload.name,
        isLoadingCsv: false
      };
    case GET_CSV_PENDING:
      return {
        ...state,
        isLoadingCsv: true
      };
    case GET_CSV_ERROR:
      return {
        ...state,
        isLoadingCsv: false
      };
    case GET_ALL_PERSONAL_PENDING:
      return {
        ...state,
        isLoadingAllPersonal: true,
        allPersonal: []
      };
    case GET_ALL_PERSONAL_SUCCESS:
      return {
        ...state,
        isLoadingAllPersonal: false,
        allPersonal: [...payload.data]
      };
    case GET_ALL_PERSONAL_ERROR:
      return {
        ...state,
        isLoadingAllPersonal: false,
        allPersonal: []
      };
    case GET_PERSON_BY_POSITION_PENDING:
      return {
        ...state,
        isLoadingPersonalByIdPosition: true,
        allPersonal: []
      };
    case GET_PERSON_BY_POSITION_SUCCESS:
      return {
        ...state,
        isLoadingPersonalByIdPosition: false,
        personalByIdPosition: [...payload.data]
      };
    case GET_PERSON_BY_POSITION_ERROR:
      return {
        ...state,
        isLoadingPersonalByIdPosition: false,
        personalByIdPosition: []
      };
    case GET_ALL_POSITION_PENDING:
      return {
        ...state,
        isLoadingAllPosition: true,
        personalByIdPosition: []
      };
    case GET_ALL_POSITION_SUCCESS:
      return {
        ...state,
        isLoadingAllPosition: false,
        allPosition: payload.data
      };
    case GET_ALL_POSITION_ERROR:
      return {
        ...state,
        isLoadingAllPosition: false,
        allPosition: []
      };
    case GET_POSITION_PENDING:
      return {
        ...state,
        isloadingPositionByid: true,
        positionById: []
      };
    case GET_POSITION_SUCCESS:
      return {
        ...state,
        isloadingPositionByid: false,
        positionById: [...payload.data]
      };
    case GET_POSITION_ERROR:
      return {
        ...state,
        isloadingPositionByid: false,
        positionById: []
      };
    case GET_SPECIAL_ROLE_PENDING:
      return {
        ...state,
        isLoadingSpecialRole: true,
        specialRole: []
      };
    case GET_SPECIAL_ROLE_SUCCESS:
      return {
        ...state,
        isLoadingSpecialRole: false,
        specialRole: [...payload.data]
      };
    case GET_SPECIAL_ROLE_ERROR:
      return {
        ...state,
        isLoadingSpecialRole: false,
        specialRole: []
      };
    case GET_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoadingListEmployee: true,
        listEmployee: []
      };
    case GET_EMPLOYEE_SUCCESS:
      populateEmployee = [
        { value: "", label: i18n.t("company.form.selectEmployee") }
      ];
      payload.data.map((item: any) => {
        populateEmployee = [
          ...populateEmployee,
          { value: item.id, label: item.name }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingListEmployee: false,
        listEmployee: [...payload.data],
        pagination: payload.page,
        populateEmployee
      };
    case GET_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoadingListEmployee: false,
        listEmployee: []
      };

    case DELETE_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoadingListEmployee: true
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingListEmployee: false,
        listEmployee: state.listEmployee.filter(
          item => item.id !== payload.data
        )
      };
    case DELETE_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoadingListEmployee: false
      };

    case PUT_SAVE_MY_PROFILE_SUCCESS:
      if (!payload.isNew) {
        result = [...state.listEmployee];
        index = result.findIndex(e => e.id === payload.data.id);
        if (index >= 0) {
          result[index] = payload.data;
        }
      }

      return {
        ...state,
        listEmployee: payload.isNew
          ? [...state.listEmployee, payload.data]
          : result
      };
    case SET_ACTIVE_DEPARTMENT:
      return { ...state, activeDepartment: payload.data };
    case SET_ACTIVE_POSITION:
      return { ...state, activePosition: payload.data };
    case SET_ACTIVE_PERSONIL:
      return { ...state, activePersonil: payload.data };
    case SET_ACTIVE_ROLE:
      return { ...state, activeSpecialRole: payload.data };
    case SET_DEPARTEMENT_NAME:
      return { ...state, departementName: payload.data };
    case SET_POSITION_NAME:
      return { ...state, positionName: payload.data };
    case SET_PERSONIL_NAME:
      return { ...state, personilName: payload.data };

    case GET_POPULATE_EMPLOYEE_PENDING:
      return {
        ...state,
        isLoadingListAllEmployee: true,
        listAllEmployee: []
      };
    case GET_POPULATE_EMPLOYEE_SUCCESS:
      populateAllEmployee = [{ value: "", label: "Select Employee" }];
      payload.data.map((item: any) => {
        populateAllEmployee = [
          ...populateAllEmployee,
          { value: item.id, label: item.name }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingListAllEmployee: false,
        listAllEmployee: [...payload.data],
        populateAllEmployee
      };
    case GET_POPULATE_EMPLOYEE_ERROR:
      return {
        ...state,
        isLoadingListAllEmployee: false,
        listAllEmployee: []
      };

    case RESET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        isLoadingListEmployee: false,
        listEmployee: [],
        pagination: {}
      };

    default:
      return state;
  }
};
