import React, { memo, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "./styles.scss";
import { Button, Container, Modal } from "../../../../components";
import { IMAGES } from "../../../../configs";
import {
  deleteContract,
  getContractsByEmployee,
  getContractsById,
  setModalEditContract
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import { Permission } from "../../../../utils";
import { ModalFormContract } from "..";

interface Props {
  employee: string;
}

const TableContracts = ({ employee }: Props) => {
  const dispatch = useDispatch();
  const permission = {
    list: Permission("employee-others-list"),
    others: Permission("employee-others-view"),
    edit: Permission("employee-others-edit")
  };
  const { t } = useTranslation();

  const [isSetUpdate, setUpdate] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [contractId, setContractId] = useState("");

  const { employeeState, contractState, homeState } = useSelector(
    (state: Reducers) => ({
      company: state.company,
      employeeState: state.employee,
      contractState: state.employee.staff.listContract,
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getContractsByEmployee(employee));
    if (!employeeState.isDeleteContract && isDelete) {
      setDelete(false);
      dispatch(getContractsByEmployee(employee));
      setDeleteModal(false);
    }
  }, [dispatch, employee, employeeState.isDeleteContract, isDelete]);

  const _statusColor = (status: boolean) => {
    let color = "";
    if (status === false) {
      color = "color-y";
    } else {
      color = "color-g";
    }
    return color;
  };

  const statusDesc = (status: boolean) => {
    let label = "";
    if (status === true) {
      label = t("personnel.employeeDetail.tbl.active");
    } else {
      label = t("personnel.employeeDetail.tbl.notActive");
    }
    return label;
  };

  const _deleteContract = (id: string) => {
    if (!isDelete) {
      setDelete(true);
      dispatch(
        deleteContract(id, e => {
          setMessage(e);
          setWarning(true);
          setDeleteModal(false);
        })
      );
      setDeleteModal(false);
    }
  };

  const attachment_info = (data: any) => {
    if (data.attachment && data.attachment.length > 0) {
      return (
        <img src={IMAGES.document} alt="n/a" className="task-image-list" />
      );
    }
    return "";
  };

  return (
    <>
      <Container isLoading={employeeState.isLoading} style={{ marginTop: 70 }}>
        {contractState.length > 0 ? (
          <table className="data-table">
            <thead>
              <tr>
                <th>{t("personnel.employeeDetail.tbl.contractNo")}</th>
                <th colSpan={2}>{t("personnel.employeeDetail.tbl.date")}</th>
                <th>{t("personnel.employeeDetail.tbl.remarks")}</th>
                <th style={{ paddingLeft: "46px" }}>
                  {t("personnel.employeeDetail.tbl.attachment")}
                </th>
                <th colSpan={2} style={{ paddingLeft: "46px" }}>
                  {t("personnel.employeeDetail.tbl.status")}
                </th>
              </tr>
            </thead>
            <tbody>
              {contractState.map((item: any, index: number) => (
                <tr key={index}>
                  <td className="col-subject">
                    <p>{item.number}</p>
                  </td>
                  <td className="grey">
                    <div>{t("personnel.table.startDate")}</div>
                    <div>{t("personnel.table.endDate")}</div>
                  </td>
                  <td className="col-date">
                    <div>
                      {moment(item.started_at).format("ddd, DD MMM YYYY")}
                    </div>
                    <div>
                      {moment(item.finished_at).format("ddd, DD MMM YYYY")}
                    </div>
                  </td>
                  <td className="col-remarks">
                    <p className="table-list-ellipsis">{item.remarks}</p>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {attachment_info(item)}
                  </td>
                  <td className="col-status">
                    <div className={`status ${_statusColor(item.active)}`}>
                      <div className="table-list-status-td">
                        <p className="bold">{statusDesc(item.active)}</p>
                      </div>
                      <div className="table-list-action actions">
                        {permission.edit ||
                        homeState.profile.listRole.includes(
                          "personal-loan-hr"
                        ) ? (
                          <>
                            <Button
                              onPress={() => {
                                dispatch(getContractsById(item.id));
                                setUpdate(true);
                                dispatch(setModalEditContract(true));
                              }}
                            >
                              <img
                                src={IMAGES.pencil}
                                alt="n/a"
                                style={{ height: 50, width: 50 }}
                                className="pointer"
                              />
                            </Button>
                            <Button
                              onPress={() => {
                                setDeleteModal(true);
                                setContractId(item.id);
                              }}
                            >
                              <img
                                src={IMAGES.deleteRound}
                                alt="n/a"
                                style={{ height: 50, width: 50 }}
                                className="pointer"
                              />
                            </Button>
                          </>
                        ) : null}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="table-empty-data">{t("emptyData")}</div>
        )}
      </Container>
      <ModalFormContract employee={employee} isUpdate={isSetUpdate} />
      <Modal
        warning
        btnSubmitText={
          employeeState.isDeleteContract ? "LOADING" : t("btn.submit")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModal}
        onPressCancel={() => setDeleteModal(false)}
        message={String(t("personnel.form.deleteMsg"))}
        onPressSubmit={() => _deleteContract(contractId)}
      />
      <Modal
        warning
        titleModal="WARNING"
        isOpen={warning}
        onPressCancel={() => setWarning(false)}
        message={message}
        mandatory
      />
    </>
  );
};

export default memo(TableContracts);
