import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { Modal, Text } from "..";

interface Props {
  open: boolean;
  onCancel: () => void;
  onPress: () => void;
  data: any[];
  isLoading?: boolean;
}

const ModalSubmitForm = ({
  open,
  onCancel,
  onPress,
  data,
  isLoading
}: Props) => {
  const { t } = useTranslation();
  const _renderValue = (title: string, value: any, index: number) => {
    if (Array.isArray(value[0]) && value[0].length > 0) {
      return (
        <div key={index}>
          <Text className="modal-confirm-label">{title}</Text>
          <div className="modal-confirm-wrap-table">
            <table className="data-table">
              <tbody className="wrap-normal">
                {value.map((e, i) =>
                  i === 0 ? (
                    <tr key={i}>
                      {e.map((item, num) => (
                        <th key={num}>{item}</th>
                      ))}
                    </tr>
                  ) : (
                    <tr key={i}>
                      {e.map((item, num) => (
                        <td key={num}>{item}</td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      );
    }
    if (Array.isArray(value)) {
      return (
        <div key={index} style={{ display: "flex", flexDirection: "row" }}>
          <Text className="modal-confirm-label">{title}</Text>
          <Text className="modal-confirm-value">
            {`${Array.isArray(value) ? value.join(", ") : value || "-"}`}
          </Text>
        </div>
      );
    }
    return (
      <div key={index} style={{ display: "flex", flexDirection: "row" }}>
        <Text className="modal-confirm-label">{title}</Text>
        <Text className="modal-confirm-value">{value}</Text>
      </div>
    );
  };

  return (
    <Modal
      loading={isLoading}
      isOpen={open}
      titleModal="Confirm Submit"
      onPressCancel={onCancel}
      onPressSubmit={onPress}
      btnCancelText={t("btn.cancel")}
      btnSubmitText={t("btn.submit")}
    >
      <div className="modal-confirm-container">
        <Text className="warn-text">{t("confirmSubmission.msg")}</Text>

        {data.map((item, index) => _renderValue(item.title, item.value, index))}
      </div>
    </Modal>
  );
};

export default ModalSubmitForm;
