import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./styles.scss";
import "../../../../sass/styles.scss";
import { documentTitle } from "../../../../utils";
import { Card, Container } from "../../../../components";
import { Filter, SidebarTop, Widget } from "./components";

// action && type redux
import {
  getListWidget,
  getProfileById,
  getProjectByid
} from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";
import WidgetUser from "../UserPage/components/WidgetUser";

interface Props {
  type?: "default" | "user" | "project" | "department";
}

const Home = ({ type }: Props) => {
  documentTitle(`Home${type === "default" ? "" : ` - ${type}`}`);
  const dispatch = useDispatch();
  const { uuid } = useParams() as any;

  const homeState = useSelector((state: Reducers) => state.home);

  useEffect(() => {
    if (type === "user") {
      dispatch(getProfileById(uuid!));
      dispatch(getListWidget(uuid));
    } else if (type === "project") {
      dispatch(getListWidget("", uuid));
      dispatch(getProjectByid(uuid!));
    } else if (type === "department") {
      dispatch(getListWidget("", "", uuid));
      dispatch(getProfileById(uuid!));
    } else {
      dispatch(getListWidget("", ""));
    }
  }, [dispatch, uuid, type]);

  const _handleRenderContent = () => {
    return (
      <div className="dashboard-home-content-person">
        <WidgetUser type={type} />
      </div>
    );
  };

  return (
    <Container>
      <div className="dashboard-home-container">
        <div className="dashboard-home-content">
          <div className="dashboard-filter-container dashboard-home-content-row dashboard-home-content-row-margin-bottom">
            <Filter />
          </div>

          <Container
            isLoading={homeState.listWidget && homeState.listWidget.isLoading}
          >
            <div className="dashboard-home-content-row dashboard-home-scroll-view global-scroll-bar">
              {type === "user" || type === "project"
                ? _handleRenderContent()
                : null}
              {homeState.listWidget.data.map((item, index) => (
                <Widget item={item} index={index} key={index} />
              ))}
            </div>
          </Container>
        </div>

        <div className="dashboard-home-side">
          <Card className="dashboard-home-content-wrap">
            <Container isLoading={homeState.listWidget.isLoading}>
              <div className="dashboard-home-content-card">
                <SidebarTop />
              </div>
              {/* <div
                className="dashboard-home-content-card global-scroll-bar"
                style={{ overflowY: "auto" }}
              >
                <SidebarBottom />
              </div> */}
            </Container>
          </Card>
        </div>
      </div>
    </Container>
  );
};

Home.defaultProps = { type: "default" };

export default Home;
