import React, { memo } from "react";

// import { Text } from "../../../../components";
import "./styles.scss";
import { HeaderTittle } from "../../../../components";

const HeaderPolicy = () => (
  <div className="header-policy">
    <div className="termpolicy-headerpolicy-container">
      <HeaderTittle title="Policy" />
    </div>
  </div>
);

export default memo(HeaderPolicy);
