import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_REPORT_TYPE_PENDING = "GET_REPORT_TYPE_PENDING";
export const GET_REPORT_TYPE_SUCCESS = "GET_REPORT_TYPE_SUCCESS";
export const GET_REPORT_TYPE_ERROR = "GET_REPORT_TYPE_ERROR";

export const GET_DEPARTMENT_PENDING = "GET_DEPARTMENT_PENDING";
export const GET_DEPARTMENT_SUCCESS = "GET_DEPARTMENT_SUCCESS";
export const GET_DEPARTMENT_ERROR = "GET_DEPARTMENT_ERROR";

export const GET_PERSONAL_PENDING = "GET_PERSONAL_PENDING";
export const GET_PERSONAL_SUCCESS = "GET_PERSONAL_SUCCESS";
export const GET_PERSONAL_ERROR = "GET_PERSONAL_ERROR";

export const GET_CLIENT_PENDING = "GET_CLIENT_PENDING";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_ERROR = "GET_CLIENT_ERROR";

export const GET_PROJECT_PENDING = "GET_PROJECT_PENDING";
export const GET_PROJECT_SUCCESS = "GET_PROJECT_SUCCESS";
export const GET_PROJECT_ERROR = "GET_PROJECT_ERROR";

export const GET_PROJECT_BY_ID_PENDING = "GET_PROJECT_BY_ID_PENDING";
export const GET_PROJECT_BY_ID_SUCCESS = "GET_PROJECT_BY_ID_SUCCESS";
export const GET_PROJECT_BY_ID_ERROR = "GET_PROJECT_BY_ID_ERROR";

export const GET_HTML = "GET_HTML";
export const GET_HTML_PENDING = "GET_HTML_PENDING";
export const GET_HTML_ERROR = "GET_HTML_ERROR";

export const GET_PDF = "GET_PDF";
export const GET_PDF_PENDING = "GET_PDF_PENDING";
export const GET_PDF_ERROR = "GET_PDF_ERROR";

export const GET_CSV = "GET_CSV";
export const GET_CSV_PENDING = "GET_CSV_PENDING";
export const GET_CSV_ERROR = "GET_CSV_ERROR";

export const GET_ALL_PERSONAL_PENDING = "GET_ALL_PERSONAL_PENDING";
export const GET_ALL_PERSONAL_SUCCESS = "GET_ALL_PERSONAL_SUCCESS";
export const GET_ALL_PERSONAL_ERROR = "GET_ALL_PERSONAL_ERROR";

export const GET_ALL_POSITION_PENDING = "GET_ALL_POSITION_PENDING";
export const GET_ALL_POSITION_SUCCESS = "GET_ALL_POSITION_SUCCESS";
export const GET_ALL_POSITION_ERROR = "GET_ALL_POSITION_ERROR";

export const SET_ACTIVE_DEPARTMENT = "SET_ACTIVE_DEPARTMENT";
export const SET_ACTIVE_POSITION = "SET_ACTIVE_POSITION";
export const SET_ACTIVE_PERSONIL = "SET_ACTIVE_PERSONIL";
export const SET_ACTIVE_ROLE = "SET_ACTIVE_ROLE";

export const GET_POSITION_PENDING = "GET_POSITION_PENDING";
export const GET_POSITION_SUCCESS = "GET_POSITION_SUCCESS";
export const GET_POSITION_ERROR = "GET_POSITION_ERROR";

export const GET_PERSON_BY_POSITION_PENDING = "GET_PERSON_BY_POSITION_PENDING";
export const GET_PERSON_BY_POSITION_SUCCESS = "GET_PERSON_BY_POSITION_SUCCESS";
export const GET_PERSON_BY_POSITION_ERROR = "GET_PERSON_BY_POSITION_ERROR";

export const GET_SPECIAL_ROLE_PENDING = "GET_SPECIAL_ROLE_PENDING";
export const GET_SPECIAL_ROLE_SUCCESS = "GET_SPECIAL_ROLE_SUCCESS";
export const GET_SPECIAL_ROLE_ERROR = "GET_SPECIAL_ROLE_ERROR";

export const GET_EMPLOYEE_PENDING = "GET_EMPLOYEE_PENDING";
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_EMPLOYEE_ERROR = "GET_EMPLOYEE_ERROR";

export const RESET_EMPLOYEE_SUCCESS = "RESET_EMPLOYEE_SUCCESS";

export const GET_POPULATE_EMPLOYEE_PENDING = "GET_POPULATE_EMPLOYEE_PENDING";
export const GET_POPULATE_EMPLOYEE_SUCCESS = "GET_POPULATE_EMPLOYEE_SUCCESS";
export const GET_POPULATE_EMPLOYEE_ERROR = "GET_POPULATE_EMPLOYEE_ERROR";

export const SET_DEPARTEMENT_NAME = "SET_DEPARTEMENT_NAME";
export const SET_POSITION_NAME = "SET_POSITION_NAME";
export const SET_PERSONIL_NAME = "SET_PERSONIL_NAME";

export const DELETE_EMPLOYEE_PENDING = "DELETE_EMPLOYEE_PENDING";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_ERROR = "DELETE_EMPLOYEE_ERROR";

export const getReportType = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_REPORT_TYPE_PENDING });

    const res = await API.getReportType();
    dispatch({
      type: GET_REPORT_TYPE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_REPORT_TYPE_ERROR });
  }
};

export const getDepartment = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_DEPARTMENT_PENDING });

    const res = await API.getDepartment();
    dispatch({
      type: GET_DEPARTMENT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_DEPARTMENT_ERROR });
  }
};

export const getPersonal = (id?: string, idPos?: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_PERSONAL_PENDING });

    const res = await API.getPersonal(id, idPos);
    dispatch({ type: GET_PERSONAL_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PERSONAL_ERROR });
  }
};

export const getEmployee = (
  id?: string,
  idPos?: string,
  idRole?: string,
  page = 1,
  hidden = false,
  q?: string,
  parent?: boolean,
  show_self?: boolean,
  hiddenOutSource?: boolean,
  disable?: boolean,
  bypassPermission?: boolean,
  per_page?: number
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYEE_PENDING });

    const res = await API.getEmployee(
      id,
      idPos,
      idRole,
      page,
      hidden,
      hiddenOutSource,
      q,
      parent,
      show_self,
      disable,
      bypassPermission,
      per_page
    );
    dispatch({
      type: GET_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data, page: res.data.meta.pagination }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_EMPLOYEE_ERROR });
  }
};

export const getAllPersonal = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ALL_PERSONAL_PENDING });

    const res = await API.getAllPersonal();
    dispatch({
      type: GET_ALL_PERSONAL_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ALL_PERSONAL_ERROR });
  }
};

export const getPersonByIdPosition = (idPos: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_PERSON_BY_POSITION_PENDING });

    const res = await API.getPersonalByIdPosition(idPos);
    dispatch({
      type: GET_PERSON_BY_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PERSON_BY_POSITION_ERROR });
  }
};

export const getAllPosition = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ALL_POSITION_PENDING });

    const res = await API.getAllPosition();
    dispatch({
      type: GET_ALL_POSITION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_ALL_POSITION_ERROR });
  }
};

export const getPositionByID = (idDp: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_POSITION_PENDING });

    const res = await API.getPositionById(idDp);
    dispatch({ type: GET_POSITION_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_POSITION_ERROR });
  }
};

export const getClient = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_CLIENT_PENDING });

    const res = await API.getClient();
    dispatch({ type: GET_CLIENT_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_CLIENT_ERROR });
  }
};

export const getProject = (id?: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_PROJECT_PENDING });

    const res = await API.getProject(id);
    dispatch({ type: GET_PROJECT_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PROJECT_ERROR });
  }
};

export const getProjectV2 = (is_parent?: boolean) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_PROJECT_PENDING });

    const res = await API.getProjectV2("", is_parent || false);
    dispatch({ type: GET_PROJECT_SUCCESS, payload: { data: res.data.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PROJECT_ERROR });
  }
};

export const getProjectByid = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_PROJECT_BY_ID_PENDING });

    const res = await API.getProjectById(id);
    dispatch({
      type: GET_PROJECT_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PROJECT_BY_ID_ERROR });
  }
};

export const getHTML = (
  type: any,
  from: any,
  to: any,
  department_id: string,
  client_id: string,
  project_id: string,
  personel_id: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_HTML_PENDING });
    const res = await API.downloadHTMLReport(
      type,
      from,
      to,
      department_id,
      client_id,
      project_id,
      personel_id
    );

    if (res.data) {
      window.open(res.data, "data report");
    }
    dispatch({ type: GET_HTML, payload: { data: res.data } });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_HTML_ERROR });
  }
};

export const getPDF = (
  type: any,
  from: any,
  to: any,
  department_id: string,
  client_id: string,
  project_id: string,
  personel_id: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_PDF_PENDING });

    const res = await API.getPDFReport(
      type,
      from,
      to,
      department_id,
      client_id,
      project_id,
      personel_id
    );

    if (res.headers) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        res.headers["content-disposition"].split('"')[1]
      );
      document.body.appendChild(link);
      link.click();
    }

    dispatch({
      type: GET_PDF,
      payload: {
        data: res.data,
        name: res.headers["content-disposition"].split('"')[1]
      }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PDF_ERROR });
  }
};

export const getCSV = (
  type: any,
  from: any,
  to: any,
  department_id: string,
  client_id: string,
  project_id: string,
  personnel_id: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_CSV_PENDING });

    const res = await API.getCSVReport(
      type,
      from,
      to,
      department_id,
      client_id,
      project_id,
      personnel_id
    );

    if (res.headers) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        res.headers["content-disposition"].split('"')[1]
      );
      document.body.appendChild(link);
      link.click();
    }
    dispatch({
      type: GET_CSV,
      payload: {
        data: res.data,
        name: res.headers["content-disposition"].split('"')[1]
      }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_CSV_ERROR });
  }
};

export const setActiveDepartment = (index: number, id?: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ACTIVE_DEPARTMENT,
    payload: { data: { index, id } }
  });
};

export const setActivePosition = (index: number, id?: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ACTIVE_POSITION,
    payload: { data: { index, id } }
  });
};

export const setActivePersonil = (index: number, id?: string) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_ACTIVE_PERSONIL,
    payload: { data: { index, id } }
  });
};

export const setActiveSpecialRole = (index: number) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_ACTIVE_ROLE,
    payload: { data: index }
  });
};

export const getSpecialRole = (idDp?: string, idPos?: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_SPECIAL_ROLE_PENDING });

    const res = await API.getAllRoles(idDp, idPos);
    dispatch({
      type: GET_SPECIAL_ROLE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_SPECIAL_ROLE_ERROR });
  }
};

export const setDepartementName = (title: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_DEPARTEMENT_NAME,
    payload: { data: title }
  });
};

export const setPositionName = (title: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_POSITION_NAME,
    payload: { data: title }
  });
};

export const setPersonilName = (title: string) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_PERSONIL_NAME,
    payload: { data: title }
  });
};

export const deleteEmployee = (id: string, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_EMPLOYEE_PENDING });

    await API.deleteEmployee(id);
    cb();
    dispatch({
      type: DELETE_EMPLOYEE_SUCCESS,
      payload: { data: id }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: DELETE_EMPLOYEE_ERROR });
  }
};

export const getPopulateEmployeeExceptSelf = () => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_POPULATE_EMPLOYEE_PENDING });

    const res = await API.getPopulateEmployeeExceptSelf();
    dispatch({
      type: GET_POPULATE_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_POPULATE_EMPLOYEE_ERROR });
  }
};

export const resetEmployee = () => async (dispatch: Dispatch) => {
  dispatch({ type: RESET_EMPLOYEE_SUCCESS, payload: { data: [] } });
};
