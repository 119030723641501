import React, { memo } from "react";

const AddIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="58.871"
      height="58.871"
      viewBox="0 0 58.871 58.871"
    />
  );
};

export default memo(AddIcon);
