import React, { memo } from "react";

import { Spinner } from "..";

interface Props {
  isVisible: boolean;
}

const Loading = ({ isVisible }: Props) => {
  return (
    <div className={`modal component-modal ${isVisible ? "is-active" : ""}`}>
      <div className="modal-background" />
      <div>
        <Spinner />
      </div>
    </div>
  );
};

export default memo(Loading);
