import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Button, Text } from "../../../../components";
import { Reducers } from "../../../../redux/types";
import {
  checkRegisteredCompany,
  getRegency,
  setFormWalkthrough
  // updateFormWalkthrough
} from "../../../../redux/actions";
import "./styles.scss";
import { documentTitle, heightPercent } from "../../../../utils";

const FormCompany = () => {
  documentTitle("Form Company Data");
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, errors, handleSubmit } = useForm();

  const walkthroughState = useSelector((state: Reducers) => state.walkthrough);

  useEffect(() => {
    dispatch(getRegency());
    dispatch(checkRegisteredCompany());
  }, [dispatch]);

  const _handleFormValidation = useCallback(
    (isShowError: boolean, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  const _handleSubmit = useCallback(
    (data: any) => {
      dispatch(setFormWalkthrough(data));
      history.push("/setup/detail");
    },
    [history, dispatch]
  );

  return (
    <div className="page-walktrough">
      <form className="form-company" onSubmit={handleSubmit(_handleSubmit)}>
        <div
          className="walktrough-form-company-container"
          style={{ height: heightPercent(100) }}
        >
          <Text className="walktrough-form-company-title">
            Input Your Company Data
          </Text>
          <div className="walktrough-form-company-form-container">
            <div style={{ width: "45%" }}>
              <Text className="walktrough-form-company-label-form">
                Company Name
              </Text>
              <input
                className="walktrough-form-company-input-form"
                placeholder="Company"
                name="name_company"
                ref={register({ required: true })}
              />
              {_handleFormValidation(
                errors.name_company,
                "Company name is required"
              )}
              <div className="walktrough-form-company-margin-form">
                <Text className="walktrough-form-company-label-form">
                  Company Address
                </Text>
                <input
                  className="walktrough-form-company-input-form"
                  placeholder="Input your company address"
                  name="address1"
                  ref={register({ required: true, minLength: 5 })}
                />
              </div>
              {_handleFormValidation(
                errors.address1,
                "Minimum text length is 5"
              )}
              <div className="walktrough-form-company-margin-form">
                <Text className="walktrough-form-company-label-form">City</Text>
                <select
                  className="walktrough-form-company-input-form walktrough-form-company-select"
                  name="city"
                  ref={register({ required: true })}
                >
                  <option selected value="" disabled hidden>
                    City
                  </option>
                  {walkthroughState.listRegency.data.map(
                    (e: any, index: number) => {
                      return (
                        <option
                          key={index}
                          value={`${e.id}:${e.attributes.name}`}
                        >
                          {`${e.attributes.name}`}
                        </option>
                      );
                    }
                  )}
                </select>

                {_handleFormValidation(errors.city, "Region is required")}
              </div>
              <div className="walktrough-form-company-margin-form">
                <Text className="walktrough-form-company-label-form">
                  Zip Code
                </Text>
                <input
                  type="number"
                  className="walktrough-form-company-input-form"
                  placeholder="Zip Code"
                  name="zip_code"
                  ref={register({ required: true })}
                />
                {_handleFormValidation(
                  errors.zip_code,
                  "Company zip code is required"
                )}
              </div>
            </div>
            <div style={{ width: "45%" }}>
              <Text className="walktrough-form-company-label-form">
                Company Email
              </Text>
              <input
                className="walktrough-form-company-input-form"
                placeholder="Email"
                name="email_company"
                ref={register({
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                })}
              />
              {_handleFormValidation(
                errors.email_company,
                "Wrong email format"
              )}

              <div className="walktrough-form-company-margin-form">
                <Text className="walktrough-form-company-label-form">
                  Phone Number
                </Text>
                <input
                  type="number"
                  className="walktrough-form-company-input-form"
                  placeholder="Phone No."
                  name="phone"
                  ref={register({ required: true, minLength: 5 })}
                />
                {_handleFormValidation(
                  errors.phone,
                  "Minimum text length is 5"
                )}
              </div>

              <div className="walktrough-form-company-margin-form">
                <Text className="walktrough-form-company-label-form">
                  Fax Number
                </Text>
                <input
                  type="number"
                  className="walktrough-form-company-input-form"
                  placeholder="Fax No."
                  name="fax"
                  ref={register({ required: false, minLength: 4 })}
                />
                {_handleFormValidation(errors.fax, "Minimum text length is 4")}
              </div>

              <input type="submit" hidden />
            </div>
          </div>
          <Button
            onPress={handleSubmit(_handleSubmit)}
            className="walktrough-form-company-container-button "
          >
            <div className="walktrough-form-company-container-text-button">
              <Text
                style={{
                  alignSelf: "center",
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: "Poppins",
                  fontSize: 23
                }}
              >
                NEXT
              </Text>
            </div>
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FormCompany;
