import {
  GET_POLICIES_ERROR,
  GET_POLICIES_PENDING,
  GET_POLICIES_SUCESSS,
  POST_SAVE_POLICIES_ERROR,
  POST_SAVE_POLICIES_PENDING,
  POST_SAVE_POLICIES_SUCESSS,
  UPDATE_POLICIES_SUCESSS
} from "../actions";
import { Action, PolicyState } from "../types";

const initalState: PolicyState = {
  listPolicy: {
    isLoadingPolicy: false,
    data: []
  },
  form: {
    listForm: [],
    isLoading: false
  }
};
let policyListForm;

export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_POLICIES_PENDING:
      return {
        ...state,
        listPolicy: { ...state.listPolicy, isLoadingPolicy: true },
        form: initalState.form
      };
    case GET_POLICIES_SUCESSS:
      return {
        ...state,
        listPolicy: {
          ...state.listPolicy,
          isLoadingPolicy: false,
          data: payload.data
        }
      };
    case GET_POLICIES_ERROR:
      return {
        ...state,
        listPolicy: { ...state.listPolicy, isLoadingPolicy: false }
      };
    case UPDATE_POLICIES_SUCESSS:
      policyListForm = state.form.listForm.filter(
        dt => dt.policy_slug !== payload.policy_slug
      );
      return {
        ...state,
        form: {
          ...state.form,
          listForm: [
            ...policyListForm,
            {
              policy_slug: payload.policy_slug,
              value: payload.value,
              method: payload.method
            }
          ]
        }
      };

    // handle save
    case POST_SAVE_POLICIES_PENDING:
      return { ...state, form: { ...state.form, isLoading: true } };
    case POST_SAVE_POLICIES_SUCESSS:
      return { ...state, form: { ...state.form, isLoading: false } };
    case POST_SAVE_POLICIES_ERROR:
      return { ...state, form: { ...state.form, isLoading: false } };

    default:
      return state;
  }
};
