import React, { memo } from "react";

import "./styles.scss";
import Picker from "../Picker";

interface ListValue {
  id: string;
  title: string;
}

interface Props {
  listValue: ListValue[];
  onChangeDropDown?: (e: any) => void;
  valueDropDown?: string;
  onChangeInput?: (e: any) => void;
  valueInput?: string;
  placeholder?: string;
}

const RoleInput = ({ onChangeDropDown, valueDropDown, listValue }: Props) => (
  <div>
    <div className="components-employee-custom-label">Role and Permission</div>
    <div className="components-employee-role-input-container global-shadow-card">
      <div className="components-employee-role-input-dropdown-container">
        <div
          style={{ marginLeft: 11 }}
          className="components-employee-custom-label"
        >
          Copy From
        </div>
        <Picker
          classNameSelect="components-employee-role-input-dropdown-container-style"
          classNameContainer="components-employee-role-input-dropdown global-shadow-card"
          listValue={listValue}
          value={valueDropDown}
          onChange={onChangeDropDown}
        />
      </div>
      <div className="components-employee-role-input-text-container">
        <input className="components-employee-role-input-text-custom" />
        <div
          style={{
            width: 100,
            height: 28,
            backgroundColor: "red",
            marginRight: 2
          }}
        />
        <div
          style={{
            width: 100,
            height: 28,
            backgroundColor: "red",
            marginRight: 2
          }}
        />
        <div
          style={{
            width: 100,
            height: 28,
            backgroundColor: "red",
            marginRight: 2
          }}
        />
        <div
          style={{
            width: 100,
            height: 28,
            backgroundColor: "red",
            marginRight: 2
          }}
        />
      </div>
    </div>
  </div>
);

export default memo(RoleInput);
