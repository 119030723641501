import React, { ReactNode, memo } from "react";
import { useTranslation } from "react-i18next";
import { IMAGES } from "../../configs";
import { Button } from "..";

import "./styles.scss";

interface Props {
  children?: ReactNode;
  onPressSubmit?: () => void;
  onPressCancel?: () => void;
  onchangeFirstInput?: () => void;
  onchangeInputSecond?: () => void;
  titleModal?: string;
  isOpen: boolean;
  message?: string | boolean;
  warning?: boolean;
  btnSubmitText?: string;
  btnCancelText?: string;
  btnCancelGreen?: boolean;
  mandatory?: boolean;
  noMessage?: boolean;
  loading?: boolean;
  customBtn?: boolean;
  onPressCustomBtn?: () => void;
  txtCustomBtn?: string;
  onCancelModal?: () => void;
}

const ModalEmployee = ({
  isOpen,
  onPressSubmit,
  onPressCancel,
  titleModal,
  message,
  children,
  warning,
  btnSubmitText,
  btnCancelText,
  mandatory,
  btnCancelGreen,
  loading,
  onCancelModal,
  customBtn,
  txtCustomBtn,
  onPressCustomBtn
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={`modal component-modal ${isOpen ? "is-active" : ""}`}>
      <div
        className="modal-background"
        onClick={onCancelModal || onPressCancel}
      />
      <div className="modal-content padding-left-80 padding-right-80">
        <span className="component-modal-label">{titleModal}</span>
        {warning ? (
          <img
            alt="warn"
            src={IMAGES.warn}
            className="component-modal-delete-warn-img"
          />
        ) : null}
        {message && (
          <div style={{ marginTop: 36 }}>
            {message === true ? t("confirmSubmission.defaultWarning") : message}
          </div>
        )}
        {children}
        <div>
          {!mandatory ? (
            <div style={{ display: "flex", marginTop: 56, marginBottom: 46 }}>
              <Button
                tabIndex={0}
                className={`component-modal-button ${
                  btnCancelGreen ? "green" : "grey"
                }`}
                onPress={onPressCancel}
                style={{ marginRight: 20 }}
              >
                <span className="component-modal-button-text">
                  {btnCancelText || "CANCEL"}
                </span>
              </Button>
              {customBtn && !loading && (
                <Button
                  tabIndex={0}
                  className="component-modal-button green"
                  onPress={onPressCustomBtn}
                  style={{ marginRight: 20 }}
                >
                  <span className="component-modal-button-text">
                    {txtCustomBtn || "CUSTOM"}
                  </span>
                </Button>
              )}

              <Button
                tabIndex={0}
                className={`component-modal-button ${
                  loading ? "grey" : "orange"
                }`}
                onPress={!loading ? onPressSubmit : () => null}
              >
                <span className="component-modal-button-text">
                  {loading ? "Loading ..." : btnSubmitText || "SUBMIT"}
                </span>
              </Button>
            </div>
          ) : (
            <div style={{ marginTop: 56, marginBottom: 46, display: "flex" }}>
              <Button
                className={`component-modal-button ${
                  btnCancelGreen ? "green" : "grey"
                }`}
                onPress={onPressCancel}
              >
                <span className="component-modal-button-text">
                  {btnCancelText || "CANCEL"}
                </span>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ModalEmployee);
