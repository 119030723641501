import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import { SideBar, TopBar, WrapApp } from "../../components";

// screens
import Home from "./pages/Home";
import UserPage from "../Dashboard/pages/UserPage";
import Generate from "./pages/Generate";

const App = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const getLanguage = Cookies.get("locale") || "en";
  const listPage = [
    {
      path: `${path}`,
      component: <Generate />,
      exact: true
    },
    {
      path: `${path}/budget-company/sumber-anggaran-statement`,
      component: (
        <div className="page-report-budget">
          <UserPage />
        </div>
      ),
      exact: true
    },
    {
      path: `${path}/data-export`,
      component: <Home />,
      exact: true
    }
  ];

  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
  }, [dispatch, getLanguage]);

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />

        <div
          style={{
            marginLeft: 340
          }}
        >
          {listPage.map((item, index) => (
            <Route path={item.path} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </div>
      </WrapApp>
    </Switch>
  );
};

export default App;
