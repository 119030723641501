import {
  COMBINE_DESC,
  DELETE_FILE_COLLECTION_REDUCER,
  DELETE_FILE_COLLECTION_REPORT_REDUCER,
  GET_ADD_PARTICIPANT,
  GET_ADD_REQUEST_PARTICIPANT,
  GET_AVAILABLE_DATE_TASK_ERROR,
  GET_AVAILABLE_DATE_TASK_PENDING,
  GET_AVAILABLE_DATE_TASK_SUCCESS,
  GET_DETAIL_TASK_ERROR,
  GET_DETAIL_TASK_PENDING,
  GET_DETAIL_TASK_REPORT_ERROR,
  GET_DETAIL_TASK_REPORT_PENDING,
  GET_DETAIL_TASK_REPORT_SUCCESS,
  GET_DETAIL_TASK_SUCCESS,
  GET_TASK_BUBBLE_ERROR,
  GET_TASK_BUBBLE_PENDING,
  GET_TASK_BUBBLE_SUCCESS,
  GET_TASK_DETAIL_REMARKS_ERROR,
  GET_TASK_DETAIL_REMARKS_PENDING,
  GET_TASK_DETAIL_REMARKS_SUCCESS,
  GET_TASK_FORM_ERROR,
  GET_TASK_FORM_PENDING,
  GET_TASK_FORM_SUCCESS,
  GET_TASK_LIST_ERROR,
  GET_TASK_LIST_PENDING,
  GET_TASK_LIST_SUCCESS,
  GET_TASK_PRINT_ERROR,
  GET_TASK_PRINT_PENDING,
  GET_TASK_PRINT_SUCCESS,
  POST_ACTIVATE_TASK_ERROR,
  POST_ACTIVATE_TASK_PENDING,
  POST_ACTIVATE_TASK_SUCCESS,
  POST_AVAILABLE_DATE_TASK_REPORT_ERROR,
  POST_AVAILABLE_DATE_TASK_REPORT_PENDING,
  POST_AVAILABLE_DATE_TASK_REPORT_SUCCESS,
  POST_END_TASK_ERROR,
  POST_END_TASK_PENDING,
  POST_END_TASK_SUCCESS,
  POST_TASK_ERROR,
  POST_TASK_PENDING,
  POST_TASK_REQUEST_ERROR,
  POST_TASK_REQUEST_PENDING,
  POST_TASK_REQUEST_SUCCESS,
  POST_TASK_SUCCESS,
  POST_UPLOAD_TASK_DETAIL_FILE_ERROR,
  POST_UPLOAD_TASK_DETAIL_FILE_PENDING,
  POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS,
  POST_UPLOAD_TASK_DETAIL_REPORT_FILE_ERROR,
  POST_UPLOAD_TASK_DETAIL_REPORT_FILE_PENDING,
  POST_UPLOAD_TASK_DETAIL_REPORT_FILE_SUCCESS,
  PUT_TASK_ASSIGNED_ERROR,
  PUT_TASK_ASSIGNED_PENDING,
  PUT_TASK_ASSIGNED_SUCCESS,
  PUT_TASK_DETAIL_REPORT_ERROR,
  PUT_TASK_DETAIL_REPORT_PENDING,
  PUT_TASK_DETAIL_REPORT_SUCCESS,
  PUT_TASK_EDIT_ERROR,
  PUT_TASK_EDIT_PENDING,
  PUT_TASK_EDIT_SUCCESS,
  PUT_TASK_REPORT_SUBMIT_ERROR,
  PUT_TASK_REPORT_SUBMIT_PENDING,
  PUT_TASK_REPORT_SUBMIT_SUCCESS,
  RESET_PARTICIPANT,
  RESET_REQUEST_PARTICIPANT,
  SAVE_ADD_PARTICIPANT,
  SAVE_ADD_REQUEST_PARTICIPANT,
  SET_ADD_PARTICIPANT,
  SET_ADD_REQUEST_PARTICIPANT,
  SET_DELETE_PARTICIPANT,
  SET_DELETE_REQUEST_PARTICIPANT,
  SET_MODAL_ASSIGN,
  SET_MODAL_EDIT_PARTICIPANT,
  SET_MODAL_PARTICIPANT,
  SET_MODAL_REQUEST,
  SET_MODAL_REQUEST_PARTICIPANT,
  UPDATE_DESC_DETAIL_TASK,
  UPDATE_DETAIL_TASK_FORM,
  UPDATE_DETAIL_TASK_REPORT,
  UPDATE_EXTENDS_TIME_REDUCER,
  UPDATE_FILE_COLLECTION,
  UPDATE_FILE_COLLECTION_REPORT,
  UPDATE_PROJECT_ID
} from "../actions";
import { Action, TaskState } from "../types";

const initalState: TaskState = {
  taskDetail: null,
  isLoadingTaskDetail: false,
  listTaskDetailReport: [],
  isLoadingTaskDetailReport: false,
  taskList: [
    {
      number: "",
      pic: {
        employee: {
          name: "",
          position_name: ""
        }
      }
    }
  ],
  isLoadingTaskList: false,
  isLoadingPostTask: false,
  setModalAsign: false,
  setModalRequest: false,
  assignedChangeStatus: 0,
  projectData: {
    id: ""
  },
  isLoadingUploadDetailTaskFile: false,
  taskDetailRemarks: null,
  isLoadingtaskDetailRemarks: false,
  isEditListReport: false,
  listAvailableDate: [],
  isLoadingAvailableDate: false,
  combineReport: [],
  setModalParticipant: false,
  setEditModalParticipant: false,
  setModalRequestParticipant: false,
  participanList: [],
  requestParticipantList: [],
  saveRequestParticipanList: [],
  saveParticipanList: [],
  isEdit: false,
  listTaskForm: null,
  isLoadingUploadFile: false,
  isLoadingTaskForm: false,
  listTaskFormReport: null,
  saveLog: [],
  isLoadingPutTaskDetailReport: false,
  isLoadingSubmitReport: false,
  isLoadingEndTask: false,
  isLoadingPostAvailableDate: false,
  paginationTask: { current_page: 1, last_page: 1, from: 1, to: 5, total: 5 },
  isLoadingTaskPrint: false,
  listTaskPrint: null,
  listEmployee: [],
  isLoadingPostTaskRequest: false,
  isLoadingTaskBubble: false,
  dataTaskBubble: null,
  isDisabledButton: false
};
let result;
export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_DETAIL_TASK_PENDING:
      return {
        ...state,
        isLoadingTaskDetail: true,
        taskDetail: null
      };
    case GET_DETAIL_TASK_SUCCESS:
      return {
        ...state,
        isLoadingTaskDetail: false,
        taskDetail: { ...payload.data, isEdit: false },
        projectData: {
          id: payload.data.project
            ? payload.data.project.id
            : payload.data.project_id
        },
        isEdit: false,
        saveParticipanList: payload.data.employee
      };
    case GET_DETAIL_TASK_ERROR:
      return {
        ...state,
        isLoadingTaskDetail: false,
        taskDetail: null
      };
    case POST_UPLOAD_TASK_DETAIL_FILE_PENDING:
      return {
        ...state,
        isLoadingUploadFile: true,
        isDisabledButton: true
      };
    case POST_UPLOAD_TASK_DETAIL_FILE_SUCCESS:
      return {
        ...state,
        isLoadingUploadFile: false,
        isDisabledButton: false
      };
    case POST_UPLOAD_TASK_DETAIL_FILE_ERROR:
      return {
        ...state,
        isLoadingUploadFile: false,
        isDisabledButton: false
      };
    case GET_DETAIL_TASK_REPORT_PENDING:
      return {
        ...state,
        isLoadingTaskDetailReport: true,
        listTaskDetailReport: []
      };
    case GET_DETAIL_TASK_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingTaskDetailReport: false,
        listTaskDetailReport: payload.data,
        listTaskFormReport: payload.value,
        isEdit: false
      };
    case GET_DETAIL_TASK_REPORT_ERROR:
      return {
        ...state,
        isLoadingTaskDetailReport: false,
        listTaskDetailReport: []
      };

    case GET_TASK_LIST_PENDING:
      return {
        ...state,
        isLoadingTaskList: true
      };
    case GET_TASK_LIST_SUCCESS:
      return {
        ...state,
        isLoadingTaskList: false,
        taskList: payload.data,
        paginationTask: payload.value,
        taskDetail: null,
        saveParticipanList: initalState.participanList
      };
    case GET_TASK_LIST_ERROR:
      return {
        ...state,
        isLoadingTaskList: false
      };
    case GET_AVAILABLE_DATE_TASK_PENDING:
      return {
        ...state,
        isLoadingTaskList: true
      };
    case GET_AVAILABLE_DATE_TASK_SUCCESS:
      result = [];
      payload.data.data.map(item => {
        result = [
          ...result,
          {
            date: item,
            file_collection: [],
            description: "",
            available_date: true
          }
        ];
        return true;
      });
      return {
        ...state,
        isLoadingTaskList: false,
        listAvailableDate: result
      };
    case GET_AVAILABLE_DATE_TASK_ERROR:
      return {
        ...state,
        isLoadingTaskList: false
      };
    case PUT_TASK_DETAIL_REPORT_PENDING:
      return {
        ...state,
        isLoadingPutTaskDetailReport: true,
        isDisabledButton: true
      };
    case PUT_TASK_DETAIL_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingPutTaskDetailReport: false,
        saveLog: [
          ...state.saveLog,
          {
            date: payload.data.data.date,
            message: payload.data.message,
            success: payload.data.success,
            file: null,
            type: "Change report task"
          }
        ],
        isDisabledButton: false
      };
    case PUT_TASK_DETAIL_REPORT_ERROR:
      return {
        ...state,
        isLoadingPutTaskDetailReport: false,
        isDisabledButton: false
      };

    case GET_TASK_DETAIL_REMARKS_PENDING:
      return {
        ...state,
        isLoadingtaskDetailRemarks: true
      };
    case GET_TASK_DETAIL_REMARKS_SUCCESS:
      return {
        ...state,
        isLoadingtaskDetailRemarks: false,
        taskDetailRemarks: payload.data
      };
    case GET_TASK_DETAIL_REMARKS_ERROR:
      return {
        ...state,
        isLoadingtaskDetailRemarks: false
      };

    case POST_TASK_PENDING:
      return {
        ...state,
        isLoadingPostTask: true
      };
    case POST_TASK_SUCCESS:
      return {
        ...state,
        isLoadingPostTask: false
      };
    case POST_TASK_ERROR:
      return {
        ...state,
        isLoadingPostTask: false
      };

    case PUT_TASK_EDIT_PENDING:
      return {
        ...state,
        isLoadingPostTask: true
      };
    case PUT_TASK_EDIT_SUCCESS:
      return {
        ...state,
        isLoadingPostTask: false
      };
    case PUT_TASK_EDIT_ERROR:
      return {
        ...state,
        isLoadingPostTask: false
      };

    case POST_ACTIVATE_TASK_PENDING:
      return {
        ...state,
        isLoadingActivateTask: true
      };
    case POST_ACTIVATE_TASK_SUCCESS:
      return {
        ...state,
        isLoadingActivateTask: false
      };
    case POST_ACTIVATE_TASK_ERROR:
      return {
        ...state,
        isLoadingActivateTask: false
      };

    case UPDATE_FILE_COLLECTION:
      return {
        ...state,
        taskDetail: {
          ...state.taskDetail,
          file_collection: [
            ...state.taskDetail.file_collection,
            {
              file: {
                title: payload.data.fileName,
                isNew: true,
                url: URL.createObjectURL(payload.data.file),
                id: payload.data.res.data.data[0],
                extension: payload.data.file.type
              }
            }
          ]
        },
        isLoadingUploadDetailTaskFile: false
      };
    case UPDATE_FILE_COLLECTION_REPORT:
      state.listTaskDetailReport[payload.data.index].inputFile = [
        ...state.listTaskDetailReport[payload.data.index].inputFile,
        {
          file: payload.data.file,
          url: URL.createObjectURL(payload.data.file)
        }
      ];
      state.listTaskDetailReport[payload.data.index].isEdit = true;
      return {
        ...state,
        isEdit: true
      };

    case SET_MODAL_ASSIGN:
      return { ...state, setModalAsign: payload.data };

    case UPDATE_DETAIL_TASK_FORM:
      return {
        ...state,
        taskDetail: {
          ...state.taskDetail,
          [payload.data.formName]: payload.data.value,
          isEdit: true
        },
        isEdit: true
      };
    case UPDATE_DETAIL_TASK_REPORT:
      return {
        ...state,
        combineReport: payload.data
      };
    case UPDATE_DESC_DETAIL_TASK:
      state.listTaskDetailReport[payload.data.index].description =
        payload.data.description;
      state.listTaskDetailReport[payload.data.index].isEdit = true;
      return {
        ...state,
        isEdit: true
      };
    case UPDATE_PROJECT_ID:
      return {
        ...state,
        projectData: {
          id: payload.id
        }
      };
    case DELETE_FILE_COLLECTION_REDUCER:
      state.taskDetail.file_collection.splice(payload.data, 1);

      state.taskDetail.isEdit = true;

      return {
        ...state
      };

    case PUT_TASK_ASSIGNED_SUCCESS:
      state.assignedChangeStatus = 1;
      return {
        ...state
      };
    case PUT_TASK_ASSIGNED_PENDING:
      state.assignedChangeStatus = 0;
      return {
        ...state
      };
    case PUT_TASK_ASSIGNED_ERROR:
      state.assignedChangeStatus = 2;
      return {
        ...state
      };

    case DELETE_FILE_COLLECTION_REPORT_REDUCER:
      if (payload.data.type === "file_collection") {
        state.listTaskDetailReport[
          payload.data.indexReport
        ].file_collection.splice(payload.data.indexFile, 1);
      } else {
        state.listTaskDetailReport[payload.data.indexReport].inputFile.splice(
          payload.data.indexFile,
          1
        );
      }

      state.listTaskDetailReport[payload.data.indexReport].isEdit = true;

      return {
        ...state,
        isEdit: true
      };
    case COMBINE_DESC:
      return {
        ...state,
        combineReport: [
          ...state.listAvailableDate,
          ...state.listTaskDetailReport
        ]
      };
    case SET_MODAL_PARTICIPANT:
      return { ...state, setModalParticipant: payload.data };
    case SET_MODAL_EDIT_PARTICIPANT:
      return { ...state, setEditModalParticipant: payload.data };
    case GET_ADD_PARTICIPANT:
      return { ...state, participanList: state.saveParticipanList };

    case SET_ADD_PARTICIPANT:
      return {
        ...state,
        participanList: [...state.participanList, payload.data]
      };

    case SAVE_ADD_PARTICIPANT:
      return {
        ...state,
        saveParticipanList: state.participanList,
        participanList: initalState.participanList
      };

    case RESET_PARTICIPANT:
      return {
        ...state,
        saveParticipanList: initalState.participanList
      };

    case UPDATE_EXTENDS_TIME_REDUCER:
      return {
        ...state,
        isEdit: true
      };

    case SET_DELETE_PARTICIPANT:
      return {
        ...state,
        participanList: state.participanList.filter(e => e.id !== payload.data)
      };

    case GET_TASK_FORM_PENDING:
      return {
        ...state,
        isLoadingTaskForm: true,
        listTaskForm: null
      };
    case GET_TASK_FORM_SUCCESS:
      return {
        ...state,
        isLoadingTaskForm: false,
        listTaskForm: payload.data
      };
    case GET_TASK_FORM_ERROR:
      return {
        ...state,
        isLoadingTaskForm: false,
        listTaskForm: null
      };
    case PUT_TASK_REPORT_SUBMIT_PENDING:
      return {
        ...state,
        isLoadingSubmitReport: true,
        isDisabledButton: true
      };
    case PUT_TASK_REPORT_SUBMIT_SUCCESS:
      return {
        ...state,
        isLoadingSubmitReport: false,
        isDisabledButton: false
      };
    case PUT_TASK_REPORT_SUBMIT_ERROR:
      return {
        ...state,
        isLoadingSubmitReport: false,
        isDisabledButton: false
      };

    case POST_END_TASK_PENDING:
      return {
        ...state,
        isLoadingEndTask: true
      };
    case POST_END_TASK_SUCCESS:
      return {
        ...state,
        isLoadingEndTask: false
      };
    case POST_END_TASK_ERROR:
      return {
        ...state,
        isLoadingEndTask: false
      };
    case POST_AVAILABLE_DATE_TASK_REPORT_PENDING:
      return {
        ...state,
        isLoadingPostAvailableDate: true,
        isDisabledButton: true
      };
    case POST_AVAILABLE_DATE_TASK_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingPostAvailableDate: false,
        isDisabledButton: false
      };
    case POST_AVAILABLE_DATE_TASK_REPORT_ERROR:
      return {
        ...state,
        isLoadingPostAvailableDate: false,
        isDisabledButton: false
      };
    case GET_TASK_PRINT_PENDING:
      return {
        ...state,
        isLoadingTaskPrint: true
      };
    case GET_TASK_PRINT_SUCCESS:
      return {
        ...state,
        isLoadingTaskPrint: false,
        listTaskPrint: payload.data
      };
    case GET_TASK_PRINT_ERROR:
      return {
        ...state,
        isLoadingTaskPrint: false
      };

    case SET_MODAL_REQUEST:
      return { ...state, setModalRequest: payload.data };
    case SET_MODAL_REQUEST_PARTICIPANT:
      return { ...state, setModalRequestParticipant: payload.data };
    case GET_ADD_REQUEST_PARTICIPANT:
      return {
        ...state,
        requestParticipantList: state.saveRequestParticipanList
      };
    case RESET_REQUEST_PARTICIPANT:
      return {
        ...state,
        saveRequestParticipanList: initalState.requestParticipantList
      };
    case SAVE_ADD_REQUEST_PARTICIPANT:
      return {
        ...state,
        saveRequestParticipanList: state.requestParticipantList,
        requestParticipantList: initalState.requestParticipantList
      };
    case SET_ADD_REQUEST_PARTICIPANT:
      return {
        ...state,
        requestParticipantList: [...state.requestParticipantList, payload.data]
      };
    case SET_DELETE_REQUEST_PARTICIPANT:
      return {
        ...state,
        requestParticipantList: state.requestParticipantList.filter(
          e => e.id !== payload.data
        )
      };

    case POST_TASK_REQUEST_PENDING:
      return {
        ...state,
        isLoadingPostTaskRequest: true
      };
    case POST_TASK_REQUEST_SUCCESS:
      return {
        ...state,
        isLoadingPostTaskRequest: false
      };
    case POST_TASK_REQUEST_ERROR:
      return {
        ...state,
        isLoadingPostTaskRequest: false
      };
    // Get Bubble Task
    case GET_TASK_BUBBLE_PENDING:
      return {
        ...state,
        isLoadingTaskBubble: true,
        dataTaskBubble: null
      };
    case GET_TASK_BUBBLE_SUCCESS:
      return {
        ...state,
        isLoadingTaskBubble: false,
        dataTaskBubble: payload.data
      };
    case GET_TASK_BUBBLE_ERROR:
      return {
        ...state,
        isLoadingTaskBubble: false,
        dataTaskBubble: null
      };
    case POST_UPLOAD_TASK_DETAIL_REPORT_FILE_PENDING:
      return {
        ...state,
        isDisabledButton: true
      };
    case POST_UPLOAD_TASK_DETAIL_REPORT_FILE_SUCCESS:
      return {
        ...state,
        isDisabledButton: false
      };
    case POST_UPLOAD_TASK_DETAIL_REPORT_FILE_ERROR:
      return {
        ...state,
        isDisabledButton: false
      };

    default:
      return { ...state };
  }
};
