import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { FormContext, useForm } from "react-hook-form";

import "./styles.scss";
import { Button, Container, HeaderTittle, Modal } from "../../../components";
import { Permission, convertDate, copyWritings } from "../../../utils";
import { IMAGES } from "../../../configs";
import {
  getEmployee,
  getMyPermission,
  getOnBehalf,
  postOnBehalf,
  putOnBehalfActive,
  resetOnbihalfById
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import NewOnBehalf from "./NewOnBehalf";
import { Navigation } from "../components/Navigation";

const OnBehalf = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const companyState = useSelector((state: Reducers) => state.company);
  const { t } = useTranslation();

  const permission = {
    create: Permission("on-behalf-create"),
    otherEdit: Permission("on-behalf-others-edit"),
    ownedEdit: Permission("on-behalf-owned-edit"),
    divisionOtherList: Permission("division-others-list"),
    positionOtherList: Permission("position-others-list")
  };

  useEffect(() => {
    dispatch(getMyPermission());
    dispatch(getOnBehalf());
    dispatch(getEmployee());
  }, [dispatch]);

  const [modalOnBehalf, setModalOnBehalf] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [active, setActive] = useState(true);
  const [idOnbehalf, setIdOnbehalf] = useState("");
  const [changePass, setChangePass] = useState(false);

  const _onSave = (data: any) => {
    const remove = ["pass_confirmation"];
    Object.keys(data)
      .filter(key => remove.includes(key))
      .forEach(key => delete data[key]);

    const form = {
      ...data,
      active: active ? 1 : 0
    };
    dispatch(
      postOnBehalf(form, idOnbehalf, () => {
        setModalOnBehalf(false);
        dispatch(getOnBehalf());
        methods.reset();
        setIdOnbehalf("");
        setChangePass(false);
        setActive(true);
      })
    );
  };

  return (
    <FormContext {...methods}>
      <Container scroll withMaxWidth>
        <HeaderTittle title={copyWritings("company")} />
        <Navigation />
        <Container isLoading={companyState.isLoadingListOnbehalf}>
          <>
            {companyState.listOnbehalf.length > 0 ? (
              <table className="data-table">
                <thead>
                  <tr>
                    <th>{t("company.table.onBehalf")}</th>
                    <th>{t("company.table.createdBy")}</th>
                    <th>{t("company.table.account")}</th>
                    <th>{t("company.table.createdAt")}</th>
                    <th colSpan={2}>{t("status.active")}</th>
                  </tr>
                </thead>
                <tbody className="double">
                  {companyState.listOnbehalf.map((item, index) => (
                    <tr key={index} className="less-radius">
                      <td>
                        <p>{item.parent.name}</p>
                        <span>{item.parent.email}</span>
                        <p>
                          <span>{item.parent.position}</span>
                        </p>
                      </td>
                      <td>
                        <p>{item.created_by.name}</p>
                        <span>{item.created_by.email}</span>
                        <p>
                          <span>{item.created_by.position}</span>
                        </p>
                      </td>
                      <td>
                        <p>
                          <p>{item.name}</p>
                          <span>{item.email}</span>
                        </p>
                      </td>
                      <td className="">
                        <p>
                          {convertDate(item.created_at, "DD MMM YYYY hh:mm")}
                        </p>
                      </td>
                      <td>
                        <p>{item.active ? t("yes") : t("no")}</p>
                      </td>
                      <td>
                        {(permission.ownedEdit || permission.otherEdit) && (
                          <div className="on-behalf-action">
                            <Button
                              onPress={() => {
                                setIsEdit(true);
                                setModalOnBehalf(true);
                                setIdOnbehalf(item.id);
                              }}
                            >
                              <img
                                src={IMAGES.pencil}
                                alt="n/a"
                                style={{ height: 50, width: 50 }}
                                className="pointer"
                              />
                            </Button>
                            {item.active && (
                              <Button
                                onPress={() =>
                                  dispatch(putOnBehalfActive(item.id))
                                }
                              >
                                <img
                                  src={IMAGES.deleteRound}
                                  alt="n/a"
                                  style={{ height: 50, width: 50 }}
                                  className="pointer"
                                />
                              </Button>
                            )}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="task-empty-data">{t("emptyData")}</div>
            )}
            <Container
              withMaxWidth
              flex
              className="company-onbehalf-footer-button"
            >
              <div style={{ position: "relative", width: "100%" }}>
                <div style={{ position: "absolute", right: 0 }}>
                  {permission.create ? (
                    <Button
                      className="company-button"
                      onPress={() => setModalOnBehalf(true)}
                    >
                      <img src={IMAGES.floatingPlusButton} alt="create" />
                    </Button>
                  ) : (
                    <Button
                      disabled
                      className="company-button"
                      onPress={() => setModalOnBehalf(true)}
                    >
                      <img
                        src={IMAGES.buttonGrey}
                        alt="create"
                        className="on-behalf-button-image"
                        title={t("company.form.onBehalfPermissionMsg")}
                      />
                    </Button>
                  )}
                </div>
              </div>
            </Container>
          </>
        </Container>
        <Modal
          loading={companyState.isLoadingPostOnbehalf}
          titleModal={
            isEdit
              ? t("company.form.editOnBehalf")
              : t("company.form.newOnBehalf")
          }
          isOpen={modalOnBehalf}
          onPressCancel={() => {
            setModalOnBehalf(false);
            setIsEdit(false);
            methods.reset();
            setIdOnbehalf("");
            dispatch(resetOnbihalfById());
            setActive(true);
          }}
          onPressSubmit={methods.handleSubmit(_onSave)}
          btnSubmitText={`${isEdit ? t("btn.edit") : t("btn.add")}`}
          btnCancelText={t("btn.cancel")}
        >
          <NewOnBehalf
            active={active}
            setActive={e => setActive(e)}
            id={idOnbehalf}
            changePass={changePass}
            setChangePass={e => setChangePass(e)}
            isLoad={modalOnBehalf}
          />
        </Modal>
      </Container>
    </FormContext>
  );
};

export default OnBehalf;
