import React, { useEffect } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { Container, SideBar, TopBar, WrapApp } from "../../components";

import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";

import List from "./List";

const Task = () => {
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";
  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
  }, [dispatch, getLanguage]);

  const match = useRouteMatch();

  const items: any = [
    {
      pathname: `${match.path}/list`,
      component: <List />,
      exact: false
    },
    {
      pathname: `${match.path}/detail/:id`,
      component: <div>asdasd</div>,
      exact: false
    }
  ];

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <Route path={match.path} exact>
          <Redirect to={`${match.path}/list`} />
        </Route>
        <Container scroll withMaxWidth>
          <div>
            {items.map((item, index) => (
              <Route path={item.pathname} exact={item.exact} key={index}>
                {item.component}
              </Route>
            ))}
          </div>
        </Container>
      </WrapApp>
    </Switch>
  );
};

export default Task;
