/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Reducers } from "../../../../redux/types";

import { Permission, Roles, copyWritings } from "../../../../utils";

const Navigation = () => {
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const homeState = useSelector((state: Reducers) => state.home);
  const permission = {
    otherList: Permission("on-behalf-others-list"),
    ownedList: Permission("on-behalf-owned-list"),
    divisionOtherList: Permission("division-others-list"),
    positionOtherList: Permission("position-others-list"),
    positioCreate: Permission("position-create"),
    positionOtherEdit: Permission("position-others-edit"),
    announcementOthers: Permission("announcement-others-list")
  };

  const roles = {
    loanUser: Roles("personal-loan-user"),
    loanHr: Roles("personal-loan-hr"),
    loanFinance: Roles("personal-loan-finance")
  };

  const copyWritingss = {
    department: copyWritings("department")
  };

  const _checkLoanPersonelPermission = () => {
    if (homeState.profile.listRole.length === 1 && roles.loanHr) {
      return false;
    }
    if (homeState.profile.listRole.length > 1 && roles.loanHr) {
      return true;
    }
    return true;
  };

  return (
    <>
      <div className="company-navbar">
        <NavLink
          exact
          className="navbar-item"
          activeClassName="active"
          to="/company"
        >
          {sprintf(t("company.tab.detail"), copyWritings("company"))}
        </NavLink>
        {_checkLoanPersonelPermission() && (
          <NavLink
            exact
            className="navbar-item"
            activeClassName="active"
            to="/company/division"
          >
            {t("company.tab.position")}
          </NavLink>
        )}
        {(permission.divisionOtherList ||
          permission.positionOtherList ||
          roles.loanHr) && (
          <NavLink
            exact
            className="navbar-item"
            activeClassName="active"
            to="/company/department"
          >
            {sprintf(t("company.tab.department"), copyWritingss.department)}
          </NavLink>
        )}
        <NavLink
          exact
          className="navbar-item"
          activeClassName="active"
          to="/company/tree"
        >
          {t("company.tab.chart")}
        </NavLink>
        {(permission.ownedList || permission.otherList) && (
          <NavLink
            exact
            className="navbar-item"
            activeClassName="active"
            to="/company/on-behalf"
          >
            {t("company.tab.onBehalf")}
          </NavLink>
        )}
        {permission.announcementOthers && (
          <NavLink
            exact
            className="navbar-item"
            activeClassName="active"
            to="/company/announcement"
          >
            {t("company.tab.announcement")}
          </NavLink>
        )}
      </div>
    </>
  );
};

export default Navigation;
