import React from "react";
import moment from "moment";

import "./styles.scss";
import { Button } from "..";
import { ICONS } from "../../configs";
import { maskedMoney } from "../../utils";
import { PaginationBack, PaginationForward } from "../../assets";

interface Props {
  data?: any;
  pagination?: any;
  nextPage?: () => void;
  prevPage?: () => void;
  downloadXML?: () => void;
  downloadCSV?: () => void;
}

const Table = ({
  data,
  downloadXML,
  downloadCSV,
  pagination,
  nextPage,
  prevPage
}: Props) => {
  return (
    <div className="component-table-container">
      <table
        style={{
          height: "30%",
          width: "95%",
          display: "table",
          tableLayout: "fixed"
        }}
        cellSpacing="0"
        cellPadding="0"
      >
        <thead>
          <tr className="component-table-header">
            <th className="component-table-header-text">Date</th>
            <th className="component-table-header-text">COA</th>
            <th className="component-table-header-text">Debit</th>
            <th className="component-table-header-text">Credit</th>
            <th className="component-table-header-text">JV Number</th>
            <th className="component-table-header-text">Description</th>
            <th className="component-table-pagination">{`${pagination.from} - ${pagination.to} of ${pagination.total}`}</th>
            <th className="component-table-pagination-container">
              {pagination.current_page !== 1 ? (
                <div
                  className="component-table-pagination-arrow"
                  style={{ marginLeft: 20 }}
                >
                  <Button onPress={prevPage}>
                    <PaginationBack />
                  </Button>
                </div>
              ) : null}
            </th>
            <th className="component-table-pagination-container">
              {pagination.current_page !== pagination.last_page ? (
                <div className="component-table-pagination-arrow">
                  <Button onPress={nextPage}>
                    <PaginationForward />
                  </Button>
                </div>
              ) : null}
            </th>
          </tr>
        </thead>

        <tbody>
          {data.map((e: any) => (
            <tr key={e}>
              <td
                className="component-table-body-text"
                style={{ textAlign: "left" }}
              >
                {moment(e.date).format("ddd, DD MMM YYYY")}
              </td>
              <td
                className="component-table-body-text"
                style={{ textAlign: "left" }}
              >
                <p>{e.coa}</p>
              </td>
              <td
                className="component-table-body-text"
                style={{ textAlign: "left" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      marginRight: "20%"
                    }}
                  >
                    IDR
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    {e.debit !== 0 ? maskedMoney(e.debit) : "-"}
                  </p>
                </div>
              </td>
              <td
                className="component-table-body-text"
                style={{ textAlign: "left" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <p
                    style={{
                      display: "inline-block",
                      marginRight: "20%"
                    }}
                  >
                    IDR
                  </p>
                  <p style={{ fontWeight: "bold" }}>
                    {e.credit !== 0 ? maskedMoney(e.credit) : "-"}
                  </p>
                </div>
              </td>
              <td
                className="component-table-body-text"
                style={{
                  textAlign: "left"
                }}
              >
                <p
                  title={e.jv}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    wordBreak: "break-all"
                  }}
                >
                  {e.jv}
                </p>
              </td>
              <td
                className="component-table-body-text"
                style={{
                  textAlign: "left"
                }}
                colSpan={3}
              >
                <p
                  title={e.description}
                  className="component-table-text-global"
                >
                  {e.description}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {data.length !== 0 ? (
        <div className="component-table-action">
          <div>
            <img style={{ opacity: 0.3 }} src={ICONS.btnMessage} alt="print" />
          </div>
          <Button onPress={downloadXML}>
            <img title="Download XML" src={ICONS.btnCopy} alt="copy" />
          </Button>
          <Button onPress={downloadCSV}>
            <img title="Download CSV" src={ICONS.btnExcel} alt="message" />
          </Button>
          <div>
            <img style={{ opacity: 0.3 }} src={ICONS.btnPrint} alt="print" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Table;
