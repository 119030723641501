import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

interface Props {
  color?: string;
  opacity?: number;
}

const ArrowRightV2Icon = ({ color, opacity }: Props) => {
  const configState = useSelector((state: Reducers) => state.config);

  const _color = () => {
    if (color) {
      return color;
    }
    return configState.isDark ? "#fff" : "#000";
  };

  const _opacity = () => {
    if (opacity) {
      return opacity.toString();
    }
    return "0.25";
  };

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4.06"
      height="8.12"
      viewBox="0 0 4.06 8.12"
    >
      <path
        id="Path_2698"
        data-name="Path 2698"
        d="M4.06,8.12,0,4.06,4.06,0Z"
        transform="translate(4.06 8.12) rotate(180)"
        fill={_color()}
        opacity={_opacity()}
      />
    </svg>
  );
};

export default memo(ArrowRightV2Icon);
