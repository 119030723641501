/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import "./styles.scss";
import {
  Button,
  Container,
  ModalAssignTask,
  ModalRequestTask,
  Search,
  Tab
} from "../../../components";
import {
  getTaskList,
  setModalAssign,
  setModalRequest
} from "../../../redux/actions";
import { Permission, copyWritings, documentTitle } from "../../../utils";
import { IMAGES } from "../../../configs";
import { Table } from "./components";
import { Reducers } from "../../../redux/types";

const List = () => {
  const { t } = useTranslation();
  documentTitle("Task - List");
  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;
  const dispatch = useDispatch();
  const taskState = useSelector((state: Reducers) => state.task);

  const { sprintf } = require("sprintf-js");

  // const [search, setSearch] = useState("");
  const permission = {
    create: Permission("task-create"),
    assign: Permission("task-assign")
  };

  const leftItems = [
    {
      pathname: `${match.path}/myrequest`,
      component: (
        <div className="page-task-list-request">
          <Table type="myrequest" />
        </div>
      ),
      exact: true,
      img: "request",
      subLabel: t("task.requestTab"),
      type: "myrequest",
      accessible: Permission("task-self-list")
    },
    {
      pathname: `${match.path}/upcoming`,
      component: (
        <div className="page-task-list-upcoming">
          <Table type="upcoming" />
        </div>
      ),
      exact: true,
      img: "upcoming",
      subLabel: t("task.upcomingTab"),
      type: "upcoming",
      accessible: Permission("task-self-list"),
      bubleData: taskState.dataTaskBubble && taskState.dataTaskBubble.upcoming
    },
    {
      pathname: `${match.path}/ongoing`,
      component: (
        <div className="page-task-list-ongoing">
          <Table type="ongoing" />
        </div>
      ),
      exact: false,
      img: "ongoing",
      subLabel: t("task.ongoingTab"),
      type: "ongoing",
      accessible: Permission("task-self-list"),
      bubleData: taskState.dataTaskBubble && taskState.dataTaskBubble.ongoing
    }
  ];

  const rightItems = [
    {
      pathname: `${match.path}/moderation`,
      component: (
        <div className="page-task-list-approval">
          <Table type="moderation" />
        </div>
      ),
      exact: false,
      img: "approval",
      subLabel: t("task.approvalTab"),
      type: "moderation",
      accessible: Permission("task-self-list")
    },
    {
      pathname: `${match.path}/history`,
      component: (
        <div className="page-task-list-history">
          <Table type="finished" />
        </div>
      ),
      exact: false,
      img: "history",
      subLabel: t("task.historyTab"),
      type: "finished",
      accessible: Permission("task-self-list"),
      bubleData: taskState.dataTaskBubble && taskState.dataTaskBubble.history
    },
    {
      pathname: `${match.path}/assigned-task`,
      component: (
        <div className="page-task-list-assigned">
          <Table type="assigned" />
        </div>
      ),
      exact: false,
      img: IMAGES.assignWhite,
      subLabel: t("task.assignTab"),
      type: "assigned",
      accessible: Permission("task-others-list"),
      bubleData: taskState.dataTaskBubble && taskState.dataTaskBubble.assigned
    }
  ];

  // useEffect(() => {
  //   setSearch("");
  // }, [pathname]);

  const items: any[] = [...leftItems, ...rightItems];

  items.map(item => {
    item.onClick = () => history.push(item.pathname);
    return true;
  });

  // const _typeForSearch = useCallback(() => {
  //   const list = [...leftItems, ...rightItems];
  //   const index = list.findIndex(e => e.pathname === pathname);
  //   return list[index].type;
  // }, [leftItems, rightItems, pathname]);

  const searchText = useCallback(
    debounce((text: any) => {
      const list = [...leftItems, ...rightItems];
      const path = String(window.location.pathname).replace(
        `${match.path}/`,
        ""
      );
      if (list.length > 0) {
        dispatch(getTaskList(path === "history" ? "finished" : path, text));
      }
    }, 1000),
    []
  );

  const handleText = (text: any) => {
    searchText(text);
  };

  return (
    <>
      <Container scroll className="page-task-list" withMaxWidth>
        <div className="tab margin-bottom-16" style={{ marginTop: 24 }}>
          {permission.create || permission.assign ? (
            <Button
              onPress={() => dispatch(setModalAssign(true))}
              className="task-assign-button margin-right-8"
            >
              <img
                className="task-assign-button-img"
                src={IMAGES.floatingPlusButton}
                alt="n/a"
              />
              <span className="task-assign-button-text">
                {sprintf(t("task.assignButton"), copyWritings("task"))}
              </span>
            </Button>
          ) : (
            <Button className="task-assign-button margin-right-8" disabled>
              <img
                title="You dont have permission to assign task!"
                className="task-assign-button-img"
                src={IMAGES.floatingPlusButton}
                alt="n/a"
              />
              <span className="task-assign-button-text-grey">
                {sprintf(t("task.assignButton"), copyWritings("task"))}
              </span>
            </Button>
          )}
          <Tab {...{ pathname }} items={leftItems} fontSize="medium" />
          <Search
            className="margin-left-8 margin-right-8"
            onChange={e => {
              handleText(e);
              // setSearch(e);
            }}
            // value={search}
          />
          <Tab {...{ pathname }} items={rightItems} fontSize="medium" />
          <Button
            onPress={() => dispatch(setModalRequest(true))}
            className="task-request-button margin-left-8"
          >
            <img
              className="task-request-button-img"
              src={IMAGES.floatingPlusButton}
              alt="n/a"
            />
            <span className="task-request-button-text">
              {sprintf(t("task.requestTaskButton"), copyWritings("task"))}
            </span>
          </Button>
        </div>

        <Switch>
          <Route path={match.path} exact>
            <Redirect to={`${match.path}/upcoming`} />
          </Route>

          {items.map(
            (item, index) =>
              item.accessible && (
                <Route path={item.pathname} exact={item.exact} key={index}>
                  {item.component}
                </Route>
              )
          )}
        </Switch>
      </Container>
      <ModalAssignTask />
      <ModalRequestTask />
    </>
  );
};

export default List;
