/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Card,
  Container,
  FileUpload,
  Footer,
  HeaderTittle,
  Upload
} from "../../../components";
import { heightPercent, openNewTab } from "../../../utils";
import { postSupport } from "../../../redux/actions";
import { Reducers } from "../../../redux/types";

import "./styles.scss";

const SupportPage = () => {
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const { isLoading } = useSelector(
    (state: Reducers) => ({
      isLoading: state.home.isLoadingSupport
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [form, setForm]: any = useState({
    title: "",
    description: "",
    type: "error",
    file: null
  });
  const [isError, setError] = useState(false);
  const buttons = [
    {
      label: t("btn.submit"),
      onClick: () => {
        if (
          form.description === "" ||
          form.title === "" ||
          form.description === null ||
          form.title === null
        ) {
          setError(true);
        } else {
          dispatch(postSupport(form));
        }
      },
      isLoading
    }
  ];
  return (
    <>
      <Container className="page-task-list" withMaxWidth scroll>
        <HeaderTittle title={t("support.title")} />
        <div className="page-task-detail-report">
          <Card
            style={{
              width: "100%",
              height: heightPercent(70),
              marginTop: 40
            }}
          >
            <div style={{ marginLeft: 60, paddingTop: 60 }}>
              <div className="support-container-input">
                <span className="support-title-input">
                  {t("company.form.title")}
                </span>
                <div style={{ width: "100%" }}>
                  <Card className="support-container-content-input">
                    <input
                      className="support-input-text"
                      value={form.title}
                      onChange={e =>
                        setForm({ ...form, title: e.target.value })
                      }
                    />
                  </Card>
                  {((isError && form.title === "") || form.title === null) && (
                    <span style={{ fontFamily: "Poppins", color: "red" }}>
                      {sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("company.form.title")
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="support-container-input">
                <span className="support-title-input">
                  {t("support.form.type")}
                </span>
                <div style={{ width: "100%" }}>
                  <Card className="support-container-content-input">
                    <select
                      className="support-dropdown"
                      onChange={e => setForm({ ...form, type: e.target.value })}
                    >
                      <option value="bug">{t("support.form.bug")}</option>
                      <option value="support">
                        {t("support.form.support")}
                      </option>
                      <option value="feedback">
                        {t("support.form.feedback")}
                      </option>
                      <option value="others">{t("support.form.other")}</option>
                    </select>
                  </Card>
                </div>
              </div>
              <div className="support-container-input">
                <span className="support-title-input">
                  {t("task.form.description")}
                </span>
                <div style={{ width: "100%" }}>
                  <Card className="support-container-content-input">
                    <textarea
                      value={form.description}
                      onChange={e =>
                        setForm({ ...form, description: e.target.value })
                      }
                      className="support-textarea"
                    />
                  </Card>
                  {((isError && form.description === "") ||
                    form.description === null) && (
                    <span style={{ fontFamily: "Poppins", color: "red" }}>
                      {sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("task.form.description")
                      )}
                    </span>
                  )}
                </div>
              </div>
              <div className="support-container-input">
                <span className="support-title-input">
                  {t("support.form.file")}
                </span>
                {form.file ? (
                  <FileUpload
                    type="image"
                    canDelete
                    linkImage={
                      form.file.type === "png" ||
                      form.file.type === "jpg" ||
                      form.file.type === "jpeg" ||
                      form.file.type.includes("image")
                        ? URL.createObjectURL(form.file)
                        : "document"
                    }
                    name={form.file.name}
                    deleteFile={e => {
                      e.stopPropagation();
                      setForm({ ...form, file: null });
                    }}
                    onPress={() => openNewTab(URL.createObjectURL(form.file))}
                  />
                ) : null}
                <Upload
                  tabIndex={0}
                  onChange={e => setForm({ ...form, file: e.target.files[0] })}
                  textBtn={t("btn.attachmentButton")}
                />
              </div>
            </div>
          </Card>
        </div>
      </Container>
      <Footer {...{ buttons }} />
    </>
  );
};
export default SupportPage;
