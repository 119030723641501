/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import { autoGrowTextArea, copyWritings } from "../../utils";

interface Props {
  onPressSubmit?: () => void;
  onPressCancel?: () => void;
  onChangeDepartment?: (e: any) => void;
  onChangeDesc?: (e: any) => void;
  onChangeCode?: (e: any) => void;
  isOpen: boolean;
  valueDepartment?: string;
  valueDesc?: string;
  valueCode?: string;
  isEdit?: boolean;
}

const ModalDepartment = ({
  onChangeDepartment,
  onChangeDesc,
  onPressCancel,
  onChangeCode,
  onPressSubmit,
  valueDepartment,
  valueDesc,
  valueCode,
  isOpen,
  isEdit
}: Props) => {
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const [err, setErr] = useState(false);

  const _submit = (e?: any) => {
    if (valueDepartment) {
      e && e.preventDefault();
      onPressSubmit && onPressSubmit();
    } else {
      setErr(true);
    }
  };
  return (
    <Modal
      titleModal={
        isEdit
          ? sprintf(t("company.form.editDeptTitle"), copyWritings("department"))
          : sprintf(t("company.form.newDeptTitle"), copyWritings("department"))
      }
      isOpen={isOpen}
      onPressCancel={onPressCancel}
      onPressSubmit={_submit}
      btnSubmitText={isEdit ? t("btn.save") : t("btn.add")}
      btnCancelText={t("btn.cancel")}
    >
      <form
        onSubmit={e => {
          _submit(e);
        }}
        className="form-department"
      >
        <div style={{ width: 380 }}>
          <div style={{ marginBottom: 16, fontWeight: "bold" }}>
            {t("personnel.form.name")}
          </div>
          <input
            className="component-modal-input"
            onChange={onChangeDepartment}
            onInput={() => setErr(false)}
            defaultValue={valueDepartment}
            value={valueDepartment}
          />
          {err && (
            <div className="department-input-error">Title is Required</div>
          )}
          <div style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}>
            Department Code
          </div>
          <input
            className="component-modal-input"
            onChange={onChangeCode}
            defaultValue={valueCode}
            value={valueCode}
          />
          <div style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}>
            {t("company.form.desc")}
          </div>
          <textarea
            className="component-modal-input text-area"
            onInput={autoGrowTextArea}
            onChange={onChangeDesc}
            defaultValue={valueDesc}
            value={valueDesc}
          />
          <input type="submit" hidden />
        </div>
      </form>
    </Modal>
  );
};

ModalDepartment.defaultProps = {
  isEdit: false
};

export default memo(ModalDepartment);
