import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "../Button";
import { Modal } from "../../../../../components";
import { import_template } from "../../../../../redux/actions/master_data";

const UploadButton = () => {
  const { t } = useTranslation();
  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [filename, setFilename] = useState("");
  const [file, setFile] = useState("");
  const dispatch = useDispatch();

  const open_modal_upload = () => {
    setOpenModalUpload(true);
  };

  const onPressCancel = () => {
    setFilename("");
    setFile("");
    setOpenModalUpload(false);
  };

  const getFile = e => {
    setFilename(e.target.files[0].name);
    setFile(e.target.files[0]);
  };

  const uploading = () => {
    const _form = new FormData();
    _form.append("file", file);
    dispatch(import_template(_form));
    onPressCancel();
  };
  return (
    <>
      <Button
        {...{
          onClick: () => open_modal_upload(),
          type: "button",
          text: t("master_data.upload"),
          style: {
            marginTop: 50,
            marginBottom: 50,
            marginLeft: 5,
            background: "linear-gradient(#faaf40, #f05a28, #db3a26)",
            width: "5em",
            height: "2.8em",
            padding: 5,
            color: "white",
            fotWeight: "bold",
            borderRadius: 3,
            cursor: "pointer"
          }
        }}
      />

      <Modal
        isOpen={openModalUpload}
        onPressCancel={onPressCancel}
        titleModal={`${t("master_data.upload")} ${t("master_data.file")}`}
        onPressSubmit={uploading}
        loading={false}
        btnSubmitText="upload data"
        btnCancelText="Batal"
      >
        <div style={{ marginTop: "30px", cursor: "pointer" }}>
          <div className="component-upload components-employee-container-attachment-input-button global-shadow-card">
            <input disabled={false} type="file" onChange={getFile} />
            <span>{`${t("master_data.choose")} ${t("master_data.file")}`}</span>
          </div>
        </div>
        <div style={{ marginTop: "15px", color: "#ddd" }}>{filename}</div>
      </Modal>
    </>
  );
};

export default UploadButton;
