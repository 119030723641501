import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import { COLORS } from "../configs";
import { Reducers } from "../redux/types";
import { useCookie } from "../utils";

import { PageNotFound } from "../components";
// module
import Dashboard from "../modules/Dashboard";
import Report from "../modules/Report";
import Walkthrough from "../modules/Walkthrough";
import TermsPolicy from "../modules/TermsPolicy";
import Company from "../modules/Company";
import LoanProfiles from "../modules/LoanProfile";
import Employee from "../modules/Employee";
import EmployeeDetail from "../modules/EmployeeDetail";
import Task from "../modules/Task";
import Support from "../modules/Support";
import Setting from "../modules/Setting";
import PrintPageTask from "../modules/Task/Detail/PrintPage";
import Admin from "../modules/Admin";
import MasterData from "../modules/MasterData";

const listModules = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: false
  },
  {
    path: "/report",
    component: <Report />,
    exact: false
  },
  {
    path: "/setup",
    component: <Walkthrough />,
    exact: false
  },
  {
    path: "/policy",
    component: <TermsPolicy />,
    exact: false
  },
  {
    path: "/company",
    component: <Company />,
    exact: false
  },
  {
    path: "/employee",
    component: <Employee />,
    exact: false
  },
  {
    path: "/employee-detail/:id",
    component: <EmployeeDetail />,
    exact: false
  },
  {
    path: "/task",
    component: <Task />,
    exact: false
  },
  {
    path: "/support",
    component: <Support />,
    exact: false
  },
  {
    path: "/print-task-detail/:id",
    component: <PrintPageTask />,
    exact: false
  },
  {
    path: "/loan-profiles",
    component: <LoanProfiles />,
    exact: false
  },
  {
    path: "/admin",
    component: <Admin />,
    exact: false
  },
  {
    path: "/setting",
    component: <Setting />,
    exact: false
  },
  {
    path: "/master",
    component: <MasterData />,
    exact: false
  }
];

const App = () => {
  const cookie = useCookie();
  const dispatch = useDispatch();

  const configState = useSelector((state: Reducers) => state.config);

  useEffect(() => {
    document.getElementById("body")!.style.background = configState.isDark
      ? COLORS.mainDark
      : COLORS.mainLight;
    document.getElementById("body")!.className = configState.isDark
      ? "is-dark"
      : "is-light";
  }, [configState.isDark, dispatch, cookie]);

  return (
    <BrowserRouter>
      <ToastContainer limit={1} />
      <Switch>
        <Route path="/" exact>
          <Redirect
            to={{
              pathname: "dashboard"
            }}
          />
        </Route>
        {listModules.map((item, index) => (
          <Route path={item.path} exact={item.exact} key={index}>
            {item.component}
          </Route>
        ))}
        <Route>
          <PageNotFound />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
