import React, { useState } from "react";

function SearchData(props) {
  // eslint-disable-next-line react/prop-types
  const { translate } = props;
  const [dataInput, setDataInput] = useState("");
  const data_input = e => {
    setDataInput(e.target.value);
  };
  return (
    <>
      <div style={{ width: "100%", display: "flex" }}>
        <div
          style={{
            width: "85%",
            borderColor: "lightgrey",
            borderStyle: "solid",
            borderTop: "0px",
            borderLeft: "0px",
            marginTop: "30px",
            height: "4em",
            borderRadius: "35px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <input
            onChange={e => data_input(e)}
            id="__input"
            style={{
              width: "90%",
              height: "100%",
              padding: "20px",
              boxSizing: "border-box"
            }}
            type="text"
            placeholder={translate("deleteData.searchBox")}
          />
        </div>
        <div
          style={{
            marginTop: "30px",
            width: "15%",
            height: "4em",
            padding: "6px",
            boxSizing: "border-box"
          }}
        >
          <button
            // eslint-disable-next-line react/prop-types
            onClick={() => props.__action(dataInput)}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "25px",
              background:
                "linear-gradient(to bottom, #faaf40 0%, #f05a28 60%, #db3a26 100%)",
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              borderColor: "lightgrey",
              cursor: "pointer"
            }}
            type="button"
          >
            {translate("deleteData.search").toUpperCase()}
          </button>
        </div>
      </div>
    </>
  );
}

export default SearchData;
