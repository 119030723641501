import { Slide, toast } from "react-toastify";

interface Props {
  message: string;
  is_success?: boolean;
}

const Toast = ({ message, is_success }: Props) => {
  return toast(message, {
    position: "top-center",
    autoClose: 3500,
    hideProgressBar: true,
    transition: Slide,
    className: `assign-task-toast ${
      is_success ? "assign-green" : "assign-red"
    }`,
    closeButton: false,
    draggable: false
  });
};

Toast.defaultProps = {
  is_success: false
};

export default Toast;
