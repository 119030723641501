import React, { memo } from "react";
import { useSelector } from "react-redux";

import "./styles.scss";
// import { Circular, Histogram, LineGraph, Pie } from "./components";
import { Text } from "../../../../../../components";
// import { ArrowForSelectIcon } from "../../../../../../assets";

// action & type redux
import { Reducers } from "../../../../../../redux/types";

// const listPages = [
//   { label: "LineGraph", component: <LineGraph /> },
//   { label: "Histogram", component: <Histogram /> },
//   { label: "Circular", component: <Circular /> },
//   { label: "Pie", component: <Pie /> },
// ];

const SidebarBottom = () => {
  // const [activePage, setActivePage] = useState(0);
  const homeState = useSelector((state: Reducers) => state.home);

  const _categoriesActive = () => {
    const index = homeState.listWidget.data[
      homeState.listWidget.activeWidget
    ].detail.categories.findIndex(
      (find: any) =>
        Number(find.value) ===
        Number(
          homeState.listWidget.data[homeState.listWidget.activeWidget]
            .categories_active
        )
    );
    return index;
  };

  return (
    <div style={{ height: "100%" }}>
      {homeState.listWidget.data.length > 0 &&
        homeState.listWidget.activeSubWidget !== null &&
        homeState.listWidget.data[homeState.listWidget.activeWidget].detail &&
        homeState.listWidget.data[homeState.listWidget.activeWidget].detail
          .categories[_categoriesActive()].items.length > 0 &&
        homeState.listWidget.data[homeState.listWidget.activeWidget].detail
          .categories[_categoriesActive()].items[
          homeState.listWidget.activeSubWidget
        ].values.length > 0 && (
          <>
            <div className="dashboard-home-sidebar-bottom-wrap-header">
              <div className="dashboard-home-sidebar-bottom-wrap-title">
                <Text className="dashboard-home-sidebar-bottom-text-title">
                  {
                    homeState.listWidget.data[homeState.listWidget.activeWidget]
                      .detail.categories[_categoriesActive()].items[
                      homeState.listWidget.activeSubWidget
                    ].values[homeState.listWidget.activeDataChart][0]
                  }
                </Text>
              </div>

              {/* <div className="dashboard-home-sidebar-bottom-wrap-category">
                <select
                  className="dashboard-home-sidebar-bottom-category"
                  onChange={(e) => setActivePage(Number(e.target.value))}
                >
                  {listPages.map((item: any, index: number) => (
                    <option
                      key={index}
                      value={index}
                      className="dashboard-home-sidebar-bottom-category-options"
                    >
                      {item.label}
                    </option>
                  ))}
                </select>
                <ArrowForSelectIcon />
              </div> */}
            </div>

            {/* {listPages[activePage].component} */}
          </>
        )}
    </div>
  );
};

export default memo(SidebarBottom);
