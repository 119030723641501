import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "../../styles.scss";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Reducers } from "../../../../../redux/types";
import { getList, removeData } from "../../../../../redux/actions/master_data";
import { Container } from "../../../../../components";
import { ModalDelete } from "../../../../../components/ModalMasterData";
import {
  PaginationBackEmployee,
  PaginationForwardEmployee
} from "../../../../../assets";

interface Props {
  type:
    | "expenseType"
    | "projectCategory"
    | "activityCategory"
    | "paymentCategory";
  _action?: (id: any, page: any) => void;
}

const Table = ({ type, _action }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [uuid, setUuid] = useState(null);
  const [removeType, setRemoveType] = useState(null);

  const openModal = (id, action, current_page) => {
    action(id, current_page);
  };

  const remove_data = (_type: any, _uuid: any) => {
    dispatch(removeData(_type, _uuid));

    setOpenModalDelete(false);
    setUuid(null);
    setRemoveType(null);
  };

  const __openModalDelete = (_type: any, _uuid: any) => {
    setUuid(_uuid);
    setRemoveType(_type);
    let __title = "";
    switch (_type) {
      case "finance_category":
        __title = "Delete Expense Type";
        break;
      case "category":
        __title = "Delete Payment Category";
        break;
      case "trip_category":
        __title = "Delete Activity Category";
        break;
      case "payment_method":
        __title = "Delete Payment Method";
        break;

      default:
        break;
    }

    setTitleModal(__title);
    setOpenModalDelete(true);
  };

  const __closeModalDelete = () => {
    setOpenModalDelete(false);
  };

  useEffect(() => {
    dispatch(getList(type));
  }, [dispatch, type]);

  const masterState = useSelector((state: Reducers) => state.masterdata);

  return (
    <Container isLoading={masterState.is_loading} style={{ marginTop: 70 }}>
      {masterState.list_data && masterState.list_data.length > 0 ? (
        <table
          className="data-table"
          style={{
            color: "#363636",
            background: "white"
          }}
        >
          <thead>
            <tr>
              <th>{t("master_data.table.title").toUpperCase()}</th>
              {type === "expenseType" ? (
                <th>{t("expense type").toUpperCase()}</th>
              ) : (
                ""
              )}
              {type === "expenseType" || type === "paymentCategory" ? (
                <th>{t("coa").toUpperCase()}</th>
              ) : (
                ""
              )}
              {type === "paymentCategory" ? (
                <th>{t("Bank").toUpperCase()}</th>
              ) : (
                ""
              )}
              <th>{t("master_data.table.description").toUpperCase()}</th>
              {type === "expenseType" ? (
                <th>{t("Product").toUpperCase()}</th>
              ) : (
                ""
              )}
              <th>{t("master_data.table.last_update").toUpperCase()}</th>
              {type === "paymentCategory" ? (
                <th>{t("master_data.table.state").toUpperCase()}</th>
              ) : (
                ""
              )}
              <th colSpan={2}>{t("master_data.table.action").toUpperCase()}</th>
              <th colSpan={2} className="task-list-regular-text">
                <div className="task-list-pagging">{`${masterState.paginationTask.from} - ${masterState.paginationTask.to} of ${masterState.paginationTask.total}`}</div>
                {masterState.paginationTask.current_page > 1 && (
                  <div
                    onClick={() =>
                      dispatch(
                        getList(
                          type,
                          masterState.paginationTask.current_page - 1
                        )
                      )
                    }
                    className="task-list-pagging arrow pointer"
                  >
                    <PaginationBackEmployee />
                  </div>
                )}
                {masterState.paginationTask.current_page <
                  masterState.paginationTask.last_page && (
                  <div
                    onClick={() =>
                      dispatch(
                        getList(
                          type,
                          masterState.paginationTask.current_page + 1
                        )
                      )
                    }
                    className="task-list-pagging arrow pointer"
                  >
                    <PaginationForwardEmployee />
                  </div>
                )}
              </th>
            </tr>
          </thead>
          <tbody className="double">
            {masterState.list_data.length &&
              masterState.list_data.map((item: any, index: number) => (
                <tr key={index}>
                  <td>{item.title}</td>

                  {type === "expenseType" ? (
                    <td>{item.parent ? item.parent.title : "-"}</td>
                  ) : (
                    ""
                  )}

                  {type === "expenseType" || type === "paymentCategory" ? (
                    <td>{item.code}</td>
                  ) : (
                    ""
                  )}

                  {type === "paymentCategory" ? (
                    // eslint-disable-next-line react/jsx-one-expression-per-line
                    <td> {item.bank ? item.bank.name : "-"}</td>
                  ) : (
                    ""
                  )}

                  <td>
                    {item.description && item.description !== "null"
                      ? item.description
                      : "-"}
                  </td>

                  {type === "expenseType" ? (
                    <td>
                      {item.unit ? (
                        <span
                          style={{
                            padding: 5,
                            background: "#23d160",
                            color: "#fff",
                            fontWeight: "bold",
                            borderRadius: 3
                          }}
                        >
                          Yes
                        </span>
                      ) : (
                        "No"
                      )}
                    </td>
                  ) : (
                    ""
                  )}

                  <td>{moment(item.updated_at).format("ddd, DD MMM YYYY")}</td>

                  {type === "paymentCategory" ? (
                    <td>
                      {item.primary ? (
                        <span
                          style={{
                            padding: 5,
                            background: "#23d160",
                            color: "#fff",
                            fontWeight: "bold",
                            borderRadius: 3,
                            display: "block",
                            marginBottom: "3px",
                            textAlign: "center"
                          }}
                        >
                          Primary
                        </span>
                      ) : (
                        ""
                      )}
                      {item.active ? (
                        <span
                          style={{
                            padding: 5,
                            background: "#23d160",
                            color: "#fff",
                            fontWeight: "bold",
                            borderRadius: 3
                          }}
                        >
                          Active
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                  ) : (
                    ""
                  )}

                  <td colSpan={2} style={{ padding: 0, height: 1, width: 100 }}>
                    <span
                      onClick={() =>
                        openModal(
                          item.id,
                          _action,
                          masterState.paginationTask.current_page
                        )
                      }
                      style={{
                        padding: "5.5px 5.5px",
                        boxSizing: "border-box",
                        border: "1px solid #ddd",
                        borderRadius: "75px",
                        marginRight: 6,
                        boxShadow: "0 2px 2px 1px rgb(0 0 0 / 10%)",
                        cursor: "pointer",
                        color: "grey"
                      }}
                    >
                      <FontAwesomeIcon size="lg" icon={faEdit} />
                    </span>
                    <span
                      onClick={() => __openModalDelete(item.type, item.id)}
                      style={{
                        padding: "5px 8px",
                        border: "1px solid #ddd",
                        borderRadius: "75px",
                        boxShadow: "0 2px 2px 1px rgb(0 0 0 / 10%)",
                        cursor: "pointer",
                        color: "grey"
                      }}
                    >
                      <FontAwesomeIcon size="lg" icon={faTimes} />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div className="task-empty-data">{t("emptyData")}</div>
      )}

      <ModalDelete
        titleModal={titleModal}
        isOpen={openModalDelete}
        onPressCancel={__closeModalDelete}
        onPressSubmit={() => remove_data(removeType, uuid)}
      />
    </Container>
  );
};
// remove_data
export default memo(Table);
