import React, { ChangeEvent, memo } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import { ICONS, IMAGES } from "../../../../configs";
import { openNewTab } from "../../../../utils";

interface Props {
  label?: string;
  onChange?: (e: { name: "number" | "mm" | "yy"; value: string }) => void;
  value?: string;
  valueInput?: { number: string; expired_at?: { mm: string; yy: string } };
  placeholder?: string;
  inputPassport: boolean;
  onChangeFile?: (e: ChangeEvent<HTMLInputElement>) => void;
  linkImage?: string;
  valueImageLocal?: File;
  onRemoveImage?: () => void;
}

const AttachmentInput = ({
  label,
  onChange,
  value,
  placeholder,
  inputPassport,
  onChangeFile,
  valueInput,
  linkImage,
  valueImageLocal,
  onRemoveImage
}: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      {inputPassport ? (
        <div>
          <div className="components-employee-container-attachment-input-container">
            <div>
              <div className="components-employee-custom-label">
                {t("personnel.form.passportNo")}
              </div>
              <input
                placeholder={placeholder}
                className="components-employee-container-attachment-input passport-number"
                onChange={e =>
                  onChange &&
                  onChange({ name: "number", value: e.target.value })
                }
                value={valueInput?.number}
              />
            </div>
            <div style={{ marginLeft: 10 }}>
              <div className="components-employee-custom-label">
                {t("personnel.form.expiredOn")}
              </div>
              <div className="components-employee-container-attachment-input-passport">
                <input
                  placeholder="MM"
                  className="components-employee-container-attachment-input expire-date"
                  onChange={e =>
                    onChange && onChange({ name: "mm", value: e.target.value })
                  }
                  value={valueInput?.expired_at?.mm}
                />
                <div>/</div>
                <input
                  placeholder="YY"
                  className="components-employee-container-attachment-input expire-date"
                  onChange={e =>
                    onChange && onChange({ name: "yy", value: e.target.value })
                  }
                  value={valueInput?.expired_at?.yy}
                />
              </div>
              <div
                style={{ height: 1, width: 68, backgroundColor: "#666666" }}
              />
            </div>
            <div
              className={
                value
                  ? "component-upload components-employee-container-attachment global-shadow-card"
                  : "component-upload components-employee-container-attachment-input-button global-shadow-card"
              }
            >
              <input type="file" onChange={onChangeFile} />
              <span>{value || t("btn.attachmentButton")}</span>
            </div>
            {(valueImageLocal?.name || linkImage) && (
              <div
                style={{ width: 24, height: 24, marginLeft: 12 }}
                onClick={() =>
                  openNewTab(
                    valueImageLocal?.name
                      ? URL.createObjectURL(valueImageLocal)
                      : linkImage!
                  )
                }
              >
                <img
                  src={ICONS.viewImage}
                  alt="viewImage"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            )}
            {valueImageLocal?.name && (
              <div style={{ marginLeft: 12 }} onClick={onRemoveImage}>
                <img
                  className="employee-row-button"
                  src={IMAGES.deleteRound}
                  alt="close"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="components-employee-custom-label">{label}</div>
          <div className="components-employee-container-attachment-input-container">
            <input
              placeholder={placeholder}
              className="components-employee-container-attachment-input"
              onChange={e =>
                onChange && onChange({ name: "number", value: e.target.value })
              }
              value={valueInput?.number}
            />
            <div
              className={
                value
                  ? "component-upload components-employee-container-attachment global-shadow-card"
                  : "component-upload components-employee-container-attachment-input-button global-shadow-card"
              }
            >
              <input type="file" onChange={onChangeFile} />
              <span>{value || t("btn.attachmentButton")}</span>
            </div>
            {(valueImageLocal?.name || linkImage) && (
              <div
                style={{ width: 24, height: 24, marginLeft: 12 }}
                onClick={() =>
                  openNewTab(
                    valueImageLocal?.name
                      ? URL.createObjectURL(valueImageLocal)
                      : linkImage!
                  )
                }
              >
                <img
                  src={ICONS.viewImage}
                  alt="viewImage"
                  style={{ width: 24, height: 24 }}
                />
              </div>
            )}

            {valueImageLocal?.name && (
              <div style={{ marginLeft: 12 }} onClick={onRemoveImage}>
                <img
                  className="employee-row-button"
                  src={IMAGES.deleteRound}
                  alt="close"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(AttachmentInput);
