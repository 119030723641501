import React, { ReactNode } from "react";

import "./styles.scss";

interface Props {
  children: ReactNode;
  className?: string;
  scrollEnabled?: boolean;
}

const WrapContent = ({ children, className, scrollEnabled }: Props) => (
  <div
    className={`component-wrap-content${
      scrollEnabled ? " component-wrap-content-scroll global-scroll-bar" : ""
    } ${className}`}
  >
    {children}
  </div>
);

WrapContent.defaultProps = {
  scrollEnabled: false
};

export default WrapContent;
