import React, { memo } from "react";

const PaginationBack = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="25"
    viewBox="0 0 12 25"
  >
    <text
      id="_"
      data-name="&lt;"
      transform="translate(0 19)"
      fill="#666"
      fontSize="18"
      fontFamily="Poppins-Medium, Poppins"
      fontWeight="500"
    >
      <tspan x="0" y="0">
        &lt;
      </tspan>
    </text>
  </svg>
);

export default memo(PaginationBack);
