import {
  GET_SETTING_DETAIL_ERROR,
  GET_SETTING_DETAIL_PENDING,
  GET_SETTING_DETAIL_SUCCESS,
  GET_SETTING_LIST_ERROR,
  GET_SETTING_LIST_PENDING,
  GET_SETTING_LIST_SUCCESS,
  GET_SETTING_LOOKUP_ERROR,
  GET_SETTING_LOOKUP_PENDING,
  GET_SETTING_LOOKUP_SUCCESS,
  POST_RESET_SETTING_ERROR,
  POST_RESET_SETTING_PENDING,
  POST_RESET_SETTING_SUCCESS,
  POST_SETTING_ERROR,
  POST_SETTING_PENDING,
  POST_SETTING_SUCCESS,
  RESET_VALUE_SETTING,
  SET_SETTING
} from "../actions";
import { Action, SettingState } from "../types";

const initalState: SettingState = {
  listSetting: [],
  paginationListSetting: null,
  isLoadingListSetting: false,
  settingDetail: null,
  isLoadingSettingDetail: false,
  isLoadingLookupValue: false,
  lookupValue: [],
  resetValue: null,
  isLoadingPostSetting: false,
  isLoadingResetSetting: false
};

export default (state = initalState, { type, payload }: Action) => {
  let processedData: any = null;
  switch (type) {
    case GET_SETTING_LIST_PENDING:
      return {
        ...state,
        isLoadingListSetting: true
      };
    case GET_SETTING_LIST_SUCCESS:
      return {
        ...state,
        isLoadingListSetting: false,
        listSetting: payload.data.data,
        paginationListSetting: payload.data.meta.pagination
      };
    case GET_SETTING_LIST_ERROR:
      return {
        ...state,
        isLoadingListSetting: false
      };

    case GET_SETTING_DETAIL_PENDING:
      return {
        ...state,
        isLoadingSetingDetail: true
      };
    case GET_SETTING_DETAIL_SUCCESS:
      processedData = payload.data;
      if (
        processedData.data &&
        processedData.data.type !== "numbering" &&
        processedData.datatype === "array-object" &&
        processedData.value.length === 0
      ) {
        processedData.value = [{ level: 1, user: [""] }];
      }

      return {
        ...state,
        isLoadingSetingDetail: false,
        settingDetail: processedData,
        resetValue: processedData
      };
    case GET_SETTING_DETAIL_ERROR:
      return {
        ...state,
        isLoadingSetingDetail: false
      };

    case SET_SETTING:
      return {
        ...state,
        settingDetail: {
          ...state.settingDetail,
          [payload.data.attributes]: payload.data.value
        }
      };

    case RESET_VALUE_SETTING:
      return {
        ...state,
        settingDetail: state.resetValue
      };

    case GET_SETTING_LOOKUP_PENDING:
      return {
        ...state,
        isLoadinLookupValue: true
      };
    case GET_SETTING_LOOKUP_SUCCESS:
      return {
        ...state,
        isLoadinLookupValue: false,
        lookupValue: payload.data.data
      };
    case GET_SETTING_LOOKUP_ERROR:
      return {
        ...state,
        isLoadinLookupValue: false
      };

    case POST_SETTING_PENDING:
      return {
        ...state,
        isLoadingPostSetting: true
      };
    case POST_SETTING_SUCCESS:
      return {
        ...state,
        isLoadingPostSetting: false
      };
    case POST_SETTING_ERROR:
      return {
        ...state,
        isLoadingPostSetting: false
      };

    case POST_RESET_SETTING_PENDING:
      return {
        ...state,
        isLoadingResetSetting: true
      };
    case POST_RESET_SETTING_SUCCESS:
      return {
        ...state,
        isLoadingResetSetting: false
      };
    case POST_RESET_SETTING_ERROR:
      return {
        ...state,
        isLoadingResetSetting: false
      };

    default:
      return state;
  }
};
