import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

const ArrowForSelectIcon = () => {
  const configState = useSelector((state: Reducers) => state.config);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.614"
      height="5.807"
      viewBox="0 0 10.614 5.807"
    >
      <path
        id="Path_309"
        data-name="Path 309"
        d="M-6133.4,3474l-4.6,4.6,4.6,4.6"
        transform="translate(-3473.293 -6132.693) rotate(-90)"
        fill="none"
        stroke={configState.isDark ? "#fff" : "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
};

export default memo(ArrowForSelectIcon);
