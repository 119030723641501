import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  Button,
  Container,
  FileUpload,
  Footer,
  HeaderTittle,
  Modal,
  ModalAddRemarks,
  ModalAssignTask,
  ModalEditAssignTask,
  ModalExtendTask,
  ModalUserPicker,
  RichTextEditor,
  Spinner,
  Tab,
  Upload
} from "../../../components";
import "./styles.scss";
import {
  deleteFileCollection,
  deleteFileCollectionReducer,
  deleteFileCollectionReportReducer,
  deleteFileReportCollection,
  getAvailableDateTask,
  getDetailTask,
  getDetailTaskRemarks,
  getDetailTaskReport,
  getEmployee,
  getMyPermission,
  getProjectV2,
  postActivateTask,
  postAvailableDateTaskReport,
  postDetailTaskRemarks,
  postExtendsTaskDay,
  postUploadDetailTaskReportFile,
  putTaskAssigned,
  putTaskDetail,
  putTaskDetailReport,
  putTaskModeration,
  putTaskReportSubmit,
  setModalAssign,
  setModalEditParticipant,
  updateDescDetailTaskReport,
  updateDetailTaskForm,
  updateExtendsTimeReducer,
  updateFileCollection,
  updateFileCollectionReport
} from "../../../redux/actions";
import {
  Permission,
  documentTitle,
  openNewTab,
  textToLink
} from "../../../utils";
import { IMAGES } from "../../../configs";
import { Reducers } from "../../../redux/types";
import { usePrevious } from "../../../utils/prevDataHook";

const Detail = () => {
  documentTitle("Task - Detail");
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialSubmitForm = {
    form: null,
    message: ""
  };

  const [isOpenModalEditUser, setIsOpenModalEditUser] = useState<boolean>(
    false
  );

  const [extendModal, setExtendModalOpen] = useState(false);
  const [valueExtend, setValueExtend] = useState("");
  const [remarksModal, setRemarksModal] = useState(false);
  const [valueRemarks, setValueRemarks] = useState("");
  const [fileCollection, setfileCollection]: any = useState([]);
  const [isOpenModalSave, setIsOpenModalSave] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [department, setDepartment] = useState("");
  const previousDepartment = usePrevious(department);
  const [isOpenModalApprove, setIsOpenModalApprove] = useState(false);
  const [isOpenModalSaveFinish, setIsOpenModalSaveFinish] = useState(false);
  const [activeDate, setActiveDate] = useState(0);
  const [modalIsOpen, setModalIsOpen]: any = useState(false);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [selectedFile, setSelectedFile]: any = useState();
  const [isOpenModalDeleteAttachment, setOpenModalDeleteAttachment] = useState(
    false
  );
  const [isOpenModalActivate, setOpenModalActivate] = useState(false);

  const { id } = useParams() as any;
  const history = useHistory();
  const permission = {
    self: Permission("task-self-edit"),
    others: Permission("task-others-edit")
  };

  const { pathname } = history.location;

  const { taskState, myProfile } = useSelector(
    (state: Reducers) => ({
      taskState: state.task,
      myProfile: state.home,
      project: state.generate
    }),
    shallowEqual
  );

  const { uuid } = myProfile.profile.data;
  const employeeId = myProfile.profile.data.employee.id;

  const _handleOpenModalExtend = () => {
    setExtendModalOpen(!extendModal);
  };

  const _cancelExtend = () => {
    setValueExtend("");
    setExtendModalOpen(false);
  };

  const _handleUpdateFileCollection = (file: any, e: any) => {
    setfileCollection([...fileCollection, file]);

    let is_file_exist = false;

    if (taskState.taskDetail.file_collection) {
      const checked = taskState.taskDetail.file_collection.find(
        (__value: any) => {
          if (__value.file.title === file.name) {
            return __value;
          }

          return null;
        }
      );

      if (checked) {
        is_file_exist = true;
      }
    }

    if (is_file_exist) {
      toast(t("task.form.message.duplicateAttachment"), {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    } else {
      dispatch(
        updateFileCollection(file.name, file, id!, () =>
          toast("File successfully added", {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "term-policy-toast",
            closeButton: false,
            draggable: false
          })
        )
      );
    }

    e.target.value = null;
  };

  const _handleUploadFileTaskReport = (file: any, e) => {
    if (!taskState.listTaskDetailReport[activeDate].available_date) {
      let is_file_exist = false;

      if (taskState.listTaskDetailReport[activeDate].file_collection.length) {
        const checked = taskState.listTaskDetailReport[
          activeDate
        ].file_collection.find((__value: any) => {
          if (__value.file.title === file.name) {
            return __value;
          }

          return null;
        });

        if (checked) {
          is_file_exist = true;
        }
      }

      if (is_file_exist) {
        toast(t("task.form.message.duplicateAttachment"), {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      } else {
        dispatch(
          postUploadDetailTaskReportFile(
            taskState.listTaskDetailReport[activeDate].id,
            file,
            id,
            uuid,
            () =>
              dispatch(
                updateFileCollectionReport({
                  index: activeDate,
                  file
                })
              )
          )
        );
      }
    } else {
      let is_file_exist = false;
      if (
        taskState.listTaskDetailReport[activeDate] &&
        taskState.listTaskDetailReport[activeDate].inputFile.length
      ) {
        const checked = taskState.listTaskDetailReport[
          activeDate
        ].inputFile.find((__value: any) => {
          if (__value.file.name === file.name) {
            return __value;
          }

          return null;
        });

        if (checked) {
          is_file_exist = true;
        }
      }

      if (is_file_exist) {
        toast(t("task.form.message.duplicateAttachment"), {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      } else {
        dispatch(
          updateFileCollectionReport({
            index: activeDate,
            file
          })
        );
      }
    }

    e.target.value = null;
  };

  toast.configure();

  const isPic =
    taskState.taskDetail &&
    taskState.taskDetail?.pic?.employee?.id ===
      myProfile?.profile?.data?.employee?.id;

  const isAssigner =
    taskState.taskDetail &&
    taskState.taskDetail?.assigner?.uuid ===
      myProfile?.profile?.data?.employee?.id;

  const [currentUuid, setCurrentUuid] = useState(uuid);

  const [assignedId, setAssignedId] = useState<string | undefined>();

  useEffect(() => {
    if (taskState.assignedChangeStatus === 1) {
      window.location.reload();
    }
  }, [taskState.assignedChangeStatus]);

  useEffect(() => {
    if (isAssigner) {
      setCurrentUuid(taskState?.taskDetail?.assignee[0]?.user?.id);
      dispatch(
        getDetailTaskReport(
          String(id),
          String(taskState?.taskDetail?.assignee[0]?.user?.id)
        )
      );
      dispatch(
        getAvailableDateTask(
          String(id),
          String(taskState?.taskDetail?.assignee[0]?.user?.id)
        )
      );
    } else {
      setCurrentUuid(myProfile.profile.data.id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssigner, dispatch, id, uuid]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(
      () => {
        dispatch(
          getEmployee(
            department || "",
            "",
            "",
            1,
            false,
            searchUser || "",
            false,
            false,
            false,
            false,
            true
          )
        );
      },
      department !== previousDepartment ? 0 : 1500
    );

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [department, dispatch, searchUser]);

  useEffect(() => {
    dispatch(getProjectV2(true));
    dispatch(getDetailTaskRemarks(String(id)));
    dispatch(getMyPermission());
    if (uuid) {
      dispatch(getDetailTaskReport(String(id), String(uuid)));
      dispatch(getAvailableDateTask(String(id), String(uuid)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAssigner, dispatch, id, uuid]);

  useEffect(() => {
    dispatch(getDetailTask(String(id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile.profile.data]);

  useEffect(() => {
    if (
      taskState.taskDetail &&
      taskState.taskDetail.assignee.filter(
        (item: any) => item.user.id === String(uuid)
      ).length === 0 &&
      taskState.taskDetail &&
      taskState.taskDetail.assigner.uuid !==
        myProfile.profile.data.employee.id &&
      taskState.taskDetail.created_by !== myProfile.profile.data.user_id
    ) {
      // history.push("/task/list/upcoming");
    }
  }, [history, taskState, uuid, myProfile]);

  const _handleExtendTaskDay = () => {
    dispatch(
      postExtendsTaskDay(
        id,
        { days: Number(valueExtend) },
        () => dispatch(getDetailTask(id)),
        (e: { success: boolean; message: string }) => {
          toast(`${e.message}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-task-toast",
            closeButton: false,
            draggable: false
          });
        },
        uuid
      )
    );
    setExtendModalOpen(false);
    setValueExtend("");
  };

  const _handlePostTaskDetailRemarks = () => {
    dispatch(postDetailTaskRemarks(id, { comment: valueRemarks }));
    setRemarksModal(false);
    setValueRemarks("");
  };

  const _handleDeleteDocument = (index: number) => {
    if (taskState.taskDetail.file_collection[index].file.isNew!) {
      dispatch(
        deleteFileCollection(
          id,
          taskState.taskDetail.file_collection[index].file.id
        )
      );
      dispatch(deleteFileCollectionReducer(index));
      setOpenModalDeleteAttachment(false);
    } else {
      dispatch(
        deleteFileCollection(id, taskState.taskDetail.file_collection[index].id)
      );
      dispatch(deleteFileCollectionReducer(index));
      setOpenModalDeleteAttachment(false);
    }
  };

  const _handleSelectUser = (index: number) => {
    if (isPic || isAssigner) {
      setCurrentUuid(taskState.taskDetail.assignee[index].user.id);
      dispatch(
        getDetailTaskReport(id, taskState.taskDetail.assignee[index].user.id)
      );
    }
  };

  const _handleToast = () => {
    return (e: { success: boolean; message: string }) => {
      if (e.success === true) {
        toast(`${e.message}`, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast",
          closeButton: false,
          draggable: false
        });
      } else if (e.success === false) {
        toast(`${e.message}`, {
          position: "top-center",
          autoClose: 3500,
          hideProgressBar: true,
          transition: Slide,
          className: "assign-task-toast assign-red",
          closeButton: false,
          draggable: false
        });
      }
    };
  };

  const _handleSaveChangeTask = async (submit?: boolean, endTask?: boolean) => {
    const input = taskState.listTaskDetailReport[activeDate];

    if (input.description === "") {
      toast(t("task.form.message.errorDescription"), {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });

      return;
    }

    if (input.available_date) {
      const body = {
        date: input.date,
        description: input.description,
        files: input.inputFile
      };
      dispatch(
        postAvailableDateTaskReport(
          id,
          body,
          uuid,
          taskState.listTaskDetailReport.length > 0 &&
            taskState.listTaskDetailReport[activeDate].status > 1,
          currentUuid === uuid,
          submit,
          endTask,
          _handleToast()
        )
      );
    } else {
      dispatch(
        putTaskDetailReport(
          id,
          input.id,
          { description: input.description },
          uuid,
          taskState.listTaskDetailReport.length > 0 &&
            taskState.listTaskDetailReport[activeDate].status > 1,
          currentUuid === uuid,
          endTask,
          _handleToast()
        )
      );
    }
    setIsOpenModalSave(false);
    setIsOpenModalSaveFinish(false);
  };

  const _handleSaveAndSubmit = async () => {
    _handleSaveChangeTask(true);
  };

  const _handleSubmitExtendTask = () => {
    dispatch(updateExtendsTimeReducer());
    _handleExtendTaskDay();
    setExtendModalOpen(false);
  };

  const _handleDeleteFileReport = (
    indexFile: number,
    type: "inputFile" | "file_collection"
  ) => {
    if (!taskState.listTaskDetailReport[activeDate].available_date) {
      const fileId =
        taskState.listTaskDetailReport[activeDate].file_collection[indexFile]
          .id;
      const taskReportId =
        taskState.listTaskDetailReport[activeDate].file_collection[indexFile]
          .ref_id;
      dispatch(deleteFileReportCollection(taskReportId, fileId));
    }

    dispatch(
      deleteFileCollectionReportReducer({
        indexReport: activeDate,
        indexFile,
        type
      })
    );
  };

  const _checkAccess = useCallback(() => {
    let result = false;

    if (taskState?.taskDetail) {
      const index =
        taskState?.taskDetail?.assignee &&
        taskState?.taskDetail?.assignee.findIndex(
          (item: any) => item.pic === true
        );
      result =
        taskState?.taskDetail?.assignee[index]?.employee?.id ===
        myProfile?.profile?.data?.employee?.id;
    }

    return (
      (myProfile.profile.data.employee &&
        taskState.taskDetail &&
        taskState.taskDetail.assigner &&
        myProfile?.profile?.data?.employee?.id ===
          taskState?.taskDetail?.assigner?.uuid) ||
      result
    );
  }, [myProfile, taskState]);

  const onSubmitApproval = (data: any) => {
    const form = {
      ...data,
      message: null,
      position_id: null,
      employee_id: null
    };
    data && data.answer === "3"
      ? (form.message = "no message")
      : (form.message = "");
    setSubmitForm({ ...submitForm, form });
    dispatch(
      putTaskModeration(taskState.taskDetail.moderation.id, form, () =>
        dispatch(getDetailTask(String(id)))
      )
    );
    setIsOpenModalApprove(false);
  };

  const spinner = (
    <div
      className="page-task-detail paper margin-top-28"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spinner />
    </div>
  );

  const saveLogModal = () => (
    <Modal
      btnCancelText={t("btn.close")}
      mandatory
      isOpen={modalIsOpen}
      onPressCancel={() => setModalIsOpen(false)}
    >
      <div style={{ backgroundColor: "white", height: 100, width: 100 }} />
    </Modal>
  );

  const _activateTask = () => {
    dispatch(postActivateTask(String(id)));
    setOpenModalActivate(false);
    dispatch(getDetailTask(String(id)));
    history.push("/task/list/upcoming");
  };

  const detailJSX = (
    <div className="page-task-detail-my-task page-task-detail paper margin-top-28">
      <div className="users margin-right-72">
        <label>{t("task.detail.assignedTo")}</label>
        {taskState.taskDetail
          ? taskState.taskDetail.assignee.map((item: any, index: number) => {
              return (
                <div className="user margin-top-16" key={index}>
                  <img
                    alt="avatar"
                    src={
                      item.employee && item.employee.avatar
                        ? item.employee.avatar.url
                        : IMAGES.avatar
                    }
                    className="margin-right-8"
                  />
                  <span>{taskState.taskDetail ? item.employee.name : "-"}</span>
                  {taskState.taskDetail.status === 2 && (
                    <span
                      className="margin-left-8 btn-remarks is-focus"
                      onClick={() => {
                        setAssignedId(item.id);
                        setIsOpenModalEditUser(true);
                      }}
                      style={{ fontSize: "1rem", padding: "5px 10px" }}
                    >
                      {t("master_data.edit")}
                    </span>
                  )}
                </div>
              );
            })
          : null}

        {taskState.taskDetail &&
          taskState.taskDetail.status < 6 &&
          taskState.taskDetail.is_team &&
          taskState.taskDetail.status !== 2 &&
          taskState.taskDetail.status !== 1 &&
          _checkAccess() && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 16
              }}
            >
              <Button onPress={() => dispatch(setModalEditParticipant(true))}>
                <img
                  className="task-list-button"
                  src={IMAGES.asignTask}
                  alt="n/a"
                />
              </Button>
            </div>
          )}
      </div>
      <ModalExtendTask
        isOpen={extendModal}
        onPressCancel={() => _cancelExtend()}
        value={valueExtend}
        onChangeValue={(e: any) => setValueExtend(e.target.value)}
        onPressSubmit={() => _handleSubmitExtendTask()}
      />
      <ModalAddRemarks
        isOpen={remarksModal}
        onPressCancel={() => {
          setRemarksModal(false);
          setValueRemarks("");
        }}
        value={valueRemarks}
        onChange={(e: any) => setValueRemarks(e.target.value)}
        onPressSubmit={() => _handlePostTaskDetailRemarks()}
      />
      <div style={{ width: "100%" }}>
        <div className="forms">
          <div className="margin-bottom-20 users">
            <label>{t("task.detail.assignedBy")}</label>
            <div className="user margin-top-16">
              <img
                alt="avatar"
                src={IMAGES.avatar}
                className="margin-right-8"
              />
              <span className="value-task-text">
                {taskState?.taskDetail?.assigner?.name || "-"}
              </span>
            </div>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.detail.taskName")}</label>
            <span className="value-task-text">
              {taskState.taskDetail && taskState.taskDetail.title
                ? taskState.taskDetail.title
                : "-"}
            </span>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.form.projectName")}</label>
            <span>
              {taskState.taskDetail && taskState.taskDetail.project
                ? taskState.taskDetail.project.title
                : "-"}
            </span>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.detail.repeatStart")}</label>
            <span>
              {taskState.taskDetail && taskState.taskDetail.recurring_at
                ? taskState.taskDetail.recurring_at
                : "-"}
            </span>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.detail.repeatEnd")}</label>
            <span>
              {taskState.taskDetail && taskState.taskDetail.recurring_ended_at
                ? taskState.taskDetail.recurring_ended_at
                : "-"}
            </span>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.form.startDate")}</label>
            <span>
              {taskState.taskDetail
                ? moment(taskState.taskDetail.started_at).format(
                    "dddd, DD MMM YYYY"
                  )
                : "-"}
            </span>
          </div>
          <div className="form-group">
            <label>
              {taskState.taskDetail && taskState.taskDetail.status === 6
                ? t("task.detail.finishDate")
                : t("task.form.finishDate")}
            </label>
            <div>
              {taskState.taskDetail && taskState.taskDetail.status === 6 ? (
                <div className="grey">
                  <span style={{ fontWeight: "bold" }}>
                    {taskState.taskDetail &&
                      moment(taskState.taskDetail.finished_at).format(
                        "dddd, DD MMM YYYY"
                      )}
                  </span>
                </div>
              ) : (
                <div className="grey">
                  {taskState.taskDetail &&
                  taskState.taskDetail.status !== 1 &&
                  taskState.taskDetail.history_extends.length > 0 ? (
                    taskState.taskDetail.history_extends.map(
                      (item: any, index: number) => {
                        return (
                          <div key={index}>
                            <span
                              style={{
                                fontWeight:
                                  index ===
                                  taskState.taskDetail.history_extends.length -
                                    1
                                    ? "bold"
                                    : "normal"
                              }}
                            >
                              {moment(item).format("dddd, DD MMM YYYY")}
                            </span>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div className="grey">
                      <span style={{ fontWeight: "bold" }}>
                        {taskState.taskDetail &&
                          moment(taskState.taskDetail.due_at).format(
                            "dddd, DD MMM YYYY"
                          )}
                      </span>
                    </div>
                  )}
                </div>
              )}

              <div
                className="margin-bottom-20"
                style={{ display: "flex", flexDirection: "row" }}
              >
                {taskState.taskDetail &&
                taskState.taskDetail.status < 6 &&
                taskState.taskDetail.status !== 2 &&
                taskState.taskDetail.status !== 1 &&
                isPic &&
                permission.self ? (
                  <div
                    tabIndex={0}
                    style={{
                      marginTop: 20,
                      opacity: taskState.isDisabledButton ? 0.5 : 1
                    }}
                    className="btn-remarks is-focus"
                    onClick={() =>
                      taskState.isDisabledButton
                        ? null
                        : _handleOpenModalExtend()
                    }
                    onKeyPress={e =>
                      e.key === "Enter" && _handleOpenModalExtend()
                    }
                  >
                    <span
                      style={{
                        paddingLeft: 20,
                        paddingRight: 20
                      }}
                    >
                      {t("task.detail.moreTimeBtn")}
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.form.description")}</label>
            <div
              dangerouslySetInnerHTML={textToLink(
                taskState.taskDetail ? taskState.taskDetail.description : ""
              )}
              className="shadow text-area textarea-desc text-break desc"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                dispatch(
                  updateDetailTaskForm({
                    formName: "description",
                    value: e.target.value
                  })
                )
              }
            />
          </div>
          <div className="form-group margin-bottom-20">
            <label>{t("task.detail.documents")}</label>
            <div
              style={{ opacity: taskState.isDisabledButton ? 0.5 : 1 }}
              className="flex-wrap"
            >
              {taskState.taskDetail && !taskState.isLoadingUploadFile ? (
                taskState.taskDetail.file_collection.map(
                  (item: any, index: number) => {
                    return (
                      <div
                        onClick={() => openNewTab(item.file.url)}
                        style={{ cursor: "pointer" }}
                        key={index}
                      >
                        <FileUpload
                          name={item.file.title}
                          canDelete={
                            taskState.taskDetail &&
                            taskState.taskDetail.status < 6 &&
                            taskState.taskDetail.status !== 2 &&
                            permission.self
                          }
                          type="image"
                          linkImage={
                            item.file.extension === "png" ||
                            item.file.extension === "jpg" ||
                            item.file.extension === "jpeg" ||
                            item.file.extension.includes("image")
                              ? item.file.url
                              : "document"
                          }
                          isLoadingUpload={taskState.isLoadingUploadFile}
                          deleteFile={e => {
                            e.stopPropagation();
                            setSelectedFile(index);
                            setOpenModalDeleteAttachment(true);
                          }}
                        />
                      </div>
                    );
                  }
                )
              ) : (
                <Spinner />
              )}
              {taskState.taskDetail &&
              taskState.taskDetail.status < 6 &&
              taskState.taskDetail.status !== 2 &&
              permission.self ? (
                <Upload
                  disable={taskState.isDisabledButton}
                  tabIndex={0}
                  onChange={e =>
                    _handleUpdateFileCollection(e.target.files[0], e)
                  }
                  textBtn={t("btn.attachmentButton")}
                />
              ) : null}
            </div>
          </div>
          {taskState.taskDetail &&
          taskState.taskDetail.status === 2 &&
          taskState.taskDetail.assigner.uuid === employeeId ? (
            <div className="request-approval-btn">
              <Button
                className="button regular"
                onPress={() => setIsOpenModalApprove(true)}
              >
                {t("btn.approval")}
              </Button>
            </div>
          ) : null}
        </div>
        {taskState.taskDetail &&
        taskState.taskDetail.status !== 2 &&
        taskState.taskDetail.status !== 1 ? (
          <div className="remarks">
            <div
              className="form-group margin-bottom-20"
              style={{ display: "flex", alignItems: "center" }}
            >
              <label className="margin-right-8">
                {t("task.detail.remarks")}
              </label>
              {permission.self &&
                taskState.taskDetail &&
                taskState.taskDetail.status < 6 && (
                  <div
                    className="btn-remarks is-focus"
                    tabIndex={0}
                    onClick={() =>
                      taskState.isDisabledButton ? null : setRemarksModal(true)
                    }
                    onKeyPress={e => e.key === "Enter" && setRemarksModal(true)}
                    style={{
                      opacity: taskState.isDisabledButton ? 0.5 : 1
                    }}
                  >
                    <span style={{ paddingRight: 20, paddingLeft: 20 }}>
                      {t("btn.addRemarks")}
                    </span>
                  </div>
                )}
            </div>
            {taskState.taskDetailRemarks ? (
              taskState.taskDetailRemarks.comments.map(
                (item: any, index: number) => (
                  <div key={index} className="remark margin-bottom-20">
                    <img
                      alt="avatar"
                      src={
                        item.creator.avatar
                          ? item.creator.avatar.url
                          : IMAGES.avatar
                      }
                      className="margin-right-16"
                    />
                    <div>
                      <div>{item.creator.name}</div>
                      <div className="grey">
                        {moment(item.created_at).format("ddd, DD/MM/YY HH:mm")}
                      </div>
                      <p dangerouslySetInnerHTML={textToLink(item.content)} />
                    </div>
                  </div>
                )
              )
            ) : (
              <div />
            )}
          </div>
        ) : null}
        {taskState.taskDetail && [1, 4].includes(taskState.taskDetail.status) && (
          <div className="task-draft-action-btn">
            <Button
              className="button regular"
              onPress={() => dispatch(setModalAssign(true))}
            >
              {t("btn.edit")}
            </Button>
            {[1, 2, 4].includes(taskState.taskDetail.status) ? (
              <Button
                className="button regular"
                onPress={() => setOpenModalActivate(true)}
              >
                {t("btn.submit")}
              </Button>
            ) : (
              ""
            )}
          </div>
        )}
        {taskState.taskDetail &&
        taskState.taskDetail.status >= 2 &&
        taskState.taskDetail.status <= 5 &&
        taskState.taskDetail.recurring ? (
          <div
            style={{ opacity: taskState.isDisabledButton ? 0.5 : 1 }}
            className="task-draft-action-btn"
          >
            <Button
              className="button regular"
              onPress={() =>
                taskState.isDisabledButton
                  ? null
                  : dispatch(setModalAssign(true))
              }
            >
              {t("btn.edit")}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );

  const reportJSX = (
    <div className="page-task-detail-report">
      {taskState.listTaskDetailReport.length < 0 ||
      taskState.isLoadingTaskDetailReport ? (
        <Spinner />
      ) : (
        <div className="page-task-detail-report margin-top-28">
          <div className="date-report">
            <div className="margin-bottom-20 date-report-container">
              {taskState.listTaskDetailReport.map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`capsule margin-right-12 margin-bottom-20 ${
                      activeDate === index ? "active" : ""
                    }`}
                    onClick={() => setActiveDate(index)}
                  >
                    {moment(item.date).format("DD MMM YYYY")}
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            <div className="users margin-right-48 margin-top-8">
              <label>{t("task.detail.assignedTo")}</label>
              {taskState.taskDetail
                ? taskState.taskDetail.assignee.map(
                    (item: any, index: number) => {
                      return (
                        <div
                          onClick={() => {
                            _handleSelectUser(index);
                          }}
                          className="user margin-top-16"
                          key={index}
                          style={{
                            borderRadius: 100,
                            height: 60,
                            backgroundColor:
                              taskState.taskDetail &&
                              taskState.taskDetail.assignee[index].user.id ===
                                currentUuid
                                ? "#50B1B1"
                                : "#fffff7",
                            flexDirection: "row"
                          }}
                        >
                          <div
                            className="globalShadowCard"
                            style={{
                              marginRight: 10,
                              marginLeft: 10,
                              height: 50,
                              width: 50,
                              backgroundColor: "white",
                              borderRadius: 100,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                          >
                            <img
                              style={{ height: "95%", width: "95%" }}
                              alt="avatar"
                              src={
                                item.employee && item.employee.avatar
                                  ? item.employee.avatar.url
                                  : IMAGES.avatar
                              }
                            />
                          </div>
                          <span
                            style={{
                              marginRight: 20,
                              color:
                                taskState.taskDetail &&
                                taskState.taskDetail.assignee[index].user.id ===
                                  currentUuid
                                  ? "#ffffff"
                                  : "#666666",
                              fontWeight:
                                taskState.taskDetail &&
                                taskState.taskDetail.assignee[index].user.id ===
                                  currentUuid
                                  ? "bold"
                                  : "normal"
                            }}
                          >
                            {taskState.taskDetail ? item.employee.name : "-"}
                          </span>
                        </div>
                      );
                    }
                  )
                : null}
            </div>
            <div className="forms">
              <div className="form-group margin-bottom-20">
                <label className="margin-top-8">
                  {t("task.detail.report")}
                </label>
                <div>
                  <div>
                    {taskState.listTaskDetailReport.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="text-editor shadow"
                          style={{
                            width: 720,
                            minHeight: 340,
                            borderRadius: 10,
                            display: activeDate !== index ? "none" : ""
                          }}
                        >
                          {(taskState.listTaskDetailReport &&
                            taskState.taskDetail &&
                            (taskState.listTaskDetailReport[activeDate]
                              .status === 2 ||
                              taskState.taskDetail.status > 5)) ||
                          currentUuid !== uuid ? (
                            <div className="task-report-desc">
                              <p
                                className="text-break"
                                dangerouslySetInnerHTML={textToLink(
                                  taskState.listTaskDetailReport[index]
                                    .description
                                )}
                              />
                            </div>
                          ) : (
                            <RichTextEditor
                              readonly={
                                (taskState.listTaskDetailReport &&
                                  taskState.taskDetail &&
                                  (taskState.listTaskDetailReport[activeDate]
                                    .status === 2 ||
                                    taskState.taskDetail.status > 5)) ||
                                currentUuid !== uuid
                              }
                              placeHolder={
                                (taskState.listTaskDetailReport &&
                                  taskState.taskDetail &&
                                  taskState.taskDetail.status > 5) ||
                                currentUuid !== uuid
                                  ? ""
                                  : t("task.detail.reportPlaceholder")
                              }
                              value={
                                taskState.listTaskDetailReport[index]
                                  .description
                              }
                              onChange={e =>
                                dispatch(
                                  updateDescDetailTaskReport({
                                    index: activeDate,
                                    description: e
                                  })
                                )
                              }
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="form-group margin-bottom-20">
                <label className="margin-top-8">
                  {t("task.detail.documents")}
                </label>
                <div
                  style={{
                    opacity: taskState.isDisabledButton ? 0.5 : 1
                  }}
                  className="flex-wrap"
                >
                  {taskState.listTaskDetailReport.length > 0
                    ? taskState.listTaskDetailReport[
                        activeDate
                      ].file_collection.map((item: any, index: number) => (
                        <div
                          onClick={() => openNewTab(item.file.url)}
                          style={{ cursor: "pointer" }}
                          key={index}
                        >
                          <FileUpload
                            key={index}
                            name={item.file.title}
                            type="image"
                            linkImage={
                              item.file.extension === "png" ||
                              item.file.extension === "jpg" ||
                              item.file.extension === "jpeg" ||
                              item.file.extension.includes("image")
                                ? item.file.url
                                : "document"
                            }
                            canDelete={
                              taskState.listTaskDetailReport[activeDate]
                                .status !== 2 &&
                              currentUuid === uuid &&
                              permission.self
                            }
                            deleteFile={e => {
                              e.stopPropagation();
                              _handleDeleteFileReport(index, "file_collection");
                            }}
                          />
                        </div>
                      ))
                    : null}
                  {taskState.listTaskDetailReport.length > 0
                    ? taskState.listTaskDetailReport[activeDate].inputFile.map(
                        (item: any, index: number) => (
                          <div
                            onClick={() => openNewTab(item.url)}
                            style={{ cursor: "pointer" }}
                            key={index}
                          >
                            <FileUpload
                              key={index}
                              type="image"
                              canDelete={
                                currentUuid === uuid && permission.self
                              }
                              linkImage={
                                item.file.type === "png" ||
                                item.file.type === "jpg" ||
                                item.file.type === "jpeg" ||
                                item.file.type.includes("image")
                                  ? item.url
                                  : "document"
                              }
                              name={item.file.name}
                              deleteFile={e => {
                                e.stopPropagation();
                                _handleDeleteFileReport(index, "inputFile");
                              }}
                            />
                          </div>
                        )
                      )
                    : null}

                  {taskState.listTaskDetailReport[activeDate] &&
                  taskState.taskDetail &&
                  taskState.taskDetail.status < 6 &&
                  taskState.listTaskDetailReport[activeDate].status !== 2 &&
                  uuid === currentUuid &&
                  permission.self ? (
                    // eslint-disable-next-line react/jsx-indent
                    <Upload
                      disable={taskState.isDisabledButton}
                      tabIndex={0}
                      onChange={e => {
                        _handleUploadFileTaskReport(e.target.files[0], e);
                      }}
                      textBtn={t("btn.attachmentButton")}
                    />
                  ) : null}
                </div>
              </div>
              <div className="form-group">
                <label />
                {taskState.taskDetail &&
                !taskState.isLoadingSubmitReport &&
                !taskState.isLoadingEndTask &&
                taskState.listTaskDetailReport &&
                taskState.listTaskDetailReport[activeDate] &&
                taskState.taskDetail.status < 6 &&
                taskState.listTaskDetailReport[activeDate].status &&
                currentUuid === uuid ? (
                  <div>
                    {taskState.listTaskDetailReport[activeDate].status !== 2 &&
                    permission.self ? (
                      <div
                        style={{
                          opacity: taskState.isDisabledButton ? 0.5 : 1
                        }}
                        onClick={() =>
                          taskState.isDisabledButton
                            ? null
                            : dispatch(
                                putTaskReportSubmit(
                                  String(id),
                                  taskState.listTaskDetailReport[activeDate].id,
                                  {
                                    date:
                                      taskState.listTaskDetailReport[activeDate]
                                        .date,
                                    description:
                                      taskState.listTaskDetailReport[activeDate]
                                        .description
                                  },
                                  String(uuid),
                                  () =>
                                    dispatch(
                                      getDetailTaskReport(
                                        String(id),
                                        String(uuid)
                                      )
                                    )
                                )
                              )
                        }
                        className="button-submit-report active shadow"
                      >
                        {t("task.detail.submitReport")}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const items: any = [
    {
      pathname: `/task/detail/${id}`,
      component: taskState.taskDetail ? detailJSX : spinner,
      exact: true,
      label: t("task.detail.details"),
      accessible: true
    },
    {
      pathname: `/task/detail/${id}/report`,
      component: taskState.combineReport ? reportJSX : spinner,
      exact: false,
      label: t("task.detail.report"),
      accessible: true
    }
  ];

  items.map((item: any) => {
    item.onClick = () => history.push(item.pathname);
    return true;
  });

  const renderStatus = (status: any) => {
    let label = "";
    if (status === 1) {
      label = t("status.draft");
    } else if (status === 2) {
      label = t("status.issued");
    } else if (status === 3) {
      label = t("status.assigned");
    } else if (status === 4) {
      label = t("status.revise");
    } else if (
      status === 5 &&
      taskState.taskDetail &&
      taskState.taskDetail.history_extends.length > 1
    ) {
      label = t("status.delayed");
    } else if (status === 5) {
      label = t("status.ongoing");
    } else if (status === 6) {
      label = t("status.finished");
    } else if (status === 7) {
      label = t("status.canceled");
    }
    return label;
  };

  const _statusColor = (status: number) => {
    let color = "#50b1b1";
    if (status === 1 || status === 2) {
      color = "#F5AA3F";
    } else if (status === 4) {
      color = "#ed5a28";
    } else if (
      status === 5 &&
      taskState.taskDetail &&
      taskState.taskDetail.history_extends.length > 1
    ) {
      color = "#2B94FC";
    }
    return color;
  };

  const capsule = {
    type: renderStatus(taskState.taskDetail && taskState.taskDetail.status),
    label: t("task.detail.taskNo"),
    subLabel: taskState.taskDetail ? taskState.taskDetail.number : "",
    color: _statusColor(taskState.taskDetail && taskState.taskDetail.status)
  };

  const buttons = [
    {
      label: t("btn.saveSubmit"),
      isDisable: taskState.isDisabledButton,
      onClick: () => setIsOpenModalSave(true),
      isLoading:
        (taskState.listTaskDetailReport &&
          taskState.listTaskDetailReport[activeDate] &&
          taskState.listTaskDetailReport[activeDate].status === 2) ||
        (taskState.listTaskDetailReport.length > 0 &&
          !taskState.listTaskDetailReport[activeDate].isEdit) ||
        (taskState.listTaskDetailReport.length > 0 &&
          taskState.listTaskDetailReport[activeDate].description ===
            "<p><br></p>") ||
        currentUuid !== uuid ||
        taskState.isLoadingPutTaskDetailReport ||
        taskState.isLoadingSubmitReport ||
        taskState.isLoadingEndTask ||
        taskState.isLoadingPostAvailableDate ||
        taskState.isLoadingTaskDetailReport
    },
    {
      label: t("btn.saveEndTask"),
      isDisable: taskState.isDisabledButton,
      onClick: () => setIsOpenModalSaveFinish(true),
      isLoading:
        (!isPic && taskState.taskDetail) ||
        taskState.isLoadingSubmitReport ||
        taskState.isLoadingTaskDetailReport ||
        taskState.isLoadingPostAvailableDate ||
        taskState.isLoadingEndTask
    }
  ];

  const _goToPrint = () => {
    window.open(`/print-task-detail/${id}`, "print");
  };

  const _printJSX = () => (
    <div className="wrap-print-task">
      <Button className="shadow task-button-print" onPress={() => _goToPrint()}>
        <img src={IMAGES.print} className="task-print-icon" alt="n/a" />
      </Button>
    </div>
  );

  const _getParticipants = useCallback((list: any[]) => {
    if (list.length > 1) {
      let result: any[] = [];
      list.map(e => {
        result = [...result, e.id];
        return true;
      });

      return result.join(",");
    }
    return list[0].id;
  }, []);

  return (
    <>
      <Container className="page-task-detail">
        <Container withMaxWidth scroll>
          <ToastContainer limit={1} />
          <HeaderTittle title="Task" />
          {taskState.taskDetail &&
          taskState.taskDetail.status !== 2 &&
          taskState.taskDetail.status !== 1 ? (
            <Tab {...{ items, pathname, capsule, fontSize: "large" }} />
          ) : (
            <Tab
              {...{
                items: Array(items[0]),
                pathname,
                capsule,
                fontSize: "large"
              }}
            />
          )}
          <Switch>
            {taskState.taskDetail &&
            taskState.taskDetail.status !== 2 &&
            taskState.taskDetail.status !== 1 ? (
              items.map((item, index) => (
                <Route path={item.pathname} exact={item.exact} key={index}>
                  {item.component}
                </Route>
              ))
            ) : (
              <Route path={items[0].pathname} exact={items[0].exact}>
                {items[0].component}
              </Route>
            )}
          </Switch>
          {saveLogModal()}
        </Container>
      </Container>
      <ModalAssignTask taskUuid={id} />
      {isOpenModalEditUser && (
        <ModalEditAssignTask
          close={() => {
            setIsOpenModalEditUser(false);
            setAssignedId(undefined);
          }}
          isOpen={isOpenModalEditUser}
          submit={data => {
            if (assignedId) {
              dispatch(putTaskAssigned(assignedId, data));
            }
          }}
        />
      )}
      <ModalUserPicker
        getData
        onSubmit={e =>
          e.length > 0 &&
          dispatch(
            putTaskDetail(id, { participants: _getParticipants(e) }, () =>
              dispatch(getDetailTask(String(id)))
            )
          )
        }
        onChange={e => {
          setSearchUser(e);
        }}
        pickerOnChange={e => {
          setDepartment(e);
        }}
        value={searchUser}
        hasPicker
        pickerValue={department}
      />
      <Modal
        btnCancelGreen
        btnCancelText={t("btn.save")}
        btnSubmitText={t("btn.saveSubmit")}
        isOpen={isOpenModalSave}
        onCancelModal={() => setIsOpenModalSave(false)}
        onPressCancel={() => _handleSaveChangeTask()}
        onPressSubmit={() => _handleSaveAndSubmit()}
      >
        <div>{t("task.detail.saveSubmitMsg")}</div>
      </Modal>
      <Modal
        warning
        btnCancelText={t("btn.no")}
        btnSubmitText={t("btn.yes")}
        isOpen={isOpenModalDeleteAttachment}
        onPressCancel={() => setOpenModalDeleteAttachment(false)}
        onPressSubmit={() => _handleDeleteDocument(selectedFile)}
      >
        <div>{t("task.detail.deleteFileMsg")}</div>
      </Modal>
      <Modal
        warning
        btnCancelText={t("btn.no")}
        btnSubmitText={t("btn.yes")}
        isOpen={isOpenModalActivate}
        onPressCancel={() => setOpenModalActivate(false)}
        onPressSubmit={() => _activateTask()}
      >
        <div>{t("task.detail.activateTaskMsg")}</div>
      </Modal>
      <Modal
        btnCancelText={t("btn.no")}
        btnSubmitText={t("btn.yes")}
        isOpen={isOpenModalSaveFinish}
        onPressCancel={() => setIsOpenModalSaveFinish(false)}
        onPressSubmit={() => _handleSaveChangeTask(false, true)}
      >
        <div>{t("task.detail.saveCloseTaskMsg")}</div>
      </Modal>
      <Modal
        btnCancelGreen
        btnCancelText={t("btn.cancel")}
        btnSubmitText={t("btn.submit")}
        isOpen={isOpenModalApprove}
        onPressCancel={() => setIsOpenModalApprove(false)}
        onPressSubmit={handleSubmit(onSubmitApproval)}
      >
        <h2>{t("moderation.titleCaps")}</h2>
        <div>
          <form>
            <div className="approval-radiogroup">
              <label>
                <input name="answer" type="radio" value={1} ref={register} />
                <span className="radio" />
                <span className="label approve">{t("moderation.approve")}</span>
              </label>
              <label>
                <input name="answer" type="radio" value={3} ref={register} />
                <span className="radio" />
                <span className="label reject">{t("moderation.reject")}</span>
              </label>
            </div>
          </form>
        </div>
      </Modal>
      {taskState.taskDetail &&
      taskState.taskDetail.status < 6 &&
      permission.self &&
      pathname.includes("report") ? (
        <Footer {...{ buttons }} previewButton={() => _printJSX()} />
      ) : (
        <Footer
          previewButton={() =>
            pathname.includes("report") ? _printJSX() : null
          }
        />
      )}
    </>
  );
};

export default Detail;
