import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import { useHistory } from "react-router-dom";
import "../../sass/styles.scss";
import { COLORS, ICONS, IMAGES } from "../../configs";
import { copyWritings, navigateToOtherHost } from "../../utils";

import { ArrowRightV2Icon, TitleDjurneeIcon } from "../../assets";
import { Button, Container, Text } from "..";

// action & types redux
import { getMyPermission, getMyProfile } from "../../redux/actions";
import { Reducers } from "../../redux/types";

interface Props {
  show?: boolean;
}

interface InitMenuActive {
  active: number;
  subMenu: InitMenuActive[];
}

const initMenuActive: InitMenuActive[] = [];

const SideBar = ({ show }: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;
  const { homeState, menuState, configState, authState } = useSelector(
    (state: Reducers) => ({
      homeState: state.home,
      menuState: state.menu,
      configState: state.config,
      authState: state.auth
    }),
    shallowEqual
  );
  const [menuActive, setMenuActive] = useState(initMenuActive);
  const [path, setPath] = useState("");

  const _handleMenuActive = useCallback((indexMenu: number) => {
    setMenuActive(e => {
      let result: InitMenuActive[];
      if (e.find(res => res.active === indexMenu)) {
        result = e.filter(item => item.active !== indexMenu);
      } else {
        result = [...e, { active: indexMenu, subMenu: [] }];
      }
      return result;
    });
  }, []);

  const _handleSubMenuActive = useCallback(
    (indexMenu: number, indexSubMenu: number) => {
      setMenuActive(e => {
        let result: InitMenuActive[];
        const findIndex = e.findIndex(res => res.active === indexMenu);

        const data = e[findIndex].subMenu;

        if (data.find(res => res.active === indexSubMenu)) {
          result = data.filter(item => item.active !== indexSubMenu);
        } else {
          result = [...data, { active: indexSubMenu, subMenu: [] }];
        }

        e[findIndex].subMenu = result;
        return [...e];
      });
    },
    []
  );

  const _handleMenuActiveByUrl = useCallback(() => {
    if (path === pathname) {
      const menuIndex = menuState.listMenu.findIndex((item: any) =>
        pathname.includes(item.url)
      );
      if (menuIndex >= 0) {
        const pathCount = pathname
          .split("/")
          .filter(item => item !== "" && item !== "dashboard").length;
        _handleMenuActive(menuIndex);
        if (pathCount >= 2) {
          const subMenuIndex =
            menuState.listMenu[menuIndex].childrens &&
            menuState.listMenu[menuIndex].childrens.findIndex((item: any) =>
              pathname.includes(item.url)
            );

          _handleSubMenuActive(menuIndex, subMenuIndex);
        }
      } else {
        menuState.listMenu.forEach((item: any, i: number) => {
          const _menuIndex =
            item.childrens &&
            item.childrens.findIndex((subItem: any) =>
              pathname.includes(subItem.url)
            );
          if (_menuIndex >= 0) {
            _handleMenuActive(_menuIndex);
          } else {
            item.childrens &&
              item.childrens.forEach((subItem: any, j: number) => {
                if (subItem.childrens) {
                  const subMenuIndex = subItem.childrens.findIndex(
                    (subItemLv2: any) => pathname.includes(subItemLv2.url)
                  );
                  if (subMenuIndex >= 0) {
                    _handleMenuActive(i);
                    _handleSubMenuActive(i, j);
                  }
                }
              });
          }
        });
      }
    }
  }, [
    pathname,
    menuState.listMenu,
    path,
    _handleSubMenuActive,
    _handleMenuActive
  ]);

  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getMyPermission());
  }, [dispatch]);

  const [hasPermissionDashboard, sethasPermissionDashboard] = useState(false);
  const [txtDashboard, settxtDashboard] = useState("DASHBOARD");
  const tempTextDashboard = copyWritings("dashboard").toUpperCase();

  useEffect(() => {
    if (authState.myPermission.list.length > 0) {
      if (
        history.location.pathname === "/dashboard" &&
        (homeState.profile.listRole.includes("personal-loan-finance") ||
          homeState.profile.listRole.includes("personal-loan-user") ||
          homeState.profile.listRole.includes("personal-loan-hr"))
      ) {
        window.location.replace(process.env.REACT_APP_HOST_WEB || "");
      } else {
        if (
          homeState.profile.listRole.includes("personal-loan-finance") ||
          homeState.profile.listRole.includes("personal-loan-user") ||
          homeState.profile.listRole.includes("personal-loan-hr")
        ) {
          sethasPermissionDashboard(false);
        } else {
          sethasPermissionDashboard(true);
        }
        settxtDashboard(tempTextDashboard);
      }
    }
  }, [
    hasPermissionDashboard,
    authState.myPermission.list,
    history.location.pathname,
    tempTextDashboard,
    homeState.profile.listRole
  ]);

  useEffect(() => {
    if (path === pathname) {
      setPath(pathname);
      setMenuActive(initMenuActive);
      _handleMenuActiveByUrl();
    }
  }, [_handleMenuActiveByUrl, path, pathname]);

  useEffect(() => {
    if (menuState.listMenu.length > 0 && show) {
      let menuActiveShow: InitMenuActive[] = [];
      menuState.listMenu.map((item, index) => {
        menuActiveShow = [...menuActiveShow, { active: index, subMenu: [] }];
        return menuActiveShow;
      });
      setMenuActive(menuActiveShow);
    } else {
      setMenuActive(initMenuActive);
    }
  }, [menuState.listMenu, show]);

  const _isActiveMenu = useCallback(
    (index: number) => menuActive.find(e => e.active === index),
    [menuActive]
  );

  const _isActiveSubMenu = useCallback(
    (index: number, indexSubMenu: number) =>
      menuActive[menuActive.findIndex(e => e.active === index)].subMenu.find(
        e => e.active === indexSubMenu
      ),
    [menuActive]
  );

  const _handleColorIcon = useCallback(
    (url: string) => {
      let result = COLORS.orange;
      if (!pathname.includes(url)) {
        if (configState.isDark) {
          result = COLORS.mainLight;
        } else {
          result = COLORS.mainDark;
        }
      }

      return result;
    },
    [pathname, configState.isDark]
  );

  const _handleNavigateMenu = useCallback(
    (to: string, active: boolean) => {
      if (active) {
        navigateToOtherHost(process.env.REACT_APP_HOST_WEB!);
      } else {
        history.push(to);
      }
    },
    [history]
  );

  const _handleNavigate = useCallback(
    (item: any) => {
      if (item.host === "REACT_APP_HOST_WEB") {
        navigateToOtherHost(`${process.env.REACT_APP_HOST_WEB}${item.url}`);
      } else {
        history.push(item.url);
      }
    },
    [history]
  );

  const _checkActive = useCallback(
    (link: string) => {
      if (link === "/task/list") {
        link = "/task/list/upcoming";
      }
      const base = pathname.split("/");
      const linkParam = link.split("/");

      return (
        base[base.length - 1] === linkParam[linkParam.length - 1] &&
        base[base.length - 2] === linkParam[linkParam.length - 2]
      );
    },
    [pathname]
  );

  const _handleClickMenu = useCallback(
    (item: any, index: number) => {
      if (item.url && item.childrens.length > 0) {
        _handleNavigate(item);
        _handleMenuActive(index);
      } else if (item.url) {
        _handleNavigate(item);
      } else {
        !show && _handleMenuActive(index);
      }
    },
    [_handleNavigate, _handleMenuActive, show]
  );

  const _handleClickSubMenu = useCallback(
    (item: any, index: number, indexSubMenu: number) => {
      if (item.url && item.childrens.length > 0) {
        _handleNavigate(item);
        _handleSubMenuActive(index, indexSubMenu);
      } else if (item.url) {
        _handleNavigate(item);
      } else {
        _handleSubMenuActive(index, indexSubMenu);
      }
    },
    [_handleNavigate, _handleSubMenuActive]
  );

  return (
    <div className={`sidebar-container${show ? " show" : ""}`}>
      <div className="sidebar-background" />
      <div className="sidebar-path-first">
        <img
          src={configState.isDark ? ICONS.pathFirst : ICONS.pathFirstLight}
          alt="path first"
        />
        <Button
          className="sidebar-wrap-title-djurnee"
          onPress={() => navigateToOtherHost(process.env.REACT_APP_HOST_WEB!)}
        >
          <TitleDjurneeIcon />
        </Button>
      </div>
      <div className="sidebar-wrap-menu">
        <div className="sidebar-wrap-label-menu">
          {hasPermissionDashboard ? (
            <Button
              className={`sidebar-label-menu  ${
                pathname.includes("/dashboard") ? "active" : ""
              }`}
              onPress={() =>
                _handleNavigateMenu(
                  "/dashboard",
                  pathname.includes("/dashboard")
                )
              }
            >
              <Text
                className={`sidebar-text-label-menu ${
                  pathname.includes("/dashboard") ? "active" : ""
                }`}
              >
                {txtDashboard}
              </Text>
            </Button>
          ) : null}
          {menuState.listMenu.map((item: any, index: number) =>
            item.title === "" ? (
              item.childrens.map((menu: any, indexMenu: number) => (
                // eslint-disable-next-line react/jsx-key
                <Button
                  key={indexMenu}
                  className={`sidebar-label-menu  ${
                    pathname.includes("/dashboard") ? "active" : ""
                  }`}
                  onPress={() => _handleClickSubMenu(menu, index, indexMenu)}
                >
                  <Text
                    className={`sidebar-text-label-menu ${
                      pathname.includes("/dashboard") ? "active" : ""
                    }`}
                  >
                    {menu.title.toUpperCase()}
                  </Text>
                </Button>
              ))
            ) : (
              <div className="no-display" key={index}>
                &nbsp;
              </div>
            )
          )}
        </div>
        <Container
          isLoading={menuState.isListMenuLoading}
          style={{ height: "50%" }}
        >
          <div className="sidebar-menu global-scroll-bar">
            <div style={{ width: "50%" }}>
              {menuState.listMenu.map((item: any, index: number) => (
                <div
                  key={index}
                  className={`sidebar-wrap-item-menu ${(index + 1) % 2 === 0 &&
                    "sidebar-wrap-item-menu-margin"}`}
                >
                  <div>
                    {item.childrens ? (
                      <Button
                        className="sidebar-button-menu"
                        onPress={() => _handleClickMenu(item, index)}
                      >
                        <div
                          className={`sidebar-icon-arrow-right ${
                            _isActiveMenu(index)
                              ? "sidebar-icon-arrow-rotate90"
                              : ""
                          }`}
                        >
                          <ArrowRightV2Icon
                            opacity={parseInt(
                              `${
                                item.url
                                  ? `${_checkActive(item.url) ? 1 : undefined}`
                                  : `${_isActiveMenu(index) ? 1 : undefined}`
                              }`,
                              0
                            )}
                          />
                        </div>
                        <div
                          className={`sidebar-item-text-menu${
                            _isActiveMenu(index) ? "" : " off"
                          }`}
                          style={{ textTransform: "uppercase" }}
                        >
                          {item.title}
                        </div>
                      </Button>
                    ) : (
                      // button report on dashboard
                      <Button
                        onPress={e => {
                          e.stopPropagation();
                          _handleNavigate(item);
                        }}
                        className={`item-text-menu sidebar-item-text-menu${
                          _checkActive(item.url) && _isActiveMenu(index)
                            ? " active"
                            : " off"
                        }`}
                        style={{ textTransform: "uppercase" }}
                      >
                        {item.title}
                      </Button>
                    )}
                  </div>
                  <div className="child-items">
                    {_isActiveMenu(index) &&
                      item?.childrens &&
                      item?.childrens?.length > 0 &&
                      item?.childrens?.map(
                        (itemSubMenu: any, indexSubMenu: number) => (
                          <div
                            key={indexSubMenu}
                            className={`sidebar-wrap-item-menu ${item.childrens ===
                              "sidebar-wrap-item-menu-margin"}`}
                            style={{ marginLeft: 14 }}
                          >
                            {itemSubMenu.url !== "/report" && (
                              <>
                                {itemSubMenu.childrens ? (
                                  <Button
                                    className="sidebar-button-menu"
                                    onPress={e => {
                                      e.stopPropagation();
                                      _handleClickSubMenu(
                                        itemSubMenu,
                                        index,
                                        indexSubMenu
                                      );
                                    }}
                                  >
                                    {/* under company in dashboard */}
                                    <div
                                      className={`sidebar-icon-arrow-right ${
                                        _isActiveSubMenu(index, indexSubMenu)
                                          ? "sidebar-icon-arrow-rotate90"
                                          : ""
                                      }`}
                                    >
                                      <ArrowRightV2Icon
                                        color={_handleColorIcon(
                                          itemSubMenu.url
                                        )}
                                        opacity={parseInt(
                                          `${
                                            item.url
                                              ? `${
                                                  _checkActive(item.url)
                                                    ? 1
                                                    : undefined
                                                }`
                                              : `${
                                                  _isActiveMenu(index)
                                                    ? 1
                                                    : undefined
                                                }`
                                          }`,
                                          0
                                        )}
                                      />
                                    </div>
                                    <div
                                      className={`sidebar-item-text-menu ${
                                        _isActiveSubMenu(index, indexSubMenu) &&
                                        !itemSubMenu.childrens
                                          ? " active"
                                          : ""
                                      }`}
                                    >
                                      {itemSubMenu.url === "/report"
                                        ? ""
                                        : itemSubMenu.title}
                                      {itemSubMenu.notification ? (
                                        <div className="sidebar-notification">
                                          <p
                                            style={{
                                              fontSize: 12,
                                              marginRight: 1,
                                              paddingRight: 9,
                                              paddingLeft: 9
                                            }}
                                          >
                                            {itemSubMenu.notification}
                                          </p>
                                        </div>
                                      ) : null}
                                    </div>
                                  </Button>
                                ) : (
                                  <>
                                    {/* sidebar Navigation + under Project in dashboard */}
                                    <Button
                                      onPress={e => {
                                        e.stopPropagation();
                                        _handleNavigate(itemSubMenu);
                                      }}
                                      className={`sidebar-item-text-menu off ${
                                        _checkActive(itemSubMenu.url)
                                          ? " active"
                                          : ""
                                      }`}
                                      style={{
                                        ...(pathname.includes(item.url)
                                          ? { opacity: 1 }
                                          : {})
                                      }}
                                    >
                                      {itemSubMenu.title}
                                      {itemSubMenu.notification ? (
                                        <div className="sidebar-notification">
                                          <p
                                            style={{
                                              fontSize: 12,
                                              marginRight: 1,
                                              paddingRight: 9,
                                              paddingLeft: 9
                                            }}
                                          >
                                            {itemSubMenu.notification}
                                          </p>
                                        </div>
                                      ) : null}
                                    </Button>
                                  </>
                                )}
                              </>
                            )}
                            {_isActiveSubMenu(index, indexSubMenu) &&
                              itemSubMenu.childrens &&
                              itemSubMenu.childrens.map(
                                (
                                  itemSubMenulv2: any,
                                  indexSubMenulv2: number
                                ) => (
                                  <div
                                    key={indexSubMenulv2}
                                    className={`sidebar-wrap-item-menu ${itemSubMenu
                                      .childrens.length ===
                                      indexSubMenulv2 + 1 &&
                                      "sidebar-wrap-item-menu-margin"}`}
                                    style={{ marginLeft: 14 }}
                                  >
                                    {itemSubMenulv2.childrens ? (
                                      <Button className="sidebar-button-menu">
                                        <div
                                          className={`sidebar-icon-arrow-right ${
                                            _checkActive(itemSubMenulv2.url)
                                              ? "sidebar-icon-arrow-rotate90"
                                              : ""
                                          }`}
                                        >
                                          <ArrowRightV2Icon
                                            opacity={
                                              pathname.includes(item.url)
                                                ? 1
                                                : undefined
                                            }
                                          />
                                        </div>
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    <Button
                                      onPress={e => {
                                        e.stopPropagation();
                                        _handleNavigate(itemSubMenulv2);
                                      }}
                                      className={`sidebar-item-text-menu${
                                        _checkActive(itemSubMenulv2.url)
                                          ? " active"
                                          : ""
                                      }`}
                                      style={{
                                        ...(pathname.includes(item.url)
                                          ? { opacity: 1 }
                                          : {})
                                      }}
                                    >
                                      {itemSubMenulv2.title}
                                    </Button>
                                  </div>
                                )
                              )}
                          </div>
                        )
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>

      <div className="sidebar-path-second">
        <img
          src={configState.isDark ? ICONS.pathSecond : ICONS.pathSecondLight}
          alt="path second"
        />
        <div className="sidebar-wrap-profile">
          <Container
            isLoading={homeState.profile && homeState.profile.isLoading}
          >
            <div
              onClick={() =>
                history.push(
                  `/employee-detail/${homeState.profile.data.employee.id}`
                )
              }
              className="sidebar-profile"
            >
              <div className="sidebar-wrap-image-profile">
                <img
                  className="sidebar-image-profile"
                  src={
                    (homeState.profile &&
                      homeState.profile.data.avatar &&
                      homeState.profile.data.avatar.url) ||
                    IMAGES.avatar
                  }
                  alt="profile"
                />
              </div>
              <div className="sidebar-desc-profile">
                <Text className="sidebar-text-name">
                  {homeState.profile.data && homeState.profile.data.name}
                </Text>
                <Text className="sidebar-text-role">
                  {homeState.profile.data &&
                    homeState.profile.data.employee.position.title}
                </Text>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};

SideBar.defaultProps = {
  show: false
};

export default memo(SideBar);
