import { Slide, toast } from "react-toastify";

import { Dispatch } from "../types";
import { navigateToOtherHost } from "../../utils";
import { API } from "../../configs";

export const HANDLE_LOGOUT_PENDING = "HANDLE_LOGOUT_PENDING";
export const HANDLE_LOGOUT_SUCCESS = "HANDLE_LOGOUT_SUCCESS";
export const HANDLE_LOGOUT_ERROR = "HANDLE_LOGOUT_ERROR";

export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_ERROR = "CHANGE_PASSWORD_ERROR";

export const GET_MY_PERMISSION_PENDING = "GET_MY_PERMISSION_PENDING";
export const GET_MY_PERMISSION_SUCCESS = "GET_MY_PERMISSION_SUCCESS";
export const GET_MY_PERMISSION_ERROR = "GET_MY_PERMISSION_ERROR";

export const CHECK_REGISTERED_COMPANY_PENDING =
  "CHECK_REGISTERED_COMPANY_PENDING";
export const CHECK_REGISTERED_COMPANY_SUCCESS =
  "CHECK_REGISTERED_COMPANY_SUCCESS";
export const CHECK_REGISTERED_COMPANY_ERROR = "CHECK_REGISTERED_COMPANY_ERROR";

export const GET_SETTING_PENDING = "GET_SETTING_PENDING";
export const GET_SETTING_SUCCESS = "GET_SETTING_SUCCESS";
export const GET_SETTING_ERROR = "GET_SETTING_ERROR";

export const GET_POLICY_OWNED_PENDING = "GET_POLICY_OWNED_PENDING";
export const GET_POLICY_OWNED_SUCCESS = "GET_POLICY_OWNED_SUCCESS";
export const GET_POLICY_OWNED_ERROR = "GET_POLICY_OWNED_ERROR";

export const handleLogout = (consumeApi = false) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: HANDLE_LOGOUT_PENDING });
    if (consumeApi) {
      await API.postLogout();
      navigateToOtherHost(`${process.env.REACT_APP_HOST_WEB}/login`);
    } else if (process.env.NODE_ENV === "production") {
      navigateToOtherHost(`${process.env.REACT_APP_HOST_WEB}/login`);
    }

    dispatch({ type: HANDLE_LOGOUT_SUCCESS });
  } catch (err) {
    dispatch({ type: HANDLE_LOGOUT_ERROR });
  }
};

export const changePassword = (form: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: CHANGE_PASSWORD_PENDING });

    await API.changePassword(form);
    cb();
    toast("Password is updated", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({
      type: CHANGE_PASSWORD_ERROR,
      payload: { data: err.response && err.response.data.message }
    });
  }
};

export const getMyPermission = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_MY_PERMISSION_PENDING });

    const res = await API.getMyPermission();
    dispatch({
      type: GET_MY_PERMISSION_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_MY_PERMISSION_ERROR });
  }
};

export const checkRegisteredCompany = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: CHECK_REGISTERED_COMPANY_PENDING });

    const res = await API.checkRegisteredCompany();
    dispatch({
      type: CHECK_REGISTERED_COMPANY_SUCCESS
    });
    if (res.data.data.result && res.data.success) {
      navigateToOtherHost(`${process.env.REACT_APP_HOST_WEB}/login`);
    }
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: CHECK_REGISTERED_COMPANY_ERROR });
  }
};

export const getSetting = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_SETTING_PENDING });

    const res = await API.getSetting();
    dispatch({
      type: GET_SETTING_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SETTING_ERROR });
  }
};

export const getPolicyOwned = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_POLICY_OWNED_PENDING });

    const res = await API.getPolicyOwned();
    dispatch({
      type: GET_POLICY_OWNED_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_POLICY_OWNED_ERROR });
  }
};
