import React, { Fragment, memo, useCallback } from "react";

import "./styles.scss";
import { useTranslation } from "react-i18next";
import { ICONS, IMAGES } from "../../../../../../configs";
import { copyWritings } from "../../../../../../utils";

interface Props {
  data: any;
  value: any[];
  onChange?: (e: any) => void;
}

const MultiInput = ({ data, value, onChange }: Props) => {
  const { t } = useTranslation();
  const _value = useCallback((name: string, obj: any) => {
    const index = Object.keys(obj).findIndex(e => e === name);
    const result = Object.values(obj)[index] as any;
    return result;
  }, []);

  const _onChange = useCallback(
    (resValue: string, index: number, name: string) => {
      const result = [...value];
      result[index][name] = resValue;
      onChange && onChange(result);
    },
    [onChange, value]
  );

  const _addRow = useCallback(() => {
    let result = [...value];
    let item = {};
    data.data.array_fields.map(e => {
      item = { ...item, [e.name]: "" };
      return true;
    });
    result = [...result, item];
    onChange && onChange(result);
  }, [data.data.array_fields, value, onChange]);

  const _removeRow = useCallback(
    (index: number) => {
      let result = [...value];
      result = result.filter((e, i) => i !== index);
      onChange && onChange(result);
    },
    [onChange, value]
  );

  const _isDisableAdd = useCallback(() => {
    if (data.data.array_max !== undefined) {
      if (value.length < data.data.array_max) {
        return false;
      }
      return true;
    }
    return false;
  }, [data.data.array_max, value]);

  const _isDisableRemove = useCallback(() => {
    if (data.data.array_min !== undefined) {
      if (value.length > data.data.array_min) {
        return false;
      }
      return true;
    }
    return false;
  }, [data.data.array_min, value]);

  const _setText = (text: string) => {
    const dataText = text
      .replace(/\s/g, "_")
      .replace(/[().'&]/g, "")
      .replace(/_+/g, "_")
      .toLocaleLowerCase();
    return copyWritings(dataText)
      ? copyWritings(dataText)
      : t(`policy.${dataText}`);
  };

  return (
    <div className="container-policy component-multi-input">
      <div className="row">
        <div>
          <div className="policy-title">
            {/* {`${data.title}${data.override ? " *" : ""}`} */}
            {_setText(`${data.title}${data.override ? " *" : ""}`)}
          </div>
        </div>
        <div>
          <button type="button" onClick={_addRow} disabled={_isDisableAdd()}>
            <img
              alt="tambah"
              src={_isDisableAdd() ? ICONS.btnAddDisabled : ICONS.btnAdd}
            />
          </button>
        </div>
      </div>
      {value.map((item, index) => (
        <div className="row" key={index}>
          {data.data.array_fields.map((e, i) => (
            <Fragment key={i}>
              <div className="wrap-input-policy">
                <div className="wrap-label">
                  {/* <label htmlFor="">{`${e.label}`}</label> */}
                  <label htmlFor="">{_setText(e.label)}</label>
                </div>
                <input
                  type="number"
                  step={e.type === "float" ? "0.001 " : "1"}
                  className="input-policy"
                  placeholder={e.label}
                  value={_value(e.name, item)}
                  onChange={res => _onChange(res.target.value, index, e.name)}
                />
              </div>
              {i === data.data.array_fields.length - 1 && !_isDisableRemove() && (
                <button type="button" onClick={() => _removeRow(index)}>
                  <img alt="tambah" src={IMAGES.deleteRound} />
                </button>
              )}
            </Fragment>
          ))}
        </div>
      ))}
    </div>
  );
};

export default memo(MultiInput);
