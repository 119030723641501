import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Loading, Modal } from "..";
import { IMAGES } from "../../configs";
import { getEmployee, listDepartment } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import Picker from "../Picker";

interface ModalEditAssignTaskProps {
  isOpen: boolean;
  close: () => void;
  defaultValues?: {
    department?: string;
  };
  submit: (data) => void;
}

const ModalEditAssignTask = (props: ModalEditAssignTaskProps) => {
  const dispatch = useDispatch();
  const [department, setDepartment] = useState<string>("");
  const [searchUser, setSearchUser] = useState<string>("");
  const [selectedParticipant, setSelectedParticipant] = useState<string>("");

  const {
    listDepartment: listDept,
    generateState,
    taskState,
    employee
  } = useSelector(
    (state: Reducers) => ({
      listDepartment: state.company.listDepartment,
      taskState: state.task,
      generateState: state.generate,
      employee: state.generate.listEmployee
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(listDepartment());
    dispatch(
      getEmployee(
        "",
        "",
        "",
        1,
        false,
        "",
        false,
        false,
        false,
        false,
        true,
        9999
      )
    );
  }, [dispatch]);

  const _updateListDepartments = data => {
    if (data) {
      if (data[0]) {
        data[0].label = "All";
        data[0].value = "";
      }
    }

    return data;
  };

  const _getEmployee = useCallback(() => {
    if (taskState.taskDetail && taskState.taskDetail.status !== 1) {
      return taskState.taskDetail
        ? employee.filter(e => {
            return (
              taskState.taskDetail.assignee.findIndex(
                res => res.employee.id === e.id
              ) < 0 &&
              e.name.toLowerCase().includes(searchUser.toLowerCase()) &&
              (department.length ? e.department.id === department : true)
            );
          })
        : [];
    }
    return employee;
  }, [employee, taskState.taskDetail, searchUser, department]);

  const _handleModalPic = (data: any) => {
    setSelectedParticipant(data);
  };

  const { close, isOpen } = props;

  return (
    <Modal
      titleModal=""
      btnSubmitText="CHANGE"
      onPressCancel={close}
      isOpen={isOpen}
      onPressSubmit={() => {
        if (selectedParticipant) {
          props.submit(selectedParticipant);
          // try {
          //   console.log(selectedParticipant);
          // } catch (e) {
          //   console.log(e);
          // }
        }
      }}
    >
      <div className="component-modal-userpicker-card-search-input global-shadow-card margin-bottom-36">
        <div className="component-modal-user-picker-department-container">
          <div>Department</div>
          <Picker
            listValue={_updateListDepartments(listDept)}
            onChange={e => setDepartment(e.target.value)}
            value={department}
            className="picker margin-left-12"
            containerClassName="picker-container"
          />
        </div>
        <div className="component-modal-userpicker-container-search-input">
          <img
            src={IMAGES.search}
            alt="edit"
            style={{ height: 24, width: 24 }}
          />
          <input
            placeholder="Looking for someone?"
            style={{ fontSize: 24 }}
            type="text"
            className="component-modal-userpicker-search-input margin-left-12"
            onChange={e => {
              setSearchUser(e.target.value);
            }}
            value={searchUser}
          />
        </div>
      </div>
      <div className="component-modal-user-picker-list-employee-container">
        {_getEmployee().map((data, index) => (
          <div
            key={index}
            className={`component-modal-user-picker-container-employee ${
              index > 0 ? "margin-top-24" : null
            }`}
          >
            <div className="component-modal-user-picker-container-employee-data">
              <img
                src={IMAGES.avatar}
                alt="avatar"
                className="global-shadow-card"
                style={{ height: 60, width: 60, borderRadius: 100 }}
              />
              <div className="margin-left-12">
                <div>{data.name}</div>
                <div style={{ marginTop: -8 }}>
                  {`${data.division && data.division.title} - ${data.position &&
                    data.position.title}`}
                </div>
              </div>
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => _handleModalPic(data.id)}
            >
              <img
                src={
                  selectedParticipant === data.id
                    ? IMAGES.substractButton
                    : IMAGES.addButton
                }
                alt="avatar"
                style={{ height: 28, width: 28, borderRadius: 100 }}
              />
            </div>
          </div>
        ))}
      </div>
      <Loading isVisible={generateState.isLoadingListEmployee} />
    </Modal>
  );
};

export default memo(ModalEditAssignTask);
