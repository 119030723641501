import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import "./styles.scss";
import { SideBar, WrapApp, WrapContent } from "../../components";
import { TopBar } from "./components";

// redux
import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";

// screens
import Home from "./pages/Home";

const App = () => {
  const dispatch = useDispatch();
  const getLanguage = Cookies.get("locale") || "en";

  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
  }, [dispatch, getLanguage]);

  return (
    <WrapApp className="page-term-policy term-policy">
      <SideBar show={!menuState.collapsible} />
      <TopBar />
      <WrapContent scrollEnabled className="content">
        <Home />
      </WrapContent>
    </WrapApp>
  );
};

export default App;
