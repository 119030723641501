const icons = {
  pathFirst: require("../assets/svgs/pathFirst.svg"),
  pathSecond: require("../assets/svgs/pathSecond.svg"),
  pathFirstLight: require("../assets/svgs/pathFirstLight.svg"),
  pathSecondLight: require("../assets/svgs/pathSecondLight.svg"),
  btnCopy: require("../assets/svgs/btn_copy.svg"),
  btnMessage: require("../assets/svgs/btn_message.svg"),
  btnPrint: require("../assets/svgs/btn_print.svg"),
  btnExcel: require("../assets/svgs/btn_excel.svg"),
  btnAdd: require("../assets/svgs/plus.svg"),
  btnAddDisabled: require("../assets/svgs/plus-disabled.svg"),
  viewImage: require("../assets/svgs/viewImage.svg")
};

export default icons;
