import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";

import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import { Button, Text } from "../../../../components";
import { HomeIcon } from "../../../../assets";

// redux
import { handleLogout } from "../../../../redux/actions";
import { Reducers } from "../../../../redux/types";

const TopBar = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(Cookies.get("locale") || "en");

  const dispatch = useDispatch();

  const authState = useSelector((state: Reducers) => state.auth);

  const _handleLogout = () => {
    dispatch(handleLogout(true));
  };

  const _changeLanguage = (e: any) => {
    const code = e;

    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      Cookies.set("locale", code);
      setLanguage(code);
      window.location.reload();
    }
  };

  return (
    <header className="topbar">
      <div className="section">
        <nav className="navbar">
          <div className="navbar-menu">
            <div className="navbar-start">
              <nav className="breadcrumb">
                <ul>
                  <li>
                    <div className="wrap-icon">
                      <HomeIcon />
                    </div>
                  </li>
                  <li className="item">
                    <Text className="title">Policy</Text>
                  </li>
                  <li className="item">
                    <Text className="title is-active">Policy</Text>
                  </li>
                </ul>
              </nav>
            </div>

            <div className="top-bar-wrap-item-navigation language-switcher">
              <Button
                onPress={() => _changeLanguage("en")}
                disabled={authState.isLoading}
              >
                <Text
                  className={`top-bar-title top-bar-text-logout ${language ===
                    "en" && "language-active"}`}
                >
                  EN
                </Text>
              </Button>
              <div className="language-spacing"> | </div>
              <Button
                onPress={() => _changeLanguage("id")}
                disabled={authState.isLoading}
              >
                <Text
                  className={`top-bar-title top-bar-text-logout ${language ===
                    "id" && "language-active"}`}
                >
                  ID
                </Text>
              </Button>
            </div>

            <div className="top-bar-wrap-item-navigation">
              <Button onPress={_handleLogout} disabled={authState.isLoading}>
                <Text
                  className={`top-bar-title top-bar-text-logout ${!authState.isLoading &&
                    "top-bar-title-active"}`}
                >
                  {t("logout")}
                </Text>
              </Button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default memo(TopBar);
