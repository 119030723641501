import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import "./styles.scss";
import { Button, Spinner, Text } from "../../../../components";
import { IMAGES } from "../../../../configs";
import { navigateToOtherHost } from "../../../../utils";

// redux
import { Reducers } from "../../../../redux/types";

const SideBar = () => {
  const history = useHistory();

  const homeState = useSelector((state: Reducers) => state.home);

  return (
    <div className="sidebar">
      <div
        className="container-logo2"
        style={{
          backgroundImage: `url(${IMAGES.sidemenuDecor02})`
        }}
      >
        <Button
          onPress={() => navigateToOtherHost(process.env.REACT_APP_HOST_WEB!)}
        >
          <img src={IMAGES.logoWhite} alt="Logo" />
        </Button>
      </div>

      <div className="container-menu">
        <div className="wrap-menu">
          <div className="mask">
            <div className="offset">
              <div className="content-wrapper">
                <div className="simplebar-content">
                  <div className="menu">
                    <ul className="menu-list">
                      <li>
                        <Button
                          className="dashboard"
                          onPress={() => history.push("/dashboard")}
                        >
                          <Text>DASHBOARD</Text>
                        </Button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-profile"
        style={{
          backgroundImage: `url(${IMAGES.sidemenuDescor01})`
        }}
      >
        <div className="wrap-profile">
          {homeState.profile.isLoading ? (
            <Spinner />
          ) : (
            <>
              <div className="wrap-photo">
                <img
                  src={homeState.profile.data.avatar || IMAGES.avatar}
                  alt="profile"
                />
              </div>
              <div className="wrap-bio">
                <h1>{homeState.profile.data.name}</h1>
                <p>{homeState.profile.data.employee.position.title}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(SideBar);
