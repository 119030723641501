import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

const ArrowBottomIcon = () => {
  const configState = useSelector((state: Reducers) => state.config);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.628"
      height="5.813"
      viewBox="0 0 10.628 5.813"
    >
      <path
        id="Path_2681"
        data-name="Path 2681"
        d="M-6133.186,3483.628l-4.813-4.814,4.813-4.814Z"
        transform="translate(-3473.5 -6132.686) rotate(-90)"
        fill={configState.isDark ? "#fff" : "#000"}
        stroke={configState.isDark ? "#fff" : "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      />
    </svg>
  );
};

export default memo(ArrowBottomIcon);
