import React, { ReactNode, memo } from "react";
import "./styles.scss";
import { IMAGES } from "../../../../configs";

interface Props {
  listTime: string[];
  handleEnterKeyPress: (e: any) => void;
  onChangeHour: (e: any) => void;
  valueHour: string;
  onChangeMinute: (e: any) => void;
  valueMinute: string;
  handleDelete: (e: string[]) => void;
}

const TimePicker = ({
  listTime,
  handleEnterKeyPress,
  onChangeHour,
  valueHour,
  onChangeMinute,
  valueMinute,
  handleDelete
}: Props) => {
  const _handleDeleteTime = (index: any) => {
    const timeList = listTime;
    timeList.splice(index, 1);
    handleDelete([...timeList]);
  };

  const _renderOptionHour = () => {
    let res: ReactNode[] = [];
    for (let hour = 0; hour < 24; hour += 1) {
      if (hour < 10) {
        res = [...res, <option key={hour}>{`0${hour}`}</option>];
      }
      if (hour >= 10) {
        res = [...res, <option key={hour}>{`${hour}`}</option>];
      }
    }
    return res;
  };

  const _renderOptionMinute = () => {
    let res: ReactNode[] = [];
    for (let minute = 0; minute < 60; minute += 1) {
      if (minute < 10) {
        res = [...res, <option key={minute}>{`0${minute}`}</option>];
      }
      if (minute >= 10) {
        res = [...res, <option key={minute}>{`${minute}`}</option>];
      }
    }
    return res;
  };

  return (
    <div
      className="container-time-picker"
      style={{ display: "flex", width: "100%" }}
    >
      <div>
        <select
          className="select"
          value={valueHour}
          onChange={onChangeHour}
          onKeyPress={handleEnterKeyPress}
          style={{
            color: "#666666",
            fontWeight: "bold",
            fontSize: 16,
            height: 28,
            width: 50,
            backgroundColor: "transparent",
            borderRight: 0,
            borderLeft: 0,
            borderTop: 0,
            borderBottomColor: "#666666"
          }}
        >
          <option> </option>
          {_renderOptionHour()}
        </select>
        :
        <select
          className="select"
          value={valueMinute}
          onChange={onChangeMinute}
          onKeyPress={handleEnterKeyPress}
          style={{
            color: "#666666",
            fontWeight: "bold",
            fontSize: 16,
            height: 28,
            width: 50,
            backgroundColor: "transparent",
            borderRight: 0,
            borderLeft: 0,
            borderTop: 0,
            borderBottomColor: "#666666"
          }}
        >
          <option> </option>
          {_renderOptionMinute()}
        </select>
      </div>
      <div style={{ display: "flex" }}>
        {listTime.map((item: string, index: any) => {
          return (
            <div
              className="nyobain"
              key={index}
              style={{
                padding: "3px 5px",
                boxSizing: "border-box",
                backgroundColor: "#50B1B1",
                width: 75,
                height: 28,
                marginLeft: 3,
                marginRight: 3,
                fontSize: 8,
                fontWeight: "bold",
                color: "#ffffff",
                borderRadius: 100
              }}
            >
              {item}
              <div
                onClick={() => _handleDeleteTime(index)}
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: 100,
                  marginLeft: 5,
                  backgroundColor: "#66666650"
                }}
              >
                <img
                  style={{ width: 15, height: 15, alignSelf: "center" }}
                  src={IMAGES.btnDel}
                  alt="btnDel"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default memo(TimePicker);
