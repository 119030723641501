import React, { memo } from "react";

import Modal from "../Modal";

import "./styles.scss";

// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";

interface Props {
  onPressSubmit?: () => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: string;
  onChange?: (e: any) => void;
}

const ModalDeleteData = ({ onPressCancel, onPressSubmit, isOpen }: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      titleModal={t("deleteData.title")}
      isOpen={isOpen}
      onPressCancel={onPressCancel}
      onPressSubmit={onPressSubmit}
      btnSubmitText={t("deleteData.remove")}
      btnCancelText={t("deleteData.cancel")}
    >
      <div>
        <h4>{`${t("deleteData.remove")} Data ?`}</h4>
      </div>
    </Modal>
  );
};

export default memo(ModalDeleteData);
