import { Dispatch, GetState } from "../types";

import { API } from "../../configs";

export const GET_POLICIES_SUCESSS = "GET_POLICIES_SUCESSS";
export const GET_POLICIES_PENDING = "GET_POLICIES_PENDING";
export const GET_POLICIES_ERROR = "GET_POLICIES_ERROR";

export const POST_SAVE_POLICIES_SUCESSS = "POST_SAVE_POLICIES_SUCESSS";
export const POST_SAVE_POLICIES_PENDING = "POST_SAVE_POLICIES_PENDING";
export const POST_SAVE_POLICIES_ERROR = "POST_SAVE_POLICIES_ERROR";

export const UPDATE_POLICIES_SUCESSS = "UPDATE_POLICIES_SUCESSS";

export const getPolicy = (
  departmentId?: string,
  positionId?: string,
  personnelId?: string
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_POLICIES_PENDING });

    const res = await API.getPolicy(departmentId, positionId, personnelId);

    dispatch({
      type: GET_POLICIES_SUCESSS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    dispatch({ type: GET_POLICIES_ERROR });
  }
};

export const updatePolicy = (policy_slug: string, value: any) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: UPDATE_POLICIES_SUCESSS,
    payload: {
      value,
      policy_slug,
      method: "set"
    }
  });
};

export const savePolicy = (cb: (e: any) => void) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { policy, generate } = getState();
    dispatch({ type: POST_SAVE_POLICIES_PENDING });

    let type: "global" | "department" | "position" | "personnel" = "global";
    let id = "";
    if (generate.activeDepartment.index >= 0) {
      type = "department";
      id = generate.activeDepartment.id;
    }
    if (generate.activePosition.index >= 0) {
      type = "position";
      id = generate.activePosition.id;
    }
    if (generate.activePersonil.index >= 0) {
      type = "personnel";
      id = generate.activePersonil.id;
    }
    if (policy.form.listForm.length > 0) {
      const res = await API.postSavePolicy(id, policy.form.listForm, type);
      cb(res.data.success);
    }

    dispatch({ type: POST_SAVE_POLICIES_SUCESSS });
  } catch (err) {
    dispatch({ type: POST_SAVE_POLICIES_ERROR });
  }
};
