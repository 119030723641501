/* eslint-disable radix */
const shortNumber = (
  num: number | string,
  type: "short" | "full" | "no" = "short",
  getLabel = false
) => {
  const list = [
    {
      label: type === "short" ? "K" : "Thousand",
      value: 1000
    },
    {
      label: type === "short" ? "M" : "Million",
      value: 1000000
    },
    {
      label: type === "short" ? "B" : "Billion",
      value: 1000000000
    },
    {
      label: type === "short" ? "T" : "Trillion",
      value: 10000000000000
    }
  ];

  // const _numFixed = (first: number | string, second: number) => {
  //   if (Number(first) % second === 0) {
  //     return 0;
  //   }
  //   return 1;
  // };

  // console.log(
  //   "ini ngitung: ",
  //   (3000000 / list[1].value).toFixed(_numFixed(3000000, list[1].value))
  // );

  let result: string | number = getLabel ? "" : num;
  if (num >= list[0].value && num < list[1].value) {
    result = `${Number(num) / list[0].value}`;

    result = `${parseInt(result)} ${type !== "no" ? list[0].label : ""}`;
    if (getLabel) {
      result = list[0].label;
    }
  } else if (num >= list[1].value && num < list[2].value) {
    result = `${Number(num) / list[1].value}`;
    result = `${parseInt(result)} ${type !== "no" ? list[1].label : ""}`;
    if (getLabel) {
      result = list[1].label;
    }
  } else if (num >= list[2].value && num < list[3].value) {
    result = `${Number(num) / list[2].value}`;
    result = `${parseInt(result)} ${type !== "no" ? list[2].label : ""}`;
    if (getLabel) {
      result = list[1].label;
    }
  } else if (num >= list[3].value) {
    result = `${Number(num) / list[3].value}`;
    result = `${parseInt(result)} ${type !== "no" ? list[3].label : ""}`;
    if (getLabel) {
      result = list[3].label;
    }
  }

  return result;
};

export default shortNumber;
