import React, { useCallback, useEffect } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormContext, useForm } from "react-hook-form";
import Cookies from "js-cookie";
import { copyWritings } from "../../utils";

import {
  Button,
  Container,
  Footer,
  HeaderTittle,
  SideBar,
  Text,
  TopBar,
  WrapApp
} from "../../components";
import "./styles.scss";
import {
  getMenu,
  getMyPermission,
  handleTheme,
  setActiveDepartment,
  setActivePosition,
  setActiveSpecialRole
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

import StaffList from "./StaffList";
// import Profile from "./Profile";

const Employee = () => {
  const dispatch = useDispatch();
  const methods = useForm();
  const getLanguage = Cookies.get("locale") || "en";
  const { t } = useTranslation();

  const { menuState, authState } = useSelector(
    (state: Reducers) => ({
      employeeState: state.employee,
      menuState: state.menu,
      authState: state.auth
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(handleTheme("light"));
    dispatch(getMenu("general", getLanguage));
    dispatch(getMyPermission());
  }, [dispatch, getLanguage]);

  const match = useRouteMatch();
  const history = useHistory();
  const { pathname } = history.location;

  const _checkPermission = useCallback(
    () => authState.myPermission.list.includes("employee-others-list"),
    [authState.myPermission.list]
  );

  const listPage = [
    {
      pathname: `${match.path}/staff`,
      component: <StaffList />,
      exact: true,
      titleTab: t("personnel.staffList"),
      disabled: !_checkPermission()
    }
  ];

  const _redirectPage = useCallback(
    (path: string) => {
      history.push(path);
      dispatch(setActiveDepartment(-1));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
    },
    [dispatch, history]
  );

  return (
    <Switch>
      <WrapApp>
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <FormContext {...methods}>
          <Container scroll withMaxWidth>
            <HeaderTittle title={copyWritings("employee")} />
            <div
              className="dashboard-employee-menu " // ${
              // listPage[1].disabled ? "disabled" : ""
              // }`}
            >
              {/* dasboard-employee-menu-nav */}
              {listPage.map((item, index) => (
                <div
                  className={`dasboard-employee-menu-nav ${index === 0 &&
                    item.pathname === pathname &&
                    "active"} ${index !== 0 &&
                    pathname.includes(item.pathname) &&
                    "active"} ${item.disabled ? "disabled" : ""}`}
                  key={index}
                >
                  <Button
                    onPress={() => _redirectPage(item.pathname)}
                    style={{ width: "100%", textAlign: "center" }}
                    disabled={item.disabled}
                  >
                    <Text>{item.titleTab}</Text>
                  </Button>
                </div>
              ))}
            </div>
            <div>
              {listPage.map((item, index) => (
                <Route path={item.pathname} exact={item.exact} key={index}>
                  {item.component}
                </Route>
              ))}
            </div>
          </Container>
          <Footer />
        </FormContext>
      </WrapApp>
    </Switch>
  );
};

export default Employee;
