import React from "react";

interface Props {
  title: string;
}

const Header = ({ title }: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="780"
    height="450"
    viewBox="0 0 947.383 523.814"
    className="svg-header"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="1.032"
        y1="0.636"
        x2="-0.187"
        y2="-0.131"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#50b1b1" />
        <stop offset="1" stopColor="#262261" />
      </linearGradient>
      <filter
        id="Path_2054"
        x="0"
        y="0.238"
        width="796.255"
        height="523.576"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="6" result="blur" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
      <linearGradient
        id="linear-gradient-2"
        x1="0.962"
        y1="0.429"
        x2="0.161"
        y2="0.364"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#faaf40" />
        <stop offset="0.629" stopColor="#f05a28" />
        <stop offset="1" stopColor="#db3a26" />
      </linearGradient>
      <filter
        id="Path_2055"
        x="498.352"
        y="0"
        width="449.031"
        height="439.023"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="6" result="blur-2" />
        <feFlood floodOpacity="0.161" />
        <feComposite operator="in" in2="blur-2" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Component_1" data-name="Component 1" transform="translate(18 12)">
      <g transform="matrix(1, 0, 0, 1, -18, -12)" filter="url(#Path_2054)">
        <path
          id="Path_2054-2"
          data-name="Path 2054"
          d="M263.362,4.842C153.558-12.252,3.529,1.207.076,295.533S111.689,730.545,288.935,737.449s171.1-110.219,150.359-373.538S373.167,21.937,263.362,4.842Z"
          transform="translate(18 461.2) rotate(-87)"
          fill="url(#linear-gradient)"
        />
      </g>
      <g transform="matrix(1, 0, 0, 1, -18, -12)" filter="url(#Path_2055)">
        <path
          id="Path_2055-2"
          data-name="Path 2055"
          d="M150.892.988C87.972-.189,16.776,52.1,2.279,138.471S83.35,323.739,146.27,324.916,271.852,216.849,253.962,143.179,213.813,2.165,150.892.988Z"
          transform="translate(768.36 10.77) rotate(51)"
          fill="url(#linear-gradient-2)"
        />
      </g>
      <text
        id="Company"
        transform="translate(357.976 362.655)"
        fill="#fffff7"
        fontSize="35"
        fontFamily="Poppins-Bold, Poppins"
        fontWeight="700"
      >
        <tspan x="-89.635" y="0">
          {title}
        </tspan>
      </text>
    </g>
  </svg>
);

export default Header;
