/* eslint-disable no-nested-ternary */
import React from "react";
import Spinner from "../../../../components/Spinner";

function DetailTask(props: any) {
  const { data, is_loading, translate } = props;

  return (
    <>
      {is_loading ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : data ? (
        <div
          style={{
            border: "1px solid #eeeee6",
            padding: "15px",
            boxSizing: "border-box",
            borderRadius: "20px",
            marginTop: "30px"
          }}
        >
          <div style={{ borderBottom: "1px solid #eeeee6" }}>
            <h2>DETAIL TASK</h2>
          </div>
          <div style={{ marginTop: "20px" }}>
            <div className="columns">
              <div className="column" style={{ marginLeft: "0em" }}>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.task_number")}
                  </label>
                  <p>{data.number}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.asigned_by")}
                  </label>
                  <p>{data.assigner.name}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.started_at")}
                  </label>
                  <p>{data.started_at}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData._title")}
                  </label>
                  <p>{data.title}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.description")}
                  </label>
                  <p>{data.description}</p>
                </div>
              </div>
              <div className="column" style={{ marginLeft: "0em" }}>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.created_at")}
                  </label>
                  <p>{data.created_at}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.asigner_position")}
                  </label>
                  <p>{data.assigner.position_name}</p>
                </div>
                <div className="field">
                  <label className="label" style={{ fontSize: "15px" }}>
                    {translate("deleteData.finished_at")}
                  </label>
                  <p>{data.finished_at ? data.finished_at : "-"}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%"
        }}
      >
        <div
          style={{
            height: "3.2em",
            width: "15%",
            marginTop: "35px"
          }}
        >
          <button
            onClick={() => props.destroy("task", data.id)}
            disabled={!!is_loading}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              background:
                "linear-gradient(to bottom, #faaf40 0%, #f05a28 60%, #db3a26 100%)",
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              borderColor: "lightgrey",
              cursor: "pointer"
            }}
            type="button"
          >
            {translate("deleteData.remove").toUpperCase()}
          </button>
        </div>
      </div>
    </>
  );
}

export default DetailTask;
