import { Dispatch } from "../types";
import { API } from "../../configs";

export const GET_COMPANY_TREE_LOADING = "GET_COMPANY_TREE_LOADING";
export const GET_COMPANY_TREE_SUCCESS = "GET_COMPANY_TREE_SUCCESS";
export const GET_COMPANY_TREE_ERROR = "GET_COMPANY_TREE_ERROR";

export const getCompanyTree = (params: any) => (dispatch: Dispatch) => {
  let str = "";
  Object.keys(params).forEach((key: any) => {
    if (params[key]) {
      str = `${str}${key}=${params[key]}&`;
    }
  });
  dispatch({ type: GET_COMPANY_TREE_LOADING });
  API.getCompanyTree(str)
    .then(res => {
      dispatch({ type: GET_COMPANY_TREE_SUCCESS, payload: res });
    })
    .catch(err => {
      if (typeof err.response.data !== "undefined") {
        dispatch({ type: GET_COMPANY_TREE_ERROR, payload: err.response.data });
      } else {
        dispatch({
          type: GET_COMPANY_TREE_ERROR,
          payload: err.response.statusText
        });
      }
    });
};

export const resetCompanyTree = () => (dispatch: Dispatch) => {
  dispatch({ type: GET_COMPANY_TREE_LOADING });
};
