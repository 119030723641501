import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Container, HeaderTittle, Picker } from "../../../../components";
import Date from "../component/DatePicker";
import { Reducers } from "../../../../redux/types";
import {
  getCSV,
  getClient,
  getDepartment,
  getHTML,
  getPDF,
  getPersonal,
  getProject,
  getReportType
} from "../../../../redux/actions";
import "./styles.scss";
import { documentTitle } from "../../../../utils";

interface TypeFormType {
  departmentId: string;
  clientId: string;
  from: string;
  to: string;
  project_id: string;
  type: string;
  personel_id: string;
}

const typeForm: TypeFormType = {
  departmentId: "",
  clientId: "",
  from: "",
  to: "",
  project_id: "",
  type: "expense",
  personel_id: ""
};

const Generate = () => {
  documentTitle("Report");

  const [form, setForm] = useState(typeForm);
  const dispatch = useDispatch();
  const { reportType } = useSelector(
    (state: Reducers) => ({
      reportType: state.generate
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getReportType());
    dispatch(getDepartment());
    dispatch(getClient());
    dispatch(getProject());
    dispatch(getPersonal());
  }, [dispatch]);

  const _getDepartId = (id: string) => {
    dispatch(getPersonal(id));
    setForm({ ...form, departmentId: id });
  };

  const _getClientId = (id: string) => {
    dispatch(getProject(id));
    setForm({ ...form, clientId: id });
  };

  const _getHTML = () => {
    const from = form.from ? moment(form.from).format("MM/DD/YYYY") : "";
    const to = form.to ? moment(form.to).format("MM/DD/YYYY") : "";
    const { departmentId, clientId, project_id, type, personel_id } = form;

    dispatch(
      getHTML(type, from, to, departmentId, clientId, project_id, personel_id)
    );
  };

  const _downloadPDF = () => {
    const from = form.from ? moment(form.from).format("MM/DD/YYYY") : "";
    const to = form.to ? moment(form.to).format("MM/DD/YYYY") : "";
    const { departmentId, clientId, project_id, type, personel_id } = form;

    dispatch(
      getPDF(type, from, to, departmentId, clientId, project_id, personel_id)
    );
  };

  const _downloadCSV = () => {
    const from = form.from ? moment(form.from).format("MM/DD/YYYY") : "";
    const to = form.to ? moment(form.to).format("MM/DD/YYYY") : "";
    const { departmentId, clientId, project_id, type, personel_id } = form;

    dispatch(
      getCSV(type, from, to, departmentId, clientId, project_id, personel_id)
    );
  };

  const convertToKebabCase = (string: any) => {
    return string.replace(/\s+/g, "-").toLowerCase();
  };

  const types = reportType.listReportType.map(e => {
    return { value: convertToKebabCase(e.value), label: e.value };
  });

  return (
    <Container
      withMaxWidth
      small
      className="page-report report-generate-container"
    >
      <HeaderTittle title="Report" />
      <div className="paper shadow">
        <form className="form-generate-report">
          <div className="form-group">
            <label>
              Report Type
              <Picker
                listValue={types}
                onChange={e => setForm({ ...form, type: e.target.value })}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Client
              <Picker
                listValue={reportType.listClient}
                onChange={e => _getClientId(e.target.value)}
              />
            </label>
            <label>
              Project
              <Picker
                listValue={reportType.listProject}
                onChange={e => setForm({ ...form, project_id: e.target.value })}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              From
              <Date
                onChange={e => setForm({ ...form, from: e.target.value })}
              />
            </label>
            <label>
              To
              <Date onChange={e => setForm({ ...form, to: e.target.value })} />
            </label>
          </div>

          <div className="form-group">
            <label>
              Department
              <Picker
                listValue={reportType.listDepartment}
                onChange={e => _getDepartId(e.target.value)}
              />
            </label>
            <label>
              Personel
              <Picker
                listValue={reportType.listPersonal}
                onChange={e =>
                  setForm({ ...form, personel_id: e.target.value })
                }
              />
            </label>
          </div>
        </form>
      </div>
      <div className="button-group">
        <button
          className="button shadow"
          onClick={() => _getHTML()}
          disabled={reportType.isLoadingHtml}
          type="button"
        >
          {reportType.isLoadingHtml ? "Loading..." : "HTML"}
        </button>
        <button
          className="button shadow"
          onClick={() => _downloadPDF()}
          disabled={reportType.isLoadingPdf}
          type="button"
        >
          {reportType.isLoadingPdf ? "Loading..." : "PDF"}
        </button>
        <button
          className="button shadow"
          onClick={() => _downloadCSV()}
          type="button"
        >
          {reportType.isLoadingCsv ? "Loading..." : "CSV"}
        </button>
      </div>
    </Container>
  );
};

export default Generate;
