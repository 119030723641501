import React, { memo, useEffect, useState } from "react";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../Modal";
import "./styles.scss";
import { Toast } from "../index";

// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";
import { Reducers } from "../../redux/types";

import {
  getListMasterCurrency,
  getListPaymentCategory,
  getPopulateBank
} from "../../redux/actions/master_data";
import Spinner from "../Spinner";

interface Props {
  onPressSubmit?: (data, id: any) => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: string;
  onChange?: (e: any) => void;
  title?: string;
}

const ModalPaymentCategory = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  title
}: Props) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const masterState = useSelector((state: Reducers) => state.masterdata);

  const [_form, _setForm] = useState({
    payment_method_id: null,
    title: null,
    currency_code: null,
    code: null,
    description: null,
    primary: false,
    active: true,
    account_bank_id: null,
    account_branch: null,
    account_number: null,
    account_name: null,
    method_id: null,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const [typeCode, setTypeCode] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    _setForm({
      payment_method_id: masterState.data
        ? masterState.data.payment_method_id
        : null,
      title: masterState.data ? masterState.data.title : null,
      currency_code: masterState.data ? masterState.data.currency_code : null,
      code: masterState.data ? masterState.data.code : null,
      description: masterState.data ? masterState.data.description : null,
      primary: masterState.data ? masterState.data.primary : false,
      active: masterState.data ? masterState.data.active : true,
      account_bank_id:
        masterState.data && masterState.data.bank
          ? masterState.data.bank.id
          : null,
      account_branch: masterState.data ? masterState.data.account_branch : null,
      account_number: masterState.data ? masterState.data.account_number : null,
      account_name: masterState.data ? masterState.data.account_name : null,
      method_id:
        masterState.data && masterState.data.method
          ? masterState.data.method.id
          : null,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    dispatch(getPopulateBank());
    dispatch(getListMasterCurrency());
    dispatch(getListPaymentCategory());
  }, [dispatch, masterState.data]);

  const __submited = __callback => {
    const id = masterState.data ? masterState.data.id : null;
    if (!_form.title) {
      setError(true);
      Toast({
        message: t("master_data.validation.title_is_required"),
        is_success: false
      });
      return;
    }

    if (!_form.currency_code) {
      setError(true);
      Toast({
        message: t("master_data.validation.currency_is_required"),
        is_success: false
      });
      return;
    }

    if (!_form.method_id) {
      setError(true);
      Toast({
        message: t("master_data.validation.type_is_required"),
        is_success: false
      });
      return;
    }

    if (!_form.code) {
      setError(true);
      Toast({
        message: t("master_data.validation.coa_is_required"),
        is_success: false
      });
      return;
    }

    __callback(id, _form);

    _setForm({
      payment_method_id: null,
      title: null,
      currency_code: null,
      code: null,
      description: null,
      primary: false,
      active: false,
      account_bank_id: null,
      account_branch: null,
      account_number: null,
      account_name: null,
      method_id: null,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const __closedModal = __callback => {
    setError(false);
    __callback();
  };

  const switch_active = () => {
    if (!_form.active) {
      _setForm({ ..._form, active: true });
    } else {
      _setForm({ ..._form, active: false });
    }
  };

  const switch_primary = () => {
    if (!_form.primary) {
      _setForm({ ..._form, primary: true });
    } else {
      _setForm({ ..._form, primary: false });
    }
  };

  const setTitle = e => {
    _setForm({ ..._form, title: e.target.value });
  };

  const setCurrency = e => {
    _setForm({
      ..._form,
      currency_code: e.target.value
    });
  };

  const setType = e => {
    _setForm({
      ..._form,
      method_id: e.target.value
    });

    const type_code = masterState.list_payment_category.find(_value => {
      if (_value.id === e.target.value) {
        return _value;
      }
      return null;
    });

    setTypeCode(type_code ? type_code.code : null);
  };

  const setBank = e => {
    _setForm({
      ..._form,
      account_bank_id: e.target.value
    });
  };

  const setCode = e => {
    _setForm({
      ..._form,
      code: e.target.value
    });
  };

  const setBranch = e => {
    _setForm({
      ..._form,
      account_branch: e.target.value
    });
  };

  const setAccountNumber = e => {
    _setForm({
      ..._form,
      account_number: e.target.value
    });
  };

  const setAccountName = e => {
    _setForm({
      ..._form,
      account_name: e.target.value
    });
  };

  const setDescription = e => {
    _setForm({
      ..._form,
      description: e.target.value
    });
  };

  return (
    <Modal
      titleModal={title}
      isOpen={isOpen}
      onPressCancel={() => __closedModal(onPressCancel)}
      onPressSubmit={() => __submited(onPressSubmit)}
      btnSubmitText={t("master_data.submit").toUpperCase()}
      btnCancelText={t("deleteData.cancel").toUpperCase()}
    >
      <div style={{ width: "550px", marginTop: "30px" }}>
        {masterState.is_loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <form style={{ width: "100%" }}>
            <div>
              <span className="component-modal-request-task-label">
                {t("master_data.form.title").toUpperCase()}
              </span>
              <input
                onKeyUp={setTitle}
                type="text"
                defaultValue={masterState.data ? masterState.data.title : null}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottom: "1px solid lightgrey"
                }}
              />
              <span style={{ color: "red" }}>
                {(!_form.title ||
                  (masterState.data && !masterState.data.title)) &&
                error
                  ? t("master_data.validation.title_is_required")
                  : ""}
              </span>
            </div>

            <div style={{ display: "flex", marginTop: 15 }}>
              <div style={{ width: "32%", marginRight: 15 }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.currency").toUpperCase()}
                </span>
                <select
                  onChange={setCurrency}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "38px",
                    border: "none",
                    borderBottom: "1px solid lightgrey",
                    color: "#666",
                    fontSize: "1.6rem"
                  }}
                >
                  <option value="">
                    {t("master_data.form.select_currency")}
                  </option>
                  {masterState.list_currency &&
                    masterState.list_currency.length &&
                    masterState.list_currency.map((_value, _index) => (
                      <option
                        selected={
                          masterState.data &&
                          masterState.data.currency_code &&
                          masterState.data.currency_code === _value.id
                        }
                        key={_index}
                        value={_value.id}
                      >
                        {_value.title}
                      </option>
                    ))}
                </select>
                <span style={{ color: "red" }}>
                  {(!_form.currency_code ||
                    (masterState.data && !masterState.data.currency_code)) &&
                  error
                    ? t("master_data.validation.currency_is_required")
                    : ""}
                </span>
              </div>
              <div style={{ width: "32%", marginRight: 15 }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.type").toUpperCase()}
                </span>
                <select
                  onChange={setType}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "38px",
                    border: "none",
                    borderBottom: "1px solid lightgrey",
                    color: "#666",
                    fontSize: "1.6rem"
                  }}
                >
                  <option value="">{t("master_data.form.select_type")}</option>
                  {masterState.list_payment_category &&
                    masterState.list_payment_category.length &&
                    masterState.list_payment_category.map((_value, _index) => (
                      <option
                        selected={
                          !!(
                            masterState.data &&
                            masterState.data.method &&
                            masterState.data.method.id === _value.id
                          )
                        }
                        key={_index}
                        value={_value.id}
                      >
                        {_value.title}
                      </option>
                    ))}
                </select>
                <span style={{ color: "red" }}>
                  {(!_form.method_id ||
                    (masterState.data &&
                      !masterState.data.method &&
                      !masterState.data.method.id)) &&
                  error
                    ? t("master_data.validation.type_is_required")
                    : ""}
                </span>
              </div>
              <div style={{ width: "32%" }}>
                <span className="component-modal-request-task-label">
                  {t("COA")}
                </span>
                <input
                  onKeyUp={setCode}
                  type="text"
                  defaultValue={masterState.data ? masterState.data.code : null}
                  style={{
                    width: "100%",
                    height: 35,
                    borderBottom: "1px solid lightgrey"
                  }}
                />
                <span style={{ color: "red" }}>
                  {(!_form.code ||
                    (masterState.data && !masterState.data.code)) &&
                  error
                    ? t("master_data.validation.coa_is_required")
                    : ""}
                </span>
              </div>
            </div>

            <div
              style={{
                display:
                  typeCode === "101" || typeCode === null ? "none" : "flex",
                marginTop: 15,
                width: "100%"
              }}
            >
              <div style={{ width: "50%", marginRight: 15 }}>
                <span className="component-modal-request-task-label">
                  {t("Bank")}
                </span>
                <select
                  onChange={setBank}
                  style={{
                    cursor: "pointer",
                    width: "100%",
                    height: "38px",
                    border: "none",
                    borderBottom: "1px solid lightgrey",
                    color: "#666",
                    fontSize: "1.6rem"
                  }}
                >
                  <option value="">{t("master_data.form.select_bank")}</option>
                  {masterState.list_bank &&
                    masterState.list_bank.length &&
                    masterState.list_bank.map((_value, _index) => (
                      <option
                        selected={
                          !!(
                            masterState.data &&
                            masterState.data.bank &&
                            masterState.data.bank.id === _value.id
                          )
                        }
                        key={_index}
                        value={_value.id}
                      >
                        {_value.title}
                      </option>
                    ))}
                </select>
              </div>
              <div style={{ width: "50%" }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.branch").toUpperCase()}
                </span>
                <input
                  onKeyUp={setBranch}
                  type="text"
                  defaultValue={
                    masterState.data ? masterState.data.account_branch : null
                  }
                  style={{
                    width: "100%",
                    height: 35,
                    borderBottom: "1px solid lightgrey"
                  }}
                />
              </div>
            </div>

            <div
              style={{
                display:
                  typeCode === null || typeCode === "101" ? "none" : "flex",
                marginTop: 15
              }}
            >
              <div style={{ width: "50%", marginRight: 15 }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.account_number").toUpperCase()}
                </span>
                <input
                  onKeyUp={setAccountNumber}
                  type="text"
                  defaultValue={
                    masterState.data ? masterState.data.account_number : null
                  }
                  style={{
                    width: "100%",
                    height: 35,
                    borderBottom: "1px solid lightgrey"
                  }}
                />
              </div>
              <div style={{ width: "50%" }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.account_name").toUpperCase()}
                </span>
                <input
                  onKeyUp={setAccountName}
                  type="text"
                  defaultValue={
                    masterState.data ? masterState.data.account_name : null
                  }
                  style={{
                    width: "100%",
                    height: 35,
                    borderBottom: "1px solid lightgrey"
                  }}
                />
              </div>
            </div>

            <div style={{ display: "flex", marginTop: 15, width: "100%" }}>
              <div style={{ width: "50%" }}>
                <span className="component-modal-request-task-label">
                  {t("master_data.form.visibility").toUpperCase()}
                </span>
                <div style={{ marginTop: 5, display: "flex" }}>
                  <div>
                    <Switch
                      onChange={switch_active}
                      checked={_form.active}
                      onColor="#ff7600"
                      offColor="#b5b5b5"
                      onHandleColor="#fffff7"
                      handleDiameter={25}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={27}
                      width={55}
                      tabIndex={0}
                    />
                  </div>
                  <div style={{ marginLeft: 15 }}>
                    {_form.active
                      ? t("master_data.form.active")
                      : t("master_data.form.inactive")}
                  </div>
                </div>
              </div>

              <div style={{ width: "50%" }}>
                <span className="component-modal-request-task-label">
                  {`${t("master_data.form.primary").toUpperCase()} ?`}
                </span>
                <div style={{ display: "flex" }}>
                  <div style={{ marginTop: 5 }}>
                    <Switch
                      onChange={switch_primary}
                      checked={_form.primary}
                      onColor="#ff7600"
                      offColor="#b5b5b5"
                      onHandleColor="#fffff7"
                      handleDiameter={25}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={27}
                      width={55}
                      tabIndex={0}
                    />
                  </div>
                  <div style={{ marginLeft: 15, marginTop: 5 }}>
                    {_form.primary
                      ? t("master_data.form.yes")
                      : t("master_data.form.no")}
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: 20 }}>
              <span className="component-modal-request-task-label">
                {t("master_data.form.description").toUpperCase()}
              </span>
              <textarea
                onKeyUp={setDescription}
                defaultValue={
                  masterState.data ? masterState.data.description : null
                }
                rows={5}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1.6rem",
                  border: "none",
                  width: "100%",
                  borderBottom: "1px solid lightgrey"
                }}
              />
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default memo(ModalPaymentCategory);
