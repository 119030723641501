import React, { ReactNode, memo } from "react";

import "./styles.scss";

interface Props {
  children: ReactNode;
  className?: string;
}

const WrapApp = ({ children, className }: Props) => (
  <div className={`wrap-app-container ${className}`}>{children}</div>
);

export default memo(WrapApp);
