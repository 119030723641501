/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Switch from "react-switch";
import moment from "moment";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  addAccountsEmployee,
  deleteAccountEmployee,
  deleteAccountsEmployee,
  getAllBank,
  getAllPosition,
  getAllRoles,
  getCompanyDetail,
  getProfileStaff,
  getRegency,
  removeFormMyProfileEmployee,
  setFormAccountsEmployee,
  setFormMyProfileEmployee,
  setImageMyProfileEmployee,
  setPrimaryAccountEmployee,
  setStaffEmployeeProfile
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import {
  Autocomplete,
  Button,
  Checkbox,
  Container,
  FileUpload,
  Modal,
  ModalPassword,
  Text,
  Upload
} from "../../../components";
import { AttachmentInput, Card, CardForm } from "../components";
import { ICONS, IMAGES } from "../../../configs";
import "./styles.scss";
import "../../../sass/styles.scss";
import { documentTitle, openNewTab } from "../../../utils";
import AttachmentInputBussiness from "../../EmployeeDetail/components/AttachmentInputBussiness";

interface Props {
  isMe?: boolean;
  idForm?: string;
  isLoadListRole?: boolean;
  isLoadingReset?: boolean;
}

const Company = ({ isMe, isLoadListRole, isLoadingReset, idForm }: Props) => {
  const Address: any = {
    alias: "",
    line_1: "",
    line_2: "",
    city: "",
    zicode: "",
    phone: "",
    fax: ""
  };

  documentTitle("Company - Detail");
  const dispatch = useDispatch();
  const { register, errors, setValue, watch } = useFormContext();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const [showFormAddress] = useState(false);
  const [addAddress, setAddress] = useState([Address]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalAccountOpen, setModalAccountOpen] = useState(false);
  const [indexAddress, setIndexAddress] = useState(0);
  const [modalChangePass, setModalChangePass] = useState(false);
  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [visiblePass, setVisiblePass] = useState(false);
  const [visiblePassConfirmation, setVisiblePassConfirmation] = useState(false);
  const [chagePass, setChangePass] = useState(false);

  useEffect(() => {
    if (isMe) {
      dispatch(
        setFormMyProfileEmployee("idNumber", {
          number: "",
          file: {}
        })
      );
      dispatch(
        setFormMyProfileEmployee("passport", {
          number: "",
          expired_at: {
            mm: "",
            yy: ""
          },
          file: {}
        })
      );
      dispatch(
        setFormMyProfileEmployee("taxNumber", {
          number: "",
          file: {}
        })
      );
      dispatch(setFormMyProfileEmployee("city", ""));
      dispatch(setImageMyProfileEmployee(""));
      dispatch(
        getCompanyDetail(e => {
          return e;
        })
      );
      dispatch(getProfileStaff("self"));
      dispatch(addAccountsEmployee(true));
    }

    dispatch(getRegency());
    dispatch(getAllBank());
    dispatch(getAllRoles());
    dispatch(getAllPosition());
  }, [dispatch, isMe]);

  const {
    companyState,
    employeeState,
    walkthroughState,
    generateState,
    authState
  } = useSelector(
    (state: Reducers) => ({
      companyState: state.company,
      employeeState: state.employee,
      walkthroughState: state.walkthrough,
      generateState: state.generate,
      authState: state.auth
    }),
    shallowEqual
  );

  // const _handleAdress = () => {
  //   if (!showFormAddress) {
  //     setShowFormAddress(true);
  //   } else {
  //     setAddress(addAddress.concat([Address]));
  //     // console.log(addAddress);
  //   }
  // };

  const _handleModalDelete = (index: any) => {
    setIndexAddress(index);
    setModalOpen(true);
  };

  // const _handleSubmit = () => {
  //   let formAddress: any = [];
  //   addAddress.map((item: any) => {
  //     formAddress = [
  //       ...formAddress,
  //       {
  //         alias: item.alias,
  //         city: { name: item.city, id: 1 },
  //         fax: item.fax,
  //         id: 2,
  //         is_primary: false,
  //         line_1: item.line_1,
  //         line_2: item.line_2,
  //         phone: item.phone,
  //         zipcode: null
  //       }
  //     ];
  //     return formAddress;
  //   });
  //   dispatch(addFormDataAddress(formAddress));
  // };

  const _handleModalDeleteAccount = useCallback((index: number) => {
    setIndexAddress(index);
    setModalAccountOpen(true);
  }, []);

  const _handleRemoveAdress = (idx: any) => {
    setModalOpen(false);
    setAddress(addAddress.filter((s, sidx) => sidx !== idx));
  };

  const _handleRemoveAccount = useCallback(
    (idx: number) => {
      setIsLoadingDeleteAccount(true);
      setModalAccountOpen(false);
      dispatch(deleteAccountsEmployee(idx));
      setTimeout(() => {
        setIsLoadingDeleteAccount(false);
      }, 300);
    },
    [dispatch]
  );

  const indexForm =
    companyState.loadCompanyDetail.addresses &&
    companyState.loadCompanyDetail.addresses.length;

  const _city = useCallback(() => {
    let result: any[] = [];
    walkthroughState.listRegency.data.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.name }];
      return true;
    });
    return result;
  }, [walkthroughState.listRegency.data]);

  const _allPosition = useCallback(() => {
    let result: any[] = [];
    generateState.allPosition.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.title }];
      return true;
    });
    return result;
  }, [generateState.allPosition]);

  const _activeRoles = useCallback(
    (id: string) => {
      const result = employeeState.staff.data.roles.findIndex(
        e => e.id.toString() === id.toString()
      );
      return result >= 0;
    },
    [employeeState.staff.data.roles]
  );

  const _handleFormValidation = useCallback(
    (isShowError: boolean, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  const _checkPermissionOthers = useCallback(
    () => authState.myPermission.list.includes("employee-others-edit"),
    [authState.myPermission.list]
  );

  const _checkMinExpiredDateOutsource = () => {
    const startDateData = new Date(startDate);
    if (startDateData > new Date()) {
      return moment(startDateData)
        .add(1, "days")
        .format("YYYY-MM-DD");
    }
    return moment().format("YYYY-MM-DD");
  };

  useEffect(() => {
    setValue("name", employeeState.staff.data.name);
    setValue(
      "pob",
      employeeState.staff.data.primaryUser
        ? employeeState.staff.data.primaryUser.pob
        : ""
    );
    setValue(
      "email",
      employeeState.staff.data.primaryUser
        ? employeeState.staff.data.primaryUser.email
        : ""
    );
    setValue(
      "dob",
      employeeState.staff.data.primaryUser
        ? employeeState.staff.data.primaryUser.dob
        : ""
    );
    setValue(
      "code",
      employeeState.staff.data?.code ? employeeState.staff.data?.code : ""
    );
    setValue(
      "address",
      (employeeState.staff.data.addresses &&
        employeeState.staff.data.addresses.length > 0 &&
        employeeState.staff.data.addresses[0].address.address) ||
        ""
    );
    setValue(
      "started_at",
      (employeeState.staff.data.started_at &&
        employeeState.staff.data.started_at) ||
        ""
    );
    setValue(
      "expired_at",
      (employeeState.staff.data.expired_at &&
        employeeState.staff.data.expired_at) ||
        ""
    );
    setValue(
      "zip",
      (employeeState.staff.data.addresses &&
        employeeState.staff.data.addresses.length > 0 &&
        employeeState.staff.data.addresses[0].address.zip) ||
        ""
    );
    setValue(
      "phone",
      (employeeState.staff.data.addresses &&
        employeeState.staff.data.addresses.length > 0 &&
        employeeState.staff.data.addresses[0].address.phone) ||
        ""
    );
    setValue(
      "fax",
      (employeeState.staff.data.addresses &&
        employeeState.staff.data.addresses.length > 0 &&
        employeeState.staff.data.addresses[0].address.fax) ||
        ""
    );
  }, [
    employeeState.staff.data.name,
    employeeState.staff.data.addresses,
    employeeState.staff.data.primaryUser,
    employeeState.staff.data.started_at,
    employeeState.staff.data.expired_at,
    setValue,
    employeeState.staff.data
  ]);

  useEffect(() => {
    setValue("number", employeeState.staff.data.number || "");
  }, [employeeState.staff.data.number, authState.myPermission.list, setValue]);

  return (
    <Container
      isLoading={
        employeeState.staff.isLoading ||
        employeeState.isLoading ||
        isLoadListRole
      }
      typeLoading="modal"
    >
      <div className="form-user">
        <div className="columns dashboard-company-container-card shadow is-8r">
          <div className="column">
            <div>
              <div style={{ width: "40%", marginBottom: 20 }}>
                <div className="component-upload shadow">
                  <input
                    type="file"
                    onChange={e =>
                      dispatch(setImageMyProfileEmployee(e.target.files![0]))
                    }
                  />
                  <img src={IMAGES.pencil} alt="edit" />
                  <img
                    src={
                      employeeState.staff.form.imageString ||
                      (employeeState.staff.data.avatar &&
                        employeeState.staff.data.avatar.url) ||
                      IMAGES.avatar
                    }
                    alt="foto"
                  />
                </div>
              </div>
            </div>
            <div
              className="margin-bottom-8"
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                whiteSpace: "nowrap",
                cursor: _checkPermissionOthers() ? "unset" : "not-allowed"
              }}
            >
              <Switch
                onColor="#50B1B1"
                width={37}
                height={22}
                onChange={(event: boolean) =>
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "is_disabled",
                      value: !event
                    })
                  )
                }
                onKeyPress={() =>
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "is_disabled",
                      value: !employeeState.staff.data.is_disabled
                    })
                  )
                }
                checked={!employeeState.staff.data.is_disabled}
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={!_checkPermissionOthers()}
                tabIndex={0}
              />
              <div className="margin-left-8">
                <div
                  className={`dashboard-profile-status-user ${
                    !employeeState.staff.data.is_disabled
                      ? "active"
                      : "inactive"
                  }`}
                >
                  {!employeeState.staff.data.is_disabled
                    ? t("personnel.form.active")
                    : t("personnel.form.inactive")}
                </div>
              </div>
            </div>
            <div
              className="margin-bottom-16"
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                whiteSpace: "nowrap",
                cursor: _checkPermissionOthers() ? "unset" : "not-allowed"
              }}
            >
              <Switch
                onColor="#50B1B1"
                width={37}
                height={22}
                onChange={(event: boolean) =>
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "is_hidden",
                      value: event
                    })
                  )
                }
                onKeyPress={() =>
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "is_hidden",
                      value: !employeeState.staff.data.is_hidden
                    })
                  )
                }
                checked={employeeState.staff.data.is_hidden}
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={!_checkPermissionOthers()}
              />
              <div className="margin-left-8">
                <div
                  className={`dashboard-profile-status-user ${
                    employeeState.staff.data.is_hidden ? "active" : "inactive"
                  }`}
                >
                  {employeeState.staff.data.is_hidden
                    ? t("personnel.form.hidden")
                    : t("personnel.form.unhidden")}
                </div>
              </div>
            </div>

            <div className="dashboard-profile-status-user-container roles">
              <div
                style={{ marginLeft: 45 }}
                className="dashboard-profile-status-user-text role"
              >
                {t("personnel.generalRole")}
              </div>
              {!employeeState.staff.isLoading &&
                !isLoadListRole &&
                employeeState.staff.data.roles &&
                employeeState.listRoles.list
                  .filter(item => !String(item.name).includes("loan"))
                  .map((item: any, index: number) => {
                    return (
                      <div style={{ marginLeft: 5, marginTop: 10 }} key={index}>
                        <Checkbox
                          label={item.name}
                          checked={_activeRoles(item.id)}
                          defaultChecked={_activeRoles(item.id)}
                          disabled={!_checkPermissionOthers()}
                          onChange={() => {
                            let value = [...employeeState.staff.data.roles];
                            if (_activeRoles(item.id)) {
                              value = value.filter(
                                e => e.id.toString() !== item.id.toString()
                              );
                            } else {
                              value = [...employeeState.staff.data.roles, item];
                            }
                            dispatch(
                              setStaffEmployeeProfile({
                                formName: "roles",
                                value
                              })
                            );
                          }}
                        />
                      </div>
                    );
                  })}
            </div>

            <div className="dashboard-profile-status-user-container roles margin-top-16">
              <div
                style={{ marginLeft: 45 }}
                className="dashboard-profile-status-user-text role"
              >
                {t("personnel.loanRole")}
              </div>
              {!employeeState.staff.isLoading &&
                !isLoadListRole &&
                employeeState.staff.data.roles &&
                employeeState.listRoles.list
                  .filter(item => String(item.name).includes("loan"))
                  .map((item: any, index: number) => {
                    return (
                      <div style={{ marginLeft: 5, marginTop: 10 }} key={index}>
                        <Checkbox
                          label={item.name}
                          checked={_activeRoles(item.id)}
                          defaultChecked={_activeRoles(item.id)}
                          disabled={!_checkPermissionOthers()}
                          onChange={() => {
                            let value = [...employeeState.staff.data.roles];
                            if (_activeRoles(item.id)) {
                              value = value.filter(
                                e => e.id.toString() !== item.id.toString()
                              );
                            } else {
                              value = [...employeeState.staff.data.roles, item];
                            }
                            dispatch(
                              setStaffEmployeeProfile({
                                formName: "roles",
                                value
                              })
                            );
                          }}
                        />
                      </div>
                    );
                  })}
            </div>
            <div
              style={{ marginLeft: 45, marginTop: 20, marginBottom: 10 }}
              className="dashboard-profile-status-user-text role"
            >
              {t("personnel.form.outsource")}
            </div>
            <div
              className="margin-bottom-16"
              style={{
                display: "flex",
                flexDirection: "row",
                textAlign: "center",
                whiteSpace: "nowrap",
                cursor: _checkPermissionOthers() ? "unset" : "not-allowed"
              }}
            >
              <Switch
                onColor="#50B1B1"
                width={37}
                height={22}
                onChange={(event: boolean) => {
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "outsource",
                      value: event
                    })
                  );
                }}
                onKeyPress={() => {
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "outsource",
                      value: !employeeState.staff.data.outsource
                    })
                  );
                }}
                checked={employeeState.staff.data.outsource}
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                disabled={!_checkPermissionOthers()}
              />
              <div className="margin-left-8">
                <div
                  className={`dashboard-profile-status-user ${
                    employeeState.staff.data.outsource ? "active" : "inactive"
                  }`}
                >
                  {employeeState.staff.data.outsource
                    ? t("personnel.form.true")
                    : t("personnel.form.false")}
                </div>
                {/* <div className="dashboard-profile-status-user-text est">
                {`active since ${moment(
                  employeeState.staff.data.join_date
                ).format("DD-MM-YY")}`}
              </div> */}
              </div>
            </div>
          </div>
          <div className="column">
            <div>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.name")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  style={{ fontWeight: "bold" }}
                  className="dashboard-company-text-regular"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.name,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.name")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.birthPlace")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="pob"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.pob,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.birthPlace")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.dateBirth")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  placeholder="YYYY/MM/DD"
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  type="date"
                  name="dob"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.dob,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.dateBirth")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>Employee Code</Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="code"
                  placeholder="Optional"
                  ref={register}
                />
              </div>
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>{t("personnel.department")}</Text>
              <div
                style={{ marginTop: 16 }}
                className="dashboard-profile-status-user-text bold"
              >
                {employeeState.staff.data.department
                  ? employeeState.staff.data.department.title
                  : t("personnel.departmentAutoChosen")}
              </div>
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.position")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              {_checkPermissionOthers() ? (
                <div
                  className="dashboard-company-underline"
                  style={{ marginTop: 10 }}
                >
                  {!isLoadingReset && (
                    <Autocomplete
                      option={_allPosition()}
                      placeHolder={
                        (employeeState.staff.data.position &&
                          employeeState.staff.data.position.title) ||
                        ""
                      }
                      onValueChange={e =>
                        dispatch(
                          setStaffEmployeeProfile({
                            formName: "position",
                            value: {
                              id: e.value,
                              title: e.label
                            }
                          })
                        )
                      }
                    />
                  )}
                </div>
              ) : (
                <div
                  style={{ marginTop: 10 }}
                  className="dashboard-profile-status-user-text bold"
                >
                  {employeeState.staff.data.position &&
                    employeeState.staff.data.position.title}
                </div>
              )}

              {_handleFormValidation(
                (errors.name ||
                  errors.pob ||
                  errors.dob ||
                  errors.number ||
                  errors.address ||
                  errors.zip ||
                  errors.phone ||
                  errors.fax ||
                  employeeState.staff.formValidate.city) &&
                  employeeState.staff.data.position.id === "",
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.position")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.staffId")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              {_checkPermissionOthers() ? (
                <div
                  className="dashboard-company-underline"
                  style={{ marginTop: 10 }}
                >
                  <input
                    className="dashboard-company-text-regular"
                    style={{ fontWeight: "bold" }}
                    name="number"
                    ref={register({ required: true })}
                  />
                </div>
              ) : (
                <div
                  style={{ marginTop: 10 }}
                  className="dashboard-profile-status-user-text bold"
                >
                  {employeeState.staff.data.number || ""}
                </div>
              )}
              {_handleFormValidation(
                errors.number,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.staffId")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.address")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="address"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.address,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.address")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.city")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                {!isLoadingReset && (
                  <Autocomplete
                    option={_city()}
                    placeHolder={
                      (employeeState.staff.data.addresses &&
                        employeeState.staff.data.addresses.length > 0 &&
                        employeeState.staff.data.addresses[0].address.regency &&
                        employeeState.staff.data.addresses[0].address.regency
                          .name) ||
                      (employeeState.staff.data.addresses &&
                        employeeState.staff.data.addresses.length > 0 &&
                        employeeState.staff.data.addresses[0].address.city) ||
                      ""
                    }
                    onValueChange={e =>
                      dispatch(setFormMyProfileEmployee("city", e.value))
                    }
                  />
                )}
              </div>
              {_handleFormValidation(
                (errors.name ||
                  errors.pob ||
                  errors.dob ||
                  errors.number ||
                  errors.address ||
                  errors.zip ||
                  errors.phone ||
                  errors.fax ||
                  employeeState.staff.formValidate.city) &&
                  employeeState.staff.form.city === "",
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.city")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.zip")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="zip"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.zip,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.zip")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>
                <>
                  {t("personnel.form.phone")}
                  <span className="profile-superscrip"> * </span>
                </>
              </Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="phone"
                  ref={register({ required: true })}
                />
              </div>
              {_handleFormValidation(
                errors.phone,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.phone")
                )
              )}
            </div>
            <div style={{ marginTop: 30, marginBottom: 15 }}>
              <Text style={{ fontSize: 14 }}>{t("personnel.form.fax")}</Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  className="dashboard-company-text-regular"
                  style={{ fontWeight: "bold" }}
                  name="fax"
                  ref={register}
                />
              </div>
              {_handleFormValidation(
                errors.fax,
                sprintf(
                  t("personnel.form.isRequiredMsg"),
                  t("personnel.form.fax")
                )
              )}
            </div>
            <div>
              {employeeState.listEmployee.data &&
                employeeState.listEmployee.data.addresses.map(
                  (address: any, index: any) => (
                    <div style={{ marginBottom: 15 }} key={index}>
                      <Card
                        data={address}
                        name={`Address #${index + 1}`}
                        hapus={false}
                        primary={address.is_primary}
                        isAccount={false}
                      />
                    </div>
                  )
                )}
            </div>
            {showFormAddress
              ? addAddress.map((submit, index) => {
                  return (
                    <div style={{ marginBottom: 10 }} key={index}>
                      <CardForm
                        name={`Address # ${indexForm + index + 1}`}
                        hapus
                        hapusAddress={() => _handleModalDelete(index)}
                        primary={false}
                        data={submit}
                        onChange={e => {
                          const a = addAddress;
                          a[index][e.name] = e.value;
                          setAddress(a);
                        }}
                      />
                    </div>
                  );
                })
              : null}

            {/* <div style={{ marginBottom: 60 }}>
            <Button onPress={() => _handleAdress()}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <img alt="tambah" src={ICONS.btnAdd} />
                <div style={{ paddingTop: 15 }}>
                  <Text className="dashboard-company-button-add">
                    ADD ADDRESS
                  </Text>
                </div>
              </div>
            </Button>
          </div> */}
          </div>
          <div className="column">
            <div className="components-employee-custom-label">
              <>
                {t("personnel.form.email")}
                <span className="profile-superscrip"> * </span>
              </>
            </div>
            <div
              className="dashboard-company-underline"
              style={{ marginTop: 10 }}
            >
              <input
                className="dashboard-company-text-regular"
                style={{ fontWeight: "bold" }}
                name="email"
                ref={register({ required: true })}
              />
            </div>
            {_handleFormValidation(
              errors.email,
              sprintf(
                t("personnel.form.isRequiredMsg"),
                t("personnel.form.email")
              )
            )}

            {employeeState.staff.data.primaryUser &&
              employeeState.staff.data.primaryUser.user_password &&
              employeeState.staff.data.primaryUser.user_password.updated_at && (
                <>
                  <div
                    style={{ marginTop: 30 }}
                    className="components-employee-custom-label"
                  >
                    {t("personnel.form.passwordStatus")}
                  </div>
                  <div
                    style={{ marginTop: 16 }}
                    className="dashboard-profile-status-user-text bold-green"
                  >
                    {`Last changed on ${employeeState.staff.data.primaryUser &&
                      moment(
                        employeeState.staff.data.primaryUser.user_password
                          .updated_at
                      ).format("ddd, DD MMMM YYYY")}`}
                  </div>
                </>
              )}

            {isMe ? (
              <Button
                style={{ marginTop: 16 }}
                className="dashboard-profile-btn-change-password"
                onPress={() => setModalChangePass(true)}
              >
                {t("btn.changePassword")}
              </Button>
            ) : (
              <>
                <div style={{ marginTop: 30, marginBottom: 15 }}>
                  <Text style={{ fontSize: 14 }}>
                    <>
                      {t("personnel.form.password")}
                      {idForm === "add" && (
                        <span className="profile-superscrip"> * </span>
                      )}
                    </>
                  </Text>
                  <div
                    className="dashboard-company-underline"
                    style={{ marginTop: 10, display: "flex" }}
                  >
                    <input
                      className="dashboard-company-text-regular"
                      style={{ fontWeight: "bold" }}
                      type={`${visiblePass ? "text" : "password"}`}
                      name="password"
                      onChange={() => setChangePass(true)}
                      ref={register(
                        idForm === "add"
                          ? { required: true }
                          : { required: false }
                      )}
                      autoComplete="new-password"
                    />
                    <Button onPress={() => setVisiblePass(!visiblePass)}>
                      <img
                        alt="n/a"
                        src={visiblePass ? IMAGES.eyeOff : IMAGES.eye}
                        style={{ height: 24, marginRight: 2 }}
                      />
                    </Button>
                  </div>
                  {_handleFormValidation(
                    errors.password,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.password")
                    )
                  )}
                </div>
                {chagePass && watch("password") && (
                  <div style={{ marginTop: 30, marginBottom: 15 }}>
                    <Text style={{ fontSize: 14 }}>
                      <>
                        {t("personnel.form.confirmPassword")}
                        <span className="profile-superscrip"> * </span>
                      </>
                    </Text>
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10, display: "flex" }}
                    >
                      <input
                        className="dashboard-company-text-regular"
                        style={{ fontWeight: "bold" }}
                        type={`${
                          visiblePassConfirmation ? "text" : "password"
                        }`}
                        name="confirmation_password"
                        ref={register({ required: true })}
                      />
                      <Button
                        onPress={() =>
                          setVisiblePassConfirmation(!visiblePassConfirmation)
                        }
                      >
                        <img
                          alt="n/a"
                          src={
                            visiblePassConfirmation ? IMAGES.eyeOff : IMAGES.eye
                          }
                          style={{ height: 24, marginRight: 2 }}
                        />
                      </Button>
                    </div>
                    {_handleFormValidation(
                      errors.confirmation_password,
                      sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("personnel.form.confirmPassword")
                      )
                    )}
                  </div>
                )}
              </>
            )}

            {employeeState.staff.data && employeeState.staff.data.outsource && (
              <div style={{ marginTop: 30, marginBottom: 15 }}>
                <Text style={{ fontSize: 14 }}>
                  {t("personnel.form.startDate")}
                </Text>
                <div
                  className="dashboard-company-underline"
                  style={{ marginTop: 10 }}
                >
                  <input
                    placeholder="YYYY/MM/DD"
                    onChange={(e: any) => setStartDate(e.target.value)}
                    className="dashboard-company-text-regular"
                    style={{ fontWeight: "bold" }}
                    type="date"
                    name="started_at"
                    max={
                      endDate === ""
                        ? ""
                        : moment(endDate)
                            .subtract(1, "days")
                            .format("YYYY-MM-DD")
                    }
                    ref={register({ required: true })}
                  />
                </div>
                {_handleFormValidation(
                  errors.started_at,
                  sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("personnel.form.startDate")
                  )
                )}
              </div>
            )}

            {employeeState.staff.data && employeeState.staff.data.outsource && (
              <div style={{ marginTop: 30, marginBottom: 15 }}>
                <Text style={{ fontSize: 14 }}>
                  {t("personnel.form.expiredDate")}
                </Text>
                <div
                  className="dashboard-company-underline"
                  style={{ marginTop: 10 }}
                >
                  <input
                    placeholder="YYYY/MM/DD"
                    onChange={(e: any) => setEndDate(e.target.value)}
                    className="dashboard-company-text-regular"
                    style={{ fontWeight: "bold" }}
                    type="date"
                    name="expired_at"
                    min={_checkMinExpiredDateOutsource()}
                    ref={register({ required: true })}
                  />
                </div>
                {_handleFormValidation(
                  errors.expired_at,
                  sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("personnel.form.expiredDate")
                  )
                )}
              </div>
            )}

            <div style={{ marginTop: 40, marginBottom: 26.5 }}>
              <AttachmentInputBussiness
                value={
                  (employeeState.staff.form.employeeBussiness &&
                    employeeState.staff.form.employeeBussiness.file &&
                    employeeState.staff.form.employeeBussiness.file.name) ||
                  (employeeState.staff.data.employeeBussiness &&
                    employeeState.staff.data.employeeBussiness.file &&
                    employeeState.staff.data.employeeBussiness.file.title)
                }
                valueInput={employeeState.staff.form.employeeBussiness}
                onChange={e =>
                  dispatch(
                    setFormMyProfileEmployee("employeeBussiness", {
                      ...employeeState.staff.form.employeeBussiness,
                      [e.name !== "name" ? "address" : e.name]: e.value
                    })
                  )
                }
                onChangeFile={e =>
                  e.target.files![0] &&
                  dispatch(
                    setFormMyProfileEmployee("employeeBussiness", {
                      ...employeeState.staff.form.employeeBussiness,
                      file: e.target.files![0]
                    })
                  )
                }
                linkImage={
                  (employeeState.staff.data.employeeBussiness &&
                    employeeState.staff.data.employeeBussiness.file &&
                    employeeState.staff.data.employeeBussiness.file.url) ||
                  ""
                }
                valueImageLocal={
                  employeeState.staff.form.employeeBussiness &&
                  employeeState.staff.form.employeeBussiness.file &&
                  employeeState.staff.form.employeeBussiness.file.name
                    ? employeeState.staff.form.employeeBussiness.file
                    : ""
                }
                onRemoveImage={() =>
                  dispatch(removeFormMyProfileEmployee("employeeBussiness"))
                }
              />
            </div>
            <div style={{ marginBottom: 26.5 }}>
              <AttachmentInput
                label={t("personnel.form.idCardNo")}
                inputPassport={false}
                value={
                  employeeState.staff.form.idNumber.file.name ||
                  (employeeState.staff.data.idNumber &&
                    employeeState.staff.data.idNumber.file &&
                    employeeState.staff.data.idNumber.file.title)
                }
                valueInput={employeeState.staff.form.idNumber}
                onChange={e =>
                  dispatch(
                    setFormMyProfileEmployee("idNumber", {
                      ...employeeState.staff.form.idNumber,
                      [e.name]: e.value
                    })
                  )
                }
                onChangeFile={e =>
                  e.target.files![0] &&
                  dispatch(
                    setFormMyProfileEmployee("idNumber", {
                      ...employeeState.staff.form.idNumber,
                      file: e.target.files![0]
                    })
                  )
                }
                linkImage={
                  (employeeState.staff.data.idNumber &&
                    employeeState.staff.data.idNumber.file &&
                    employeeState.staff.data.idNumber.file.url) ||
                  ""
                }
                valueImageLocal={
                  employeeState.staff.form.idNumber.file.name
                    ? employeeState.staff.form.idNumber.file
                    : ""
                }
                onRemoveImage={() =>
                  dispatch(removeFormMyProfileEmployee("idNumber"))
                }
              />
            </div>
            <div style={{ marginBottom: 26.5 }}>
              <AttachmentInput
                inputPassport
                value={
                  employeeState.staff.form.passport.file.name ||
                  (employeeState.staff.data.passport &&
                    employeeState.staff.data.passport.file &&
                    employeeState.staff.data.passport.file.title)
                }
                valueInput={employeeState.staff.form.passport}
                onChange={e =>
                  dispatch(
                    setFormMyProfileEmployee("passport", {
                      ...employeeState.staff.form.passport,
                      [e.name !== "number" ? "expired_at" : e.name]:
                        e.name !== "number"
                          ? {
                              ...employeeState.staff.form.passport.expired_at,
                              [e.name]: e.value
                            }
                          : e.value
                    })
                  )
                }
                onChangeFile={e =>
                  e.target.files![0] &&
                  dispatch(
                    setFormMyProfileEmployee("passport", {
                      ...employeeState.staff.form.passport,
                      file: e.target.files![0]
                    })
                  )
                }
                linkImage={
                  (employeeState.staff.data.passport &&
                    employeeState.staff.data.passport.file &&
                    employeeState.staff.data.passport.file.url) ||
                  ""
                }
                valueImageLocal={
                  employeeState.staff.form.passport.file.name
                    ? employeeState.staff.form.passport.file
                    : ""
                }
                onRemoveImage={() =>
                  dispatch(removeFormMyProfileEmployee("passport"))
                }
              />
            </div>
            <div style={{ marginBottom: 40.5 }}>
              <AttachmentInput
                label={t("personnel.form.taxId")}
                inputPassport={false}
                value={
                  employeeState.staff.form.taxNumber.file.name ||
                  (employeeState.staff.data.taxNumber &&
                    employeeState.staff.data.taxNumber.file &&
                    employeeState.staff.data.taxNumber.file.title)
                }
                valueInput={employeeState.staff.form.taxNumber}
                onChange={e =>
                  dispatch(
                    setFormMyProfileEmployee("taxNumber", {
                      ...employeeState.staff.form.taxNumber,
                      [e.name]: e.value
                    })
                  )
                }
                onChangeFile={e =>
                  e.target.files![0] &&
                  dispatch(
                    setFormMyProfileEmployee("taxNumber", {
                      ...employeeState.staff.form.taxNumber,
                      file: e.target.files![0]
                    })
                  )
                }
                linkImage={
                  (employeeState.staff.data.taxNumber &&
                    employeeState.staff.data.taxNumber.file &&
                    employeeState.staff.data.taxNumber.file.url) ||
                  ""
                }
                valueImageLocal={
                  employeeState.staff.form.taxNumber.file.name
                    ? employeeState.staff.form.taxNumber.file
                    : ""
                }
                onRemoveImage={() =>
                  dispatch(removeFormMyProfileEmployee("taxNumber"))
                }
              />
            </div>
            <div>
              <Text style={{ fontSize: 14, marginBottom: 20 }}>
                <>
                  {t("personnel.form.signature")}
                  {/* <span className="profile-superscrip"> * </span> */}
                </>
              </Text>
              {employeeState.staff.data.signature ? (
                <div
                  onClick={() =>
                    openNewTab(
                      employeeState.staff.data.changedSignature
                        ? URL.createObjectURL(
                            employeeState.staff.data.signature
                          )
                        : employeeState.staff.data.signature.url
                    )
                  }
                  style={{ cursor: "pointer" }}
                >
                  <FileUpload
                    name={employeeState.staff.data.signature.name}
                    canDelete={false}
                    type="image"
                    linkImage={
                      employeeState.staff.data.changedSignature &&
                      (employeeState.staff.data.signature.type === "png" ||
                        employeeState.staff.data.signature.type === "jpg" ||
                        employeeState.staff.data.signature.type === "jpeg" ||
                        employeeState.staff.data.signature.type.includes(
                          "image"
                        ))
                        ? URL.createObjectURL(
                            employeeState.staff.data.signature
                          )
                        : employeeState.staff.data.signature.url
                    }
                    deleteFile={e => {
                      e.stopPropagation();
                      dispatch(
                        setStaffEmployeeProfile({
                          formName: "signature",
                          value: null
                        })
                      );
                    }}
                  />
                </div>
              ) : null}
              <Upload
                onlyImage
                textBtn={t("btn.attachmentButton")}
                tabIndex={0}
                className="component-upload components-employee-container-attachment-input-button global-shadow-card"
                onChange={event => {
                  dispatch(
                    setStaffEmployeeProfile({
                      formName: "signature",
                      value: event.target.files[0]
                    })
                  );
                }}
              />
            </div>

            <div style={{ marginLeft: 10, marginBottom: 10, marginTop: 20 }}>
              <Text style={{ fontSize: 14 }}>
                {t("personnel.form.bankAccounts")}
              </Text>
            </div>
            {employeeState.staff.data.accounts &&
              employeeState.staff.data.accounts.map(
                (account: any, index: number) => (
                  <div className="margin-bottom-16" key={index}>
                    <Card
                      name="Bank"
                      hapus
                      primary={account.primary}
                      dataAccount={account}
                      isAccount
                      onDeleteAccount={() =>
                        dispatch(deleteAccountEmployee(account.account.id))
                      }
                      onPrimaryAccount={() =>
                        dispatch(setPrimaryAccountEmployee(account.account.id))
                      }
                      isLoading={employeeState.staff.isLoadingAccount}
                    />
                  </div>
                )
              )}
            {employeeState.staff.form.accounts.map((acc, idx) => (
              <div className="margin-bottom-16" key={idx}>
                <CardForm
                  index={idx}
                  hapus
                  primary={false}
                  hapusAccount={() => _handleModalDeleteAccount(idx)}
                  isAccount
                  name="Bank"
                  dataAccount={acc}
                  onChange={e => dispatch(setFormAccountsEmployee(e, idx))}
                  isLoading={isLoadingDeleteAccount}
                />
              </div>
            ))}
            <div>
              <Button onPress={() => dispatch(addAccountsEmployee())}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img alt="tambah" src={ICONS.btnAdd} />
                  <div style={{ paddingTop: 15 }}>
                    <Text className="dashboard-company-button-add">
                      {t("personnel.form.addAccount")}
                    </Text>
                  </div>
                </div>
              </Button>
            </div>
            <div
              style={{
                position: "sticky",
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 50,
                margin: "0 auto"
              }}
            />
          </div>
        </div>

        <input type="submit" hidden />
      </div>

      <Modal
        warning
        onPressSubmit={() => _handleRemoveAdress(indexAddress)}
        titleModal="DELETE ITEM"
        isOpen={modalOpen}
        onPressCancel={() => setModalOpen(false)}
      />

      <Modal
        warning
        onPressSubmit={() => _handleRemoveAccount(indexAddress)}
        titleModal="DELETE ITEM"
        isOpen={modalAccountOpen}
        onPressCancel={() => setModalAccountOpen(false)}
      />

      <ModalPassword
        isOpen={modalChangePass}
        setModal={e => setModalChangePass(e)}
      />
    </Container>
  );
};

Company.defaultProps = {
  isMe: true,
  idForm: "",
  isLoadListRole: false
};

export default memo(Company);
