import React, { memo } from "react";
import ReactTooltip from "react-tooltip";

import "./styles.scss";

interface Props {
  value: string | number;
  message?: string;
}

const BubbleNotif = ({ value, message }: Props) => (
  <div data-tip={message} className="container-bubble">
    <p>{value}</p>
    <ReactTooltip place="bottom" className="tooltip-task" />
  </div>
);

BubbleNotif.defaultProps = {
  message: ""
};

export default memo(BubbleNotif);
