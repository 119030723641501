import React, {
  BaseSyntheticEvent,
  CSSProperties,
  ReactNode,
  memo
} from "react";

import "./styles.scss";

interface Props {
  children?: ReactNode;
  text: string;
  disabled?: boolean;
  onClick?: (e: BaseSyntheticEvent) => void;
  className?: string;
  style?: CSSProperties;
  tabIndex?: number;
  type?: string;
}

const Button = ({
  text,
  disabled,
  onClick,
  className,
  style,
  tabIndex,
  type
}: Props) => {
  return (
    <input
      onClick={onClick}
      className={`button-container ${className}`}
      tabIndex={tabIndex}
      style={style}
      disabled={disabled}
      type={type}
      value={text}
    />
  );
};

Button.defaultProps = {
  disabled: false,
  type: "button"
};

export default memo(Button);
