/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useForm } from "react-hook-form";
import { Slide, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Switch from "react-switch";
import { IMAGES } from "../../../../../configs";
import {
  Button,
  Modal,
  ModalAnnouncementUserPicker,
  ModalSubmitForm
} from "../../../../../components";
import { Reducers } from "../../../../../redux/types";
import {
  getAnnouncementList,
  getAnnouncementParticipants,
  getEmployee,
  postAnnouncement,
  resetAnnouncementParticipants,
  setModalAnnouncement,
  setModalAnnouncementParticipants
} from "../../../../../redux/actions";

import "./styles.scss";

const ModalNewAnnouncement = () => {
  const {
    register,
    handleSubmit,
    errors,
    reset,
    watch,
    setValue,
    getValues
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");

  const initialBody = {
    sendToAll: true,
    saveAsDraft: false
  };

  const initialSubmitForm = {
    form: null,
    message: ""
  };

  const [body, setBody] = useState(initialBody);
  const [submitForm, setSubmitForm] = useState(initialSubmitForm);
  const [searchUser, setSearchUser] = useState("");
  const [modalSubmitForm, setModalSubmitForm] = useState(false);
  const [dataConfirmSubmit, setDataConfirmSubmit] = useState([]);

  const { setModal, assignLoading, companyState } = useSelector(
    (state: Reducers) => ({
      setModal: state.company.setModalNewAnnouncement,
      assignLoading: state.company.isLoadingPostAnnouncement,
      companyState: state.company
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getEmployee("", "", "", 1, false, "", false, false, false));
  }, [dispatch]);

  useEffect(() => {
    setSearchUser("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyState.setModalAnnouncementParticipants]);

  const _setAnnouncementParticipantList = useCallback(() => {
    dispatch(setModalAnnouncementParticipants(true));
    dispatch(getAnnouncementParticipants());
  }, [dispatch]);

  const _getIdParticipant = () => {
    const result = [] as any;
    if (companyState.saveAnnouncementParticipantList.length > 0) {
      companyState.saveAnnouncementParticipantList.map(el =>
        result.push(el.id)
      );
    }
    return result.join(",");
  };

  const _onConfirmSubmitForm = (data: any) => {
    const form = {
      ...data,
      ...body,
      participants: _getIdParticipant()
    };

    let tempData: any = [];
    Object.keys(form).map((key: any) => {
      if (form[key]) {
        tempData = [...tempData, { title: key, value: form[key] }];
      }
      return true;
    });

    let participants: any = [];
    companyState.saveAnnouncementParticipantList.map(e => {
      participants = [...participants, e.name];
      return true;
    });

    const result: any = [
      { title: t("company.form.subject"), value: tempData[2].value || "-" },
      {
        title: t("task.form.participants"),
        value: participants.length > 0 ? participants : t("all")
      },
      {
        title: t("task.form.startDate"),
        value: moment(tempData[0].value).format("DD MMM YYYY") || "-"
      },
      {
        title: t("task.detail.finishDate"),
        value: moment(tempData[1].value).format("DD MMM YYYY") || "-"
      },
      { title: t("company.tab.announcement"), value: tempData[3].value || "-" }
    ];

    if (result) {
      setDataConfirmSubmit(result);
      setModalSubmitForm(true);
    }
  };

  const _onSave = (data: any) => {
    const form = {
      ...data,
      ...body,
      participants: _getIdParticipant()
    };
    const message = "";

    setSubmitForm({ ...submitForm, form, message });
    dispatch(
      postAnnouncement(form, (e: { success: boolean; message: string }) => {
        if (e.success === true) {
          toast(`${e.message.toUpperCase()}`, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-announcement-toast",
            closeButton: false,
            draggable: false
          });
          dispatch(resetAnnouncementParticipants());
          dispatch(setModalAnnouncement(false));
          dispatch(getAnnouncementList());
          setModalSubmitForm(false);
          reset();
          setBody(initialBody);
        } else if (e.success === false) {
          toast(`${e.message}`, {
            position: "top-center",
            autoClose: 3500,
            hideProgressBar: true,
            transition: Slide,
            className: "assign-announcement-toast assign-red",
            closeButton: false,
            draggable: false
          });
        }
      })
    );
  };

  const _onCancel = () => {
    reset();
    setBody(initialBody);
    dispatch(setModalAnnouncement(false));
    dispatch(resetAnnouncementParticipants());
  };

  return (
    <>
      <Modal
        loading={assignLoading}
        titleModal={t("company.form.newAnnouncement")}
        isOpen={setModal}
        onPressCancel={_onCancel}
        onPressSubmit={
          body.saveAsDraft
            ? handleSubmit(_onSave)
            : handleSubmit(_onConfirmSubmitForm)
        }
        btnSubmitText={!body.saveAsDraft ? t("btn.create") : t("btn.save")}
        btnCancelText={t("btn.cancel")}
      >
        <form className="margin-top-12" onSubmit={handleSubmit(_onSave)}>
          <div style={{ width: 494 }}>
            <span className="component-modal-assign-announcement-label">
              {t("company.form.announceTo")}
            </span>
            <div className="announcement-wrap-switch">
              <span
                className={`component-modal-assign-announcement-label switch ${!body.sendToAll &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("all")}
              </span>
              <Switch
                onChange={() => {
                  setBody({ ...body, sendToAll: !body.sendToAll });
                  dispatch(resetAnnouncementParticipants());
                }}
                checked={!body.sendToAll}
                onColor="#ff7600"
                offColor="#ff7600"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() =>
                  setBody({ ...body, sendToAll: !body.sendToAll })
                }
              />
              <span
                className={`component-modal-assign-announcement-label switch ${body.sendToAll &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("company.form.selectedEmployee")}
              </span>
            </div>
            {!body.sendToAll && (
              <div className="modal-assign-person-container margin-bottom-12">
                {companyState.saveAnnouncementParticipantList &&
                companyState.saveAnnouncementParticipantList.length > 0 ? (
                  companyState.saveAnnouncementParticipantList.map(
                    (el, index) => (
                      <div
                        className="modal-assign-person margin-right-8 margin-top-8"
                        key={index}
                      >
                        <img
                          src={IMAGES.avatar}
                          alt="n/a"
                          className="modal-assign-person-photo"
                        />
                        <div>
                          {`(${el.position && el.position.title}) `}
                          <span>{`${el.name}`}</span>
                        </div>
                      </div>
                    )
                  )
                ) : (
                  <></>
                )}
                <Button
                  onPress={() => _setAnnouncementParticipantList()}
                  className="margin-top-8"
                >
                  <div className="component-modal-assign-announcement-btn-add-person">
                    <img
                      className="is-focus"
                      style={{ height: 50, width: 50 }}
                      src={IMAGES.floatingPlusButton}
                      alt="create"
                      tabIndex={0}
                    />
                    <span className="component-modal-assign-announcement-btn-add-person-text">
                      {t("task.form.addPerson")}
                    </span>
                  </div>
                </Button>
              </div>
            )}
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              className="margin-top-20"
            >
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-announcement-label">
                  {t("personnel.form.startDate")}
                </span>
                <input
                  placeholder="YYYY/MM/DD"
                  type="date"
                  style={{ color: "#666666" }}
                  name="started_at"
                  ref={register({
                    required: sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.startDate")
                    )
                  })}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  min={moment().format("YYYY-MM-DD")}
                  max={watch("end_at")}
                  onChange={e => {
                    if (getValues("end_at") === "") {
                      setValue(
                        "end_at",
                        moment(e.target.value)
                          .add(1, "d")
                          .format("YYYY-MM-DD")
                      );
                    }
                  }}
                />
                <div className="component-modal-line" />
                <ErrorMessage
                  errors={errors}
                  name="started_at"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
              <div style={{ width: "40%" }}>
                <span className="component-modal-assign-announcement-label">
                  {t("company.form.endDate")}
                </span>
                <div>
                  <input
                    placeholder="YYYY/MM/DD"
                    type="date"
                    style={{ color: "#666666" }}
                    name="end_at"
                    ref={register({
                      required: sprintf(
                        t("personnel.form.isRequiredMsg"),
                        t("company.form.endDate")
                      )
                    })}
                    min={
                      watch("started_at") || moment().format("YYYY-MM-DD hh:mm")
                    }
                  />
                  <div className="component-modal-line" />
                  <ErrorMessage
                    errors={errors}
                    name="end_at"
                    as="p"
                    style={{ color: "red" }}
                  />
                </div>
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label">
                {t("company.form.subject")}
              </span>
              <input
                name="subject"
                type="text"
                className="component-modal-input margin-top-12"
                placeholder={t("company.form.subject")}
                ref={register({
                  required: sprintf(
                    t("personnel.form.isRequiredMsg"),
                    t("company.form.subject")
                  )
                })}
                maxLength={200}
              />
              <ErrorMessage
                errors={errors}
                name="subject"
                as="p"
                style={{ color: "red" }}
              />
            </div>

            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label">
                {t("company.tab.announcement")}
              </span>
              <div className="global-shadow-card component-modal-assign-announcement-card-desc margin-top-12">
                <textarea
                  name="description"
                  className="component-modal-assign-announcement-text-area"
                  ref={register({
                    required: sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("company.tab.announcement")
                    )
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  className="alert"
                  name="description"
                  as="p"
                  style={{ color: "red" }}
                />
              </div>
            </div>
            <div className="margin-top-20">
              <span className="component-modal-assign-announcement-label ">
                {t("company.form.saveDraft")}
              </span>
            </div>
            <div className="announcement-wrap-switch">
              <span
                className={`component-modal-assign-announcement-label switch ${body.saveAsDraft &&
                  "opacity"}`}
                style={{ marginRight: 5 }}
              >
                {t("no")}
              </span>
              <Switch
                onChange={() =>
                  setBody({ ...body, saveAsDraft: !body.saveAsDraft })
                }
                checked={body.saveAsDraft}
                onColor="#ff7600"
                offColor="#888888"
                onHandleColor="#fffff7"
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={48}
                tabIndex={0}
                onKeyPress={() =>
                  setBody({ ...body, saveAsDraft: !body.saveAsDraft })
                }
              />
              <span
                className={`component-modal-assign-announcement-label switch ${!body.saveAsDraft &&
                  "opacity"}`}
                style={{ marginLeft: 5 }}
              >
                {t("yes")}
              </span>
            </div>
          </div>
        </form>
      </Modal>
      <ModalAnnouncementUserPicker
        onChange={e => {
          dispatch(getEmployee("", "", "", 1, false, "", false, false, false));
          setSearchUser(e);
        }}
        value={searchUser}
      />
      <ModalSubmitForm
        isLoading={companyState.isLoadingPostAnnouncement}
        open={modalSubmitForm}
        data={dataConfirmSubmit}
        onPress={handleSubmit(_onSave)}
        onCancel={() => setModalSubmitForm(false)}
      />
    </>
  );
};

export default memo(ModalNewAnnouncement);
