import React from "react";

import WidgetUser from "./components/WidgetUser";
import { documentTitle } from "../../../../utils";

const UserPage = () => {
  documentTitle("Report - Budget Company");

  return (
    <div>
      <WidgetUser />
    </div>
  );
};

export default UserPage;
