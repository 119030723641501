import React, { memo } from "react";

//  import styles from "./styles";

interface Props {
  height: number;
  width: number;
}

const Component = ({ width, height }: Props) => (
  <div style={{ height, width }} />
);

Component.defaultProps = {
  height: 0,
  width: 0
};

export default memo(Component);
