import { Slide, toast } from "react-toastify";
import { Dispatch } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";

export const GET_SETTING_LIST_PENDING = "GET_SETTING_LIST_PENDING";
export const GET_SETTING_LIST_SUCCESS = "GET_SETTING_LIST_SUCCESS";
export const GET_SETTING_LIST_ERROR = "GET_SETTING_LIST_ERROR";

export const GET_SETTING_DETAIL_PENDING = "GET_SETTING_DETAIL_PENDING";
export const GET_SETTING_DETAIL_SUCCESS = "GET_SETTING_DETAIL_SUCCESS";
export const GET_SETTING_DETAIL_ERROR = "GET_SETTING_DETAIL_ERROR";

export const GET_SETTING_LOOKUP_PENDING = "GET_SETTING_LOOKUP_PENDING";
export const GET_SETTING_LOOKUP_SUCCESS = "GET_SETTING_LOOKUP_SUCCESS";
export const GET_SETTING_LOOKUP_ERROR = "GET_SETTING_LOOKUP_ERROR";

export const POST_SETTING_PENDING = "POST_SETTING_PENDING";
export const POST_SETTING_SUCCESS = "POST_SETTING_SUCCESS";
export const POST_SETTING_ERROR = "POST_SETTING_ERROR";

export const POST_RESET_SETTING_PENDING = "POST_RESET_SETTING_PENDING";
export const POST_RESET_SETTING_SUCCESS = "POST_RESET_SETTING_SUCCESS";
export const POST_RESET_SETTING_ERROR = "POST_RESET_SETTING_ERROR";

export const SET_SETTING = "SET_SETTING";
export const RESET_VALUE_SETTING = "RESET_VALUE_SETTING";

export const getSettingList = (page: number, q?: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_SETTING_LIST_PENDING });

    const res = await API.getSettingList(page, q || "");
    dispatch({
      type: GET_SETTING_LIST_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SETTING_LIST_ERROR });
  }
};

export const getSettingDetail = (id: number, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_SETTING_DETAIL_PENDING });

    const res = await API.getSettingDetail(id);
    dispatch({
      type: GET_SETTING_DETAIL_SUCCESS,
      payload: { data: res.data.data }
    });
    cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SETTING_DETAIL_ERROR });
  }
};

export const getSettingLookup = (lookup: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_SETTING_LOOKUP_PENDING });

    const res = await API.getSettingLookup(lookup);
    dispatch({
      type: GET_SETTING_LOOKUP_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SETTING_LOOKUP_ERROR });
  }
};

export const setSetting = (attributes: string, value: any) => async (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_SETTING,
    payload: { data: { attributes, value } }
  });
};

export const resetValueSetting = () => async (dispatch: Dispatch) => {
  dispatch({
    type: RESET_VALUE_SETTING
  });
};

export const postSetting = (id: string, cb: () => void, value: any) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_SETTING_PENDING });
    const formData = new FormData();
    formData.append("value", value);

    const res = await API.postSetting(formData, id);
    dispatch({
      type: POST_SETTING_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Setting has been changed", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    if (err.response.data.message) {
      toast(err.response.data.message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }
    dispatch({ type: POST_SETTING_ERROR });
  }
};

export const postResetSetting = (id: string, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_RESET_SETTING_PENDING });

    await API.postResetSetting(id);
    dispatch({
      type: POST_RESET_SETTING_SUCCESS
    });
    toast("Setting has been reset", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    cb();
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    if (err.response.data.message) {
      toast(err.response.data.message, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_RESET_SETTING_ERROR });
  }
};
