/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import "./styles.scss";
import "../../../../../../sass/styles.scss";
import { useTranslation } from "react-i18next";
import { Text } from "../../../../../../components";
import { maskedMoney } from "../../../../../../utils";

// type redux
import { setActiveSidebarData } from "../../../../../../redux/actions";
import { Reducers } from "../../../../../../redux/types";

const SidebarTop = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const homeState = useSelector((state: Reducers) => state.home);

  const _categoriesActive = () => {
    const indexCategory = homeState.listWidget.data[
      homeState.listWidget.activeWidget!
    ].detail.categories.findIndex(
      (find: any) =>
        Number(find.value) ===
        Number(
          homeState.listWidget.data[homeState.listWidget.activeWidget!]
            .categories_active
        )
    );
    return indexCategory;
  };

  const _checkFields = () =>
    homeState.listWidget.data[homeState.listWidget.activeWidget!] &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail.fields;

  const _checkHeader = () =>
    homeState.listWidget.data[homeState.listWidget.activeWidget!] &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
      .categories[_categoriesActive()].items &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
      .categories[_categoriesActive()].items[
      homeState.listWidget.activeSubWidget!
    ].fields;

  const checkItems =
    homeState.listWidget.data.length > 0 &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail &&
    homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
      .categories[_categoriesActive()].items.length === 0;

  const _total = () => {
    if (homeState.listWidget.activeSubWidget !== null) {
      if (
        homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "total-projects"
      ) {
        const data =
          homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
            .categories[_categoriesActive()].items[
            homeState.listWidget.activeSubWidget!
          ].values;

        // data.reduce((prev: any, next: any) => prev["2"] + prev["2"])

        let res = 0;
        if (data.length > 0) {
          data.map((temp, indexData) => {
            res += data[indexData]["2"];
            return true;
          });
        }

        return res;
      }
      return (
        homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
          .categories[_categoriesActive()].items.length > 0 &&
        homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
          .categories[_categoriesActive()].items[
          homeState.listWidget.activeSubWidget!
        ].value
      );
    }
    if (
      homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "total-trips" ||
      homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "total-taks" ||
      homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "total-expenses" ||
      homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "category-expenses" ||
      homeState.listWidget.data[homeState.listWidget.activeWidget!].type ===
        "total-budget"
    ) {
      return homeState.listWidget.data[homeState.listWidget.activeWidget!]
        .categories_active;
    }

    return null;
  };
  const _renderSubRowTable = () => {
    if (homeState.listWidget.activeSubWidget !== null) {
      return (
        homeState.listWidget.data &&
        homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
          .categories[_categoriesActive()].items.length > 0 &&
        homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
          .categories[_categoriesActive()].items[
          homeState.listWidget.activeSubWidget!
        ].values.length > 0 && (
          <tbody>
            {homeState.listWidget.data[
              homeState.listWidget.activeWidget!
            ].detail.categories[_categoriesActive()].items[
              homeState.listWidget.activeSubWidget!
            ].values.map((item: any, index: number) => {
              return (
                <tr
                  key={index}
                  onClick={() => dispatch(setActiveSidebarData(index))}
                  className="dashboard-home-sidebar-top-button-row"
                >
                  {Object.values(item).map((itemSub: any, indexSub: number) =>
                    Object.values(item).length - 1 !== indexSub ? (
                      <td
                        key={indexSub}
                        title={itemSub}
                        align={!isNaN(Number(itemSub)) ? "right" : "left"}
                      >
                        <span
                          style={{ color: "white" }}
                          data-tip={String(Object.values(item))}
                          data-for={`tableData${indexSub}`}
                        >
                          {!isNaN(Number(itemSub))
                            ? maskedMoney(Number(itemSub))
                            : itemSub}
                        </span>
                        <ReactTooltip
                          id={`tableData${indexSub}`}
                          getContent={dataTip => (
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {homeState.listWidget.data[
                                homeState.listWidget.activeWidget!
                              ].detail.fields.map(
                                (itemHeader: string, indexHeader: number) => (
                                  <div
                                    key={indexHeader}
                                    style={{
                                      marginRight: 20,
                                      textAlign: "center"
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontWeight: "bold"
                                      }}
                                    >
                                      {itemHeader}
                                    </span>
                                    {dataTip ? (
                                      <div style={{ alignSelf: "flex-start" }}>
                                        {!isNaN(
                                          Number(
                                            dataTip.split(",")[indexHeader]
                                          )
                                        )
                                          ? maskedMoney(
                                              dataTip.split(",")[indexHeader]
                                            )
                                          : dataTip.split(",")[indexHeader]}
                                      </div>
                                    ) : null}
                                  </div>
                                )
                              )}
                              <div style={{ marginRight: 20 }}>
                                <span style={{ fontWeight: "bold" }}>
                                  {item.pop_up.start_date !== undefined
                                    ? t("task.form.startDate")
                                    : null}
                                </span>
                                <div>
                                  {item.pop_up.start_date !== undefined
                                    ? item.pop_up.start_date
                                    : null}
                                </div>
                              </div>
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  {item.pop_up.end_date !== undefined
                                    ? t("company.form.endDate")
                                    : null}
                                </span>
                                <div>
                                  {item.pop_up.end_date !== undefined
                                    ? item.pop_up.end_date
                                    : null}
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </td>
                    ) : null
                  )}
                </tr>
              );
            })}
          </tbody>
        )
      );
    }
    return (
      <tbody>
        {homeState.listWidget.data[
          homeState.listWidget.activeWidget!
        ].detail.categories[_categoriesActive()].values.map(
          (itemRow: any, indexItemRow: number) => (
            <tr key={indexItemRow}>
              {Object.values(itemRow).map((itemSub: any, indexSub: number) =>
                Object.values(itemRow).length - 1 !== indexSub ? (
                  <td
                    key={indexSub}
                    title={itemSub}
                    align={!isNaN(Number(itemSub)) ? "right" : "left"}
                  >
                    <span
                      style={{ color: "white" }}
                      data-tip={String(Object.values(itemRow))}
                      data-for={`tableData${indexSub}`}
                    >
                      {!isNaN(Number(itemSub))
                        ? maskedMoney(Number(itemSub))
                        : itemSub}
                    </span>
                    <ReactTooltip
                      id={`tableData${indexSub}`}
                      getContent={dataTip => (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          {homeState.listWidget.data[
                            homeState.listWidget.activeWidget!
                          ].detail.fields.map(
                            (itemHeader: string, indexHeader: number) => (
                              <div
                                key={indexHeader}
                                style={{
                                  marginRight: 20,
                                  textAlign: "left"
                                }}
                              >
                                <span
                                  style={{
                                    fontWeight: "bold"
                                  }}
                                >
                                  {itemHeader}
                                </span>
                                {dataTip ? (
                                  <div style={{ alignSelf: "flex-start" }}>
                                    {!isNaN(
                                      Number(dataTip.split(",")[indexHeader])
                                    )
                                      ? maskedMoney(
                                          dataTip.split(",")[indexHeader]
                                        )
                                      : dataTip.split(",")[indexHeader]}
                                  </div>
                                ) : null}
                              </div>
                            )
                          )}
                          <div style={{ marginRight: 20 }}>
                            <span style={{ fontWeight: "bold" }}>
                              {itemRow.pop_up.start_date !== undefined
                                ? "Start Date"
                                : null}
                            </span>
                            <div>
                              {itemRow.pop_up.start_date !== undefined
                                ? itemRow.pop_up.start_date
                                : null}
                            </div>
                          </div>
                          <div>
                            <span style={{ fontWeight: "bold" }}>
                              {itemRow.pop_up.end_date !== undefined
                                ? "End Date"
                                : null}
                            </span>
                            <div>
                              {itemRow.pop_up.end_date !== undefined
                                ? itemRow.pop_up.end_date
                                : null}
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </td>
                ) : null
              )}
            </tr>
          )
        )}
      </tbody>
    );
  };

  const _renderRowTable = () => {
    return checkItems ? (
      <tbody>
        {homeState.listWidget.data[
          homeState.listWidget.activeWidget!
        ].detail.categories[_categoriesActive()].values.map(
          (item: any, index: number) => (
            <tr
              key={index}
              onClick={() => dispatch(setActiveSidebarData(index))}
              className="dashboard-home-sidebar-top-button-row"
            >
              {item.map((itemSub: string | number, indexSub: number) => (
                <td
                  key={indexSub}
                  className={`dashboard-home-sidebar-top-text-table ${indexSub ===
                    1 &&
                    "dashboard-home-sidebar-top-text-table-center"} ${indexSub ===
                    2 &&
                    "dashboard-home-sidebar-top-text-table-right"} ${homeState
                    .listWidget.activeDataChart === index &&
                    "dashboard-home-sidebar-top-text-table-active"}`}
                >
                  {typeof itemSub === "number" ? maskedMoney(itemSub) : itemSub}
                </td>
              ))}
            </tr>
          )
        )}
      </tbody>
    ) : (
      _renderSubRowTable()
    );
  };

  const _handleEmptyTable = () => {
    if (
      homeState.listWidget.activeSubWidget === 0 &&
      homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
        .categories[_categoriesActive()].values.length === 0
    ) {
      return (
        <div className="dashboard-home-sidebar-top-wrap-no-select">
          <Text className="dashboard-home-sidebar-top-side-title dashboard-home-sidebar-top-text-no-select">
            No data
          </Text>
        </div>
      );
    }
    if (
      homeState.listWidget.activeSubWidget !== null &&
      homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
        .categories[_categoriesActive()].items.length > 0 &&
      homeState.listWidget.data[homeState.listWidget.activeWidget!].detail
        .categories[_categoriesActive()].items[
        homeState.listWidget.activeSubWidget!
      ].values.length === 0
    ) {
      return (
        <div className="dashboard-home-sidebar-top-wrap-no-select">
          <Text className="dashboard-home-sidebar-top-side-title dashboard-home-sidebar-top-text-no-select">
            No data
          </Text>
        </div>
      );
    }

    return null;
  };

  const _handleEmptyCard = () => {
    return homeState.listWidget.activeWidget !== null ? (
      <Text className="dashboard-home-sidebar-top-side-title dashboard-home-sidebar-top-text-no-select">
        No data
      </Text>
    ) : (
      <Text className="dashboard-home-sidebar-top-side-title dashboard-home-sidebar-top-text-no-select">
        No Widget Selected
      </Text>
    );
  };

  return (
    <div
      className={`dashboard-home-sidebar-top-card-side ${(homeState.listWidget
        .activeWidget === null ||
        !_checkFields()) &&
        "dashboard-home-sidebar-top-wrap-no-select"}`}
    >
      {homeState.listWidget.activeWidget !== null && _checkFields() ? (
        <div className="dashboard-home-sidebar-top-side-content">
          <div className="dashboard-home-sidebar-top-side-wrap-title">
            <Text className="dashboard-home-sidebar-top-side-title">
              {
                homeState.listWidget.data[homeState.listWidget.activeWidget!]
                  .detail.categories[_categoriesActive()].title
              }
            </Text>
          </div>

          <div className="dashboard-home-sidebar-top-side-wrap-table global-scroll-bar">
            <table>
              <thead>
                {homeState.listWidget.data[homeState.listWidget.activeWidget!]
                  .detail.categories[_categoriesActive()].items[
                  homeState.listWidget.activeSubWidget!
                ] && _checkHeader() ? (
                  <tr>
                    {homeState.listWidget.data[
                      homeState.listWidget.activeWidget!
                    ].detail.categories[_categoriesActive()].items[
                      homeState.listWidget.activeSubWidget!
                    ].fields.map((item: string, index: number) => (
                      <th
                        style={{ width: "20%", paddingBottom: 13 }}
                        key={index}
                        align={!isNaN(Number(item)) ? "center" : "center"}
                      >
                        <Text className="dashboard-home-sidebar-top-text-table dashboard-home-sidebar-top-text-table-header">
                          {item}
                        </Text>
                      </th>
                    ))}
                  </tr>
                ) : (
                  <tr>
                    {homeState.listWidget.data[
                      homeState.listWidget.activeWidget!
                    ].detail.fields.map((item: string, index: number) => (
                      <th
                        style={{ width: "20%", paddingBottom: 13 }}
                        key={index}
                        align={!isNaN(Number(item)) ? "center" : "center"}
                      >
                        <Text className="dashboard-home-sidebar-top-text-table dashboard-home-sidebar-top-text-table-header">
                          {item}
                        </Text>
                      </th>
                    ))}
                  </tr>
                )}
              </thead>
              {homeState.listWidget.data[homeState.listWidget.activeWidget!]
                .detail.categories[_categoriesActive()].values.length > 0 &&
                _renderRowTable()}
            </table>

            {_handleEmptyTable()}
          </div>

          <div className="dashboard-home-sidebar-top-wrap-total">
            <table style={{ width: "100%" }}>
              <tr>
                <td>
                  <Text className="dashboard-home-sidebar-top-text-total">
                    TOTAL
                  </Text>
                </td>
                {/* <td>
                  <Text className="dashboard-home-sidebar-top-text-table-center dashboard-home-sidebar-top-text-total">
                    {maskedMoney(_subTotal())}
                  </Text>
                </td> */}
                <td>
                  <Text className="dashboard-home-sidebar-top-text-table-right dashboard-home-sidebar-top-text-total">
                    {maskedMoney(_total())}
                  </Text>
                </td>
              </tr>
            </table>
          </div>
        </div>
      ) : (
        _handleEmptyCard()
      )}
    </div>
  );
};

export default memo(SidebarTop);
