interface ObjectCookie {
  "auth._token.djurnee.redirect": string;
}

const useCookie: () => ObjectCookie = () => {
  return document.cookie.split("; ").reduce((prev: any, current: any) => {
    const [name, value] = current.split("=");
    prev[name] = value;
    return prev;
  }, {});
};

export default useCookie;
