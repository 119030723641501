const images = {
  person: require("../assets/images/person.png"),
  avatar: require("../assets/images/avatar.jpeg"),
  sidemenuDecor02: require("../assets/images/sidemenu-decor-02.png"),
  logoWhite: require("../assets/images/logo-white.png"),
  sidemenuDescor01: require("../assets/images/sidemenu-decor-01.png"),
  btnDel: require("../assets/images/close.png"),
  upArrow: require("../assets/images/up-arrow.png"),
  downArrow: require("../assets/images/down-arrow.png"),
  chevron: require("../assets/images/chevron.png"),
  chevronUp: require("../assets/images/chevron-up.png"),
  pencil: require("../assets/images/pencil.png"),
  deleteRound: require("../assets/images/deleteRound.png"),
  addStaff: require("../assets/images/addStaff.png"),
  warn: require("../assets/images/warn.png"),
  floatingPlusButton: require("../assets/images/floatingPlusButton.png"),
  searchIcon: require("../assets/svgs/search-icon.svg"),
  crossRed: require("../assets/svgs/cross-red.svg"),
  upcomingWhite: require("../assets/svgs/upcoming-white.svg"),
  ongoingWhite: require("../assets/svgs/ongoing-white.svg"),
  historyWhite: require("../assets/svgs/history-white.svg"),
  assignWhite: require("../assets/svgs/assign-white.svg"),
  search: require("../assets/images/search.png"),
  addButton: require("../assets/images/add-button.png"),
  substractButton: require("../assets/images/grey-min.png"),
  deleteFile: require("../assets/images/deleteFile.png"),
  asignTask: require("../assets/images/assignTask.png"),
  plus: require("../assets/images/plus.png"),
  companyDefault: require("../assets/images/company-default.png"),
  assignTaskGrey: require("../assets/images/assignGrey.png"),
  print: require("../assets/images/print.png"),
  document: require("../assets/images/document.png"),
  hazardSign: require("../assets/images/hazard-sign.png"),
  buttonGrey: require("../assets/images/floatingButtonGrey@3x.png"),
  eye: require("../assets/images/eye.png"),
  eyeOff: require("../assets/images/eyeOff.png"),
  mixed: require("../assets/images/mixed.png"),
  picAttach: require("../assets/images/picAttch.png"),
  document2: require("../assets/images/document2.png"),
  cashInHand: require("../assets/images/cash-in-hand.png")
};

export default images;
