import React from "react";
import "./styles.scss";

interface Props {
  document?: any;
  value?: any;
  image?: any;
  onChange?: (value: any) => void;
  extionsion?: any;
  multiple: boolean;
  name?: string;
  ref?: any;
  className?: string;
  tabIndex?: number;
  textBtn?: string;
  onlyImage?: boolean;
  disable?: boolean;
}

const upload = ({
  onChange,
  image,
  multiple,
  ref,
  name,
  className,
  tabIndex,
  textBtn,
  onlyImage,
  disable
}: Props) => {
  const _handleEnterUpload = () => {
    document.getElementById("upload")?.click();
  };
  return (
    <div
      style={{ width: "fit-content", height: 40, padding: 0, borderRadius: 40 }}
      className="is-focus"
      tabIndex={tabIndex}
      onKeyPress={_handleEnterUpload}
    >
      <div>
        {/* eslint-disable-next-line */}
        <label className={className||"custom-file-upload"}>
          <input
            id="upload"
            style={{ backgroundColor: "red" }}
            name={name}
            ref={ref}
            type="file"
            multiple={multiple}
            onChange={onChange}
            accept={`image/jpeg, image/png, image/jpg ${
              onlyImage
                ? ""
                : ",application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xlsx,.csv,.txt"
            }`}
            disabled={disable}
          />
          <div className="title-attc">{textBtn || "+ Attachment"}</div>
        </label>
      </div>
      <img src={image} alt="" style={{ width: "40%" }} />
    </div>
  );
};

upload.defaultProps = {
  multiple: false
};

export default upload;
