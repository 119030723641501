import React, { CSSProperties, ReactNode, memo } from "react";

import "./styles.scss";

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Card = ({ children, className, style }: Props) => (
  <div className={`policy-card-container shadow ${className}`} style={style}>
    {children}
  </div>
);

Card.defaultProps = {
  className: ""
};

export default memo(Card);
