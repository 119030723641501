import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

import "./styles.scss";

import { Button, Container, Modal } from "../../../../../components";
import { IMAGES } from "../../../../../configs";
import {
  deleteAnnouncement,
  getAnnouncementById,
  getAnnouncementList,
  resetAnnouncementParticipants,
  setModalAnnouncement
} from "../../../../../redux/actions";
import { Reducers } from "../../../../../redux/types";
import { Permission } from "../../../../../utils";

import { ModalEditAnnouncement } from "..";

const Table = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const permission = {
    self: Permission("announcement-self-view"),
    others: Permission("announcement-others-view"),
    edit: Permission("announcement-others-edit"),
    delete: Permission("announcement-others-delete")
  };

  // const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [uuid, setId] = useState("");
  const [warning, setWarning] = useState(false);
  const [message, setMessage] = useState("");
  const [firstTimeOnly, setFirstTimeOnly] = useState(true);

  const _statusColor = (status: number) => {
    let color = "";
    if (status === 1) {
      color = "color-y";
    } else if (status === 2) {
      color = "color-g";
    } else if (status === 3) {
      color = "color-b";
    }
    return color;
  };

  const statusDesc = (status: number) => {
    let label = "";
    if (status === 1) {
      label = t("status.draft");
    } else if (status === 2) {
      label = t("status.active");
    } else if (status === 3) {
      label = t("status.finished");
    } else {
      label = "NULL";
    }
    return label;
  };

  const announcementState = useSelector((state: Reducers) => state.company);

  const _openModalEdit = useCallback(
    (id: string) => {
      setModalEdit(true);
      dispatch(getAnnouncementById(id));
    },
    [dispatch]
  );

  useEffect(() => {
    if (firstTimeOnly) {
      dispatch(getAnnouncementList());
      setFirstTimeOnly(false);
    }
    if (
      !announcementState.isDeleteAnnouncement &&
      isDelete &&
      announcementState.deletePosition
    ) {
      setDelete(false);
      dispatch(getAnnouncementList());
      setDeleteModal(false);
    }
  }, [
    dispatch,
    announcementState.isDeleteAnnouncement,
    announcementState.deleteAnnouncement,
    isDelete,
    firstTimeOnly,
    announcementState.deletePosition
  ]);

  const _deleteAnnouncement = (id: string) => {
    if (!isDelete) {
      setDelete(true);
      dispatch(
        deleteAnnouncement(id, e => {
          setMessage(e);
          setWarning(true);
          setDeleteModal(false);
        })
      );
      setDeleteModal(false);
    }
  };

  return (
    <>
      <Container
        isLoading={announcementState.isLoadingListAnnouncement}
        style={{ marginTop: 70 }}
      >
        {announcementState.listAnnouncement.length > 0 ? (
          <table className="data-table">
            <thead>
              <tr>
                <th>{t("company.table.subject")}</th>
                <th colSpan={2}>{t("personnel.employeeDetail.tbl.date")}</th>
                <th>{t("company.table.announcement")}</th>
                <th>{t("company.table.assignTo")}</th>
                <th colSpan={2}>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {announcementState.listAnnouncement.map(
                (item: any, index: number) => (
                  <tr key={index}>
                    <td className="col-subject">
                      {permission.self || permission.others ? (
                        <Link
                          style={{ color: "#db3a26" }}
                          to={`/company/announcement/detail/${item.id}`}
                        >
                          <p>{item.title}</p>
                        </Link>
                      ) : (
                        <p>{item.title}</p>
                      )}
                    </td>
                    <td className="grey">
                      <div>{t("company.table.from")}</div>
                      <div>{t("company.table.to")}</div>
                    </td>
                    <td className="col-date">
                      <div>
                        {moment(item.started_at).format("ddd, DD MMM YYYY")}
                      </div>
                      <div>
                        {moment(item.finished_at).format("ddd, DD MMM YYYY")}
                      </div>
                    </td>
                    <td className="col-announcement">
                      <p>{item.description}</p>
                    </td>
                    {!item.users ? (
                      <td className="col-assigner">
                        <b>{t("all")}</b>
                      </td>
                    ) : (
                      <td className="col-assigner">
                        {item.users.map((userItem: any, i: number) => (
                          <div key={i}>
                            &bull;
                            {userItem.name}
                          </div>
                        ))}
                      </td>
                    )}
                    <td className="col-status">
                      <div className={`status ${_statusColor(item.status)}`}>
                        <div className="announcement-list-status-td">
                          <p className="bold">{statusDesc(item.status)}</p>
                        </div>
                        <div className="announcement-list-action actions">
                          {item &&
                          item.status === 1 &&
                          permission.edit &&
                          permission.delete ? (
                            <>
                              <Button
                                onPress={() => {
                                  _openModalEdit(item.id);
                                  setId(item.id);
                                }}
                              >
                                <img
                                  src={IMAGES.pencil}
                                  alt="n/a"
                                  style={{ height: 50, width: 50 }}
                                  className="pointer"
                                />
                              </Button>
                              <Button
                                onPress={() => {
                                  setDeleteModal(true);
                                  setId(item.id);
                                }}
                              >
                                <img
                                  src={IMAGES.deleteRound}
                                  alt="n/a"
                                  style={{ height: 50, width: 50 }}
                                  className="pointer"
                                />
                              </Button>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div className="announcement-empty-data">{t("emptyData")}</div>
        )}
      </Container>
      <Modal
        warning
        btnSubmitText={
          announcementState.isDeleteAnnouncement ? "LOADING" : t("btn.submit")
        }
        btnCancelText={t("btn.cancel")}
        titleModal={t("personnel.employeeDetail.deleteTitle")}
        isOpen={deleteModal}
        onPressCancel={() => setDeleteModal(false)}
        message={String(t("personnel.form.deleteMsg"))}
        onPressSubmit={() => _deleteAnnouncement(uuid)}
      />
      <Modal
        warning
        titleModal="WARNING"
        isOpen={warning}
        onPressCancel={() => setWarning(false)}
        message={message}
        mandatory
      />
      <ModalEditAnnouncement
        isOpen={modalEdit}
        onCancel={() => {
          setModalEdit(false);
          dispatch(setModalAnnouncement(false));
          dispatch(resetAnnouncementParticipants());
        }}
        announcementId={uuid}
      />
    </>
  );
};

export default memo(Table);
