import React, {
  BaseSyntheticEvent,
  CSSProperties,
  ReactNode,
  memo
} from "react";

import "./styles.scss";

interface Props {
  children: ReactNode;
  disabled?: boolean;
  onPress?: (e: BaseSyntheticEvent) => void;
  className?: string;
  style?: CSSProperties;
  tabIndex?: number;
}

const Button = ({
  children,
  disabled,
  onPress,
  className,
  style,
  tabIndex
}: Props) => {
  return !disabled ? (
    <div
      className={`button-container is-focus ${className}`}
      onClick={onPress}
      onKeyPress={onPress}
      style={style}
      tabIndex={tabIndex}
    >
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

Button.defaultProps = {
  disabled: false
};

export default memo(Button);
