/* eslint-disable no-nested-ternary */
import React from "react";
import Spinner from "../../../../components/Spinner";

function DetailInvoice(props: any) {
  const { data, is_loading, translate } = props;

  return (
    <>
      {is_loading ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : data ? (
        <div
          className="column"
          style={{
            border: "1px solid #ddd",
            marginTop: "30px",
            borderRadius: "25px",
            padding: "20px",
            boxSizing: "border-box"
          }}
        >
          <div className="panel-collapsable is-expanded theme1">
            <div className="">
              <div>
                <h2>DETAIL INVOICE</h2>
              </div>
            </div>
            <div>
              <div className="columns">
                <div className="column" style={{ marginLeft: "0em" }}>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.invoice_number")}
                    </label>
                    <p>{data.number}</p>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      Total
                    </label>
                    <div>
                      <div className="currency-container">
                        <p>{`${data.currency_code} ${data.total}`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.created_at")}
                    </label>
                    <p>{data.created_at}</p>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.purpose")}
                    </label>
                    <p>{data.purpose}</p>
                  </div>
                </div>
                <div className="column" style={{ marginLeft: "0em" }}>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.amount")}
                    </label>
                    <div>
                      <div className="currency-container">
                        <p>{`${data.currency_code} ${data.amount}`}</p>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.paid")}
                    </label>
                    <div>
                      <div className="currency-container">
                        <p>
                          {`${data.currency_code} ${
                            data.total_paid == null ? 0 : data.total_paid
                          }`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.due_date")}
                    </label>
                    <p>{data.due_at}</p>
                  </div>
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      {translate("deleteData.remarks")}
                    </label>
                    <p>{data.remarks ? data.remarks : "-"}</p>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label" style={{ fontSize: "15px" }}>
                      Items
                    </label>
                  </div>
                  <div
                    className="b-table has-mobile-cards"
                    id="invoice-payment-table"
                  >
                    <div className="table-wrapper">
                      <table
                        style={{ width: "100%" }}
                        className="table is-hoverable"
                      >
                        <thead>
                          <tr style={{ height: "4em" }}>
                            <th style={{ borderBottom: "1px solid #ddd" }}>
                              <div className="th-wrap">
                                {translate("deleteData._title")}
                                <span className="icon is-small is-invisible">
                                  <i className="mdi mdi-arrow-up" />
                                </span>
                              </div>
                            </th>
                            <th style={{ borderBottom: "1px solid #ddd" }}>
                              <div className="th-wrap">
                                {translate("deleteData.date")}
                                <span className="icon is-small is-invisible">
                                  <i className="mdi mdi-arrow-up" />
                                </span>
                              </div>
                            </th>
                            <th style={{ borderBottom: "1px solid #ddd" }}>
                              <div className="th-wrap">
                                {translate("deleteData.amount")}
                                <span className="icon is-small is-invisible">
                                  <i className="mdi mdi-arrow-up" />
                                </span>
                              </div>
                            </th>
                            <th style={{ borderBottom: "1px solid #ddd" }}>
                              <div className="th-wrap">
                                Total
                                <span className="icon is-small is-invisible">
                                  <i className="mdi mdi-arrow-up" />
                                </span>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.items.map((value, index) => (
                            <tr key={`tr-${index}`}>
                              <td
                                data-label="Title"
                                style={{ textAlign: "center" }}
                              >
                                <p key={`title-${index}`}>{value.title}</p>
                              </td>
                              <td
                                data-label="Date"
                                style={{ textAlign: "center" }}
                              >
                                <p key={`date-${index}`}>{value.created_at}</p>
                              </td>
                              <td
                                data-label="Amount"
                                style={{ textAlign: "center" }}
                              >
                                <div>
                                  <div className="currency-container">
                                    <p key={`amount-${index}`}>
                                      {`${data.currency_code} ${value.amount}`}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td
                                data-label="Total"
                                style={{ textAlign: "center" }}
                                text-align="right"
                              >
                                <div>
                                  <div className="currency-container">
                                    <p key={`currency-${index}`}>
                                      {`${data.currency_code} ${value.total}`}
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          width: "100%"
        }}
      >
        <div
          style={{
            height: "3.2em",
            width: "15%",
            marginTop: "35px"
          }}
        >
          <button
            onClick={() => props.destroy("finance", data.id)}
            disabled={!!is_loading}
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "20px",
              background:
                "linear-gradient(to bottom, #faaf40 0%, #f05a28 60%, #db3a26 100%)",
              color: "white",
              fontWeight: "bold",
              fontSize: "16px",
              borderColor: "lightgrey",
              cursor: "pointer"
            }}
            type="button"
          >
            {translate("deleteData.remove").toUpperCase()}
          </button>
        </div>
      </div>
    </>
  );
}

export default DetailInvoice;
