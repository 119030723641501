import {
  GET_MENU_ERROR,
  GET_MENU_PENDING,
  GET_MENU_REPORT_ERROR,
  GET_MENU_REPORT_PENDING,
  GET_MENU_REPORT_SUCCESS,
  GET_MENU_SUCCESS
} from "../actions";
import { Action, MenuState } from "../types";

const initalState: MenuState = {
  listMenu: [],
  listMenuReport: [],
  isListMenuLoading: false,
  collapsible: true
};

export default (state = initalState, { type, payload }: Action) => {
  switch (type) {
    case GET_MENU_PENDING:
      return {
        ...state,
        isListMenuLoading: true,
        listMenu: []
      };
    case GET_MENU_SUCCESS:
      return {
        ...state,
        isListMenuLoading: false,
        listMenu: [...payload.data],
        collapsible: payload.collapsible
      };
    case GET_MENU_ERROR:
      return {
        ...state,
        isListMenuLoading: false,
        listMenu: []
      };
    case GET_MENU_REPORT_PENDING:
      return {
        ...state,
        isListMenuLoading: true,
        listMenuReport: []
      };
    case GET_MENU_REPORT_SUCCESS:
      return {
        ...state,
        isListMenuLoading: false,
        listMenuReport: [...payload.data]
      };
    case GET_MENU_REPORT_ERROR:
      return {
        ...state,
        isListMenuLoading: false,
        listMenuReport: []
      };
    default:
      return { ...state };
  }
};
