import React, { memo, useCallback, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Loading, Modal } from "..";
import { IMAGES } from "../../configs";
import {
  getEmployee,
  saveAddAnnouncementParticipants,
  setAddAnnouncementParticipants,
  setDeleteAnnouncementParticipants,
  setModalAnnouncementParticipants
} from "../../redux/actions";
import { Reducers } from "../../redux/types";

interface Props {
  onChange?: (e: string) => void;
  value?: string;
  getData?: boolean;
  onSubmit?: (e: any[]) => void;
}

const ModalAnnouncementUserPicker = ({
  onChange,
  value,
  getData,
  onSubmit
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyState, employee, generateState } = useSelector(
    (state: Reducers) => ({
      employee: state.generate.listEmployee,
      companyState: state.company,
      generateState: state.generate
    }),
    shallowEqual
  );

  useEffect(() => {
    if (getData && companyState.setModalAnnouncementParticipants) {
      dispatch(getEmployee("", "", "", 1, false, "", false, false, false));
    }
  }, [getData, dispatch, companyState.setModalAnnouncementParticipants]);

  const _cancel = () => {
    if (!getData) {
      dispatch(getEmployee("", "", "", 1, false, "", false, false, false));
    }
    dispatch(setModalAnnouncementParticipants(false));
  };

  const _selected = useCallback(
    (id: string) => {
      const result =
        companyState.announcementParticipantList &&
        companyState.announcementParticipantList.findIndex(e => e.id === id);

      return result >= 0;
    },
    [companyState.announcementParticipantList]
  );

  const _getEmployee = useCallback(() => {
    if (getData) {
      return companyState.listEmployee
        ? employee.filter(
            e =>
              companyState.announcementById.users.findIndex(
                res => res.employee.id === e.id
              ) < 0
          )
        : [];
    }
    return employee;
  }, [
    employee,
    companyState.announcementById,
    companyState.listEmployee,
    getData
  ]);

  const _handleModalPic = (data: any) => {
    dispatch(setAddAnnouncementParticipants(data));
  };

  return (
    <Modal
      titleModal=""
      isOpen={companyState.setModalAnnouncementParticipants}
      onPressCancel={_cancel}
      onPressSubmit={() => {
        if (getData) {
          onSubmit && onSubmit(companyState.announcementParticipantList);
        } else {
          dispatch(saveAddAnnouncementParticipants());
        }
        _cancel();
      }}
      btnSubmitText={t("btn.save")}
      btnCancelText={t("btn.cancel")}
    >
      <div className="component-modal-userpicker-card-search-input global-shadow-card margin-bottom-36">
        <div className="component-modal-userpicker-container-search-input">
          <img
            src={IMAGES.search}
            alt="edit"
            style={{ height: 24, width: 24 }}
          />
          <input
            placeholder={t("searchPlaceholderPerson")}
            style={{ fontSize: 24 }}
            type="text"
            className="component-modal-userpicker-search-input margin-left-12"
            onChange={e => onChange && onChange(e.target.value)}
            value={value}
          />
        </div>
      </div>
      <div className="component-modal-user-picker-list-employee-container">
        {_getEmployee().map((data, index) => (
          <div
            key={index}
            className={`component-modal-user-picker-container-employee ${
              index > 0 ? "margin-top-24" : null
            }`}
          >
            <div className="component-modal-user-picker-container-employee-data">
              <img
                src={IMAGES.avatar}
                alt="avatar"
                className="global-shadow-card"
                style={{ height: 60, width: 60, borderRadius: 100 }}
              />
              <div className="margin-left-12">
                <div>{data.name}</div>
                <div style={{ marginTop: -8 }}>
                  {`${data.division.title} - ${data.position.title}`}
                </div>
              </div>
            </div>
            {_selected(data.id) ? (
              <div
                onClick={() =>
                  dispatch(setDeleteAnnouncementParticipants(data.id))
                }
              >
                <img
                  src={IMAGES.substractButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            ) : (
              <div onClick={() => _handleModalPic(data)}>
                <img
                  src={IMAGES.addButton}
                  alt="avatar"
                  style={{ height: 28, width: 28, borderRadius: 100 }}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      <Loading isVisible={generateState.isLoadingListEmployee} />
    </Modal>
  );
};

ModalAnnouncementUserPicker.defaultProps = {
  getData: false
};

export default memo(ModalAnnouncementUserPicker);
