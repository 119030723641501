import React, { memo, useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import "./styles.scss";
import { Button, Container, Text } from "../../../../components";
import { Card } from "../../../TermsPolicy/components";
import { IMAGES } from "../../../../configs";

import { Reducers } from "../../../../redux/types";
import {
  getAllPosition,
  getDepartment,
  getEmployee,
  getPositionByID,
  getSpecialRole,
  setActiveDepartment,
  setActivePosition,
  setActiveSpecialRole
} from "../../../../redux/actions";
import { copyWritings } from "../../../../utils";

const CategoryPolicy = () => {
  const filterText = {
    company: copyWritings("company"),
    department: copyWritings("department")
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = history.location;

  const [isShow, setIsShow] = useState(false);
  const [headerName, setHeaderName] = useState({
    departement: "",
    position: "",
    personil: ""
  });

  const { policy } = useSelector(
    (state: Reducers) => ({
      policy: state.generate
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllPosition());
    dispatch(getSpecialRole());
    dispatch(getEmployee("", "", "", 1));
  }, [dispatch]);

  const _setActiveDepartment = (index: number, id: string, title: string) => {
    if (index === policy.activeDepartment.index) {
      dispatch(setActiveDepartment(-1));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getEmployee());
      dispatch(getAllPosition());
      dispatch(getSpecialRole());
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activePosition.index >= 0 &&
      policy.activeSpecialRole < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getPositionByID(id));
      dispatch(getSpecialRole(id));
      dispatch(getEmployee(id));
      setHeaderName({ ...headerName, departement: title });
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activeSpecialRole >= 0 &&
      policy.activePosition.index < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getPositionByID(id));
      dispatch(getSpecialRole(id));
      dispatch(getEmployee(id));
      setHeaderName({ ...headerName, departement: title });
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activeSpecialRole >= 0 &&
      policy.activePosition.index >= 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getPositionByID(id));
      dispatch(getSpecialRole(id));
      dispatch(getEmployee(id));
      setHeaderName({ ...headerName, departement: title });
    }
    if (
      index !== policy.activeDepartment.index &&
      policy.activeSpecialRole < 0 &&
      policy.activePosition.index < 0
    ) {
      dispatch(setActiveDepartment(index, id));
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getPositionByID(id));
      dispatch(getSpecialRole(id));
      dispatch(getEmployee(id));
      setHeaderName({ ...headerName, departement: title });
    }
  };

  const _setActivePosition = (
    index: number,
    idDp: string,
    idPos: string,
    title: string
  ) => {
    if (
      index === policy.activePosition.index &&
      policy.activeDepartment.index < 0 &&
      policy.activeSpecialRole < 0
    ) {
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getSpecialRole());
      dispatch(getEmployee());
    }
    if (
      policy.activeDepartment.index >= 0 &&
      index !== policy.activePosition.index
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(getSpecialRole(idDp, idPos));
      dispatch(getEmployee(idDp, idPos));
      setHeaderName({ ...headerName, position: title });
    }
    if (
      policy.activeDepartment.index < 0 &&
      index !== policy.activePosition.index &&
      policy.activeSpecialRole < 0
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(getEmployee(idDp, idPos));
      dispatch(getSpecialRole("", idPos));
      setHeaderName({ ...headerName, position: title });
    }
    if (
      policy.activeDepartment.index >= 0 &&
      index === policy.activePosition.index &&
      policy.activeSpecialRole < 0
    ) {
      dispatch(setActivePosition(-1));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getEmployee(idDp));
      dispatch(getSpecialRole(idDp));
    }
    if (
      policy.activeSpecialRole >= 0 &&
      index !== policy.activePosition.index &&
      policy.activeDepartment.index < 0
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getSpecialRole("", idPos));
      dispatch(getEmployee("", idPos));
      setHeaderName({ ...headerName, position: title });
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activeSpecialRole >= 0 &&
      index !== policy.activePosition.index
    ) {
      dispatch(setActivePosition(index, idPos));
      dispatch(setActiveSpecialRole(-1));
      dispatch(getEmployee(idDp, idPos));
      dispatch(getSpecialRole(idDp, idPos));
      setHeaderName({ ...headerName, position: title });
    }
  };

  const _setActiveRole = (
    idDp: string,
    idPos: string,
    idRole: string,
    index: number,
    name: string
  ) => {
    if (index === policy.activeSpecialRole) {
      dispatch(setActiveSpecialRole(-1));
      dispatch(getEmployee(idDp, idPos));
    } else {
      dispatch(setActiveSpecialRole(index));
      dispatch(getEmployee(idDp, idPos, idRole));
      setHeaderName({ ...headerName, personil: name });
    }
  };

  const _renderRole = () => {
    if (policy.activeDepartment.index >= 0) {
      return policy.specialRole;
    }
    if (policy.activePosition.index >= 0) {
      return policy.specialRole;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return policy.specialRole;
    }
    return policy.specialRole;
  };

  const _loadingRole = () => {
    if (policy.activeDepartment.index >= 0) {
      return policy.isLoadingSpecialRole;
    }
    if (policy.activePosition.index >= 0) {
      return policy.isLoadingSpecialRole;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return policy.isLoadingSpecialRole;
    }
    return policy.isLoadingSpecialRole;
  };

  const _renderPosition = () => {
    if (policy.activeDepartment.index < 0) {
      return policy.allPosition;
    }
    return policy.positionById;
  };

  const _renderHeaderName = useCallback(() => {
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activeSpecialRole >= 0
    ) {
      return headerName.personil;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activeSpecialRole < 0
    ) {
      return headerName.position;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index < 0 &&
      policy.activeSpecialRole >= 0
    ) {
      return headerName.personil;
    }
    if (
      policy.activeDepartment.index < 0 &&
      policy.activePosition.index < 0 &&
      policy.activeSpecialRole >= 0
    ) {
      return headerName.personil;
    }
    if (
      policy.activeDepartment.index < 0 &&
      policy.activePosition.index >= 0 &&
      policy.activeSpecialRole >= 0
    ) {
      return headerName.personil;
    }
    if (policy.activeDepartment.index >= 0) {
      return headerName.departement;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0
    ) {
      return headerName.position;
    }
    if (policy.activeDepartment.index < 0 && policy.activePosition.index >= 0) {
      return headerName.position;
    }
    if (
      policy.activeDepartment.index >= 0 &&
      policy.activePosition.index >= 0 &&
      policy.activeSpecialRole >= 0
    ) {
      return headerName.personil;
    }
    return filterText.company;
  }, [
    headerName.departement,
    headerName.position,
    headerName.personil,
    policy.activeDepartment.index,
    policy.activePosition.index,
    policy.activeSpecialRole,
    filterText.company
  ]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 32
      }}
    >
      <Card
        className={`${
          isShow ? "employee-filter-card" : "employee-filter-card-close"
        }`}
        style={{ height: isShow ? 395 : 100 }}
      >
        <Button
          onPress={() => setIsShow(!isShow)}
          className="employee-filter-button-acordion"
        >
          {isShow ? (
            <div
              className="employee-filter-header-title-wrap"
              style={{ position: "relative" }}
            >
              <Text className="employee-filter-header-title">
                {filterText.department}
              </Text>
              <Text className="employee-filter-header-title">Position</Text>
              {pathname !== "/company/tree" && (
                <Text className="employee-filter-header-title">
                  Special Role
                </Text>
              )}
              <img
                style={{
                  width: 23,
                  height: 13,
                  position: "absolute",
                  right: 0
                }}
                src={IMAGES.chevronUp}
                alt="up-arrow"
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between"
              }}
            >
              <Text className="employee-filter-card-close-text">
                {_renderHeaderName()}
              </Text>
              <img
                style={{ width: 23, height: 13, alignSelf: "center" }}
                src={IMAGES.chevron}
                alt="down-arrow"
              />
            </div>
          )}
        </Button>
        {isShow && (
          <>
            <div className="employee-filter-line" />
            <div className="employee-filter-card-category">
              <div className="employee-filter-card-category-wrap">
                <Container isLoading={policy.isLoadingDepartment}>
                  {policy.dataDepartment.map((item: any, index: number) => (
                    <Card
                      key={index}
                      className={`employee-filter-card-category-content ${index ===
                        policy.activeDepartment.index &&
                        "employee-filter-card-selected"}`}
                    >
                      <Button
                        onPress={() =>
                          _setActiveDepartment(
                            index,
                            item.id,
                            item.attributes.title
                          )
                        }
                      >
                        <Text
                          className={`${index ===
                            policy.activeDepartment.index &&
                            "employee-filter-card-selected-text"}`}
                        >
                          {item.attributes.title}
                        </Text>
                      </Button>
                    </Card>
                  ))}
                </Container>
              </div>

              <div className="employee-filter-card-category-wrap">
                <Container
                  isLoading={
                    policy.activeDepartment.index < 0
                      ? policy.isLoadingAllPosition
                      : policy.isloadingPositionByid
                  }
                >
                  {_renderPosition().map((item: any, index: number) => (
                    <Card
                      key={index}
                      className={`employee-filter-card-category-content ${index ===
                        policy.activePosition.index &&
                        "employee-filter-card-selected"}`}
                    >
                      <Button
                        onPress={() =>
                          _setActivePosition(
                            index,
                            policy.activeDepartment.id,
                            item.id,
                            item.attributes.title
                          )
                        }
                      >
                        <Text
                          className={`${index === policy.activePosition.index &&
                            "employee-filter-card-selected-text"}`}
                        >
                          {item.attributes.title}
                        </Text>
                      </Button>
                    </Card>
                  ))}
                </Container>
              </div>

              {pathname !== "/company/tree" && (
                <div className="employee-filter-card-category-wrap">
                  <Container isLoading={_loadingRole()}>
                    {_renderRole().map((item: any, index: number) => (
                      <Card
                        key={index}
                        className={`employee-filter-card-category-content ${index ===
                          policy.activeSpecialRole &&
                          "employee-filter-card-selected"}`}
                      >
                        <Button
                          onPress={() =>
                            _setActiveRole(
                              policy.activeDepartment.id,
                              policy.activePosition.id,
                              item.id,
                              index,
                              item.name
                            )
                          }
                        >
                          <Text
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              width: "90%"
                            }}
                            className={`${index === policy.activeSpecialRole &&
                              "employee-filter-card-selected-text"}`}
                          >
                            {item.name}
                          </Text>
                        </Button>
                      </Card>
                    ))}
                  </Container>
                </div>
              )}
            </div>
          </>
        )}
      </Card>
    </div>
  );
};

export default memo(CategoryPolicy);
