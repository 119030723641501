import React, { memo } from "react";

import "./styles.scss";

interface Props {
  onChange?: (e: any) => void;
  label: string;
  id?: any;
  name?: any;
  value?: any;
  defaultChecked?: boolean;
}

const Picker = ({
  onChange,
  label,
  id,
  name,
  value,
  defaultChecked
}: Props) => {
  return (
    <label className="container-checkbox">
      <div style={{ fontSize: 14, fontWeight: "bold", color: "#666666" }}>
        {label}
      </div>
      <input
        type="checkbox"
        onChange={onChange}
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        className="input"
      />
      <span className="checkmark" />
    </label>
  );
};

export default memo(Picker);
