import React from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../redux/types";

import { setSetting } from "../../../../redux/actions";

const DropdownInput = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const settingState = useSelector((state: Reducers) => state.setting);

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>{t("setting.value")}</p>
      <select
        name="project_id"
        className="component-modal-input-dropdown"
        onChange={e => dispatch(setSetting("value", e.target.value))}
      >
        {settingState.lookupValue.map((item, index) => (
          <option
            key={index}
            value={item.real_id}
            selected={item.real_id === settingState.settingDetail.value}
          >
            {item.title}
          </option>
        ))}
      </select>
      <div className="setting-list-line" />
    </div>
  );
};

export default DropdownInput;
