import { Slide, toast } from "react-toastify";

import { Dispatch, GetState } from "../types";
import { API } from "../../configs";
import { handleLogout } from ".";
import i18n from "../../i18n";

export const GET_EMPLOYEE_PROFILE_PENDING = "GET_EMPLOYEE_PROFILE_PENDING";
export const GET_EMPLOYEE_PROFILE_SUCCESS = "GET_EMPLOYEE_PROFILE_SUCCESS";
export const GET_EMPLOYEE_PROFILE_ERROR = "GET_EMPLOYEE_PROFILE_ERROR";

export const RESET_EMPLOYEE_PROFILE = "RESET_EMPLOYEE_PROFILE";

export const RESET_ROLES_EMPLOYEE_PROFILE = "RESET_ROLES_EMPLOYEE_PROFILE";

export const SET_EMPLOYEE_PROFILE = "SET_EMPLOYEE_PROFILE";

export const ADD_FORM_DATA_ADDRESS = "ADD_FORM_DATA_ADDRESS";

export const GET_EMPLOYEE_PROFILE_DUMMY_SUCCESS =
  "GET_EMPLOYEE_PROFILE_DUMMY_SUCCESS";

export const GET_PROFILE_STAFF_PENDING = "GET_PROFILE_STAFF_PENDING";
export const GET_PROFILE_STAFF_SUCCESS = "GET_PROFILE_STAFF_SUCCESS";
export const GET_PROFILE_STAFF_ERROR = "GET_PROFILE_STAFF_ERROR";

export const PUT_SAVE_MY_PROFILE_PENDING = "PUT_SAVE_MY_PROFILE_PENDING";
export const PUT_SAVE_MY_PROFILE_SUCCESS = "PUT_SAVE_MY_PROFILE_SUCCESS";
export const PUT_SAVE_MY_PROFILE_ERROR = "PUT_SAVE_MY_PROFILE_ERROR";

export const SET_IMAGE_MY_PROFILE_EMPLOYEE = "SET_IMAGE_MY_PROFILE_EMPLOYEE";
export const SET_FORM_MY_PROFILE_EMPLOYEE = "SET_FORM_MY_PROFILE_EMPLOYEE";
export const REMOVE_FORM_MY_PROFILE_EMPLOYEE =
  "REMOVE_FORM_MY_PROFILE_EMPLOYEE";

export const ADD_ACCOUNT_FORM_EMPLOYEE = "ADD_ACCOUNT_FORM_EMPLOYEE";
export const DELETE_ACCOUNT_FORM_EMPLOYEE = "DELETE_ACCOUNT_FORM_EMPLOYEE";
export const SET_FORM_ACCOUNT_FORM_EMPLOYEE = "SET_FORM_ACCOUNT_FORM_EMPLOYEE";

export const SET_STAFF_EMPLOYEE_PROFILE = "SET_STAFF_EMPLOYEE_PROFILE";

export const GET_ALL_BANK_PENDING = "GET_ALL_BANK_PENDING";
export const GET_ALL_BANK_SUCCESS = "GET_ALL_BANK_SUCCESS";
export const GET_ALL_BANK_ERROR = "GET_ALL_BANK_ERROR";

export const GET_ALL_ROLES_PENDING = "GET_ALL_ROLES_PENDING";
export const GET_ALL_ROLES_SUCCESS = "GET_ALL_ROLES_SUCCESS";
export const GET_ALL_ROLES_ERROR = "GET_ALL_ROLES_ERROR";

export const SET_PRIMARY_ACCOUNT_EMPLOYEE_PENDING =
  "SET_PRIMARY_ACCOUNT_EMPLOYEE_PENDING";
export const SET_PRIMARY_ACCOUNT_EMPLOYEE_SUCCESS =
  "SET_PRIMARY_ACCOUNT_EMPLOYEE_SUCCESS";
export const SET_PRIMARY_ACCOUNT_EMPLOYEE_ERROR =
  "SET_PRIMARY_ACCOUNT_EMPLOYEE_ERROR";

export const DELETE_ACCOUNT_EMPLOYEE_PENDING =
  "DELETE_ACCOUNT_EMPLOYEE_PENDING";
export const DELETE_ACCOUNT_EMPLOYEE_SUCCESS =
  "DELETE_ACCOUNT_EMPLOYEE_SUCCESS";
export const DELETE_ACCOUNT_EMPLOYEE_ERROR = "DELETE_ACCOUNT_EMPLOYEE_ERROR";

export const GET_TEMPLATE_EMPLOYEE_PENDING = "GET_TEMPLATE_EMPLOYEE_PENDING";
export const GET_TEMPLATE_EMPLOYEE_SUCCESS = "GET_TEMPLATE_EMPLOYEE_SUCCESS";
export const GET_TEMPLATE_EMPLOYEE_ERROR = "GET_TEMPLATE_EMPLOYEE_ERROR";

export const VALIDATE_DATA_EMPLOYEE_PENDING = "VALIDATE_DATA_EMPLOYEE_PENDING";
export const VALIDATE_DATA_EMPLOYEE_SUCCESS = "VALIDATE_DATA_EMPLOYEE_SUCCESS";
export const VALIDATE_DATA_EMPLOYEE_ERROR = "VALIDATE_DATA_EMPLOYEE_ERROR";

export const UPLOAD_DATA_EMPLOYEE_PENDING = "UPLOAD_DATA_EMPLOYEE_PENDING";
export const UPLOAD_DATA_EMPLOYEE_SUCCESS = "UPLOAD_DATA_EMPLOYEE_SUCCESS";
export const UPLOAD_DATA_EMPLOYEE_ERROR = "UPLOAD_DATA_EMPLOYEE_ERROR";

export const REMOVE_VALIDATE_FILE_EMPLOYEE = "REMOVE_VALIDATE_FILE_EMPLOYEE";

export const SET_MODAL_ADD_CONTRACT = "SET_MODAL_ADD_CONTRACT";
export const SET_MODAL_EDIT_CONTRACT = "SET_MODAL_EDIT_CONTRACT";
export const SET_MODAL_ADD_SALARY = "SET_MODAL_ADD_SALARY";
export const SET_MODAL_EDIT_SALARY = "SET_MODAL_EDIT_SALARY";

export const GET_CONTRACTS_BY_EMPLOYEE_PENDING =
  "GET_CONTRACTS_BY_EMPLOYEE_PENDING";
export const GET_CONTRACTS_BY_EMPLOYEE_SUCCESS =
  "GET_CONTRACTS_BY_EMPLOYEE_SUCCESS";
export const GET_CONTRACTS_BY_EMPLOYEE_ERROR =
  "GET_CONTRACTS_BY_EMPLOYEE_ERROR";
export const GET_CONTRACTS_BY_ID_PENDING = "GET_CONTRACTS_BY_ID_PENDING";
export const GET_CONTRACTS_BY_ID_SUCCESS = "GET_CONTRACTS_BY_ID_SUCCESS";
export const GET_CONTRACTS_BY_ID_ERROR = "GET_CONTRACTS_BY_ID_ERROR";
export const RESET_DETAIL_CONTRACT = "RESET_DETAIL_CONTRACT";

export const GET_SALARY_BY_EMPLOYEE_PENDING = "GET_SALARY_BY_EMPLOYEE_PENDING";
export const GET_SALARY_BY_EMPLOYEE_SUCCESS = "GET_SALARY_BY_EMPLOYEE_SUCCESS";
export const GET_SALARY_BY_EMPLOYEE_ERROR = "GET_SALARY_BY_EMPLOYEE_ERROR";
export const GET_SALARY_BY_ID_PENDING = "GET_SALARY_BY_ID_PENDING";
export const GET_SALARY_BY_ID_SUCCESS = "GET_SALARY_BY_ID_SUCCESS";
export const GET_SALARY_BY_ID_ERROR = "GET_SALARY_BY_ID_ERROR";

export const POST_ADD_CONTRACT_PENDING = "POST_ADD_CONTRACT_PENDING";
export const POST_ADD_CONTRACT_SUCCESS = "POST_ADD_CONTRACT_SUCCESS";
export const POST_ADD_CONTRACT_ERROR = "POST_ADD_CONTRACT_ERROR";
export const PUT_CONTRACT_PENDING = "PUT_CONTRACT_PENDING";
export const PUT_CONTRACT_SUCCESS = "PUT_CONTRACT_SUCCESS";
export const PUT_CONTRACT_ERROR = "PUT_CONTRACT_ERROR";
export const POST_ADD_SALARY_PENDING = "POST_ADD_SALARY_PENDING";
export const POST_ADD_SALARY_SUCCESS = "POST_ADD_SALARY_SUCCESS";
export const POST_ADD_SALARY_ERROR = "POST_ADD_SALARY_ERROR";
export const PUT_SALARY_PENDING = "PUT_SALARY_PENDING";
export const PUT_SALARY_SUCCESS = "PUT_SALARY_SUCCESS";
export const PUT_SALARY_ERROR = "PUT_SALARY_ERROR";

export const DELETE_CONTRACT_PENDING = "DELETE_CONTRACT_PENDING";
export const DELETE_CONTRACT_SUCCESS = "DELETE_CONTRACT_SUCCESS";
export const DELETE_CONTRACT_ERROR = "DELETE_CONTRACT_ERROR";

export const DELETE_SALARY_PENDING = "DELETE_SALARY_PENDING";
export const DELETE_SALARY_SUCCESS = "DELETE_SALARY_SUCCESS";
export const DELETE_SALARY_ERROR = "DELETE_SALARY_ERROR";

export const SET_MODAL_GENERATE_LOAN_PROFILE =
  "SET_MODAL_GENERATE_LOAN_PROFILE";

export const getEmployeeProfile = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_EMPLOYEE_PROFILE_PENDING });

    const res = await API.getEmployeeProfile();
    dispatch({
      type: GET_EMPLOYEE_PROFILE_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_EMPLOYEE_PROFILE_ERROR });
  }
};

export const getProfileStaff = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_PROFILE_STAFF_PENDING });

    const res = await API.getProfileStaff(id);
    dispatch({
      type: GET_PROFILE_STAFF_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    dispatch({ type: GET_PROFILE_STAFF_ERROR });
  }
};

export const getEmployeeProfileDummy = () => async (dispatch: Dispatch) => {
  dispatch({
    type: GET_EMPLOYEE_PROFILE_DUMMY_SUCCESS,
    payload: {
      data: {
        avatar: null,
        active: true,
        roles: [
          { name: "roles1", status: true },
          { name: "roles2", status: false }
        ],
        name: "Rizky Abdulrachman",
        place_and_date_birth: "Bandung, 1912-12-12",
        departement: "IT Division",
        staff_id: "128798132",
        position_name: "Front End Engineer",
        addresses: [
          {
            alias: "Rumah Rizky",
            city: { name: "Bandung", id: 2 },
            fax: "02129222999",
            id: 1,
            is_primary: false,
            line_1: "baris 1",
            line_2: null,
            phone: "02134156723",
            zipcode: "123"
          },
          {
            alias: "Kosan",
            city: { name: "jakarta", id: 1 },
            fax: "02129222999",
            id: 2,
            is_primary: false,
            line_1: "baris 1",
            line_2: null,
            phone: "02134156723",
            zipcode: "123"
          }
        ],
        email: "rizkyabdulrachman@gmail.com",
        last_update_password: "2020-12-12",
        id_card_number: "321233312333",
        passport_number: "12345678",
        passport_expire_date: "02",
        passport_expire_month: "01",
        accounts: [
          {
            type: null,
            id: "427aaccd8bfc69da44371e7600333e1c",
            index: 1,
            primary: true,
            active: true,
            created_at: "2020-05-06T05:21:38.000000Z",
            updated_at: "2020-05-06T05:21:38.000000Z",
            account: {
              type: "account",
              id: "789b070f-7191-4f45-afa2-dee0002c1853",
              bank: {
                type: "bank",
                id: "d11d4d85-7754-4f74-9394-13d609e0e61c",
                name: "Bank Central Asia (BCA)",
                swift: null,
                country_code: 360
              },
              branch: "Kuningan Centre",
              number: "1238956512",
              bankName: "Bank Central Asia (BCA)",
              name: "Ahmad Saubani",
              created_at: "2020-05-06T05:21:38.000000Z",
              updated_at: "2020-05-06T05:21:38.000000Z"
            }
          }
        ]
      }
    }
  });
};

export const setEmployeeProfile = (data: {
  formName: string;
  value: string | number | boolean | null;
}) => (dispatch: Dispatch) => {
  dispatch({ type: SET_EMPLOYEE_PROFILE, payload: { data } });
};

export const setStaffEmployeeProfile = (data: {
  formName: string;
  value: any;
}) => (dispatch: Dispatch) => {
  dispatch({ type: SET_STAFF_EMPLOYEE_PROFILE, payload: { data } });
};

export const addFormDataAddress = (data: any) => (dispatch: Dispatch) => {
  dispatch({ type: ADD_FORM_DATA_ADDRESS, payload: { data } });
};

export const setImageMyProfileEmployee = (dataImage: File | "") => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_IMAGE_MY_PROFILE_EMPLOYEE,
    payload: { data: dataImage }
  });
};

export const setFormMyProfileEmployee = (
  formName:
    | "idNumber"
    | "passport"
    | "taxNumber"
    | "city"
    | "signature"
    | "employeeBussiness",
  file: File | {} | string
) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_FORM_MY_PROFILE_EMPLOYEE,
    payload: { data: file, name: formName }
  });
};

export const removeFormMyProfileEmployee = (
  formName:
    | "idNumber"
    | "passport"
    | "taxNumber"
    | "city"
    | "signature"
    | "employeeBussiness"
) => (dispatch: Dispatch) => {
  dispatch({
    type: REMOVE_FORM_MY_PROFILE_EMPLOYEE,
    payload: { data: formName }
  });
};

export const putSaveMyProfile = (
  form: any,
  id: string,
  onSuccess?: () => void,
  isNew = false
) => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const { employee } = getState();

    dispatch({ type: PUT_SAVE_MY_PROFILE_PENDING });

    const formData = new FormData();
    const primaryUser = employee?.staff?.data?.primaryUser;
    formData.append(
      "gender",
      primaryUser?.gender && primaryUser.gender !== "undefined"
        ? primaryUser.gender
        : null
    );
    formData.append("outsource", employee.staff.data.outsource || false);
    if (employee.staff.data.changedSignature) {
      formData.append("signature", employee.staff.data.signature);
    }
    formData.append("position_id", employee.staff.data.position.id || "");
    formData.append("number", form.number || employee.staff.data.number);
    formData.append("hidden", employee.staff.data.is_hidden);
    formData.append(
      "active",
      employee.staff.data.is_disabled ? "false" : "true"
    );

    if (employee.staff.form.accounts.length > 0) {
      employee.staff.form.accounts.map((e: any, index: number) => {
        formData.append(`accounts[${index}][bank_id]`, e.bank_id);
        formData.append(`accounts[${index}][branch]`, e.branch);
        formData.append(`accounts[${index}][number]`, e.number);
        formData.append(`accounts[${index}][name]`, e.name);
        return true;
      });
    }

    if (employee.staff.form.employeeBussiness) {
      if (employee.staff.form.employeeBussiness.name !== "") {
        formData.append(
          "employeeBussiness[name]",
          employee.staff.form.employeeBussiness.name
        );
      }
      if (employee.staff.form.employeeBussiness.address !== "") {
        formData.append(
          "employeeBussiness[address]",
          employee.staff.form.employeeBussiness.address
        );
      }
      if (employee.staff.form.employeeBussiness.file) {
        if (
          employee.staff.form.employeeBussiness.file.name ||
          employee.staff.form.employeeBussiness.file.title
        ) {
          formData.append(
            "employeeBussiness[file]",
            employee.staff.form.employeeBussiness.file
          );
        }
      }
    }

    employee.staff.data.roles.map((e: any, index: number) => {
      formData.append(`roles[${index}]`, e.id);
      return true;
    });

    if (
      employee.staff.data.addresses &&
      employee.staff.data.addresses.length > 0
    ) {
      formData.append(
        "addresses[0][id]",
        employee.staff.data.addresses[0].address.id
      );
      if (
        employee.staff.form.city ||
        (employee.staff.data.addresses[0].address.regency &&
          employee.staff.data.addresses[0].address.regency.id)
      ) {
        formData.append(
          "addresses[0][regency_id]",
          employee.staff.form.city ||
            employee.staff.data.addresses[0].address.regency.id
        );
      }
    } else {
      formData.append("new_address[0][regency_id]", employee.staff.form.city);
    }

    Object.values(form).map((e: any, i: number) => {
      if (
        Object.keys(form)[i] === "address" ||
        Object.keys(form)[i] === "zip" ||
        Object.keys(form)[i] === "phone" ||
        Object.keys(form)[i] === "fax"
      ) {
        if (
          employee.staff.data.addresses &&
          employee.staff.data.addresses.length > 0
        ) {
          formData.append(`addresses[0][${Object.keys(form)[i]}]`, e);
        } else {
          formData.append(`new_address[0][${Object.keys(form)[i]}]`, e);
        }
      } else {
        formData.append(Object.keys(form)[i], e);
      }
      return true;
    });
    if (employee.staff.form.imageString !== "") {
      formData.append("avatar", employee.staff.form.imageFile);
    }

    if (employee.staff.form.idNumber.number !== "") {
      formData.append("idNumber[number]", employee.staff.form.idNumber.number);
    }
    if (employee.staff.form.idNumber.file.name) {
      formData.append("idNumber[file]", employee.staff.form.idNumber.file);
    }

    if (employee.staff.form.passport.number !== "") {
      formData.append("passport[number]", employee.staff.form.passport.number);
    }
    if (
      employee.staff.form.passport.expired_at.mm !== "" &&
      employee.staff.form.passport.expired_at.yy !== ""
    ) {
      formData.append(
        "passport[expired_at]",
        `20${employee.staff.form.passport.expired_at.yy}-${employee.staff.form.passport.expired_at.mm}-28`
      );
    }
    if (employee.staff.form.passport.file.name) {
      formData.append("passport[file]", employee.staff.form.passport.file);
    }

    if (employee.staff.form.taxNumber.number !== "") {
      formData.append(
        "taxNumber[number]",
        employee.staff.form.taxNumber.number
      );
    }
    if (employee.staff.form.taxNumber.file.name) {
      formData.append("taxNumber[file]", employee.staff.form.taxNumber.file);
    }

    if (
      (employee.staff.form.city !== "" ||
        employee.staff.data.addresses[0].address.city) &&
      employee.staff.data.position.id !== ""
    ) {
      const res = await API.putSaveProfileEmployee(
        formData,
        id,
        isNew ? "POST" : "PUT"
      );
      onSuccess && onSuccess();
      toast(
        isNew
          ? i18n.t("personnel.toast.addSuccess")
          : i18n.t("personnel.toast.editSuccess"),
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "term-policy-toast",
          closeButton: false,
          draggable: false
        }
      );
      dispatch({
        type: PUT_SAVE_MY_PROFILE_SUCCESS,
        payload: { data: res.data.data, isNew }
      });
    } else {
      dispatch({
        type: PUT_SAVE_MY_PROFILE_ERROR,
        payload: { data: { formName: "city", value: true } }
      });
    }
  } catch (err) {
    const error = err.response.data.message?.error_messages || {
      message: "Error tidak terduga"
    };
    const errorMessages = Object.values(error).join(", ");
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(errorMessages, {
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: true,
        transition: Slide,
        className: "term-policy-toast danger",
        closeButton: false,
        draggable: false
      });
      dispatch({
        type: PUT_SAVE_MY_PROFILE_ERROR
      });
    }
  }
};

export const getAllBank = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ALL_BANK_PENDING });

    const res = await API.getAllBank();
    dispatch({
      type: GET_ALL_BANK_SUCCESS,
      payload: { data: res.data.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_ALL_BANK_ERROR });
  }
};

export const addAccountsEmployee = (reset = false) => (dispatch: Dispatch) => {
  dispatch({ type: ADD_ACCOUNT_FORM_EMPLOYEE, payload: { data: reset } });
};

export const deleteAccountsEmployee = (index: number) => (
  dispatch: Dispatch
) => {
  dispatch({ type: DELETE_ACCOUNT_FORM_EMPLOYEE, payload: { data: index } });
};

export const setFormAccountsEmployee = (data: any, index: number) => (
  dispatch: Dispatch
) => {
  dispatch({ type: SET_FORM_ACCOUNT_FORM_EMPLOYEE, payload: { data, index } });
};

export const getAllRoles = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_ALL_ROLES_PENDING });

    const res = await API.getAllRoles();
    dispatch({
      type: GET_ALL_ROLES_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_ALL_ROLES_ERROR });
  }
};

export const deleteAccountEmployee = (id: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_ACCOUNT_EMPLOYEE_PENDING });

    await API.deleteAccountEmployee(id);
    dispatch({ type: DELETE_ACCOUNT_EMPLOYEE_SUCCESS, payload: { data: id } });
    toast("Account has been deleted", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    toast(err?.response?.data?.message || "Something went wrong!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast danger",
      closeButton: false,
      draggable: false
    });
    dispatch({ type: DELETE_ACCOUNT_EMPLOYEE_ERROR });
  }
};

export const resetEmployeeProfile = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_EMPLOYEE_PROFILE });
};

export const resetRolesEmployeeProfile = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_ROLES_EMPLOYEE_PROFILE });
};

export const setPrimaryAccountEmployee = (account_id: string) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const { employee } = getState();

    dispatch({ type: SET_PRIMARY_ACCOUNT_EMPLOYEE_PENDING });

    await API.putPrimaryAccountEmployee(account_id, employee.staff.data.id);
    dispatch({
      type: SET_PRIMARY_ACCOUNT_EMPLOYEE_SUCCESS,
      payload: { data: account_id }
    });
    toast("Your primary account has been changed", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }
    toast(err?.response?.data?.message || "Something went wrong!", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast danger",
      closeButton: false,
      draggable: false
    });

    dispatch({ type: SET_PRIMARY_ACCOUNT_EMPLOYEE_ERROR });
  }
};

export const getTemplateEmployee = () => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_TEMPLATE_EMPLOYEE_PENDING });

    await API.getTemplateEmployee();

    dispatch({
      type: GET_TEMPLATE_EMPLOYEE_SUCCESS
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_TEMPLATE_EMPLOYEE_ERROR });
  }
};

export const validateUploadDataEmployee = (
  file: File,
  cb?: () => void
) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: VALIDATE_DATA_EMPLOYEE_PENDING });

    const formData = new FormData();
    formData.append("file", file);

    const res = await API.validateUploadDataEmployee(formData, e =>
      dispatch({ type: VALIDATE_DATA_EMPLOYEE_PENDING, payload: { data: e } })
    );
    toast(res.data.message, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
    dispatch({
      type: VALIDATE_DATA_EMPLOYEE_SUCCESS,
      payload: { data: res.data }
    });
    cb && cb();
  } catch (err) {
    if (err.response) {
      if (err.response.status === 401) {
        dispatch(handleLogout());
      } else {
        toast(err.response.data.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          transition: Slide,
          className: "term-policy-toast danger",
          closeButton: false,
          draggable: false
        });
      }
    }
    dispatch({ type: VALIDATE_DATA_EMPLOYEE_ERROR });
  }
};

export const removeValidateEmployee = () => async (dispatch: Dispatch) => {
  dispatch({ type: REMOVE_VALIDATE_FILE_EMPLOYEE });
};

export const uploadDataEmployee = (file: File, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: UPLOAD_DATA_EMPLOYEE_PENDING });

    const formData = new FormData();
    formData.append("file", file);

    const res = await API.uploadDataEmployee(formData, e =>
      dispatch({ type: UPLOAD_DATA_EMPLOYEE_PENDING, payload: { data: e } })
    );
    cb();
    toast("Import success", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "term-policy-toast",
      closeButton: false,
      draggable: false
    });
    dispatch({
      type: UPLOAD_DATA_EMPLOYEE_SUCCESS,
      payload: { data: res.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast("Error! Try again!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        transition: Slide,
        className: "term-policy-toast danger",
        closeButton: false,
        draggable: false
      });
      dispatch({ type: UPLOAD_DATA_EMPLOYEE_ERROR });
    }
  }
};

export const getContractsByEmployee = (id: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_CONTRACTS_BY_EMPLOYEE_PENDING });

    const res = await API.getContractsByEmployee(id);
    dispatch({
      type: GET_CONTRACTS_BY_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_CONTRACTS_BY_EMPLOYEE_ERROR });
  }
};

export const getContractsById = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_CONTRACTS_BY_ID_PENDING });

    const res = await API.getContractsById(id);
    dispatch({
      type: GET_CONTRACTS_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_CONTRACTS_BY_ID_ERROR });
  }
};

export const resetDetailContract = () => (dispatch: Dispatch) => {
  dispatch({ type: RESET_DETAIL_CONTRACT });
};

export const getSalaryByEmployee = (id: string) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: GET_SALARY_BY_EMPLOYEE_PENDING });

    const res = await API.getSalaryByEmployee(id);
    dispatch({
      type: GET_SALARY_BY_EMPLOYEE_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SALARY_BY_EMPLOYEE_ERROR });
  }
};

export const getSalaryById = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({ type: GET_SALARY_BY_ID_PENDING });

    const res = await API.getSalaryById(id);
    dispatch({
      type: GET_SALARY_BY_ID_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    }

    dispatch({ type: GET_SALARY_BY_ID_ERROR });
  }
};

export const setModalAddContract = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_ADD_CONTRACT,
    payload: { data: index }
  });
};
export const setModalEditContract = (index: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_EDIT_CONTRACT,
    payload: { data: index }
  });
};
export const setModalAddSalary = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_ADD_SALARY,
    payload: { data: index }
  });
};
export const setModalEditSalary = (index: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: SET_MODAL_EDIT_SALARY,
    payload: { data: index }
  });
};
export const postAddContract = (body: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_ADD_CONTRACT_PENDING });
    const formData = new FormData();
    formData.append("number", body.number);
    formData.append("started_at", body.started_at);
    formData.append("finished_at", body.finished_at);
    formData.append("remarks", body.remarks);
    formData.append("employee_id", body.employee_id);
    formData.append("active", body.active);

    // eslint-disable-next-line no-prototype-builtins
    if (body.hasOwnProperty("files")) {
      body.files.map((item: any, index: number) =>
        formData.append(`files[${String(index)}]`, item)
      );
    }

    const res = await API.postEmployeeContract(formData);
    dispatch({
      type: POST_ADD_CONTRACT_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Data Stored", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_ADD_CONTRACT_ERROR });
  }
};

export const putContract = (body: any, id: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: PUT_CONTRACT_PENDING });
    const formData = new FormData();
    formData.append("number", body.number);
    formData.append("started_at", body.started_at);
    formData.append("finished_at", body.finished_at);
    formData.append("remarks", body.remarks);
    formData.append("employee_id", body.employee_id);
    formData.append("active", body.active);

    if (body.delete_file.length > 0) {
      formData.append("delete_files", body.delete_file);
    }

    // eslint-disable-next-line no-prototype-builtins
    if (body.hasOwnProperty("files")) {
      body.files.map((item: any, index: number) =>
        formData.append(`files[${String(index)}]`, item)
      );
    }

    const res = await API.putEmployeeContract(formData, id);
    dispatch({
      type: PUT_CONTRACT_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Data Stored", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 2200);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: PUT_CONTRACT_ERROR });
  }
};

export const postAddSalary = (body: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: POST_ADD_SALARY_PENDING });

    const res = await API.postEmployeeSalary(body);

    dispatch({
      type: POST_ADD_SALARY_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Data Stored", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: POST_ADD_SALARY_ERROR });
  }
};

export const putSalary = (body: any, id: any, cb: () => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: PUT_SALARY_PENDING });

    const res = await API.putEmployeeSalary(body, id);

    dispatch({
      type: PUT_SALARY_SUCCESS,
      payload: { data: res.data.data }
    });
    toast("Data Stored", {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      transition: Slide,
      className: "assign-task-toast",
      closeButton: false,
      draggable: false
    });
    setTimeout(() => {
      cb();
    }, 600);
  } catch (err) {
    const error = err.response;
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else {
      toast(error.data.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        transition: Slide,
        className: "assign-task-toast assign-red",
        closeButton: false,
        draggable: false
      });
    }

    dispatch({ type: PUT_SALARY_ERROR });
  }
};

export const deleteContract = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_CONTRACT_PENDING });

    const res = await API.deleteContract(id);
    dispatch({
      type: DELETE_CONTRACT_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else if (err.response && err.response.status === 409) {
      cb(err.response.data.message);
    }
    dispatch({ type: DELETE_CONTRACT_ERROR });
  }
};

export const deleteSalary = (id: string, cb: (e: any) => void) => async (
  dispatch: Dispatch
) => {
  try {
    dispatch({ type: DELETE_SALARY_PENDING });

    const res = await API.deleteSalary(id);
    dispatch({
      type: DELETE_SALARY_SUCCESS,
      payload: { data: res.data.data }
    });
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(handleLogout());
    } else if (err.response && err.response.status === 409) {
      cb(err.response.data.message);
    }
    dispatch({ type: DELETE_SALARY_ERROR });
  }
};

export const setModalGenerateLoanProfile = (index: boolean, isall: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({
    type: SET_MODAL_GENERATE_LOAN_PROFILE,
    payload: { data: index, value: isall }
  });
};
