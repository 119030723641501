import { combineReducers } from "redux";

import home from "./home";
import report from "./report";
import config from "./config";
import walkthrough from "./walkthrough";
import menu from "./menu";
import profileById from "./profile";
import auth from "./auth";
import generate from "./generate";
import policy from "./policy";
import company from "./company";
import employee from "./employee";
import companytree from "./companytree";
import division from "./division";
import task from "./task";
import loan from "./loan";
import deletedata from "./deletedata";
import setting from "./setting";
import masterdata from "./masterdata";

export default combineReducers({
  home,
  report,
  config,
  walkthrough,
  menu,
  profileById,
  auth,
  generate,
  policy,
  company,
  employee,
  companytree,
  division,
  task,
  loan,
  deletedata,
  setting,
  masterdata
});
