import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { Button, Text } from "../../../../../components";
import "./styles.scss";

interface Props {
  name: string;
  hapus?: boolean;
  primary?: boolean;
  value?: string;
  subValue?: string;
  isAccount?: boolean;
  data?: any;
  onDeleteAccount?: () => void;
  onDeleteAddress?: () => void;
  onPrimaryAddress?: () => void;
  onPrimaryAccount?: () => void;
  dataAccount?: any;
  disabled?: boolean;
}

const CardCompany = ({
  data,
  primary,
  dataAccount,
  isAccount,
  onDeleteAccount,
  onDeleteAddress,
  onPrimaryAddress,
  onPrimaryAccount,
  disabled
}: Props) => {
  const { t } = useTranslation();
  const _handlePrimary = useCallback(() => {
    if (!disabled) {
      if (isAccount) {
        onPrimaryAccount && onPrimaryAccount();
      } else {
        onPrimaryAddress && onPrimaryAddress();
      }
    }
  }, [disabled, isAccount, onPrimaryAccount, onPrimaryAddress]);

  return (
    <div className="dashboard-company-card-container shadow">
      <div className="dashboard-company-card-button-container">
        <Text
          style={{
            paddingBottom: 10,
            fontSize: "1.2rem",
            whiteSpace: "nowrap"
          }}
        >
          {dataAccount.method?.title}
        </Text>
        <div
          className="dashboard-company-card-button-container"
          style={{ marginLeft: "auto" }}
        >
          {!disabled && (
            <div style={{ paddingRight: "0.4rem" }}>
              <Button
                onPress={isAccount ? onDeleteAccount : onDeleteAddress}
                disabled={primary}
              >
                <div className={`button small ${primary ? "alt" : ""}`}>
                  {t("btn.delete")}
                </div>
              </Button>
            </div>
          )}
          {(!disabled || primary) && (
            <div>
              <Button
                className={`button small hollow ${primary ? "" : "alt"}`}
                onPress={_handlePrimary}
              >
                {primary ? t("btn.primary") : t("btn.makePrimary")}
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isAccount ? (
        <div>
          <div className="alias">{data.alias}</div>
          <div className="address">
            {!isAccount ? `${data.line_1}. ` : "Hello"}

            {data.line_2 === null ? "" : data.line_2}

            {!isAccount ? `${data.city.name}. ` : "Hello"}

            {!isAccount ? `${data.zipcode}. ` : "Hello"}
          </div>
          <div className="phone-fax">
            phone.
            {!isAccount ? `${data.phone}. ` : null}
            Fax:
            {data.fax === null ? "" : data.fax}
          </div>
        </div>
      ) : (
        <div>
          <div className="name-branch">{dataAccount.title}</div>
          <div className="account-no">{dataAccount.account_number}</div>
          <div className="coa">{dataAccount.code}</div>
        </div>
      )}
    </div>
  );
};

CardCompany.defaultProps = {
  disabled: false
};

export default CardCompany;
