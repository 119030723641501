import React, { memo, useEffect, useState } from "react";
import Switch from "react-switch";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import { Toast } from "../index";
import Modal from "../Modal";
import "./styles.scss";

// eslint-disable-next-line import/order
import { useTranslation } from "react-i18next";
import { Reducers } from "../../redux/types";
import Spinner from "../Spinner";
import {
  getListParentCategory,
  getListUnit,
  isProduct
} from "../../redux/actions/master_data";

interface Props {
  onPressSubmit?: (data, id: any) => void;
  onPressCancel?: () => void;
  isOpen: boolean;
  value?: string;
  onChange?: (e: any) => void;
  title?: string;
}

const ModalExpenseType = ({
  onPressCancel,
  onPressSubmit,
  isOpen,
  title
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const masterState = useSelector((state: Reducers) => state.masterdata);

  const [_form, _setForm] = useState({
    category_id: null,
    title: null,
    parent_id: null,
    code: null,
    description: null,
    hidden: false,
    unit_id: null,
    temporary_unit_id: null,
    direct_item: false,
    client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  });

  const [error, setError] = useState(false);

  useEffect(() => {
    _setForm({
      category_id: masterState.data ? masterState.data.id : null,
      title: masterState.data ? masterState.data.title : null,
      parent_id:
        masterState.data && masterState.data.parent
          ? masterState.data.parent.id
          : null,
      code: masterState.data ? masterState.data.code : null,
      description: masterState.data ? masterState.data.description : null,
      hidden: masterState.data ? masterState.data.hidden : false,
      unit_id:
        masterState.data && masterState.data.unit
          ? masterState.data.unit.id
          : null,
      temporary_unit_id:
        masterState.data && masterState.data.unit
          ? masterState.data.unit.id
          : null,
      direct_item:
        masterState.data && masterState.data.direct_item
          ? masterState.data.direct_item
          : false,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });

    dispatch(getListUnit());
    dispatch(getListParentCategory());
  }, [_setForm, dispatch, masterState.data]);

  const __submited = __callback => {
    const id = masterState.data ? masterState.data.id : null;
    if (!_form.title) {
      setError(true);
      Toast({
        message: t("master_data.validation.title_is_required"),
        is_success: false
      });
      return;
    }
    __callback(id, _form);

    _setForm({
      category_id: null,
      title: null,
      parent_id: null,
      code: null,
      description: null,
      hidden: false,
      unit_id: null,
      temporary_unit_id: null,
      direct_item: false,
      client_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    setError(false);
  };

  const __closedModal = __callback => {
    setError(false);
    __callback();
  };

  const is_hide = () => {
    if (!_form.hidden) {
      _setForm({ ..._form, hidden: true });
    } else {
      _setForm({ ..._form, hidden: false });
    }
  };

  const is_product = () => {
    if (masterState.is_product) {
      dispatch(isProduct(false));
      _setForm({ ..._form, unit_id: null });
    } else {
      dispatch(isProduct(true));
      _setForm({ ..._form, unit_id: _form.temporary_unit_id });
    }
  };

  const is_direct_item = () => {
    if (!_form.direct_item) {
      _setForm({ ..._form, direct_item: true });
    } else {
      _setForm({ ..._form, direct_item: false });
    }
  };

  const setTitle = e => {
    _setForm({ ..._form, title: e.target.value });
  };

  const setCode = e => {
    _setForm({ ..._form, code: e.target.value });
  };

  const setDescription = e => {
    _setForm({ ..._form, description: e.target.value });
  };

  const setParentCategory = options => {
    _setForm({ ..._form, parent_id: options.id });
  };

  const setUnit = e => {
    _setForm({
      ..._form,
      unit_id: e.target.value,
      temporary_unit_id: e.target.value
    });
  };

  return (
    <Modal
      titleModal={title}
      isOpen={isOpen}
      onPressCancel={() => __closedModal(onPressCancel)}
      onPressSubmit={() => __submited(onPressSubmit)}
      btnSubmitText={t("master_data.submit").toUpperCase()}
      btnCancelText={t("deleteData.cancel").toUpperCase()}
    >
      <div style={{ width: "550px" }}>
        {masterState.is_loading ? (
          <div style={{ textAlign: "center" }}>
            <Spinner />
          </div>
        ) : (
          <form style={{ width: "100%" }}>
            <div>
              <span className="component-modal-request-task-label">
                {t("master_data.form.title").toUpperCase()}
              </span>
              <input
                onKeyUp={setTitle}
                type="text"
                defaultValue={masterState.data ? masterState.data.title : null}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottom: "1px solid lightgrey"
                }}
              />
              <span style={{ color: "red" }}>
                {(!_form.title ||
                  (masterState.data && !masterState.data.title)) &&
                error
                  ? t("master_data.validation.title_is_required")
                  : ""}
              </span>
            </div>
            <div style={{ marginTop: 20 }}>
              <span className="component-modal-request-task-label">
                {t("master_data.form.parent_category").toUpperCase()}
              </span>

              <div
                style={{
                  width: "100%",
                  height: 35
                }}
              >
                <Select
                  onChange={setParentCategory}
                  style={{
                    border: "1px solid red"
                  }}
                  aria-labelledby="aria-label"
                  inputId="aria-example-input"
                  name="aria-live-color"
                  defaultValue={[
                    {
                      value:
                        masterState.data && masterState.data.parent
                          ? masterState.data.parent.id
                          : null,
                      label:
                        masterState.data && masterState.data.parent
                          ? masterState.data.parent.title
                          : null
                    }
                  ]}
                  options={masterState.list_parent_category}
                />
              </div>
            </div>
            <div style={{ marginTop: 20 }}>
              <span className="component-modal-request-task-label">
                {t("COA")}
              </span>
              <input
                type="text"
                onKeyUp={setCode}
                defaultValue={masterState.data ? masterState.data.code : null}
                style={{
                  width: "100%",
                  height: 35,
                  borderBottom: "1px solid lightgrey"
                }}
              />
            </div>
            <div style={{ marginTop: 20 }}>
              <span className="component-modal-request-task-label">
                {t("master_data.form.description").toUpperCase()}
              </span>
              <textarea
                onKeyUp={setDescription}
                defaultValue={
                  masterState.data && masterState.data.description !== "null"
                    ? masterState.data.description
                    : null
                }
                rows={10}
                style={{
                  fontFamily: "Poppins",
                  fontSize: "1.6rem",
                  border: "none",
                  width: "100%",
                  borderBottom: "1px solid lightgrey"
                }}
              />
            </div>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                width: "100%"
              }}
            >
              <div style={{ width: "70%" }}>
                <span className="component-modal-request-task-label">
                  {`${t("master_data.hidden").toUpperCase()} ?`}
                </span>
                <div style={{ marginTop: 5 }}>
                  <Switch
                    onChange={is_hide}
                    checked={_form.hidden}
                    onColor="#ff7600"
                    offColor="#b5b5b5"
                    onHandleColor="#fffff7"
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={27}
                    width={55}
                    tabIndex={0}
                  />
                </div>
              </div>

              <div style={{ width: "30%" }}>
                <span className="component-modal-request-task-label">
                  {`${t("master_data.form.product").toUpperCase()} ?`}
                </span>
                <div style={{ marginTop: 5 }}>
                  <Switch
                    onChange={is_product}
                    checked={masterState.is_product}
                    onColor="#ff7600"
                    offColor="#b5b5b5"
                    onHandleColor="#fffff7"
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={27}
                    width={55}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                display: masterState.is_product ? "flex" : "none",
                width: "100%"
              }}
            >
              <div style={{ width: "70%" }}>
                <span className="component-modal-request-task-label">
                  {`${t("master_data.form.unit").toUpperCase()}`}
                </span>
                <div style={{ marginTop: 5 }}>
                  <select
                    onChange={setUnit}
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "1.6rem",
                      width: "80%",
                      height: 35,
                      border: "none",
                      borderBottom: "1px solid lightgrey"
                    }}
                  >
                    {masterState.list_unit &&
                      masterState.list_unit.length &&
                      masterState.list_unit.map((__value, index) => (
                        <option
                          selected={
                            !!(
                              masterState.data &&
                              masterState.data.unit &&
                              masterState.data.unit.id === __value.id
                            )
                          }
                          key={index}
                          value={__value.id}
                        >
                          {`(${__value.abbv == null ? "" : __value.abbv}) ${
                            __value.title
                          }`}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div style={{ width: "30%" }}>
                <span className="component-modal-request-task-label">
                  {`${t("master_data.form.direct_item").toUpperCase()}`}
                </span>
                <div style={{ marginTop: 5 }}>
                  <Switch
                    onChange={is_direct_item}
                    checked={_form.direct_item}
                    onColor="#ff7600"
                    offColor="#b5b5b5"
                    onHandleColor="#fffff7"
                    handleDiameter={25}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    height={27}
                    width={55}
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
};

export default memo(ModalExpenseType);
