import React from "react";

interface Props {
  value?: string;
  onChange?: (e: any) => void;
}

const DatePicker = ({ value, onChange }: Props) => {
  return (
    <div>
      <input
        placeholder="DD/MM/YYYY"
        type="date"
        onChange={onChange}
        value={value}
        className="report-component-datepicker-input"
      />
    </div>
  );
};

export default DatePicker;
