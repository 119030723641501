import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

const ChatIcon = () => {
  const configState = useSelector((state: Reducers) => state.config);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.157"
      height="22.157"
      viewBox="0 0 22.157 22.157"
    >
      <g id="prefix__chat" transform="translate(.151 .15)">
        <g id="prefix__Group_1457" data-name="Group 1457">
          <path
            id="prefix__Path_2051"
            d="M18.667 3.2A10.926 10.926 0 002.526 17.9a4.181 4.181 0 01-1.751 1.938 1.025 1.025 0 00.3 1.932 5.647 5.647 0 00.864.065 6.524 6.524 0 003.845-1.275A10.925 10.925 0 0018.667 3.2zm-.883 14.57a9.678 9.678 0 01-11.746 1.509.621.621 0 00-.758.1.205.205 0 00-.051.037 5.335 5.335 0 01-3.295 1.174 6.118 6.118 0 001.872-2.49.638.638 0 00.032-.4.649.649 0 00-.152-.351 9.679 9.679 0 1114.1.43z"
            fill={configState.isDark ? "#fff" : "#666"}
            data-name="Path 2051"
            transform="translate(-.019)"
          />
          <circle
            id="prefix__Ellipse_65"
            cx=".778"
            cy=".778"
            r=".778"
            fill={configState.isDark ? "#fff" : "#666"}
            stroke={configState.isDark ? "#fff" : "#666"}
            strokeWidth={0.3}
            data-name="Ellipse 65"
            transform="translate(10.144 10.151)"
          />
          <circle
            id="prefix__Ellipse_66"
            cx=".778"
            cy=".778"
            r=".778"
            fill={configState.isDark ? "#fff" : "#666"}
            stroke={configState.isDark ? "#fff" : "#666"}
            strokeWidth={0.3}
            data-name="Ellipse 66"
            transform="translate(14.095 10.151)"
          />
          <circle
            id="prefix__Ellipse_67"
            cx=".778"
            cy=".778"
            r=".778"
            fill={configState.isDark ? "#fff" : "#666"}
            stroke={configState.isDark ? "#fff" : "#666"}
            strokeWidth={0.3}
            data-name="Ellipse 67"
            transform="translate(6.193 10.151)"
          />
        </g>
      </g>
    </svg>
  );
};

export default memo(ChatIcon);
