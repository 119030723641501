import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";

import { SideBar, TopBar, WrapApp, WrapContent } from "../../components";
import { getMenu, handleTheme } from "../../redux/actions";
import { Reducers } from "../../redux/types";

// screens
import Home from "./pages/Home";

const App = () => {
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const getLanguage = Cookies.get("locale") || "en";
  const listPage = [
    {
      path: `${path}`,
      component: (
        <div className="page-dashboard">
          <Home />
        </div>
      ),
      exact: true
    },
    {
      path: `${path}/user/:uuid`,
      component: (
        <div className="page-dashboard-user">
          <Home type="user" />
        </div>
      ),
      exact: true
    },
    {
      path: `${path}/project/:uuid`,
      component: (
        <div className="page-dashboard-project">
          <Home type="project" />
        </div>
      ),
      exact: true
    },
    {
      path: `${path}/department/:uuid`,
      component: (
        <div className="page-dashboard-user">
          <Home type="department" />
        </div>
      ),
      exact: true
    }
  ];

  const menuState = useSelector((state: Reducers) => state.menu);

  useEffect(() => {
    dispatch(getMenu("dashboard", getLanguage));
    dispatch(handleTheme("dark"));
  }, [dispatch, getLanguage]);

  return (
    <Switch>
      <WrapApp className="dashboard">
        <SideBar show={!menuState.collapsible} />
        <TopBar />
        <WrapContent>
          {listPage.map((item, index) => (
            <Route path={item.path} exact={item.exact} key={index}>
              {item.component}
            </Route>
          ))}
        </WrapContent>
      </WrapApp>
    </Switch>
  );
};

export default App;
