import React, { memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import "./styles.scss";
import Modal from "../Modal";
import { Text } from "..";
import { changePassword } from "../../redux/actions";
import { Reducers } from "../../redux/types";
import { IMAGES } from "../../configs";

interface Props {
  isOpen: boolean;
  setModal: (e: any) => void;
}

const ModalPassword = ({ isOpen, setModal }: Props) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, errors, reset } = useForm();

  const authState = useSelector((state: Reducers) => state.auth);

  const [visibleOldPass, setVisibleOldPass] = useState(false);
  const [visibleNewPass, setVisibleNewPass] = useState(false);
  const [visibleRetypePass, setVisibleRetypePass] = useState(false);

  const _onCancel = useCallback(() => {
    setModal(false);
    setVisibleNewPass(false);
    setVisibleOldPass(false);
    setVisibleRetypePass(false);
    reset();
  }, [reset, setModal]);

  const _handleSubmit = useCallback(
    form => {
      dispatch(changePassword(form, _onCancel));
    },
    [_onCancel, dispatch]
  );

  const _handleFormValidation = useCallback(
    (isShowError: boolean, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  return (
    <Modal
      onPressSubmit={handleSubmit(_handleSubmit)}
      titleModal="Change Password"
      isOpen={isOpen}
      onPressCancel={_onCancel}
      btnSubmitText="SAVE"
    >
      <div style={{ width: 380 }}>
        <form onSubmit={handleSubmit(_handleSubmit)}>
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            {_handleFormValidation(
              authState.changePassword.message !== "",
              authState.changePassword.message
            )}
          </div>

          <div style={{ marginBottom: 16, fontWeight: "bold" }}>
            Old Password
          </div>
          <div className="modal-pass-wrap-eye">
            <input
              className="component-modal-input"
              type={`${visibleOldPass ? "text" : "password"}`}
              name="password1"
              ref={register({ required: true })}
            />
            <div
              className="modal-pass-eye-underline"
              onClick={() => setVisibleOldPass(!visibleOldPass)}
            >
              <img
                alt="n/a"
                src={visibleOldPass ? IMAGES.eyeOff : IMAGES.eye}
                style={{ height: 24, marginRight: 2 }}
              />
            </div>
          </div>
          {_handleFormValidation(errors.password1, "Wrong password")}
          <div style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}>
            New Password
          </div>
          <div className="modal-pass-wrap-eye">
            <input
              className="component-modal-input"
              type={`${visibleNewPass ? "text" : "password"}`}
              name="password2"
              ref={register({ required: true, minLength: 6 })}
            />
            <div
              className="modal-pass-eye-underline"
              onClick={() => setVisibleNewPass(!visibleNewPass)}
            >
              <img
                alt="n/a"
                src={visibleNewPass ? IMAGES.eyeOff : IMAGES.eye}
                style={{ height: 24, marginRight: 2 }}
              />
            </div>
          </div>
          {_handleFormValidation(errors.password2, "Min length 6")}
          <div style={{ marginBottom: 16, marginTop: 16, fontWeight: "bold" }}>
            Re-type Password
          </div>
          <div className="modal-pass-wrap-eye">
            <input
              className="component-modal-input"
              type={`${visibleRetypePass ? "text" : "password"}`}
              name="password3"
              ref={register({
                required: true,
                validate: value => value === getValues("password2")
              })}
            />
            <div
              className="modal-pass-eye-underline"
              onClick={() => setVisibleRetypePass(!visibleRetypePass)}
            >
              <img
                alt="n/a"
                src={visibleRetypePass ? IMAGES.eyeOff : IMAGES.eye}
                style={{ height: 24, marginRight: 2 }}
              />
            </div>
          </div>
          <input type="submit" hidden />
          {_handleFormValidation(errors.password3, "Invalid")}
        </form>
      </div>
    </Modal>
  );
};

export default memo(ModalPassword);
