import React from "react";
import Spinner from "../../../../components/Spinner";

const MessageInfo = props => {
  // eslint-disable-next-line react/prop-types
  const { is_loading, _message, translate } = props;
  return (
    <>
      {is_loading ? (
        <div style={{ textAlign: "center" }}>
          <Spinner />
        </div>
      ) : (
        <p style={{ color: "red", textAlign: "center" }}>
          {_message != null
            ? translate(
                // eslint-disable-next-line react/prop-types
                `deleteData.${_message.toLowerCase().replace(/\s+/g, "_")}`
              )
            : ""}
        </p>
      )}
    </>
  );
};

export default MessageInfo;
