import { Dispatch } from "../types";
import { API } from "../../configs";

export const GET_DIVISION_PENDING = "GET_DIVISION_PENDING";
export const GET_DIVISION_SUCCESS = "GET_DIVISION_SUCCESS";
export const GET_DIVISION_ERROR = "GET_DIVISION_ERROR";

export const POST_DIVISION_PENDING = "POST_DIVISION_PENDING";
export const POST_DIVISION_SUCCESS = "POST_DIVISION_SUCCESS";
export const POST_DIVISION_ERROR = "POST_DIVISION_ERROR";
export const POST_DIVISION_RESET = "POST_DIVISION_RESET";

export const PUT_DIVISION_PENDING = "PUT_DIVISION_PENDING";
export const PUT_DIVISION_SUCCESS = "PUT_DIVISION_SUCCESS";
export const PUT_DIVISION_ERROR = "PUT_DIVISION_ERROR";
export const PUT_DIVISION_RESET = "PUT_DIVISION_RESET";

export const DELETE_DIVISION_PENDING = "DELETE_DIVISION_PENDING";
export const DELETE_DIVISION_SUCCESS = "DELETE_DIVISION_SUCCESS";
export const DELETE_DIVISION_ERROR = "DELETE_DIVISION_ERROR";
export const DELETE_DIVISION_RESET = "DELETE_DIVISION_RESET";

export const getDivision = (params: any) => (dispatch: Dispatch) => {
  let str = "";
  Object.keys(params).forEach((key: any) => {
    if (params[key]) {
      str = `${str}${key}=${params[key]}&`;
    }
  });
  dispatch({ type: GET_DIVISION_PENDING });
  API.getDivision(str)
    .then(res => {
      dispatch({ type: GET_DIVISION_SUCCESS, payload: res });
    })
    .catch(err => {
      if (typeof err.response.data !== "undefined") {
        dispatch({ type: GET_DIVISION_ERROR, payload: err.response.data });
      } else {
        dispatch({
          type: GET_DIVISION_ERROR,
          payload: err.response.statusText
        });
      }
    });
};

export const postDivision = (form: any) => (dispatch: Dispatch) => {
  dispatch({ type: POST_DIVISION_PENDING });
  API.postDivision(form)
    .then(res => {
      dispatch({ type: POST_DIVISION_SUCCESS, payload: res });
    })
    .catch(err => {
      if (typeof err.response.data !== "undefined") {
        dispatch({ type: POST_DIVISION_ERROR, payload: err.response.data });
      } else {
        dispatch({
          type: POST_DIVISION_ERROR,
          payload: err.response.statusText
        });
      }
    });
};

export const resetPostDivision = () => (dispatch: Dispatch) => {
  dispatch({ type: POST_DIVISION_RESET });
};

export const putDivision = (form: any) => (dispatch: Dispatch) => {
  dispatch({ type: PUT_DIVISION_PENDING });
  API.putDivision(form, form.uuid)
    .then(res => {
      dispatch({ type: PUT_DIVISION_SUCCESS, payload: res });
    })
    .catch(err => {
      if (typeof err.response.data !== "undefined") {
        dispatch({ type: PUT_DIVISION_ERROR, payload: err.response.data });
      } else {
        dispatch({
          type: PUT_DIVISION_ERROR,
          payload: err.response.statusText
        });
      }
    });
};

export const resetPutDivision = () => (dispatch: Dispatch) => {
  dispatch({ type: PUT_DIVISION_RESET });
};

export const deleteDivision = (id: any) => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_DIVISION_PENDING });
  API.deleteDivision(id)
    .then(res => {
      dispatch({ type: DELETE_DIVISION_SUCCESS, payload: res });
    })
    .catch(err => {
      if (typeof err.response.data !== "undefined") {
        dispatch({ type: DELETE_DIVISION_ERROR, payload: err.response.data });
      } else {
        dispatch({
          type: DELETE_DIVISION_ERROR,
          payload: err.response.statusText
        });
      }
    });
};

export const resetDeleteDivision = () => (dispatch: Dispatch) => {
  dispatch({ type: DELETE_DIVISION_RESET });
};
