import React, { memo } from "react";
import { useSelector } from "react-redux";

import { Reducers } from "../../redux/types";

const NotificationIcon = () => {
  const configState = useSelector((state: Reducers) => state.config);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.28"
      height="22.834"
      viewBox="0 0 18.28 22.834"
    >
      <path
        id="ic_notifications_24px"
        data-name="ic notifications 24px"
        d="M532.39,268.178a2.1,2.1,0,0,0,2.1-2.1h-4.2a2.1,2.1,0,0,0,2.1,2.1ZM538.683,261v-5.243c0-3.22-1.72-5.917-4.72-6.628v-.714a1.573,1.573,0,1,0-3.146,0v.713c-3.01.717-4.72,3.4-4.72,6.629V261l-2.1,2.1v1.049h16.78V263.1Z"
        transform="translate(-523.25 -246.094)"
        fill="none"
        stroke={configState.isDark ? "#fff" : "#666"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default memo(NotificationIcon);
