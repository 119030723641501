import { Action } from "../types";

const initialState: any = {
  success: null,
  data: null,
  message: null,
  success_delete: false,
  message_delete: null
};

const deletedata = (state = initialState, { type, payload }: Action) => {
  switch (type) {
    case "GET_DATA":
      return {
        ...state,
        success: payload.data.success,
        data: payload.data.data,
        message: payload.data.message,
        success_delete: false,
        message_delete: null
      };
    case "DELETE_DATA":
      return {
        ...state,
        success_delete: payload.data.success,
        message_delete: payload.data.message
      };
    default:
      return state;
  }
};

export default deletedata;
