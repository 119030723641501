import { HANDLE_THEME } from "../actions";
import { Action, ConfigState } from "../types";

const initialState: ConfigState = {
  isDark: true
};

export default (state = initialState, { type, payload }: Action) => {
  switch (type) {
    // handle theme
    case HANDLE_THEME:
      return { ...state, isDark: payload.data };

    default:
      return state;
  }
};
