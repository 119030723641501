/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-var-requires */
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import {
  deleteAccount,
  getAllBank,
  getCompanyDetail,
  getMyPermission,
  getPaymentMethode,
  getRegency,
  handleTheme,
  postCompanies,
  setImageCompany,
  setPrimaryBank
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import {
  Autocomplete,
  Button,
  Container,
  Footer,
  HeaderTittle,
  Text
} from "../../../components";
import { Card, CardForm } from "./component";
import { ICONS, IMAGES } from "../../../configs";
import "./styles.scss";
import "../../../sass/styles.scss";
import { copyWritings, documentTitle } from "../../../utils";
import { Navigation } from "../components/Navigation";

const Company = () => {
  // const Address: any = {
  //   index: 0,
  //   alias: "",
  //   line_1: "",
  //   line_2: "",
  //   city: "",
  //   zicode: "",
  //   phone: "",
  //   fax: ""
  // };
  const { t } = useTranslation();
  const { sprintf } = require("sprintf-js");
  const Account: any = {
    index: 0,
    account_name: "",
    bank_name: "",
    account_number: "",
    branch: "",
    title: "",
    coa: "",
    bank_id: "",
    payment_id: "",
    payment_name: ""
  };
  // const initAddress: any = [];
  const initAccount: any = [];
  const labelCompany = copyWritings("company");

  documentTitle("Company - Detail");
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  // const [addAddress, setAddress] = useState(initAddress);
  // const [uui, setId] = useState("");
  const [addAccount, setAccount] = useState(initAccount);
  const initialState = {
    companyName: "",
    companyEmail: "",
    alias: "",
    address: "",
    zipCode: "",
    phoneNo: "",
    faxNo: "",
    bankId: "",
    img: "",
    regencyId: "",
    cityName: ""
  };
  const [onChangeData, setOnchangeData] = useState(initialState);
  const [dataPayment, setDataPayment] = useState([]);
  const [first, setFirst] = useState(true);
  const [validateRegency, setValidateRegency] = useState(false);
  const [validateAccount, setValidateAccount] = useState(false);
  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] = useState(false);

  const { companyState, walkthroughState, authState } = useSelector(
    (state: Reducers) => ({
      companyState: state.company,
      walkthroughState: state.walkthrough,
      authState: state.auth
    }),
    shallowEqual
  );

  const _checkPermissionEdit = useCallback(
    () => authState.myPermission.list.includes("company-self-edit"),
    [authState.myPermission.list]
  );

  useEffect(() => {
    if (first) {
      dispatch(handleTheme("light"));
      dispatch(getRegency());
      dispatch(getAllBank());
      // dispatch(getMyPermission());
      dispatch(
        getCompanyDetail(e => {
          return e;
        })
      );
      setFirst(false);
    }
  }, [dispatch, first]);

  useEffect(() => {
    dispatch(getMyPermission());
  }, [dispatch]);

  const _handleRevert = useCallback(() => {
    dispatch(getRegency());
    dispatch(getAllBank());
    dispatch(
      getCompanyDetail(e => {
        return e;
      })
    );

    if (!companyState.isLoadingPostCompanies) {
      dispatch(
        getCompanyDetail(e => {
          return e;
        })
      );
    }
  }, [dispatch, companyState.isLoadingPostCompanies]);

  const _city = useCallback(() => {
    let result: any[] = [];
    walkthroughState.listRegency.data.map((e: any) => {
      result = [...result, { value: e.id, label: e.attributes.name }];
      return true;
    });
    return result;
  }, [walkthroughState.listRegency.data]);

  const bankPopulate = companyState.listBank.map((e: any) => {
    return { value: e.id, label: e.attributes.title };
  });

  // const _handleAdress = () => {
  //   setAddress([...addAddress, { ...Address, index: addAddress.length }]);
  // };

  // const _handleRemoveAdress = (idx: number) => {
  //   setAddress(
  //     addAddress.filter((e: any) => {
  //       return e.index !== idx;
  //     })
  //   );
  // };

  const _handleAccount = () => {
    setValidateAccount(false);
    setAccount([...addAccount, { ...Account, index: addAccount.length }]);
    dispatch(
      getPaymentMethode(e => {
        setDataPayment(e);
        setAccount([
          ...addAccount,
          { ...Account, payment_id: e[0].value, payment_name: e[0].label }
        ]);
      })
    );
  };

  const _handleRemoveAccount = (idx: any) => {
    setIsLoadingDeleteAccount(true);
    setValidateAccount(false);
    setAccount(addAccount.filter((s: any, sIdx: number) => sIdx !== idx));
    // console.log(addAccount.filter((s: any, sIdx: number) => sIdx !== idx));
    setTimeout(() => {
      setIsLoadingDeleteAccount(false);
    }, 300);
  };

  // const indexForm =
  //   companyState.loadCompanyDetail.addresses &&
  //   companyState.loadCompanyDetail.addresses.length;

  const _updateCompany = useCallback(
    async (form: any) => {
      let check = true;
      let checkCash = true;
      if (addAccount.length > 0) {
        addAccount.map(formObject => {
          if (formObject.payment_name === "Bank") {
            Object.values(formObject).map((e, i) => {
              if (
                Object.keys(formObject)[i] !== "index" &&
                Object.keys(formObject)[i] !== "bank_name"
              ) {
                if (e === "") {
                  check = false;
                }
              }

              return true;
            });
          } else {
            Object.values(formObject).map((e, i) => {
              if (
                Object.keys(formObject)[i] === "title" ||
                Object.keys(formObject)[i] === "coa"
              ) {
                if (e === "") {
                  checkCash = false;
                }
              }

              return true;
            });
          }

          return true;
        });
      }

      if (
        !check ||
        !checkCash ||
        (onChangeData.regencyId === "" &&
          !(
            companyState.loadCompanyDetail.branches[0].branchAddress.length >
              0 &&
            companyState.loadCompanyDetail.branches[0].branchAddress[0]
              .address &&
            companyState.loadCompanyDetail.branches[0].branchAddress[0].address
              .regency.name
          ))
      ) {
        if (!check || !checkCash) {
          setValidateAccount(true);
        } else {
          setValidateRegency(true);
        }
      } else {
        setValidateAccount(false);
        setValidateRegency(false);
        const formData = {
          name: form.companyName,
          company_account: form.companyEmail,
          address: form.address,
          zipCode: form.zipCode,
          phoneNo: form.phoneNo,
          faxNo: form.faxNo,
          regencyId:
            onChangeData.regencyId !== ""
              ? onChangeData.regencyId
              : companyState.loadCompanyDetail.branches[0].branchAddress
                  .length > 0 &&
                companyState.loadCompanyDetail.branches[0].branchAddress[0]
                  .address &&
                companyState.loadCompanyDetail.branches[0].branchAddress[0]
                  .address.regency.id,
          img: companyState.imageString,
          id: companyState.loadCompanyDetail.branches[0].id,
          addressId:
            (companyState.loadCompanyDetail.branches[0].branchAddress.length >
              0 &&
              companyState.loadCompanyDetail.branches[0].branchAddress[0]
                .address &&
              companyState.loadCompanyDetail.branches[0].branchAddress[0]
                .address.id) ||
            null,
          account: addAccount
        };
        dispatch(postCompanies(formData, companyState.loadCompanyDetail.id));
        setAccount(initAccount);
      }
    },
    [
      dispatch,
      addAccount,
      companyState.imageString,
      companyState.loadCompanyDetail,
      initAccount,
      onChangeData.regencyId
    ]
  );

  const _setPrimaryBank = useCallback(
    (idBank: string) => {
      dispatch(setPrimaryBank(idBank));
    },
    [dispatch]
  );

  const _deleteBank = useCallback(
    (idBank: string) => {
      dispatch(deleteAccount(idBank));
    },
    [dispatch]
  );

  const _handleFormValidation = useCallback(
    (isShowError: boolean, errorText: string) => {
      return isShowError ? (
        <Text className="walktrough-form-company-text-warning">
          {errorText}
        </Text>
      ) : null;
    },
    []
  );

  return (
    <>
      <Container scroll className="page-company-detail" withMaxWidth>
        <HeaderTittle title={labelCompany} />
        <Navigation />
        <Container
          isLoading={
            companyState.isLoading ||
            companyState.isLoadingPostCompanies ||
            companyState.isLoadingSetPrimaryBank ||
            companyState.isLoadCompanyDetail
          }
          typeLoading="modal"
        >
          <form
            onSubmit={handleSubmit(_updateCompany)}
            className="form-company"
          >
            <div className="columns dashboard-company-container-card shadow is-8r">
              <div className="column">
                <div className="component-upload shadow">
                  <input
                    type="file"
                    onChange={e =>
                      dispatch(setImageCompany(e.target.files![0]))
                    }
                    disabled={!_checkPermissionEdit()}
                  />
                  <img src={IMAGES.pencil} alt="edit" className="img-edit" />
                  <img
                    src={
                      companyState.imageString ||
                      (companyState.loadCompanyDetail.logo &&
                        companyState.loadCompanyDetail.logo.url) ||
                      IMAGES.companyDefault
                    }
                    alt="foto"
                  />
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      textAlign: "center",
                      justifyContent: "center",
                      whiteSpace: "nowrap"
                    }}
                  >
                    <div style={{ marginRight: 5, marginBottom: 6 }}>
                      <Text
                        style={{
                          color: "#7c7c7c",
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                          opacity: 0.5
                        }}
                      >
                        djurnee &trade;
                      </Text>
                    </div>
                    <Text
                      style={{
                        color: "#7c7c7c",
                        fontSize: "1.2rem",
                        opacity: 0.5
                      }}
                    >
                      {t("company.productCode")}
                    </Text>
                  </div>
                  <div style={{}}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        borderBottom: "1px solid #707070",
                        width: "22.1rem",
                        margin: "0 auto"
                      }}
                    >
                      SHT00802291011115556
                    </Text>
                  </div>

                  <div style={{ marginTop: 6 }}>
                    <Text
                      style={{
                        color: "#7c7c7c",
                        textAlign: "center",
                        fontSize: "1.2rem",
                        opacity: 0.5
                      }}
                    >
                      {t("company.licensePeriod")}
                    </Text>
                    <Text
                      style={{
                        color: "#7c7c7c",
                        textAlign: "center",
                        fontWeight: "bold",
                        opacity: 0.5,
                        fontSize: "1.2rem"
                      }}
                    >
                      2 Sep 2020 – 5 Sep 2025s
                    </Text>
                  </div>
                  {/* <div
                style={{
                  width: "100%",
                  margin: "0 auto",
                  marginTop: "2rem"
                }}
              >
                <Button className="dashboard-company-button-update-profile">
                  <Text className="dashboard-company-button-text-update-profile">
                    UPDATE
                  </Text>
                </Button>
              </div> */}
                </div>
                <div className="dashboard-company-button-action" />
              </div>
              <div className="column">
                <div>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {sprintf(t("company.form.companyName"), labelCompany)}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={companyState.loadCompanyDetail.name}
                        placeholder={sprintf(
                          t("company.form.companyName"),
                          labelCompany
                        )}
                        type="text"
                        name="companyName"
                        ref={register({ required: true })}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {companyState.loadCompanyDetail.name}
                    </div>
                  )}
                  {_handleFormValidation(
                    errors.companyName,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      sprintf(t("company.form.companyName"), labelCompany)
                    )
                  )}
                </div>
                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {sprintf(t("company.form.companyEmail"), labelCompany)}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={
                          (companyState.loadCompanyDetail.branches[0]
                            .branchAddress.length > 0 &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address.email) ||
                          ""
                        }
                        placeholder={`${labelCompany} Email`}
                        ref={register({ required: true })}
                        name="companyEmail"
                        type="text"
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {(companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.email) ||
                        ""}
                    </div>
                  )}
                  {_handleFormValidation(
                    errors.companyEmail,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      sprintf(t("company.form.companyEmail"), labelCompany)
                    )
                  )}
                </div>
                {/* <div style={{ marginTop: 30, marginBottom: 16 }}>
              <Text style={{ fontSize: 12, marginLeft: 12 }}>Alias</Text>
              <div
                className="dashboard-company-underline"
                style={{ marginTop: 10 }}
              >
                <input
                  style={{ marginLeft: 12 }}
                  className="dashboard-company-text-regular"
                  defaultValue={
                    companyState.loadCompanyDetail.branches[0].branchAddress[0]
                      .name
                  }
                  placeholder="Alias"
                  type="text"
                  onChange={e =>
                    setOnchangeData({
                      ...onChangeData,
                      alias: e.target.value
                    })
                  }
                />
              </div>
            </div> */}
                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {sprintf(t("company.form.companyAddress"), labelCompany)}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={
                          (companyState.loadCompanyDetail.branches[0]
                            .branchAddress.length > 0 &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address.address) ||
                          ""
                        }
                        placeholder={sprintf(
                          t("company.form.companyAddress"),
                          labelCompany
                        )}
                        name="address"
                        ref={register({ required: true })}
                        type="text"
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {(companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.address) ||
                        ""}
                    </div>
                  )}
                  {_handleFormValidation(
                    errors.address,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.address")
                    )
                  )}
                </div>
                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {t("personnel.form.city")}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <div style={{ marginLeft: 12 }}>
                        <Autocomplete
                          option={_city()}
                          placeHolder={
                            (companyState.loadCompanyDetail.branches[0]
                              .branchAddress.length > 0 &&
                              companyState.loadCompanyDetail.branches[0]
                                .branchAddress[0].address &&
                              companyState.loadCompanyDetail.branches[0]
                                .branchAddress[0].address.regency.name) ||
                            onChangeData.regencyId ||
                            t("personnel.form.city")
                          }
                          onValueChange={e =>
                            setOnchangeData({
                              ...onChangeData,
                              regencyId: e.value
                            })
                          }
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {(companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.regency.name) ||
                        onChangeData.regencyId ||
                        t("personnel.form.city")}
                    </div>
                  )}
                  {_handleFormValidation(
                    (errors.companyName ||
                      errors.companyEmail ||
                      errors.address ||
                      errors.zipCode ||
                      errors.phoneNo ||
                      validateRegency) &&
                      onChangeData.regencyId === "" &&
                      !(
                        companyState.loadCompanyDetail.branches[0].branchAddress
                          .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.regency.name
                      ),
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.city")
                    )
                  )}
                </div>

                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {t("personnel.form.zip")}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={
                          (companyState.loadCompanyDetail.branches[0]
                            .branchAddress.length > 0 &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address.zip) ||
                          ""
                        }
                        placeholder={t("personnel.form.zip")}
                        name="zipCode"
                        ref={register({ required: true })}
                        type="number"
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {(companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.zip) ||
                        ""}
                    </div>
                  )}
                  {_handleFormValidation(
                    errors.zipCode,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.zip")
                    )
                  )}
                </div>
                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {t("personnel.form.phone")}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={
                          (companyState.loadCompanyDetail.branches[0]
                            .branchAddress.length > 0 &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address &&
                            companyState.loadCompanyDetail.branches[0]
                              .branchAddress[0].address.phone) ||
                          ""
                        }
                        placeholder={t("personnel.form.phone")}
                        name="phoneNo"
                        type="number"
                        ref={register({ required: true })}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {(companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address &&
                        companyState.loadCompanyDetail.branches[0]
                          .branchAddress[0].address.phone) ||
                        ""}
                    </div>
                  )}
                  {_handleFormValidation(
                    errors.phoneNo,
                    sprintf(
                      t("personnel.form.isRequiredMsg"),
                      t("personnel.form.phone")
                    )
                  )}
                </div>
                <div style={{ marginTop: 30, marginBottom: 16 }}>
                  <Text style={{ fontSize: 12, marginLeft: 12 }}>
                    {t("personnel.form.fax")}
                  </Text>
                  {_checkPermissionEdit() ? (
                    <div
                      className="dashboard-company-underline"
                      style={{ marginTop: 10 }}
                    >
                      <input
                        style={{ marginLeft: 12 }}
                        className="dashboard-company-text-regular"
                        defaultValue={
                          companyState.loadCompanyDetail.branches[0]
                            .branchAddress.length > 0 &&
                          companyState.loadCompanyDetail.branches[0]
                            .branchAddress[0].address &&
                          companyState.loadCompanyDetail.branches[0]
                            .branchAddress[0].address.fax !== null
                            ? companyState.loadCompanyDetail.branches[0]
                                .branchAddress[0].address.fax
                            : "-"
                        }
                        placeholder={t("personnel.form.fax")}
                        type="number"
                        name="faxNo"
                        ref={register}
                      />
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: 10, marginLeft: 12 }}
                      className="dashboard-profile-status-user-text bold"
                    >
                      {companyState.loadCompanyDetail.branches[0].branchAddress
                        .length > 0 &&
                      companyState.loadCompanyDetail.branches[0]
                        .branchAddress[0].address &&
                      companyState.loadCompanyDetail.branches[0]
                        .branchAddress[0].address.fax !== null
                        ? companyState.loadCompanyDetail.branches[0]
                            .branchAddress[0].address.fax
                        : "-"}
                    </div>
                  )}
                </div>
                {/* <div style={{ marginTop: "3rem" }}>
              {companyState.loadCompanyDetail.addresses &&
                companyState.loadCompanyDetail.addresses.map(
                  (address: any, index: any) => (
                    <div style={{ marginBottom: 16 }}>
                      <Card
                        data={address}
                        onDeleteAddress={() =>
                          dispatch(deleteAddress(address.id))
                        }
                        onPrimaryAddress={() =>
                          dispatch(primaryAddress(address.id, index))
                        }
                        name={`Address #${index + 1}`}
                        primary={address.is_primary}
                        isAccount={false}
                      />
                    </div>
                  )
                )}
            </div> */}
                {/* {addAddress.map((submit: any, index: number) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <CardForm
                    name={`Address #${indexForm + index + 1}`}
                    hapus
                    data={submit}
                    hapusAddress={() => _handleRemoveAdress(submit.index)}
                    primary={false}
                    onChange={e => {
                      const a = [...addAddress];
                      a[index][e.name] = e.value;
                      setAddress(a);
                    }}
                  />
                </div>
              );
            })} */}
                {/* <div style={{ marginBottom: 60 }}>
              <Button onPress={() => _handleAdress()}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <img alt="tambah" src={ICONS.btnAdd} />
                  <div style={{ paddingTop: 15 }}>
                    <Text className="dashboard-company-button-add">
                      ADD ADDRESS
                    </Text>
                  </div>
                </div>
              </Button>
            </div> */}
              </div>
              <div className="column">
                <div style={{ marginLeft: 10, marginBottom: 10 }}>
                  <Text style={{ fontSize: 12 }}>
                    {t("company.form.accounts")}
                  </Text>
                </div>
                {companyState.loadCompanyDetail.company_account &&
                  companyState.loadCompanyDetail.company_account.map(
                    (account: any, index: number) => (
                      <div style={{ marginBottom: 15 }} key={index}>
                        <Card
                          name="Bank"
                          hapus
                          primary={account.primary}
                          dataAccount={account}
                          isAccount
                          onPrimaryAccount={() => _setPrimaryBank(account?.id)}
                          onDeleteAccount={() => _deleteBank(account?.id)}
                          disabled={!_checkPermissionEdit()}
                        />
                      </div>
                    )
                  )}
                {addAccount.map((acc: any, idx: number) => (
                  <div style={{ marginBottom: "1.6rem" }} key={idx}>
                    <CardForm
                      hapus
                      primary={false}
                      dataAccount={acc}
                      listValue={dataPayment}
                      setOptionData={e =>
                        setOnchangeData({
                          ...onChangeData,
                          bankId: e.target.value
                        })
                      }
                      hapusAccount={() => _handleRemoveAccount(idx)}
                      isAccount
                      dataPopulate={bankPopulate}
                      onChange={e => {
                        const a = [...addAccount];
                        a[idx][e.name] = e.value;
                        setAccount(a);
                      }}
                      isLoading={isLoadingDeleteAccount}
                      isError={validateAccount}
                      index={idx}
                    />
                  </div>
                ))}
                {_checkPermissionEdit() && (
                  <div>
                    <Button onPress={() => _handleAccount()}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <img alt="tambah" src={ICONS.btnAdd} />
                        <div style={{ paddingTop: 16 }}>
                          <Text className="dashboard-company-button-add">
                            {t("personnel.form.addAccount")}
                          </Text>
                        </div>
                      </div>
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <input type="submit" hidden />
          </form>
        </Container>
      </Container>
      {_checkPermissionEdit() && (
        <Footer
          buttons={[
            {
              label: t("btn.revert"),
              className: "alt",
              onClick: _handleRevert,
              isLoading: companyState.isLoading
            },
            {
              label: t("btn.save"),
              onClick: handleSubmit(_updateCompany),
              isLoading: companyState.isLoadingPostCompanies
            }
          ]}
        />
      )}
    </>
  );
};

export default Company;
