import React from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Footer, HeaderTittle } from "../../../components";
import { IMAGES } from "../../../configs";
import "./styles.scss";
import { Permission, copyWritings, documentTitle } from "../../../utils";
import { setModalAnnouncement } from "../../../redux/actions";
import { ModalNewAnnouncement, Table } from "./components";
import { Navigation } from "../components/Navigation";

const Announcement = () => {
  const dispatch = useDispatch();
  const permission = {
    create: Permission("announcement-create")
  };

  documentTitle("Company - Announcement");

  return (
    <>
      <Container
        scroll
        withMaxWidth
        className="page-company-position company-tree"
      >
        <HeaderTittle title={copyWritings("company")} />
        <Navigation />
        <Container>
          <div className="page-announcement-list">
            <Table />
          </div>
        </Container>
      </Container>
      <Container withMaxWidth flex className="company-division-footer-button">
        <div style={{ position: "relative", width: "100%" }}>
          <div style={{ position: "absolute", right: 0 }}>
            {permission.create ? (
              <Button
                className="company-button"
                onPress={() => dispatch(setModalAnnouncement(true))}
              >
                <img src={IMAGES.floatingPlusButton} alt="create" />
              </Button>
            ) : null}
          </div>
        </div>
      </Container>
      <Footer />
      <ModalNewAnnouncement />
    </>
  );
};

export default Announcement;
