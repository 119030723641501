import React, { memo } from "react";

import "./styles.scss";

interface Props {
  onChange?: (e: any) => void;
  label: string;
  id?: any;
  name?: any;
  value?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
  checked?: boolean;
}

const Picker = ({
  onChange,
  label,
  id,
  name,
  value,
  defaultChecked,
  disabled,
  checked
}: Props) => {
  return (
    <label className={`container-checkbox ${disabled ? "disabled" : ""}`}>
      <div
        style={{
          marginLeft: 10,
          fontSize: 14,
          fontWeight: "bold",
          color: "#666666"
        }}
      >
        {label}
      </div>
      <input
        type="checkbox"
        onChange={onChange}
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        className="input"
        disabled={disabled}
        checked={checked}
      />
      <span className="checkmark" />
    </label>
  );
};

Picker.defaultProps = {
  disabled: false
};

export default memo(Picker);
