import React, { memo } from "react";

import "./styles.scss";

const Spinner = () => (
  <div className="lds-ring">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default memo(Spinner);
