import React, { memo, useCallback, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

import "./styles.scss";
import { Button, Container, Text } from "..";
import BreadCrumb from "./BreadCrumb";
import {
  // ChatIcon,
  HomeIcon
  // NotificationIcon,
  // SettingIcon,
} from "../../assets";

// action & type redux
import { getMenu, handleLogout } from "../../redux/actions";
import { Reducers } from "../../redux/types";

let result: any;

const TopBar = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { pathname } = history.location;
  const dispatch = useDispatch();

  const [language, setLanguage] = useState(Cookies.get("locale") || "en");

  // const _changeTheme = () => {
  //   dispatch(handleTheme());
  // };

  const { menuState, authState, taskState } = useSelector(
    (state: Reducers) => ({
      menuState: state.menu,
      authState: state.auth,
      taskState: state.task
    }),
    shallowEqual
  );

  const _getTitle = useCallback((url: string, listData: any[]) => {
    listData &&
      listData.length > 0 &&
      listData.map(item => {
        if (item.url === url) {
          result = item;
        } else {
          _getTitle(url, item.childrens || []);
        }

        return true;
      });
    return result;
  }, []);

  const _breadCrumbPath = () => {
    const bc = pathname.split("/").filter(i => i !== "");
    if (bc[0] === "task" && bc[1] === "detail") {
      if (taskState.taskDetail) {
        bc[2] = taskState.taskDetail.number;
      }
      while (bc.length > 3) {
        bc.pop();
      }
    }

    if (bc[1] === "user_id") {
      let name = "";
      Object.keys(menuState.listMenu[0].childrens).forEach(element => {
        for (
          let i = 0;
          i < menuState.listMenu[0].childrens[element].childrens.length;
          i += 1
        ) {
          if (
            menuState.listMenu[0].childrens[element].childrens[i].url ===
            pathname
          ) {
            name = menuState.listMenu[0].childrens[element].childrens[i].title;
          }
        }
      });
      const path = ["dashboard", name];
      return path;
    }

    if (
      _getTitle(pathname, menuState.listMenu) &&
      _getTitle(pathname, menuState.listMenu).title
    ) {
      let res = bc.slice(0, 2);
      res = [...res, _getTitle(pathname, menuState.listMenu).title];

      return res;
    }

    return bc;
  };

  const _changeLanguage = (e: any) => {
    const code = e;

    if (i18n.language !== code) {
      i18n.changeLanguage(code);
      Cookies.set("locale", code);
      setLanguage(code);
      // window.location.reload();
      dispatch(getMenu("general", code || language));
      if (pathname.includes("dashboard")) {
        window.location.reload(); // dispatch(getListWidget(""))
      }
    }
  };

  const _handleLogout = () => {
    dispatch(handleLogout(true));
  };

  return (
    <div className="top-bar">
      <Container withMaxWidth className="top-bar-custom-container">
        <div
          className={
            !pathname.includes("dashboard")
              ? "top-bar-container"
              : "top-bar-container-dashboard"
          }
        >
          <div className="top-bar-wrap-title">
            <div
              style={{
                marginTop: 2,
                marginRight: 20
              }}
            >
              <Link to="/">
                <HomeIcon />
              </Link>
            </div>
            {menuState.listMenu.length > 0 ? (
              _breadCrumbPath().map((item, index) => (
                <BreadCrumb
                  key={index}
                  item={item}
                  active={
                    index ===
                    pathname.split("/").filter(i => i !== "").length - 1
                  }
                  bold={_breadCrumbPath().length === index + 1}
                />
              ))
            ) : (
              <></>
            )}
          </div>
          <div className="top-bar-absolute-right">
            {/* <div className="top-bar-wrap-item-navigation">
              <Button style={{ opacity: 0 }} onPress={_changeTheme}>
                <SettingIcon />
              </Button>
            </div>
            <div className="top-bar-wrap-item-navigation">
              <Button style={{ opacity: 0 }}>
                <NotificationIcon />
              </Button>
            </div>
            <div className="top-bar-wrap-item-navigation">
              <Button style={{ opacity: 0 }}>
                <ChatIcon />
              </Button>
            </div> 
            <div className="top-bar-wrap-item-navigation">{t("title")}</div> */}
            <div className="top-bar-wrap-item-navigation language-switcher">
              <Button
                onPress={() => _changeLanguage("en")}
                disabled={authState.isLoading}
              >
                <Text
                  className={`top-bar-title top-bar-text-logout ${language ===
                    "en" && "language-active"}`}
                >
                  EN
                </Text>
              </Button>
              <div className="language-spacing"> | </div>
              <Button
                onPress={() => _changeLanguage("id")}
                disabled={authState.isLoading}
              >
                <Text
                  className={`top-bar-title top-bar-text-logout ${language ===
                    "id" && "language-active"}`}
                >
                  ID
                </Text>
              </Button>
            </div>
            <div className="top-bar-wrap-item-navigation">
              <Button onPress={_handleLogout} disabled={authState.isLoading}>
                <Text
                  className={`top-bar-title top-bar-text-logout ${!authState.isLoading &&
                    "top-bar-title-active"}`}
                >
                  {t("logout")}
                </Text>
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default memo(TopBar);
