import React, { useState } from "react";
import Switch from "react-switch";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { Reducers } from "../../../../redux/types";

import { resetValueSetting, setSetting } from "../../../../redux/actions";

const CopywritingInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [copyWritingLang, setCopyWritingLang] = useState(true);
  const settingState = useSelector((state: Reducers) => state.setting);

  const setCopyWritingSetting = (attributes: string, value: any) => {
    const res = {
      ...settingState.settingDetail.value,
      [copyWritingLang ? "EN" : "ID"]: {
        ...settingState.settingDetail.value[copyWritingLang ? "EN" : "ID"],
        [attributes]: value
      }
    };
    dispatch(setSetting("value", res));
  };

  return (
    <div>
      <p style={{ fontWeight: "bold" }}>{t("setting.value")}</p>
      <div
        style={{ marginBottom: 20 }}
        className="setting-list-container-switch"
      >
        <Switch
          onChange={() => {
            setCopyWritingLang(!copyWritingLang);
            dispatch(resetValueSetting());
          }}
          checked={copyWritingLang}
          onColor="#ff7600"
          onHandleColor="#fffff7"
          handleDiameter={18}
          uncheckedIcon={false}
          checkedIcon={false}
          height={26}
          width={40}
        />
        <p style={{ marginLeft: 12 }}>{copyWritingLang ? "EN" : "ID"}</p>
      </div>
      {Object.keys(
        settingState.settingDetail.value[copyWritingLang ? "EN" : "ID"]
      ).map((item, index: number) => (
        <div key={index} className="setting-list-container-copywriting-input">
          <div className="setting-list-copywriting-input-disable">
            <span className="setting-list-copywriting-input-disable-text">
              {item}
            </span>
          </div>
          <div style={{ width: "49%" }}>
            <input
              className="setting-list-copywriting-input"
              value={settingState.settingDetail.value.EN[item]}
              onChange={(e: any) => setCopyWritingSetting(item, e.target.value)}
            />
            <div style={{ width: "100%" }} className="setting-list-line" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CopywritingInput;
