import React from "react";
import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

import "./styles.css";

interface Props {
  onChange?: (e: any) => void;
  value?: string;
  placeHolder?: string;
  readonly: boolean;
}

const RichTextEditor = ({ onChange, value, placeHolder, readonly }: Props) => {
  const toolbarOptions = {
    toolbar: [
      ["bold", "italic", "underline"], // toggled buttons
      [{ align: [] }]
    ]
  };
  return (
    <ReactQuill
      readOnly={readonly}
      style={{
        width: "100%",
        height: 300,
        fontSize: 16
      }}
      theme="snow"
      value={value}
      onChange={onChange}
      modules={toolbarOptions}
      placeholder={placeHolder}
    />
  );
};

export default RichTextEditor;
