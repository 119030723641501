import React, { useCallback, useState } from "react";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";

import "./styles.scss";
import moment from "moment";
import { debounce } from "lodash";
import { documentTitle } from "../../../utils";
import { FilterStaff, TableStaff } from "../components";
import { Modal, Space, Spinner, Text } from "../../../components";

import {
  getEmployee,
  getTemplateEmployee,
  removeValidateEmployee,
  updateAllLoanProfileStatus,
  uploadDataEmployee,
  validateUploadDataEmployee
} from "../../../redux/actions";
import { Reducers } from "../../../redux/types";
import { IMAGES } from "../../../configs";

const initFileUpload: File | any = {};

const StaffList = () => {
  documentTitle("Employee - Staff List");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const employeeState = useSelector((state: Reducers) => state.employee);
  const [modalUpload, setModalUpload] = useState(false);
  const [fileUpload, setFileUpload] = useState(initFileUpload);
  const [showHidden, setShowHidden] = useState(false);
  const [showOutSource, setShowOutSource] = useState(false);
  const [validateFile, setValidateFile] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [modalApproveAll, setModalApproveAll] = useState(false);
  const [modalDeclineAll, setModalDeclineAll] = useState(false);
  const [valueSearch, setValueSearch] = useState("");

  const _handleHidden = () => {
    setShowHidden(!showHidden);
    dispatch(
      getEmployee(
        "", // id
        "", // idPos
        "", // idRole
        1, // page
        !showHidden, // hidden
        valueSearch, // q
        false, // parent
        false, // show_self
        showOutSource, // hiddenOutSource
        showDisable // disable
      )
    );
  };

  const _handleHiddenOutSource = () => {
    setShowOutSource(!showOutSource);
    dispatch(
      getEmployee(
        "", // id
        "", // idPos
        "", // idRole
        1, // page
        showHidden, // hidden
        valueSearch, // q
        false, // parent
        false, // show_self
        !showOutSource, // hiddenOutSource
        showDisable // disable
      )
    );
  };

  const _handleDisable = () => {
    setShowDisable(!showDisable);
    dispatch(
      getEmployee(
        "", // id
        "", // idPos
        "", // idRole
        1, // page
        showHidden, // hidden
        valueSearch, // q
        false, // parent
        false, // show_self
        showOutSource, // hiddenOutSource
        !showDisable // disable
      )
    );
  };

  const _resetData = () => {
    if (employeeState.resultValidate && employeeState.resultValidate.message) {
      dispatch(
        validateUploadDataEmployee(fileUpload, () =>
          dispatch(getEmployee("", "", "", 1, showHidden))
        )
      );
    } else {
      dispatch(validateUploadDataEmployee(fileUpload));
    }
  };
  const _handleValidateFile = () => {
    setValidateFile(!validateFile);
  };

  const _updateAllLoanProfileStatus = (status: number) => {
    dispatch(
      updateAllLoanProfileStatus(status, () => {
        setModalApproveAll(false);
        setModalDeclineAll(false);
      })
    );
    dispatch(getEmployee("", "", "", 1, showHidden));
  };

  const _onChangeSearch = useCallback(
    debounce(
      (text: any, hidden: boolean, outsource: boolean, disable: boolean) => {
        setValueSearch(text);
        dispatch(
          getEmployee(
            "", // id
            "", // idPos
            "", // idRole
            1, // page
            hidden, // hidden
            text, // q
            false, // parent
            false, // show_self
            outsource, // hiddenOutSource
            disable // disable
          )
        );
      },
      1000
    ),
    []
  );

  return (
    <div className="page-employee-staff-list staff-list-container">
      <FilterStaff />
      <div className="wrap-menu">
        {/* <div>ojoj</div> */}
        <div style={{ flex: 1, display: "flex", alignItems: "center" }}>
          <img
            src={IMAGES.searchIcon}
            className="icon-search-employee"
            alt="search-icon"
          />
          <Space width={8} />
          <input
            placeholder={t("searchPlaceholderPerson")}
            type="text"
            className="search-input-employee"
            onChange={e =>
              _onChangeSearch(
                e.target.value,
                showHidden,
                showOutSource,
                showDisable
              )
            }
            // value={valueSearch}
          />
        </div>
        <Space width={20} />
        <div className="wrap-switch">
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginBottom: 10
            }}
          >
            <Switch
              onColor="#50B1B1"
              width={37}
              height={22}
              onChange={_handleHidden}
              checked={showHidden}
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <Text className="label">
              {t("personnel.switch.hiddenEmployee")}
            </Text>
          </div>
          <Space width={10} />
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginBottom: 10
            }}
          >
            <Switch
              onColor="#50B1B1"
              width={37}
              height={22}
              onChange={_handleHiddenOutSource}
              checked={showOutSource}
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <Text className="label">
              {t("personnel.switch.outsourceEmployee")}
            </Text>
          </div>
          <Space width={10} />
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              marginBottom: 10
            }}
          >
            <Switch
              onColor="#50B1B1"
              width={37}
              height={22}
              onChange={_handleDisable}
              checked={showDisable}
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
            />
            <Text className="label">
              {t("personnel.switch.disabledEmployee")}
            </Text>
          </div>
          <Space width={12} />
        </div>
        <button
          className="button btn-employee"
          type="button"
          onClick={() => setModalUpload(true)}
        >
          {t("btn.upload")}
        </button>
        <Space width={8} />
        <button
          className="button btn-employee"
          type="button"
          onClick={() => dispatch(getTemplateEmployee())}
        >
          TEMPLATE
        </button>
      </div>
      <TableStaff
        showHidden={showHidden}
        showHiddenOurSource={showOutSource}
        showDisabled={showDisable}
        q={valueSearch}
      />

      <Modal
        isOpen={modalUpload}
        onPressCancel={() => {
          setValidateFile(false);
          setModalUpload(false);
          setFileUpload(initFileUpload);
          dispatch(removeValidateEmployee());
        }}
        titleModal={t("personnel.btn.uploadStaffData")}
        onPressSubmit={() => {
          employeeState.resultValidate.message
            ? dispatch(
                uploadDataEmployee(fileUpload, () => {
                  setModalUpload(false);
                  setFileUpload(initFileUpload);
                  dispatch(getEmployee());
                })
              )
            : _resetData();
          _handleValidateFile();
        }}
        loading={employeeState.isLoading}
        btnSubmitText={
          employeeState.resultValidate.message
            ? t("btn.upload")
            : t("btn.validate")
        }
        btnCancelText={t("btn.cancel")}
      >
        <div className="body-modal">
          {fileUpload && fileUpload.name && validateFile ? null : (
            <div
              className={
                fileUpload && fileUpload.name
                  ? "component-upload components-employee-container-attachment global-shadow-card"
                  : "component-upload components-employee-container-attachment-input-button global-shadow-card"
              }
            >
              {modalUpload && (
                <input
                  disabled={fileUpload && fileUpload.name && validateFile}
                  type="file"
                  onChange={e =>
                    e.target.files!.length > 0 &&
                    setFileUpload(e.target.files![0])
                  }
                />
              )}

              <span>
                {fileUpload && fileUpload.name
                  ? fileUpload.name
                  : t("btn.attachmentButton")}
              </span>
            </div>
          )}

          {employeeState.isLoading && (
            <div className="wrap-loading">
              <Spinner />
              <Text>{employeeState.uploadProgress}</Text>
            </div>
          )}
          {Object.values(employeeState.resultValidate).length > 0 &&
          employeeState.resultValidate.status.failed.length > 0 ? (
            <div>
              <table
                style={{
                  display: "table",
                  tableLayout: "fixed",
                  borderCollapse: "collapse"
                }}
                cellSpacing="0"
                cellPadding="0"
              >
                <tr className="staff-list-modal-tr-bottom-border">
                  <td style={{ width: 50, fontSize: 16, fontWeight: "bold" }}>
                    LINE
                  </td>
                  <td style={{ width: 250, fontSize: 16, fontWeight: "bold" }}>
                    <p>NAME</p>
                  </td>
                  <td style={{ width: 250, fontSize: 16, fontWeight: "bold" }}>
                    <p className=" margin-left-20">ACCOUNT & POSITION</p>
                  </td>
                  <td style={{ width: 250, fontSize: 16, fontWeight: "bold" }}>
                    <p className=" margin-left-40">DESCRIPTION</p>
                  </td>
                </tr>
              </table>
              <div style={{ maxHeight: 400, overflow: "scroll" }}>
                <table
                  style={{
                    display: "table",
                    tableLayout: "fixed",
                    borderCollapse: "collapse"
                  }}
                  cellSpacing="0"
                  cellPadding="0"
                >
                  {employeeState.resultValidate.status.failed.map(
                    (item: any, index: number) => (
                      <tr key={index} style={{ verticalAlign: "top" }}>
                        <td style={{ width: 50 }} className="halah-coba">
                          <p className="margin-top-20">{index + 1}</p>
                        </td>
                        <td style={{ width: 250 }} className="halah-coba">
                          <div className="margin-top-20">{item.nama}</div>
                          <div>{item.nomor_karyawan}</div>
                          <div>
                            {`${item.pob}, ${moment(item.dob).format(
                              "DD-MM-YYYY"
                            )}`}
                          </div>
                        </td>
                        <td className="halah-coba">
                          <div
                            style={{
                              width: 250,
                              wordBreak: "break-all"
                            }}
                            className="margin-top-20 margin-left-20"
                          >
                            {item.email}
                          </div>
                          <div
                            className="margin-left-20"
                            style={{ fontStyle: "italic" }}
                          >
                            {item.jabatan}
                          </div>
                        </td>
                        <td style={{ width: 250 }} className="halah-coba">
                          {Object.keys(item.error_messages).map(e => (
                            <>
                              <div
                                className="margin-top-20 margin-left-20"
                                style={{ fontWeight: "bold" }}
                              >
                                {e}
                              </div>
                              <div className="margin-left-20">
                                {item.error_messages[e][0]}
                              </div>
                            </>
                          ))}
                        </td>
                      </tr>
                    )
                  )}
                </table>
              </div>
            </div>
          ) : null}
          {Object.values(employeeState.resultValidate).length > 0 &&
          employeeState.resultValidate.status.failed.length === 0 ? (
            <div
              className="margin-top-40"
              style={{
                width: 600,
                backgroundColor: "#f6fef9",
                display: "flex",
                alignItems: "center",
                borderRadius: 10
              }}
            >
              <div
                style={{
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  width: 5,
                  height: 100,
                  backgroundColor: "#4cda7e"
                }}
              />
              <FaCheckCircle
                className="margin-left-20 margin-right-20"
                style={{ height: 45, width: 45 }}
              />
              <p>
                Congratulations system did`nt find any mismatch in the document
                . Click &quot;UPLOAD&quot; to continue
              </p>
            </div>
          ) : null}
        </div>
      </Modal>
      <Modal
        isOpen={modalApproveAll}
        warning
        onPressCancel={() => setModalApproveAll(false)}
        onPressSubmit={() => _updateAllLoanProfileStatus(5)}
        btnSubmitText={t("btn.yes")}
        btnCancelText={t("btn.cancel")}
      >
        <div style={{ textAlign: "center" }}>
          <Trans>{t("personnel.message.approveAll")}</Trans>
        </div>
      </Modal>
      <Modal
        isOpen={modalDeclineAll}
        warning
        onPressCancel={() => setModalDeclineAll(false)}
        onPressSubmit={() => _updateAllLoanProfileStatus(6)}
        btnSubmitText={t("btn.yes")}
        btnCancelText={t("btn.cancel")}
      >
        <div style={{ textAlign: "center" }}>
          <Trans>{t("personnel.message.declineAll")}</Trans>
        </div>
      </Modal>
    </div>
  );
};

export default StaffList;
