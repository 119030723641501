import React, { memo, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Button, Container } from "../../../components";
import { IMAGES } from "../../../configs";
import { Permission } from "../../../utils";
import { getProfileStaff, setModalAddContract } from "../../../redux/actions";
import { ModalFormContract } from "../components";
import TableContract from "../components/TableContracts";
import { Reducers } from "../../../redux/types";

interface Props {
  employeeId: string;
}

const Contracts = ({ employeeId }: Props) => {
  const dispatch = useDispatch();

  const { homeState } = useSelector(
    (state: Reducers) => ({
      homeState: state.home
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getProfileStaff(employeeId));
  }, [dispatch, employeeId]);

  const permission = {
    create: Permission("employee-others-edit")
  };

  return (
    <>
      <Container scroll>
        <div className="page-contracts-list">
          <TableContract employee={employeeId} />
        </div>
      </Container>
      {permission.create ||
      homeState.profile.listRole.includes("personal-loan-hr") ? (
        <Container withMaxWidth flex className="company-division-footer-button">
          <div style={{ position: "relative", width: "100%" }}>
            <div style={{ position: "absolute", right: 0 }}>
              <Button
                className="company-button"
                onPress={() => dispatch(setModalAddContract(true))}
              >
                <img src={IMAGES.floatingPlusButton} alt="create" />
              </Button>
            </div>
          </div>
        </Container>
      ) : null}
      <ModalFormContract employee={employeeId} isUpdate={false} />
    </>
  );
};

export default memo(Contracts);
