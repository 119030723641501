import React, { memo } from "react";

import "./styles.scss";
import Picker from "../Picker";

interface ListValue {
  id: string;
  title: string;
}

interface Props {
  listValue: ListValue[];
  onChangePhoneCode?: (e: any) => void;
  onChangePhoneNumber?: (e: any) => void;
  valuePhoneNumber?: string;
  valuePhoneCode?: string;
}

const PhoneInput = ({
  listValue,
  onChangePhoneCode,
  onChangePhoneNumber,
  valuePhoneNumber,
  valuePhoneCode
}: Props) => (
  <div>
    <div className="components-employee-custom-label">Phone Number</div>
    <div style={{ display: "flex" }}>
      <Picker
        classNameContainer="components-employee-phone-code-input border"
        classNameSelect="components-employee-phone-code-input"
        listValue={listValue}
        value={valuePhoneCode}
        onChange={onChangePhoneCode}
      />
      <input
        type="number"
        onChange={onChangePhoneNumber}
        value={valuePhoneNumber}
        className="components-employee-phone-number-input"
        placeholder="Handphone Number"
      />
    </div>
  </div>
);

export default memo(PhoneInput);
